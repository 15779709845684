import client from "../../../Configurations/apollo";
import { gql } from "@apollo/client";

const GET_LOGIN = gql`
	query($email: String!, $password: String!,$fcmToken:String) {
		sellerlogin(email: $email, password: $password,fcmToken:$fcmToken) {
			retailerid
			token
			tokenExpiration
			storeid
			formNumber
			storeStatus
			role
		}
	}
`;

const GET_SHOPASSISTANT_LOGIN=gql`
	query($phoneNumber:String!,$otp:Int!,$fcmToken:String){
		loginShopAssistant(phoneNumber:$phoneNumber,otp:$otp,fcmToken:$fcmToken){
		shopAssistantId
		firstName
		lastName
		phoneNumber
		storeid
		storeName
		token
		verification
		shopAsstTruncatedFlow
		role
}
}`;


const FORGOT_PASSWORD = gql`
mutation($emailAddress:String!){
	resetPassword(emailAddress:$emailAddress)
  }
`
function removeToken() {
	localStorage.removeItem("token");
	localStorage.removeItem("retailerid");
	localStorage.removeItem("isLoggedin");
	localStorage.removeItem("storeid");
	localStorage.removeItem("role");
	localStorage.removeItem("loginformNumber");
	localStorage.removeItem("storestatus");
	localStorage.removeItem("tokenExpiration");
  }
  
  export function loginAccess(params, stayLoggedIn) {
	return (dispatch) => {
	  dispatch({
		type: "LOGGING_IN",
	  });
  
	  client
		.query({
		  query: GET_LOGIN,
		  variables: {
			email: params.userName.toLowerCase(),
			password: params.password,
			fcmToken: localStorage.getItem("fcmToken") || null,
		  },
		})
		.then((res) => {
		  if (res.data && res.data.sellerlogin) {
			const {
			  token,
			  retailerid,
			  storeid,
			  formNumber,
			  storeStatus,
			  role,
			} = res.data.sellerlogin;

			localStorage.setItem("token", token);
			localStorage.setItem("retailerid", retailerid);
			localStorage.setItem("isLoggedin", true);
			localStorage.setItem("storeid", storeid);
			localStorage.setItem("role", role);
			localStorage.setItem("loginformNumber", formNumber);
			localStorage.setItem("storestatus", storeStatus);

			if (stayLoggedIn) {
			  localStorage.setItem("tokenExpiration", "persistent");
			} else {
			  localStorage.setItem("tokenExpiration", "session");
			}
  
			dispatch({
			  type: "LOGIN_ACCESS_SUCCESS",
			  payload: res.data,
			});
		  } else {
			dispatch({
			  type: "LOGIN_ACCESS_ERROR",
			  payload: res.errors,
			});
		  }
		})
		.catch((err) => {
		  dispatch({
			type: "LOGIN_ACCESS_FAILED",
			payload: err.message,
		  });
		});
	};
  }
  function handleSessionCheck() {
	const sessionMarker = sessionStorage.getItem("sessionMarker");
  
	if (!sessionMarker) {
	  const tokenExpiration = localStorage.getItem("tokenExpiration");
  
	  if (tokenExpiration === "session") {
		removeToken();
	  }
	}
	sessionStorage.setItem("sessionMarker", "active");
  }
  window.addEventListener("load", handleSessionCheck);

 

export  function shopAssistantLoginAccess(params1,params2) {

	return (dispatch) => {
		dispatch({
			type: "SHOPASSISTANT_LOGGING_IN",
		});
		client.query({
			query: GET_SHOPASSISTANT_LOGIN,
			variables: {
				phoneNumber: params1,
				otp: parseInt(params2),
				fcmToken: localStorage.getItem("fcmToken")?localStorage.getItem("fcmToken"):null,
			},
		})

		.then((res) => {
			if (res.data&&res.data.loginShopAssistant!==null) {
				const {shopAssistantId,storeid,token,role,storeName} = res.data.loginShopAssistant;
				localStorage.setItem('shopAssistantId', shopAssistantId);
				localStorage.setItem('ShopAssistantStoreName',storeName)
				localStorage.setItem('ShopAssistantisLoggedin',true);
				localStorage.setItem('storeid',storeid)
				localStorage.setItem('role',role)
				localStorage.setItem("token",token)
				localStorage.setItem("isLoggedin", true);
				
				dispatch({
					type: "SHOPASSISTANT_LOGIN_ACCESS_SUCCESS",
					payload: res.data,
				});
			} else  {
				dispatch({
					type: "SHOPASSISTANT_LOGIN_ACCESS_ERROR",
					payload: res.errors,
				});
			}
		})
		.catch((err) => {
			dispatch({
				type: "SHOPASSISTANT_LOGIN_ACCESS_FAILED",
				payload: err.message,
			});
		});
	};
};

export function resetpassword(params) {
   
    return (dispatch) => {
        dispatch({
            type: "RESET_WAITING",
        });
        client
            .mutate({
                mutation: FORGOT_PASSWORD,
                variables: {
                    emailAddress:`${params}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    dispatch({
                        type: "RESET_SUCCESS",
                        payload: res.data,
                    });
                } else {
                    dispatch({
                        type: "RESET_NETWORK_FAILED",
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: "RESET_FAILED",
                    payload: err.message,
                });
            });
    };
}
 
export const closeSnackBars = () => {
	return {
		type: "CLOSE_SNACKBARS",
	};
};
