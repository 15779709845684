import { gql } from "@apollo/client";

export const AddBill = gql`
mutation($orderid:ID!,$finalBillAmount:Float!){
    addBillAndAmount(orderid:$orderid,finalBillAmount:$finalBillAmount){
      id
      orderNumber
      finalBillAmount
    }
  }
  `;


  export const PaymentStatus = gql`
  mutation($orderid:ID!){
    updatePaymentStatus(orderid:$orderid)
  }
  `;