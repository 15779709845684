import React, { useState, useEffect } from "react";
import "./StoreUpdate.css";
import CustomTextField from "../Commoncomponents/input/index";
import CustomSelect1 from "../Commoncomponents/CheckSelectBox/index";
import { InputAdornment, Typography } from "../MUIComponents/index";
import indiaImage from "../../utils/images/indiaImage.svg";
import StoreLocation4 from "../../utils/images/StoreLocation4.svg";
import mapImage from "../../utils/images/mapImage.svg";
import CustomButton from "../Commoncomponents/button/index";
import EditIcon1 from "../../utils/Icon/EditIcon1.svg";
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import { HeaderNavigationFontSize_20 } from "../Typography/index";
import { HeaderFontSize_24 } from "../Typography/index";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { contentFontSize_16 } from "../Typography/index";
import { Grid } from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import { ButtonFontSize_18 } from "../Typography/index";
import { useFormik } from "formik";
import client from "../../Configurations/apollo";
import { storevalidationSchema } from "../MyAccountForm/validation";
import { EDIT_STORE_MUTATION } from "../MyAccountForm/Query";
import MapEditModal from "../MapEditModal";
import BuisnessType from "../FormFields/BusinessType";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "../MUIComponents/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MapEditField from "../EditMapLocation";
import EditGoogleMap from "../EditGoogleMap/index";
import ImageEditWindow from "../ImageEditModal";
import EditImageField from "../EditImageLogo";
import BusinessType from "../MyAccountForm/BusinessType";
import { useNavigate } from "react-router-dom";
import { TextField } from "../MUIComponents/index";
import EditLocation from '../EditLocation';
import { useDispatch } from "react-redux";
import { message } from "antd";
import dummy from '../../utils/images/dummy.png';

export default function StoreUpdate(props) {
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openImageEdit, setOpenImageEdit] = useState(false)
  const [error, setError] = useState(false);
  const [BusinessError, setBError] = useState(false);
  const [net, setNet] = useState(false);
  const [selectedBusinessTypes, setSelectedBusinessTypes] = useState([]);
  const [selectBussinessTypeId, setBussinessTypeId] = useState([]);
  const [open4, setOpen4] = useState(false);
  const [open3, setOpen3] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [mapaddress, setMapaddress] = useState('')
  const [mapDetails, setMapDetails] = useState(props?.retailerDetails?.data)
  const [mapCoordinates, setMapCoordinates] = useState('')
  const [storeImageTemp1, setStoreImageTemp1] = useState(null);
  const [StoreEdit, setStoreEdit] = useState(null)
  const [businessType, setBusinessType] = useState(() => props?.businessId || []);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9,setOpen9]=useState(false);

  useEffect(() => {
    setBusinessType(props?.businessId);
  }, [props.businessId]);




  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.retailerDetails?.data?.retailerdetails?.stores[0]?.businessTypeId) {
      const businessTypeIds =
        props?.retailerDetails?.data?.retailerdetails?.stores[0]?.businessTypeId;
      const selectedOptions =
        props?.BusinessType?.data?.businessTypeListing?.filter((option) =>
          businessTypeIds.includes(option.id)
        );

      setSelectedBusinessTypes(selectedOptions);
    }
  }, [props?.retailerDetails, props?.BusinessType]);
  useEffect(() => {
  
    props.setBusinessType(
      props?.retailerDetails &&
      props?.retailerDetails?.data &&
      props?.retailerDetails?.data?.retailerdetails &&
      props?.retailerDetails?.data?.retailerdetails?.stores[0] &&
      props?.retailerDetails?.data?.retailerdetails?.stores[0].businessTypeId
    );
    // 
  }, []);



  function handleProfileroute() {
    nav('/home/Profile')
  }
  const options = props?.BusinessType?.data?.businessTypeListing.map((options) => {
    return {
      value: options.id,
      label: options.name,
    };
  });


  const handleOpen6 = () => {
    setOpen6(true)
  }

  const handleClose6 = () => {
    setOpen6(false)
  }

  const handleOpen7 = () => {
    setOpen7(true)
  }

  const handleClose7 = () => {
    setOpen7(false)
  }
  const handleOpen8 = () => {
    setOpen8(true)
  }

  const handleClose8 = () => {
    setOpen8(false)
  }
  const handleOpen9 = () => {
    setOpen9(true)
  }

  const handleClose9 = () => {
    setOpen9(false)
  }



  // const editStoreLogo =() =>{
  //   setOpenImageEdit(true)
  // }
  const editStoreLogo = () => {
    setOpenImageEdit(true)
    setOpen3(true)
  }

  const [state, setState] = React.useState({
    mapEditModal: false,
  });

  // let { deliveryDays, deliveryCharge, storeCoordinates } =
  //   props?.retailerDetails &&
  //   props?.retailerDetails?.data &&
  //   props?.retailerDetails?.data?.retailerdetails &&
  //   props?.retailerDetails?.data?.retailerdetails?.stores[0] &&
  //   props?.retailerDetails?.data?.retailerdetails?.stores[0];
  // // delete storeCoordinates.__typename;

  let storeDetails = props?.retailerDetails?.data?.retailerdetails?.stores?.[0] || {};

  let { deliveryDays = [], deliveryCharge, storeCoordinates } = storeDetails;
if (storeCoordinates) {
  delete storeCoordinates.__typename;
}


let deliverDay = (Array.isArray(deliveryDays) ? deliveryDays : []).map((day) => ({
  T9amTO12pm: `${day.T9amTO12pm}`,
  T12pmTO3pm: `${day.T12pmTO3pm}`,
  T3pmTO6pm: `${day.T3pmTO6pm}`,
  T6pmTO9pm: `${day.T6pmTO9pm}`,
  deliveryDay: `${day.deliveryDay}`,
}));


  const token = localStorage.getItem("token");
  const mapEditModal = () => {
    setState({ ...state, mapEditModal: !state.mapEditModal });
  };

  const handleImageEdit = () => {
    setState({ imageEditModal: !state.imageEditModal });
    localStorage.setItem("StoreImagelocal", true)

    props.newSignedUrl &&
      props.postNewUrl({
        url: props.newSignedUrl,
        details: props.retailerDetails.data.retailerdetails,
      });

  };


  const StoreImageLocal = localStorage.getItem("StoreImagelocal")



  useEffect(() => {
    if (props.newSignedLogoUrl && StoreImageLocal) {
      // localStorage.setItem("StoreImageTemp1", props.newSignedLogoUrl);
      setStoreImageTemp1(props.newSignedLogoUrl);
    }
  }, [StoreImageLocal]);


  useEffect(() => {
    // Clear ProfileImageTemp1 when leaving the component

    return () => {
      // localStorage.removeItem("StoreImageTemp1");
      localStorage.removeItem("StoreImagelocal")
      setStoreImageTemp1(null)
      dispatch({
        type: "CLEAR_LOGO_URL"
      });

    };
  }, []);



  const storeformik = useFormik({
    enableReinitialize:true,
    initialValues: {
      storeName:
        props?.retailerDetails &&
        props?.retailerDetails?.data &&
        props?.retailerDetails?.data?.retailerdetails &&
        props?.retailerDetails?.data?.retailerdetails?.stores[0]&&
        props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeName,
      storeDescription:
        props?.retailerDetails &&
        props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeDescription
        ,
      storeManagerFirstName:
        props?.retailerDetails &&
        props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeManagerFirstName,
      storeManagerLastName:
        props.retailerDetails &&
        props.retailerDetails.data.retailerdetails.stores[0]?.storeManagerLastName,
      storeManagerPhone:
        props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0][
          "storeManagerPhone"
          ] == null
          ? ""
          : props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0][
          "storeManagerPhone"
          ],
      storeManagerMobile:
        props.retailerDetails &&
        props.retailerDetails.data.retailerdetails.stores[0][
        "storeManagerMobile"
        ],
      bankAccountNumber:
        props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0].bankDetails[
          "bankAccountNumber"
          ] == null
          ? ""
          : props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0].bankDetails[
          "bankAccountNumber"
          ],
      IFSCcode:
        props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0].bankDetails[
          "IFSCcode"
          ] == null
          ? ""
          : props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0].bankDetails[
          "IFSCcode"
          ],
      fssaiNo:
        props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0][
          "fssaiNo"
          ] == null
          ? ""
          : props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0][
          "fssaiNo"
          ],
      accountHolderName:
        props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0].bankDetails[
          "accountHolderName"
          ] == null
          ? ""
          : props.retailerDetails &&
          props.retailerDetails.data.retailerdetails.stores[0].bankDetails[
          "accountHolderName"
          ],
      buisnessType:
        props.retailerDetails &&
        props.retailerDetails.data &&
        props.retailerDetails.data.retailerdetails &&
        props.retailerDetails.data.retailerdetails.stores[0].businessType,
    },
    validationSchema: storevalidationSchema,
    onSubmit: (values) => {
      // props.editStore(props.retailerDetails.data.retailerdetails.stores[0],values)

      if (props.fileListLogo === true && !props.newSignedLogoUrl) {
        {
        }
        Modal.confirm({
          title: "Warning",
          icon: <ExclamationCircleOutlined />,
          content: "Please upload the image or delete the image before saving",
          okText: "Ok",
        });
      } else if (props.businessId.length === 0) {
        setBError(true);
        message.error({
          content: "Please select atleast one business type",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      } else if(values.storeManagerFirstName===""){
        message.error({
                 content: "Please Enter StoreManagerFirstName",
                 className: "custom-class",
                 style: {
                   marginTop: "10vh",
                   marginLeft: "15vw",
                 },
                 duration: 3.5,
               });
       
       }else if(values.storeManagerLastName===""){
        message.error({
                 content: "Please Enter storeManagerLastName",
                 className: "custom-class",
                 style: {
                   marginTop: "10vh",
                   marginLeft: "15vw",
                 },
                 duration: 3.5,
               });
       
       }
       
       
      
      else if (values.storeManagerMobile === values.storeManagerPhone) {
        //   Modal.confirm({
        // 	title: "Confirm",
        // 	icon: <ExclamationCircleOutlined />,
        // 	content:
        // 	  "Please provide different contact number and alternate contact number for seller details.",
        // 	okText: "Ok",
        //   });
        // currentValidationSchema.setFieldError('mobilenumber','Please provide different contact number')
        //   document.body.scrollTop = 0;
        storeformik.setFieldError(
          "storeManagerPhone",
          "Please provide different contact number"
        );
      }
      else if (props.newSignedLogoUrl !== null) {
        if (mapCoordinates && mapaddress) {
          props.Delete_Logo(
            props.retailerDetails &&
            props.retailerDetails.data &&
            props.retailerDetails.data.retailerdetails &&
            props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl
          );
          client
            .mutate({
              mutation: EDIT_STORE_MUTATION,
              variables: {
                storeid:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0].id,
                storeName: values.storeName,
                fssaiNo: values.fssaiNo,
                storeLocation: mapaddress,
                storeDescription: values.storeDescription,
                storeLogoUrl:
                  props.newSignedLogoUrl !== ""
                    ? props.newSignedLogoUrl
                    : props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .storeLogoUrl,
                storeCoordinates: {
                  coordinates: [
                    Number(mapCoordinates.lng),
                    Number(mapCoordinates.lat),
                  ],
                },
                storeManagerFirstName: values.storeManagerFirstName,
                storeManagerLastName: values.storeManagerLastName,
                storeManagerMobile: values.storeManagerMobile,
                storeManagerPhone:
                  values.storeManagerPhone === ""
                    ? " "
                    : values.storeManagerPhone,
                deliveryType:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .deliveryType,
                // deliveryMaxDistance:Number(
                // 	props.retailerDetails&&props.retailerDetails.data&&
                // props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryMaxDistance),
                // deliveryCharge: deliveryRate,
                paymentSupported:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .paymentSupported,
                deliveryDays: deliverDay,
                bankDetails: {
                  bankAccountNumber: values.bankAccountNumber,
                  accountHolderName: values.accountHolderName,
                  IFSCcode: values.IFSCcode,
                },
                businessTypeId: props.businessId,
                sameDayDelivery:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .sameDayDelivery
                    : true,
                deliveryAfter:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .deliveryAfter
                    : null,
              },
              context: {
                headers: {
                  Authorization: "Bearer " + token,
                },
              },
            })
            .then((res) => {
              if (res.data) {
                setEdit(!edit);
                setOpen(true);
                props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
              } else {
                setError(true);
              }
            })
            .catch((err) => {
              setNet(true);
              // setNetmsg(err)
            });

          setFormSubmitted(true)
          message.success({
            content: "Saved changes successfully",
            className: "custom-class",
            style: {
              marginTop: "10vh",
              marginLeft: "15vw",
            },
            duration: 3.5,
          });
          setTimeout(() => {
            nav('/home/Profile')
          }, 4000);

        } else {
          props.Delete_Logo(
            props.retailerDetails &&
            props.retailerDetails.data &&
            props.retailerDetails.data.retailerdetails &&
            props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl
          );
          client
            .mutate({
              mutation: EDIT_STORE_MUTATION,
              variables: {
                storeid:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0].id,
                storeName: values.storeName,
                fssaiNo: values.fssaiNo,
                storeLocation:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .storeLocation,
                storeDescription: values.storeDescription,
                storeLogoUrl:
                  props.newSignedLogoUrl !== ""
                    ? props.newSignedLogoUrl
                    : props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .storeLogoUrl,
                storeCoordinates: {
                  coordinates: [
                    Number(storeCoordinates.coordinates[0]),
                    Number(storeCoordinates.coordinates[1]),
                  ],
                },
                storeManagerFirstName: values.storeManagerFirstName,
                storeManagerLastName: values.storeManagerLastName,
                storeManagerMobile: values.storeManagerMobile,
                storeManagerPhone:
                  values.storeManagerPhone === ""
                    ? " "
                    : values.storeManagerPhone,
                deliveryType:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .deliveryType,
                // deliveryMaxDistance:Number(
                // 	props.retailerDetails&&props.retailerDetails.data&&
                // props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryMaxDistance),
                // deliveryCharge: deliveryRate,
                paymentSupported:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .paymentSupported,
                deliveryDays: deliverDay,
                bankDetails: {
                  bankAccountNumber: values.bankAccountNumber,
                  accountHolderName: values.accountHolderName,
                  IFSCcode: values.IFSCcode,
                },
                businessTypeId: props.businessId,
                sameDayDelivery:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .sameDayDelivery
                    : true,
                deliveryAfter:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .deliveryAfter
                    : null,
              },
              context: {
                headers: {
                  Authorization: "Bearer " + token,
                },
              },
            })
            .then((res) => {
              if (res.data) {
                setEdit(!edit);
                setOpen(true);
                props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
              } else {
                setError(true);
              }
            })
            .catch((err) => {
              setNet(true);
              // setNetmsg(err)
            });

          setFormSubmitted(true)
          message.success({
            content: "Saved changes successfully",
            className: "custom-class",
            style: {
              marginTop: "10vh",
              marginLeft: "15vw",
            },
            duration: 3.5,
          });
          setTimeout(() => {
            nav('/home/Profile')
          }, 4000);

        }

        //
      }
      else {

        if (mapCoordinates && mapaddress) {

          client
            .mutate({
              mutation: EDIT_STORE_MUTATION,
              variables: {
                storeid:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0].id,
                storeName: values.storeName,
                fssaiNo: values.fssaiNo,
                storeLocation: mapaddress,
                storeDescription: values.storeDescription,
                storeLogoUrl:
                  props.newSignedUrl !== ""
                    ? props.newSignedUrl
                    : props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .storeLogoUrl,
                storeCoordinates: {
                  coordinates: [
                    Number(mapCoordinates.lng),
                    Number(mapCoordinates.lat),
                  ],
                },
                storeManagerFirstName: values.storeManagerFirstName,
                storeManagerLastName: values.storeManagerLastName,
                storeManagerMobile: values.storeManagerMobile,
                storeManagerPhone:
                  values.storeManagerPhone === ""
                    ? " "
                    : values.storeManagerPhone,
                deliveryType:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .deliveryType,
                // deliveryMaxDistance:Number(
                // 	props.retailerDetails&&props.retailerDetails.data&&
                // props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryMaxDistance),
                // deliveryCharge: deliveryRate,
                paymentSupported:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .paymentSupported,
                deliveryDays: deliverDay,
                bankDetails: {
                  bankAccountNumber: values.bankAccountNumber,
                  accountHolderName: values.accountHolderName,
                  IFSCcode: values.IFSCcode,
                },
                businessTypeId: props.businessId,
                sameDayDelivery:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .sameDayDelivery
                    : true,
                deliveryAfter:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .deliveryAfter
                    : null,
              },
              context: {
                headers: {
                  Authorization: "Bearer " + token,
                },
              },
            })
            .then((res) => {
              if (res.data) {
                setEdit(!edit);
                setOpen(true);
                props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
              } else {
                setError(true);
              }
            })
            .catch((err) => {
              setNet(true);
              // setNetmsg(err)
            });





          props.getNewLocation({
            address: mapaddress,
            details: mapDetails,
            coordinates: mapCoordinates,
          });


          setFormSubmitted(true)
          message.success({
            content: "Saved changes successfully",
            className: "custom-class",
            style: {
              marginTop: "10vh",
              marginLeft: "15vw",
            },
            duration: 3.5,
          });
          setTimeout(() => {
            nav('/home/Profile')
          }, 4000);


        } else {

          client
            .mutate({
              mutation: EDIT_STORE_MUTATION,
              variables: {
                storeid:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0].id,
                storeName: values.storeName,
                fssaiNo: values.fssaiNo,
                storeLocation: props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .storeLocation,
                storeDescription: values.storeDescription,
                storeLogoUrl:
                  props.newSignedUrl !== ""
                    ? props.newSignedUrl
                    : props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .storeLogoUrl,
                storeCoordinates: {
                  coordinates: [
                    Number(storeCoordinates.coordinates[0]),
                    Number(storeCoordinates.coordinates[1]),
                  ],
                },
                storeManagerFirstName: values.storeManagerFirstName,
                storeManagerLastName: values.storeManagerLastName,
                storeManagerMobile: values.storeManagerMobile,
                storeManagerPhone:
                  values.storeManagerPhone === ""
                    ? " "
                    : values.storeManagerPhone,
                deliveryType:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .deliveryType,
                // deliveryMaxDistance:Number(
                // 	props.retailerDetails&&props.retailerDetails.data&&
                // props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryMaxDistance),
                // deliveryCharge: deliveryRate,
                paymentSupported:
                  props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.stores[0]
                    .paymentSupported,
                deliveryDays: deliverDay,
                bankDetails: {
                  bankAccountNumber: values.bankAccountNumber,
                  accountHolderName: values.accountHolderName,
                  IFSCcode: values.IFSCcode,
                },
                businessTypeId: props.businessId,
                sameDayDelivery:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .sameDayDelivery
                    : true,
                deliveryAfter:
                  values.buisnessType === "Home Buisness"
                    ? props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0]
                      .deliveryAfter
                    : null,
              },
              context: {
                headers: {
                  Authorization: "Bearer " + token,
                },
              },
            })
            .then((res) => {
              if (res.data) {
                setEdit(!edit);
                setOpen(true);
                props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
              } else {
                setError(true);
              }
            })
            .catch((err) => {
              setNet(true);
              // setNetmsg(err)
            });


          setFormSubmitted(true)
          message.success({
            content: "Saved changes successfully",
            className: "custom-class",
            style: {
              marginTop: "10vh",
              marginLeft: "15vw",
            },
            duration: 3.5,
          });
          setTimeout(() => {
            nav('/home/Profile')
          }, 4000);



          // props.getNewLocation({
          //   address: mapaddress,
          //   details: mapDetails,
          //   coordinates: mapCoordinates,
          // });
        }
      }

    },
  });

  const handleChange = (event, option) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedBusinessTypes([...selectedBusinessTypes, option]);
    } else {
      setSelectedBusinessTypes(
        selectedBusinessTypes.filter((item) => item.id !== option.id)
      );
    }
    const selectedIds = selectedBusinessTypes.map((type) => type.id);
    props.setBusinessType(selectedIds);
  };

  const handleRemove = (value) => {
    setSelectedBusinessTypes(
      selectedBusinessTypes.filter((item) => item.id !== value.id)
    );
    const selectedIds = selectedBusinessTypes
      .filter((item) => item.id !== value.id)
      .map((type) => type.id);
    props.setBusinessType(selectedIds);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down('sm'));

  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);


  
  useEffect(() => {
    if (!storeformik.isSubmitting) return;
    if (Object.keys(storeformik.errors).length > 0) {
      document.getElementsByName(Object.keys(storeformik.errors)[0])[0].focus();
    }
  }, [storeformik]);

  return (
    <div style={{ background: "rgb(244, 245, 249)", height: "100%" }}>
      <div className="profileLinker">
        <Typography
          onClick={handleProfileroute}
          style={{ cursor: "pointer" }}
          fontSize={HeaderNavigationFontSize_20}
          className="storeUpdateFontFamily"
        >
          Profile
        </Typography>
        <Typography
          fontSize={HeaderNavigationFontSize_20}
          className="storeUpdateFontFamily"
        >
          /
        </Typography>
        <Typography
          style={{ color: "#85BE49" }}
          fontSize={HeaderNavigationFontSize_20}
          className="storeUpdateFontFamily"
        >
          Update My Store
        </Typography>
      </div>
      <Typography
        className="ProfileSecond"
        fontSize={HeaderNavigationFontSize_20}
      >
        Update My Store
      </Typography>
      <form onSubmit={storeformik.handleSubmit}>
        <div className="updateProfileProfileImage1" >
          <div className="profile">
            <div
              style={{
                width: matches ? "80px" : "100px",
                height: matches ? "80px" : "100px",
                borderRadius: "50%",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {storeImageTemp1 ? (<img
                src={
                  StoreEdit
                }
                alt="user profile image"
                style={{
                  width: matches ? "80px" : "100px",
                  height: matches ? "80px" : "100px",
                  borderRadius: "50%",
                  border: "3px solid #72B01D",
                  cursor: "pointer"
                }}
                onClick={handleOpen6}
              />) : (props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLogoUrl ? <img
                src={
                  props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLogoUrl
                }
                alt="user profile image"
                style={{
                  width: matches ? "80px" : "100px",
                  height: matches ? "80px" : "100px",
                  borderRadius: "50%",
                  border: "3px solid #72B01D",
                  cursor: "pointer"
                }}
                onClick={handleOpen7}
              /> : <img
                src={
                  dummy
                }
                alt="user profile image"
                style={{
                  width: matches ? "80px" : "100px",
                  height: matches ? "80px" : "100px",
                  borderRadius: "50%",
                  border: "3px solid #72B01D",
                  cursor: "pointer"
                }}
                onClick={handleOpen8}
              />)}
              <div
                className="svg-icon"
                style={{
                  width: matches ? "20px" : "29px",
                  height: matches ? "21px" : "30px",
                }}
              >
                <img src={EditIcon1} alt="edit pen" onClick={editStoreLogo} style={{ cursor: "pointer" }} />
                {openImageEdit ? <ImageEditWindow {...props} handleImageEdit={handleImageEdit} open3={open3} setOpen3={setOpen3} StoreEdit={StoreEdit} setStoreEdit={setStoreEdit} style={{ width: "100%" }} /> : null}

              </div>
            </div>
          </div>
          <Typography className="ProfileName" fontSize={HeaderFontSize_24}>
            <p>
              {
                props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeName
              }
            </p>
          </Typography>
        </div>

        <Modal
          open={open6}
          style={modalStyle}
          onClose={handleClose6}

        >
          <img
            src={
              StoreEdit
                ? StoreEdit
                : null
            }
            style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
            // onClick={() => setOpen5(false)}
            onClick={handleOpen6}
          ></img>

        </Modal>
        <Modal
          open={open7}
          style={modalStyle}
          onClose={handleClose7}

        >
          <img
            src={
              props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLogoUrl
                ? props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeLogoUrl
                : null
            }
            style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
            // onClick={() => setOpen5(false)}
            onClick={handleOpen7}
          ></img>

        </Modal>
        <Modal
          open={open8}
          style={modalStyle}
          onClose={handleClose8}

        >
          <img
            src={
              dummy
                ? dummy
                : null
            }
            style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
            // onClick={() => setOpen5(false)}
            onClick={handleOpen8}
          ></img>

        </Modal>






        <div className="firstContainerStore">
          <div className="StoreNameStyle">
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Store Name <span className="redstart">*</span>
            </Typography>

            <TextField
              variant="outlined"
              sx={{
                width: '100%',

                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#85BE49',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#85BE49',
                  },
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'white',
                  borderRadius: "10px"
                },
              }}
              InputProps={{
                sx: {
                  fontSize: matches && '13px',
                },
              }}
              size={matches ? "small" : "normal"}
              name="storeName"
              onChange={storeformik.handleChange}
              value={storeformik.values.storeName}
              error={
                storeformik.touched.storeName &&
                Boolean(storeformik.errors.storeName)
              }
              helperText={
                storeformik.touched.storeName && storeformik.errors.storeName
              }
            ></TextField>
          </div>

          <div className="StoreNameStyle">
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Store Description <span className="redstart">*</span>
            </Typography>
            <TextField
              // background="white"
              multiline={true}
              rows="3"
              variant="outlined"
              sx={{
                width: '100%',

                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#85BE49',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#85BE49',
                  },
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'white',
                  borderRadius: "10px"
                },
              }}
              InputProps={{
                sx: {
                  fontSize: matches && '13px',
                  background: "white"
                },
              }}
              size={matches ? "small" : "normal"}
              name="storeDescription"
              onChange={storeformik.handleChange}
              value={storeformik.values.storeDescription}

              error={storeformik.touched.storeDescription && Boolean(storeformik.errors.storeDescription)}
              helperText={storeformik.touched.storeDescription && storeformik.errors.storeDescription}

            ></TextField>
          </div>

          <div className="StoreNameStyle">
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Business Type <span className="redstart">*</span>
            </Typography>
            <div>
              <BusinessType props={props} businessType={businessType} setBusinessType={setBusinessType} />
            </div>

            {/* {start} */}


            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minWidth: 120,
                width: props.width,
                height: props.height,
                background: props.background,
                borderRadius: props.borderRadius,
                border: props.border,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div style={{ background: "white" }}>
                  <FormControl
                    fullWidth
                    sx={{ border: "1px solid #85BE49", borderRadius: "10px" }}
                    size={props.size}
                  >
                    <Select
                      value={selectedBusinessTypes.map((item) => item.id)}
                      // onChange={handleChange}
                      multiple
                      renderValue={(selected) => (
                        <div>
                          {selected.map((value) => {
                            const selectedOption = options.find(
                              (option) => option.value === value
                            );
                            return <span key={value}></span>;
                          })}
                        </div>
                      )}
                    >
                      {props?.BusinessType?.data?.businessTypeListing?.map(
                        (bussinessType) => (
                          <MenuItem
                            key={bussinessType.id}
                            value={bussinessType.id}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedBusinessTypes.includes(
                                    bussinessType.id
                                  )}
                                />
                              }
                              label={bussinessType.name}
                              onChange={(event) =>
                                handleChange(event, bussinessType)
                              }
                            />
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>


                <div>
                  <Box
                    mt={2}
                    style={{ display: "flex", gap: "20px", marginTop: "0px" }}
                  >
                    {selectedBusinessTypes.map((selectedType) => (
                      <div
                        style={{ display: "flex", gap: "20px" }}
                        key={selectedType.value}
                      >
                        <CustomButton
                          variant="contained"
                          color={props.buttonColor}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {
                              props?.BusinessType?.data?.businessTypeListing.find(
                                (option) => option.id === selectedType.id
                              )?.name
                            }
                            <IconButton
                              onClick={() => handleRemove(selectedType)}
                            >
                              <CloseIcon style={{ color: "white" }} />
                            </IconButton>
                          </div>
                        </CustomButton>
                      </div>
                    ))}
                  </Box>


                  
                </div>



              </div>
            </Box> */}



          </div>
          {/* {start} */}

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>

              {/* start  */}
                
 <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    Store Manager first Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    value={
                      storeformik.values.storeManagerFirstName &&
                      storeformik.values.storeManagerFirstName
                    }
                    name="storeManagerFirstName"
                    onChange={storeformik.handleChange}
                    error={
                      storeformik.touched.storeManagerFirstName &&
                      Boolean(storeformik.errors.storeManagerFirstName)
                    }
                    helperText={
                      storeformik.touched.storeManagerFirstName &&
                      storeformik.errors.storeManagerFirstName
                    }
                  ></TextField>
                </div>
              </Grid>

              

 <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: smmatch && "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    Store Manager Last Name
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    value={
                      storeformik.values.storeManagerLastName &&
                      storeformik.values.storeManagerLastName
                    }
                    name="storeManagerLastName"
                    onChange={storeformik.handleChange}
                    error={
                      storeformik.touched.storeManagerLastName &&
                      Boolean(storeformik.errors.storeManagerLastName)
                    }
                    helperText={
                      storeformik.touched.storeManagerLastName &&
                      storeformik.errors.storeManagerLastName
                    }
                  ></TextField>
                </div>
              </Grid>



                {/* end*/}
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    Store Manager Phone Number{" "}
                    <span className="redstart">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                        background: "white"
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={indiaImage}
                            alt="India flag"
                            style={{ marginRight: "8px" }}
                          />
                          <div style={{ color: "black" }}>+91 |</div>
                        </InputAdornment>
                      ),
                    }}
                    name="storeManagerMobile"
                    value={storeformik.values.storeManagerMobile}
                    onChange={storeformik.handleChange}
                    error={
                      storeformik.touched.storeManagerMobile &&
                      Boolean(storeformik.errors.storeManagerMobile)
                    }
                    helperText={
                      storeformik.touched.storeManagerMobile &&
                      storeformik.errors.storeManagerMobile
                    }
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: smmatch && "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    Store Manager Alternate phone Number

                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                        background: "white"
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={indiaImage}
                            alt="India flag"
                            style={{ marginRight: "8px" }}
                          />
                          <div style={{ color: "black" }}>+91 |</div>
                        </InputAdornment>
                      ),
                    }}
                    name="storeManagerPhone"
                    onChange={storeformik.handleChange}
                    value={
                      storeformik.values.storeManagerPhone &&
                      storeformik.values.storeManagerPhone
                    }
                    error={
                      storeformik.touched.storeManagerPhone &&
                      Boolean(storeformik.errors.storeManagerPhone)
                    }
                    helperText={
                      storeformik.touched.storeManagerPhone &&
                      storeformik.errors.storeManagerPhone
                    }
                  ></TextField>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    Bank Account Number <span className="redstart">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    value={
                      storeformik.values.bankAccountNumber &&
                      storeformik.values.bankAccountNumber
                    }
                    name="bankAccountNumber"
                    onChange={storeformik.handleChange}
                    error={
                      storeformik.touched.bankAccountNumber &&
                      Boolean(storeformik.errors.bankAccountNumber)
                    }
                    helperText={
                      storeformik.touched.bankAccountNumber &&
                      storeformik.errors.bankAccountNumber
                    }
                  ></TextField>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: smmatch && "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    Account Holder Name <span className="redstart">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    name="accountHolderName"
                    onChange={storeformik.handleChange}
                    value={
                      storeformik.values.accountHolderName &&
                      storeformik.values.accountHolderName
                    }
                    error={
                      storeformik.touched.accountHolderName &&
                      Boolean(storeformik.errors.accountHolderName)
                    }
                    helperText={
                      storeformik.touched.accountHolderName &&
                      storeformik.errors.accountHolderName
                    }
                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    FSSAI Number <span className="redstart">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    value={
                      storeformik.values.fssaiNo && storeformik.values.fssaiNo
                    }
                    name="fssaiNo"
                    onChange={storeformik.handleChange}

                    error={
                      storeformik.touched.fssaiNo &&
                      Boolean(storeformik.errors.fssaiNo)
                    }
                    helperText={
                      storeformik.touched.fssaiNo &&
                      storeformik.errors.fssaiNo
                    }

                  ></TextField>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: smmatch && "0px" }}>
                <div className="Namestyles">
                  <Typography
                    className="StoreLabelStyles"
                    fontSize={contentFontSize_16}
                  >
                    IFSC Code <span className="redstart">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    sx={{
                      width: '100%',

                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49',
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius: "10px"
                      },
                    }}
                    InputProps={{
                      sx: {
                        fontSize: matches && '13px',
                      },
                    }}
                    size={matches ? "small" : "normal"}
                    value={
                      storeformik.values.IFSCcode && storeformik.values.IFSCcode
                    }
                    name="IFSCcode"
                    onChange={storeformik.handleChange}

                    error={
                      storeformik.touched.IFSCcode &&
                      Boolean(storeformik.errors.IFSCcode)
                    }
                    helperText={
                      storeformik.touched.IFSCcode &&
                      storeformik.errors.IFSCcode
                    }
                  ></TextField>
                </div>
              </Grid>
            </Grid>
          </Box>
          {/* {end} */}
          {/* <div className="StoreNameStyle">
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Store Name
            </Typography>
            <TextField
             
           
              variant="outlined"
              sx={{
                width: '100%',
            
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#85BE49', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#85BE49', 
                  },
                },
                '& .MuiInputBase-input': {
                  // backgroundColor: 'white',
                  borderRadius:"10px" 
                },
              }}
              InputProps={{
               sx: {
                 fontSize: matches && '13px', 
                 background: 'rgba(133, 190, 73, 0.35)'
               },
               endAdornment: (
                <InputAdornment position="start">
                  <img
                  src={StoreLocation4}
                  alt="Store Location"
                  style={{ marginRight: "8px",}}
                />
                
                </InputAdornment>
              ),
           }}
               size={matches ? "small" : "normal"}
              value={
                props.retailerDetails &&
                props.retailerDetails.data &&
                props.retailerDetails.data.retailerdetails &&
                props.retailerDetails.data.retailerdetails.stores &&
                props.retailerDetails.data.retailerdetails.stores[0]
                  .storeLocation
              }
           
            ></TextField>
          </div> */}

          <div>
            <EditLocation props={props} setMapaddress={setMapaddress} setMapCoordinates={setMapCoordinates} setMapDetails={setMapDetails} mapaddress={mapaddress} />
          </div>

          <div className="StoreNameStyle">
            <div
              style={{
                border: "1px solid rgba(133, 190, 73, 0.35)",
                borderRadius: "9px",
              }}
            >
              <EditGoogleMap props={props} mapCoordinates={mapCoordinates} setMapCoordinates={setMapCoordinates} />
            </div>
          </div>
          <div className="Namestyles">
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Store Coordinates <span className="redstart">*</span>
            </Typography>
            <MapEditField props={props} mapCoordinates={mapCoordinates} setMapCoordinates={setMapCoordinates} mapEditModal={mapEditModal} />
          </div>

          <Typography
            className="StoreLabelStyles"
            fontSize={contentFontSize_16}
          >
           Uploaded Documents
          </Typography>

          <div className="footerUpdateProfile">
            {props.retailerDetails &&
              props.retailerDetails.data &&
              props.retailerDetails.data.retailerdetails &&
              props.retailerDetails.data.retailerdetails.stores[0] &&
              props.retailerDetails.data.retailerdetails.stores[0].fssaiUrl && (<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}><Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                FSSAI 
              </Typography>



                <div className="aadhaarStyle" >
                  <img
                    src={
                      props.retailerDetails &&
                        props.retailerDetails.data &&
                        props.retailerDetails.data.retailerdetails &&
                        props.retailerDetails.data.retailerdetails.stores[0] &&
                        props.retailerDetails.data.retailerdetails.stores[0].fssaiUrl

                        ? props.retailerDetails.data.retailerdetails.stores[0].fssaiUrl
                        : null
                    }
                    style={{ width: matches ? "80px" : "100px", height: matches ? "80px" : "100px", cursor: "pointer" }}

                    onClick={() => setOpen2(true)}
                  ></img>
                </div></div>)
            }
            <Modal
              open={open2}
              style={modalStyle}
              onClose={handleClose2}

            >
              <img
                src={
                  props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0] &&
                    props.retailerDetails.data.retailerdetails.stores[0].fssaiUrl

                    ? props.retailerDetails.data.retailerdetails.stores[0].fssaiUrl

                    : null
                }

                style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
                onClick={handleOpen2}
              ></img>

            </Modal>

            {props.retailerDetails &&
              props.retailerDetails.data &&
              props.retailerDetails.data.retailerdetails &&
              props.retailerDetails.data.retailerdetails.stores[0] && props.retailerDetails.data.retailerdetails.stores[0].licenseUrl && (<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}> <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                License 
              </Typography>

                <div className="aadhaarStyle" >
                  <img
                    src={
                      props.retailerDetails &&
                        props.retailerDetails.data &&
                        props.retailerDetails.data.retailerdetails &&
                        props.retailerDetails.data.retailerdetails.stores[0] &&
                        props.retailerDetails.data.retailerdetails.stores[0].licenseUrl

                        ? props.retailerDetails.data.retailerdetails.stores[0].licenseUrl
                        : null
                    }
                    style={{ width: matches ? "80px" : "100px", height: matches ? "80px" : "100px", cursor: "pointer" }}

                    onClick={() => setOpen4(true)}
                  ></img>
                </div></div>)
            }
            <Modal
              open={open4}
              style={modalStyle}
              onClose={handleClose4}

            >
              <img
                src={
                  props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0] &&
                    props.retailerDetails.data.retailerdetails.stores[0].licenseUrl

                    ? props.retailerDetails.data.retailerdetails.stores[0].licenseUrl

                    : null
                }
                style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
                onClick={handleOpen4}
              ></img>

            </Modal>
            {props.retailerDetails &&
              props.retailerDetails.data &&
              props.retailerDetails.data.retailerdetails &&
              props.retailerDetails.data.retailerdetails.GSTurl && (<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}> <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                GST 
              </Typography>

                <div className="aadhaarStyle" >
                  <img
                    src={
                      props.retailerDetails &&
                        props.retailerDetails.data &&
                        props.retailerDetails.data.retailerdetails &&
                        props.retailerDetails.data.retailerdetails.GSTurl

                        ? props.retailerDetails.data.retailerdetails.GSTurl
                        : null
                    }
                    style={{ width: matches ? "80px" : "100px", height: matches ? "80px" : "100px", cursor: "pointer" }}

                    onClick={() => setOpen9(true)}
                  ></img>
                </div></div>)
            }
            <Modal
              open={open9}
              style={modalStyle}
              onClose={handleClose9}

            >
              <img
                src={
                  props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.GSTurl

                    ? props.retailerDetails.data.retailerdetails.GSTurl

                    : null
                }
                style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
                onClick={handleOpen9}
              ></img>

            </Modal>
          </div>








          <div style={{ textAlign: "center", marginTop: "25px" }}>
            <CustomButton width="40%" borderRadius="10px" type="submit"
              matchPadding={true}
              padding="10px 0px"
              background={formSubmitted ? "gray" : '#659B1B'}
              disabled={formSubmitted && "disabled"}>
              <Typography
                fontSize={ButtonFontSize_18}
                className="StoreUpdateButtonStyle"
              >
                Save Changes
              </Typography>
            </CustomButton>
          </div>
        </div>
      </form>
    </div>
  );
}
