// import {React,useState,useEffect} from "react";
// import { Modal } from "antd";
// import IconButton from "@mui/material/IconButton";
// import PhotoCamera from "@mui/icons-material/PhotoCamera";
// import { green } from "@mui/material/colors";
// import DeleteIcon from "@mui/icons-material/Delete";
// import CropperModel from "../cropper-modal/CropperModel";
// import Cards from '../Cards/index'

// function FileInputDemo(props) {
//   useEffect(() => {
//     const imageUrl = localStorage.getItem("imageUrl");
//     if (imageUrl === "null" || imageUrl === null) {
//       setResizedImage(null);
//     } else {
//       setResizedImage(imageUrl);
//     }
//   }, [localStorage.getItem("imageUrl")]);

//   const [file, setFile] = useState(null);
//   const [resizedImage, setResizedImage] = useState(null);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [hover, setHover] = useState(false);
//   const [fileList, setFileList] = useState([]);

//   const onHover = () => {
//     setHover(true);
//   };

//   const onLeave = () => {
//     setHover(false);
//   };

//   const handleCancel = () => setPreviewOpen(false);

//   const handleDelete = () => {
//     setFile(null);
//     localStorage.setItem("imageUrl", null);
//     setResizedImage(null);
//   };

//   const handlePreview = () => {
//     setPreviewOpen(true);
//   };

//   const onFileInputChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const maxSize = 1 * 1024 * 1024;
//       if (file.size > maxSize) {
//         alert("File size exceeds 1MB. Please select a smaller file.");
//         return;
//       }
//       setFile(file);
//     }
//     e.target.value = null;
//   };

//   return (
//     <>
//       <div style={{ display: "flex",gap:"10px", justifyContent:"space-between"}}>
//         {resizedImage === null && (
//           <span>
//             <IconButton
//               color="primary"
//               aria-label="upload picture"
//               component="label"
//             >
//               <input
//                 hidden
//                 accept="image/*"
//                 type="file"
//                 onChange={onFileInputChange}
//               />
//               <PhotoCamera sx={{ color: green[500], fontSize: 40 }} />
//             </IconButton>
//           </span>
//         )}
//         <span>
//           {resizedImage && (
//             <>
//               <div
//                 onMouseEnter={onHover}
//                 onMouseLeave={onLeave}
//                 role="button"
//                 onClick={handlePreview}
//               >
//                 <img
//                   alt="example"
//                   src={resizedImage}
//                   style={{ width: 100 }}
//                 />
//               </div>
//               <div role="button" onClick={handleDelete}>
//                 <DeleteIcon />
//               </div>
//             </>
//           )}
//         </span>
//         <Modal
//           visible={previewOpen}
//           footer={null}
//           onCancel={handleCancel}
//           maskClosable={true}
//         >
//           <img alt="example" style={{ width: "100%" }} src={resizedImage} />
//         </Modal>
//         <div><Cards/></div>
//       </div>
//       <div>
//         <CropperModel
//           file={file}
//           onConfirm={(croppedFile) => {
//             props.uploadImage({ files: croppedFile, fileName: props.fileName });
//             props.uploadThumbnail({
//               files: croppedFile,
//               fileName: props.fileName,
//             });
//             setResizedImage(window.URL.createObjectURL(croppedFile));
//             setPreviewOpen(false);
//           }}
//           onCompleted={() => setFile(null)}
//         />
//       </div>
//     </>
//   );
// }

// export default FileInputDemo;
import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { green } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import CropperModel from "../cropper-modal/CropperModel";
import Cards from '../Cards/index';
import { Upload, Button } from "antd";
import { message } from "antd";

function FileInputDemo(props) {
  const [file, setFile] = useState(null);
  const [resizedImage, setResizedImage] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [fileList, setFileList] = useState([]);


  useEffect(()=>{
    localStorage.removeItem("imageUrl",null)
  },[])

  useEffect(() => {
    const imageUrl = localStorage.getItem("imageUrl");
    if (imageUrl) {
      setResizedImage(imageUrl);
    } else {
      setResizedImage(null);
    }
  }, [resizedImage]); 

  const onHover = () => setHover(true);
  const onLeave = () => setHover(false);
  const handleCancel = () => setPreviewOpen(false);

  const handleDelete = () => {
    setFile(null);
    localStorage.removeItem("imageUrl"); 
    setResizedImage(null);
  };

  const handlePreview = () => setPreviewOpen(true);

  const onFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const maxSize = 1 * 1024 * 1024;
      const validFileTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!validFileTypes.includes(selectedFile.type)) {
        alert("Supported image formats should be JPG, PNG, and JPEG.");
        return;
      }
      if (selectedFile.size > maxSize) {
        message.error("File size exceeds 1MB. Please select a smaller file.");
        return;
      }
      setFile(selectedFile);
      e.target.value = null; 
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
        {!resizedImage && (
          <span>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                accept=".jpg,.jpeg,.png" 
                type="file"
                onChange={onFileInputChange}
              />
              <PhotoCamera sx={{ color: green[500], fontSize: 40 }} />
            </IconButton>
          </span>
        )}
        <span>
          {resizedImage && (
            <>
              <div
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
                role="button"
                onClick={handlePreview}
              >
                <img alt="example" src={resizedImage} style={{ width: 100 }} />
              </div>
              <div role="button" onClick={handleDelete}>
                <DeleteIcon />
              </div>
            </>
          )}
        </span>
        <Modal
          open={previewOpen} 
          footer={null}
          onCancel={handleCancel}
          maskClosable={true}
        >
          <img alt="example" style={{ width: "100%" }} src={resizedImage} />
        </Modal>
        <div><Cards /></div>
      </div>
      <div>
        <CropperModel
          file={file}
          onConfirm={(croppedFile) => {
            const croppedFileUrl = window.URL.createObjectURL(croppedFile);
            props.uploadImage({ files: croppedFile, fileName: props.fileName });
            props.uploadThumbnail({ files: croppedFile, fileName: props.fileName });
            setResizedImage(croppedFileUrl);
            localStorage.setItem("imageUrl", croppedFileUrl);
            message.success({
              content: "Image Uploaded successfully",
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
              duration: 2.5,
            });
            setPreviewOpen(false);
          }}
          onCompleted={() => setFile(null)}
        />
      </div>
    </>
  );
}

export default FileInputDemo;
