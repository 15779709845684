import React from 'react';
import AppBar from '@mui/material/AppBar';
import { Toolbar } from '@mui/material';
import Logo from "./Logo.png";
// import { useStyles } from './style';

export default function CheckoutHeader(props) {
  // const classes = useStyles();


  return (
    <AppBar position="fixed"  
    // className={classes.appBar}
    >
      <Toolbar style={{justifyContent:'center'}}>
          <img style={{height:'58px',width:'152px',cursor:'pointer'}} src={Logo} alt="SHOP.png"  />
        </Toolbar>
    </AppBar>
  );
}
