import React, { useState , useEffect } from "react";
// import ReactDOM from 'react-dom';
import ImgCrop from "antd-img-crop";
import { Upload, Modal } from "antd";
import {Row,Col} from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {IMAGE_UPLOAD} from './Query';
import client from "../../Configurations/apollo";
// import 'antd/dist/antd.css';
import "./index.css";
// import { BiJoystick } from "react-icons/bi";


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const MultipleUploader = (props) => {
 
  useEffect(() => {
    setFileList(props.product&&props.product.data&&
      props.product&&props.product.data&&
      props.product.data.salesproducts&&props.product.data.salesproducts.image&&
      props.product.data.salesproducts&&props.product.data.salesproducts.image.secondary!==null&&
      props.product.data.salesproducts.image.secondary.length!==0?
     ( props.product&&props.product.data&&
      props.product&&props.product.data&&
      props.product.data.salesproducts&&props.product.data.salesproducts.image&&
      props.product.data.salesproducts.image.secondary.map((S,index)=>
    (  {
        id:index,
        url: `${S}`,
      })
      
      ))
    :
    ([]))
  },[props.product]);

  const [fileList, setFileList] = useState([]);


  const [previewImage, setPreviewImage] = useState();
  const [previewVisible, setPreviewVisible] = useState();
  const [multi,setMulti]=useState()
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setMulti(true)
    props.MultipleImage();
    if(newFileList.length>fileList.length)
    {
    client
      .mutate({
        mutation: IMAGE_UPLOAD,
        variables: {
          file: newFileList[(newFileList.length)-1].originFileObj,
          type: 2,
          size: 512,
          filename: `${props.ProductDetailsData&&props.ProductDetailsData.data&&
            props.ProductDetailsData.data.salesproducts&&
            props.ProductDetailsData.data.salesproducts.productname&&
            props.ProductDetailsData.data.salesproducts.productname}${Math.floor(Math.random() * (999 - 100 + 1) + 100)}.jpg`,
          storeid: localStorage.getItem('storeid')
        },
      })
      .then((res) => {
          newFileList.pop()
          newFileList.push({ url:res.data&&res.data.imageEditAndUpload&&res.data.imageEditAndUpload.Location})
          setFileList(newFileList)
          props.setMultipleImage(newFileList)
      })
    }
   };
   const handleRemove = ( newFileList) => {
    fileList.map((f,index)=>{
      if(f.url===newFileList.url)
      {
        fileList.splice(index,1)
      }
    })
    props.setMultipleImage(fileList)
  };
  

  const dummyRequest = ({ file, onSuccess }) => {
    
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
     <Row>
    <Col>
    <ImgCrop
        modalTitle="product"
        modalWidth={700}
        modalHeight={700}
        aspect={1 / 1}
        rotate={false}
        zoom={false}
        grid
      >
        <Upload
          maxCount={4}
          customRequest={dummyRequest}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onRemove={handleRemove}
          onPreview={handlePreview}
          accept="image/*"
        >
          {fileList.length < 4 && "Click here to choose the image"}
        </Upload>
      </ImgCrop>
      </Col>
      </Row>
      <Row>
<Col>
      <Modal
        visible={previewVisible}
        title={'Image'}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      </Col>
      </Row>
    </>
  );
};
export default MultipleUploader;
