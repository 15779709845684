// import React, { useEffect, useState } from 'react';
// import Chart from 'react-apexcharts';
// import './MyChartComponent.css';
// import ApexCharts from 'apexcharts';

// const MyChartComponent = ({ upXlmatch, xlmatch, lgmatch, mdmatch, smmatch, xsmatch,uplgmatch,props}) => {

//     console.log("this is the myChartcomponent",props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Monday)
//     const [chartOptions, setChartOptions] = useState({
//         series: [
//             {
//                 name: 'Actual',
//                 data: [
//                     { x: 'Sun', y: props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Sunday },
//                     { x: 'Mon', y: props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Monday },
//                     { x: 'Tue', y: props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Tuesday },
//                     { x: 'Wed', y: props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Wednesday },
//                     { x: 'Thu', y: props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Thursday },
//                     { x: 'Fri', y: props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Friday },
//                     { x: 'Sat', y: props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails?.Saturday },
//                 ].map(data => ({ ...data, y: (data.y / 500) * 100 }))
                 
//             }
//         ],
//         chart: {
            
//             toolbar: { // Hamburger menu at top
//                 show: false,
//             },
//             id: 'chart',
//             type: 'bar',
//             offsetY: 0, // Adjust the top margin
//         offsetX: 0,
          
            
//         },
//         plotOptions: {
//             bar: {
//                 columnWidth: uplgmatch?'40%':'40%',
//                 borderRadius: upXlmatch ?  13 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 5 : 5) : 5) : 5) :9) :  10,
//             }
//         },
//         colors: ['#46A46C'],
//         dataLabels: {
//             enabled: false
//         },
//         grid: {
//             show: false,      // you can either change hear to disable all grids
//             xaxis: {
//                 lines: {
//                     show: false  //or just here to disable only x axis grids
//                 },
//             },  
//             yaxis: {
//                 lines: { 
//                     show: false , //or just here to disable only y axis
//                 }
//             },   
//         },
//         yaxis: {
//             min: 0,
//             max: 100,
//             tickAmount: 4,
//             stepSize:25,
           
    
//         },
//         xaxis: {
//             show: false,
//             axisBorder: {
//                 show: false
//             },
//             axisTicks: {
//                 show: false
//             },
           
//         },
//         tooltip:{
//             enabled:false,
//         }
//     });

//     return (
//         <div id="chart" style={{
//             width:"100%",height:"100%"
//         }}>
//             <Chart options={chartOptions} series={chartOptions.series} type="bar" height="100%" width="100%"/>
//         </div>
//     );
// };

// export default MyChartComponent;

//new code
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import './MyChartComponent.css';

const MyChartComponent = ({ upXlmatch, xlmatch, lgmatch, mdmatch, smmatch, xsmatch, uplgmatch, props }) => {
    const [pastWeekOrderDetails, setPastWeekOrderDetails] = useState(props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails);
    const [data, setData] = useState({
        Sunday: 0,
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        Saturday: 0
    });

    useEffect(() => {
        setPastWeekOrderDetails(props?.NewDashboardDetails?.getDashboardNew?.pastWeekOrderDetails);
    }, [props?.NewDashboardDetails?.getDashboardNew]);

    useEffect(() => {
        if (pastWeekOrderDetails) {
            setData({
                Sunday: pastWeekOrderDetails.Sunday || 0,
                Monday: pastWeekOrderDetails.Monday || 0,
                Tuesday: pastWeekOrderDetails.Tuesday || 0,
                Wednesday: pastWeekOrderDetails.Wednesday || 0,
                Thursday: pastWeekOrderDetails.Thursday || 0,
                Friday: pastWeekOrderDetails.Friday || 0,
                Saturday: pastWeekOrderDetails.Saturday || 0,
            });
        }
    }, [pastWeekOrderDetails]);

    const maxYValue = Math.max(...Object.values(data)) || 1;

    const percentageData = [
        { x: 'Sun', y: (data.Sunday / maxYValue) * 100 },
        { x: 'Mon', y: (data.Monday / maxYValue) * 100 },
        { x: 'Tue', y: (data.Tuesday / maxYValue) * 100 },
        { x: 'Wed', y: (data.Wednesday / maxYValue) * 100 },
        { x: 'Thu', y: (data.Thursday / maxYValue) * 100 },
        { x: 'Fri', y: (data.Friday / maxYValue) * 100 },
        { x: 'Sat', y: (data.Saturday / maxYValue) * 100 }
    ];

    const columnWidth = uplgmatch ? '40%' : '40%';
    const borderRadius = upXlmatch ? 13 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 5 : 5) : 5) : 5) : 9) : 10;

    const [chartOptions, setChartOptions] = useState({
        series: [
            {
                name: 'Actual',
                data: percentageData
            }
        ],
        chart: {
            toolbar: {
                show: false,
            },
            id: 'chart',
            type: 'bar',
            offsetY: 0,
            offsetX: 0,
        },
        plotOptions: {
            bar: {
                columnWidth: columnWidth,
                borderRadius: borderRadius,
            }
        },
        colors: ['#46A46C'],
        dataLabels: {
            enabled: false
        },
        grid: {
            show: false,
            xaxis: {
                lines: {
                    show: false
                },
            },
            yaxis: {
                lines: {
                    show: false,
                }
            },
        },
        yaxis: {
            min: 0,
            max: 100,
            tickAmount: 4,
            stepSize: 25,
            labels: {
                formatter: function (value) {
                    return value;
                }
            }
        },
        xaxis: {
            show: false,
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
        },
        tooltip: {
            enabled: false,
        }
    });

    useEffect(() => {
        setChartOptions(prevOptions => ({
            ...prevOptions,
            series: [
                {
                    name: 'Actual',
                    data: percentageData
                }
            ]
        }));
    }, [data]);

    return (
        <div id="chart" style={{ width: "100%", height: "100%" }}>
            <Chart options={chartOptions} series={chartOptions.series} type="bar" height="100%" width="100%" />
        </div>
    );
};

export default MyChartComponent;
