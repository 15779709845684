import { connect } from "react-redux";
import { SelectState } from "./selector";
import SignUpNew from "./signUpNew";
// import { handleInputState } from "../../Redux/Action/LoginActions/index";
import {
	handleInputChange,
	handleUploadImage,
	handleAlertClose,
	submitSignUp,
	submitSignUpP,
	submitSignUpG,
	submitSignUpPG,
	viewTable,
	setMaxDistance,
	setScheduleChange,
	uploadLogoImage,
	handleTableChange,
	closeSnack,
	UploadAadhar,
	UploadLicense,
	UploadFSSAI,
	UploadGst,
	SubscriptionClick,
	LogoDelete,
	Delete_Logo,
	LogoChange ,
	aadharDelete,
	aadharChange,
	LicenseDelete,
	LicenseChange,
	PanDelete,
	PanChange,
	gstDelete,
	gstChange,
	fssaiDelete,
	fssaiChange,
	setImage,	
	checkBoxSlots,
	setOpenTime,
	setCloseTime,
	getSameDay,
	setDays,
	setbusinessTypeId,
	setbusinessTypeError,
	sellerReg,
	signupForm1,
	signupForm2,
	setStoreName,
	setStoreDescription,
	setStoreLocation,
	setStoreCoordinates,
	signupForm3,
	signupForm4


} from "../../Redux/Action/NewSignUp/index";
import {getRadioButtonChange,handleFormChange,handleTimeChange} from './helper'
import { loginAccess,shopAssistantLoginAccess } from "../../Redux/Action/LoginActions";


const mapStateToProps = (state) => {
	return { ...SelectState(state) };
};

// eslint-disable-next-line max-lines-per-function
const mapDispatchToProps = (dispatch) => {
	return {
		checkBoxSlots:(params)=>{
			dispatch(checkBoxSlots(params));
		},
		fssaiChange:(params)=>{
			dispatch(fssaiChange(params));
		},
		fssaiDelete:()=>{
			dispatch(fssaiDelete());
		},
		gstChange:(params)=>{
			dispatch(gstChange(params));
		},
		gstDelete:()=>{
			dispatch(gstDelete());
		},
		aadharChange:(params)=>{
			dispatch(aadharChange(params));
		},
		aadharDelete:()=>{
			dispatch(aadharDelete());
		},
		LicenseChange:(params)=>{
			dispatch(LicenseChange(params));
		},
		LicenseDelete:()=>{
			dispatch(LicenseDelete());
		},
		LogoChange:()=>{
			dispatch(LogoChange());
		}, 
		Delete_Logo:(params)=>{
			dispatch(Delete_Logo(params));
		},
		LogoDelete:()=>{
			dispatch(LogoDelete());
		},
		SubscriptionClick:() => {
				dispatch(SubscriptionClick());
			},
		handleFormChange:(e, configuration) => {
			dispatch(handleFormChange(e, configuration));
		},
		getRadioButtonChange:(val, config, index) => {
			dispatch(getRadioButtonChange(val, config, index));
		},
		closeSnack: (params) => {
			dispatch(closeSnack(params));
		},
		onInputChange: (params) => {
			dispatch(handleInputChange(params));
		},
		imageSubmit: (formdata) => {
			dispatch(handleUploadImage(formdata));
		},
		closeAlert: () => {
			dispatch(handleAlertClose());
		},
		uploadLogoImage:(params)=>{
			dispatch(uploadLogoImage(params));
		},
		formSubmit: (param,params,parms1,params2,params3,params4,params5,params6,params7,params8) => {
			dispatch(submitSignUp(param,params,parms1,params2,params3,params4,params5,params6,params7,params8));
		},
		formSubmitP: (param,params,parms1,params2,params3,params4,params5,params6,params7,params8) => {
			dispatch(submitSignUpP(param,params,parms1,params2,params3,params4,params5,params6,params7,params8));
		},
		formSubmitG: (param,params,parms1,params2,params3,params4,params5,params6,params7,params8) => {
			dispatch(submitSignUpG(param,params,parms1,params2,params3,params4,params5,params6,params7,params8));
		},
		formSubmitPG: (param,params,parms1,params2,params3,params4,params5,params6,params7,params8) => {
			dispatch(submitSignUpPG(param,params,parms1,params2,params3,params4,params5,params6,params7,params8));
		},
		viewTable: () => {
			dispatch(viewTable());
		},
		setMaxDistance: (distance) => {
			dispatch(setMaxDistance(distance));
		},
		setScheduleChange: (key, index) => {
			dispatch(setScheduleChange(key, index));
		},
		handleTableChange: (params) => {
			dispatch(handleTableChange(params));
		},
		UploadAadhar: (params) => {
			dispatch(UploadAadhar(params))
		},
		UploadLicense: (params) => {
			dispatch(UploadLicense(params))
		},
		UploadFSSAI: (params) => {
			dispatch(UploadFSSAI(params))
		},
		UploadGst: (params) => {
			dispatch(UploadGst(params))
		},
		setImage: (params) => {
			dispatch(setImage(params))
		},
		handleTimeChange: (params1,params2,params3) => {
			dispatch(handleTimeChange(params1,params2,params3))
		},
		setOpenTime:(params) => {
			dispatch(setOpenTime(params))
		},
		setCloseTime:(params) => {
			dispatch(setCloseTime(params))
		},
		getSameDay:(params) => {
			dispatch(getSameDay(params))
		},
		setDays:(params) => {
			dispatch(setDays(params))
		},
		setbusinessTypeId:(params) => {
			dispatch(setbusinessTypeId(params))
		},
		setbusinessTypeError:(params) => {
			dispatch(setbusinessTypeError(params))
		},
		sellerReg:(params)=>{
			dispatch(sellerReg(params))
		},
		signupForm1:(params)=>{
			dispatch(signupForm1(params))
		},
		signupForm2:(params,params1) =>{
			dispatch(signupForm2(params,params1))
		},
		setStoreName:(params)=>{
			dispatch(setStoreName(params))
		},
		setStoreDescription:(params)=>{
			dispatch(setStoreDescription(params))
		},
		setStoreLocation:(params)=>{
			dispatch(setStoreLocation(params))
		},
		setStoreCoordinates:(params)=>{
			dispatch(setStoreCoordinates(params))
		},
		signupForm3:(params,params2,params3,params4)=>{
			dispatch(signupForm3(params,params2,params3,params4))
		},
        signupForm4:(param,params,params1,params2,params3)=>{
			dispatch(signupForm4(param,params,params1,params2,params3))
		},
		signInClick: (params,params1) => {
			dispatch(loginAccess(params,params1));
		},
		shopAssistantSignInClick:(params1,params2)=>{
			dispatch(shopAssistantLoginAccess(params1,params2));
		},
		PanChange:(params)=>{
			dispatch(PanChange(params));
		},
		PanDelete:()=>{
			dispatch(PanDelete());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpNew);
