// import React, { Component } from "react";
// import { Row, Col } from'react-bootstrap';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
// import ShareIcon from '@material-ui/icons/Share';
// import './index.css';
// // import { green } from '@material-ui/core/colors';

// export default class index extends Component {
//   // componentDidMount=()=>{
//   //   this.props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
//   //     }

//   constructor(props) {
//     super(props)

//     this.state = {
//       isMobile: window.innerWidth < 768,
//         url:this.props.DynamicLink&&this.props.DynamicLink,
//       copySuccess: false,
//       open:false,
//     }
//   }

//   copyCodeToClipboard = () => {
//     const el = this.input
//     el.select()
//     document.execCommand("copy")
//     this.setState({copySuccess: true})
//   }

//   render() {
//     // s
//   const handleClickOpen = () => {
//     this.setState({open:true});
//   };

//   const handleClose = () => {
//     this.setState({open:false});
//     this.setState({copySuccess: false})
//   };

//   return (
//     <>
//      <ShareIcon  style={{ color:'rgb(137, 199, 74, 1)',cursor:'pointer'} } onClick={handleClickOpen}/>
//       <Dialog
//         fullWidth={true}
//         maxWidth={'sm'}
//         open={this.state.open}
//         onClose={handleClose}
//       >
//         <DialogTitle> Share Url</DialogTitle>
//         <DialogContent>
//           {/* <Box
//             noValidate
//             component="form"
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               m: 'auto',
//               width: 'fit-content',
//               justifyContent:'left',
//               alignItems:'left'
//             }}
//           > */}
//              <Row style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
//          <Col md={8} sm={12} xs={12}>
//       <input
//       style={{width:'100%',height: '37px',borderRadius: '13px',border:'1px solid rgba(196, 196, 196, 0.2)',
//       background: 'rgba(196, 196, 196, 0.2)',color:'#85BE49',fontFamily: 'Varela Round, sans-serif',justifyContent:' center',
//       minHeight:'14px',}}
//       ref={(input) => this.input = input}
//       type="text"
//       value={this.props.DynamicLink}
//       readOnly
//     />
//     </Col>
//     <Col md={4} sm={6} xs={6}>
//     <div>
//         <button
//         type="button"
//         style={{
//           justifyContent:' center',
//           color: 'rgb(255, 255, 255)',
//           backgroundImage: '-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
//           width:'150px',
//           height: '37px',
//           fontSize: '16px',
//           fontWeight: '600',
//           borderRadius: '13px',
//           borderWidth: '1px',
//           borderStyle:' solid',
//           borderColor: 'transparent',
//           borderImage: 'initial',
//           overflow: 'hidden',
//           outline: 'none',
//           minHeight:'14px',
//           fontFamily: 'Varela Round, sans-serif'
//           }}
//           onClick={() => this.copyCodeToClipboard()}>
//            Copy Url
//         </button>

//       </div>
//     </Col>
//       </Row>
//       <Row style={{justifyContent:'center',alignItems:'center'}}>
//       {
//           this.state.copySuccess ?
//           <div style={{color:'#85BE49',paddingTop:'6px'}}>
//             Copied to Clipboard
//           </div> : null
//         }
//       </Row>

//           {/* </Box> */}
//         </DialogContent>
//         <DialogActions style={{paddingRight:'20px'}}>
//           <Button color='success' onClick={handleClose}>Close</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//    )
//   }
// }

//Berlin New code

//shareUrl code in the Navbar

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CustomButton from "../Commoncomponents/button/index";
import CustomDialog from "../Commoncomponents/dialog/index";
import { InputAdornment } from "../MUIComponents/index";
import { DialogContent, Typography } from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import CustomTextField from "../Commoncomponents/input/index";
import ShareIcon from "../../utils/Icon/shareIcon.svg";
import { contentFontSize_16, HeaderFontSize_24 } from "../Typography/index";
import "./index.css";
import { useMediaQuery, useTheme } from "../MUIComponents/index";

function ShareUrl(props) {

  const [open, setOpen] = useState(false);
  // const [url, setUrl] = useState(props.DynamicLink ? props.DynamicLink : '');
  const [url, setUrl] = useState();

  const [copySuccess, setCopySuccess] = useState(false);


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (props.DynamicLink) {
      setUrl(props.DynamicLink);
    }
  }, [props.DynamicLink]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCopySuccess(false);
  };

  const copyCodeToClipboard = async () => {
    const el = inputRef.current;
    el.select();
    try {
      await navigator.clipboard.writeText(el.value);
      setCopySuccess(true);
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };

  const inputRef = React.createRef();

  return (
    <>
      <Box sx={{ color: "action.active" }}>
        <img
          src={ShareIcon}
          alt="share Icon"
          style={{
            cursor: "pointer",
            height: matches ? "20px" : "30px",
            width: matches ? "16.84px" : "26.84px",
          }}
          onClick={handleClickOpen}
        />
      </Box>

      <CustomDialog
        width="600px"
        height="200px"
        borderRadius="20px"
        open={open}
        onClose={handleClose}
        titleAlign="center"
        // title="Share URL"
        top={"15px"}
        right={"20px"}
        // showCloseButton={false}
      >
        <Typography
          style={{
            color: "#170F49",
            textAlign: "center",
            fontFamily: "Roboto",
            fontWeight: "700",
          }}
          className="head-font"
          fontSize={HeaderFontSize_24}
        >
          Share URL
        </Typography>
        <DialogContent>
          <div>
            <CustomTextField
              width="100%"
              value={url}
              inputRef={inputRef}
              endAdornment={
                <InputAdornment position="end">
                  <div>
                    <CustomButton onClick={() => copyCodeToClipboard()}>
                      <Typography fontSize={contentFontSize_16}>
                        Copy Url
                      </Typography>
                    </CustomButton>
                  </div>
                </InputAdornment>
              }
            ></CustomTextField>
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              {copySuccess ? (
                <div style={{ color: "#85BE49", paddingTop: "6px" }}>
                  <Typography fontSize={contentFontSize_16}>
                    Copied to Clipboard
                  </Typography>
                </div>
              ) : null}
            </div>
          </div>
        </DialogContent>
      </CustomDialog>
    </>
  );
}

export default ShareUrl;
