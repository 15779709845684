import React, { useEffect } from "react";
import Selectedphoto from "../../utils/images/Selectedphoto.png";
import sectionbreak from "../../utils/images/sectionbreak.svg";
import { Typography, Container, Box } from "../MUIComponents";
import './Privacy.css'
import { contentFontSize_36, contentFontsize_30,contentFontSize_20 } from "../Typography";
import { useNavigate } from "react-router-dom";
function Privacy() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const nav=useNavigate()

  const handleHomeClick =()=>{
     nav('/')
  }
  return (
    <>
      <div className="parent" style={{ position: "relative" }}>
        <img src={Selectedphoto} width="100%" className="image1" />
        <div className="top-right">
        <img src="/static/media/Logo.8e4fa8338e24da3e1e2e.png" width="210px" height="70px" alt="shop and deliver.png" />
        </div>
        <div class="top-left">
        <Typography
          style={{ color: "#2D2A6E", fontFamily: "Nunito", fontWeight: "700", cursor:"pointer"}}
          fontSize={contentFontSize_20}
          onClick={handleHomeClick}
        >
          Home
        </Typography>
        <Typography
          style={{ color: "#69A85C", fontFamily: "Nunito", fontWeight: "700" }}
          fontSize={contentFontSize_20}
        >
       Privacy Policy
        </Typography>
        </div>
        <div class="top-left1">
          <Typography sx={{fontFamily:"Roboto",fontWeight:"800"}} fontSize={contentFontsize_30}>Privacy Policy</Typography>
        </div>
      </div>
      <div
        style={{ paddingLeft: "50px", paddingRight: "35px", marginTop: "50px" }}
      >
        <Box mb={4}>
          <Typography
            variant="h5"
            gutterBottom
            fontFamily="Roboto"
            sx={{ paddingBottom: "0px" }}
          >
            1. Introduction
          </Typography>
          {/* <Typography variant="h6" gutterBottom>1.1</Typography> */}

          <Typography paragraph>
            NearShopz, operated by 4Labs Technologies Pvt. Ltd. ("NearShopz,"
            "we," "our," or "us"), is dedicated to safeguarding the privacy and
            security of your personal information. This Privacy Policy
            elucidates how we collect, utilize, disclose, and protect your
            personal information when you utilize our hyperlocal delivery
            services, mobile applications, and website.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            2. Information We Collect
          </Typography>

          <Typography paragraph>
            We may gather various types of information, including but not
            limited to:
            <br /> <br/> Personal Information: This encompasses your name, contact
            information, address, and payment details. Location Information:
            Your device's location information is collected when you avail our
            services to facilitate efficient hyperlocal delivery. Transaction
            Details: Information pertaining to your transactions, such as order
            history and delivery status. Usage Information: Data regarding your
            interaction with our website and mobile applications. Device
            Information: This encompasses details about the devices you employ
            to access our Services, including hardware specifications, OS
            versions, and software information.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            3. How We Use Your Information
          </Typography>

          <Typography paragraph>
            We utilize your personal information for the ensuing purposes:
            <br /> <br/> 
             To process and execute your orders for hyperlocal delivery services. To
            communicate with you regarding your orders and furnish customer
            support. To enhance and personalize your experience with our
            services. To dispatch updates, promotions, and information regarding
            our services.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            4. Information Sharing
          </Typography>

          <Typography paragraph>
            We may divulge your information to the following entities: 
            <br /> <br/> Delivery
            Partners: Your delivery details are shared with our delivery
            partners to execute your orders. Service Providers: We enlist
            third-party service providers for diverse purposes, including
            payment processing and analytics. Legal Requirements: Your
            information may be disclosed to adhere to legal obligations or
            respond to law enforcement requests.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            5. Data Security
          </Typography>

          <Typography paragraph>
            We deploy industry-standard security measures to safeguard your
            personal information. Nevertheless, no method of data transmission
            over the internet or electronic storage is wholly secure.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            6. Your Choices
          </Typography>

          <Typography paragraph>
            You retain the following options:
            <br /> <br/>  Review and amend your personal
            information via your account settings. <br/>  Opt out of marketing
            communications at any juncture.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            7. Cookies and Tracking Technologies
          </Typography>

          <Typography paragraph>
            We utilize cookies and akin technologies to enrich your experience
            on our website and mobile applications. You can manage cookie
            preferences through your browser settings.
          </Typography>

          <Typography variant="h5" sx={{ paddingBottom: "0px" }} gutterBottom>
            8. Children's Privacy (Need to be clarified)
          </Typography>

          <Typography paragraph>
            Our services are not intended for individuals under the age of 13.
            We do not knowingly amass information from children.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            9.Changes to this Privacy Policy
          </Typography>

          <Typography paragraph>
            We may revise this Privacy Policy to reflect alterations in our
            practices. You will be apprised of any noteworthy changes.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            10.Contact Us
          </Typography>

          <Typography paragraph>
            Should you have any queries or concerns regarding this Privacy
            Policy or your personal information, please reach out to us at:
            <br/> <br/> 
            support@nearshopz.com
          </Typography>
          {/* <Typography variant="h6" gutterBottom>10.2</Typography> */}

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            11. Consent
          </Typography>

          <Typography paragraph>
            By utilizing our hyperlocal delivery services, you consent to the
            terms expounded in this Privacy Policy.
          </Typography>
        </Box>
      </div>
    </>
  );
}

export default Privacy;
