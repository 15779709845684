import React, { useEffect, useState } from "react";
import CustomCard from "../Commoncomponents/card";
import partner from "../../utils/images/partner.png";
import { Typography, useMediaQuery, useTheme,Button,Card } from "../MUIComponents/index";
import {
  HeaderNavigationFontSize_22,
  contentFontSize_18,
  ButtonFontSize_18,
  HeaderfontSize_30,
  contentFontSize_15,
} from "../Typography";
import "./LandingPage.css";
import CustomButton from "../Commoncomponents/button";
import rideimg from "../../utils/images/rideimg.jpeg";
import Shopimg from "../../utils/images/Shopwithus.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

function LandingPageCard() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const [color, setColor] = useState("#13801C");
  const nav = useNavigate()

  const handleConnectClick = (subject) =>{
    nav("/Contact", { state: { subject } });
  }

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 1500,
      easing: "ease-out-cubic",
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor((prevColor) => (prevColor === "#13801C" ? "black" : "#13801C"));
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="linear-gradient-background">
      <div style={{ marginTop: "100px" }}>
        <Typography
          fontSize={HeaderfontSize_30}
          className="header"
          style={{ color: color }}
        >
          Let's Grow Together with NearShopz!
        </Typography>
      </div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          gap: "30px",
          alignItems: "center",
          padding: "20px",
          justifyContent: "space-evenly",
        }}
      >
        <div data-aos="slide-right">
          <Card
            className="card"
            sx={{padding:"20px",paddingTop:"50px",height:"600px",width:"450px",background:"white",borderRadius:"20px", cursor:"pointer"}}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div>
                <img src={partner} alt="no image"  style={{ width: "100%",height:"300px" }} />
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Typography
                  fontSize={HeaderNavigationFontSize_22}
                  className="header-1"
                >
                  Partner with Us
                </Typography>
              </div>
              <div style={{ paddingTop: "20px",height:"150px" }}>
                <Typography fontSize={contentFontSize_15} className="para">
                Calling all ambitious business owners! Expand your reach and unlock new sales potential by partnering with NearShopz, the leading local ecommerce platform. With Low markups and convenient services, NearShopz helps your business grow. Join our network of successful sellers and attract more customers! 
                </Typography>
              </div>
              <div style={{ paddingTop: "50px", textAlign: "center" }}>
              <Button
                  className="custom-button1"
                  sx={{
                    width: {
                      xs: "40px", 
                      sm: "60px", 
                      md: "80px", 
                      lg: "130px",
                      xl: "160px", 
                      upXl: "170px", 
                    },
                    '&:focus': {
                          outline: 'none',
                        },
                    height: "40px",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                  }}
                  onClick={() => handleConnectClick("Partner with Us")}
                >
                  <Typography
                    fontSize={ButtonFontSize_18} 
                    className="Add-product-btn"
                  >
                    Connect
                  </Typography>
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div data-aos="fade-down">
        <Card
            className="card"
            sx={{padding:"20px",paddingTop:"50px",height:"600px",width:"450px",background:"white",borderRadius:"20px", cursor:"pointer"}}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={Shopimg}
                  alt="no image" 
                  style={{ width: "100%",height:"300px",borderRadius:"10px" }}
                />
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Typography
                  fontSize={HeaderNavigationFontSize_22}
                  className="header-1"
                >
                  Shop with Us
                </Typography>
              </div>
              <div style={{ paddingTop: "20px" ,height:"150px" }}>
                <Typography fontSize={contentFontSize_15} className="para">
                Elevate your everyday with NearShopz!  From fresh groceries to delightful treats, essential daily items to pampering pet supplies, NearShopz offers endless options to buy and get it all delivered straight to your door.  Download NearShopz and simplify your life - shop smarter, not harder! 
                </Typography>
              </div>
              <div style={{ paddingTop: "50px", textAlign: "center" }}>
              <Button
                  className="custom-button1"
                  sx={{
                    width: {
                      xs: "40px", 
                      sm: "60px", 
                      md: "80px", 
                      lg: "130px",
                      xl: "160px", 
                      upXl: "170px", 
                    },
                    '&:focus': {
                          outline: 'none',
                        },
                    height: "40px",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                  }}
                  onClick={() => handleConnectClick("Shop with Us")}
                >
                  <Typography
                    fontSize={ButtonFontSize_18} 
                    className="Add-product-btn"
                  >
                    Connect
                  </Typography>
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div data-aos="slide-left">
        <Card
            className="card"
            sx={{padding:"20px",paddingTop:"50px",height:"600px",width:"450px",background:"white",borderRadius:"20px", cursor:"pointer"}}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div>
                <img
                alt="no image" 
                  src={rideimg}
                  style={{ width: "100%",height:"300px" ,borderRadius:"10px"}}
                />
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Typography
                  fontSize={HeaderNavigationFontSize_22}
                  className="header-1"
                >
                  Ride with Us
                </Typography>
              </div>
              <div style={{ paddingTop: "20px",height:"150px"  }}>
                <Typography fontSize={contentFontSize_15} className="para">
                Looking for a flexible and rewarding opportunity? Join NearShopz as a delivery rider! Earn competitive income with a flexible schedule and full support. Deliver food, groceries , treats, fresh produce, and pet items to our customers. Download the NearShopz app to apply now and start earning today!
                </Typography>
              </div>
              <div style={{ paddingTop: "50px", textAlign: "center" }}>
                <Button
                  className="custom-button1"
                  sx={{
                    width: {
                      xs: "40px", 
                      sm: "60px", 
                      md: "80px", 
                      lg: "130px",
                      xl: "160px", 
                      upXl: "170px", 
                    },
                    '&:focus': {
                          outline: 'none',
                        },
                    height: "40px",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                  }}
                  onClick={() => handleConnectClick("Ride with Us")}
                >
                  <Typography
                    fontSize={ButtonFontSize_18} 
                    className="Add-product-btn"
                  >
                    Connect
                  </Typography>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default LandingPageCard;
