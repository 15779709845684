import { React, useEffect } from "react";
import { useTheme } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Button, CloseButton, Col, Row } from "react-bootstrap";
import { fields } from "./Helper";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert, useMediaQuery, AlertTitle } from "@mui/material";
import { green } from "@mui/material/colors";
import LoginForm from "../LoginForm";
import { useState } from "react";
import { useFormik } from "formik";
import { Snackbar } from "@mui/material";
import { LOGO } from "../../utils/image";
import * as Yup from "yup";
import OTPInput from "react-otp-input";
import "./index.css";
import { BiFemaleSign } from "react-icons/bi";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const Sellerreg = (props) => {
  const smallscreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  useEffect(()=>{
    formik.resetForm();
  },[])

  // console.log("props", props);
  const theme = createTheme({
    palette: {
      primary: green,
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&:-webkit-autofill": {
            transitionDelay: "9999s",
            transitionProperty: "background-color, color",
          },
        },
      },
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showReg, setShowReg] = useState(false);
  const [counter, setCounter] = useState(59);
  const [counterStart, setCounterStart] = useState(false);
  const [heading, setHeading] = useState(true);
  const [showRegister, setShowRegister] = useState(true);
  useEffect(() => {
    let timer;

    if (showPopup) {
      setCounter(59);
      setCounterStart(true);
    }

    if (counterStart && showPopup) {
      timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter > 0) {
            return prevCounter - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [counterStart, showPopup]);

  const openPopup = () => {
    setShowPopup(true);
    setShowRegister(false);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  const toggleForm = () => {
    setShowLoginForm((prev) => !prev);
    setShowRegister(false);
    setHeading(false);
  };
  const nav = useNavigate();
  const validationSchema = Yup.object().shape({
    storeName: Yup.string()
      .matches(/^(?!\s).*$/, "Cannot start with a space")
      .required("Store name is required")
      .min(3, "Store name must be at least 3 characters")
      .max(20, "Store name must not exceed 50 characters"),
    emailId: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format"
      )
      .required("Email is required")
      .email("Email is invalid"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "One Uppercase, One Lowercase, One Number and one special case Character"
      )
      .min(8, "Password must be atleast 8 characters")
      .required("Password is required"),
    mobileNumber: Yup.string()
      .matches(
        /^[1-9]\d{9}$/,
        "Mobile number must be exactly 10 digits and cannot start with 0"
      )
      .required("Mobile number is required"),
    otp: Yup.string().matches(/^[1-9]\d{9}$/, "only numbers are allowed"),
  });
  const formik = useFormik({
    initialValues: {
      storeName: "",
      mobileNumber: "",
      emailId: "",
      password: "",
      otp: "",
    },
    validationSchema,
    onSubmit: (values) => {
      localStorage.setItem("formValues", JSON.stringify(values));
      props.sendOTP(formik.values.mobileNumber);
      openPopup();
    },
  });
  const handleVerifyClick = () => {
    const otpInteger = parseInt(formik.values.otp, 10);
    formik.setFieldValue("otp", otpInteger);
    props.sellerReg(formik.values);
  };

  useEffect(() => {
    if (props.sellerRegSuccess) {
      localStorage.setItem("Registration",true)
      nav("/Register", {
        state: {
          storeName: formik.values.storeName,
          mobileNumber: formik.values.mobileNumber,
          emailId: formik.values.emailId,
          password: formik.values.password,
        },
      });
    } else if (props.error) {
      message.error({
        content: `${props.error}`,
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
       formik.resetForm();
      setShowPopup(false)
      setShowRegister(true)
      console.error("Seller registration failed:", props.error);
    }
  }, [props.sellerRegSuccess, props.error]);

  const resend = () => {
    props.sendOTP(formik.values.mobileNumber);
    setCounter(59);
  };

  return (
    <>
      <div>
        {showLoginForm && <LoginForm toggleForm={toggleForm} {...props} />}
        {heading && (
          <div className="login_container">
            {smallscreen ? (
              <Row>
                <Col
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img
                    src={LOGO}
                    width="210px"
                    height="70px"
                    alt="shop and deliver.png"
                  />
                </Col>
              </Row>
            ) : null}
            {showPopup && (
              <>
                <h5 className="signin-heading">{"Verify OTP"}</h5>
                <div className="signin-subheading">
                  {`An OTP has been sent to ${formik.values.mobileNumber.slice(
                    -4
                  )}`}
                </div>

                <label className="textfield-label">{"Enter 4 digit OTP"}</label>
                <OTPInput
                  onChange={(otpValue) => {
                    const numericValue = otpValue.replace(/\D/g, "");

                    formik.setFieldValue("otp", numericValue);
                  }}
                  value={formik.values.otp}
                  autoFocus
                  OTPLength={4}
                  inputMode="numeric"
                  inputStyles={{
                    width: "55px",
                    height: "55px",
                    borderRadius: "10px",
                    color: "black",
                  }}
                  inputStyle={{
                    width: "55px",
                    height: "55px",
                    padding: "8px",
                    margin: "0px 10px 0 10px",
                    border: "1px solid #B9B9B9",
                    borderRadius: " 12px",
                    padding: "0px, 30px, 0px, 30px",
                    outline: "none",
                  }}
                  renderInput={(props) => <input {...props} />}
                />

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "right",
                  }}
                >
                  <button
                    onClick={handleVerifyClick}
                    className="signin-btn"
                    width="100%"
                    style={{width:"100%"}}
                  >
                    Verify
                  </button>
                </div>

                <span
                  style={{
                    color: "rgba(49, 49, 49, 1)",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  Didn't receive OTP?&nbsp;
                 {counter > 0 ? (
              <span>00:{counter < 10 ? `0${counter}` : counter}</span>
            ) :  (
                    <button
                      type="button"
                      style={{
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "rgba(43, 110, 246, 1)",
                        background: "none",
                        borderWidth: "initial",
                        borderStyle: "none",
                        borderColor: "initial",
                        borderImage: "initial",
                        marginTop: "1px",
                        fontFamily: "Varela Round, sans-serif",
                      }}
                      onClick={() => {
                        resend();
                        setCounter(59);
                      }}
                    >
                      {"Resend OTP"}
                    </button>
                  )}
                </span>
              </>
            )}
            {showRegister && (
              <>
                <h5 className="signin-heading">{"Register"}</h5>
                <div className="signin-subheading">
                  {"Last chance to choose your free membership"}
                </div>
                {fields.map((field, index) => {
                  return (
                    <div className="textfield" style={{ marginBottom: "-2%" }}>
                      <label className="textfield-label">{field.label}<span style={{ color: "#EA4335" }}>*</span></label>
                      <TextField
                        sx={{
                          width: "100%",

                          "& .MuiInputBase-input": {
                            backgroundColor: "none",
                          },
                        }}
                        id={field.name}
                        value={formik.values[field.name]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[`${field.name}`] &&
                          Boolean(formik.errors[`${field.name}`])
                        }
                        helperText={
                          formik.touched[`${field.name}`] &&
                          formik.errors[`${field.name}`]
                        }
                        InputProps={{
                          style: {
                            backgroundColor: "rgb(247, 247, 247) ",
                            borderRadius: "10px",
                          },

                          endAdornment:
                            field.type === "password" ? (
                              <InputAdornment position="end">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={handleClick}
                                >
                                  {showPassword ? (
                                    <VisibilityIcon
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    <VisibilityOffIcon
                                      style={{ cursor: "pointer" }}
                                    />
                                  )}
                                </div>
                              </InputAdornment>
                            ) : null,
                        }}
                        className="form-fields"
                        type={
                          (field.name === "password" && showPassword) ||
                          field.name === "storeName" ||
                          field.name === "emailId" ||
                          field.name === "mobileNumber"
                            ? "text"
                            : "password"
                        }
                        name={field.name}
                        placeholder={field.placeholder}
                        variant="outlined"
                      />
                    </div>
                  );
                })}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:"10px",
                    flexDirection:"column"
                  }}
                >
                  <button
                    onClick={formik.handleSubmit}
                    className="signin-btn"
                    type="submit"
                    width="100%"
                    style={{width:"70%"}}
                  >
                    Register my shop now
                  </button>
                  <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      color: "rgba(49, 49, 49, 1)",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    Already have an account?&nbsp;
                  </span>
                  <button
                    type="button"
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "rgba(43, 110, 246, 1)",
                      background: "none",
                      borderWidth: "initial",
                      borderStyle: "none",
                      borderColor: "initial",
                      borderImage: "initial",
                      marginTop: "1px",
                      fontFamily: "Varela Round, sans-serif",
                    }}
                    onClick={() => toggleForm()}
                  >
                    Login Now
                  </button>
                </div>
                </div>
               
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default Sellerreg;
