import React, { useEffect } from "react";
// import { makeStyles } from "@mui/styles";
import {Stepper} from "@mui/material";
import {Step} from "@mui/material";
import {StepLabel} from "@mui/material";
import {StepContent} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {TextareaAutosize} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import client from "../../Configurations/apollo";
import { AddBill, PaymentStatus } from "./Query";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { AlertTitle } from '@mui/material';
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import io from "socket.io-client";
import "./index.css";
import { message } from 'antd';
import TimeField from "./TimeField";

// function Alert(props) {
//   return <Alert elevation={6} variant="filled" {...props} />;
// }

const muiTheme = createTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: "#b3b3b4", // or 'rgba(0, 0, 0, 1)'
        "&$active": {
          color: 'rgb(137, 199, 74, 1)',
        },
        "&$completed": {
          color: "rgb(137, 199, 74, 1)",
        },
      },
    },
  },
});

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },

//   button: {
//     marginTop: theme.spacing(1),
//     marginRight: theme.spacing(1),
//   },
//   actionsContainer: {
//     marginBottom: theme.spacing(2),
//   },
//   resetContainer: {
//     padding: theme.spacing(3),
//   },
// }));

function getSteps() {
  return [
    "Order-Placed",
    "Order-Processing",
    "Order-Accepted",
    "Shopping-In-Progress",
    "Order-Ready",
    "Completed",
  ];
}
function getRejectedStep() {
  return ["Order-Placed", "Order-Processing", "Order-Rejected"];
}

function getCancelledStep() {
  return ["Order-Placed", "Order-Processing", "Order-Cancelled"];
}

// const socket = io.connect(`${process.env.}`);
const socket = io.connect(`https://testserver.nearshopz.com`);

export default function OrderStatusStepper(props) {

  const [estimatedtime, setEstimatedTime] = React.useState(null)

  React.useEffect(() => {
    setBill(props.searchDetails && props.searchDetails.totalPrice)
    props.getShopassistantlist(localStorage.getItem("storeid"));
    socket.on(`${localStorage.getItem('orderid')}_order`, (data) => {
      props.setOrderDetails(data);

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  React.useEffect(() => {
    if (props.EstimatedTime && props.EstimatedTime !== null) {
      setEstimatedTime(new Date(new Date().getTime() + ((props.EstimatedTime && props.EstimatedTime.travelTime && props.EstimatedTime.travelTime.value) * 60 * 1000)))
    }
  }, [props.EstimatedTime])

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [partnerValue, setpartnerValue] = React.useState("");
  const [shopassistantId, setSId] = React.useState("");
  const [partnerId, setpartnerId] = React.useState("");
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleListItemClick = (value, id, num) => {
    if (num === 2) {
      setSId(id)
      props.AssignOrder(id, props.orderid ? props.orderid : props.searchDetails._id);

    } else if (num === 4) {
      setpartnerId(id)
      props.AssignPartnerOrder(id, props.orderid ? props.orderid : props.searchDetails._id, props.travelTime);
    }

    handleClose(value, num);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value, num) => {
    setOpen(false);
    if (num === 2) {
      setSelectedValue(value);
    } else if (num === 4) {
      setpartnerValue(value);
    }
  };

  // const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [acceptedState, setAcceptedStep] = React.useState(true);
  const [bill, setBill] = React.useState(props.searchDetails && props.searchDetails.totalPrice);
  const [completed, setCompleted] = React.useState(false);
  const [error, setError] = React.useState(false);
  //   const [errorMsg, setErrorMsg] = React.useState(null);
  const [state, setComment] = React.useState({ comment: null });
  const steps = getSteps();
  const rejectedStep = getRejectedStep();
  const cancelledStep = getCancelledStep();
  const handleNext = (status) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (status === "Order-Rejected") {
      props.rejectedStatus({
        comment: state.comment,
        status: "Order-Rejected",
        orderId: props.orderid ? props.orderid : props.searchDetails._id,
      });

      props.scheduleAlert({
        orderid: props.orderid ? props.orderid : props.searchDetails._id,
        status: "Order-Rejected",
        deliveryDate: `${props.deliveryDate}`,
        deliveryTime: `${props.deliveryTime}`,
        orderNumber: props.orderNumber,
        shopassistantId: partnerId !== "" ? partnerId
          : shopassistantId !== "" ? shopassistantId
            : props.deliveryPartnerId ? props.deliveryPartnerId :
              props.shopAssistantId ? props.shopAssistantId : null
      });

    } else {
      props.statusChangeHandler({ status: status, orderid: props.orderid ? props.orderid : props.searchDetails._id });
      props.scheduleAlert({
        orderid: props.orderid ? props.orderid : props.searchDetails._id,
        status: status,
        deliveryDate: `${props.deliveryDate}`,
        deliveryTime: `${props.deliveryTime}`,
        orderNumber: props.orderNumber,
        shopassistantId: partnerId !== "" ? partnerId
          : shopassistantId !== "" ? shopassistantId
            : props.deliveryPartnerId ? props.deliveryPartnerId :
              props.shopAssistantId ? props.shopAssistantId : null
      });
      // props.getSearchDetails(props.orderid?props.orderid: props.searchDetails._id)
    }
  };


  useEffect(() => {
    setBill(props.searchDetails && props.searchDetails.totalPrice)
  }
    , [])
  useEffect(() => {
    setBill(props.searchDetails && props.searchDetails.totalPrice)
    setActiveStep(
      props.status
        ? props.status.length <= 1
          ? props.status.length
          : props.status.length + 1
        :
        props.searchDetails && props.searchDetails.finalBillAmount !== null && props.status.length === 1 ?
          2
          :
          1
    );

    if (props.status && props.status.length === 3) {
      setCompleted(true)
    }
    else {
      setCompleted(false)
    }

  }, [props.status]);
  useEffect(() => {
    setActiveStep(
      props.status
        ? props.status.length <= 1
          ?
          props.searchDetails && props.searchDetails.finalBillAmount !== null && props.status.length === 1 ?
            2
            :
            props.status.length
          :
          props.status.length + 1
        :

        1
    );
    if (props.status && props.status.length === 3) {
      setCompleted(true)
    }
    if (props.searchDetails && props.searchDetails.estimatedDeliveryTime && props.searchDetails.estimatedDeliveryTime !== null) {
      setEstimatedTime(new Date(Number(props.searchDetails.estimatedDeliveryTime && props.searchDetails.estimatedDeliveryTime
        && props.searchDetails.estimatedDeliveryTime)).toString())
    }

  }, [props.searchDetails]);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.setRejectClick(false)
  };
  const handleReject = () => {
    setAcceptedStep(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    props.setRejectClick(true)
    message.success({
      content: 'Provide a valid reason to reject',
      className: 'custom-class',
      style: {
        marginTop: '50vh',
      },
    });
  };

  const handleAccept = () => {
    setAcceptedStep(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const changeComment = (e) => {
    setComment({
      [e.target.name]: e.target.value,
    });
  };
  let statusValue = props.status && props.status[1] && props.status[1].status;
  return statusValue === "Order-Cancelled" ? <>
    <ThemeProvider theme={muiTheme}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {cancelledStep.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            {index === 0 ? (
              <Typography>
                {props.status &&
                  props.status[index] &&
                  props.status[index].created}
              </Typography>
            ) : index > 1 ? (
              <Typography>
                {props.status &&
                  props.status[index - 1] &&
                  props.status[index - 1].created}
              </Typography>
            ) : null}
          </Step>
        ))}
      </Stepper>
    </ThemeProvider>
    {activeStep === cancelledStep.length && (
      <Paper square elevation={0}
      //  className={classes.resetContainer}
       >
        <button
          disabled
          type="button"
          style={{
            height: "37px",
            backgroundColor: "white",
            color: "rgb(211, 35, 35)",
            border: "1px solid rgb(211, 35, 35)",
            fontSize: "18px",
            fontWeight: 600,
            marginLeft: 5,
            borderRadius: 5,
          }}
        >
          Order Cancelled
        </button>
      </Paper>
    )}
  </> :
    acceptedState && statusValue !== "Order-Rejected" ? (
      <div  
      // className={classes.root} 
       >
        <ThemeProvider theme={muiTheme}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <span>{label}</span>
                  {index === 4 && estimatedtime !== null && props.searchDetails && props.searchDetails.deliveryPartnerId &&
                    props.searchDetails.deliveryPartnerId !== null && props.searchDetails.deliveryPartnerId.firstName &&
                    props.searchDetails.deliveryPartnerId.firstName !== null &&<span style={{ fontWeight: 400 }}>
                    <br />
                    {"\t"}
                    {`Estimated Delivery Time : ${estimatedtime}`}
                    <br />
                    <PermIdentityIcon />

                    {"\t"}
                    {'Delivery Agent :'} {props.searchDetails.deliveryPartnerId.firstName ||
                         (props.searchDetails.shopAssistantId ? props.searchDetails.shopAssistantId.firstName : 'N/A')}
                   </span>
                  
                  }
                  {(index === 2 || index === 3) && props.searchDetails && props.searchDetails.shopAssistantId &&
                    props.searchDetails.shopAssistantId !== null && props.searchDetails.shopAssistantId.firstName &&
                    props.searchDetails.shopAssistantId.firstName !== null &&
                    <>
                      <br />
                      <span style={{ fontWeight: 400, alignItems: 'center' }}>
                        <PermIdentityIcon />
                        {index === 2 ? 'Accepted by : ' : 'Assigned Shop Assistant : '}
                        {"\t"}
                        {props.searchDetails.shopAssistantId.firstName}
                      </span>
                    </>}
                  {index === 0 ? (
                    props.status &&
                    props.status[index] && (
                      <span style={{ fontWeight: 400 }}>
                        {"\ton\t"}
                        {props.status[index].created}
                      </span>
                    )
                  ) : index > 1 && index !== 2 && index !== 4 ? (
                    props.status &&
                    props.status[index - 1] && (
                      <span
                        style={{ fontWeight: 400 }}>
                        {" "}
                        {"\ton\t"}
                        {props.status[index - 1].created}
                      </span>
                    )
                  )
                    : null}
                </StepLabel>
                <StepContent>
                  <div 
                  // className={classes.actionsContainer}
                  >
                    {index === 1 ? (
                      <div>
                        <button
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: 'rgb(137, 199, 74, 1)',
                            minWidth: " auto",
                            /* width: 50%; */
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          type="button"
                          disabled={activeStep === 0}
                          onClick={handleAccept}
                          // className={classes.button}
                        >
                          Accept
                        </button>
                        <button
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: 'rgb(137, 199, 74, 1)',
                            minWidth: " auto",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          type="button"
                          onClick={handleReject}
                          // className={classes.button}
                        >
                          Reject
                        </button>
                      </div>
                    ) : (
                      <div>
                        {error ? (
                          <Snackbar
                            style={{
                              width: "fit-content",
                              left: "50%",
                              bottom: "50%",
                            }}
                            open={error}
                            autoHideDuration={2000}
                            onClose={() => setError(false)}
                          >
                            <Alert severity={"error"}>
                              <AlertTitle>{"Something went Wrong"}</AlertTitle>
                            </Alert>
                          </Snackbar>
                        ) : null}

                        {activeStep === 2 ? (
                          <>
                            {completed === true && (
                              <button
                                style={{
                                  justifyContent: " center",
                                  color: "rgb(255, 255, 255)",
                                  backgroundColor: 'rgb(137, 199, 74, 1)',
                                  minWidth: " auto",
                                  /* width: 50%; */
                                  height: "37px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  borderRadius: "5px",
                                  borderWidth: "1px",
                                  borderStyle: " solid",
                                  borderColor: "transparent",
                                  borderImage: "initial",
                                  overflow: "hidden",
                                  outline: "none",
                                  minHeight: " 14px",
                                  fontFamily: "Varela Round, sans-serif",
                                }}
                                type="button"
                                onClick={() => {
                                  // props.statusChangeHandler({
                                  //   status: "Order-Accepted",
                                  //   orderid: props.orderid?props.orderid: props.searchDetails._id,
                                  // });

                                  handleNext(label);
                                  setBill(props?.searchDetails?.totalPrice);
                                  client
                                  .mutate({
                                    mutation: AddBill,
                                    variables: {
                                      orderid: `${props.orderid ? props.orderid : props.searchDetails._id}`,
                                      finalBillAmount: Number(props?.searchDetails?.totalPrice),
                                    },
                                  })
                                  .then((res) => {
                                    if (res.data) {
                                    } else {
                                    }
                                  })
                                  .catch((err) => {
                                  });
                                }}
                                // className={classes.button}
                              >
                                {"Completed"}
                              </button>
                            )}
                            {completed === false && (
                              <>
                                <button
                                  style={{
                                    justifyContent: " center",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: 'rgb(137, 199, 74, 1)',
                                    minWidth: " auto",
                                    /* width: 50%; */
                                    height: "37px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    borderRadius: "5px",
                                    borderWidth: "1px",
                                    borderStyle: " solid",
                                    borderColor: "transparent",
                                    borderImage: "initial",
                                    overflow: "hidden",
                                    outline: "none",
                                    minHeight: " 14px",
                                    fontFamily: "Varela Round, sans-serif",
                                  }}
                                  type="button"
                                  onClick={() => {
                                    handleClickOpen();
                                    // setCompleted(true);
                                  }}
                                  // className={classes.button}

                                >
                                  {activeStep === 2
                                    ? "Assign shop assistant"
                                    : activeStep === 4 &&
                                    "Assign Delivery Partner"}
                                </button>
                                {props.AssignSuccess && (
                                  <button
                                    style={{
                                      justifyContent: " center",
                                      color: "rgb(255, 255, 255)",
                                      backgroundColor: 'rgb(137, 199, 74, 1)',
                                      minWidth: " auto",
                                      /* width: 50%; */
                                      height: "37px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      borderRadius: "5px",
                                      borderWidth: "1px",
                                      borderStyle: " solid",
                                      borderColor: "transparent",
                                      borderImage: "initial",
                                      overflow: "hidden",
                                      outline: "none",
                                      minHeight: " 14px",
                                      fontFamily: "Varela Round, sans-serif",
                                    }}
                                    type="button"
                                    onClick={() => {
                                      handleNext(label);
                                      // setBill(props?.searchDetails?.totalPrice);
                                      client
                                      .mutate({
                                        mutation: AddBill,
                                        variables: {
                                          orderid: `${props.orderid ? props.orderid : props.searchDetails._id}`,
                                          finalBillAmount: (bill!==""&&bill!==null)?Number(bill):Number(props?.searchDetails?.totalPrice),
                                        },
                                      })
                                      .then((res) => {
                                        if (res.data) {
                                        } else {
                                        }
                                      })
                                      .catch((err) => {
                                      });
                                    }}
                                    // className={classes.button}
                                  >
                                    {"Next"}
                                  </button>
                                )}
                              </>
                            )}

                            <br />

                            {/* MODAL FOR SHOP ASSISTANT */}

                            <Dialog
                              onClose={handleClose}
                              open={open}
                              fullWidth={fullWidth}
                              maxWidth={maxWidth}
                              fullScreen={fullScreen}
                            >
                              <DialogTitle>Shop Assistant List</DialogTitle>
                              <List sx={{ pt: 0 }}>
                                {props.Shopassistantlist &&
                                  props.Shopassistantlist.data &&
                                  props.Shopassistantlist.data
                                    .shopAssistantsList &&
                                  props.Shopassistantlist.data.shopAssistantsList&&
                                  props.Shopassistantlist.data.shopAssistantsList.length!==0?
                                  props.Shopassistantlist.data.shopAssistantsList.map(
                                    (s, index) => (
                                      <ListItem
                                        button
                                        onClick={() => {
                                          handleListItemClick(
                                            `${s.firstName} ${s.lastName}`,
                                            s.id,
                                            2
                                          );
                                          setCompleted(true);
                                        }
                                        }
                                        key={s.id}
                                      >
                                        <ListItemAvatar>
                                          <Avatar
                                            sx={{
                                              bgcolor: "#4caf50",
                                              color: "#a2cf6e",
                                            }}
                                          >
                                            <PersonIcon />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={`${s.firstName} ${s.lastName}`}
                                        />
                                      </ListItem>
                                    )
                                  )
                                :"No Shop Assistants Registered"}
                              </List>
                            </Dialog>

                            {/* MODAL END */}
                          </>
                        ) : activeStep === 4 ? (
                          <>
                            {((props.searchDetails && props.searchDetails.finalBillAmount === null) && (props?.searchDetails?.version === 1)) ?
                              <>
                                {
                                  completed === true &&
                                  <TextField
                                    type="number"
                                    onKeyPress={(event) => {
                                      if (event?.key === "-" || event?.key === "+") {
                                        event.preventDefault();
                                      }
                                    }}
                                    id="Final Bill Amount"
                                    placeholder="Final Bill Amount "
                                    required
                                    defaultValue={props.searchDetails && props.searchDetails.totalPrice}
                                    value={bill}
                                    onChange={(e) => {
                                      setBill(e.target.value);
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                  />
                                }
                                {bill !== "" && completed === true && (
                                  <button
                                    style={{
                                      justifyContent: " center",
                                      color: "rgb(255, 255, 255)",
                                      backgroundColor: 'rgb(137, 199, 74, 1)',
                                      minWidth: " auto",
                                      /* width: 50%; */
                                      height: "37px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      borderRadius: "5px",
                                      borderWidth: "1px",
                                      borderStyle: " solid",
                                      borderColor: "transparent",
                                      borderImage: "initial",
                                      overflow: "hidden",
                                      outline: "none",
                                      minHeight: " 14px",
                                      fontFamily: "Varela Round, sans-serif",
                                    }}
                                    type="button"
                                    onClick={() => {
                                      client
                                        .mutate({
                                          mutation: AddBill,
                                          variables: {
                                            orderid: `${props.orderid ? props.orderid : props.searchDetails._id}`,
                                            finalBillAmount: (bill === null) ? Number(props.searchDetails && props.searchDetails.totalPrice) : Number(bill),
                                          },
                                        })
                                        .then((res) => {
                                          if (res.data) {
                                            setCompleted(false);
                                            // props.statusChangeHandler({
                                            //   status: "Order-Ready",
                                            //   orderid: props.orderid?props.orderid: props.searchDetails._id,
                                            // });
                                            // props.getSearchDetails(props.orderid?props.orderid: props.searchDetails._id)
                                            // handleNext(label);
                                          } else {
                                            setError(true);
                                            // setErrorMsg(res.errors[0].message)
                                          }
                                        })
                                        .catch((err) => {
                                          setError(true);
                                          // setErrorMsg({err})
                                        });
                                    }}
                                    // className={classes.button}
                                  >
                                    {activeStep === steps.length - 1
                                      ? "Finish"
                                      : "Completed"}
                                  </button>
                                )}
                                {completed === false && (
                                  <>
                                    <TimeField {...props} orderid={`${props.orderid ? props.orderid : props.searchDetails._id}`} />
                                    <button
                                      style={{
                                        justifyContent: " center",
                                        color: "rgb(255, 255, 255)",
                                        backgroundColor: 'rgb(137, 199, 74, 1)',
                                        minWidth: " auto",
                                        height: "37px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        borderRadius: "5px",
                                        borderWidth: "1px",
                                        borderStyle: " solid",
                                        borderColor: "transparent",
                                        borderImage: "initial",
                                        overflow: "hidden",
                                        outline: "none",
                                        minHeight: " 14px",
                                        fontFamily: "Varela Round, sans-serif",
                                      }}
                                      type="button"
                                      onClick={handleClickOpen}
                                      // className={classes.button}
                                    >
                                      {"Assign Delivery Partner"}
                                    </button>
                                    {props.AssignPartnerSuccess && (
                                      <button
                                        style={{
                                          justifyContent: " center",
                                          color: "rgb(255, 255, 255)",
                                          backgroundColor: 'rgb(137, 199, 74, 1)',
                                          minWidth: " auto",
                                          height: "37px",
                                          fontSize: "16px",
                                          fontWeight: "600",
                                          borderRadius: "5px",
                                          borderWidth: "1px",
                                          borderStyle: " solid",
                                          borderColor: "transparent",
                                          borderImage: "initial",
                                          overflow: "hidden",
                                          outline: "none",
                                          minHeight: " 14px",
                                          fontFamily: "Varela Round, sans-serif",
                                        }}
                                        type="button"
                                        onClick={() => {
                                          setCompleted(false);
                                          handleNext(label);
                                          client
                                          .mutate({
                                            mutation: AddBill,
                                            variables: {
                                              orderid: `${props.orderid ? props.orderid : props.searchDetails._id}`,
                                              finalBillAmount: (bill === null||bill==="") ? Number(props.searchDetails && props.searchDetails.totalPrice) : Number(bill),
                                            },
                                          })
                                          .then((res) => {
                                            if (res.data) {
                                            } else {
                                              // setErrorMsg(res.errors[0].message)
                                            }
                                          })
                                          .catch((err) => {
                                            // setErrorMsg({err})
                                          });
                                        }}
                                        // className={classes.button}
                                      >
                                        {"Next"}
                                      </button>
                                    )}
                                  </>
                                )}
                              </>
                              :
                              <>
                                {/* GetEstimatedTime */}
                                <TimeField {...props} orderid={`${props.orderid ? props.orderid : props.searchDetails._id}`} />
                                <button
                                  style={{
                                    justifyContent: " center",
                                    color: "rgb(255, 255, 255)",
                                    backgroundColor: 'rgb(137, 199, 74, 1)',
                                    minWidth: "auto",
                                    height: "37px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    borderRadius: "5px",
                                    borderWidth: "1px",
                                    borderStyle: " solid",
                                    borderColor: "transparent",
                                    borderImage: "initial",
                                    overflow: "hidden",
                                    outline: "none",
                                    minHeight: " 14px",
                                    fontFamily: "Varela Round, sans-serif",
                                  }}
                                  type="button"
                                  onClick={handleClickOpen}
                                  // className={classes.button}
                                >
                                  {"Assign Delivery Partner"}
                                </button>
                                {props.AssignPartnerSuccess && (
                                  <button
                                    style={{
                                      justifyContent: " center",
                                      color: "rgb(255, 255, 255)",
                                      backgroundColor: 'rgb(137, 199, 74, 1)',
                                      minWidth: " auto",
                                      height: "37px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      borderRadius: "5px",
                                      borderWidth: "1px",
                                      borderStyle: " solid",
                                      borderColor: "transparent",
                                      borderImage: "initial",
                                      overflow: "hidden",
                                      outline: "none",
                                      minHeight: " 14px",
                                      fontFamily: "Varela Round, sans-serif",
                                    }}
                                    type="button"
                                    onClick={() => {
                                      setCompleted(false);
                                      handleNext(label);
                                      client
                                      .mutate({
                                        mutation: AddBill,
                                        variables: {
                                          orderid: `${props.orderid ? props.orderid : props.searchDetails._id}`,
                                          finalBillAmount: (bill === null||bill==="") ? Number(props.searchDetails && props.searchDetails.totalPrice) : Number(bill),
                                        },
                                      })
                                      .then((res) => {
                                        if (res.data) {
                                        } else {
                                          // setErrorMsg(res.errors[0].message)
                                        }
                                      })
                                      .catch((err) => {
                                        // setErrorMsg({err})
                                      });
                                    }}
                                    // className={classes.button}
                                  >
                                    {"Next"}
                                  </button>
                                )}
                              </>
                            }
                            <br />

                            {/* MODAL FOR SHOP ASSISTANT */}

                            <Dialog
                              onClose={handleClose}
                              open={open}
                              fullWidth={fullWidth}
                              maxWidth={maxWidth}
                              fullScreen={fullScreen}
                            >
                              <DialogTitle>Shop Assistant List</DialogTitle>
                              <List sx={{ pt: 0 }}>
                                {props.Shopassistantlist &&
                                  props.Shopassistantlist.data &&
                                  props.Shopassistantlist.data
                                    .shopAssistantsList &&
                                  props.Shopassistantlist.data.shopAssistantsList&&
                                  props.Shopassistantlist.data.shopAssistantsList.length!==0?
                                  props.Shopassistantlist.data.shopAssistantsList.map(
                                    (s) => (
                                      <ListItem
                                        button
                                        onClick={() =>
                                          handleListItemClick(
                                            `${s.firstName} ${s.lastName}`,
                                            s.id,
                                            4
                                          )
                                        }
                                        key={s.id}
                                      >
                                        <ListItemAvatar>
                                          <Avatar
                                            sx={{
                                              bgcolor: "#4caf50",
                                              color: "#a2cf6e",
                                            }}
                                          >
                                            <PersonIcon />
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={`${s.firstName} ${s.lastName}`}
                                        />
                                      </ListItem>
                                    )
                                  ):
                                "No Shop Assistants registered"}
                              </List>
                            </Dialog>

                            {/* MODAL END */}
                          </>
                        ) : activeStep === 5 && props.paymentStatus === 0 ? (
                          <button
                            style={{
                              justifyContent: " center",
                              color: "rgb(255, 255, 255)",
                              backgroundColor: 'rgb(137, 199, 74, 1)',
                              minWidth: " auto",
                              height: "50px",
                              fontSize: "16px",
                              fontWeight: "600",
                              borderRadius: "5px",
                              borderWidth: "1px",
                              borderStyle: " solid",
                              borderColor: "transparent",
                              borderImage: "initial",
                              overflow: "hidden",
                              outline: "none",
                              minHeight: " 14px",
                              fontFamily: "Varela Round, sans-serif",
                            }}
                            type="button"
                            onClick={() => {
                              client
                                .mutate({
                                  mutation: PaymentStatus,
                                  variables: {
                                    orderid: `${props.orderid ? props.orderid : props.searchDetails._id}`,
                                  },
                                })
                                .then((res) => {
                                  if (res.data) {
                                    handleNext(label);
                                  } else {
                                    setError(true);
                                  }
                                })
                                .catch((err) => {
                                  setError(true);
                                });
                            }}
                            // className={classes.button}
                          >
                            {"Payment accepted by store"}
                          </button>
                        ) : (
                          <>
                            {activeStep === steps.length - 1 &&
                              (props.paymentStatus === 1 ||
                                props.paymentStatus === 2) && (
                                <div>{"Payment recieved by nearShopz"}</div>
                              )}
                            <button
                              style={{
                                justifyContent: " center",
                                color: "rgb(255, 255, 255)",
                                backgroundColor: 'rgb(137, 199, 74, 1)',
                                minWidth: " auto",
                                height: "37px",
                                fontSize: "16px",
                                fontWeight: "600",
                                borderRadius: "5px",
                                borderWidth: "1px",
                                borderStyle: " solid",
                                borderColor: "transparent",
                                borderImage: "initial",
                                overflow: "hidden",
                                outline: "none",
                                minHeight: " 14px",
                                fontFamily: "Varela Round, sans-serif",
                              }}
                              type="button"
                              onClick={() => {
                                handleNext(label);
                              }}
                              // className={classes.button}
                            >
                              {activeStep === steps.length - 1
                                ? "Finish"
                                : "Completed"}
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        {activeStep === steps.length && (
          <Paper square elevation={0}
          //  className={classes.resetContainer}
           >
            <Typography>Order Closed</Typography>
          </Paper>
        )}
      </div>
    ) : (
      <div 
      // className={classes.root}
      >
        <div style={{ float: "right" }}>
          {activeStep === rejectedStep.length ? (
            <TextareaAutosize
              style={{ 
                width: "300px", 
                height: "100px", 
                marginTop: "80px" }}
              aria-label="minimum height"
              disabled
              name="comment"
              onChange={(e) => {
                changeComment(e);
              }}
              value={props.commentsSeller ? props.commentsSeller : state.comment}
              rowsMin={3}
            />
          ) : (
            <TextareaAutosize
              style={{ 
                width: "300px", 
                height: "100px", 
                marginTop: "80px" }}
              aria-label="minimum height"
              name="comment"
              onChange={(e) => {
                changeComment(e);
              }}
              value={props.commentsSeller ? props.commentsSeller : state.comment}
              rowsMin={3}
              placeholder="Please provide reason for reject incase you would like to reject this orders*"
            />
          )}
        </div>
        <ThemeProvider theme={muiTheme}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {rejectedStep.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                {index === 0 ? (
                  <Typography>
                    {props.status &&
                      props.status[index] &&
                      props.status[index].created}
                  </Typography>
                ) : index > 1 ? (
                  <Typography>
                    {props.status &&
                      props.status[index - 1] &&
                      props.status[index - 1].created}
                  </Typography>
                ) : null}
                <StepContent>
                  <div
                  //  className={classes.actionsContainer}
                   >
                    {index === 1 ? (
                      <div>
                        <button
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: 'rgb(137, 199, 74, 1)',
                            minWidth: " auto",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          type="button"
                          disabled={activeStep === 0}
                          onClick={handleAccept}
                          variant="success"
                          // className={classes.button}
                        >
                          Accept
                        </button>
                        <button
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: 'rgb(137, 199, 74, 1)',
                            minWidth: " auto",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          type="button"
                          onClick={handleReject}
                          // className={classes.button}
                        >
                          Reject
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: 'rgb(137, 199, 74, 1)',
                            minWidth: " auto",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          type="button"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                        >
                          Back
                        </button>
                        {rejectedStep.length - 1 && (state.comment === null || state.comment === "") ? (
                          <button
                            type="button"
                            style={{
                              height: "37px",
                              backgroundColor: "#b3b3b4",
                              color: "white",
                              fontSize: "16px",
                              fontWeight: 600,
                              border: "none",
                              borderRadius: 5,
                              marginLeft: 5,
                            }}
                            disabled
                          >
                            {activeStep === rejectedStep.length - 1
                              ? "Finish"
                              : "Completed"}
                          </button>
                        ) : (
                          <button
                            style={{
                              justifyContent: " center",
                              color: "rgb(255, 255, 255)",
                              backgroundColor: 'rgb(137, 199, 74, 1)',
                              minWidth: " auto",
                              marginLeft: 5,
                              height: "37px",
                              fontSize: "16px",
                              fontWeight: "600",
                              borderRadius: "5px",
                              borderWidth: "1px",
                              borderStyle: " solid",
                              borderColor: "transparent",
                              borderImage: "initial",
                              overflow: "hidden",
                              outline: "none",
                              minHeight: " 14px",
                              fontFamily: "Varela Round, sans-serif",
                            }}
                            type="button"
                            onClick={() => {
                              handleNext(label);
                            }}
                            // className={classes.button}
                          >
                            {activeStep === rejectedStep.length - 1
                              ? "Finish"
                              : "Completed"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </ThemeProvider>
        {activeStep === rejectedStep.length && (
          <Paper square elevation={0} 

          // className={classes.resetContainer}

          >
            <button
              disabled
              type="button"
              style={{
                height: "37px",
                backgroundColor: "white",
                color: "rgb(211, 35, 35)",
                border: "1px solid rgb(211, 35, 35)",
                fontSize: "18px",
                fontWeight: 600,
                marginLeft: 5,
                borderRadius: 5,
              }}
            >
              Order Rejected
            </button>
          </Paper>
        )}
      </div>
    );
}
