import * as yup from "yup";

const validationSchema = yup.object().shape({
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('Start Time is required')
    .test('fromtime-equal-totime', 'Start Time and End Time must be different', function (value) {
        const { totime } = this.parent;
        return value !== totime;
      }),

    totime:yup.string()
    .trim("Space not allowed")
    .required('End Time is required')
    .when(['fromtime'],(fromtime,schema)=>{
        return schema.test({
            test:function(totime){
                if(!fromtime || !totime) return true;
                const  formTimeStamp= new Date(fromtime).getTime();
                const toTimeStamp=new Date(totime).getTime();
                return formTimeStamp < toTimeStamp;
            },
            message:'End Time must be greater than Start Time',
        });
    })
    .test('fromtime-equal-totime', 'Start Time and End Time must be different', function (value) {
        const { fromtime } = this.parent;
        return value !== fromtime;
      }),
});
