import React from 'react'
import { Container } from 'react-bootstrap'
import { DESCRIPTION, MAIN_HEADING, termsofuse } from '../../Components/FooterLinks/Terms'
import CheckoutHeader from '../CheckoutPage/CheckoutHeader'
import { useStyles } from './style'

const Termsofuse = (props) => {
    const classes = useStyles();
    React.useEffect(()=>{
        document.body.scrollTop = 0;
    },[])
    return (
        <React.Fragment>
            <CheckoutHeader {...props}/>
            <Container className={classes.root}>
                <div className={classes.headingDiv}>
                    <div className={classes.center}>
                    <h1 className={classes.heading}>
                        {MAIN_HEADING}
                    </h1>
                    </div>
                </div>
                <div className={classes.description}>
                    {DESCRIPTION}
                </div>
                {termsofuse.map((terms,index)=>
                    <div className={classes.terms} key={index}>
                        <div className={classes.termsHeading}>
                            <h2 className={classes.termsTitle}>
                                {`${terms.index}.\t${terms.heading}`}
                            </h2>
                        </div>
                        <div className={classes.description}>
                            {terms.description}
                        </div>
                    </div>
                )}
            </Container>
        </React.Fragment>
    )
}

export default Termsofuse
