import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import CustomButton from '../button/index';

function CustomSelect1(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event, value) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter(option => option !== value));
    }
  };

  const handleRemove = (value) => {
    const updatedOptions = selectedOptions.filter((option) => option !== value);
    setSelectedOptions(updatedOptions);
  };

  return (
   
      
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 120,
      width: props.width,
      height: props.height,
      background: props.background,
      borderRadius: props.borderRadius,
      border: props.border
    }}>
        <div style={{display:"flex",flexDirection:"column",gap:"20px",}}>
            <div style={{background:"white"}}>
      <FormControl fullWidth  sx={{border: '1px solid #85BE49', borderRadius: '10px'}} size={props.size}>
        <InputLabel>{props.label}</InputLabel>
        <Select
          value={selectedOptions}
          sx={{fontSize:props.match&&"13px"}}
          onChange={() => {}}
          multiple
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300, // adjust as needed
              },
            },
            variant: "menu",
            getContentAnchorEl: null
          }}
          renderValue={(selected) => (
            <div>
              {selected.map((value, index) => (
                <span key={value} style={{fontSize:props.match&&"13px"}}>
                       
                </span>
              ))}
            </div>
          )}
        >
          {props.options.map(option => (
  <MenuItem key={option.value} value={option.value}>
    
    <FormControlLabel
      control={<Checkbox checked={selectedOptions.includes(option.value)} size={props.match ? "small" : "medium"} />}
      label={option.label}
      style={{ fontSize: '10px' }}
      onChange={(event) => handleChange(event, option.value)}
      sx={{ '& .MuiTypography-root': { fontSize: props.match&&'12px' } }}
    />
    
  </MenuItem>
))}

        </Select>
      </FormControl>
      </div>
      <div>
      <Box mt={2} style={{ display: "flex", gap: "20px",marginTop:"0px" }}>
        {selectedOptions.map((value) => (
          <div style={{ display: "flex", gap: "20px" }}>
            <CustomButton key={value} variant="contained" color={props.buttonColor} size={props.match?"small":"medium"}>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",fontSize:props.match&&"12px"}}>
                {value}
              <IconButton onClick={() => handleRemove(value)}>
              <CloseIcon  style={{color:"white",width:props.match?"12px":"17px",height:props.match?"12px":"14px"}}/>
              </IconButton>
                </div>
            </CustomButton>
            
          </div>
        ))}
      </Box>
      </div>
      </div>
    </Box>
  
   
  );
}

CustomSelect1.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  borderRadius: PropTypes.any.isRequired,
  border: PropTypes.any.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  buttonColor: PropTypes.string.isRequired,
  size:PropTypes.string.isRequired,
  match:PropTypes.bool,

};

CustomSelect1.defaultProps = {
  buttonColor: 'success'
};

export default CustomSelect1;
