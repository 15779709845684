// import React from 'react';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import {orderReady,accepted,orderPlaced,shopping} from '../../utils/image'
// import {
//     CustomCardContent,
//     ClickAwayListener,
//     Divider,
//     List,
//     ListItemIcon,
//     Paper,
//     Popper,
//     CustomCard,
//     Avatar,
//     Box
// } from '@material-ui/core';
// import ListItemButton from '@mui/material/ListItemButton';
// import { makeStyles} from '@material-ui/styles';
// import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
// import { useMediaQuery } from 'react-responsive';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
// import Badge from '@mui/material/Badge';
// // import { useTheme } from '@mui/material/styles';
// // third-party

// // project imports
// import Transitions from './Transitions';
// // import { green } from '@material-ui/core/colors';
// // import UpgradePlanCustomCard from './UpgradePlanCustomCard';

// // import User1 from 'assets/images/users/user-round.svg';

// // style const

// const useStyles = makeStyles(() => ({
//     navContainer: {

//         width: '100%',
//         maxWidth: '350px',
//         minWidth: '300px',
//         backgroundColor: 'white',
//         borderRadius: '10px',
//         height: '400px',
//         overflowY: 'scroll',
//         // [theme.breakpoints.down('sm')]: {
//         //     minWidth: '100%'
//         // }
//     },
//     headerAvatar: {
//         cursor: 'pointer',
//         // ...theme.typography.mediumAvatar,
//         margin: '8px 0 8px 8px !important'
//     },
//     profileChip: {
//         height: '48px',
//         alignItems: 'center',
//         borderRadius: '27px',
//         transition: 'all .2s ease-in-out',
//         borderColor: '#B9F6CA',
//         backgroundColor: '#B9F6CA',
//         '&[aria-controls="menu-list-grow"], &:hover': {
//             borderColor: '#00E676',
//             backgroundColor: '#00E676',
//             color: '#B9F6CA',
//             // '& svg': {
//             //     stroke: '#B9F6CA'
//             // }
//         }
//     },
//     profileLabel: {
//         lineHeight: 0,
//         padding: '12px'
//     },
//     listItem: {
//         marginTop: '5px'
//     },
//     CustomCardContent: {
//         padding: '16px !important'
//     },
//     CustomCard: {
//         backgroundColor: '#B9F6CA',
//         marginBottom: '16px',
//         marginTop: '16px'
//     },
//     searchControl: {
//         width: '100%',
//         paddingRight: '8px',
//         paddingLeft: '16px',
//         marginBottom: '16px',
//         marginTop: '16px'
//     },
//     startAdornment: {
//         fontSize: '1rem',
//         color: '#9E9E9E'
//     },
//     flex: {
//         display: 'flex'
//     },
//     name: {
//         marginLeft: '2px',
//         fontWeight: 400
//     },
//     ScrollHeight: {
//         height: '100%',
//         maxHeight: 'calc(100vh - 250px)',
//         overflowX: 'hidden'
//     },badgeWarning: {
//         backgroundColor: '#FFC107',
//         color: '#fff'
//     }
// }));
// const Notification = (props) => {
//     const classes = useStyles();
//     // const theme = useTheme();
//     const [open, setOpen] = React.useState(false);
//     const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
//     // const [badge, setBadge] = React.useState(false);
//     const anchorRef = React.useRef(null);

//     // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

//     const handleToggle = () => {
//         setOpen((prevOpen) => !prevOpen);
//     };
//     const handleClose = (event) => {
//         setOpen(false);
//     };
//     const OrderDetails = (id,orderNumber) => {
//         props.getSearchDetails(id);
//         props.history.push({
//             pathname: "/home/MyOrders/details",
//             state: {
//               orderId: id.toString(),
//               orderNumber:orderNumber
//             },
//           });
//     }
//     const prevOpen = React.useRef(open);
//     React.useEffect(() => {
//         // props.alertOrders();
//         props.OrderNotifications();
//         if (prevOpen.current === true && open === false) {
//             anchorRef.current.focus();
//         }
//         prevOpen.current = open;
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//      [open]);
//     return (
//         <>
//             <Box sx={{ color: 'action.active' }}>
//             <Badge badgeContent={props.OrderNotification&&
//             props.OrderNotification.data &&
//             props.OrderNotification.data.alertOrders.length} color="success">
//                     <NotificationsIcon style={{ color:'rgb(137, 199, 74, 1)',cursor:'pointer',fontSize:'25px'} } onClick={handleToggle}
//                         ref={anchorRef}
//                         aria-controls={open ? 'menu-list-grow' : undefined} />

// </Badge>
//             </Box>
//             {isSmallScreen?

//             <Dialog
//             // fullScreen={fullScreen}
//             // fullScreen
//             scroll={'paper'}
//             open={open}
//             onClose={handleToggle}
//             aria-labelledby="responsive-dialog-title"
//           >

//             <DialogContent>

//                                     <List component="nav" className={classes.navContainer} >
//                                         {props.OrderNotification&&
//                                                 props.OrderNotification.data &&
//                                                 props.OrderNotification.data.alertOrders.length!==0 ?
//                                                 (props.OrderNotification&&
//                                                 props.OrderNotification.data &&
//                                             props.OrderNotification.data.alertOrders.map((order,index) =>
//                                                 <React.Fragment key={index}>
//                                                     <ListItemButton
//                                                         className={classes.listItem}
//                                                         sx={{ borderRadius: `12px` }}
//                                                         key={`${order.orderid.id&&order.orderid.id}-${order.orderid.lastStatus}`}
//                                                     // selected={selectedIndex === 4}

//                                                      onClick={()=>{OrderDetails(order.orderid.id,order.orderNumber)}}
//                                                     >
//                                                         <ListItemIcon>
//                                                             <Avatar alt="Remy Sharp" src={order.orderid.lastStatus==="Order-Ready"?
//                                                         orderReady
//                                                         : order.orderid.lastStatus === 'Order-Accepted' ? accepted :
//                                                                                 order.orderid.lastStatus === 'Shopping-In-Progress' ? shopping
//                                                                                 :
//                                                                                 order.orderid.lastStatus === 'Order-Placed' ? orderPlaced :null} />
//                                                         </ListItemIcon>

//                                                          {order.notification}

//                                                     </ListItemButton>
//                                                      <Divider />
//                                                 </React.Fragment>
//                                             ))
//                                             :
//                                            <div style={{display:'flex',
//                                            flexDirection:'column',
//                                            paddingTop:'50px',
//                                            textAlign:'center',
//                                            alignItems:'center',
//                                            justifyContent:'center'
//                                            }}>
//                                         <CircleNotificationsIcon fontSize="large"/>
//                                         <div>
//                                                 No Berlin notifications
//                                         </div>
//                                         </div>
//                                         }
//                                     </List>

//             </DialogContent>

//           </Dialog>

//         :

//         <Popper
//         style={{ marginTop:'1.5%',position:'absolute',zIndex:6}}
//             placement="bottom-end"
//             open={open}
//             anchorEl={anchorRef.current}
//             role={undefined}
//             transition
//             disablePortalpopperOptions={{
//                 modifiers: [
//                     {
//                         name: 'offset',
//                         options: {
//                             offset: [0, 14]
//                         }
//                     }
//                 ]
//             }}
//         >
//             {({ TransitionProps }) => (
//                 <Transitions in={open} {...TransitionProps}>
//                     <Paper elevation={16} style={{
//                          padding: '12px',
//                          position:'absolute',
//                          top:0,
//                          right:0,
//                          zIndex:10,
//                      borderRadius: '14px', marginTop: '12px',backgroundColor:'#fff' }}>
//                         <ClickAwayListener onClickAway={handleClose}>
//                             <CustomCard border={false} elevation={0} content={false}>
//                                 <CustomCardContent className={classes.CustomCardContent}>
//                                     <List component="nav" className={classes.navContainer} >
//                                         {props.OrderNotification&&
//                                                 props.OrderNotification.data &&
//                                                 props.OrderNotification.data.alertOrders.length!==0 ?
//                                                 (props.OrderNotification&&
//                                                 props.OrderNotification.data &&
//                                             props.OrderNotification.data.alertOrders.map((order,index) =>
//                                                 <React.Fragment key={index}>
//                                                     <ListItemButton
//                                                         className={classes.listItem}
//                                                         sx={{ borderRadius: `12px` }}
//                                                         key={`${order.orderid.id&&order.orderid.id}-${order.orderid.lastStatus}`}
//                                                     // selected={selectedIndex === 4}

//                                                      onClick={()=>{OrderDetails(order.orderid.id,order.orderNumber)}}
//                                                     >
//                                                         <ListItemIcon>
//                                                             <Avatar alt="Remy Sharp" src={order.orderid.lastStatus==="Order-Ready"?
//                                                         orderReady
//                                                         : order.orderid.lastStatus === 'Order-Accepted' ? accepted :
//                                                                                 order.orderid.lastStatus === 'Shopping-In-Progress' ? shopping
//                                                                                 :
//                                                                                 order.orderid.lastStatus === 'Order-Placed' ? orderPlaced :null} />
//                                                         </ListItemIcon>

//                                                          {order.notification}

//                                                     </ListItemButton>
//                                                      <Divider />
//                                                 </React.Fragment>
//                                             ))
//                                             :
//                                            <div style={{display:'flex',
//                                            flexDirection:'column',
//                                            paddingTop:'50px',
//                                            textAlign:'center',
//                                            alignItems:'center',
//                                            justifyContent:'center'
//                                            }}>

//                                         <CircleNotificationsIcon fontSize="large"/>

//                                         <div>
//                                                 No new notifications
//                                         </div>
//                                         </div>
//                                         }
//                                     </List>
//                                 </CustomCardContent>
//                             </CustomCard>
//                         </ClickAwayListener>
//                         </Paper>
//                 </Transitions>
//             )}
//         </Popper>

//         }

//         </>
//     )
// }

// export default Notification

//new code by berlin
//notification drawer code




import React, { useEffect, useState } from "react";
import CustomDrawer from "../Commoncomponents/drawer";
import CustomCard from "../Commoncomponents/card/index";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Typography } from "../MUIComponents/index";
import { List } from "../MUIComponents/index";
import { ListItem } from "../MUIComponents/index";
import { ListItemText } from "../MUIComponents/index";
import { Divider } from "../MUIComponents/index";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import { Container } from "../MUIComponents/index";
import NotificationProfile from "../../utils/images/NotificationProfile.png";
import NotificationIcon from "../../utils/Icon/NotificationIcon.svg";
import NotificationDotIcon from "../../utils/Icon/NotificationDotIcon.svg";
import "./Notification.css";
import { useTheme } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import { HeaderFontSize_36_xs_25 } from "../Typography/index";
import { HeaderNavigationFontSize_20_xs_13 } from "../Typography/index";
import { contentFontSize_16_xs_10 } from "../Typography/index";
import {useDispatch} from 'react-redux'

export default function Notification(props) {
 
 
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [markUsRead, setMarkUsRead] = useState(false);
  const [drawerItems, setDrawerItems] = useState([]);
  const [draweItems1,setDrawerItems1]=useState([])
  const [clickedItems, setClickedItems] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const [allItemsClicked,setAllItemsClicked]=useState(null)

  const dispatch=useDispatch();

  useEffect(() => {
   
      
    props.alertOrders();

  
 
}, []);

     
  useEffect(() => {
   
   
    
      //props.alertOrders();
     
      if (props?.Alerts?.alertOrders !== undefined) {
        setDrawerItems1(props?.Alerts?.alertOrders);
        dispatch({type:"CLEAR_ALERTORDERS"}) 
      }


}, [props?.Alerts,props.lastStatusChangedSuccess, props.lastStatusChangedAllSuccess,props.lastStatusChangedfail,props.isOrderLoaded,props.lastStatusChangedAllFail]); 


  useEffect(() => {
    if (props?.Alerts?.alertOrders) {
      const initialArrayList = props?.Alerts?.alertOrders
        .filter(item => item.seenBy.length === 0)
        .map(item => item.id);
      setArrayList(initialArrayList);
      const clickedItems = props?.Alerts?.alertOrders.every(item => item.seenBy.length > 0);
      setAllItemsClicked(clickedItems);
      
   
    }
    
  }, [props?.Alerts,props.lastStatusChangedSuccess, props.lastStatusChangedAllSuccess,props.lastStatusChangedfail,props.isOrderLoaded,props.lastStatusChangedAllFail]);


  

  // useEffect(() => {
  //   props.alertOrders()
  //   if (props?.OrderNotification?.data?.alertOrders !== undefined) {
  //     setDrawerItems1(props?.OrderNotification?.data?.alertOrders);
  //   }
  // }, [props?.OrderNotification?.data?.alertOrders,props?.lastStatusChangedSuccess,props?.lastStatusChangedAllSuccess]);

  


  // useEffect(()=>{
  //     props.alertOrders()
  // },[props.lastStatusChangedSuccess,props.lastStatusChangedAllSuccess])

  // useEffect(() => {
    
  //   if (props.OrderNotification && props.OrderNotification.data && props.OrderNotification.data.alertOrders) {
  //     setDrawerItems(props.OrderNotification.data.alertOrders);
  //     setClickedItems(Array(props.OrderNotification.data.alertOrders.length).fill(false));
  //   }
  // }, [props?.OrderNotification]);

 
  

 
   

 
  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));



 
 
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
   // props.alertOrders()
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMarkUsRead = () => {
    setMarkUsRead(true);
   props.LastSeenChangeNew(arrayList)
   //props.alertOrders()
   setTimeout(()=>{
    props.alertOrders()
  },[1500])
  };

  const handleAllClick = () => {
    setMarkUsRead(false);
    dispatch({type:"CLEAR_SCENCE_STATUS"})
   // props.alertOrders()
  };

 

  const handleColorChange = (id) => {
    
    
    props.LastSeenChange({id})
    setTimeout(()=>{
      props.alertOrders()
    },[1500])
   //

  };
  

  const handleTotalColorChange = () => {
    setClickedItems(Array(drawerItems.length).fill(true));
  };


  const getTimeAgo = (timestampStr) => {
    const timestamp = parseInt(timestampStr, 10) || 0;
    const dateTime = new Date(timestamp);
    const now = new Date();
    const difference = now - dateTime; // Difference in milliseconds
  
    const minutes = Math.floor(difference / 60000);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    if (minutes < 60) {
      return `${minutes} mins`;
    } else if (hours > 0 && remainingMinutes > 0) {
      return `${hours} hr ${remainingMinutes} mins`;
    } else if (hours > 0) {
      return `${hours} hr`;
    } else {
      return `${minutes} mins`;
    }
  };


  return (
    <>
    
      <Box sx={{ color: "action.active", position: "relative" }}>
        {!allItemsClicked && (
          <img
            src={NotificationDotIcon}
            height={mdmatch?"6px":"11px"}
            width={mdmatch?"6px":"11px"}
            alt="Notification Dot Icon"
            style={{cursor:"pointer",position:"absolute",top:mdmatch?"1px":"-5px",right:mdmatch?"1px":"-5px"}}
          />
        )}
        <img
          src={NotificationIcon}
          alt="Notification Icon"
          style={{ cursor: "pointer",height: mdmatch?"20px":"30px", width: mdmatch?"16.84px":"26.84px" }}
          onClick={handleDrawerOpen}
        />
      </Box>

      <CustomDrawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        // width="550px"
        width={
          upXlmatch
            ? "550px"
            : xlmatch
            ? lgmatch
              ? mdmatch
                ? smmatch
                  ? xsmatch
                    ? "300px"
                    : "300px"
                  : "400px"
                : "450px"
              : "500px"
            : "550px"
        }
        height="100%"
        anchor="right"
      >
        <div
          onClick={handleDrawerClose}
          style={{
            position: "absolute",
            right: "15px",
            top: "15px",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              height: mdmatch ? "20px" : "27px",
              width: mdmatch ? "20px" : "27px",
              border: "2px solid #8B8989",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CloseIcon style={{ color: "#8B8989", width: mdmatch && "16px" }} />
          </div>
        </div>
        <Container>
          <Typography
            variant="h6"
            style={{
              marginTop: "40px",
              color: "#659B1B",
              fontFamily: "Roboto",
            }}
            fontSize={HeaderFontSize_36_xs_25}
            gutterBottom
          >
            Notifications
          </Typography>
          <Divider />
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ display: "flex", gap: "20px" ,marginTop:"20px"}}
          >
            <Typography
              onClick={handleAllClick}
              style={{
                borderBottom: markUsRead ? "" : "2px solid #659B1B",
                cursor: "pointer",
                color: markUsRead ? "#979797" : "#000000",
              }}
              fontSize={HeaderNavigationFontSize_20_xs_13}
              className="NotificationFontStyle"
            >
              All
            </Typography>
            <Typography
              onClick={() => {
                handleMarkUsRead();
                handleTotalColorChange();
              }}
              style={{
                borderBottom: markUsRead ? "2px solid #659B1B" : "",
                cursor: "pointer",
                color: markUsRead ? "#000000" : "#979797",
              }}
              fontSize={HeaderNavigationFontSize_20_xs_13}
              className="NotificationFontStyle"
            >
              Mark all as read
            </Typography>
          </Typography>
        </Container>
        {/* <Box sx={{ width: 500 }}> */}
       
        {props?.Alerts&&
    props?.Alerts?.alertOrders.length === 0 ? (
          <div style={{marginLeft:"25px",marginTop:"20px"}}>
          <Typography variant="subtitle1" gutterBottom>
            No new notifications
          </Typography>
          </div>
        ) : !markUsRead ? (
          <>
            <List>
              <Container
                style={{
                  marginTop:"10px",
                  display: "flex",
                  flexDirection: "Column",
                  gap: "20px",
                }}
              >
                {draweItems1.map((item, index) => (
    
                  <CustomCard
                    key={index}
                    width="100%"
                    height="100%"
                    borderRadius="8px"
                    boxShadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                    padding={mdmatch ? "8px" : "12px"}
                    // background={
                    //   clickedItems[index] ? "none" : "rgba(101, 155, 27, 0.1)"
                    // }
                    background={item.seenBy.length!==0?"transparent":"rgba(101, 155, 27, 0.1)"}
                    onClick={() => handleColorChange(item.id)}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxSizing: "border-box",
                        cursor:"pointer",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                        background: item.seenBy.length!==0 ? "none":"#659B1B",
                         // background:"#659B1B",
                          width: mdmatch ? "9px" : "12px",
                          height: mdmatch ? "9px" : "12px",
                        }}
                      ></div>
                    
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <Typography
                          fontSize={HeaderNavigationFontSize_20_xs_13}
                          className="NotificationFontStyle"
                        >
                          {item.orderNumber}
                        </Typography>
                        <Typography
                          fontSize={contentFontSize_16_xs_10}
                          className="NotificationFontStyle400"
                        >
                         {item.notification}
                        </Typography>
                      </div>
                      <Typography
                        fontSize={contentFontSize_16_xs_10}
                        className="NotificationFontStyle400"
                        style={{ marginLeft: "auto" }}
                      >
                       { getTimeAgo(item.updatedAt)}
                      </Typography>
                    </div>
                  </CustomCard>
                ))}
              </Container>
            </List>
          </>
        ) : (
          <>
            <List>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "Column",
                  gap: "20px",
                  marginTop:"10px"
                }}
              >
                {draweItems1.map((item, index) => (
    
                  <CustomCard
                    key={index}
                    width="100%"
                    height="100%"
                    borderRadius="8px"
                    boxShadow="rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
                    padding={mdmatch ? "8px" : "12px"}
                    // background={
                    //   clickedItems[index] ? "none" : "rgba(101, 155, 27, 0.1)"
                    // }
                 
                  >
                
                
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "space-between",
                        alignItems: "center",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                       //   background: clickedItems[index] ? "none" : "#659B1B",
                       background:"none",
                          width: mdmatch ? "9px" : "12px",
                          height: mdmatch ? "9px" : "12px",
                        }}
                      ></div>
                     
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                       <Typography
                          fontSize={HeaderNavigationFontSize_20_xs_13}
                          className="NotificationFontStyle"
                        >
                          {item.orderNumber}
                        </Typography>
                        <Typography
                          fontSize={contentFontSize_16_xs_10}
                          className="NotificationFontStyle400"
                        >
                          {item.notification}
                        </Typography>
                      </div>
                      <Typography
                        fontSize={contentFontSize_16_xs_10}
                        className="NotificationFontStyle400"
                        style={{ marginLeft: "auto" }}
                      >
                       { getTimeAgo(item.updatedAt)}
                      </Typography>
                    </div>
                  </CustomCard>
                ))}
              </Container>
            </List>
          </>
        )}
        {/* </Box> */}
      </CustomDrawer>
    </>
  );
}
