// import React from "react";
// import { RadioGroup } from "@mui/material";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Radio from "@mui/material/Radio";
// import Grid from "@mui/material/Grid";
// import FormLabel from "@mui/material/FormLabel";
// // import { InputField  } from '../FormFields';



// const SameDayDelivery = (params) => {
//   // const {
//   // 	formField: {minAmount}
//   //   } =params;
//   const [option, setState] = React.useState(`${params.retailerDetails&&params.retailerDetails.data&&params.retailerDetails.data.retailerdetails.stores[0]
//     &&params.retailerDetails.data.retailerdetails.stores[0].sameDayDelivery}`);
//     // React.useEffect(()=>{
//     //   console.log("same day delivery useeffect")
//     //   params.getSameDayDelivery(params.retailerDetails&&params.retailerDetails.data&&params.retailerDetails.data.retailerdetails.stores[0]
//     //     &&params.retailerDetails.data.retailerdetails.stores[0].sameDayDelivery);
//     // }
//     // ,
//     // [])
   
//   const getRadioChange = (e) => {
//     setState(e.target.value);
//     if(e.target.value==='true'||e.target.value===true)

//     {
//       params.setDeliveryDays(0)
//     }
//     else{
//       params.setDeliveryDays(1)
//     }
//     params.getSameDayDelivery(e.target.value);
//   };
//   return (
//     <Grid item xs={12}>
//       <FormLabel component="legend">{'Do you support same day delivery ?'}</FormLabel>
//       <RadioGroup row aria-label="position" name="position">
//         <FormControlLabel
//           value="true"
//           control={<Radio color="primary" />}
//           label="Yes"
//           checked={option === "true"}
//           onChange={(e) => {
//             getRadioChange(e);
//           }}
//         />
//         <FormControlLabel
//           value="false"
//           control={<Radio color="primary" />}
//           label="No"
//           checked={option === "false"}
//           onChange={(e) => {
//             getRadioChange(e);
//           }}
//         />
//       </RadioGroup>
//       {/* {
// 					option==="Yes"?
// 					<Grid item xs={12} sm={6}>
//           <InputField  fullWidth />
//         </Grid>
// 		:
// 		null
// 				} */}
//     </Grid>
//   );
// };
// export default SameDayDelivery;


import React from 'react'
import {HeaderNavigationFontSize_20,contentFontSize_18} from '../Typography/index';
import {Typography,Checkbox,useTheme,useMediaQuery} from '../MUIComponents/index'
import '../Profile/ManageSlots.css'


export default function SameDayDelivery(params) {
  const [option, setState] = React.useState(`${params.retailerDetails&&params.retailerDetails.data&&params.retailerDetails.data.retailerdetails.stores[0]
    &&params.retailerDetails.data.retailerdetails.stores[0].sameDayDelivery}`);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const smmatch=useMediaQuery(theme.breakpoints.down("sm"))

 const getRadioChange = (e) => {
    setState(e.target.value);
    if(e.target.value==='true'||e.target.value===true)

    {
      params.setDeliveryDays(0)
    }
    else{
      params.setDeliveryDays(1)
    }
    params.getSameDayDelivery(e.target.value);
  };

  return (
    <>
    <div style={{display:"flex",flexDirection:"column",gap:"15px"}}>
      <div>
    <Typography
                        className="chooseHolidaysStyle"
                        fontSize={contentFontSize_18}
                      >
                        Do you support same day delivery?<span style={{ color: "#EA4335" }}>*</span>
                      </Typography>
                      </div>
                      <div  style={{display:"flex",gap:smmatch?"80px":"60px"}}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                      <Checkbox
                      value="true"
                      checked={option === "true"}
                          
                          style={{
                            padding: 0,
                            borderRadius: 0,
                            color: "#85BE49",
                          }}
                          size={matches ? "small" : "medium"}
                          onChange={(e) => {
                                         getRadioChange(e);
                                       }}
                        />
                         <Typography
                          className="kmStyle"
                          fontSize={HeaderNavigationFontSize_20}
                        >
                          Yes
                        </Typography> 
                        </div>
                        <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                        value="false"
                        checked={option === "false"}
                          
                          style={{
                            padding: 0,
                            borderRadius: 0,
                            color: "#85BE49",
                          }}
                          size={matches ? "small" : "medium"}
                          onChange={(e) => {
                                         getRadioChange(e);
                                       }}
                        />
                         <Typography
                          className="kmStyle"
                          fontSize={HeaderNavigationFontSize_20}
                        >
                          No
                        </Typography> 
                        </div>


                      </div>

    </div>
    </>
  )
}
