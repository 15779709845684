// import React from "react";
// import Paper from "@mui/material/Paper";
// // import { makeStyles } from "@mui/system";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import { TextField } from "@mui/material";


// // const useStyles = makeStyles({
// // 	table: {
// // 		minWidth: 500,
// // 	},
// // });
// const EditDeliveryCharge = (props) => {
// 	const charges = [
// 		{ label: "0-3 Km", distanceValue: 3, value: null },
// 		{ label: "3-6 Km", distanceValue: 6, value: null },
// 		{ label: "6-9 Km", distanceValue: 9, value: null },
// 		{ label: "9-12 Km", distanceValue: 12, value: null },
// 		{ label: "12-15 Km", distanceValue: 15, value: null },
// 	];
	
// 	// const editDeliveryCharge = (params) => {
// 	// 	console.log(params.e.target.value);
// 	// 	console.log(params.index);
// 	// };
// 	// let { distanceTable, deliveryForm } = props.formConfiguration;
// 	// let maxDistanceValue = props.maxDistanceValue ? props.maxDistanceValue : "0";

// 	// let charge = distanceTable[1].charges.map((char) => {
// 	// 	return {
// 	// 		label: char.label,
// 	// 		distanceValue: char.distanceValue,
// 	// 		value: char.value,
// 	// 	};
// 	// });
// 	// const [charges, setState] = React.useState(charge);

// 	// const classes = useStyles();
// 	// const handleTableChange = (e, index) => {
// 	// 	let newCharges = [...charges];
// 	// 	newCharges[index].value = e.target.value;
// 	// 	setState(newCharges);
// 	// 	props.handleTableChange(e, index, distanceTable[1]);
// 	// };

// 	return (
	
// 		<TableContainer component={Paper} style={{paddingTop:20}}>
// 			<Table 
// 			// className={classes.table} 
// 			aria-label="simple table">
// 				<TableHead>
// 					<TableRow>
// 						<TableCell>Km from store</TableCell>
// 						<TableCell align="right">Charges(in Rupees)</TableCell>
// 					</TableRow>
// 				</TableHead>
// 				<TableBody >
// 					{charges.map((row, index) =>
// 				<React.Fragment key={index}>
// 						{row.distanceValue<=props.distance ? (
// 							<TableRow >
// 								<TableCell component="th" scope="row">
// 									{row.label}
// 								</TableCell>
// 								<TableCell align="right">
// 									<TextField
// 										index={index}
// 										type="number"
// 										variant="outlined"
// 										InputProps={{inputProps: { min: 0 },style: {height:50, width:100 } }}
// 										InputLabelProps={{
// 											shrink: true,
// 										}}
// 										onChange={(e) => {
// 											props.editDeliveryCharge({
// 												value: e.target.value,
// 												index: index,
// 												details: props.retailerDetails.data.retailerdetails,
// 											});
// 										}}
// 										name={row.label}
// 									    value={(props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryCharge[index].charge)||0}
// 									/>
// 								</TableCell>
// 							</TableRow>
// 						) : (null)}
// 						</React.Fragment>
// 					)}
// 				</TableBody>
// 			</Table>
// 		</TableContainer>

// 	);
// };

// export default EditDeliveryCharge;



import React, { useEffect } from 'react'
import {Grid,Typography,useTheme,useMediaQuery} from '../MUIComponents/index';
import { TextField } from '../MUIComponents/index';
import {contentFontSize_16} from '../Typography/index';
import "../Profile/ManageSlots.css";


function EditDeliveryCharge({props,distance,formik}) {

		const charges = [
		{ label: "0-3 Kilometers", distanceValue: 3, value: null ,fname:'firstKm'},
		{ label: "3-6 Kilometers", distanceValue: 6, value: null, fname:'secondKm'},
		{ label: "6-9 Kilometers", distanceValue: 9, value: null ,fname:'thirdKm'},
		{ label: "9-12 Kilometers", distanceValue: 12, value: null ,fname:'fourthKm'},
		{ label: "12-15 Kilometers", distanceValue: 15, value: null ,fname:'fifthKm'},
	];
	const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matches1 = useMediaQuery(theme.breakpoints.down("sm"));
  const matches2 = useMediaQuery(theme.breakpoints.up("xl"));
  return (
	<>
	<div className='flexcontainermanage'>
	{charges.map((row, index) =>(
		<div key={index} style={{margin:"5px 0px"}}>
			{row.distanceValue<=distance ? (
              <div>
                <div className="subfooterflex">
                  <div className="subfooterflexnew">
                    <Typography
                      fontSize={contentFontSize_16}
                      className="kmStyle"
                    >
                     {row.label}
                    </Typography>
                   
                  </div>
                  <TextField
                  sx={{
                    width:"100px",
                
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#85BE49', 
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#85BE49', 
                      },
                    },
                    '& .MuiInputBase-input': {
                      backgroundColor: 'white',
                      borderRadius:"10px" 
                    },
                  }}
                  InputProps={{
                   sx: {
                     fontSize: matches && '13px', 
                   },
               }}
               value={formik.values[row.fname]}
               onChange={formik.handleChange}
               error={formik.touched[row.fname] && Boolean(formik.errors[row.fname])}
               helperText={formik.touched[row.fname] && formik.errors[row.fname]}
               name={row.fname}
               
                    
                    height="100%"
                    size="small"
                    // match={matches ? true : false}
                    // border="1px solid #85BE49"
                    // borderRadius="10px"
				
																//name={row.label}
								// value={()}



                  ></TextField>
                </div>
              </div>
			  ):(null)}
			  </div>
			  ))} 
            </div>
	</>
  )
}

export default EditDeliveryCharge