import { createSelector } from "reselect";

const getSignInReducer = (state) => state.SignInReducer;
const getLoginReducer = (state) => state.LoginReducer;
const getSignUpReducer =(state)=> state.SignUpReducer
// const mergeLoginReducer = (LoginReducer) => LoginReducer;

const mergeSelectedReducers = (SignInReducer,LoginReducer,SignUpReducer) => ({...SignInReducer,...LoginReducer,...SignUpReducer});

export const SelectState = createSelector(
	[getSignInReducer,getLoginReducer,getSignUpReducer],
	mergeSelectedReducers,
);
