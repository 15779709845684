import * as firebase from "firebase/app";

import { initializeApp } from 'firebase/app';
import "firebase/messaging";
import { getMessaging } from 'firebase/messaging';

const firebaseconfig = {
  apiKey: "AIzaSyCIX1jt7367i6OuMi1aG1SUFURE9O2BG2k",
  authDomain: "nearshopz.firebaseapp.com",
  projectId: "nearshopz",
  storageBucket: "nearshopz.appspot.com",
  messagingSenderId: "482473533223",
  appId: "1:482473533223:web:d0ade637b85de510a45302",
  measurementId: "G-NCBT4E2MH0"
};
const app = initializeApp(firebaseconfig);

const messaging = getMessaging(app);


export { messaging };

// import * as firebase from "firebase/app";
// import "firebase/messaging";

// const firebaseconfig = firebase.initializeApp({
//   apiKey: "AIzaSyCIX1jt7367i6OuMi1aG1SUFURE9O2BG2k",
//   authDomain: "nearshopz.firebaseapp.com",
//   projectId: "nearshopz",
//   storageBucket: "nearshopz.appspot.com",
//   messagingSenderId: "482473533223",
//   appId: "1:482473533223:web:d0ade637b85de510a45302",
//   measurementId: "G-NCBT4E2MH0"
// });
// const messaging = firebaseconfig.messaging();
// export { messaging };
