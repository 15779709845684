// import React from "react";
// import "./index.css";
// import { initialConfiguration } from "./constants";
// import {
// 	handleFormChange,
// 	handleTimeChange,
// 	// handleTableChange,
// 	getLocationChange,
// 	getRadioButtonChange,
// 	formWrap,
// 	getCoordinatesMap,
// 	handleAddToForm,
// } from "./helper";
// // import { styles } from "./style"; // No need for styles import
// import CheckoutPage from '../../Components/CheckoutPage';
// import TextMobileStepper from "../../Components/CheckoutPage/MobileStepper";

// class SignUpNew extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			...initialConfiguration,
// 			activeStep: 0,
// 			confirmWindow: false,
// 		};
// 	}
// 	componentDidMount() {
// 		window.scrollTo(0, 0);
		
// 	}
// 	handleNext = () => {
// 		this.setState({ activeStep: this.state.activeStep + 1 });
// 	};
// 	handleBack = () => {
// 		this.setState({ activeStep: this.state.activeStep - 1 });
// 	};

// 	handleLogoUpload = (formdata) => {
// 		this.props.imageSubmit(formdata);
// 	};
// 	handleAlertComplete = () => {
// 		this.props.closeAlert();
// 	};
// 	confirmForm = () => {
// 		this.setState({
// 			confirmWindow: !this.state.confirmWindow,
// 		});
// 	};
// 	submitForm = async () => {
// 		let FormData = await formWrap(this.state, this.props.timings,this.props.DeliveryCharges);
// 		this.props.formSubmit(FormData);
// 	};

// 	render() {

// 		let props = {
// 			...this.props,
// 			formConfiguration: this.state,
// 			handleChange: handleFormChange,
// 			handleLogoUpload: this.handleLogoUpload,
// 			handleTimeChange:handleTimeChange,
// 			// handleTableChange: handleTableChange,
// 			getLocationChange: getLocationChange,
// 			getRadioButtonChange: getRadioButtonChange,
// 			getCoordinates: getCoordinatesMap,
// 			handleAddToForm: handleAddToForm,
// 			setOpenTime:this.props.setOpenTime,
// 			setCloseTime:this.props.setCloseTime
// 		};

// 		return (
// 			<TextMobileStepper
// 				id="signup-form"
// 				{...props}
// 				handleChange={handleFormChange}
// 				handleTimeChange={handleTimeChange}
// 				setOpenTime={this.props.setOpenTime}
// 			/>
// 		);
// 	}
// }
// export default SignUpNew;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
 
function SignUpNew() {
  const [formNumber, setFormNumber] = useState(
    Number(localStorage.getItem("loginformNumber"))
  );
  const navigate = useNavigate();
 
  useEffect(() => {
    if (localStorage.getItem("role") !== "Shop Assistant") {
      if (formNumber === 0) {
        navigate("/Register");
      } else if (formNumber === 1) {
        navigate("/RegisterForm1");
      } else if (formNumber === 2) {
        navigate("/RegisterForm2");
      } else if (formNumber === 3) {
        navigate("/RegisterForm3");
      }
    } else {
      navigate("/home/MyOrders");
    }
  }, [formNumber, navigate]);
 
  return <></>;
}
 
export default SignUpNew;