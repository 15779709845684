// import React, { useState } from 'react'
// import CustomButton from '../../Commoncomponents/button';
// import CustomBadge from '../../Commoncomponents/badge';
// import { useNavigate } from 'react-router-dom';
// import Arrow from '../../../utils/Icon/Arrow.svg';
// import { Container, Typography } from '@mui/material';
// import CustomCard from '../../Commoncomponents/card';
// import ClockAnimation from '../../../utils/Icon/ClockAnimation.svg';
// import OutForDeliveryFrontPage from '../OutForDeliveryFrontPage';
// function ReadyForDeliveryFrontPage() {
//   const OrderReady = JSON.parse(localStorage.getItem('OrderReady')) || []; 
//   const OutForDelivery = JSON.parse(localStorage.getItem('OrderReady')) || [];
//     const [activeButton, setActiveButton] = useState("readyForDelivery");
//     const isBadgeActive = activeButton === 'newOrders';
//     const [markUsRead, setMarkUsRead] = useState(false);
//     const [clickedItems, setClickedItems] = useState(Array(OrderReady.length).fill(false));
//     const nav = useNavigate()
//     function handleClickOne(){
//         nav("/home/MyOrders")
//       }
//       function handleClickTwo(){
//         nav("/home/MyOrders/ActiveOrders")
//       }
//       function handleClickThree(){
//         setActiveButton("readyForDelivery")
//         nav("/home/MyOrders//ReadyForDelivery")

//       }
//       const handleAllClick = () => {
//              setMarkUsRead(true);
//              nav("/home/MyOrders/ReadyForDelivery")
//       };
//       const handleoutForDelivery = () => {
//             setMarkUsRead(false);
//             nav("/home/MyOrders/OutForDelivery")
//       };
//       const handleTotalColorChange = () => {
//             setClickedItems(Array(OrderReady.length).fill(true));
//        };
//        function handleCardThree(){
//                nav("/home/MyOrders/ReadyForDeliveryDetails")
//                //console.log(selectedDataFromActiveOrders)
//                setMarkUsRead(true)
//        }
//        function handleCardFour(){
//                 nav("/home/MyOrders/OutForDeliveryDetails")
//                 //JSON.parse(localStorage.getItem('orderDetails'));
//      }

//   return (
//     <div>
//         <div className='btnStyles'>

//         <CustomButton
//         width='330px'
//           height='50px'
//           borderRadius='15px'
//           onClick={handleClickOne}
//           color={activeButton === 'newOrders' ? 'white' : '#646464'}
//           variant={activeButton === 'newOrders' ? 'contained' : 'outlined'}
//           background={activeButton === 'newOrders' ? '#659B1B': 'transparent'}
//           borderColor='#659B1B'
//        >
//          New Orders
//         <CustomBadge badgeContent='2' backgroundColor={isBadgeActive ? 'white':'none'} color={isBadgeActive?'#659B1B': '#646464'} borderColor={isBadgeActive?'none':'#646464'} border='2px solid '></CustomBadge>
//       </CustomButton>

//       <CustomButton
//         width='330px'
//           height='50px'
//           borderRadius='15px'
//           color={activeButton === 'activeOrders' ? 'white' : '#646464'}
//           variant={activeButton === 'activeOrders' ? 'contained' : 'outlined'}
//           onClick={handleClickTwo}
//           borderColor={activeButton === 'activeOrders' ? '#659B1B' : '#659B1B'}
//           background={activeButton === 'activeOrders' ? '#659B1B': 'transparent'}
//         >
//          Active Orders
//         </CustomButton>

//         <CustomButton
//         width='330px'
//          height='50px'
//          borderRadius='15px'
//           variant={activeButton === 'readyForDelivery' ? 'contained' : 'outlined'}
//           onClick={handleClickThree}
//           color={activeButton === 'readyForDelivery' ? 'white' : '#646464'}
//           borderColor={activeButton === 'readyForDelivery' ? '#659B1B' : '#659B1B'}
//           background={activeButton === 'readyForDelivery' ? '#659B1B': 'transparent'}
//         >
//           Ready for Delivery
//         </CustomButton>
//         </div>
//         <>
//   <div style={{ marginTop: '50px' }}>
//     <Container>
//       <Typography variant="subtitle1" gutterBottom style={{ display: 'flex', gap: '40px' }}>
//         <div onClick={handleAllClick} style={{ borderBottom: markUsRead ? '' : '2px solid #659B1B', cursor: 'pointer', fontWeight: 'bold' }}>
//           Ready For Delivery
//         </div>
//         <div
//           onClick={() => {
//             handleoutForDelivery();
//             handleTotalColorChange();
//           }}
//           style={{ borderBottom: markUsRead ? '2px solid #659B1B' : '', cursor: 'pointer', fontWeight: 'bold' }}
//         >
//           Out For Delivery
//         </div>
//       </Typography>
//     </Container>

//     {OrderReady.length === 0 ? (
//       <Typography variant="subtitle1" gutterBottom style={{ marginLeft: "30px", fontWeight: 'bold', marginTop: '30px' }}>
//         No new notifications
//       </Typography>
//     ) : markUsRead ? (
//       <>
//         <Container style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '50px' }}>
//           {OrderReady.map((item, index) => (
//             <CustomCard key={index} borderLeft='8px solid #FF9900' width='88%' height='120px' padding='10px'>
//               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//                 <div>
//                   <p>{item.orderId}</p>
//                   <p>{item.deliveryDate}</p>
//                   {/* <p>{item.time}</p> */}
//                 </div>

//                 <div style={{ marginTop: '30px', textTransform: 'lowercase' }}>
//                   <CustomButton variant='contained' background='#FF9900' width='120px' height='50px' textColor='white'>
//                     Ready For Delivery
//                   </CustomButton>
//                 </div>
//                 <div style={{ marginTop: '15px' }}>
//                   <img src={ClockAnimation} alt='animation'></img>
//                 </div>
//                 <div style={{ justifyItems: 'space-between', display: 'flex', flexDirection: 'row' }}>
//                   <img src={Arrow} alt='arrow' style={{ float: 'right', marginTop: '-13px', cursor: 'pointer' }} onClick={handleCardThree}></img>
//                 </div>
//               </div>
//             </CustomCard>
//           ))}
//         </Container>
//       </>

//     // ) : (
//     //   <>

//     //     <Container style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '50px' }}>
//     //       {OutForDelivery.map((item, index) => (
//     //         <CustomCard key={index} borderLeft='8px solid #85BE49' width='88%' height='120px' padding='10px'>
//     //           <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//     //             <div>
//     //               <p>{item.orderId}</p>
//     //               <p>{item.deliveryDate}</p>
//     //               <p>{item.time}</p>
//     //             </div>

//     //             <div style={{ marginTop: '30px', textTransform: 'lowercase' }}>
//     //               <CustomButton variant='contained' background='#85BE49' width='120px' height='50px' textColor='white'>
//     //                 Out for delivery
//     //               </CustomButton>
//     //             </div>
//     //             <div style={{ marginTop: '15px' }}>
//     //               <img src={ClockAnimation} alt='animation'></img>
//     //             </div>
//     //             <div style={{ justifyItems: 'space-between', display: 'flex', flexDirection: 'row' }}>
//     //               <img src={Arrow} alt='arrow' style={{ float: 'right', marginTop: '-13px', cursor: 'pointer' }} onClick={handleCardFour}></img>
//     //             </div>
//     //           </div>
//     //         </CustomCard>
//     //       ))}
//     //     </Container>


//     ):(null
//     )}
//   </div>
// </>


//   </div>
//   )
// }


// export default ReadyForDeliveryFrontPage


import React, { useEffect, useState } from 'react'
import CustomButton from '../../Commoncomponents/button';
import CustomBadge from '../../Commoncomponents/badge';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomCard from '../../Commoncomponents/card';
import Arrow from '../../../utils/Icon/Arrow.svg';
import ClockAnimation from '../../../utils/Icon/ClockAnimation.svg';
import { contentFontSize_20, contentFontsize_14_xs_10 } from '../../Typography/index';
import { ButtonFontSize_16_xs_11 } from '../../Typography/index';
import { HeaderNavigationFontSize_20_xs_13 } from '../../Typography/index';
import ShopAssistantClock from '../../../utils/Icon/ShopAssistantClock.svg';
import { contentFontsize_18_xs_11 } from '../../Typography/index';
import { ButtonFontSize_18 } from "../../Typography/index";
import './ReadyForDeliveryFrontPage.css'
function ReadyForDeliveryFrontPage(props) {
  useEffect((storeid)=>{
    props.getOrderCount(storeid)
  },[])
  const outReady = ("props",props&&props.OrderCount&&props.OrderCount.data&&props.OrderCount.data.outReadyOrdersCount)
  // console.log("bye",outReady)
  useEffect((storeid) => {
    // Dispatch the action when the component mounts
    props.getReadyOrdertlist(storeid)
  }, []);
  const ReadyorderList = ("props", props && props.readyOrders && props.readyOrders.data && props.readyOrders.data.readyOrdersList)

  useEffect((storeid) => {
    // Dispatch the action when the component mounts

     props.getOutForDeliverytlist(storeid);
  }, []);
  const [outForDelivery,setOutForDelivery] = useState([])
  useEffect(()=>{
  const OutForDeliveryList =
    ("props",
    props &&
      props.outForDeliveryOrders &&
      props.outForDeliveryOrders.data &&
      props.outForDeliveryOrders.data.outOfDeliveryList);
  setOutForDelivery(OutForDeliveryList)
})

  useEffect((storeid) => {
      
      
    props.getActiveOrdertlist(storeid);
  }, []);
  const[activeOrderList,setActiveOrderList] = useState([])
  useEffect(()=>{
  const ActiveorderList=("props",props&&props.activeOrders&&props.activeOrders.data&&props.activeOrders.data.activeOrdersList)
  setActiveOrderList(ActiveorderList)
})  


    useEffect((storeid) => {
      // Dispatch the action when the component mounts
  
      props.getNewOrdertlist(storeid); 
    }, []);
  
  
    const orderList =
    ("props",
      props &&
      props.newOrders &&
      props.newOrders.data &&
      props.newOrders.data.newOrdersList);
  



  const [details, setDetails] = useState([{ orderNumber: "1011", deliveryDate: "Fri Sep 19 2023", deliveryTime: "3:00-9:00pm" }, { orderNumber: "1012", deliveryDate: "Fri Sep 19 2023", deliveryTime: "3:00-9:00pm" }])





  const OrderReady = JSON.parse(localStorage.getItem('OrderReady')) || [];
  const [activeButton, setActiveButton] = useState("readyForDelivery");
  const isBadgeActive = activeButton;

  const [clickedItems, setClickedItems] = useState(Array(OrderReady.length).fill(false));

  const [ready, setReady] = useState(true)
  const [out, setOut] = useState(false)
  const nav = useNavigate()
  function handleClickOne() {
    setActiveButton("newOrders")
    nav("/home/MyOrders")
  }
  function handleClickTwo() {
    setActiveButton("activeOrders")
    nav("/home/MyOrders/ActiveOrders")
  }
  function handleClickThree() {
    setActiveButton("readyForDelivery")
    nav("/home/MyOrders/ReadyForDelivery")
    //setReady(true)
    //setMarkUsRead(false);
  }
  function handleAllClick() {
    setReady(true)
    setOut(false)
  }
  function handleoutForDelivery() {
    setOut(true)
    setReady(false)
    nav("/home/MyOrders/OutForDelivery")
    const outForDeliveryList =
      ("props",
        props &&
        props.outForDeliveryOrders &&
        props.outForDeliveryOrders.data &&
        props.outForDeliveryOrders.data.outOfDeliveryList);

    // Dispatch the action when the component mounts
    const storeid = localStorage.getItem('storeid')
    props.getOutForDeliverytlist(storeid);

  }
  function handleTotalColorChange() {
    setClickedItems(Array(OrderReady.length).fill(true));
  }
  const [selectedReadyOrder, setSelectedReadyOrder] = useState([])
  const [selectedReadyOrderDetails, setSelectedReadyOrderDetails] = useState([])
  function handleCardThree(orderId) {
    const selectedReadyOrder = ReadyorderList.find(
      (order) => order.id === orderId
    );

    // Update state with the selected order details
    setSelectedReadyOrderDetails((prevSelected) => [
      ...prevSelected,
      selectedReadyOrder,
    ]);

    // Set selectedActiveOrder state
    setSelectedReadyOrder(selectedReadyOrder);

    // Log the selected order details

    // Navigate to another component passing order id in state
    if (selectedReadyOrder && selectedReadyOrder.id) {
      nav("/home/MyOrders/ReadyForDeliveryDetails", { state: { orderid: selectedReadyOrder.id } });

      // Store selected order ID in localStorage
      localStorage.setItem("Rid", (selectedReadyOrder.id));
    } else {
      console.error("Invalid selectedActiveOrder or selectedActiveOrder.id");
    }




  }
  function handleOrderHistoryClick() {
    const readyfor = "Ready for Delivery"
    nav("/home/MyOrders/OrderHistory", { state: { name: readyfor } })
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matchessm = useMediaQuery(theme.breakpoints.down('md'));
  const matcheslg = useMediaQuery(theme.breakpoints.down('lg'));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));



  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: matches ? "10px" : "24px"

        }}
      >
        <CustomButton
          width="82%"
          height="50px"
          borderRadius="15px"
          onClick={handleClickOne}
          color={activeButton === "newOrders" ? "white" : "#646464"}
          variant={activeButton === "newOrders" ? "contained" : "outlined"}
          background={activeButton === "newOrders" ? "#659B1B" : "transparent"}
          borderColor="#659B1B"
          matchbtn={matches && true}
        >
          <Typography
            sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            fontSize={ButtonFontSize_18}
          >
            New Orders
          </Typography>
          <CustomBadge
            badgeContent={orderList && orderList?.length}
            backgroundColor={isBadgeActive === "newOrders" ? "white" : "none"}
            color={isBadgeActive === "newOrders" ? "#659B1B" : "#646464"}
            borderColor={isBadgeActive === "newOrders" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="82%"
          height="50px"
          borderRadius="15px"
          color={activeButton === "activeOrders" ? "white" : "#646464"}
          variant={activeButton === "activeOrders" ? "contained" : "outlined"}
          onClick={handleClickTwo}
          borderColor={activeButton === "activeOrders" ? "#659B1B" : "#659B1B"}
          background={
            activeButton === "activeOrders" ? "#659B1B" : "transparent"
          }
          matchbtn={matchessm && true}
        >
          <Typography fontSize={ButtonFontSize_18} sx={{ fontFamily: "Roboto", fontWeight: "500" }}>Active Orders</Typography>

          <CustomBadge
            badgeContent={activeOrderList&&activeOrderList?.length}
            backgroundColor={isBadgeActive === "activeOrders" ? "white" : "none"}
            color={isBadgeActive === "activeOrders" ? "#659B1B" : "#646464"}
            borderColor={isBadgeActive === "activeOrders" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="82%"
          height="50px"
          borderRadius="15px"
          variant={
            activeButton === "readyForDelivery" ? "contained" : "outlined"
          }
          onClick={handleClickThree}
          color={activeButton === "readyForDelivery" ? "white" : "#646464"}
          borderColor={
            activeButton === "readyForDelivery" ? "#659B1B" : "#659B1B"
          }
          background={
            activeButton === "readyForDelivery" ? "#659B1B" : "transparent"
          }
        >
          <Typography fontSize={ButtonFontSize_18} sx={{ fontFamily: "Roboto", fontWeight: "500" }}>
            Ready for Delivery
          </Typography>
          <CustomBadge
            badgeContent={outReady}
            backgroundColor={isBadgeActive === "readyForDelivery" ? "white" : "none"}
            color={isBadgeActive === "readyForDelivery" ? "#659B1B" : "#646464"}
            borderColor={isBadgeActive === "readyForDelivery" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>
      </div>

      <>
     


        <div style={{ marginTop: '50px', marginLeft: matchessm ? "0px" : "0px" }}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} md="auto">
              <Typography variant="subtitle1" gutterBottom style={{ display: 'flex', gap: '40px' }}>
                <div onClick={handleAllClick} style={{ borderBottom: ready ? '2px solid #659B1B' : "none", color: ready ? "#000000" : "#8B8989", cursor: 'pointer', fontWeight: "700", fontSize: { contentFontSize_20 }, fontFamily: "Nunito" }}>
                  <Typography fontSize={contentFontSize_20} sx={{ fontWeight: "700", fontFamily: "Nunito" }}>Ready For Delivery</Typography>
                </div>
                <div
                  onClick={() => {
                    handleoutForDelivery();
                    handleTotalColorChange();
                  }}
                  style={{ borderBottom: out ? '2px solid #659B1B' : "none", color: out ? "#000000" : "#8B8989", cursor: 'pointer', fontWeight: '700', fontFamily: "Nunito" }}
                >
                  <Typography fontSize={contentFontSize_20} sx={{ fontWeight: '700', fontFamily: "Nunito" }}>Out For Delivery</Typography>
                </div>
              </Typography>
            </Grid>
            <Grid item xs={12} md="auto" sx={{ marginRight: matchessm ? "20px" : matches ? "15px" : xlmatch ? "0px" : lgmatch ? "0px" : upXlmatch ? "0px" : "0px" }}>
              <Typography
                component="div"
                sx={{
                  color: "#85BE49",
                  fontWeight: "600",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontFamily: "Roboto",
                  marginTop: "-5px",
                  textAlign: xsmatch ? "left" : matches ? "left" : matchessm ? "left" : "right"

                }}
                fontSize={contentFontSize_20}
                onClick={() => handleOrderHistoryClick()}
              >
                Order History
              </Typography>
            </Grid>
          </Grid>
        </div>




        {ready && (
          <>
            <div className='containerscrollflex'>
              <div className='scrollable-content'>
                {ReadyorderList?.length === 0 ? (
                  "No results Found"
                ):(
                  <>
                {ReadyorderList?.map((item, index) => (
                  <>
                    {item?.lastStatus === "Order-Ready" && (

                      <CustomCard key={index} borderLeft='8px solid #FF9900' width={upXlmatch ? "100%" : xlmatch ? "100%" : matcheslg ? "100%" : matchessm ? "100%" : matches ? "100%" : "0%"} height='120px' padding="20px 0px 20px 48px" onClick={() => handleCardThree(item.id)} boxShadow="none">

                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={4}>
                            <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                              <div className='ReadyColumnFlex'>
                                <Typography fontSize={HeaderNavigationFontSize_20_xs_13} className='orderIdFontStyle'>#{item.orderNumber}</Typography>
                                <Typography fontSize={contentFontsize_14_xs_10} className='dateFontStyle1'>{item?.stats[3]?.created},{item?.stats[3]?.createdTime}</Typography>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}><img src={ShopAssistantClock} alt="clock icon" width={matcheslg && matches ? "10px" : "15px"} /> <Typography fontSize={contentFontsize_14_xs_10} className='AssistantFontStyle1'>{item?.preparationTime} mins</Typography></div>
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                              <div className='inProgressReady'>
                                <CustomButton variant='contained' background='#FF9900'><Typography fontSize={ButtonFontSize_16_xs_11} className="inProgressReadyFontStyle1">Ready for Delivery</Typography></CustomButton>
                              </div>
                            </Grid>
                            <Grid item xs={3} sm={5} md={5} lg={5} xl={5}>
                              {/* <div className='inProgressReady'> <img src={ClockAnimation} alt="clockAnimation" width={matches?"60px":"100px"}/></div> */}
                            </Grid>
                            <Grid item xs={2} sm={1} md={1} lg={1} xl={1} >
                              <div className='inProgressReady1' >
                                <img src={Arrow} onClick={() => handleCardThree(item.id)} alt="arrow icon" height={upXlmatch ? '20px' : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? '12px' : '12px') : '14px') : '16px') : '18px') : '20px'} />
                              </div>
                            </Grid>

                          </Grid>
                        </Box>
                      </CustomCard>
                    )}
                  </>
                ))}
                </>
              )}
              </div>
            </div>
          </>

        )}
      </>


    </div>

  )
}
export default ReadyForDeliveryFrontPage;
