import React from 'react';
import RetailerEdit from "../Components/MyAccountForm/RetailerEdit";
import SellerPortal from "../Routes/SellerPortal";
import signUpNew from "../Routes/SignUpNew";
import Termsofuse from "../Routes/Termsofuse";
import Privacypolicy from "../Routes/Privacypolicy";
import CheckoutPage from '../Components/CheckoutPage';
import Terms from '../Components/Banner/Terms';
import About from '../Components/Banner/About';
import Privacy from '../Components/Banner/Privacy';
import Contact from '../Components/Banner/Contact';
import PaymentForm from '../Components/CheckoutPage/Forms/PaymentForm'
import Additional from '../Components/CheckoutPage/Forms/Additional';
import DocumentUpload from '../Components/CheckoutPage/Forms/DocumentUpload/DocumentUpload';
import Subscription from '../Components/CheckoutPage/Forms/Subscription';

export const routesList = [
    {
        path: "/Register",
        element: <CheckoutPage/>,
        exact:false,
    },
    {
        path: "/RegisterForm1",
        element: <PaymentForm/>,
        exact:false,
    },
    {
        path: "/RegisterForm2",
        element:<Additional />,
        exact:false,
    },
    {
        path: "/RegisterForm3",
        element: <DocumentUpload/>,
        exact:false,
    },
    {
        path: "/Subscription",
        element: <Subscription/>,
        exact:false,
    },
    {
        path: "/Blogs",
        element: <About/>,
        exact:false, 
    },
    {
        path: "/Terms&Conditions",
        element: <Terms/>,
        exact:false, 
    },
    {
        path: "/Privacy&Policy",
        element: <Privacy/>,
        exact:false, 
    },
    {
        path: "/Contact",
        element: <Contact/>,
        exact:false, 
    },
    {
        path: "/seller-registration",
        element: <signUpNew/>,
        exact:false,
    },
    {
        path: "/home/MyProfile",
        element: <RetailerEdit/>,
        exact:false,
    },
    {
        path:'/privacy-policy',
        element:<Privacypolicy/>,
        exact:true,
    },
    {
        path:'/termsofuse',
        element:<Termsofuse/>,
        exact:true,
    },
    {
        path: "*",
        element: <SellerPortal/>,
        exact:false,
    },
    // {
    //     path:'/MyOrders/GoogleMap',
    //     element:<GoogleMapOrder/>,
    //     exact:true,

    // }
];
