import { ApolloClient , InMemoryCache } from '@apollo/client/core';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { ApolloLink } from '@apollo/client';

const UploadLink = new createUploadLink({
  uri: `${process.env.REACT_APP_SERVER_URL}`,
  // uri: `https://testserver.nearshopz.com/sellergraphql/v1`,
});


const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  operation.setContext({
    headers: {
      authorization: "Bearer " + token,
      mode: 'no-cors'
    },
  });
  return forward(operation);
}); 

const client = new ApolloClient({
  link: authLink.concat(UploadLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
      
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    // mutate:{
    //   fetchPolicy: "no-cache",
    //   errorPolicy: "all",
    // }
  },
});
export default client;
