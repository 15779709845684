export function Default() {
	return {
		SameDayDelivery:true,
		isImageLoading: false,
		isImageUploaded: false,
		isImageFailed: false,
		imageSignedUrl: null,
		isSignLoading: false,
		isSignSuccess: false,
		isSignFailes: false,
		isChangeschedule:false,
		chargeTableView: false,
		newImageLoading: false,
		newImageUploaded: false,
		newImageFailed: false,
		signupLoading:false,
		signupSuccess:false,
		signupError:false,
		signupkNetworError:false,
		passwordErrors: [],
		aadharUploaded:false,
		aadharFailed:false,
		licenseUploaded:false,
		licenseFailed:false,
		fssaiUploaded:false,
		fssaiFailed:false,
		aadharUrl:null,
		licenseUrl:null,
		fssaiUrl:null,
		GstUrl:null,
		panUpload:false,
		panFailed:false,
		panUrl:null,
		openTime:null,
		closeTime:null,
		slotsChecked:Array(6).fill(false),
		DeliveryDays:null,
		businessTypeId:[],
		storeName:null,
		storeDescription:null,
		storeLocation:null,
		storeCoordinates:null,
		businessTypeError:false,
		loading: false,
		data: null,
		error: null,
		message: null,
		isOpen:false,
		formNumber:null,
		sellerRegSuccess:null,
		storeLogoUrl:null,
		form4Success:false,
		timings: [
			{
				day: "Sunday",
				slots: [
					{ intvl: "9AM-12 PM", status: false },
					{ intvl: "12PM-3PM", status: false },
					{ intvl: "3PM-6PM", status: false },
					{ intvl: "6PM-9PM", status: false },
				],
			},
			{
				day: "Monday",
				slots: [
					{ intvl: "9AM-12 PM", status: false },
					{ intvl: "12PM-3PM", status: false },
					{ intvl: "3PM-6PM", status: false },
					{ intvl: "6PM-9PM", status: false },
				],
			},
			{
				day: "Tuesday",
				slots: [
					{ intvl: "9AM-12 PM", status: false },
					{ intvl: "12PM-3PM", status: false },
					{ intvl: "3PM-6PM", status: false },
					{ intvl: "6PM-9PM", status: false },
				],
			},
			{
				day: "Wednesday",
				slots: [
					{ intvl: "9AM-12 PM", status: false },
					{ intvl: "12PM-3PM", status: false },
					{ intvl: "3PM-6PM", status: false },
					{ intvl: "6PM-9PM", status: false },
				],
			},
			{
				day: "Thursday",
				slots: [
					{ intvl: "9AM-12 PM", status: false },
					{ intvl: "12PM-3PM", status: false },
					{ intvl: "3PM-6PM", status: false },
					{ intvl: "6PM-9PM", status: false },
				],
			},
			{
				day: "Friday",
				slots: [
					{ intvl: "9AM-12 PM", status: false },
					{ intvl: "12PM-3PM", status: false },
					{ intvl: "3PM-6PM", status: false },
					{ intvl: "6PM-9PM", status: false },
				],
			},
			{
				day: "Saturday",
				slots: [
					{ intvl: "9AM-12 PM", status: false },
					{ intvl: "12PM-3PM", status: false },
					{ intvl: "3PM-6PM", status: false },
					{ intvl: "6PM-9PM", status: false },
				],
			},
		],
	};
}
