// import React from "react";
// import { Icon } from "@iconify/react";
// // material
// import { alpha, styled } from "@mui/material/styles";
// import { Card, Typography } from "@mui/material";
// // utils
// import { fShortenNumber } from "./formatNumbers";

// // ----------------------------------------------------------------------



// const RootStyle = styled(Card)(({ theme }) => ({
//   boxShadow: "none",
//   textAlign: "center",
//   padding: theme.spacing(5, 0),
//   color: "#7A0C2E",
//   backgroundColor: "#FFE7D9",
//   borderRadius: "16px",
// }));

// const IconWrapperStyle = styled("div")(({ theme }) => ({
//   margin: "auto",
//   display: "flex",
//   borderRadius: "50%",
//   alignItems: "center",
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: "center",
//   marginBottom: theme.spacing(3),
//   color: theme.palette.error.dark,
//   backgroundImage: `linear-gradient(135deg, ${alpha(
//     theme.palette.error.dark,
//     0
//   )} 0%, ${alpha(theme.palette.error.dark, 0.24)} 100%)`,
// }));

// // ----------------------------------------------------------------------


// export default function OrderTotal(props) {
//   return (
//     <RootStyle>
//       <IconWrapperStyle>
//         <Icon icon="icon-park-outline:transaction-order" width={24} height={24} />
//       </IconWrapperStyle>
//       <Typography variant="h3" style={{ fontWeight: 700, lineHeight: 1.5, color: "#7A0C2E" }}>
//         {fShortenNumber(props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.totalOrdersRecieved)}
//       </Typography>
//       <Typography
//         variant="subtitle2"
//         style={{ fontWeight: 600, lineHeight: 22 / 14, color: "#7A0C2E" }}
//         sx={{ opacity: 0.72 }}
//       >
//         Orders
//       </Typography>
//     </RootStyle>
//   );
// }


//new code
import React from 'react'
import { Typography } from '../MUIComponents/index';
import {HeaderFontSize_24} from '../Typography/index';
import {HeaderNavigationFontSize_12} from '../Typography/index';
import { CircularProgressbar } from "react-circular-progressbar";
import { fShortenNumber } from "./formatNumbers";


export default function OrderTotal({ upXlmatch, xlmatch, lgmatch, mdmatch, smmatch, xsmatch,...props }) {

  const percentage = 66;
  return (
    <>
     <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  height:"100%",
                  gap: upXlmatch
                    ? "50px"
                    : xlmatch
                    ? lgmatch
                      ? mdmatch
                        ? smmatch
                          ? xsmatch
                            ? "5px"
                            : "3px"
                          : "5px"
                        : "5px"
                      : "40px"
                    : "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // gap: upXlmatch
                    //   ? "8px"
                    //   : xlmatch
                    //   ? lgmatch
                    //     ? mdmatch
                    //       ? smmatch
                    //         ? xsmatch
                    //           ? "0px"
                    //           : "1px"
                    //         : "4px"
                    //       : "5px"
                    //     : "6px"
                      // : "7px",
                      justifyContent: "space-between",
                  }}
                >
                  <Typography
                    fontSize={HeaderFontSize_24}
                    className="dashboardFontFamily"
                  >
                    {props?.NewDashboardDetails &&
         props?.NewDashboardDetails?.getDashboardNew &&
          
         props?.NewDashboardDetails?.getDashboardNew?.totalOrders}
                  </Typography>
                  <Typography
                    fontSize={HeaderNavigationFontSize_12}
                    className="dashboardFontFamily"
                    style={{ color: "#B4B1B1" }}
                  >
                    Orders
                  </Typography>
                  <Typography
                    fontSize={HeaderNavigationFontSize_12}
                    className="dashboardFontFamily"
                    style={{ color: "#659B1B" }}
                  >
                    +456.65
                  </Typography>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent:smmatch?"flex-end":"center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      styles={{
                        root: {
                          height: upXlmatch
                            ? "59px"
                            : xlmatch
                            ? lgmatch
                              ? mdmatch
                                ? smmatch
                                  ? xsmatch
                                    ? "45px"
                                    : "45px"
                                  : "45px"
                                : "45px"
                              : "50px"
                            : "100px",
                          width: upXlmatch
                            ? "59px"
                            : xlmatch
                            ? lgmatch
                              ? mdmatch
                                ? smmatch
                                  ? xsmatch
                                    ? "45px"
                                    : "45px"
                                  : "45px"
                                : "45px"
                              : "50px"
                            : "100px",
                        },
                        path: {
                          stroke: `#D739F1`,
                          strokeLinecap: "butt",
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          transform: "rotate(0.15turn)",
                          transformOrigin: "center center",
                        },
                        trail: {
                          stroke: "rgba(215, 57, 241, 0.25)",
                          strokeLinecap: "butt",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        text: {
                          fill: "green",
                          fontSize: "13px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
    </>
  )
}


