
//new code by celesta
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CustomToggleButton = ({
  initialOnline,
  onClick,
  toggleBtnCirStyle,
  boxStyle,
  disOfflineStyle,
  offlineCirStyle,
  disOnlineStyle,
  onlineCirStyle,
  textOne,
  textTwo,
  active,
  textFont,
  
}) => {
  const [isOnline, setOnline] = useState(initialOnline);
  

  const handleToggle = () => {
    setOnline(!isOnline);
    if (onClick) {
      onClick(!isOnline);
    }
  };

  const borderStyleCon = isOnline
  ? { ...toggleBtnCirStyle, border: '1px solid #72B01D' }
  : { ...toggleBtnCirStyle, border: '1px solid #8B8989' };
 
  return (
    <div style={borderStyleCon} onClick={handleToggle}>
      <div style={boxStyle}>
        <div style={{ ...disOfflineStyle, display: isOnline ? 'none' : 'flex' }} id="toggleBtn-offline">
          <span style={offlineCirStyle}></span>
          <span style={{ color: '#8B8989',fontSize:textFont }}>{textTwo&&textTwo}</span>
        </div>
        <div style={{ ...disOnlineStyle, display: isOnline ? 'flex' : 'none' }} id="toggleBtn-online">
          <span style={{ color: '#72B01D',fontSize:textFont}}>{textOne&&textOne}</span>
          <span style={{ ...onlineCirStyle }}></span>

          {/* <span style={{color:"green"}}>online</span> <span className="online-cir"></span> */}
          
        </div>
      </div>
    </div>
  );
};

CustomToggleButton.propTypes = {
  initialOnline: PropTypes.bool,
  onClick: PropTypes.func,
  toggleBtnCirStyle: PropTypes.object,
  boxStyle: PropTypes.object,
  disOfflineStyle: PropTypes.object,
  offlineCirStyle: PropTypes.object,
  disOnlineStyle: PropTypes.object,
  onlineCirStyle: PropTypes.object,
  active: PropTypes.string,
  textOne:PropTypes.string,
  textTwo:PropTypes.string,
  textFont:PropTypes.string,

};

CustomToggleButton.defaultProps = {
  initialOnline: false,
  onClick: null,
  toggleBtnCirStyle: {},
  boxStyle: {},
  disOfflineStyle: {},
  offlineCirStyle: {},
  disOnlineStyle: {},
  onlineCirStyle: {},
  active: 'active',
};

export default CustomToggleButton;
