// import React, { Component } from "react";
// import { Row, Col  } from "react-bootstrap";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from "@mui/material/Avatar";
// import { AiOutlineWhatsApp } from "react-icons/ai";
// import WifiCalling3RoundedIcon from "@mui/icons-material/WifiCalling3Rounded";
// import { Container } from "@mui/material";

// class index extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//         isMobile: window.innerWidth < 768
//     };
//   }
//   updateIsMobile=()=> {
//     this.setState({
//         isMobile: window.innerWidth < 768
//     });
// }


// componentWillUnmount() {
//     window.removeEventListener('resize', this.updateIsMobile);
// }
//   componentDidMount() {
//     window.addEventListener('resize', this.updateIsMobile);
//   }

  
//   render() {
//     return (
//       this.state.isMobile ?
//       <div
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           width: "100%",
//         }}
//       >
//         <Row>
//           <Col sm={6}>
//             <h6
//               style={{
//                 color: "rgb(13, 17, 54)",
//                 fontSize: "18px",
//                 fontWeight: "600px",
//                 paddingBottom: "10px",
//                 paddingTop: "20px",
//                 textAlign: "center",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <b>NearShopz Support </b>
//             </h6>
//           </Col>
//         </Row>
//         <List sx={{ width: "100%", maxWidth: 160 }}>
//           <ListItem>
//             <ListItemAvatar>
//               <Avatar sx={{ bgcolor: "rgb(137, 199, 74, 1)" }}>
//                 <WifiCalling3RoundedIcon />
//               </Avatar>
//             </ListItemAvatar>

//             <ListItemText
//               primary="Call Us - 9288088002"
//               secondary="Working hours from 09:00 AM to 09:00 PM"
//             />
//           </ListItem>

//           <ListItem>
//             <ListItemAvatar>
//               <Avatar sx={{ bgcolor: "rgb(137, 199, 74, 1)" }}>
//                 <AiOutlineWhatsApp />
//               </Avatar>
//             </ListItemAvatar>

//             <ListItemText
//               primary="Chat with us - 9288088002"
//               secondary="24 x 7 Support"
//             />
//           </ListItem>
//         </List>
//       </div>

//       :

//       <Container maxWidth='sm'
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           // width: "800px",
//         }}
//       >
//         <Row style={{
//                 textAlign: "center",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}>
//             <h6
//               style={{
//                 color: "rgb(13, 17, 54)",
//                 fontSize: "18px",
//                 fontWeight: "600px",
//                 paddingTop: "20px",
//                 textAlign: "center",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <b>NearShopz Support</b>
//             </h6>
//         </Row>
//         <List sx={{ width: "100%", maxWidth: 360 }}>
//           <ListItem>
//             <ListItemAvatar>
//               <Avatar sx={{ bgcolor: "rgb(137, 199, 74, 1)" }}>
//                 <WifiCalling3RoundedIcon />
//               </Avatar>
//             </ListItemAvatar>

//             <ListItemText
//               primary="Call Us - 9288088002"
//               secondary="Working hours from 09:00 AM to 09:00 PM"
//             />
//           </ListItem>

//           <ListItem>
//             <ListItemAvatar>
//               <Avatar sx={{ bgcolor: "rgb(137, 199, 74, 1)" }}>
//                 <AiOutlineWhatsApp />
//               </Avatar>
//             </ListItemAvatar>

//             <ListItemText
//               primary="Chat with us - 9288088002"
//               secondary="24 x 7 Support"
//             />
//           </ListItem>
//         </List>
//       </Container>
//     );
//   }
// }

// export default index;



//new code
//help code


import React from "react";
import CustomCard from "../Commoncomponents/card/index";
import { Divider, Grid, Box } from "../MUIComponents/index";
import CustomButton from "../Commoncomponents/button/index";
import callushelp from "../../utils/images/callushelp.png";
import chatushelp from "../../utils/images/chatushelp.png";
import { Typography } from "../MUIComponents/index";
import "./Help.css";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {contentFontSize_18} from '../Typography/index';
import { contentFontSize_16 } from "../Typography/index";
import {ButtonFontSize_16} from "../Typography/index";
import {HeaderFontSize_40} from "../Typography/index";
import {useMediaQuery} from '../MUIComponents/index';
import {useTheme} from '../MUIComponents/index';

export default function Index() {
 

  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  function handleHelp(){
    navigate('/home/Dashboard');
  }


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const mdmatch=useMediaQuery(theme.breakpoints.down('md'));
  const xlupmatch=useMediaQuery(theme.breakpoints.up('xl'))
  
  return (
    <div style={{ background: "#F4F5F9" }}>
      <div
        style={{
          fontFamily:"Roboto",
          fontSize: "20px",
          fontWeight: "300",
          padding: "10px",
          boxShadow: "border-box",
        }}
      >
        <Typography fontSize={HeaderFontSize_40}>Get Help</Typography>
        
      </div>
      <Divider />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%", // Ensure the container takes up the full viewport height
          marginTop: "50px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomCard
                width="100%"
                height="100%"
                boxShadow="none"
                background="white"
                padding={mdmatch?"30px":"40px 70px 40px 70px"}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <CustomCard padding={matches?"10px 10px":"10px 20px"} width={xlupmatch?"500px":"100%"}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{paddingTop:"0px",paddingLeft:"0px"}}>
                          <div style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                        <img
                            src={callushelp}
                            alt="call us"
                            // style={{ width:"100%" }}
                            width={matches?"100%":"80px"}
                            height={matches?"100%":"80px"}
                          />
                          </div>

                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9} style={{paddingTop:"0px"}}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "3px",
                                flexDirection: "column",
                              }}
                            >
                              <Typography fontSize={contentFontSize_18}>
                                <div className="callUsText">
                                  Call Us - 7736877750
                                </div>
                              </Typography>
                              <Typography fontSize={contentFontSize_16}>
                                <div className="secondStyle">
                                  Working Hours from 09:00 AM to 09:00 PM
                                </div>
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                  <CustomCard padding={matches?"10px 10px":"10px 20px"} width={xlupmatch?"500px":"100%"}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{paddingTop:"0px",paddingLeft:"0px"}}>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",height:"100%"}}>
                          <img
                            src={chatushelp}
                            alt="Chat with us"
                            width={matches?"100%":"80px"}
                            height={matches?"100%":"80px"}
                          />
                          </div>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9} lg={9} xl={9} style={{paddingTop:"0px"}}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "3px",
                                flexDirection: "column",
                              }}
                            >
                              <Typography fontSize={contentFontSize_18}>
                                <div className="callUsText">
                                  Chat with us - 7736877750
                                </div>
                              </Typography>
                              <Typography fontSize={contentFontSize_16}>
                                <div className="secondStyle">
                                  24 x 7 Support
                                </div>
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                </div>

                <div style={{ textAlign: "center", marginTop: "30px" }}>
              { role==='Store Manager'&&   (<CustomButton
                    width="70%"
                    height="100%"
                    borderRadius="11px"
                    backgroundColor="#85BE49"
                    onClick={handleHelp}
                    padding={mdmatch?"10px":"15px"}
                    matchPadding={true}
                  >
                    <Typography fontSize={ButtonFontSize_16} className="buttonHelpStyle">
                      Back to Dashboard
                    </Typography>
                  </CustomButton>)}
                </div>
              </CustomCard>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

