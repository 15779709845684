
// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Drawer from '@mui/material/Drawer';
// import clsx from "clsx";

// // import Typography from '@mui/material/Typography';
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import { useHistory } from "react-router-dom";
// import { Button } from "@material-ui/core";
// // import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiAlert from "@material-ui/lab/Alert";
// import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import Stack from "@mui/material/Stack";
// // import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// // import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// // import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import Collapse from "@material-ui/core/Collapse";
// import { SHOP } from "../../utils/image";
// import Snackbar from "@material-ui/core/Snackbar";
// import { STORE_WORKING,STORE_WORKING_SUBSCRIPTION } from "./Query";
// import client from "../../Configurations/apollo";
// import Notification from './Notification';
// import ShareUrl from "./ShareUrl";

// import {  useStyles,items,DrawerHeader, } from './utils';
// import './index.css';
// import { Grid } from '@mui/material';


// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }


// const drawerWidth = 240;

// export default function MobileNavbar(props) {
//     const current = new Date();
//     const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
//     // const theme = useTheme();
//     const [open, setOpen] = React.useState(false);
//     const [error, setError] = React.useState("");
  
//     const [selectedIndex, setSelectedIndex] = React.useState(0);
  
//       const handleListItemClick = (event, index) => {
//         setSelectedIndex(index);
//         if(index===2||index===8)
//         {
//             return null
//         }
//         else{
//         setMobileOpen(!mobileOpen);
//         }
//       };
//       const history = useHistory();
//       const collapseClick = (id) => {
        
//           setState((state) => ({
//               ...state,
//               items: state.items.map((itm) =>
//                   itm.id === id ? { ...itm, open: !itm.open } : itm
//               ),
//           }));
//           history.push(`/home/${items[id].title.replace(/ /g, "")}`); 
          
//           // history.push(`/home/${items[id].title.replace(/ /g, "")}/ ${retailerId}`);
//       };
  
  
//     const handleChange = (checked) => {
//       // props.storeWorking(checked);storeWorkingSubscription
//       if(props.retailerDetails.data&&
//       props.retailerDetails.data.retailerdetails&&
//       props.retailerDetails.data.retailerdetails.stores&&
//       props.retailerDetails.data.retailerdetails.stores[0].subscription&&
//       props.retailerDetails.data.retailerdetails.stores[0].subscription.startdate===null)
     
//        { 
//           //  props.storeWorkingSubscription(Boolean(checked))
//           client
//           .mutate({
//             mutation:STORE_WORKING_SUBSCRIPTION,
//             variables: {
//               storeWorking: Boolean(checked),
//               storeid: localStorage.getItem("storeid"),
//                       subscription:{
//                           type:1,
//                           amount:1000,
//                           startdate:date
//                       }
//             },
//           })
//           .then((res) => {
//             if (res.data && res.data.updatestore && res.data.updatestore !== null) {
//               props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//               props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
//             }
//             if (res.data && res.data.updatestore && res.data.updatestore === null) {
//               props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//               props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
//               // setIserror(true);
//               setOpen(true);
//               setError(res.message);
//               // console.log("error","res.message")
//             }
//           })
//           .catch((err) => {
//             props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
    
//             setOpen(true);
//             // setIserror(true)
//             setError(
//               "There is no active products or hierarchy present. so store working cannot be updated"
//             );
//             // console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
//           })
//           }  
      
//         else{
//         client
//         .mutate({
//           mutation: STORE_WORKING,
//           variables: {
//             storeWorking: Boolean(checked),
//             storeid: localStorage.getItem("storeid"),
//           },
//         })
//         .then((res) => {
//           if (res.data && res.data.updatestore && res.data.updatestore !== null) {
//             props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//           }
//           if (res.data && res.data.updatestore && res.data.updatestore === null) {
//             props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
//             // setIserror(true);
//             setOpen(true);
//             setError(res.message);
//             // console.log("error","res.message")
//           }
//         })
//         .catch((err) => {
//           props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
  
//           setOpen(true);
//           // setIserror(true)
//           setError(
//             "There is no active products or hierarchy present. so store working cannot be updated"
//           );
//           // console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
//         })
//         }  
//     };
  
//     // const handleDrawerOpen = () => {
//     //   setOpen(true);
//     // };
  
//     // const handleDrawerClose = () => {
//     //   setOpen(false);
//     // };
//  const loginformNumber = localStorage.getItem('loginformNumber');
//   const loginnumber = loginformNumber === 'null' ? null : (loginformNumber === '0' ? 0 : parseInt(loginformNumber));
//     const [state, setState] = React.useState({ items: items });
//     const classes = useStyles();
  
//     const [mobileOpen, setMobileOpen] = React.useState(false);

//     const handleDrawerToggle = () => {
//       setMobileOpen(!mobileOpen);
//     };
  
 

// //   const container = window !== undefined ? () => window().document.body : undefined;

//   return (
//     <Box sx={{ display: 'block' }}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         className={classes.appBar} style={{backgroundColor: "white",}}
//         sx={{
//           display:'flex',
//           alignItems:'center',
//           width: { sm: `calc(100% - ${drawerWidth}px)` },
//             ml: { sm: `${drawerWidth}px` },
//             '@media screen and (min-width:600px) and (max-width:899px)':{
//               width:'100%',
//               marginLeft:0,
//             }
//           }}
//       >
//         <Toolbar >
//           <Grid container alignItems="center">
//             <Grid items xs={6}  display='flex' flexDirection="row">
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             onClick={handleDrawerToggle}
//             edge="start"
//             style={{
//               marginRight: '36px',
//               ...(open && { display: 'none' }),
//             }}
//           >
//             <MenuIcon color='success'/>
//           </IconButton>
//           <img className="SHOP-image" src={SHOP} alt="SHOP.png" />
//           {error ? (
//             <Snackbar
//               open={open}
//               autoHideDuration={3000}
//               style={{ width: "fit-content"}}
//               onClose={() => setOpen(false)}
//             >
//               <Alert severity="error">{error}</Alert>
//             </Snackbar>
//           ) : null}
//           </Grid>
//           <div/>
//           <Grid item xs={6} >
//           <Stack direction="row" spacing={2} style={{
//               justifyContent:'center',alignItems:'center',textAlign:'center',alignContent:'center'}}>
// 			          <ShareUrl {...props}/>
// 		             <Notification {...props}/> 
//               <BootstrapSwitchButton
//                 checked={props.retailerWorking &&
//                   props.retailerWorking.data &&
//                   props.retailerWorking.data.retailerdetails &&
//                   props.retailerWorking.data.retailerdetails.stores[0] &&
//                   props.retailerWorking.data.retailerdetails.stores[0].storeWorking &&
//                   props.retailerWorking.data.retailerdetails.stores[0]
//                     .storeWorking}
//                 onlabel="Online"
//                 onstyle="success"
//                 offlabel="Offline"
//                 offstyle="danger"
//                 width={75}
//                 onChange={(checked) => handleChange(checked)}
//               />
//               </Stack>
//               </Grid>
//             </Grid>
//         </Toolbar>
//       </AppBar>
//       <Box
//        component="nav"
//        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, 
     
//         '@media screen and (min-width:600px) and (max-width:899px)':{ width:'151px'}
//       }}
//         aria-label="mailbox folders"
//       >
//         {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
//         <Drawer
//         // container={container}
//         variant="temporary"
//         open={mobileOpen}
//         onClose={handleDrawerToggle}
//         ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//         }}
//           sx={{
//             display: { xs: 'block', sm: 'none' },
//             '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//             '@media screen and (min-width:600px) and (max-width:899px)':{
//               width:'100%',
//               marginLeft:0,
//             }
//           }}
//         >
//         <List>
//         {state.items.map((item, index) => {
// 				let Icon = item.icon;
//         if ((loginnumber === 4 || loginnumber === null) && item.title === "Register") {
// 					return null;
// 				}
// 				return (
// 					<React.Fragment key={index}>
   
// 						<ListItem key={`${item.title}-${index}`.toString()}
// 						selected={selectedIndex === index}
// 							onClick={(event) => {
// 								collapseClick(item.id);
// 								handleListItemClick(event, index)
// 							}}
// 							className={clsx(classes.item)}
// 							mainindex={index}
// 							disableGutters>
   
// 							<Button
// 								className={selectedIndex === index?classes.activeButton:classes.button}>
// 								{Icon && 
//                  <ListItemIcon ><Icon color={selectedIndex===index?'white':'rgb(13, 17, 54)'}  className={classes.icon} size="20"/></ListItemIcon>
// 								}
//                 {/* <ListItemText className={classes.title} primary={item.title} /> */}

// 								<span className={classes.title}>{item.title}</span>
// 								{item.title === "My Account"&&
// 								<div className={classes.expand}>
// 									{item.open&&selectedIndex===2 ?  <ExpandLess/>:<ExpandMore/>}
// 								</div>
// 								}
// 								{item.title === "Shop Assistant"&&
// 								<div className={classes.expand}>
// 									{item.open&&selectedIndex===8 ? <ExpandLess/>:<ExpandMore/>}
// 								</div>
// 								}
// 							</Button>
              
// 						</ListItem>
           
// 						{item.title === "My Account"&&selectedIndex===2  ? (
// 							<Collapse
// 								className={classes.collapse}
// 								in={item.open}
// 								timeout="auto"
// 								unmountOnExit>
// 									 <>
//               <List component="div"
			  
// 			   disablePadding>
// 								{item.subMenus.map((subMenu, index) => {
// 									let Icon = subMenu.icon;
// 									return (
										
// 										<ListItem key={index}
// 										// className={selectedIndex === index?classes.activeButton:classes.button}
// 										className={clsx(classes.listItem, {
// 											[classes.nested]: open,
// 											[classes.listItemClose]: !open
// 										  })}
// 										  style={{ paddingTop: "20px"}}
// 										//   key={index}
// 										  button
// 							mainindex={index}
// 							onClick={(e) => {
// 								props.selectedList(item, index);
//                                 setMobileOpen(!mobileOpen);
// 							}}
// 							disableGutters>
// 											{Icon && 
//                  <ListItemIcon style={{ paddingLeft: "10px"}} ><Icon  size="15"/></ListItemIcon>
// 								}
// 											<span className={classes.title}>{subMenu.title}</span>
// 										</ListItem>
// 									);
// 								})}
// 								</List>
// 								</>
// 							</Collapse>
// 						)
// 						:
// 						(item.title === "Shop Assistant"&&selectedIndex===8  ? (
// 							<Collapse
// 								className={classes.collapse}
// 								in={item.open}
// 								timeout="auto"
// 								unmountOnExit>
// 									<>
// 									<List component="div" disablePadding>
// 								{item.subMenus.map((subMenu, index) => {
// 									let Icon = subMenu.icon;
// 									return (
// 										<ListItem key={`${subMenu.title}-${index}`.toString()}
// 										className={clsx(classes.listItem, {
// 											[classes.nested]: open,
// 											[classes.listItemClose]: !open
// 										  })}
// 										  style={{ paddingTop: "20px"}}
// 										//   key={index}
// 										  button
// 							mainindex={index}
// 							onClick={(e) => {props.selectedList(item, index);
//                 setMobileOpen(!mobileOpen);
// 								 history.push(`/home/ShopAssistant/${subMenu.title.replace(/ /g, "")}`);
// 								 }}
// 							disableGutters>
// 										{/* <Button
// 										    key={index}
// 											activeclassname={classes.active}
// 											className={classes.button}
// 											onClick={(e) => {
// 												props.selectedList(item, index);
// 											}}> */}
// 													{Icon && 
//                  <ListItemIcon style={{ paddingLeft: "10px"}} ><Icon color={'rgb(13, 17, 54)'} size="15"/></ListItemIcon>
// 								}
// 											<span className={classes.title}>{subMenu.title}</span>
											
// 										{/* </Button> */}
// 										</ListItem>
// 									);
// 								})}
// 								</List>
// 								</>
// 							</Collapse>):
// 						(	<Collapse
// 							className={classes.collapse}
// 							in={false}
// 							timeout="auto"
// 							unmountOnExit>
						
// 						</Collapse>)
// 						)}
// 					</React.Fragment>
// 				);
// 			})}
//         </List>
//         </Drawer>
     
//       </Box>
//       <Box
//         component="main"
//         sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` },scrollBehavior:'smooth'}}
//       >
//        <DrawerHeader />
     
//      {props.children}
    
//       </Box>
//     </Box>
//   );
// }




//new code my berlin mobile responsive of navbar
import  React from 'react';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import clsx from "clsx";

// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate,useLocation} from "react-router-dom";
import { Button } from "@mui/material";
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Alert from '@mui/material/Alert'
import Switch from '@mui/material/Switch';
import Stack from "@mui/material/Stack";
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {Collapse} from "@mui/material";
import { SHOP } from "../../utils/image";
import {Snackbar} from "@mui/material";
import { STORE_WORKING,STORE_WORKING_SUBSCRIPTION } from "./Query";
import client from "../../Configurations/apollo";
import Notification from './Notification';
import ShareUrl from "./ShareUrl";
import NavProfile from './NavProfile';
import ToggleSwitch from './ToggleSwitch';
import {  useStyles,DrawerHeader } from './utils';
import './index.css';
import { Grid } from '@mui/material';
import { useMediaQuery,useTheme } from '../MUIComponents/index';
import Shoplogo  from "../../utils/navbaricons/Shoplogo.svg";
import { ReactComponent as DashBoard } from "../../utils/navbaricons/Dashboard.svg";
import { ReactComponent as Myorders } from "../../utils/navbaricons/Myorders.svg";
import { ReactComponent as Addproducts } from "../../utils/navbaricons/Addproducts.svg";
import { ReactComponent as Coupons } from "../../utils/navbaricons/Coupons.svg";
import { ReactComponent as Payment } from "../../utils/navbaricons/Payment.svg";
import { ReactComponent as ShopAssistant } from "../../utils/navbaricons/Shopassistant.svg";
import { ReactComponent as Subscription } from "../../utils/navbaricons/Subscription.svg";
import { ReactComponent as Help } from "../../utils/navbaricons/Help.svg";






// function Alert(props) {
//   return <Alert elevation={6} variant="filled" {...props} />;
// }


const drawerWidth = 240;

export default function MobileNavbar(props) {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
    // const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState("");
  
    const [selectedIndex, setSelectedIndex] = React.useState(0);
  
      const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        // if(index===2||index===8)
        // {
        //     return null
        // }
        // else{
        setMobileOpen(!mobileOpen);
        // }
      };

     const location=useLocation();
      const role = localStorage.getItem("role");

      let items;
      if (role !== "Store Manager") {
        items = [
          {
            id: 0,
            icon: Myorders,
            title: "My Orders",
            paths:['/home/MyOrders',"/home/MyOrders/NewOrdersDetail","/home/MyOrders/ActiveOrders","/home/MyOrders/ActiveOrdersDetails","/home/MyOrders/ReadyForDelivery","/home/MyOrders/ReadyForDeliveryDetails",'/home/MyOrders/OutForDelivery',"/home/MyOrders/OutForDeliveryDetails","/home/MyOrders/OrderHistory","/home/MyOrders/OrderHistoryDetails","/"],
          },
          {
            id: 1,
            icon: Help,
            title: "Help",
            paths:['/home/Help'],
          },
       
        ];
      } else {
        items = [
          {
            id: 0,
            icon: DashBoard,
            title: "Dashboard",
            paths:['/home/Dashboard','/'],
          },
     
          {
            id: 1,
            icon: Myorders,
            title: "My Orders",
            paths:['/home/MyOrders',"/home/MyOrders/NewOrdersDetail","/home/MyOrders/ActiveOrders","/home/MyOrders/ActiveOrdersDetails","/home/MyOrders/ReadyForDelivery","/home/MyOrders/ReadyForDeliveryDetails",'/home/MyOrders/OutForDelivery',"/home/MyOrders/OutForDeliveryDetails","/home/MyOrders/OrderHistory","/home/MyOrders/OrderHistoryDetails","/home/MyOrders/GoogleMap"],
          },
          {
            id: 2,
            icon: Addproducts,
            title: "Add Products",
            paths:['/home/AddProducts','/home/ManageHierarchy','/home/ManageHierarchy/Product','/home/Product','/home/CatalogBuilder','/home/CategoryBuilder/Departments','/home/CategoryBuilder/Products','/home/CatalogProducts','/home/EditProducts/details'],
          },
          {
            id: 3,
            icon: Payment,
            title: "Payments",
            paths:['/home/Payments'],
          },
          {
            id: 4,
            icon: Coupons,
            title: "Coupons",
            paths:['/home/Coupons','/home/Coupontype','/home/Coupons/Percentagediscount','/home/Coupons/Flatdiscount','/home/Coupons/BuyXGetYfree','/home/Coupons/FreeDelivery','/home/UpcomingCoupons'],
          },
          {
            id: 5,
            icon: ShopAssistant,
            title: "Shop Assistant",
            paths:['/home/ShopAssistant','/home/ShopAssistant/Register','/home/ShopAssistant/EditRegister','/home/ShopAssistant/orders',''],
      },
          {
            id: 6,
            icon: Subscription,
            title: "Subscription",
            paths:['/home/Subscription'],
      },
          {
            id: 7,
            icon: Help,
            title: "Help",
            paths:['/home/Help']
          },
        ];
      }
      
      
      
      
      


      const navigate = useNavigate();
      const collapseClick = (id) => {
        
          setState((state) => ({
              ...state,
              items: items.map((itm) =>
                  itm.id === id ? { ...itm, open: !itm.open } : itm
              ),
          }));
          // navigate(`/home/${items[id].title.replace(/ /g, "")}`); 
          navigate(id)
          
          // history.push(`/home/${items[id].title.replace(/ /g, "")}/ ${retailerId}`);
      };
  
  
    const handleChange = (checked) => {
      // props.storeWorking(checked);storeWorkingSubscription
      if(props.retailerDetails.data&&
      props.retailerDetails.data.retailerdetails&&
      props.retailerDetails.data.retailerdetails.stores&&
      props.retailerDetails.data.retailerdetails.stores[0].subscription&&
      props.retailerDetails.data.retailerdetails.stores[0].subscription.startdate===null)
     
       { 
          //  props.storeWorkingSubscription(Boolean(checked))
          client
          .mutate({
            mutation:STORE_WORKING_SUBSCRIPTION,
            variables: {
              storeWorking: Boolean(checked),
              storeid: localStorage.getItem("storeid"),
                      subscription:{
                          type:1,
                          amount:1000,
                          startdate:date
                      }
            },
          })
          .then((res) => {
            if (res.data && res.data.updatestore && res.data.updatestore !== null) {
              props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
              props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
            }
            if (res.data && res.data.updatestore && res.data.updatestore === null) {
              props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
              props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
              // setIserror(true);
              setOpen(true);
              setError(res.message);
              // console.log("error","res.message")
            }
          })
          .catch((err) => {
            props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
    
            setOpen(true);
            // setIserror(true)
            setError(
              "There is no active products or hierarchy present. so store working cannot be updated"
            );
            // console.log("Catcherror","There is no active products or hierarchy present. so store working cannot be updated")
          })
          }  
      
        else{
        client
        .mutate({
          mutation: STORE_WORKING,
          variables: {
            storeWorking: Boolean(checked),
            storeid: localStorage.getItem("storeid"),
          },
        })
        .then((res) => {
          if (res.data && res.data.updatestore && res.data.updatestore !== null) {
            props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
          }
          if (res.data && res.data.updatestore && res.data.updatestore === null) {
            props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
            // setIserror(true);
            setOpen(true);
            setError(res.message);
          }
        })
        .catch((err) => {
          props.getSellerWorking(`${localStorage.getItem("retailerid")}`);
  
          setOpen(true);
          // setIserror(true)
          setError(
            "There is no active products or hierarchy present. so store working cannot be updated"
          );
        })
        }  
    };
  
    // const handleDrawerOpen = () => {
    //   setOpen(true);
    // };
  
    // const handleDrawerClose = () => {
    //   setOpen(false);
    // };
 const loginformNumber = localStorage.getItem('loginformNumber');
  const loginnumber = loginformNumber === 'null' ? null : (loginformNumber === '0' ? 0 : parseInt(loginformNumber));
    const [state, setState] = React.useState({ items: items });
    // const classes = useStyles();
  
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
 

//   const container = window !== undefined ? () => window().document.body : undefined;
 




  return (
    <Box sx={{ display: 'block' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        // className={classes.appBar}
         style={{backgroundColor: "white",}}
        sx={{
          display:'flex',
          alignItems:'center',
          width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
            '@media screen and (min-width:600px) and (max-width:899px)':{
              width:'100%',
              marginLeft:0,
            }
          }}
      >
        <Toolbar >
          <Grid container alignItems="center">
            <Grid items xs={6}  display='flex' flexDirection="row">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            style={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon color='success'/>
          </IconButton>
          <div style={{display:"flex",alignItems:"center"}}>
          <img 
						// className="SHOP-image"
						style={{position:"relative",right:matches?"20px":"30px"}}
            height= {matches?"28px":"48px"}
						width={matches?"85.92px":"192.2px"}
						src={SHOP} alt="SHOP.png" />
            </div>
          {error ? (
            <Snackbar
              open={open}
              autoHideDuration={3000}
              style={{ width: "fit-content"}}
              onClose={() => setOpen(false)}
            >
              <Alert severity="error">{error}</Alert>
            </Snackbar>
          ) : null}
          </Grid>
          <div/>
          <Grid item xs={6} >
          <Stack direction="row" spacing={2} style={{
              justifyContent:'center',alignItems:'center',textAlign:'center',alignContent:'center'}}>
                
			          {role !== "Store Manager" ?(
                  <>
                  <ShareUrl {...props} />
                  <Notification {...props} />
      {/* <ToggleSwitch {...props} /> */}
                  <NavProfile {...props} />
                  
                  </>
                ): (
    <>
      <ShareUrl {...props} />
      <Notification {...props} />
      <ToggleSwitch {...props} />
      <NavProfile {...props} />
    </>
  )}
              </Stack>
              </Grid>
            </Grid>
        </Toolbar>
      </AppBar>
      <Box
       component="nav"
       sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, 
     
        '@media screen and (min-width:600px) and (max-width:899px)':{ width:'151px'}
      }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
        // container={container}
       
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            '@media screen and (min-width:600px) and (max-width:899px)':{
              width:'100%',
              marginLeft:0,
            }
          }}
          PaperProps={{
            sx: {
               backgroundColor: "#293332",
            }
          }}
        >
        <List>
        <div style={{display:"flex",gap:"20px",margin:"20px 0px 20px 25px"}}>
<div>
{
  (role === "Store Manager" && props?.retailerDetails?.data?.retailerdetails?.stores?.[0]?.storeLogoUrl) ? (
    <img 
      src={props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl} 
      alt="shoplogo" 
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
       // height: "100%",
        borderRadius: "50%"
      }} 
      width="40px" 
      height="40px"
    />
  ) : (
    <div></div>
  )
}

</div>

<div style={{display:"flex",flexDirection:"column",gap:"2px"}}>
<div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",fontFamily:"Roboto",fontSize:"13px",fontWeight:"400",color:"#FFFFFF"}} >
{role==='Store Manager'&&(<>Welcome</>)}
</div>

<div >
 {role !== "Store Manager"?(<>
  <div style={{display:"flex",gap:"5px"}}>
                  <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",fontFamily:"Roboto",fontSize:"15px",fontWeight:"700",color:"#FFFFFF"}}>
                    {/* {props.ShopAssistantsuccessData.loginShopAssistant.firstName} */}
                  </div>
                  <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",fontFamily:"Roboto",fontSize:"15px",fontWeight:"700",color:"#FFFFFF"}}>
                      {/* {props.ShopAssistantsuccessData.loginShopAssistant.lastName} */}
                  </div>

                 </div>
 </>):(<>
 <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",fontFamily:"Roboto",fontSize:"15px",fontWeight:"700",color:"#FFFFFF"}}>
 {props?.retailerDetails?.data?.retailerdetails?.stores[0]?.storeName}
 </div>
 
 </>)
}
</div>

</div>


</div>
        {state.items.map((item, index) => {
           const isActive=item.paths&&item.paths.includes(location.pathname)
				let Icon = item.icon;
        if ((loginnumber === 4 || loginnumber === null) && item.title === "Register") {
					return null;
				}
				return (
					<React.Fragment key={index}>
   
						<ListItem key={`${item.title}-${index}`.toString()}
						selected={selectedIndex === index}
							onClick={(event) => {
								collapseClick(item.paths[0]);
								handleListItemClick(event, index)
							}}
							// className={clsx(classes.item)}
							mainindex={index}
							disableGutters>
   
							<Button
              style={{marginLeft:"20px"}}
								// className={selectedIndex === index?classes.activeButton:classes.button}
                >
								{Icon && 
                 <ListItemIcon ><Icon fill={isActive?'#85BE49':'#FFFFFF'} 
                  // className={classes.icon} 
                 size="20"/></ListItemIcon>
								}
                {/* <ListItemText className={classes.title} primary={item.title} /> */}

								<span 
                // className={classes.title}
                style={{color:isActive?'#85BE49':'#FFFFFF',fontFamily:"Roboto",fontSize:"13px",fontWeight:"400"}}
                >{item.title}</span>
								
								
							</Button>
              
						</ListItem>
           
						
						
						
						
					</React.Fragment>
				);
			})}
        </List>
        </Drawer>
     
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` },scrollBehavior:'smooth'}}
      >
       <DrawerHeader />
     
     {props.children}
    
      </Box>
    </Box>
  );
}