// import React, { useEffect } from "react";
// import {
//   Typography,
//   useTheme,
//   useMediaQuery,
// } from "../../MUIComponents/index";
// import { TextField } from "../../MUIComponents/index";
// import { contentFontSize_16 } from "../../Typography/index";

// function DeliveryCharge({ distance, deliveryCharges, setDeliveryCharges, formik }) {
//   const charges = [
//     {
//       label: "0-3 Kilometers",
//       distanceValue: 3,
//       fname: "firstKm",
//     },
//     {
//       label: "3-6 Kilometers",
//       distanceValue: 6,
//       fname: "secondKm",
//     },
//     {
//       label: "6-9 Kilometers",
//       distanceValue: 9,
//       fname: "thirdKm",
//     },
//     {
//       label: "9-12 Kilometers",
//       distanceValue: 12,
//       fname: "fourthKm",
//     },
//     {
//       label: "12-15 Kilometers",
//       distanceValue: 15,
//       fname: "fifthKm",
//     },
//   ];

//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.down("md"));

  
//   const handleChargeChange = (distanceValue, value) => {
//     const updatedCharges = deliveryCharges.map((charge) =>
//       charge.kmRange === distanceValue.toString()
//         ? { kmRange: distanceValue.toString(), charge: value }
//         : charge
//     );
//     if (!updatedCharges.some((charge) => charge.kmRange === distanceValue.toString())) {
//       updatedCharges.push({ kmRange: distanceValue.toString(), charge: value });
//     }

//     setDeliveryCharges(updatedCharges);
//     formik.setFieldValue("deliveryCharges", updatedCharges);
//   };

//   useEffect(() => {
//     formik.setFieldValue("deliveryCharges", deliveryCharges);
//   }, [deliveryCharges]);

//   return (
//     <div className="DeliveryChargeContainer">
//       {charges.map((row, index) => (
//         <div key={row.distanceValue} className="flexcontanineritems">
//           {row.distanceValue <= distance && (
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <Typography
//                 className="LabelStyles"
//                 style={{
//                   fontSize: contentFontSize_16,
//                   width: "200px",
//                   fontWeight: "500",
//                 }}
//               >
//                 {row.label}{" "}
//                 {/* <span style={{ color: "#EA4335", fontSize: "14px" }}>*</span> */}
//               </Typography>
//               <TextField
//                 id={row.fname}
//                 fullWidth
//                 name={`deliveryCharges.${index}.charge`}
//                 placeholder="Enter amount"
//                 style={{ marginLeft: "1rem" ,marginTop:"20px"}}
//                 onChange={(e) =>
//                   handleChargeChange(row.distanceValue, e.target.value)
//                 }
//                 size={matches ? "small" : "medium"}
//                 // type="number"
//                 inputProps={{
//                   style: {
//                     height: "30px",
//                   },
//                 }}
//               />
//             </div>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// }

// export default DeliveryCharge;
import React, { useEffect } from "react";
import {
  Typography,
  useTheme,
  useMediaQuery,
} from "../../MUIComponents/index";
import { TextField } from "../../MUIComponents/index";
import { contentFontSize_16 } from "../../Typography/index";

function DeliveryCharge({ distance, deliveryCharges, setDeliveryCharges, formik }) {
  const charges = [
    {
      label: "0-3 Kilometers",
      distanceValue: 3,
      fname: "firstKm",
    },
    {
      label: "3-6 Kilometers",
      distanceValue: 6,
      fname: "secondKm",
    },
    {
      label: "6-9 Kilometers",
      distanceValue: 9,
      fname: "thirdKm",
    },
    {
      label: "9-12 Kilometers",
      distanceValue: 12,
      fname: "fourthKm",
    },
    {
      label: "12-15 Kilometers",
      distanceValue: 15,
      fname: "fifthKm",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleChargeChange = (distanceValue, value) => {
    const updatedCharges = deliveryCharges.map((charge) =>
      charge.kmRange === `${distanceValue}` ? { kmRange: `${distanceValue}`, charge: value } : charge
    );

    // Add the charge if it doesn't exist
    if (!updatedCharges.some((charge) => charge.kmRange === `${distanceValue}`)) {
      updatedCharges.push({ kmRange: `${distanceValue}`, charge: value });
    }

    setDeliveryCharges(updatedCharges);
    formik.setFieldValue("deliveryCharges", updatedCharges);
  };

  useEffect(() => {
    if (!deliveryCharges.length) {
      const initialCharges = charges.map((charge) => ({
        kmRange: `${charge.distanceValue}`,
        charge: '0'
      }));
      setDeliveryCharges(initialCharges);
      formik.setFieldValue("deliveryCharges", initialCharges);
    } else {
      formik.setFieldValue("deliveryCharges", deliveryCharges);
    }
  }, [deliveryCharges]);

  return (
    <div className="DeliveryChargeContainer">
      {charges.map((row, index) => {
        // Find the charge value for the current distance
        const existingCharge = deliveryCharges.find(charge => charge.kmRange === `${row.distanceValue}`);
        const chargeValue = existingCharge ? existingCharge.charge : '0';

        return (
          <div key={row.distanceValue} className="flexcontanineritems">
            {row.distanceValue <= distance && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className="LabelStyles"
                  style={{
                    fontSize: contentFontSize_16,
                    width: "200px",
                    fontWeight: "500",
                  }}
                >
                  {row.label}{" "}
                  {/* <span style={{ color: "#EA4335", fontSize: "14px" }}>*</span> */}
                </Typography>
                <TextField
                  id={row.fname}
                  fullWidth
                  name={`deliveryCharges.${index}.charge`}
                  placeholder="Enter amount"
                  style={{ marginLeft: "1rem", marginTop:"20px" }}
                  value={chargeValue}
                  onChange={(e) =>
                    handleChargeChange(row.distanceValue, e.target.value)
                  }
                  size={matches ? "small" : "medium"}
                  type="number" 
                  inputProps={{
                    min: 0,
                    style: {
                      height: "30px",
                    },
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default DeliveryCharge;
