// import React from "react";
// import TextField from "@material-ui/core/TextField";
// import FormHeaders from "../FormHeaders";
// import MenuItem from "@material-ui/core/MenuItem";
// import SearchResults from "../SearchResults";
// import { Row, Col } from "react-bootstrap";
// import { Container } from "@mui/material";
// import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { green } from "@material-ui/core/colors";
// import { loader } from '../../utils/image';
// import { Modal } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
// // import TablePagination from '@mui/material/TablePagination';
// import './index.css';

// class MyorderSearch extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			orderNumber: null,
// 			mobileNumber: null,
// 			status: null,
// 			dateTo: "",
// 			dateFrom: "",
// 			deliveryDateTo: "",
// 			deliveryDateFrom: "",
// 			offset: 0,
// 			first: 10,
// 			orders: [],
// 			page: 0,
// 			rowsPerPage: 10,
// 			errorMessage: "",
// 			MobileError:"",
// 			isMobileError:false,
// 			isMobile: window.innerWidth < 768
// 		};
// 	} declare the all states

// 	componentDidMount() {
// 		document.body.scrollTop = 0;
// 	}

// 	componentDidUpdate(prevProps) {
// 		window.addEventListener('resize', this.updateIsMobile)
// 		// Typical usage (don't forget to compare props):
// 		if (this.props.searchData !== prevProps.searchData) {
// 			this.setState({
// 				orders: [...this.state.orders, this.props.searchData &&
// 					this.props.searchData.searchorder &&
// 					this.props.searchData.searchorder
// 					&& this.props.searchData.searchorder.items
// 					&& this.props.searchData.searchorder.items.map((p) => (p))]
// 			},
// 				() => {
// 					this.props.setOrders(this.state.orders)
// 				})
// 		}
// 	}
// 	componentWillUnmount() {
// 		window.removeEventListener('resize', this.updateIsMobile);
// 	}
// 	updateIsMobile = () => {
// 		this.setState({
// 			isMobile: window.innerWidth < 768
// 		});
// 	}
// 	render() {
// 		document.title = "Seller Portal | My Orders";

// 		const theme = createMuiTheme({
// 			palette: {
// 				primary: green,
// 			},
// 			overrides: {
// 				MuiInputBase: {
// 					input: {
// 						'&:-webkit-autofill': {
// 							transitionDelay: '9999s',
// 							transitionProperty: 'background-color, color',
// 						},
// 					},
// 				},
// 			},
// 		})

// 		var date = new Date(this.state.deliveryDateFrom);
// 		var elapsed = date.getTime();
// 		let storeid = localStorage.getItem('storeid')
// 		const statusFilters = [
// 			{ option: "Order-Placed", value: "Order-Placed" },
// 			{ option: "Order-Accepted", value: "Order-Accepted" },
// 			{ option: "Order-Ready", value: "Order-Ready" },
// 			{ option: "Order-Rejected", value: "Order-Rejected" },
// 			{ option: "Order-Cancelled", value: "Order-Cancelled" },
// 			{ option: "Shopping-In-Progress", value: "Shopping-In-Progress" },
// 			{ option: "Order-Completed", value: "Completed" },
// 		];
// 		const LoadMore = () => {
// 			this.setState({
// 				...this.state,
// 				offset: this.state.offset + 10,
// 			},
// 				() => {
// 					this.props.getSearchResult({ ...this.state, storeid })
// 				})

// 		}
// 		const handleSearchField = (e) => {
// 			let { value } = e.target;

// 			this.setState({
// 				...this.state,
// 				[e.target.name]: value,
// 			},()=>{
// 				if (
// 					isNaN(this.state.orderNumber)
// 				  ) {
// 				  this.setState({errorMessage:"Please enter only numbers"})
// 				  }
// 				  else if(!isNaN(this.state.orderNumber)&&this.state.errorMessage!=="")
// 					{
// 						this.setState({errorMessage:""})
// 					}

// 				  else if (this.state.mobileNumber!==null ){
// 					var pattern = new RegExp(/^[0-9\b]+$/);
// 					// if (!pattern.test(this.state.mobileNumber)) {
// 						if(!pattern.test(this.state.mobileNumber))
// 						{
// 							console.log("mobileNumber")
// 							this.setState({isMobileError:true})
// 							this.setState({MobileError:"Please enter only numbers"})
// 						}
// 						else if ((this.state.mobileNumber).length !== 10) {
// 								this.setState({isMobileError:true})
// 								this.setState({MobileError:"Please enter valid phone number."})

// 							}
// 						else if(pattern.test(this.state.mobileNumber)&&this.state.MobileError!=="")
// 					{

// 						this.setState({isMobileError:false})
// 						this.setState({MobileError:""})
// 					}
// 					else if((this.state.mobileNumber).length !== 10&&this.state.MobileError!=="")
// 					{

// 						this.setState({isMobileError:false})
// 						this.setState({MobileError:""})
// 					}
// 						// else if ((this.state.mobileNumber).length !== 10) {
// 						// 	this.setState({isMobileError:true})
// 						// 	this.setState({MobileError:"Please enter valid phone number."})

// 						// }

// 					// }
// 				}

// 			});
// 		};
// 		const Clear = () => {
// 			this.setState({
// 				orderNumber: "",
// 				mobileNumber: null,
// 				status: "",
// 				dateTo: "",
// 				dateFrom: "",
// 				deliveryDateTo: "",
// 				deliveryDateFrom: "",
// 				offset: 0,
// 				first: 10,
// 				orders: [],
// 				page: 0,
// 				rowsPerPage: 10
// 			})
// 		}
// 		const searchOrderHandle = () => {

// 			if ((this.state.deliveryDateTo !== "" && this.state.deliveryDateFrom === "") || (this.state.deliveryDateTo === "" && this.state.deliveryDateFrom !== "")) {
// 				Modal.confirm({
// 					title: "Confirm",
// 					icon: <ExclamationCircleOutlined />,
// 					content:
// 						"Please select both Delivery date from and to.",
// 					okText: "Ok",
// 				});
// 			}
// 			else if ((this.state.dateTo !== "" && this.state.dateFrom === "") || (this.state.dateTo === "" && this.state.dateFrom !== "")) {
// 				Modal.confirm({
// 					title: "Confirm",
// 					icon: <ExclamationCircleOutlined />,
// 					content:
// 						"Please select both Order From and To dates.",
// 					okText: "Ok",
// 				});
// 			}
// 				else if ((

// 					new Date(this.state.dateFrom) > new Date(this.state.dateTo)
// 				) || (new Date(this.state.deliveryDateFrom) > new Date(this.state.deliveryDateTo))) {
// 					Modal.confirm({
// 						title: "Confirm",
// 						icon: <ExclamationCircleOutlined />,
// 						content:
// 							"From date must be less than To date",
// 						okText: "Ok",
// 					});
// 				}

// 			else if ((

// 				new Date(this.state.dateFrom) > new Date(this.state.dateTo)
// 			) || (new Date(this.state.deliveryDateFrom) > new Date(this.state.deliveryDateTo))) {
// 				Modal.confirm({
// 					title: "Confirm",
// 					icon: <ExclamationCircleOutlined />,
// 					content:
// 						"From date must be less than To date",
// 					okText: "Ok",
// 				});
// 			}
   
// 			else {
// 				this.setState({
// 					orders: [],
// 					offset: 0,
// 				}, () => {
// 					this.props.getSearchResult(this.state, storeid);
// 				});
// 			}

// 		};
// 		return (
// 			<>
// 				{this.state.isMobile ?
// 					<div
// 						style={{
// 							backgroundColor: "white",
// 							border: "1px solid rgba(0, 0, 0, 0.16)",
// 							borderRadius: "10px",
// 							Top: "40px",
// 							// padding: "30px",
// 							width: "90vw",
// 							justifyContent: 'center',
// 							alignItems: 'center',
// 							textAlign: 'center'
// 						}}>
// 						<div
// 						>
// 							<h5 style={{ fontWeight: 600, paddingBottom: 20, fontSize: "23px" }}>
// 								Search Orders
// 							</h5>
// 							<form style={{
// 								width: '100%'
// 							}}>
// 								<ThemeProvider theme={theme}>
// 									<ul>
// 										<Row style={{
// 											width: '100%'
// 										}}>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													InputProps={{ style: { height: 35 } }}
// 													type="text"
// 													label="Order number"
// 													name="orderNumber"
// 													style={{ width: '100%' }}
// 													defaultValue={this.state.orderNumber}
// 													value={this.state.orderNumber}
// 													variant="outlined"
// 													helperText={this.state.errorMessage}
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													InputLabelProps={{
// 														shrink: true,
// 													}} />

// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField

// 													variant="outlined"
// 													InputProps={{ style: { height: 35 } }}
// 													label="Orders from"
// 													value={this.state.dateFrom}
// 													name="dateFrom"
// 													style={{ width: '100%' }}
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													type="date"

// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													variant="outlined"
// 													InputProps={{ style: { height: 35 } }}
// 													label="Orders to"
// 													type="date"
// 													value={this.state.dateTo}
// 													name="dateTo"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													style={{ width: '100%' }}

// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>

// 											</Col>
// 										</Row>
// 										<Row style={{
// 											width: '100%'
// 										}}>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													InputProps={{ style: { height: 35 } }}
// 													type="text"
// 													label="Mobile number"
// 													name="mobileNumber"
// 													value={this.state.mobileNumber}
// 													style={{ width: '100%' }}
// 													variant="outlined"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													fullWidth
// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>

// 												<TextField
// 													InputProps={{ style: { height: 35 } }}
// 													id="date"
// 													label="Delivery from"
// 													variant="outlined"
// 													value={this.state.deliveryDateFrom}
// 													name="deliveryDateFrom"
// 													style={{ width: '100%', height: 35 }}
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													type="date"
// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													InputProps={{ style: { height: 35 } }}
// 													id="date"
// 													label="Delivery to"
// 													type="date"
// 													minDate={elapsed}
// 													value={this.state.deliveryDateTo}
// 													name="deliveryDateTo"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													variant="outlined"
// 													style={{ width: '100%' }}
// 													InputLabelProps={{
// 														shrink: true,
// 														max: "2020-01-01",
// 														min: "2020-01-07"
// 													}}
// 												/>
// 											</Col>
// 										</Row>
// 										<Row style={{ width: '100%' }}>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													select
// 													variant="outlined"
// 													style={{ width: '100%' }}
// 													SelectProps={{ style: { width: '100%', height: 35 } }}
// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 													label="Status"
// 													value={this.state.status === null ? '' : this.state.status}
// 													name="status"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}>
// 													{statusFilters.map((statusFilter) => {
// 														return (

// 															<MenuItem
// 																style={{ width: 'fit-content', cursor: 'pointer' }}
// 																value={statusFilter.value}>
// 																{statusFilter.option}
// 															</MenuItem>

// 														);
// 													})}
// 												</TextField>
// 											</Col>
// 										</Row>
// 										<Row style={{ width: '100%', paddingTop: 15 }}>
// 											<Col xs={12} md={2} >
// 												<button
// 													type="button"
// 													style={{
// 														justifyContent: ' center',
// 														color: 'rgb(255, 255, 255)',
// 														backgroundColor: 'rgb(137, 199, 74, 1)',
// 														height: '37px',
// 														fontSize: '16px',
// 														fontWeight: '600',
// 														borderRadius: '5px',
// 														borderWidth: '1px',
// 														borderStyle: ' solid',
// 														borderColor: 'transparent',
// 														borderImage: 'initial',
// 														overflow: 'hidden',
// 														outline: 'none',
// 														minHeight: ' 14px',
// 														fontFamily: 'Varela Round, sans-serif',
// 														width: '100%',
// 													}}

// 													variant="success"
// 													onClick={() => {
// 														searchOrderHandle();
// 													}}>
// 													Search
// 												</button>
// 											</Col>
// 										</Row>
// 										<Row style={{ width: '100%', paddingTop: 15 }}>
// 											<Col xs={12} md={2} >
// 												<button
// 													type="button"
// 													style={{
// 														justifyContent: ' center',
// 														color: 'rgb(255, 255, 255)',
// 														backgroundColor: 'rgb(137, 199, 74, 1)',
// 														height: '37px',
// 														fontSize: '16px',
// 														fontWeight: '600',
// 														borderRadius: '5px',
// 														borderWidth: '1px',
// 														borderStyle: ' solid',
// 														borderColor: 'transparent',
// 														borderImage: 'initial',
// 														overflow: 'hidden',
// 														outline: 'none',
// 														minHeight: ' 14px',
// 														fontFamily: 'Varela Round, sans-serif',
// 														width: '100%',
// 													}}

// 													variant="success"
// 													onClick={() => {
// 														Clear();
// 													}}>
// 													Clear
// 												</button>
// 											</Col>
// 										</Row>
// 									</ul>
// 								</ThemeProvider>

// 								{this.props.searchingOrder &&
// 									<div style={{
// 										textAlign: 'center',
// 										justifyContent: 'center',
// 										alignItems: 'center'
// 									}}>
// 										<img src={loader} alt="Loading" />
// 									</div>}
// 								{this.props.searchSuccess ?
// 									<>
// 										<Row style={{
// 											width: '100%', justifyContent: 'center',
// 											alignItems: 'center',
// 											textAlign: 'center'
// 										}}>
// 											<SearchResults orders={this.props.Orders}{...this.props} />
// 										</Row>
// 										{
// 											<div style={{
// 												justifyContent: 'center',
// 												alignItems: 'center',
// 												textAlign: 'center',
// 												fontSize: '16px'
// 											}}>
// 												{`${1}`} - {`${this.state.offset + 10}`} of {this.props.searchData &&
// 													this.props.searchData.searchorder &&
// 													this.props.searchData.searchorder
// 													&& this.props.searchData.searchorder.count}
// 											</div>
// 										}

// 										{this.props.searchData &&
// 											this.props.searchData.searchorder &&
// 											this.props.searchData.searchorder
// 											&& this.props.searchData.searchorder.items
// 											&& this.props.searchData.searchorder.items.length >= 10 &&
// 											<Row style={{
// 												width: '100%', justifyContent: 'center',
// 												alignItems: 'center',
// 												textAlign: 'center'
// 											}}>
// 												<button
// 													style={{
// 														justifyContent: ' center',
// 														color: 'rgb(255, 255, 255)',
// 														backgroundColor: 'rgb(137, 199, 74, 1)',
// 														boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
// 														width: '100px',
// 														height: '45px',
// 														fontSize: '16px',
// 														fontWeight: '600',
// 														borderWidth: '1px',
// 														borderStyle: ' solid',
// 														borderColor: 'transparent',
// 														borderImage: 'initial',
// 														overflow: 'hidden',
// 														outline: 'none',
// 														minHeight: ' 14px',
// 														fontFamily: 'Varela Round, sans-serif'
// 													}}
// 													onClick={() => LoadMore()}>
// 													Load More
// 												</button>
// 											</Row>
// 										}

// 									</>

// 									: null}
// 							</form>
// 						</div>

// 					</div>

// 					: <Container
// 						maxWidth="xl"
// 						style={{
// 							backgroundColor: "white",
// 							border: "1px solid rgba(0, 0, 0, 0.16)",
// 							borderRadius: "10px",
// 							// width: '900px'
// 						}}>
// 						<div
// 							style={{
// 								padding: 20,
// 							}}
// 						>
// 							<FormHeaders header="Search Orders" />
// 							<form >
// 								<ThemeProvider theme={theme}>
// 									<ul>
// 										<Row >
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 												      error={isNaN(this.state.orderNumber)}
// 													  helperText={`${this.state.errorMessage}`}
// 													InputProps={{ style: { height: 35 } }}
// 													type="text"
// 													label="Order number"
// 													name="orderNumber"
// 													style={{ width: '100%' }}
// 													value={this.state.orderNumber || ""}
// 													variant="outlined"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													InputLabelProps={{
// 														shrink: true,
// 													}} />

// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField

// 													variant="outlined"
// 													InputProps={{ style: { height: 35 } }}
// 													label="Orders from"
// 													value={this.state.dateFrom}
// 													name="dateFrom"
// 													style={{ width: '100%' }}
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													type="date"

// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													variant="outlined"
// 													InputProps={{ style: { height: 35 } }}
// 													label="Orders to"
// 													type="date"
// 													value={this.state.dateTo}
// 													name="dateTo"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													style={{ width: '100%' }}

// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>

// 											</Col>
// 										</Row>
// 										<Row  >
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													InputProps={{ style: { height: 35 } }}
// 													error={this.state.isMobileError}
// 													hel
// 													helperText={`${this.state.MobileError}`}
// 													type="text"
// 													label="Mobile number"
// 													name="mobileNumber"
// 													value={this.state.mobileNumber || ""}
// 													style={{ width: '100%' }}
// 													variant="outlined"
// 													onChange={(e) => {
// 														console.log("e.targ", e.target.value)
// 														handleSearchField(e);
// 													}}
// 													fullWidth
// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>

// 												<TextField
// 													InputProps={{ style: { height: 35 } }}
// 													id="date"
// 													label="Delivery from"
// 													variant="outlined"
// 													value={this.state.deliveryDateFrom}
// 													name="deliveryDateFrom"
// 													style={{ width: '100%', height: 35 }}
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													type="date"
// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 												/>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<TextField
// 													InputProps={{ style: { height: 35 } }}
// 													id="date"
// 													label="Delivery to"
// 													type="date"
// 													value={this.state.deliveryDateTo}
// 													name="deliveryDateTo"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}
// 													variant="outlined"
// 													style={{ width: '100%' }}
// 													InputLabelProps={{
// 														shrink: true
// 													}}
// 												/>
// 											</Col>
// 										</Row>
// 										<Row >
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>

// 												<TextField
// 													select
// 													key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
// 													variant="outlined"
// 													style={{ width: '100%' }}
// 													// 	  SelectProps={{
// 													// 		  style: { width: '100%', height: 35 },
// 													// 		  value:this.state.department===null?'':this.state.department
// 													//    }}
// 													// 	  InputProps={{ style: { height: 35 } }}
// 													SelectProps={{ style: { width: '100%', height: 35 } }}
// 													InputLabelProps={{
// 														shrink: true,
// 													}}
// 													label="Status"
// 													value={this.state.status || ""}
// 													name="status"
// 													onChange={(e) => {
// 														handleSearchField(e);
// 													}}>
// 													{statusFilters.map((statusFilter, index) => {
// 														return (

// 															<MenuItem
// 																key={index}
// 																style={{ width: '100%', cursor: 'pointer' }}
// 																value={statusFilter.value}>
// 																{statusFilter.option}
// 															</MenuItem>

// 														);
// 													})}
// 												</TextField>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<button
// 													type="button"
// 													style={{
// 														justifyContent: ' center',
// 														color: 'rgb(255, 255, 255)',
// 														backgroundColor: 'rgb(137, 199, 74, 1)',
// 														//   float:'right',
// 														width: '100%',
// 														height: '37px',
// 														fontSize: '16px',
// 														fontWeight: '600',
// 														borderRadius: '5px',
// 														borderWidth: '1px',
// 														borderStyle: ' solid',
// 														borderColor: 'transparent',
// 														borderImage: 'initial',
// 														overflow: 'hidden',
// 														outline: 'none',
// 														minHeight: ' 14px',
// 														fontFamily: 'Varela Round, sans-serif'
// 													}}

// 													variant="success"
// 													onClick={() => {
// 														searchOrderHandle();
// 													}}>
// 													Search
// 												</button>
// 											</Col>
// 											<Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// 												<button
// 													type="button"
// 													style={{
// 														justifyContent: ' center',
// 														color: 'rgb(255, 255, 255)',
// 														backgroundColor: 'rgb(137, 199, 74, 1)',
// 														//   float:'right',
// 														width: '100%',
// 														height: '37px',
// 														fontSize: '16px',
// 														fontWeight: '600',
// 														borderRadius: '5px',
// 														borderWidth: '1px',
// 														borderStyle: ' solid',
// 														borderColor: 'transparent',
// 														borderImage: 'initial',
// 														overflow: 'hidden',
// 														outline: 'none',
// 														minHeight: ' 14px',
// 														fontFamily: 'Varela Round, sans-serif'
// 													}}

// 													variant="success"
// 													onClick={() => {
// 														Clear();
// 													}}>
// 													Clear
// 												</button>
// 											</Col>
// 										</Row>
// 									</ul>
// 								</ThemeProvider>
// 							</form>
// 							<Row>

// 							</Row>
// 							{this.props.searchingOrder &&
// 								<div style={{
// 									textAlign: 'center',
// 									justifyContent: 'center',
// 									alignItems: 'center'
// 								}}>
// 									<img src={loader} alt="Loading" />
// 								</div>}
// 							{this.props.searchSuccess ?
// 								<>
// 									<SearchResults orders={this.props.Orders}{...this.props} />
// 									{/* {
// 											  <div style={{textAlign:'right',
// 											  justifyContent:'right',
// 											  alignItems:'right',
// 											  fontSize:'16px'}}>
// 											   {`${1}`} - {`${this.state.offset+10}`} of {this.props.searchData&&
// 				  this.props.searchData.searchorder &&
// 							  this.props.searchData.searchorder
// 							  &&this.props.searchData.searchorder.count}
// 											  </div>
// 										  } */}

// 									{this.props.searchData &&
// 										this.props.searchData.searchorder &&
// 										this.props.searchData.searchorder
// 										&& this.props.searchData.searchorder.items
// 										&& this.props.searchData.searchorder.items.length >= 10 &&
// 										<div style={{
// 											textAlign: 'center',
// 											justifyContent: 'center',
// 											alignItems: 'center',
// 											alignContent: 'center'
// 										}}>
// 											<button
// 												style={{
// 													justifyContent: ' center',
// 													color: 'rgb(255, 255, 255)',
// 													backgroundColor: 'rgb(137, 199, 74, 1)',
// 													width: '150px',
// 													height: '45px',
// 													fontSize: '16px',
// 													fontWeight: '600',
// 													borderWidth: '1px',
// 													borderStyle: ' solid',
// 													borderColor: 'transparent',
// 													borderImage: 'initial',
// 													overflow: 'hidden',
// 													outline: 'none',
// 													minHeight: ' 14px',
// 													fontFamily: 'Varela Round, sans-serif'
// 												}}
// 												onClick={() => LoadMore()}>
// 												Load More
// 											</button>
// 										</div>
// 									}

// 								</>

// 								: null}
// 						</div>

// 					</Container>}

// 			</>
// 		);
// 	}
// }
// export default MyorderSearch;

// code by celesta
// import CustomBadge from '../Commoncomponents/badge/index';
// import CustomButton from '../Commoncomponents/button/index';
// import { Stack } from '../MUIComponents/index';
// import { Grid } from '../MUIComponents/index';
// import React, { useEffect, useState } from 'react';
// import { Typography, Container } from '../MUIComponents/index';
// import CustomCard from '../Commoncomponents/card';
// import Arrow from '../../utils/Icon/Arrow.svg';
// import ClockAnimation from '../../utils/Icon/ClockAnimation.svg';
// import NewOrder from './NewOrder';
// import ActiveOrder from './ActiveOrder';
// import ReadyForDelivery from './ReadyForDelivery';
// import Pointer from '../../utils/Icon/Pointer.svg';
// import Triangle from '../../utils/Icon/Triangle.svg';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Outlet } from 'react-router-dom';

// const MyorderSearch = ({checkedItems}) => {
//   const [showCardOne, setShowCardOne] = useState(true);
//   const [showCardTwo, setShowCardTwo] = useState(false);
//   const [showCardThree, setShowCardThree] = useState(false);
//   const [activeButton, setActiveButton] = useState("newOrders");
//   const [markUsRead, setMarkUsRead] = useState(false);
//   const [cardItems, setCardItems] = useState([{orderId:'10110',date:'friday july 31',time:'15mins'}]);
//   const [clickedItems, setClickedItems] = useState(Array(cardItems.length).fill(false));
//   // const [newOrderState,setNewOrderState]=useState(false);
//   const [activeOrderState,setActiveOrderState]=useState(false);
//   const [readyForDeliveryState,setReadyForDeliveryState]=useState(false);
//   const [outForDeliveryState,setOutForDeliveryState]=useState(false);
//   const [orderHistoryState,setOrderHistoryState]=useState(false)

//   const [newOrderDetails,setnewOrderDetails] = useState([{orderId:"#1011",orderDate:"Fri : Jul 21 2023, 3:43 PM"},{orderId:"#1012",orderDate:"Thu : Jul 21 2023, 3:43 PM"}]);
//   localStorage.setItem('orderIdDetails', JSON.stringify(newOrderDetails));
//   const navigate = useNavigate();
//     function handleCardOne(){

//       navigate('NewOrder');
//     }

//     function handleCardTwo(){
//       navigate('ActiveOrders');
//       const dataForActiveOrders = [{ id: 1,image: Pointer, name: 'Porotta', quantity: 2, price: 100 },
//       { id: 2,image: Triangle,  name: 'Beef curry', quantity: 1, price: 250 },
//       { id:3, image: Pointer, name:'Butter Chicken', quantity:3, price:150},
//       { id:4,image: Triangle,  name:'Naan', quantity:2, price:40},];

//       nav("/home/MyOrders/ActiveOrders", { state: { data: dataForActiveOrders } });

//     }

//   const location = useLocation();
//   const selectedDataFromActiveOrders = location.state?.data;
//   const nav = useNavigate();
//     function handleCardThree(){
//       nav("/home/MyOrders/ReadyForDelivery",{ state: { data: selectedDataFromActiveOrders  } })
//       console.log(selectedDataFromActiveOrders)
//       //  const selectedItems = JSON.parse(localStorage.getItem('selectedItems')) || [];

//       // // Navigate to ReadyForDelivery and pass the data
//       //  nav("/home/MyOrders/ReadyForDelivery", { state: { data: selectedItems } });
//       //  console.log(selectedItems)
//       // nav("/home/MyOrders/ReadyForDelivery");
//       // console.log(selectedItemFromActiveOrders);
//        JSON.parse(localStorage.getItem('orderDetails'));
//     }
//     function handleCardFour(){
//         nav("/home/MyOrders/OutForDelivery")
//         JSON.parse(localStorage.getItem('orderDetails'));
//     }
//   const handleTotalColorChange = () => {
//     setClickedItems(Array(cardItems.length).fill(true));
//   };

//   const handleoutForDelivery = () => {
//     setMarkUsRead(true);
//   };

//   const handleAllClick = () => {
//     setMarkUsRead(false);
//   };

//   const handleClickOne = () => {
//     setShowCardOne(true);
//     setShowCardTwo(false);
//     setShowCardThree(false);
//     setActiveButton("newOrders");
//   };

//   const handleClickTwo = () => {
//     setShowCardTwo(true);
//     setShowCardOne(false);
//     setShowCardThree(false);
//     setActiveButton('activeOrders');
//     const dataForActiveOrders = [{ id: 1,image: Pointer, name: 'Porotta', quantity: 2, price: 100 },
//     { id: 2,image: Triangle,  name: 'Beef curry', quantity: 1, price: 250 },
//     { id:3, image: Pointer, name:'Butter Chicken', quantity:3, price:150},
//     { id:4,image: Triangle,  name:'Naan', quantity:2, price:40},];

//     setOrderData(dataForActiveOrders);

//   };

//   const handleClickThree = () => {
//     setShowCardThree(true);
//     setShowCardOne(false);
//     setShowCardTwo(false);
//     setActiveButton('readyForDelivery');
//     // setCheckedItems(checkedItems);
//     setReadyCard(true)
//   };

//   const isBadgeActive = activeButton === 'newOrders';
//   const [orderData, setOrderData] = useState([]);
//   const [ReadyCard,setReadyCard]= useState(false)

//   return (
//     <>
//      <>
//          <div className='btnStyles'>

//               <CustomButton
//                 width='330px'
//                 height='50px'
//                 borderRadius='15px'
//                 onClick={handleClickOne}
//                 color={activeButton === 'newOrders' ? 'white' : '#646464'}
//                 variant={activeButton === 'newOrders' ? 'contained' : 'outlined'}
//                 background={activeButton === 'newOrders' ? '#659B1B': 'transparent'}
//                 borderColor='#659B1B'
//               >
//                 New Orders
//                 <CustomBadge badgeContent='2' backgroundColor={isBadgeActive ? 'white':'none'} color={isBadgeActive?'#659B1B': '#646464'} borderColor={isBadgeActive?'none':'#646464'} border='2px solid '></CustomBadge>
//               </CustomButton>

//               <CustomButton
//                 width='330px'
//                 height='50px'
//                 borderRadius='15px'
//                 color={activeButton === 'activeOrders' ? 'white' : '#646464'}
//                 variant={activeButton === 'activeOrders' ? 'contained' : 'outlined'}
//                 onClick={handleClickTwo}
//                 borderColor={activeButton === 'activeOrders' ? '#659B1B' : '#659B1B'}
//                 background={activeButton === 'activeOrders' ? '#659B1B': 'transparent'}
//               >
//                 Active Orders
//               </CustomButton>

//               <CustomButton
//                 width='330px'
//                 height='50px'
//                 borderRadius='15px'
//                 variant={activeButton === 'readyForDelivery' ? 'contained' : 'outlined'}
//                 onClick={handleClickThree}
//                 color={activeButton === 'readyForDelivery' ? 'white' : '#646464'}
//                 borderColor={activeButton === 'readyForDelivery' ? '#659B1B' : '#659B1B'}
//                 background={activeButton === 'readyForDelivery' ? '#659B1B': 'transparent'}
//               >
//                 Ready for Delivery
//               </CustomButton>
//             </div>
//             {showCardOne && (

//        <div style={{ marginTop: '50px' }}>
//           {newOrderDetails.map((details) => (
//             <div style={{marginTop:'30px'}}>
//          <CustomCard borderLeft='8px solid #EA4335' width='88%' height='90px' padding='15px' >

//         <div key={details.orderId} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//           <div>
//             <p>{details.orderId}</p>
//             <p>{details.orderDate}</p>
//           </div>
//           <div>
//             <img src={Arrow} onClick={handleCardOne} alt='arrow' style={{ float: 'right', marginTop: '20px', cursor: 'pointer' }}></img>
//           </div>
//         </div>

//     </CustomCard>
//     </div>
//           ))}
//   </div>

// )}
//           {showCardTwo && (
//             <div style={{ marginTop: '50px' }}>
//               <CustomCard borderLeft='8px solid #1D89E1' width='88%' height='120px' padding='10px'>
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//                   <div>
//                     <p>#1023</p>
//                     <p>Friday, June 27, 2024, 3:43pm</p>
//                     <p>15mins</p>
//                   </div>

//                   <div style={{ marginTop: '30px', textTransform: 'lowercase' }}>
//                     <CustomButton variant='contained' background='#1D89E1' width='120px' height='50px' textColor='white'>
//                       In Progress
//                     </CustomButton>
//                   </div>
//                   <div style={{ marginTop: '15px' }}>
//                     <img src={ClockAnimation} alt='animation'></img>
//                   </div>
//                   <div style={{ justifyItems: 'space-between', display: 'flex', flexDirection: 'row' }}>
//                     <img src={Arrow} onClick={handleCardTwo} alt='arrow' style={{ float: 'right', marginTop: '-13px',cursor:'pointer' }}></img>
//                   </div>
//                 </div>
//               </CustomCard>
//             </div>
//           )}
//           {showCardThree && (
//             <div style={{ marginTop: '50px' }}>
//               <Container>
//                 <Typography variant="subtitle1" gutterBottom style={{ display: 'flex', gap: '40px' }}>
//                   <div onClick={handleAllClick} style={{ borderBottom: markUsRead ? '' : '2px solid #659B1B', cursor: 'pointer', fontWeight:'bold' }}>
//                     Ready For Delivery
//                   </div>
//                   <div
//                     onClick={() => {
//                       handleoutForDelivery();
//                       handleTotalColorChange();
//                     }}
//                     style={{ borderBottom: markUsRead ? '2px solid #659B1B' : '', cursor: 'pointer',fontWeight:'bold' }}
//                   >
//                     Out For Delivery
//                   </div>
//                 </Typography>
//               </Container>

//               {cardItems.length === 0 ? (
//                 <Typography variant="subtitle1" gutterBottom style={{marginLeft:"30px", fontWeight:'bold', marginTop:'30px'}}>
//                   No new notifications
//                 </Typography>
//               ) : !markUsRead ? (
//                 <>
//                   <Container style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop:'50px' }}>
//                     {cardItems.map((item, index) => (
//                       <CustomCard key={index} borderLeft='8px solid #FF9900' width='88%' height='120px' padding='10px' >
//                         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//                           <div>
//                             <p>{item.orderId}</p>
//                             <p>{item.date}</p>
//                             <p>{item.time}</p>
//                           </div>

//                           <div style={{ marginTop: '30px', textTransform: 'lowercase' }}>
//                             <CustomButton variant='contained' background='#FF9900' width='120px' height='50px' textColor='white'>
//                               Ready For Delivery
//                             </CustomButton>
//                           </div>
//                           <div style={{ marginTop: '15px' }}>
//                             <img src={ClockAnimation} alt='animation'></img>
//                           </div>
//                           <div style={{ justifyItems: 'space-between', display: 'flex', flexDirection: 'row' }}>
//                             <img src={Arrow} alt='arrow' style={{ float: 'right', marginTop: '-13px',cursor:'pointer' }} onClick={handleCardThree}></img>
//                           </div>
//                         </div>
//                       </CustomCard>
//                     ))}
//                   </Container>
//                 </>
//               ) : (
//                 <>
//                   <Container style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop:'50px' }}>
//                     {cardItems.map((item, index) => (
//                       <CustomCard key={index} borderLeft='8px solid #85BE49' width='88%' height='120px' padding='10px'>
//                         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//                           <div>
//                             <p>{item.orderId}</p>
//                             <p>{item.date}</p>
//                             <p>{item.time}</p>
//                           </div>

//                           <div style={{ marginTop: '30px', textTransform: 'lowercase' }}>
//                             <CustomButton variant='contained' background='#85BE49' width='120px' height='50px' textColor='white'>
//                               Out for delivery
//                             </CustomButton>
//                           </div>
//                           <div style={{ marginTop: '15px' }}>
//                             <img src={ClockAnimation} alt='animation'></img>
//                           </div>
//                           <div style={{ justifyItems: 'space-between', display: 'flex', flexDirection: 'row' }}>
//                             <img src={Arrow} alt='arrow' style={{ float: 'right', marginTop: '-13px' , cursor:'pointer'  }} onClick={handleCardFour}></img>
//                           </div>
//                         </div>
//                       </CustomCard>
//                     ))}
//                   </Container>
//                 </>
//               )}
//             </div>
//           )}

//       </>

//     </>
//   );
// };

// export default MyorderSearch;
import React, { useEffect, useState } from "react";
import NewOrderFrontPage from "./NewOrderFrontPage";
import ActiveOrderFrontPage from "./ActiveOrderFrontPage";
import "./index.css";
import CustomButton from "../Commoncomponents/button";
import CustomBadge from "../Commoncomponents/badge";
import CustomCard from "../Commoncomponents/card";
import Arrow from "../../utils/Icon/Arrow.svg";
import Pointer from "../../utils/Icon/Pointer.svg";
import Triangle from "../../utils/Icon/Triangle.svg";
import { Navigate, useNavigate } from "react-router-dom";
import NewOrder from "./NewOrder";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { contentFontSize_18, contentFontSize_20 } from "../Typography/index";
import { contentFontsize_14 } from "../Typography/index";
import { ButtonFontSize_18 } from "../Typography/index";
import { getNewOrdertlist } from "../../Redux/Action/SignInActions/SignInActions";
import { useDispatch, useSelector } from "react-redux";

function MyorderSearch(props) {
  
  useEffect((storeid) => {
    // Dispatch the action when the component mounts

    props?.getNewOrdertlist(storeid);
  },[]);
 // const [newOrderList,setNewOrderList] = useState([])
  // useEffect(()=>{
  const orderList =
  ("props",
  props &&
    props?.newOrders &&
    props?.newOrders?.data &&
    props?.newOrders?.data?.newOrdersList);

useEffect((storeid) => {
    props.getActiveOrdertlist(storeid);
},[]);
  const [activeOrderList,setActiveOrderList]= useState([])
  useEffect(()=>{
  const ActiveorderList=("props",props&&props?.activeOrders&&props?.activeOrders?.data&&props?.activeOrders?.data?.activeOrdersList)
  setActiveOrderList(ActiveorderList)
},[])

  useEffect((storeid) => {
    
    props.getReadyOrdertlist(storeid);
  }, []);
  const [readyOrdersList,setReadyOrdersList]= useState([])
  useEffect(()=>{
    const ReadyorderList=("props",props&&props?.readyOrders&&props?.readyOrders?.data&&props?.readyOrders?.data?.readyOrdersList)
    setReadyOrdersList(readyOrdersList)
  },[])
const storeid = localStorage.getItem('storeid')
useEffect(()=>{
   props.getAllCount(storeid)
},[])
const count = props?.allCount?.data?.getOrderStatusCount;
const socketCount = localStorage.getItem('newOrderCount')
if(count > orderList?.length || count === orderList?.length){
  props.getNewOrdertlist(storeid)
}
  const [activeButton, setActiveButton] = useState("newOrders");
  const isBadgeActive = activeButton 
  const [showCardOne, setShowCardOne] = useState(true);
  

  const [selectedOrderDetails, setSelectedOrderDetails] = useState([]);

  const nav = useNavigate();

  useEffect(() => {
  }, [selectedOrderDetails]);
  const [show, setShow] = useState(false);
  function handleCardOne(orderNumber) {
    const selectedOrder = orderList.find(
      (order) => order.orderNumber === orderNumber
    );
    setSelectedOrderDetails((prevSelected) => [...prevSelected, selectedOrder]);
    nav("/home/MyOrders/NewOrderDetail");

    localStorage.setItem("id", JSON.stringify(selectedOrder.id));
  }
  useEffect(() => {
  }, [show]);
  useEffect((storeid)=>{
    props.getOrderCount(storeid)
  },[])
  const outReady = ("props",props&&props?.OrderCount&&props?.OrderCount?.data&&props?.OrderCount?.data?.outReadyOrdersCount)
  const handleClickOne = () => {
    setShowCardOne(true);
    setActiveButton("newOrders");
  };
  function handleClickTwo() {
    setShowCardOne(false);
    setActiveButton("activeOrders");
    nav("/home/MyOrders/ActiveOrders");
  }
  function handleClickThree() {
    setShowCardOne(false);
    setActiveButton("readyForDelivery");
    nav("/home/MyOrders/ReadyForDelivery");
  }
 
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  function handleCard() {
    setShow(true);
  }
  return (

    <>
    {/* {!show && ( */}
    <>
    <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          gap: matches ? "10px" : "24px",
          marginTop:"0px",
      
       // Add background color for better visibility
     
        }}
      >
        <CustomButton
          width="82%"
          height="50px"
          borderRadius="15px"
          onClick={handleClickOne}
          color={activeButton === "newOrders" ? "white" : "#646464"}
          variant={activeButton === "newOrders" ? "contained" : "outlined"}
          background={activeButton === "newOrders" ? "#659B1B" : "transparent"}
          borderColor="#659B1B"
          //size={matches?"small":"large"}
          matchbtn={matches && true}
        >
          <Typography
            sx={{ fontFamily: "Roboto", fontWeight: "500" }}
            fontSize={ButtonFontSize_18}
          >
            New Orders
          </Typography>
          <CustomBadge
            badgeContent={count?.newOrders}
            backgroundColor={isBadgeActive === "newOrders" ? "white" : "none"}
            color={isBadgeActive === "newOrders"? "#659B1B" : "#646464"}
            borderColor={isBadgeActive=== "newOrders" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"} 
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="82%"
          height="50px"
          borderRadius="15px"
          color={activeButton === "activeOrders" ? "white" : "#646464"}
          variant={activeButton === "activeOrders" ? "contained" : "outlined"}
          onClick={handleClickTwo}
          borderColor={activeButton === "activeOrders" ? "#659B1B" : "#659B1B"}
          background={
            activeButton === "activeOrders" ? "#659B1B" : "transparent"
          }
          // matchbtn={matchessm &&true}
        >
          <Typography
            fontSize={ButtonFontSize_18}
            sx={{ fontFamily: "Roboto", fontWeight: "500" }}
          >
            Active Orders
          </Typography>
          <CustomBadge
            badgeContent={count?.activeOrders}
            backgroundColor={isBadgeActive === "activeOrders" ? "white" : "none"}
            color={isBadgeActive === "activeOrders" ? "#659B1B" : "#646464"}
            borderColor={isBadgeActive === "activeOrders" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="82%"
          height="50px"
          borderRadius="15px"
          variant={
            activeButton === "readyForDelivery" ? "contained" : "outlined"
          }
          onClick={handleClickThree}
          color={activeButton === "readyForDelivery" ? "white" : "#646464"}
          borderColor={
            activeButton === "readyForDelivery" ? "#659B1B" : "#659B1B"
          }
          background={
            activeButton === "readyForDelivery" ? "#659B1B" : "transparent"
          }
          //matchbtn={matchessm&&true}
        >
          <Typography
            fontSize={ButtonFontSize_18}
            sx={{ fontFamily: "Roboto", fontWeight: "500" }}
          >
            Ready for Delivery
          </Typography>
          <CustomBadge
            badgeContent={count?.readyOrders}
            backgroundColor={isBadgeActive === "readyForDelivery" ? "white" : "none"}
            color={isBadgeActive === "readyForDelivery" ? "#659B1B" : "#646464"}
            borderColor={isBadgeActive === "readyForDelivery" ? "none" : "#646464"}
            border="2px solid"
            fontSize={matchessm ? "8px" : "12px"}
            height={matchessm ? "15px" : "20px"}
            minWidth={matchessm ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matchessm ? (matches ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>
      </div>
      </div>
      <div style={{ marginTop: "50px" }} className='containerscrollflex'>
        
      <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: "20px" }} className='scrollable-content' >
        {orderList?.length === 0 ? "No results found ":( 
          <>
          {orderList?.map((details) => (
            <CustomCard
              borderLeft="8px solid #EA4335"
              width={upXlmatch?"100%":xlmatch?"100%":matcheslg?"100%":matchessm?"100%":matches?"100%":"0%"}
              height="100px"
              padding="15px 0px 15px 20px"
              onClick={() => {
                handleCardOne(details?.orderNumber); 
                
              }}
              boxShadow="none"
             
              
            
            >
              <div
                key={details?.orderId}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: window.innerWidth < 600 ? "5px 0px 10px 0px" : "5px 0px 10px 30px"
                }}
              >
                <div>
                  <Typography
                    sx={{ fontWeight: "600",fontFamily:"Roboto" }}
                    fontSize={contentFontSize_18}
                  >
                    #{details?.orderNumber}
                  </Typography>
                  {details?.stats?.map((item, index) => (
        <Typography
          key={index} // Add a unique key for each item
          fontSize={contentFontsize_14}
          sx={{ fontWeight: '400', fontFamily: 'Roboto' }}
        >
          {/* Log to verify the data */}

          {item.created}, {item.createdTime}
        </Typography>
      ))}
                </div>
                <div>
                  <img
                    src={Arrow}
                    
                    alt="arrow"
                    style={{
                      float: "right",
                      marginRight:"20px",
                      marginTop: "20px",
                      cursor: "pointer",
                      height: matches ? "15px" : "19px",
                    }}
                  ></img>
                </div>
              </div>
            </CustomCard>
          ))}
          </>
          )}
        </div>
        
      </div>
      </>
     {/* )} */}
      
      {/* {show && <NewOrder {...props} />} */}
    </>
  );
}

export default MyorderSearch;
