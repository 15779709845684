import * as yup from 'yup';

export const validationSchema = yup.object({
    couponCode: yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    .matches(/^[a-zA-Z0-9\-_*&^%$#@(!)]*$/, "Space is not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .trim("Space not allowed")
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    disAmt:yup.string()
    .trim("Space not allowed")
    .required('Discount amount is required')
    .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    minOrder:yup.string()
    .trim("Space not allowed")
    .required('Minimum order value is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    disPercent:yup.string()
    .trim("Space not allowed")
    .required('Discount percent is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('Start Date is required')
    .test('fromtime-equal-totime', 'Start Time and End Time must be different', function (value) {
        const { totime } = this.parent;
        return value !== totime;
      }),

    totime:yup.string()
    .trim("Space not allowed")
    .required('End Date is required')
    .when(['fromtime'],(fromtime,schema)=>{
        return schema.test({
            test:function(totime){
                if(!fromtime || !totime) return true;
                const  formTimeStamp= new Date(fromtime).getTime();
                const toTimeStamp=new Date(totime).getTime();
                return formTimeStamp < toTimeStamp;
            },
            message:'End Time must be greater than Start Time',
        });
    })
    .test('fromtime-equal-totime', 'Start Time and End Time must be different', function (value) {
        const { fromtime } = this.parent;
        return value !== fromtime;
      }),
})

export const flatdiscountvalidationSchema = yup.object({
    couponCode: yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    .matches(/^[a-zA-Z0-9\-_*&^%$#@(!)]*$/, "Space is not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .trim("Space not allowed")
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    disAmt:yup.string()
    .trim("Space not allowed")
    .required('Discount amount is required')
    // .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed')
    .matches(/^\d+$/,'Only positive numbers are allowed ')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    minOrder:yup.string()
    .trim("Space not allowed")
    .required('Minimum order value is required')
    // .matches(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,'Only numbers are allowed')
    .matches(/^\d+$/,'Only positive numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('Start Date is required')
    .test('start-before-end', 'End Time must be greater than Start Time', function (value) {
        const totime = this.parent.totime;
        if (!value || !totime) return true;
  
        const formTimeStamp = new Date(value).getTime();
        const toTimeStamp = new Date(totime).getTime();
        return formTimeStamp < toTimeStamp;
      }),
    
    totime:yup.string()
    .trim("Space not allowed")
    .required('End Date is required')
    .when(['fromtime'],(fromtime,schema)=>{
        return schema.test({
            test:function(totime){
                if(!fromtime || !totime) return true;
                const  formTimeStamp= new Date(fromtime).getTime();
                const toTimeStamp=new Date(totime).getTime();
                return formTimeStamp < toTimeStamp;
            },
            message:'End Time must be greater than Start Time',
        });
    }),
})

export const buyxgetyvalidationSchema = yup.object({
    couponCode: yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    .matches(/^[a-zA-Z0-9\-_*&^%$#@(!)]*$/, "Space is not allowed")
     .trim("Space not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    buyitem:yup.string()
    .required('Field is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    getitem:yup.string()
    .required('Field is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('Start Date is required')
    .test('start-before-end', 'End Time must be greater than Start Time', function (value) {
        const totime = this.parent.totime;
        if (!value || !totime) return true;
  
        const formTimeStamp = new Date(value).getTime();
        const toTimeStamp = new Date(totime).getTime();
        return formTimeStamp < toTimeStamp;
      }),
    
    
    totime:yup.string()
    .trim("Space not allowed")
    .required('End Date is required')
    .when(['fromtime'],(fromtime,schema)=>{
        return schema.test({
            test:function(totime){
                if(!fromtime || !totime) return true;
                const  formTimeStamp= new Date(fromtime).getTime();
                const toTimeStamp=new Date(totime).getTime();
                return formTimeStamp< toTimeStamp;
            },
            message:'End Time must be greater than Start Time',
        });
    }),
});

export const freedeliveryvalidationSchema = yup.object({
    couponCode: yup.string()
    .matches(/^\S*$/, "Space is not allowed")
    .matches(/^[a-zA-Z0-9\-_*&^%$#@(!)]*$/, "Space is not allowed")
    .required('Coupon code is required')
    .min(3, "Enter atleast 3 characters")
    .max(15, "Only 15 characters are allowed"),
    usesperCustomer: yup.string()
    .trim("Space not allowed")
    .required('Uses per customer is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    minOrder:yup.string()
    .trim("Space not allowed")
    .required('Minimum order value is required')
    .matches(/^\d+$/,'Only numbers are allowed')
    .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0),
    // fromdate:yup.string()
    // .trim("Space not allowed")
    // .required('From Date is required'),
    // todate:yup.string()
    // .trim("Space not allowed")
    // .required('To Date is required'),
    fromtime:yup.string()
    .trim("Space not allowed")
    .required('Start Date is required')
    .test('start-before-end', 'End Time must be greater than Start Time', function (value) {
        const totime = this.parent.totime;
        if (!value || !totime) return true;
  
        const formTimeStamp = new Date(value).getTime();
        const toTimeStamp = new Date(totime).getTime();
        return formTimeStamp < toTimeStamp;
      }),
    
    totime:yup.string()
    .trim("Space not allowed")
    .required('End Date is required')
    .when(['fromtime'],(fromtime,schema)=>{
        return schema.test({
            test:function(totime){
                if(!fromtime || !totime) return true;
                const  formTimeStamp= new Date(fromtime).getTime();
                const toTimeStamp=new Date(totime).getTime();
                return formTimeStamp< toTimeStamp;
            },
            message:'End Time must be greater than Start  Time',
        });
    }),
})