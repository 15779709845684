import React, { useState } from "react";
// import ReactDOM from 'react-dom';
import ImgCrop from "antd-img-crop";
import { Upload, message, Modal } from "antd";
import {Row,Col} from'react-bootstrap';
import Cards from '../../Cards';
// import "antd/dist/antd.css";
import "./index.css";
import client from "../../../Configurations/apollo";
import { useDispatch } from "react-redux";
import { AADHAR_UPLOAD } from "../../../Redux/Action/NewSignUp/GqlQuery";



function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}



const LicenseUpload = () => {
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch()
  // React.useEffect(()=>{
  //   if(props.licenseUrl!==null){
  //       setFileList( [{
  //         uid: '-1',
  //         name: 'License',
  //         status: 'done',
  //         url: props.licenseUrl&&
  //         props.licenseUrl,
  //       }])
  //     }
  //     },
  //         // eslint-disable-next-line react-hooks/exhaustive-deps
  //     [])
  // const [cropSize, setCropSize] = useState({ width: 512, height: 512 });
  const [previewImage,setPreviewImage]=useState();
  const [previewVisible,setPreviewVisible]=useState();
  const [previewTitle,setPreviewTitle]=useState()
  // const [cropSize, setCropSize] = useState({ width: 512, height: 512 });
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
 const handleCancel = () => {setPreviewVisible(false)}

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
   
  };

  const handleRemove = () => {
  setFileList([]);
  localStorage.removeItem("LicenceUrl")
  }
  const SubmitNow = () => {
    const Imgdata = fileList[0].originFileObj;
    handleImageUpload(Imgdata);
   
  };

  const handleImageUpload = (Imgdata)=>{
    dispatch({
			type: "LOADING_LICENSE_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${fileList.fileName}-license.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
          localStorage.setItem("LicenceUrl", res.data.imageEditAndUpload.Location);
            message.success({
              content: "Licence Uploaded Successfully", 
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
            })
					dispatch({
						type: "LICENSE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
          message.error({
            content: `${res.message}`, 
            className: "custom-class",
            style: {
              marginTop: "20vh",
              // marginLeft: "40vh",
            },
          })
					dispatch({
						type: "LICENSE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
        message.error({
          content: `${err.message}`, 
          className: "custom-class",
          style: {
            marginTop: "20vh",
            // marginLeft: "40vh",
          },
        })
				dispatch({
					type: "LICENSE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});

  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error('Image must smaller than 1MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <>
    <Row   style={{paddingLeft:10,paddingTop:10}}>
      <Col>
      <ImgCrop
        modalTitle="Logo"
        modalWidth={700}
        modalHeight={700}
        aspect={4/3}
        minZoom={0.1}
        rotate={true}
        resize={ false} //Crop can be adjusted
        resizeAndDrag={true} //Pop-up width
        // cropSize={cropSize}
        grid
      >
        <Upload
          maxCount={1}
          customRequest={dummyRequest}
          beforeUpload={(fileList)=>beforeUpload(fileList)}
          listType="picture-card"
          fileList={fileList}
          onRemove={handleRemove}
          onChange={onChange}
          onPreview={handlePreview}
          accept="image/*,pdf"
        >
          {fileList.length < 1 && "Click here to choose the image"} 
        </Upload>
      </ImgCrop>
      </Col>
      <Col>
      {/* <Cards/> */}
      </Col>
      </Row>
      <Row style={{ paddingLeft: 10, paddingTop: 10 }}>
        <Col>
      {fileList.length!==0&&(
       <button
       style={{
         justifyContent: " center",
         color: "rgb(255, 255, 255)",
         backgroundImage:
           "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
         boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
         width: "80px",
         height: "30px",
         fontSize: "16px",
         fontWeight: "600",
         borderRadius: "5px",
         borderWidth: "1px",
         borderStyle: " solid",
         borderColor: "transparent",
         borderImage: "initial",
         overflow: "hidden",
         outline: "none",
         minHeight: " 14px",
         fontFamily: "Varela Round, sans-serif",
       }}
       type="button"
       onClick={SubmitNow}
     >
       Upload
     </button>)}
       </Col>
     </Row>
     <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
     
    </>
  );
};
export default LicenseUpload;