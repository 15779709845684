import * as yup from "yup";

export const validationSchema = yup.object({
fssaiNo:yup.string()
.matches(/^[A-Z0-9]{14}$/, {
  message: "FSSAI number must be 14 characters long and consist of uppercase letters and positive numbers only.",
  excludeEmptyString: true
})
.test('not-negative', 'FSSAI number cannot contain negative values.', value => {
  return !value.includes('-');
})
.required("FSSAI number is required"),
})