// import React from 'react';
// import { Container } from "@mui/material";

// export default function CatalogBusiness(props) {
//     const isMobileScreen = window.innerWidth < 768;

//     React.useEffect(() => {
//         // props.getBusinessType();
//         console.log("props.BusinessType", props.BusinessType)
//     },// eslint-disable-next-line react-hooks/exhaustive-deps
//         [])

//     const CatalogDepartmentClick = (name, id) => {
//         localStorage.setItem('CatalogBusiness', name)
//         localStorage.setItem('CatalogBusinesId', id)
//         props.getMasterDepartment(localStorage.getItem('CatalogBusinesId'));
//         props.history.push('/home/CategoryBuilder/Departments')
//     }

//     return (
//         <>
//             {isMobileScreen ? (
//                 <Container maxWidth='md'
//                     style={{
//                         backgroundColor: "#fff",
//                         border: "1px solid rgba(0, 0, 0, 0.16)",
//                         borderRadius: "10px",
//                         Top: "40px",
//                         padding: "20px",
//                         display: 'flex',
//                         flexDirection: 'column',
//                         flex: ' 1 1 0',
//                         width: "300px",
//                     }}>
//                     <div
//                     style={{
//                         display: 'grid',
//                         gridGap: '24px',
//                         gap: '24px',
//                         gridTemplateColumns: 'repeat(auto-fill, minmax(368px, 1fr))',
//                     }}>
//                         {props.BusinessType && props.BusinessType.data &&
//                             props.BusinessType.data.businessTypeListing &&
//                             props.BusinessType.data.businessTypeListing.filter(item => props.retailerDetails && props.retailerDetails.data &&
//                                 props.retailerDetails.data.retailerdetails &&
//                                 props.retailerDetails.data.retailerdetails.stores[0].businessTypeId.includes(item.id)).map((m) =>
//                                     <div
//                                         key={m.id}
//                                         type="button"
//                                         onClick={() => { CatalogDepartmentClick(m.name, m.id) }}
//                                         style={{
//                                             display: 'flex',
//                                             flexDirection: 'column',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                             padding: '16px',
//                                             backgroundColor: '#fff',
//                                             border: 'solid 1px #d9d9d9',
//                                             borderRadius: '8px',
//                                             cursor: 'pointer',
//                                             width: '70%',
//                                             height: '93px',
//                                             marginBottom: '24px',
//                                         }}>
//                                         <img src={m.iconImage} alt="Business Type" width={50} height={50} />
//                                         <span style={{ marginTop: '16px' }}>{m.name}</span>
//                                     </div>

//                                 )}
//                     </div>
//                 </Container>
//             ) : (
//                 <Container maxWidth='md'
//                     style={{
//                         backgroundColor: "#fff",
//                         border: "1px solid rgba(0, 0, 0, 0.16)",
//                         borderRadius: "10px",
//                         Top: "40px",
//                         padding: "30px",
//                         display: 'flex',
//                         flexDirection: 'column',
//                         flex: ' 1 1 0',
//                         width: "100%",
//                     }}>
//                     <div
//                     style={{
//                         display: 'grid',
//                         gridGap: '24px',
//                         gap: '24px',
//                         gridTemplateColumns: 'repeat(auto-fill,minmax(368px,1fr))'
//                     }}>
//                         {props.BusinessType && props.BusinessType.data &&
//                             props.BusinessType.data.businessTypeListing &&
//                             props.BusinessType.data.businessTypeListing.filter(item => props.retailerDetails && props.retailerDetails.data &&
//                                 props.retailerDetails.data.retailerdetails &&
//                                 props.retailerDetails.data.retailerdetails.stores[0].businessTypeId.includes(item.id)).map((m) =>
//                                     <div
//                                         type="button"
//                                         onClick={() => { CatalogDepartmentClick(m.name, m.id) }}
//                                         style={{
//                                             display: 'flex',
//                                             alignItems: 'center',
//                                             justifyContent: 'space-between',
//                                             padding: '16px 0 16px 24px',
//                                             backgroundColor: '#fff',
//                                             border: 'solid 1px #d9d9d9',
//                                             borderRadius: '8px',
//                                             cursor: 'pointer',
//                                             width: '80%'
//                                         }}>
//                                         <span>
//                                             {m.name}
//                                         </span>
//                                         <span>
//                                             <img src={m.iconImage} alt="Business Type" width={50} height={50} />
//                                         </span>
//                                     </div>
//                                 )}
//                     </div>
//                 </Container>
//             )}
//         </>
//     );
// }
import React, { useState } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card";
import Arrow from "../../utils/Icon/Arrow.svg";
import Restaurant from "../../utils/Icon/Restaurant.svg";
import Homebakers from "../../utils/Icon/Homebakers.svg";
import Coldstorage from "../../utils/Icon/Coldstorage.svg";
import "./Catalog.css";
import Business from "./Business";
import {
  contentFontSize_20,
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
  contentFontSize_18,
  HeaderNavigationFontSize_20_xs_13,
} from "../Typography/index";
import { useNavigate } from "react-router-dom";

function Catalog(props) {
  React.useEffect(
    () => {
    },
    []
  );

  const CatalogDepartmentClick = (name, id) => {
    localStorage.setItem("CatalogBusiness", name);
    localStorage.setItem("CatalogBusinesId", id);
    props.getMasterDepartment(localStorage.getItem("CatalogBusinesId"));
  };

  const nav = useNavigate();
  const [arrowClick, setArrowClick] = useState(false);
  function handleClickOne() {
    setArrowClick(true);
    nav("/home/CategoryBuilder/Departments");
  }

  const [selectedBusinessType, setSelectedBusinessType] = useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {arrowClick ? (
        <Business
          setArrowClick={setArrowClick}
          selectedBusinessType={selectedBusinessType}
          setSelectedBusinessType={setSelectedBusinessType}
        />
      ) : (
        <div>
          <div>
            <Typography
              className="Business"
              fontSize={HeaderNavigationFontSize_20}
              
            >
              Choose your business type
            </Typography>
          </div>
          <div >
            {props.BusinessType &&
              props.BusinessType.data &&
              props.BusinessType.data.businessTypeListing &&
              props.BusinessType.data.businessTypeListing
                .filter(
                  (item) =>
                    props.retailerDetails &&
                    props.retailerDetails.data &&
                    props.retailerDetails.data.retailerdetails &&
                    props.retailerDetails.data.retailerdetails.stores[0].businessTypeId.includes(
                      item.id
                    )
                )
                .map((m) => (
                  <div className="Business-card-1" onClick={handleClickOne}>
                    <CustomCard
                      padding={"20px"}
                      onClick={() => { CatalogDepartmentClick(m.name, m.id)}}
                      width={"100%"}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} sx={{paddingLeft:"0px",paddingTop:"0px"}}>
                          <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                            <CustomCard
                              padding="2px"
                              height={"70px"}
                              background="#F2F2F2"
                              boxShadow="none"
                              borderRadius="10px"
                              width={matches ? "60px" : "70px"}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "6px",
                                }}
                              >
                                <img
                                  src={m.iconImage}
                                  alt="restaurant"
                                  style={{ width: matches ? "50px" : "100%" }}
                                />
                              </div>
                            </CustomCard>
                          </Grid>
                          <Grid item xs={8} sm={6} md={6} lg={8} xl={8}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                paddingLeft: "20px",
                                boxSizing: "border-box",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  // gap: "8px",
                                }}
                              >
                                <Typography
                                  className="Restaurant"
                                  fontSize={contentFontSize_18}
                                >
                                  {m.name}
                                </Typography>
                                <div></div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={1} sm={4} md={4} lg={3} xl={3}
                          sx={{paddingLeft:"0px",paddingTop:"0px"}}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <div style={{ display: "flex", gap: "15px" }}>
                                  <div onClick={handleClickOne}>
                                    <img src={Arrow} alt="arrow icon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomCard>
                  </div>
                ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Catalog;
