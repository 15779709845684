import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function TimeField(props) {

  React.useEffect(() => {
    props.GetEstimatedTime(`${props.orderid}`)
  }, [])
  React.useEffect(() => {
    props.setEstimatedTime(new Date(new Date().getTime() + ((props.EstimatedTime && props.EstimatedTime.travelTime && props.EstimatedTime.travelTime.value) * 60 * 1000)))
  }, [props.EstimatedTime])

  React.useEffect(() => {
    if (props.EstimatedTimesuccess === true) {
      setValue(new Date(new Date().getTime() + ((props.EstimatedTime && props.EstimatedTime.travelTime && props.EstimatedTime.travelTime.value) * 60 * 1000)))
    }
    props.setEstimatedTime(new Date(new Date().getTime() + ((props.EstimatedTime && props.EstimatedTime.travelTime && props.EstimatedTime.travelTime.value) * 60 * 1000)))
  }, [props.EstimatedTimesuccess])
  const [value, setValue] = React.useState(dayjs('2020-01-01 12:00'));
  const onKeyDown = (e) => {
    e.preventDefault();
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <TimePicker
          onChange={(newValue) => {
            setValue(newValue);
            props.setEstimatedTime(dayjs(newValue))
          }}
          value={value}
          renderInput={(params) => <TextField
            onKeyDown={onKeyDown}
            {...params} />}
          label="Estimated Delivery Time"
        />
      </Stack>
    </LocalizationProvider>
  );
}
