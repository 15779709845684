import React from 'react';
// import { Card } from 'react-bootstrap';
// import Viewer from 'react-viewer';
import { Card , Modal } from 'antd';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { green } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
// Components
import CropperModel from '../cropper-modal/CropperModel';
/** FileInputDemo functional component */
function EditShopAssistantIndex(props) {

    const [file, setFile] = React.useState(null);
    const [resizedImage, setResizedImage] = React.useState(null);
	const [previewOpen, setPreviewOpen] = React.useState(false);
   
	const [hover, setHover] = React.useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

	const handleCancel = () => setPreviewOpen(false);

  const handlePreview =  () => {
   
    setPreviewOpen(true);
   
  };

    const onFileInputChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);
        }
        e.target.value = null;
    };
    return (
		<>
		<div style={{display: 'flex',
  }}>
			<span >
		<IconButton color="primary" aria-label="upload picture" component="label" >
        <input hidden accept="image/*" type= "file" onChange={onFileInputChange} />
        <PhotoCamera sx={{ color: green[500] , fontSize: 40}}/>
      </IconButton>
	  </span>
	  <span >
	  { resizedImage &&
			<>
			<div
			 onMouseEnter={onHover}
			 onMouseLeave={onLeave}
			 role="button"
			onClick={handlePreview}
		  >
			
			<img alt="example" src={resizedImage} style={{ width: 100 }}/>
		
			
		  </div>
		  <Modal visible={previewOpen}  footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={resizedImage} />
      </Modal>
		  </>}
		  </span>
          </div>
          <CropperModel file={file} onConfirm={ (croppedFile) => {
				 props.uploadLogoImage({files:croppedFile});
                    setResizedImage(window.URL.createObjectURL(croppedFile))
                }}
				 onCompleted={ () => setFile(null) }>
           
		  </CropperModel>
		
			   </>
			   )
}
export default EditShopAssistantIndex;

