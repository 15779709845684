import * as yup from "yup";

export const validationSchema = yup.object({
  firstname: yup
    .string()
    .matches(/^\S*$/, "Space is not allowed")
    .min(2,"First name should be atleast 2 characters long")
    .max(20, "Length exceeded")
    .matches(/^[a-zA-Z]+$/, "only alphabets are allowed")
    .required("First name is required"),
  lastname: yup
    .string()
    .matches(/^\S*$/, "Space is not allowed")
    .matches(/^[a-zA-Z]+$/, "only alphabets are allowed")
    .max(20, "Length exceeded")
    .required("Last name is required"),
  mobilenumber: yup
    .string()
    .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
    .min(10, "Please enter valid phone number")
    .max(10, "Please enter valid phone number")
    .matches(/^\S*$/, "Space is not allowed")
    .required("Mobile Number is required"),
});
