// import React, { useState, useReducer, useEffect } from "react";
// import CustomButton from "../../Commoncomponents/button/index";
// import CustomCard from "../../Commoncomponents/card/index";
// import CustomTextField from "../../Commoncomponents/input/index";
// import CustomSelect from "../../Commoncomponents/selectButton/index";
// import { Checkbox } from "../../MUIComponents/index";
// import ClockIcon from "../../../utils/Icon/ClockIcon.svg";
// import { InputAdornment } from "../../MUIComponents/index";
// import { AdapterDateFns } from "../../MUIComponents/index";
// import {
//   Typography,
//   MenuItem,
//   Select,
//   FormControl,
// } from "../../MUIComponents/index";
// import { useTheme } from "../../MUIComponents/index";
// import { useMediaQuery } from "../../MUIComponents/index";
// import {
//   Grid,
//   TimePicker,
//   LocalizationProvider,
//   DemoContainer,
//   AdapterDayjs,
//   TextField,
//   Alert,
//   Stack,
// } from "../../MUIComponents/index";
// import { Box } from "../../MUIComponents/index";
// import { ButtonFontSize_18_xs_10 } from "../../Typography/index";
// import { HeaderNavigationFontSize_20, contentFontSize_18 } from "../../Typography/index";
// import { ButtonFontSize_17 } from "../../Typography/index";
// import { HeaderFontSize_25 } from "../../Typography/index";
// import { contentFontSize_16 } from "../../Typography/index";
// import { ButtonFontSize_18 } from "../../Typography/index";
// import EditDeliveryCharge from "../../EditDeliveryCharge";
// import SameDayDelivery from "../../AdditionalDetailsEdit/SameDayDelivery";
// import DeliveryDayAfter from "../../AdditionalDetailsEdit/DeliveryDayAfter";
// import { useFormik } from "formik";

// import { message } from "antd";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";

// const Days = [
//   {
//     value: 1,
//     label: "1 Day",
//   },
//   {
//     value: 2,
//     label: "2 Day",
//   },
//   {
//     value: 3,
//     label: "3 Day",
//   },
// ];
// const holidays = [
//   { name: "Sunday", status: false, val: 0 },
//   { name: "Monday", status: false, val: 1 },
//   { name: "Tuesday", status: false, val: 2 },
//   { name: "Wednesday", status: false, val: 3 },
//   { name: "Thursday", status: false, val: 4 },
//   { name: "Friday", status: false, val: 5 },
//   { name: "Saturday", status: false, val: 6 },
// ];
// export default function Additional(props) {
//   React.useEffect(() => {
//     document.body.scrollTop = 0;
//   }, []);
//   const [deliverday, setDeliveryday] = useState();

//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.down("md"));
//   const matches1 = useMediaQuery(theme.breakpoints.down("sm"));
//   const matches2 = useMediaQuery(theme.breakpoints.up("lg"));
//   const matches3 = useMediaQuery(theme.breakpoints.down("xl"));

//   const [openingTime, setTime] = useState(null)
//   const [closingTime, setCTime] = useState(null);

//   function MuiIcon1() {
//     return <img src={ClockIcon} alt="clock icon" width={25} />;
//   }

//   // const { values: formValues } = useFormikContext();

//   // const { storeName, storeDescription, storeLocation, businessType } =
//   //   formValues;
//   const initialValues = {
//     deliverday: deliverday || "",
//   };

//   const handleTableView = (e) => {
//     props.viewTable();
//   };

//   const onKeyDown = (e) => {
//     e.preventDefault();
//   };

//   const getTimeChange = (e) => {
//     setTime(e);
//   };

//   const getCTimeChange = (e) => {
//     console.log("e", e);
//     setCTime(e);
//   };

//   const formik = useFormik({
//     enableReinitialize: true,
//     initialValues: initialValues,
//     // validationSchema: ManageValidationSchema(distance),
//     onSubmit: (values) => {},
//   });

//   return (
//     <React.Fragment>
//       <div style={{ height: "100vh" }}>
//         <Grid container>
//           <h5 style={{ paddingLeft: 10, paddingTop: 10 }}>
//             Additional Details Page
//           </h5>
//         </Grid>
//         <Grid container style={{ paddingLeft: 10, paddingTop: 10 }}>
//           <Grid container xs={12} md={12}>
//             <div
//               style={{
//                 alignItems: "flex-start",
//                 display: "flex",
//                 justifyContent: matches1 ? "flex-start" : "flex-end",
//               }}
//             >
//               <Typography
//                 className="chooseHolidaysStyle"
//                 fontSize={contentFontSize_18}
//               >
//                 Type of delivery you support?{" "}
//                 <span style={{ color: "#EA4335" }}>*</span>
//               </Typography>
//               <div className="flexboxforcheckbox">
//                 <div
//                   style={{
//                     display: "flex",
//                     gap: "10px",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Checkbox
//                     style={{
//                       padding: 0,
//                       borderRadius: 0,
//                       color: "#85BE49",
//                     }}
//                     onChange={(e) => {}}
//                     name="deliveryType"
//                     inputProps={{ "aria-label": "primary checkbox" }}
//                     value="delivery"
//                     checked={false}
//                     size={matches ? "small" : "medium"}
//                   />
//                   <Typography
//                     className="kmStyle"
//                     fontSize={HeaderNavigationFontSize_20}
//                   >
//                     Delivery
//                   </Typography>
//                 </div>
//                 <div
//                   style={{
//                     display: "flex",
//                     gap: "10px",
//                     alignItems: "center",
//                   }}
//                 >
//                   <Checkbox
//                     style={{
//                       padding: 0,
//                       borderRadius: 0,
//                       color: "#85BE49",
//                     }}
//                     onChange={(e) => {}}
//                     name="deliveryType"
//                     inputProps={{ "aria-label": "primary checkbox" }}
//                     value="pick-up"
//                     checked={false}
//                     size={matches ? "small" : "medium"}
//                   />
//                   <Typography
//                     className="kmStyle"
//                     fontSize={HeaderNavigationFontSize_20}
//                   >
//                     Pick Up
//                   </Typography>
//                 </div>
//               </div>
//             </div>
//           </Grid>
//           <Grid item xs={12}>
//             <div style={{ paddingTop: "0px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: "10px",
//                   // marginBottom:matches4&&"10px",
//                 }}
//               >
//                 <Typography
//                   className="chooseHolidaysStyle"
//                   fontSize={HeaderNavigationFontSize_20}
//                 >
//                   Enter the minimum order amount{" "}
//                   <span style={{ color: "#EA4335" }}>*</span>
//                 </Typography>
//                 <div>
//                   <TextField
//                     sx={{
//                       // width: matches2 ? "450px" : "100%",

//                       "& .MuiOutlinedInput-root": {
//                         "& fieldset": {
//                           borderColor: "#85BE49",
//                         },
//                         "&.Mui-focused fieldset": {
//                           borderColor: "#85BE49",
//                         },
//                       },
//                       "& .MuiInputBase-input": {
//                         backgroundColor: "white",
//                         borderRadius: "10px",
//                       },
//                     }}
//                     InputProps={{
//                       sx: {
//                         fontSize: matches && "13px",
//                         //  height: 50
//                       },
//                     }}
//                     size={matches ? "small" : "normal"}
//                     value={formik.values.minimumOrder}
//                     onChange={formik.handleChange}
//                     error={
//                       formik.touched.minimumOrder &&
//                       Boolean(formik.errors.minimumOrder)
//                     }
//                     helperText={
//                       formik.touched.minimumOrder && formik.errors.minimumOrder
//                     }
//                     name="minimumOrder"
//                     // InputProps={{ style: { height: 50 } }}
//                     variant="outlined"
//                     fullWidth
//                   ></TextField>
//                 </div>
//               </div>
//             </div>
//           </Grid>
//           <Grid item md={12} lg={12}>
//             <div style={{ paddingTop: "0px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: "10px",
//                   // marginBottom:matches4&&"10px",
//                 }}
//               >
//                 <Typography
//                   className="chooseHolidaysStyle"
//                   fontSize={HeaderNavigationFontSize_20}
//                 >
//                   Enter the Packing Charges{" "}
//                   <span style={{ color: "#EA4335" }}>*</span>
//                 </Typography>
//                 <div>
//                   <TextField
//                     fullWidth
//                     sx={{
//                       // width: matches2 ? "450px" : "100%",

//                       "& .MuiOutlinedInput-root": {
//                         "& fieldset": {
//                           borderColor: "#85BE49",
//                         },
//                         "&.Mui-focused fieldset": {
//                           borderColor: "#85BE49",
//                         },
//                       },
//                       "& .MuiInputBase-input": {
//                         backgroundColor: "white",
//                         borderRadius: "10px",
//                       },
//                     }}
//                     InputProps={{
//                       sx: {
//                         fontSize: matches && "13px",
//                         //  height: 50
//                       },
//                     }}
//                     size={matches ? "small" : "normal"}
//                     value={formik.values.packingChargeState}
//                     onChange={formik.handleChange}
//                     error={
//                       formik.touched.packingChargeState &&
//                       Boolean(formik.errors.packingChargeState)
//                     }
//                     helperText={
//                       formik.touched.packingChargeState &&
//                       formik.errors.packingChargeState
//                     }
//                     name="packingChargeState"
//                     // InputProps={{ style: { height: 50 } }}
//                     variant="outlined"
//                   ></TextField>
//                 </div>
//               </div>
//             </div>
//           </Grid>
//           <Grid>
//           <div style={{ display: "flex",gap:"10px",flexWrap: "wrap", paddingLeft: "0px", marginTop: matches3 && "20px" }}>
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "column",
//                         gap: "10px",
//                       }}
//                     >
//                       <Typography
//                         className="chooseHolidaysStyle"
//                         fontSize={HeaderNavigationFontSize_20}
//                       >
//                         Opening Time <span style={{ color: "#EA4335" }}>*</span>
//                       </Typography>
//                       <div>
//                         {/* {Start} */}

//                         <div
//                         >
//                           <LocalizationProvider dateAdapter={AdapterDateFns}>
//                             <DemoContainer components={["TimePicker"]}>
//                               <TimePicker
//                                 slots={{
//                                   openPickerIcon: MuiIcon1,

//                                 }}
//                                 slotProps={{
//                                   textField: {
//                                     size: matches && "small",
//                                   },
//                                 }}
//                                 sx={{
//                                   width: "80%",

//                                   "& .MuiOutlinedInput-root": {
//                                     paddingRight: "25px",
//                                     "& fieldset": {
//                                       borderColor: "#85BE49",
//                                     },
//                                     "&.Mui-focused fieldset": {
//                                       borderColor: "#85BE49",
//                                     },
//                                   },
//                                   "& .MuiInputBase-input": {
//                                     backgroundColor: "white",
//                                     borderRadius: "10px",
//                                     fontSize: matches && "13px",
//                                     paddingLeft: "25px"
//                                   },
//                                 }}
//                                 name="openingTime"
//                                 value={openingTime}
//                                 onChange={(e) => {
//                                   getTimeChange(e);
//                                 }}
//                                 renderInput={(props) => (
//                                   <TextField
//                                     onKeyDown={onKeyDown}
//                                     variant="outlined"
//                                     {...props}
//                                   />
//                                 )}
//                               // onClickOpenPicker={() => setTime('')}
//                               />
//                             </DemoContainer>
//                           </LocalizationProvider>
//                         </div>

//                       </div>
//                     </div>

//                     <div
//                     >
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "column",
//                           gap: "10px",
//                         }}
//                       >
//                         <Typography
//                           className="chooseHolidaysStyle"
//                           fontSize={HeaderNavigationFontSize_20}
//                         >
//                           Closing Time <span style={{ color: "#EA4335" }}>*</span>
//                         </Typography>
//                         <div>
//                           {/* {Start} */}

//                           <div
//                           >
//                             <LocalizationProvider dateAdapter={AdapterDateFns}>
//                               <DemoContainer components={["TimePicker"]}>
//                                 <TimePicker
//                                   slots={{
//                                     openPickerIcon: MuiIcon1,
//                                   }}
//                                   slotProps={{
//                                     textField: {
//                                       placeholder: "Closing Time",
//                                       size: matches && "small",

//                                     },
//                                   }}
//                                   sx={{
//                                     width: "80%",

//                                     "& .MuiOutlinedInput-root": {
//                                       paddingRight: "25px",
//                                       "& fieldset": {
//                                         borderColor: "#85BE49",

//                                       },
//                                       "&.Mui-focused fieldset": {
//                                         borderColor: "#85BE49",
//                                       },
//                                     },
//                                     "& .MuiInputBase-input": {
//                                       backgroundColor: "white",
//                                       borderRadius: "10px",
//                                       fontSize: matches && "13px",
//                                       paddingLeft: "25px"
//                                     },
//                                   }}
//                                   name={"closingTime"}
//                                   value={closingTime || ""}
//                                   onChange={(e) => {
//                                     getCTimeChange(e);
//                                   }}
//                                   renderInput={(props) => (
//                                     <TextField
//                                       onKeyDown={onKeyDown}
//                                       variant="outlined"
//                                       {...props}
//                                     />
//                                   )}
//                                 />
//                               </DemoContainer>
//                             </LocalizationProvider>
//                           </div>

//                         </div>
//                       </div>
//                     </div>
//                   </div>
//           </Grid>
//           <Grid item md={12} lg={12}>
//             <div style={{ paddingTop: "0px" }}>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   gap: "10px",
//                   // marginBottom:matches4&&"10px",
//                 }}
//               >
//                 <Typography
//                   className="chooseHolidaysStyle"
//                   fontSize={HeaderNavigationFontSize_20}
//                 >
//                   Enter Preparation Time{" "}
//                   <span style={{ color: "#EA4335" }}>*</span>
//                 </Typography>
//                 <div>
//                   <TextField
//                     fullWidth
//                     sx={{
//                       // width: matches2 ? "450px" : "100%",

//                       "& .MuiOutlinedInput-root": {
//                         "& fieldset": {
//                           borderColor: "#85BE49",
//                         },
//                         "&.Mui-focused fieldset": {
//                           borderColor: "#85BE49",
//                         },
//                       },
//                       "& .MuiInputBase-input": {
//                         backgroundColor: "white",
//                         borderRadius: "10px",
//                       },
//                     }}
//                     InputProps={{
//                       sx: {
//                         fontSize: matches && "13px",
//                         //  height: 50
//                       },
//                     }}
//                   ></TextField>
//                 </div>
//               </div>
//             </div>
//           </Grid>
//           <Grid>
//           <form onSubmit={formik.handleSubmit}>
//               <Typography
//                 className="chooseHolidaysStyle"
//                 fontSize={HeaderNavigationFontSize_20}
//               >
//                 Choose Holidays <span style={{ color: " #EA4335" }}>*</span>
//               </Typography>
//               <div style={{ width: "100%", padding: matches ? "10px 0px 10px 0px" : "30px 0px 30px 0px" }}>
//                 <Grid container spacing={1} justifyContent="space-between">
//                   {holidays.map((h, index) => (
//                     <Grid
//                       item
//                       key={index}
//                       xs={6}
//                       sm={6}
//                       md={1.714}
//                       lg={1.714}
//                       xl={1.714}
//                     >
//                       <CustomButton
//                         borderColor="#85BE49"
//                         width="100%"
//                         height="100%"
//                         borderRadius="11.88px"
//                         size={matches ? "small" : "large"}
//                         // background={
//                         //   stores?.[0]?.holidays.includes(h.val)
//                         //     ? "#85BE49"
//                         //     : "#F4F5F9"
//                         // }
//                         // onClick={(e) => {
//                         //   props.setHolidaySlot(
//                         //     index,
//                         //     props?.retailerDetails?.data?.retailerdetails
//                         //   );
//                         // }}
//                       // onClick={() => handleButtonClick(day)}
//                       >
//                         <Typography
//                           fontSize={ButtonFontSize_17}
//                           className="weekFontStyle"
//                         >
//                           {h.name}
//                         </Typography>
//                       </CustomButton>
//                     </Grid>
//                   ))}
//                 </Grid>
//                 {stores?.[0]?.holidays?.length === 7 && (
//                   <>
//                     <div style={{ marginTop: "20px" }}>
//                       <Stack sx={{ width: "100%" }} spacing={2}>
//                         <Alert severity="warning">
//                           Choosing all days is not allowed
//                         </Alert>
//                       </Stack>
//                     </div>
//                   </>
//                 )}
//               </div>

//               <Typography
//                 className="chooseHolidaysStyle"
//                 fontSize={HeaderNavigationFontSize_20}
//               >
//                 Choose Delivery Schedule{" "}
//                 <span style={{ color: " #EA4335" }}>*</span>
//               </Typography>
//               <div style={{ width: "100%" }}>
//                 <div className="scrollContainer">
//                   {/* {start} */}

//                   <div className="SliderHeaderflex">
//                     {stores?.[0]?.deliveryDays.map(
//                       (day, key) =>
//                         checkHoliday(key, stores[0].holidays) === false && (
//                           // console.log('this is day',day),

//                           <CustomCard
//                             padding="20px"
//                             key={key}
//                             // boxShadow="none"
//                             width="400px"
//                           >
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                                 gap: matches ? "15px" : "30px",
//                                 width: matches1 ? "250px" : "350px",
//                               }}
//                             >
//                               <Typography
//                                 style={{ textAlign: "center" }}
//                                 fontSize={HeaderFontSize_25}
//                                 className="DeliveryStyle"
//                               >
//                                 {day.deliveryDay !== null && day.deliveryDay}
//                               </Typography>
//                               <div
//                                 style={{
//                                   display: "grid",
//                                   gridTemplateColumns: "auto auto",
//                                   gap: matches1 ? "20px" : "30px",
//                                 }}
//                               >
//                                 <CustomButton
//                                   key={"slot1"}
//                                   // variant="outlined"
//                                   borderRadius="14.41px"
//                                   size={matches ? "small" : "large"}
//                                   textColor={
//                                     day.T9amTO12pm === "Yes" ? "white" : "white"
//                                   }

//                                     background={
//                                       day.T9amTO12pm === "Yes"
//                                         ? "#85BE49"
//                                         : "#8B8989"
//                                     }
//                                   onClick={() => {
//                                     props.scheduleEdit({
//                                       key,
//                                       timing: "T9amTO12pm",
//                                       scheduleDetail:
//                                         props.retailerDetails.data
//                                           .retailerdetails,
//                                     });
//                                   }}
//                                   matchPadding={true}
//                                   padding={matches ? "5px 15px" : "10px 60px"}
//                                 >
//                                   <Typography
//                                     className="DeliveryStyle"
//                                     fontSize={ButtonFontSize_18_xs_10}
//                                   >
//                                     {" "}
//                                     09 AM - 12 PM
//                                   </Typography>
//                                 </CustomButton>
//                                 <CustomButton
//                                   key={"slot2"}
//                                   // variant="outlined"
//                                   borderRadius="14.41px"
//                                   size={matches ? "small" : "large"}
//                                   textColor={
//                                     day.T12pmTO3pm === "Yes" ? "white" : "white"
//                                   }
//                                   background={
//                                     day.T12pmTO3pm === "Yes"
//                                       ? "#85BE49"
//                                       : "#8B8989"
//                                   }
//                                   onClick={() => {
//                                     props.scheduleEdit({
//                                       key,
//                                       timing: "T12pmTO3pm",
//                                       scheduleDetail:
//                                         props.retailerDetails.data
//                                           .retailerdetails,
//                                     });
//                                   }}
//                                   matchPadding={true}
//                                   padding={matches ? "5px 15px" : "10px 60px"}
//                                 >
//                                   <Typography
//                                     className="DeliveryStyle"
//                                     fontSize={ButtonFontSize_18_xs_10}
//                                   >
//                                     {" "}
//                                     12 PM - 03 PM
//                                   </Typography>
//                                 </CustomButton>
//                                 <CustomButton
//                                   key={"slot3"}
//                                   // variant="outlined"
//                                   borderRadius="14.41px"
//                                   size={matches ? "small" : "large"}
//                                   textColor={
//                                     day.T3pmTO6pm === "Yes" ? "white" : "white"
//                                   }
//                                   background={
//                                     day.T3pmTO6pm === "Yes"
//                                       ? "#85BE49"
//                                       : "#8B8989"
//                                   }
//                                   onClick={() => {
//                                     props.scheduleEdit({
//                                       key,
//                                       timing: "T3pmTO6pm",
//                                       scheduleDetail:
//                                         props.retailerDetails.data
//                                           .retailerdetails,
//                                     });
//                                   }}
//                                   matchPadding={true}
//                                   padding={matches ? "5px 15px" : "10px 60px"}
//                                 >
//                                   <Typography
//                                     className="DeliveryStyle"
//                                     fontSize={ButtonFontSize_18_xs_10}
//                                   >
//                                     {" "}
//                                     03 PM - 06 PM
//                                   </Typography>
//                                 </CustomButton>
//                                 <CustomButton
//                                   key={"slot4"}
//                                   // variant="outlined"
//                                   borderRadius="14.41px"
//                                   size={matches ? "small" : "large"}
//                                   textColor={
//                                     day.T6pmTO9pm === "Yes" ? "white" : "white"
//                                   }
//                                   background={
//                                     day.T6pmTO9pm === "Yes"
//                                       ? "#85BE49"
//                                       : "#8B8989"
//                                   }
//                                   onClick={() => {
//                                     props.scheduleEdit({
//                                       key,
//                                       timing: "T6pmTO9pm",
//                                       scheduleDetail:
//                                         props.retailerDetails.data
//                                           .retailerdetails,
//                                     });
//                                   }}
//                                   matchPadding={true}
//                                   padding={matches ? "5px 15px" : "10px 60px"}
//                                 >
//                                   <Typography
//                                     className="DeliveryStyle"
//                                     fontSize={ButtonFontSize_18_xs_10}
//                                   >
//                                     {" "}
//                                     06 PM - 09 PM
//                                   </Typography>
//                                 </CustomButton>
//                               </div>
//                             </div>
//                           </CustomCard>
//                         )
//                     )}
//                   </div>
//                   {/* {end} */}
//                 </div>
//               </div>
//               <Typography
//                 className="chooseHolidaysStyle"
//                 fontSize={HeaderNavigationFontSize_20}
//                 style={{ paddingTop: matches ? "10px" : "20px" }}
//               >
//                 Delivery Details (Maximum distance for delivery){" "}
//                 <span style={{ color: " #EA4335" }}>*</span>
//               </Typography>
//               <div>
//                 <Box
//                   style={{
//                     width: "100%",
//                     background: "white",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <FormControl fullWidth>
//                     <Select
//                       size={matches ? "small" : "normal"}
//                       // match={matches ? true : false}
//                       sx={{
//                         color: "white",
//                         ".MuiOutlinedInput-notchedOutline": {
//                           borderColor: "#85BE49",
//                         },
//                         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "#85BE49",
//                         },
//                         "&:hover .MuiOutlinedInput-notchedOutline": {
//                           borderColor: "#85BE49",
//                         },
//                       }}

//                       MenuProps={{
//                         disableScrollLock: true,
//                         marginThreshold: null,
//                       }}
//                       value={stores?.[0]?.deliveryMaxDistance}
//                       onChange={(e) => {
//                         setDistance(e.target.value);
//                         props.changeMaxDistance({
//                           value: e.target.value,
//                           details: props.retailerDetails.data.retailerdetails,
//                         });
//                       }}
//                     >
//                       {distanceTable.options.map((option, index) => {
//                         return (
//                           <MenuItem
//                             style={{ width: "100%" }}
//                             value={option}
//                             key={index}
//                           >
//                             {option}
//                           </MenuItem>
//                         );
//                       })}
//                     </Select>
//                   </FormControl>
//                 </Box>
//               </div>

//               {/* {start} */}
//               <div style={{ marginTop: matches ? "20px" : "25px" }}>
//                 <EditDeliveryCharge props={props} distance={distance} formik={formik} />
//               </div>
//               {/* {end} */}

//               <div style={{ textAlign: "center", marginTop: "50px" }}>
//                 <CustomButton
//                   width={matches ? "40%" : "30%"}
//                   borderRadius="20px"
//                   size={matches ? "medium" : "large"}
//                   type="submit"
//                   matchPadding={true}
//                   padding="10px 0px"
//                   background={formSubmitted ? "gray" : '#659B1B'}
//                   disabled={formSubmitted && "disabled"}
//                 >
//                   <Typography
//                     fontSize={ButtonFontSize_18}
//                     className="manageSlotButton"
//                   >
//                     Save Changes
//                   </Typography>
//                 </CustomButton>
//               </div>
//             </form>
//           </Grid>
//         </Grid>
//         {/* <DistanceForm {...props} /> */}
//       </div>
//     </React.Fragment>
//   );
// }
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import {
  AdapterDateFns,
  TimePicker,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  LocalizationProvider,
  MenuItem,
  Select,
  FormControl,
} from "../../MUIComponents/index";
import CustomCard from "../../Commoncomponents/card";
import CustomButton from "../../Commoncomponents/button";
import {
  HeaderFontSize_25,
  ButtonFontSize_18_xs_10,
  contentFontSize_22,
  contentFontSize_16,
} from "../../Typography";
import Holidays from "./Holidays";
import { useLocation, useNavigate } from "react-router-dom";
import { validationSchema } from "../Validation/SlotValidation";
import { SIGNUP_FORM3 } from "../../../Redux/Action/NewSignUp/GqlQuery";
import client from "../../../Configurations/apollo";
import { useDispatch } from "react-redux";
import DeliveryCharge from "./DeliverCharge";
import SameDayDelivery from "./SameDayDelivery";

const Registration = () => {
  const [sameDay, setSameday] = useState(
    localStorage.getItem("homeBusinessSelected")
  );

  //   useEffect(() => {
  //     const homeBusinessSelected = localStorage.getItem("homeBusinessSelected");
  //     if (homeBusinessSelected) {
  //         setSameDay(homeBusinessSelected);
  //     }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nav = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/RegisterForm2") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
    return () => {
      window.removeEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [location.pathname]);

  const dispatch = useDispatch();

  const initialSchedule = [
    {
      day: "Sunday",
      timings: {
        T9amTO12pm: "No",
        T12pmTO3pm: "No",
        T3pmTO6pm: "No",
        T6pmTO9pm: "No",
      },
    },
    {
      day: "Monday",
      timings: {
        T9amTO12pm: "No",
        T12pmTO3pm: "No",
        T3pmTO6pm: "No",
        T6pmTO9pm: "No",
      },
    },
    {
      day: "Tuesday",
      timings: {
        T9amTO12pm: "No",
        T12pmTO3pm: "No",
        T3pmTO6pm: "No",
        T6pmTO9pm: "No",
      },
    },
    {
      day: "Wednesday",
      timings: {
        T9amTO12pm: "No",
        T12pmTO3pm: "No",
        T3pmTO6pm: "No",
        T6pmTO9pm: "No",
      },
    },
    {
      day: "Thursday",
      timings: {
        T9amTO12pm: "No",
        T12pmTO3pm: "No",
        T3pmTO6pm: "No",
        T6pmTO9pm: "No",
      },
    },
    {
      day: "Friday",
      timings: {
        T9amTO12pm: "No",
        T12pmTO3pm: "No",
        T3pmTO6pm: "No",
        T6pmTO9pm: "No",
      },
    },
    {
      day: "Saturday",
      timings: {
        T9amTO12pm: "No",
        T12pmTO3pm: "No",
        T3pmTO6pm: "No",
        T6pmTO9pm: "No",
      },
    },
  ];

  const [selectedSchedule, setSelectedSchedule] = useState(initialSchedule);

  const areAllValuesNo = (schedule) => {
    return schedule.every((day) =>
      Object.values(day.timings).every((value) => value === "No")
    );
  };

  const [deliveryOption, setDeliveryOption] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState();

  const handleDeliveryChange = (option, time) => {
    setDeliveryOption(option);
    setDeliveryTime(time);
  };

  const [deliveryCharges, setDeliveryCharges] = useState([]);
  const [distance, setDistance] = useState();

  const initialValues = {
    deliveryMaxDistance: "",
    firstKm: "",
    secondKm: "",
    thirdKm: "",
    fourthKm: "",
    fifthKm: "",
  };

  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const formik = useFormik({
    initialValues: {
      deliveryType: [],
      minAmount: "",
      openingTime: new Date(),
      closingTime: new Date(),
      packingCharge: "",
      preparationTime: "",
      holidays: selectedHolidays,
      deliveryMaxDistance: 0,
      deliverySchedule: selectedSchedule,
      deliveryCharges: [],
      deliveryAfter: deliveryTime,
      SameDayDelivery: deliveryOption,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formattedOpeningTime = format(values.openingTime, "HH:mm");
      const formattedClosingTime = format(values.closingTime, "HH:mm");

      const deliverDay = values.deliverySchedule.map((day) => ({
        T9amTO12pm: day.timings.T9amTO12pm,
        T12pmTO3pm: day.timings.T12pmTO3pm,
        T3pmTO6pm: day.timings.T3pmTO6pm,
        T6pmTO9pm: day.timings.T6pmTO9pm,
        deliveryDay: day.day,
      }));

      if (values.deliveryType.length === 0) {
        message.error({
          content: "Delivery type is required",
          className: "custom-class",
          style: { marginTop: "20vh" },
        });
        return;
      }
      if (values.deliveryMaxDistance === 0) {
        message.error({
          content: "Please select a delivery max distance",
          className: "custom-class",
          style: { marginTop: "20vh" },
        });
        return;
      }
      if (!values.holidays || values.holidays.length === 0) {
        message.error({
          content: "Please select a holiday",
          className: "custom-class",
          style: { marginTop: "20vh" },
        });
        return;
      }
      if (areAllValuesNo(selectedSchedule)) {
        message.error({
          content: "Please select a time slot",
          className: "custom-class",
          style: { marginTop: "20vh" },
        });
        return;
      }

      const variables = {
        deliveryType: values.deliveryType,
        minAmount: values.minAmount ? Number(values.minAmount) : 1,
        openingTime: formattedOpeningTime,
        closingTime: formattedClosingTime,
        holidays: values.holidays,
        deliveryDays: deliverDay,
        deliveryMaxDistance: values.deliveryMaxDistance
          ? Number(values.deliveryMaxDistance)
          : 0,
        deliveryCharge: values.deliveryCharges,
        packingCharge: values.packingCharge ? Number(values.packingCharge) : 0,
      };

      if (values.deliveryAfter) {
        variables.deliveryAfter = values.deliveryAfter;
      }
      if (typeof values.SameDayDelivery === "boolean") {
        variables.sameDayDelivery = values.SameDayDelivery;
      }

      dispatch({ type: "SIGNUP_FORM3_REQ" });

      client
        .mutate({
          mutation: SIGNUP_FORM3,
          variables: variables,
        })
        .then((response) => {
          dispatch({ type: "SIGNUP_FORM3_SUCCESS", payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: "SIGNUP_FORM3_FAILURE", payload: error.message });
        });

      nav("/RegisterForm3");
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorField = Object.keys(formik.errors)[0];
      const element = document.getElementsByName(errorField)[0];

      if (element) {
        setTimeout(() => {
          element.focus();
        }, 0);
      } else {
        console.warn(`Element with name '${errorField}' not found.`);
      }
    }
  }, [formik]);

  useEffect(() => {
    formik.setFieldValue("holidays", selectedHolidays);
    formik.setFieldValue("deliverySchedule", selectedSchedule);
    formik.setFieldValue("deliveryCharges", deliveryCharges);
    formik.setFieldValue("deliveryAfter", deliveryTime);
    formik.setFieldValue("SameDayDelivery", deliveryOption);
  }, [selectedHolidays, selectedSchedule, deliveryCharges, deliveryTime]);

  const distanceTable = {
    label: "Delivery Max-Distance",
    name: "deliveryMaxDistance",
    type: "selectbox",
    value: "3",
    options: ["3", "6", "9", "12", "15"],
  };

  const [daysOfWeek, setDaysOfWeek] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);

  useEffect(() => {
    if (selectedHolidays >= 0 && selectedHolidays <= 6) {
      const dayToRemove = daysOfWeek[selectedHolidays];
      const updatedDaysOfWeek = daysOfWeek.filter(day => day !== dayToRemove);
      setDaysOfWeek(updatedDaysOfWeek);
    } else {
    }
  }, [selectedHolidays]);
  
  const handleButtonClick = (day, timing) => {
    setSelectedSchedule((prev) =>
      prev.map((schedule) =>
        schedule.day === day
          ? {
              ...schedule,
              timings: {
                ...schedule.timings,
                [timing]: schedule.timings[timing] === "Yes" ? "No" : "Yes",
              },
            }
          : schedule
      )
    );
  };

  const isSelected = (day, timing) => {
    const daySchedule = selectedSchedule.find(
      (schedule) => schedule.day === day
    );
    return daySchedule ? daySchedule.timings[timing] === "Yes" : false;
  };

  const renderDaySchedule = (day) => (
    <CustomCard padding="20px" width="400px" key={day}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          width: "350px",
        }}
      >
        <Typography
          style={{ textAlign: "center" }}
          fontSize={HeaderFontSize_25}
          className="DeliveryStyle"
        >
          {day}
        </Typography>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "30px",
          }}
        >
          {["T9amTO12pm", "T12pmTO3pm", "T3pmTO6pm", "T6pmTO9pm"].map(
            (timing, index) => (
              <CustomButton
                key={index}
                borderRadius="14.41px"
                size="large"
                textColor="white"
                background={isSelected(day, timing) ? "#85BE49" : "#808080"}
                matchPadding={true}
                padding="10px 60px"
                onClick={() => handleButtonClick(day, timing)}
              >
                <Typography
                  className="DeliveryStyle"
                  fontSize={ButtonFontSize_18_xs_10}
                >
                  {timing === "T9amTO12pm" && "09 AM - 12 PM"}
                  {timing === "T12pmTO3pm" && "12 PM - 03 PM"}
                  {timing === "T3pmTO6pm" && "03 PM - 06 PM"}
                  {timing === "T6pmTO9pm" && "06 PM - 09 PM"}
                </Typography>
              </CustomButton>
            )
          )}
        </div>
      </div>
    </CustomCard>
  );

  return (
    <div style={{ height: "auto", width: "100%", background: "white" }}>
      <div>
        <div style={{ background: "#85BE49", padding: "10px" }}>
          <Typography variant="h4" align="center" gutterBottom color={"white"}>
            Registration
          </Typography>
        </div>
        <div style={{ padding: "10px" }}>
          <Typography
            align="center"
            gutterBottom
            fontSize={contentFontSize_22}
            className="chooseHolidaysStyle"
          >
            Please enter your details to create your account
          </Typography>
        </div>
        <div style={{ paddingLeft: "30px", paddingTop: "30px" }}>
          <Typography variant="h6" gutterBottom sx={{ paddingLeft: "30px" }}>
            Additional Details Page
          </Typography>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={3}
            sx={{ padding: "60px", paddingTop: "0px" }}
          >
            <Grid item xs={12} spacing={1}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Type of delivery you support?{" "}
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.deliveryType.includes("delivery")}
                    onChange={() => {
                      if (formik.values.deliveryType.includes("delivery")) {
                        formik.setFieldValue(
                          "deliveryType",
                          formik.values.deliveryType.filter(
                            (type) => type !== "delivery"
                          )
                        );
                      } else {
                        formik.setFieldValue("deliveryType", [
                          ...formik.values.deliveryType,
                          "delivery",
                        ]);
                      }
                    }}
                  />
                }
                label="Delivery"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.deliveryType.includes("pickup")}
                    onChange={() => {
                      if (formik.values.deliveryType.includes("pickup")) {
                        formik.setFieldValue(
                          "deliveryType",
                          formik.values.deliveryType.filter(
                            (type) => type !== "pickup"
                          )
                        );
                      } else {
                        formik.setFieldValue("deliveryType", [
                          ...formik.values.deliveryType,
                          "pickup",
                        ]);
                      }
                    }}
                  />
                }
                label="Pickup"
              />
              {/* {formik.touched.deliveryType && formik.errors.deliveryType ? (
                <div></div>
              ) : null} */}
            </Grid>
            {sameDay && (
              <Grid item xs={12}>
                <SameDayDelivery onDeliveryChange={handleDeliveryChange} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Enter the Minimum order amount{" "}
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                // required
                fullWidth
                name="minAmount"
                value={formik.values.minAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.minAmount && Boolean(formik.errors.minAmount)
                }
                helperText={
                  formik.touched.minAmount && formik.errors.minimumOrder
                }
              />
              {formik.touched.minAmount && formik.errors.minAmount ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.minAmount}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Enter Preparation Time{" "}
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                name="preparationTime"
                value={formik.values.preparationTime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.preparationTime &&
                  Boolean(formik.errors.preparationTime)
                }
                // helperText={formik.touched.preparationTime && formik.errors.preparationTime}
              />
              {formik.touched.preparationTime &&
              formik.errors.preparationTime ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.preparationTime}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Opening Time <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={formik.values.openingTime}
                  onChange={(newValue) =>
                    formik.setFieldValue("openingTime", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                  error={
                    formik.touched.openingTime &&
                    Boolean(formik.errors.openingTime)
                  }
                />
              </LocalizationProvider>
              {formik.touched.openingTime && formik.errors.openingTime ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.openingTime}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Closing Time <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={formik.values.closingTime}
                  onChange={(newValue) =>
                    formik.setFieldValue("closingTime", newValue)
                  }
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: "100%" }} />
                  )}
                  error={
                    formik.touched.openingTime &&
                    Boolean(formik.errors.openingTime)
                  }
                />
              </LocalizationProvider>
              {formik.touched.closingTime && formik.errors.closingTime ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.closingTime}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Enter Packing Charges{" "}
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                name="packingCharge"
                value={formik.values.packingCharge}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.packingCharge &&
                  Boolean(formik.errors.packingCharge)
                }
                // helperText={formik.touched.packingCharge && formik.errors.packingCharge}
              />
              {formik.touched.packingCharge && formik.errors.packingCharge ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.packingCharge}
                </div>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                sx={{ borderRadius: "10px", background: "white" }}
                size="medium"
              >
                <Typography className="StoreLabelStyles" fontSize={16}>
                  Delivery Details(Maximum distance for delivery){" "}
                  <span style={{ color: "#EA4335" }}>*</span>
                </Typography>
                <Select
                  name="deliveryMaxDistance"
                  value={formik.values.deliveryMaxDistance}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setDistance(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.deliveryMaxDistance &&
                    Boolean(formik.errors.deliveryMaxDistance)
                  }
                  helperText={
                    formik.touched.deliveryMaxDistance &&
                    formik.errors.deliveryMaxDistance
                  }
                >
                  {distanceTable.options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option} Km
                    </MenuItem>
                  ))}
                </Select>
                {/* {formik.touched.deliveryMaxDistance &&
                formik.errors.deliveryMaxDistance ? (
                  <div style={{color:"red",fontSize:"12px"}}>{formik.errors.deliveryMaxDistance}</div>
                ) : null} */}
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <DeliveryCharge
                formik={formik}
                distance={distance}
                deliveryCharges={deliveryCharges}
                setDeliveryCharges={setDeliveryCharges}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Choose Holidays <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <Holidays
                selectedHolidays={selectedHolidays}
                setSelectedHolidays={setSelectedHolidays}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Choose Delivery Schedule{" "}
                <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <div style={{ width: "100%" }}>
                <div className="scrollContainer">
                  <div className="SliderHeaderflex">
                    {daysOfWeek.map((day) => renderDaySchedule(day))}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            sx={{ marginTop: 2, paddingRight: "30px" }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "20px", background: "#85BE49" }}
            >
              Next
            </Button>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default Registration;
