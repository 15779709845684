import React, { useState,useEffect } from "react";
import "./Business.css";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card";
import Arrow from "../../utils/Icon/Arrow.svg";
import Bevarages from "../../utils/Icon/Bevarages.svg";
import Nonveg from "../../utils/Icon/nonveg.svg";
import Veg from "../../utils/Icon/veg.svg";
import Subcategory from "./Subcategory";
import {
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  contentFontSize_18,
} from "../Typography";
import { useNavigate } from "react-router-dom";
import { dummyData } from "../Data/Dummydata";


function Business(props) {
  const [subCategory, setSubCategory] = useState(false);
  const nav = useNavigate()



  const [checkProducts, setCheckProducts] = useState([]);
  const [categoryName, setCategoryName] = useState(null);
  const [categoryCount, setCategoryCount] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [nextArrow, setNextArrow] = useState(false);

  useEffect(() => {
    document.body.scrollTop = 0;
    props.getMasterCategory(localStorage.getItem('CatalogId'));
    
    props.setCatalogProducts([]);
}, []);

useEffect(() => {
  if (props.MasterProducts.length !== 0) {
     
      setProducts((prevProducts) => [
          ...prevProducts,
          ...(props.MasterProducts.data.masterCategoryProducts || []).map((p) => p)
      ]);
  }
}, [props.MasterProducts]); 

useEffect(() => {
  props.setCatalogProducts(products);
}, [products]);

  useEffect(
        () => {
          
          props.getMasterDepartment(localStorage.getItem("CatalogBusinesId"));
        }, 
        []
      );

      const CatalogDepartmentClick = (name, id) => {
            localStorage.setItem("CatalogDepartment", name);
            localStorage.setItem("CatalogId", id);
            
          };


  function handleBack() {
    nav("/home/CategoryBuilder")

  }
  function handleClickSub() {
    setSubCategory(true);
    nav("/home/CategoryBuilder/Products")
  }
  function getTotalProductsInCategory(category) {
    let totalProducts = 0;
    category.subcategories.forEach((subcategory) => {
      totalProducts += subcategory.products.length;
    });
    return totalProducts;
  }

  const [selectedCategory, setSelectedCategory] = useState([]);


  

  return (
    <>
      {subCategory ? (
        <Subcategory/>
      ) : (
        <div>
          <div className="Business-sub">
            <div style={{ cursor: "pointer" }} onClick={handleBack}>
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                Business Type
              </Typography>
            </div>
            <div>
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
            </div>
            <div>
              <Typography
                className="main"
                style={{ color: "#85BE49" }}
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                Category
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              className="Businesstype"
              fontSize={HeaderNavigationFontSize_20}
            >
              Choose your Category
            </Typography>
          </div>
            <>
              <div style={{ paddingRight: "30px" }}>
             
              {props.MasterDepartments &&
                props.MasterDepartments.data &&
                props.MasterDepartments.data.departmentWiseListing &&
                props.MasterDepartments.data.departmentWiseListing.map((m) => (
                  <>
                    <div style={{ paddingTop: "29px" }}>
                      <CustomCard padding={"20px"}  onClick={() => {
                        CatalogDepartmentClick(m.name, m.id)}} >
                        <Box sx={{ flexGrow: 1 }}>
                          <Grid container spacing={2}>
                            <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                              <CustomCard
                                padding="2px"
                                // width={"70px"}
                                height={"70px"}
                                background="#F2F2F2"
                                boxShadow="none"
                                borderRadius="10px"
                                // width={matches ? "60px" : "70px"}
                                sx={{ alignItems: "center" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    //   padding:"6px"
                                  }}
                                >
                                  <img
                                    src={Bevarages}
                                    alt="Bevarages"
                                    // style={{ width: matches ? "50px" : "80%" }}
                                  />
                                </div>
                              </CustomCard>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                              <div
                                style={{
                                  display: "flex",
                                  // justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  paddingLeft: "20px",
                                  boxSizing: "border-box",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "8px",
                                  }}
                                >
                                  <Typography
                                    className="Bevarages"
                                    fontSize={contentFontSize_18}
                                  >
                                     {`${m.name}`}
                                  </Typography>
                                  <Typography
                                    className="product"
                                    fontSize={contentFontSize_16}
                                  >
                                   {`choose from ${m.departmentProductsCount} products`}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                  height: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "15px" }}>
                                    <div onClick={handleClickSub}>
                                      <img src={Arrow} alt="arrowicon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </CustomCard>
                    </div>
                  </>
                 ))} 
              </div> 
            </>
        </div>
      )}
    </>
  );
}

export default Business;
