// import React, { useState } from "react";
// import CustomTextField from "../Commoncomponents/input/index";
// import IndianFlag from "../../utils/images/indiaImage.svg";
// import CustomButton from "../Commoncomponents/button/index";
// import { useFormik } from "formik";
// import OTPInput from "react-otp-input";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// const ShopAssistantLogin = (props) => {
//   console.log("props from shpoassistantlogin", props);
//   const [otpPopUp, steOtpPopUp] = useState(false);
//   const [counter, setCounter] = useState(0);
//   const formik = useFormik({
//     initialValues: {
//       mobileNumber: "",

//     },
//     validationSchema: Yup.object({
//       mobileNumber: Yup.string()
//         .required("Mobile number is required")
//         .matches(/^[0-9]{10}$/, "Invalid mobile number"),

//     }),
//     onSubmit: (values) => {
//       console.log("this is value from onsubmit", values);
//       steOtpPopUp(true);
//       props.sendOTP(values.mobileNumber);
//     },
//   });

//   const formik = useFormik({
//     initialValues: {
//       mobileNumber: "",
//       otp: ""
//     },
//     validationSchema: Yup.object({
//       mobileNumber: Yup.string()
//         .required("Mobile number is required")
//         .matches(/^[0-9]{10}$/, "Invalid mobile number"),
//         otp: Yup.string().required("OTP is required").length(4, "OTP must be 4 digits")
//     }),
//     onSubmit: (values) => {
//       console.log("this is value from onsubmit for otp", values);
//           },
//   });

//   const navigate = useNavigate();
//   const handleVerifyClick = () => {
//     formik.resetForm();
//   };
//   const resend = () => {
//     props.sendOTP(formik.values.mobileNumber);
//   };

//   console.log(formik);
//   return (
//     <>
//       <form onSubmit={formik.handleSubmit}>
//         {otpPopUp ? (
//           <>
//             <div
//               style={{
//                 padding: "25px 0 22px 0",
//               }}
//             >
//               <h5 className="signin-heading">{"Verify OTP"}</h5>
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   flexDirection: "column",
//                   gap: "20px",
//                   paddingTop: "16px",
//                 }}
//               >
//                 <div className="signin-subheading">
//                   {`An OTP has been sent to  +91  ${formik.values.mobileNumber}`}
//                 </div>

//                 <label className="textfield-label">{"Enter 4 digit OTP"}</label>
//                 <OTPInput
//                   inputStyle={{
//                     width: "55px",
//                     height: "55px",
//                     padding: "8px",
//                     margin: "0px 10px 0 10px",
//                     border: "1px solid #B9B9B9",
//                     borderRadius: " 12px",
//                     padding: "0px, 30px, 0px, 30px",
//                     outline: "none",
//                   }}
//                   onChange={(otpValue) => {
//                     const numericValue = otpValue.replace(/\D/g, "");
//                     console.log("numericvalue",numericValue)
//                     formik.setFieldValue("otp", numericValue);
//                   }}

//                   value={formik.values.otp}
//                   autoFocus
//                   OTPLength={4}
//                   inputMode="numeric"
//                   renderInput={(props) => <input {...props} />}
//                 />

//                 <div
//                   style={{
//                     width: "100%",
//                     display: "flex",
//                     justifyContent: "right",
//                     alignItems: "right",
//                   }}
//                 >
//                   <button
//                   type="submit"
//                     onClick={handleVerifyClick}
//                     className="signin-btn"
//                     width="100%"
//                   >
//                     Verify
//                   </button>
//                 </div>

//                 <span
//                   style={{
//                     color: "rgba(49, 49, 49, 1)",
//                     fontSize: "14px",
//                     fontWeight: 400,
//                   }}
//                 >
//                   Didn't receive OTP?&nbsp;
//                   {counter > 0 ? (
//                     <span> 00:{counter}</span>
//                   ) : (
//                     <button
//                       type="button"
//                       style={{
//                         cursor: "pointer",
//                         fontSize: "14px",
//                         fontWeight: 400,
//                         color: "rgba(43, 110, 246, 1)",
//                         background: "none",
//                         borderWidth: "initial",
//                         borderStyle: "none",
//                         borderColor: "initial",
//                         borderImage: "initial",
//                         marginTop: "1px",
//                         fontFamily: "Varela Round, sans-serif",
//                       }}
//                       onClick={() => {
//                         resend();
//                         setCounter(59);
//                       }}
//                     >
//                       {"Resend OTP"}
//                     </button>
//                   )}
//                 </span>
//               </div>
//             </div>
//           </>
//         ) : (
//           <>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "12px",
//               }}
//             >
//               <div
//                 style={{
//                   fontFamily: "Roboto",
//                   fontSize: "16px",
//                   fontWeight: 600,
//                   lineHeight: "19px",
//                   letterSpacing: "0em",
//                   textAlign: "left",
//                 }}
//               >
//                 <p
//                   style={{
//                     marginBottom: "0px",
//                   }}
//                 >
//                   Mobile No<san>*</san>
//                 </p>
//               </div>
//               <CustomTextField
//                 width={"100%"}
//                 startAdornment={
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       paddingRight: "8px",
//                     }}
//                   >
//                     <img src={IndianFlag} alt="Indian Flag" />
//                     <span
//                       style={{
//                         marginLeft: "8px",
//                         borderRight: "1px solid #C1C6C6",
//                         paddingRight: "8px",
//                       }}
//                     >
//                       +91
//                     </span>
//                   </div>
//                 }
//                 value={formik.values.mobileNumber}
//                 onChange={(e) => {
//                   formik.setFieldValue("mobileNumber", e.target.value);
//                 }}
//                 onBlur={formik.handleBlur}
//                 name="mobileNumber"
//                 error={
//                   formik.touched.mobileNumber &&
//                   Boolean(formik.errors.mobileNumber)
//                 }
//               />
//               <label
//                 style={{
//                   color: "red",
//                 }}
//               >
//                 {formik.errors.mobileNumber}
//               </label>
//               <labe
//                 style={{
//                   fontFamily: "Roboto",
//                   fontSize: "16px",
//                   fontWeight: 500,
//                   lineHeight: "19px",
//                   letterSpacing: "0em",
//                   textAlign: "left",
//                   color: "#CACACA",
//                 }}
//               >
//                 Enter a valid mobile number
//               </labe>
//             </div>
//             <div
//               style={{
//                 padding: "18px 0 18px 0",
//                 boxSizing: "border-box",
//               }}
//             >
//               <CustomButton width={"100%"} type="submit">
//                 Continue
//               </CustomButton>
//             </div>
//           </>
//         )}
//       </form>
//     </>
//   );
// };
// export default ShopAssistantLogin;

//new code
import React, { useEffect, useState } from "react";
// import CustomTextField from "../Commoncomponents/input/index";
import { TextField } from "../MUIComponents/index";
import indiaImage from "../../utils/images/indiaImage.svg";
import CustomButton from "../Commoncomponents/button/index";
import { useFormik } from "formik";
import OTPInput from "react-otp-input";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import { message } from "antd";
import { useDispatch } from "react-redux";

const ShopAssistantLogin = (props) => {
  const [otpPopUp, setOtpPopUp] = useState(false);
  const [counterStart,setCounterStart] = useState(false)
  const [counter, setCounter] = useState(59);
  const [mobileNumber, setMobileNumber] = useState(""); // Store mobile number separately
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SHOPASSISTANT_CLEAR",
    });
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    if (
      props.ShopAssistantisError &&
      props.ShopAssistanterrorMessage &&
      props.ShopAssistanterrorMessage.length > 0
    ) {
      const errorMessage = props.ShopAssistanterrorMessage[0].message;
     
      message.error({
        content: errorMessage,
        className: "custom-class",
        style: {
          marginTop: "20vh",
          // marginLeft: "4vw",
        },
        duration: 3,
      });
    }

    // }, 1000);
  }, [props.ShopAssistanterrorMessage]);

  const handleback =() =>{
    setOtpPopUp(false)
  }

  const nav = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const mobileNumberFormik = useFormik({
    initialValues: {
      mobileNumber: "",
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .matches(/^\S*$/, "Space is not allowed")
        //eslint-disable-next-line
        .matches(
          /^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/,
          "Invalid mobile number"
        )
        .min(10, "Please enter 10 digit Mobile number")
        .max(10, "Please enter 10 digit Mobile number")
        .test(
          "not-zero",
          "Value cannot be zero",
          (value) => parseFloat(value) !== 0
        ),
    }),
    onSubmit: (values) => {
      setCounterStart(true)
      setMobileNumber(values.mobileNumber); // Store mobile number when form is submitted
      setOtpPopUp(true);
      props.sendOTP(values.mobileNumber);
    },
  });

  const otpFormik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required")
        .length(4, "OTP must be 4 digits"),
    }),
    onSubmit: ({ otp }) => {
     
      props.shopAssistantSignInClick(mobileNumber, otp);
      
    },
  });

  const handleVerifyClick = () => {
    otpFormik.handleSubmit();
    nav("/home/MyOrders");
  };
  useEffect(() => {
    let timer;
    if (counter > 0 && counterStart) {
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else if (counter === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [counter,counterStart]);

  const resend = () => {
    props.sendOTP(mobileNumber);
    setCounter(59);
  };
  const OpenLoginSignIn =() =>{
    props.setshopAssistantLogin(false)
    props.setLoginForm((prev) => !prev);
  }

 

  return (
    <form onSubmit={mobileNumberFormik.handleSubmit}>
      {otpPopUp ? (
        <>
          <div
            style={{
              padding: "25px 0 22px 0",
            }}
          >
            <h5 className="signin-heading">{"Verify OTP"}</h5>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "20px",
                paddingTop: "16px",
              }}
            >
              <div className="signin-subheading">{`An OTP has been sent to +91 ${mobileNumber}`}</div>

              <label className="textfield-label">{"Enter 4 digit OTP"}</label>
              <OTPInput
                inputStyle={{
                  width: "55px",
                  height: "55px",
                  padding: "8px",
                  margin: "0px 10px 0 10px",
                  border: "1px solid #B9B9B9",
                  borderRadius: " 12px",
                  padding: "0px, 30px, 0px, 30px",
                  outline: "none",
                }}
                onChange={(otpValue) => {
                  const numericValue = otpValue.replace(/\D/g, "");
                  otpFormik.setFieldValue("otp", numericValue);
                }}
                value={otpFormik.values.otp}
                autoFocus
                OTPLength={4}
                inputMode="numeric"
                renderInput={(props) => <input {...props} />}
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
                }}
              >
                <button
                  type="button"
                  onClick={handleVerifyClick}
                  className="signin-btn"
                  width="100%"
                >
                  Verify
                </button>
              </div>

              <span
                style={{
                  color: "rgba(49, 49, 49, 1)",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                Didn't receive OTP?&nbsp;
                {counter > 0 ? (
                  <span>00:{counter < 10 ? `0${counter}` : counter}</span>
                ) : (
                  <button
                    type="button"
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 400,
                      color: "rgba(43, 110, 246, 1)",
                      background: "none",
                      borderWidth: "initial",
                      borderStyle: "none",
                      borderColor: "initial",
                      borderImage: "initial",
                      marginTop: "1px",
                      fontFamily: "Varela Round, sans-serif",
                    }}
                    onClick={() => {
                      resend();
                      setCounter(59);
                    }}
                  >
                    {"Resend OTP"}
                  </button>
                )}
              </span>
            </div>
            <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
            onClick={handleback}
          >
            <p className="shop-assistant-login-text">Go back</p>
          </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <div
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "19px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  marginBottom: "0px",
                }}
              >
                Mobile No <span style={{ color: "red" }}>*</span>
              </p>
            </div>
            <TextField
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#85BE49", 
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#85BE49",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: "10px", 
                },
              }}
              InputProps={{
                sx: {
                  fontSize: matches && "13px",
                  background: "white",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={indiaImage}
                      alt="India flag"
                      style={{ marginRight: "8px" }}
                    />
                    <div style={{ color: "black" }}>+91 |</div>
                  </InputAdornment>
                ),
              }}
              name="mobileNumber"
              value={mobileNumberFormik.values.mobileNumber}
              onChange={mobileNumberFormik.handleChange}
              error={
                mobileNumberFormik.touched.mobileNumber &&
                Boolean(mobileNumberFormik.errors.mobileNumber)
              }
              helperText={
                mobileNumberFormik.touched.mobileNumber &&
                mobileNumberFormik.errors.mobileNumber
              }
            />
            {/* <label
              style={{
                color: "red",
              }}
            >
              {mobileNumberFormik.errors.mobileNumber}
            </label> */}
            <label
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "19px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#CACACA",
              }}
            >
              Enter a valid mobile number
            </label>
          </div>
          <div
            style={{
              padding: "18px 0 18px 0",
              boxSizing: "border-box",
            }}
          >
            <CustomButton width={"100%"} type="submit">
              Continue
            </CustomButton>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
            onClick={OpenLoginSignIn}
          >
            <p className="shop-assistant-login-text">Login as Seller</p>
          </div>
        </>
      )}
    </form>
  );
};

export default ShopAssistantLogin;
