import React, { useEffect, useState } from "react";
import CustomCard from "../Commoncomponents/card/index";
import { Divider, Typography } from "../MUIComponents/index";
import CustomTextField from "../Commoncomponents/input/index";
import CustomButton from "../Commoncomponents/button/index";
import { HeaderNavigationFontSize_20 } from "../Typography/index";
import { Grid } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { contentFontSize_16 } from "../Typography/index";
import { ButtonFontSize_16 } from "../Typography/index";
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EyeClose from '../../utils/images/EyeClose.svg'
import EyeOpen from '../../utils/images/EyeOpen.svg'
import IconButton from '@mui/material/IconButton';
import {InputAdornment} from "../MUIComponents/index";
import { TextField } from "../MUIComponents/index";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import './ChangePassword.css';
import {Alert,Stack} from "../MUIComponents/index";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
	password:Yup.string()
	// .trim('space not allowed')
   .test('no-leading-space', 'Password should not start with space', value => {
     if (value) {
       return !value.startsWith(' ');
     }
     return true;
   }).matches(/^\S*$/,'Space is not allowed')
    .min(8, 'Password is too short - should be 8 characters minimum')
    //eslint-disable-next-line
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,'Password must contain one number uppercase and lowercase letters and special character')
	  .required('Password is required'),
	  newPassword:Yup.string()
	  // .trim('space not allowed')
    .test('no-leading-space', 'Password should not start with space', value => {
      if (value) {
        return !value.startsWith(' ');
      }
      return true;
    })
    .matches(/^\S*$/,'Space is not allowed')
    .min(8, 'Password is too short - should be 8 characters minimum')
    //eslint-disable-next-line
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,'Password must contain one number uppercase and lowercase letters and special character')
  .required('Password is required')
,
  confirmNewPassword:Yup.string()
  // .trim('space not allowed')
  .test('no-leading-space', 'Password should not start with space', value => {
    if (value) {
      return !value.startsWith(' ');
    }
    return true;
  })
  .matches(/^\S*$/,'Space is not allowed')
  .min(8, 'Password is too short - should be 8 characters minimum')
  //eslint-disable-next-line
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,'Password must contain one number uppercase and lowercase letters and special character')
  .required('Password confirmation is required!')
  .when("newPassword", {
	is: (val) => (val && val.length > 0 ? true : false),
	then:()=> Yup.string().oneOf(
	  [Yup.ref("newPassword")],
	  "Both password need to be the same",
	  ),
	})
  });

export default function ChangePassword(props) {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitted,setFormSubmitted]=useState(false)
  
  const dispatch = useDispatch(); 
  const nav=useNavigate();

  useEffect(()=>{
    dispatch({
      type: "CLEAR_RESET_FAILED"
    });
  },[])

  
  useEffect(() => {
    if (props.passwordResetFailed&&showAlert) {
    	message.error({
        content: props.passErrMsg,
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        });}else if (props.passwordResetSuccess&&showAlert){
          setFormSubmitted(true)
          message.success({
            content: 'Password Changed Successfully',
            className: "custom-class",
            style: {
              marginTop: "10vh",
              marginLeft: "15vw",
            },
            duration:3.5,
            });
            setTimeout(() => {
              nav('/home/Profile')
            }, 4000);
        }
  }, [showAlert,props.passwordResetFailed, props.passErrMsg,props.passwordResetSuccess]);



  const theme = useTheme();
  const smmatch = useMediaQuery(theme.breakpoints.down("md"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("sm"));

  
  const handleClickShowCurrentPassword = () =>
  setShowCurrentPassword((show) => !show);
const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
const handleClickShowConfirmPassword = () =>
  setShowConfirmPassword((show) => !show);


  	// let { id } = props&&props?.retailerDetails&&props?.retailerDetails?.data&&props?.retailerDetails?.data?.retailerdetails;
    let id = props?.retailerDetails?.data?.retailerdetails?.id;
  
  
    const formik = useFormik({
		initialValues: {
		  password: "",
		  newPassword: "",
		  confirmNewPassword: "",
		},
		validationSchema: validationSchema,
    
    onReset: (values) => {
      formik.setValues({
      password: "",
		  newPassword: "",
		  confirmNewPassword: "",
      });
    },
		onSubmit: (values, { resetForm }) => {
      if(values.password===values.newPassword)
      {
        formik.setFieldError('newPassword','new password must be different from old password')
        formik.setFieldError('confirmNewPassword','new password must be different from old password')
      }
      else{
        dispatch({
          type: "CLEAR_RESET_FAILED"
        });
        setShowAlert(true)
		  props.resetPassword({
			retailerid: id,
			password: values.password,
			newPassword: values.newPassword,
		});
   
    
    setTimeout(() => {
      resetForm();
    }, 5000);
  }
		},
 
	  });

    useEffect(() => {
      if (!formik.isSubmitting) return;
      if (Object.keys(formik.errors).length > 0) {
        document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
      }
    }, [formik]);

  return (
    <div style={{height:"100%"}}>
    
     <form onSubmit={formik.handleSubmit} style={{display:"flex",alignItems:"center",justifyContent:'center',height:"100%",marginTop:"50px"}}>
      <div style={{ background: "#F4F5F9" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
           height: "80%",
          }}
        >
          <CustomCard
            // height={smmatch ? (xsmatch ? "410px" : "350px") : "400px"}
            height="100%"            
            width={smmatch ? (xsmatch ? "295px" : "400px") : "454px"}
          
            padding="40px 20px"
            // boxShadow="none"
          >
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "600",
                padding: "0px 10px 15px 10px",
                boxSizing: "border-box",
              }}
              fontSize={HeaderNavigationFontSize_20}
            >
              Change your password
            </Typography>
            <div style={{ paddingBottom: "10px" }}>
              <Divider />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <Grid container spacing={2}>
                <div style={{display:"flex",flexDirection:"column",gap:"1px",width:"100%"}}>
                  <div >
                    <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{paddingLeft:"0px"}}>
                  <Typography
                    fontSize={contentFontSize_16}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    Password
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8} style={{paddingLeft:xsmatch&&"0px"}}>
                  <div>
                    <TextField
                   
                      placeholder="Enter current password"
                      label=""
                      variant="outlined"
                      sx={{
                        width: '100%',
                    
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'black', 
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'black', 
                          },
                        },
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          borderRadius:"10px" 
                        },
                      }}
                      InputProps={{
                       sx: {
                         fontSize: smmatch && '13px', 
                       },
                       endAdornment:(
                        <InputAdornment position="end">
                          <div  onClick={handleClickShowCurrentPassword} style={{cursor:"pointer"}}
>
{showCurrentPassword ? <img src={EyeOpen} alt="Eye Open image" width={smmatch?"15px":"20px"}/> : <img src={EyeClose} alt="Eye Close image" width={smmatch?"15px":"20px"}/>}
                          </div>
                         
                        </InputAdornment>
                       )
                   }}
                       size={smmatch ? "small" : "normal"}
                      type={showCurrentPassword ? 'text' : 'password'}
                   
                     
                      name="password"
                 value={formik.values.password}
                  onChange={formik.handleChange}
                   error={
                    formik.touched.password && Boolean(formik.errors.password)
                   }
                  helperText={formik.touched.password && formik.errors.password}
                      hiddenLabel={true}
                     
                    
                    />
                  </div>
                  </Grid>
                </Grid>
                </div>
                <div>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{paddingLeft:"0px"}}>
                  <Typography
                    fontSize={contentFontSize_16}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    New Password
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}  style={{paddingLeft:xsmatch&&"0px"}}>
                  <div>
                  <TextField
                   
                   placeholder="Enter new password"
                   label=""
                   variant="outlined"
                   sx={{
                    width: '100%',
                
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black', 
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black', 
                      },
                    },
                    '& .MuiInputBase-input': {
                      backgroundColor: 'white',
                      borderRadius:"10px" 
                    },
                  }}
                  InputProps={{
                   sx: {
                     fontSize: smmatch && '13px', 
                   },
                   endAdornment:(
                    <InputAdornment position="end">
                    <div  onClick={handleClickShowNewPassword} style={{cursor:"pointer"}}
>
{showNewPassword ?<img src={EyeOpen} alt="Eye Open image" width={smmatch?"15px":"20px"}/> : <img src={EyeClose} alt="Eye Close image" width={smmatch?"15px":"20px"}/>}
                    </div>
                   
                  </InputAdornment>
                   )
               }}
                   size={smmatch ? "small" : "normal"}
                  
                   type={showNewPassword ? 'text' : 'password'}
                  
                   name="newPassword"
                   value={formik.values.newPassword}
                                       error={
                                         formik.touched.newPassword &&
                                        Boolean(formik.errors.newPassword)||
                                         ((formik.touched.confirmNewPassword &&
                                           formik.errors.confirmNewPassword==="Both password need to be the same")?true:false)
                                       }
                                       helperText={
                                         (formik.touched.newPassword && formik.errors.newPassword)||((formik.touched.confirmNewPassword &&
                                          formik.errors.confirmNewPassword==="Both password need to be the same")?formik.errors.confirmNewPassword:null)
                                       }
                   onChange={formik.handleChange}
                   hiddenLabel={true}
                   
                 />
                  </div>
                  </Grid>
                </Grid>
                </div>
                <div>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{paddingLeft:"0px"}}>
                  <Typography
                    fontSize={contentFontSize_16}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    Confirm Password
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}  style={{paddingLeft:xsmatch&&"0px"}}>
                  <div>
                  <TextField
                   
                   placeholder="Confirm new password"
                   label=""
                   variant="outlined"
                   sx={{
                    width: '100%',
                
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black', 
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black', 
                      },
                    },
                    '& .MuiInputBase-input': {
                      backgroundColor: 'white',
                      borderRadius:"10px" 
                    },
                  }}
                  InputProps={{
                   sx: {
                     fontSize: smmatch && '13px', 
                   },
                   endAdornment:(
                    <InputAdornment position="end">
                    <div  onClick={handleClickShowConfirmPassword} style={{cursor:"pointer"}}
>
{showConfirmPassword ? <img src={EyeOpen} alt="Eye Open image" width={smmatch?"15px":"20px"}/> : <img src={EyeClose} alt="Eye Close image" width={smmatch?"15px":"20px"}/>}
                    </div>
                   
                  </InputAdornment>
                   )
               }}
                   size={smmatch ? "small" : "normal"}
                   type={showConfirmPassword ? 'text' : 'password'}
                  
                  
                   name="confirmNewPassword"
                   value={formik.values.confirmNewPassword}
                                       error={
                                         formik.touched.confirmNewPassword &&
                                         Boolean(formik.errors.confirmNewPassword)
                                       }
                                       helperText={
                                         formik.touched.confirmNewPassword &&
                                        formik.errors.confirmNewPassword
                                       }
                                       onChange={formik.handleChange}
                   hiddenLabel={true}
                  
                 />
                  </div>
                  </Grid>
                </Grid>
                </div>
                </div>
              </Grid>

              <div style={{ textAlign: "center" }}>
                <CustomButton width="100%" size="large" type="submit" borderRadius="11px" matchPadding={true} padding={smmatch?"10px":"15px"}
                 background={formSubmitted?"gray":'#85BE49'}
                 disabled={formSubmitted&&"disabled"}>
                  <Typography
                    style={{ fontFamily: "Poppins", fontWeight: "600" }}
                    fontSize={ButtonFontSize_16}
                  >
                    Set Password
                  </Typography>
                </CustomButton>
              </div>
            </div>
          </CustomCard>
        </div>
      </div>
      </form>
      
    </div>
  );
}
