import React, { Component } from "react";
import { Container } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { Dep1, Dep2, Dep3 } from "./Constants";
import "./index.css";

export class index extends Component {
  render() {
    return (
      <Container
        maxWidth="md"
        style={{
          marginTop: "35px",
          borderRadius: "10px",
          paddingTop: "20px",
        }}
      >
        <div className="whoTo1">
          <p className="whoTo2"> Who Can Register ? </p>
          <div className="whoTo3">
            Currently , We are accepting registrations from a diverse range of
            local stores .
          </div>
        </div>
        <Row style={{ paddingTop: "20px" }}>
          {Dep1.map((d, index) => (
            <Col md={2} xs={12} key={index}>
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="img-col"
                  width="250px"
                  height="100px"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={d.image}
                  alt="dep.jpg"
                />
              </div>
              <div className="heading-col">{`${d.heading}`}</div>
            </Col>
          ))}
        </Row>
        <Row style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          {Dep2.map((d, index) => (
            <Col sm={12} md={2} xs={12} key={index}>
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="img-col"
                  width="250px"
                  height="100px"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={d.image}
                  alt="dep.jpg"
                />
              </div>
              <div className="heading-col">{`${d.heading}`}</div>
            </Col>
          ))}
        </Row>
        <Row style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          {Dep3.map((d, index) => (
            <Col md={2} xs={12} key={index}>
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className="img-col"
                  width="250px"
                  height="100px"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={d.image}
                  alt="dep.jpg"
                />
              </div>
              <div className="heading-col">{`${d.heading}`}</div>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default index;
