import React, { useState } from "react";
import { Grid, Typography,useMediaQuery,useTheme } from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import Arrow from "../../utils/Icon/Arrow.svg";
import Flat from "../../utils/Icon/flat.svg";
import Buy from "../../utils/Icon/Buy.svg";
import Free_Delivery from '../../utils/Icon/Free_Delivery.svg';
import Percentage from "../../utils/Icon/percentage.svg";
import CustomCard from "../Commoncomponents/card";
import "./Coupontype.css";
import Couponsubtype from "./Couponsubtype";
import { useNavigate } from "react-router-dom";
import {contentFontSize_18_xs_13,contentFontSize_16_xs_10,HeaderNavigationFontSize_20,HeaderNavigationFontSize_20_xs_13} from '../Typography/index';
import { useDispatch } from "react-redux";



function Coupontype({setCoupounTypePage,setButtonSlotsColor}) {
  const nav = useNavigate();
  const dispatch = useDispatch(); 
  const [couponSub, setCouponSub] = useState(false);
  
  function handleBack() {
    nav("/home/Coupons")
  }
  function percentageDiscount() {
    dispatch({
      type: "CLEAR_PERCENTAGE_URL"
    });
    nav("/home/Coupons/Percentagediscount")
  }

   function handlleFlatDiscount(){
    dispatch({
      type: "CLEAR_FLAT_URL"
    });

    nav("/home/Coupons/Flatdiscount")
   }
 
   function handleBuy(){
    dispatch({
      type: "CLEAR_BUYXGETY_URL"
    });
    dispatch({
      type:"CLEAR_BUYARRAY"
    });
    nav("/home/Coupons/BuyXGetYfree")
   }
   
    function handleFreeDelivery(){            
      dispatch({
        type: "CLEAR_FREE_URL"
      });
      nav("/home/Coupons/FreeDelivery")
    }

    const theme = useTheme();
    const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
    const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
    const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
    const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
    const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
    const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    
        <>
          <div>
            <div className="Coupon-sub">
              <div style={{ cursor: "pointer" }} onClick={handleBack}>
                <Typography className="main" fontSize={HeaderNavigationFontSize_20_xs_13}>Coupon</Typography>
              </div>
              <div>
                <Typography className="main" fontSize={HeaderNavigationFontSize_20_xs_13}>/</Typography>
              </div>
              <div>
                <Typography className="main" style={{ color: "#85BE49" }} fontSize={HeaderNavigationFontSize_20_xs_13}>
                  Coupon Type
                </Typography>
              </div>
            </div>
            <div>
              <Typography className="Couponstype" fontSize={HeaderNavigationFontSize_20}>
                Select Coupon Type
              </Typography>
            </div>
          </div>
          <div style={{ display:"flex",flexDirection:"column",gap:"20px",marginTop:"20px"}}>




            <div>
            <Grid onClick={percentageDiscount} style={{cursor:"pointer"}}>
              <CustomCard padding={"20px"}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                      <CustomCard
                        padding="2px"
                        width={mdmatch?"50px":"70px"}
                        height={mdmatch?"50px":"70px"}
                        background="#F2F2F2"
                        boxShadow="none"
                        borderRadius="10px"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height:"100%"
                          }}
                        >
                          <img src={Percentage} alt="Percentage" width={mdmatch&&"28px"} height={mdmatch&&"23px"}></img>
                        </div>
                      </CustomCard>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={9} md={8} lg={7} xl={7}>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          paddingLeft: "20px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            paddingTop: "10px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography className="Percentage" fontSize={contentFontSize_18_xs_13}>Percentage discount</Typography>
                          <Typography className="Coupon-percentage" fontSize={contentFontSize_16_xs_10}>
                            Offer a percentage discount to your customers.
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={2} lg={4} xl={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div style={{ display: "flex", gap: "15px" }}>
                            <div >
                              <img src={Arrow} alt="arrowicon" width={mdmatch&&"13px"} height={mdmatch&&"13px"}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </CustomCard>
            </Grid>
          </div>


          <div>
            <Grid onClick={handlleFlatDiscount} style={{cursor:"pointer"}}>
              <CustomCard padding={"20px"}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                      <CustomCard
                        padding="2px"
                        width={mdmatch?"50px":"70px"}
                        height={mdmatch?"50px":"70px"}
                        background="#F2F2F2"
                        boxShadow="none"
                        borderRadius="10px"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height:"100%"
                          }}
                        >
                          <img src={Flat} alt="Percentage"  width={mdmatch&&"28px"} height={mdmatch&&"23px"}></img>
                        </div>
                      </CustomCard>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={9} md={8} lg={7} xl={7}>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          paddingLeft: "20px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            paddingTop: "10px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography className="Percentage" fontSize={contentFontSize_18_xs_13}>Flat discount</Typography>
                          <Typography className="Coupon-percentage" fontSize={contentFontSize_16_xs_10}>
                            Offer a fixed discount to your customers.
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={2} lg={4} xl={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div style={{ display: "flex", gap: "15px" }}>
                            <div>
                              <img src={Arrow} alt="arrowicon" width={mdmatch&&"13px"} height={mdmatch&&"13px"}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </CustomCard>
            </Grid>
          </div>






          <div>
            <Grid onClick={handleBuy} style={{cursor:"pointer"}}>
              <CustomCard padding={"20px"}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                      <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                      <CustomCard
                        padding="2px"
                        width={mdmatch?"50px":"70px"}
                        height={mdmatch?"50px":"70px"}
                        background="#F2F2F2"
                        boxShadow="none"
                        borderRadius="10px"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height:"100%"
                          }}
                        >
                          <img src={Buy} alt="Buy"  width={mdmatch&&"28px"} height={mdmatch&&"23px"}></img>
                        </div>
                      </CustomCard>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={9} md={8} lg={7} xl={7}>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          paddingLeft: "20px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            paddingTop: "10px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography className="Percentage" fontSize={contentFontSize_18_xs_13}>Buy X Get Y Free</Typography>
                          <Typography className="Coupon-percentage" fontSize={contentFontSize_16_xs_10}>
                            Offer free products on purchase of certain number of
                            items
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={2} lg={4} xl={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div style={{ display: "flex", gap: "15px" }}>
                            <div>
                              <img src={Arrow} alt="arrowicon" width={mdmatch&&"13px"} height={mdmatch&&"13px"}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </CustomCard>
            </Grid>
          </div>




          <div>
            <Grid onClick={handleFreeDelivery} style={{cursor:"pointer"}}>
              <CustomCard padding={"20px"}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                       <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                      <CustomCard
                        padding="2px"
                        width={mdmatch?"50px":"70px"}
                        height={mdmatch?"50px":"70px"}
                        background="#F2F2F2"
                        boxShadow="none"
                        borderRadius="10px"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height:"100%"
                          }}
                        >
                          <img src={Free_Delivery} alt="Free_Delivery"  width={mdmatch&&"28px"} height={mdmatch&&"23px"}></img>
                        </div>
                      </CustomCard>
                      </div>
                    </Grid>
                    <Grid item xs={8} sm={9} md={8} lg={7} xl={7}>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          paddingLeft: "20px",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            paddingTop: "10px",
                            boxSizing: "border-box",
                          }}
                        >
                          <Typography className="Percentage" fontSize={contentFontSize_18_xs_13}>Free Delivery</Typography>
                          <Typography className="Coupon-percentage" fontSize={contentFontSize_16_xs_10}>
                          Offer free delivery to your customers
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={1} sm={1} md={2} lg={4} xl={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div style={{ display: "flex", gap: "15px" }}>
                            <div>
                              <img src={Arrow} alt="arrowicon"  width={mdmatch&&"13px"} height={mdmatch&&"13px"}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </CustomCard>
            </Grid>
          </div>
          </div>






        </>
  );
}

export default Coupontype;
