import React from 'react';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';

const CustomDrawer = ({ open, onClose, width, height, anchor, children, }) => {
  return (
    <Drawer anchor={anchor} open={open} onClose={onClose} sx={{
     
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: width,
        height: height,
        overflowX: 'hidden',
      },
    }}>
      <div style={{ padding: '16px'}}>
        {children}
      </div>
      
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  children: PropTypes.node.isRequired,
};

CustomDrawer.defaultProps = {
  width: '240px',
  height: '100%',
  anchor: 'left',
};

export default CustomDrawer;
