// import React from 'react';
// import { Container } from "@mui/material";
// import { Row, Col } from 'react-bootstrap';
// import Checkbox from "@mui/material/Checkbox";
// // import { withStyles } from "@mui/styles/makeStyles";
// import { NO_RESULT } from "../../utils/image";
// import './index.css';

// // const GreenCheckbox = withStyles({
// //     root: {
// //         color: 'rgb(137, 199, 74, 1)',
// //         "&$checked": {
// //             color: 'rgb(137, 199, 74, 1)'
// //         },
// //         "&$disabled": {
// //             color: 'rgb(137, 199, 74, 1)'
// //         }
// //     },
// //     checked: {},
// //     disabled: {}
// // })(Checkbox);

// // const CheckedCheckbox = withStyles({
// //     root: {
// //         color: 'rgb(153, 153, 153)',
// //         "&$checked": {
// //             color: 'rgb(153, 153, 153)'
// //         },
// //         "&$disabled": {
// //             color: 'rgb(153, 153, 153)'
// //         }
// //     },
// //     checked: {},
// //     disabled: {}
// // })(Checkbox);

// class Catalog extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             CheckProducts: [],
//             CategoryName: null,
//             CategoryCount: null,
//             CategoryId: null,
//             checkAll: false,
//             products: [],
//             first: 10,
//             offset: 0
//         };
//     }

//     componentDidMount() {
//         document.body.scrollTop = 0;
//         this.props.getMasterCategory(localStorage.getItem('CatalogId'));
//         console.log(" componentDidMount")
//         this.props.setCatalogProducts(this.state.products)

//     }

//     componentDidUpdate(prevProps) {
//         window.addEventListener('resize', this.updateIsMobile)
//         // Typical usage (don't forget to compare props):
//     //     if (this.props.addCatalogProductSuccess === true) {
//     //         this.setState({ CheckProducts: [] })
//     //         this.setState({ checkAll: false })
//     //     }
//     //    else
//         if (this.props.MasterProducts.length != 0&&this.props.MasterProducts!= prevProps.MasterProducts) {
//             console.log('if')
//             this.setState({
//                 products: [...this.state.products, this.props.MasterProducts
//                     && this.props.MasterProducts.data
//                     && this.props.MasterProducts.data.masterCategoryProducts.map((p) => (p))]
//             },
//                 () => {
//                     // const newArr = Array.from(new Set(this.state.products.map(JSON.stringify)), JSON.parse)
// 					this.props.setCatalogProducts(this.state.products)
//                     // this.props.setCatalogProducts(this.state.products)
//                 })
//         }
//     }

//     componentWillUnmount() {
//         window.removeEventListener('resize', this.updateIsMobile);
//     }
//     updateIsMobile = () => {
//         this.setState({
//             isMobile: window.innerWidth < 768
//         });
//     }
//     render() {

//         const ProductsCheck = (e, id) => {
//             let newArray = [...this.state.CheckProducts, id];
//             if (this.state.CheckProducts.includes(id)) {
//                 newArray = newArray.filter(product => product !== id);
//             }
//             this.setState({ CheckProducts: newArray });
//             this.props.MastercheckBox(newArray);
//         }

//         const SelectAll = (event, products) => {
//             console.log("this.state.products",this.state.products)
//             this.setState({ checkAll: event.target.checked });
//             let newArray = [];
//             if (event.target.checked === true) {
//                 this.state.products[0].map((p) =>
//                 p.isAdded===null?
//                 newArray = [...newArray, p.id]
//                 :
//                 newArray = [...newArray]
//                 )
//                 this.setState({ CheckProducts: newArray },
//                     ()=>{
//                         this.props.MastercheckBox(this.state.CheckProducts);
//                     });

//             }
//             else {
//                 this.setState({ CheckProducts: [] });
//                 this.props.MastercheckBox([])
//             }

//         }

//         const AddCatalogProduct = () => {
//             this.props.AddCatalogProducts({
//                 departmentName: localStorage.getItem('CatalogDepartment'),
//                 categoryName: this.state.CategoryName,
//                 storeid: localStorage.getItem('storeid'),
//                 productid: this.state.CheckProducts
//             })
//             this.setState({ CheckProducts: [] })
//             this.setState({ checkAll: false })

//         }

//         const LoadMore = () => {
//             this.setState({
//                 ...this.state,
//                 offset: this.state.offset + 10,
//             },
//                 () => {
//                     this.props.getMasterProducts({ id: this.state.CategoryId, offset: this.state.offset  });
//                 })
//             // setFirst(first + 10);

//         }

//         return (
//             <>
//                 <Container maxWidth='md'
//                     style={{
//                         position:"relative",
//                         right:"8px",
//                         backgroundColor: "white",
//                         border: "1px solid rgba(0, 0, 0, 0.16)",
//                         borderRadius: "10px",
//                         Top: "40px",
//                         padding: "30px",
//                         display: 'flex',
//                         flexDirection: 'column',
//                         flex: ' 1 1 0',
//                         width: "90%",
//                         fontFamily: "Varela Round, sans-serif",
//                     }}
//                 >
//                     {this.props.MasterCategory && this.props.MasterCategory.data &&
//                                 this.props.MasterCategory.data.masterCategories.length!==0&&
//                     <div style={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         width: '102%',
//                         padding: '12px',
//                         backgroundColor: '#fafafa',
//                         border: 'solid 1px #d9d9d9',
//                         borderRadius: '4px'
//                     }}>
//                         <span style={{
//                             color: '#4d4d4d', fontSize: '14px',
//                             lineHeight: '20px'
//                         }}>You can change the prices of the products later and set it according to your choice.</span>
//                     </div>
//     }
//                     <Row style={{ display: 'flex', justifyContent: 'center', aliigItems: 'center', paddingTop: '10px' }}>
//                         {this.props.MasterproductCheck.length !== 0 &&

//                             <button
//                                 style={{
//                                     justifyContent: " center",
//                                     color: "rgb(255, 255, 255)",
//                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                     boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//                                     height: "37px",
//                                     fontSize: "16px",
//                                     fontWeight: "600",
//                                     borderRadius: "5px",
//                                     borderWidth: "1px",
//                                     borderStyle: " solid",
//                                     borderColor: "transparent",
//                                     borderImage: "initial",
//                                     overflow: "hidden",
//                                     outline: "none",
//                                     minHeight: " 14px",
//                                     fontFamily: "Varela Round, sans-serif",
//                                 }}
//                                 onClick={AddCatalogProduct}
//                                 type="button">
//                                 Add {`${this.state.CheckProducts.length}`} Products
//                             </button>
//                         }
//                     </Row>
//                     {this.props.MasterCategory && this.props.MasterCategory.data &&
//                                 this.props.MasterCategory.data.masterCategories.length===0?
//                                (
//                                  <div

//                                  style={{
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',}}>
//                                              <p>{'No items to show'}</p>
//                                       </div>
// )
//                                 :
//                     <Row style={{
//                         borderRadius: '8px',
//                         boxShadow: '0 2px 6px #1a181e0a',
//                         backgroundColor: '#fff'
//                     }}>
//                         <Col style={{
//                             minWidth: '307px',
//                             width: '207px',
//                             height: '100%',
//                             paddingTop: '18px',
//                             // position: '-webkit-sticky',
//                             // position: 'sticky',
//                             top: 0
//                         }}>
//                             {this.props.MasterCategory && this.props.MasterCategory.data &&
//                                 this.props.MasterCategory.data.masterCategories.map((m) =>
//                                 <div>
//                                     <div
//                                     onClick= {() => {
//                                         // this.props.setCatalogProducts([])
//                                         this.setState({ first: 10 })
//                                         this.setState({ CategoryId: m.id })
//                                         this.setState({ CategoryName: m.name })
//                                         this.setState({ CategoryCount: m.categoryProductsCount })

//                                         this.setState({
//                                             products: []
//                                         },
//                                             () => {
//                                                 this.setState({ offset: 0 })
//                                                 this.props.getMasterProducts({ id: m.id, offset: 0 })
//                                             })

//                                         // this.props.setCatalogProducts(this.props.MasterProducts
//                                         //     && this.props.MasterProducts.data
//                                         //     && this.props.MasterProducts.data.masterCategoryProducts)
//                                         this.setState({ checkAll: false })
//                                         this.setState({ CheckProducts: [] })
//                                         this.props.MastercheckBox([])
//                                     }}
//                                     type="button" style={{
//                                         display: 'flex',
//                                          alignItems: 'center',
//                                         padding: '16px 0 16px 24px',
//                                         backgroundColor: '#fafafa',
//                                         border: 'solid 1px #d9d9d9',
//                                         borderRadius: '8px',
//                                         cursor: 'pointer',
//                                         width:"90%"

//                                     }}>
//                                          <div  style={{
//                     display: 'flex',

//                     // alignItems: 'center',
//                     justifyContent: 'space-between',
//                     wordBreak:'break-word',
//                     flexDirection: 'column'
//                 }}>
//                     <span style={{color:'#061303',fontWeight:600,fontSize:'16px'}}>
//                         {`${m.name}`}
//                     </span>
//                     <span style={{color:'rgb(153, 153, 153)',fontSize:'12px'}}>{`choose from ${m.categoryProductsCount} products`}</span>
//                     </div>
//                     <div style={{marginLeft: 'auto',
// marginRight: 14}}>
//        <span  style={{color:'white',fontSize:'14px',borderRadius: '100%',backgroundColor: 'rgb(137, 199, 74, 1)', width: '35px',
//     height: '30px',alignItems: 'center',textAlign: 'center',justifyContent:'center',fontWeight:550,display:'flex'}}> {`${m.categoryProductsCount}`}</span>
//         </div>
//                 </div>
//                                <br/>
//                                </div>
//                                 )}

//                         </Col>
//                         <Col style={{
//                             height: '100%',
//                             paddingTop: '18px',
//                             position: '-webkit-sticky',
//                             position: 'sticky',
//                             top: 0,
//                         }}>
//                             {this.state.CategoryName &&
//                                 <div style={{ display: 'flex' }}>
//                                     <h4 style={{
//                                         fontSize: '20px',
//                                         lineHeight: '26px',
//                                         fontWeight: 700,
//                                         maxWidth:'400px',
//                                         wordBreak:'break-word'

//                                     }}>
//                                         {`${this.state.CategoryName}`}
//                                     </h4>
//                                     <h4 style={{
//                                         fontSize: '20px',
//                                         lineHeight: '26px',
//                                         fontWeight: 700
//                                     }}>
//                                         {'('}
//                                     </h4>
//                                     {this.props.MasterProducts
//                                         && this.props.MasterProducts.data
//                                         && this.props.MasterProducts.data.masterCategoryProducts
//                                         &&
//                                         <h4 style={{
//                                             fontSize: '20px',
//                                             lineHeight: '26px',
//                                             fontWeight: 700
//                                         }}>
//                                             {`${this.state.CategoryCount}`}
//                                         </h4>

//                                     }
//                                     <h4 style={{
//                                         fontSize: '20px',
//                                         lineHeight: '26px',
//                                         fontWeight: 700
//                                     }}>
//                                         {' )'}
//                                     </h4>
//                                     <div style={{ flex: '1 1' }}></div>
//                                     {/* <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                         <GreenCheckbox
//                                             onChange={(e) => {
//                                                 SelectAll(e, this.props.MasterProducts.data.masterCategoryProducts)
//                                             }}
//                                             checked={this.state.checkAll}

//                                         // name={p.id}
//                                         // inputProps={{ "aria-label": "primary checkbox" }}
//                                         // value={p.productname}
//                                         // defaultChecked={
//                                         //     CheckProducts.includes(p.id) ? true : false
//                                         // }
//                                         >

//                                         </GreenCheckbox>
//                                     </span> */}

//                                 </div>
//                             }
//                             {this.state.CategoryName && <hr style={{ border: '1px solid #d9d9d9' }} />}
//                          {/* {console.log("SLICED_ARRAY: ", this.props.CatalogProducts.slice(0))} */}

//                             {

//                                 this.props.CatalogProducts&&this.props.CatalogProducts!==null&&this.props.CatalogProducts.length ===0 ?
//                                 this.state.CategoryName &&
//                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>

//                                     {'No products found'}
//                                 </div>
//                                 :

//                                 this.props&&this.props.CatalogProducts&&this.props.CatalogProducts.map((p) =>

//                                 p.map((source) =>
//                                 (
//                                     source.isAdded===true?

//                                     (
//                                         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' ,width:'100%'  }} id="scrollableDiv">

//                                         <img src={source.image && source.image.primary} alt={source.productname}
//                                         className='CheckedImg'
//                                          style={{ height: '80px', width: '80px', borderRadius: '6px', objectFit: 'cover', border: 'solid 1px #e5e5e5' }} />
//                                         <div style={{ paddingLeft: '10px', fontFamily: "Varela Round, sans-serif", color: '#999', width:'100%' }}>
//                                             <p style={{ fontSize: '14px', lineHeight: '24px' }}> {source.productname}</p>
//                                             <p >
//                                                 <span style={{
//                                                     fontSize: '14px',
//                                                     lineHeight: '18px',
//                                                     fontWeight: 600
//                                                 }}>
//                                                     {source.price}
//                                                 </span>
//                                                 <span style={{
//                                                     paddingleft: '5px', fontSize: '14px',
//                                                     lineHeight: '20px'
//                                                 }}>
//                                                     {source.uom}
//                                                 </span>
//                                             </p>
//                                         </div>
//                                         <div >
//                                         <input
//                                         type="checkbox"
//                                             disabled
//                                             checked={true}
//                                             name={source.id}
//                                             inputProps={{ "aria-label": "primary checkbox" }}
//                                             value={source.productname}
//                                             className="styled-checkbox"
//                                             />
//                                         </div>

//                                     </div>
//                                     )
//                                     :
//                                     (<div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' , width:'100%' }} id="scrollableDiv">

//                                         <img src={source.image && source.image.primary} alt={source.productname} style={{ height: '80px', width: '80px', borderRadius: '6px', objectFit: 'cover', border: 'solid 1px #e5e5e5' }} />
//                                         <div style={{ paddingLeft: '10px', fontFamily: "Varela Round, sans-serif", color: '#061303', width:'100%'}}>
//                                             <p style={{ fontSize: '14px', lineHeight: '24px' }}> {source.productname}</p>
//                                             <p >
//                                                 <span style={{
//                                                     fontSize: '14px',
//                                                     lineHeight: '18px',
//                                                     fontWeight: 600
//                                                 }}>
//                                                     {source.price}
//                                                 </span>
//                                                 <span style={{
//                                                     paddingleft: '5px', fontSize: '14px',
//                                                     lineHeight: '20px'
//                                                 }}>
//                                                     {source.uom}
//                                                 </span>
//                                             </p>
//                                         </div>
//                                         <div>
//                                         <input
//                                         type="checkbox"
//                                             onChange={(e) => {
//                                                 ProductsCheck(e, source.id)
//                                             }}
//                                             checked={this.state.CheckProducts.includes(source.id) ? true : false}
//                                             name={source.id}
//                                             inputProps={{ "aria-label": "primary checkbox" }}
//                                             value={source.productname}
//                                             defaultChecked={
//                                                 this.state.CheckProducts.includes(source.id) ? true : false
//                                             }
//                                             className="styled-checkbox"

//                                         />
//                                         </div>

//                                     </div>)
//                                 ))
//                                     // CategoryCount>
//                                 )

//                             }

//                             {this.state.CategoryCount >= this.state.first ?
//                                 <button type='button' onClick={LoadMore}
//                                     style={{
//                                         justifyContent: ' center',
//                                         color: 'rgb(255, 255, 255)',
//                                         backgroundColor: 'rgb(137, 199, 74, 1)',
//                                         width: '100%',
//                                         float: 'right',
//                                         height: '37px',
//                                         fontSize: '16px',
//                                         fontWeight: '600',
//                                         borderRadius: '5px',
//                                         borderWidth: '1px',
//                                         borderStyle: ' solid',
//                                         borderColor: 'transparent',
//                                         borderImage: 'initial',
//                                         overflow: 'hidden',
//                                         outline: 'none',
//                                         minHeight: ' 14px',
//                                         fontFamily: 'Varela Round, sans-serif'
//                                     }}>
//                                     Load More
//                                 </button>
//                                 :
//                                 null}
//                         </Col>

//                     </Row>
//     }
//                 </Container>

//             </>
//         );
//     }
// }
// export default Catalog;
import { React, useState, useEffect } from "react";
import "./Subcategory.css";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card";
import Arrow from "../../utils/Icon/Arrow.svg";
import Bevarages from "../../utils/Icon/Bevarages.svg";
import Businessproduct from "./Businessproduct";
import {
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  contentFontSize_18,
  HeaderFontSize_24
} from "../Typography";
import { useNavigate } from "react-router-dom";

function Subcategory(props) {
  const [categoryName, setCategoryName] = useState(null);
  const [categoryCount, setCategoryCount] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [products, setProducts] = useState([]);
  const [nextArrow, setNextArrow] = useState(false);
  const nav = useNavigate();

  useEffect(() => {
    // document.body.scrollTop = 0;
    props.getMasterCategory(localStorage.getItem("CatalogId"));
    props.setCatalogProducts([]);
  }, []);

  const handleClick = (name, id, count) => {
    localStorage.setItem("CatalogCategory", name);
    localStorage.setItem("CategoryId", id);
    localStorage.setItem("CategoryCount", count);
    nav("/home/CatalogProducts");
  };

  function handleClickOne() {
    nav("/home/CategoryBuilder/Departments");
  }
  function handleClickTwo() {
    nav("/home/CatalogBuilder");
  }
  function handleArrowClick() {
    setNextArrow(true);
  }


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {nextArrow ? (
        <Businessproduct
          categoryName={categoryName}
          categoryId={categoryId}
          props={props}
          categoryCount={categoryCount}
        />
      ) : (
        <div>
          <div className="main">
            <Typography
              className="main"
              fontSize={HeaderNavigationFontSize_20_xs_13}
              style={{ cursor: "pointer" }}
              onClick={handleClickTwo}
            >
              Business Type
            </Typography>
            <Typography
              className="main"
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              /
            </Typography>
            <Typography
              className="main"
              fontSize={HeaderNavigationFontSize_20_xs_13}
              style={{ cursor: "pointer" }}
              onClick={handleClickOne}
            >
              Category
            </Typography>
            <Typography
              className="main"
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              /
            </Typography>
            <Typography
              className="main"
              style={{ color: "#85BE49" }}
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              Sub Category
            </Typography>
          </div>
          <Typography
            className="SubCategory"
            fontSize={HeaderNavigationFontSize_20}
          >
            Choose your Sub Category
          </Typography>

          <>
            {props.MasterCategory &&
            props.MasterCategory.data &&
            props.MasterCategory.data.masterCategories.length != 0 ? (
              props.MasterCategory &&
              props.MasterCategory.data &&
              props.MasterCategory.data.masterCategories.map((m) => (
                <div style={{ paddingTop: "30px" }} onClick={handleArrowClick}>
                  <CustomCard
                    padding={"20px"}
                    onClick={() =>
                      handleClick(m.name, m.id, m.categoryProductsCount)
                    }
                    width={"100%"}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={2}
                        sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                      >
                        <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                          <CustomCard
                            padding="2px"
                            // width={"70px"}
                            height={"70px"}
                            background="#F2F2F2"
                            boxShadow="none"
                            borderRadius="10px"
                            width={matches ? "60px" : "70px"}
                            sx={{ alignItems: "center" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                //   padding:"6px"
                              }}
                            >
                              <img
                                src={Bevarages}
                                alt="Bevarages"
                                style={{ width: matches ? "45px" : "80%" }}
                              />
                            </div>
                          </CustomCard>
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                          <div
                            style={{
                              display: "flex",
                              // justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              paddingLeft: "20px",
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                              }}
                            >
                              <Typography
                                className="Drinks"
                                fontSize={contentFontSize_18}
                              >
                                {`${m.name}`}
                              </Typography>
                              <Typography
                                className="product-choose"
                                fontSize={contentFontSize_16}
                              >
                                {`choose from ${m.categoryProductsCount} products`}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <div style={{ display: "flex", gap: "15px" }}>
                                <div onClick={handleArrowClick}>
                                  <img src={Arrow} alt="arrowicon" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                </div>
              ))
            ) : (
              <>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontSize={HeaderNavigationFontSize_20}
                    className="Add-product-btn"
                  >
                    No Sub-Categories to display
                  </Typography>
                </div>
              </>
            )}
          </>
        </div>
      )}
    </>
  );
}

export default Subcategory;
