import React from "react";
import { Button } from "@mui/material";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import EditGoogleMap from "../EditGoogleMap";
import { DialogTitle } from "@mui/material";

export default function MapEditModal(params) {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  let { mapEditModal } = params;

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={true}
        onClose={mapEditModal}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Map Location"}</DialogTitle>
        <DialogContent>
          <EditGoogleMap {...params} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={mapEditModal}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
