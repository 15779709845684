import React from "react";
import {Link} from 'react-router-dom'
import { Row, Col } from "react-bootstrap";
import "./index.css";
import { useMediaQuery } from 'react-responsive';
import { Container } from "@mui/material";

const Logout = (props) => {
	// const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
	const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
document.title="Seller Portal | Logout"
    const LogoutClick = () => {
		props.setProducts([])	
		props.setOrders([])
		props.closeSnackBars()       
		localStorage.removeItem('retailerid')
		localStorage.removeItem('token')
		localStorage.removeItem('isLoggedin')
		localStorage.removeItem('storeid')
		localStorage.removeItem('imgUpload')
		localStorage.removeItem('loginformNumber')
		localStorage.removeItem('activeStep')
		localStorage.removeItem("newOrderCount")
		localStorage.removeItem("readyOrderCount")
		props.history.push({pathname:`/`})
	}
	
	const NoClick = () =>{
		props.history.push({pathname:`/home/Dashboard`})
		// window.location.reload(true);
	}
	
	
	return (
		
		
		isSmallScreen?
		<div  style={{
			backgroundColor: "white",
			border: "1px solid rgba(0, 0, 0, 0.16)",
			borderRadius: "10px",
			Top: "40px",
			justifyContent:'center',
			textAlign:'center',
			alignItems:'center',
			// padding: "20px",
			width: "90vw",
		  }}>
	<div className="form_div">
		<div id="signup-form">
			Are you sure you want to logout ? 
		</div>
		<Row style={{paddingTop:'10px'}}>
			<Col>
			<Link to='/'>
				<button
				style={{
					height: "37px",
					backgroundColor:'rgb(137, 199, 74, 1)',
					color: "white",
					fontSize: "16px",
					fontWeight: 600,
					border: "none",
					borderRadius:5,
					width:"103px"
				  }}
					onClick={LogoutClick}
					variant="success">
					Yes
				</button>
				</Link>
				</Col>
				{/* </Row>
				<Row style={{paddingTop:'10px'}}> */}
				{/* <Link to='/home'> */}
				<Col>
			<button
			style={{
				height: "37px",
				backgroundColor: "white",
				color:"rgb(137, 199, 74, 1)",
				fontSize: "16px",
				fontWeight: 600,
				border: "1px solid rgb(137, 199, 74, 1)",
				borderRadius:5,
				width:"103px"
			  }}
					  type="button"
					onClick={NoClick}
					variant="success">
					No
				</button>
				</Col>
		</Row>
	</div>
</div>
:
			<Container maxWidth='sm'   style={{
				backgroundColor: "white",
				border: "1px solid rgba(0, 0, 0, 0.16)",
				borderRadius: "10px",
				Top: "0",
				paddingTop: "30px",
				paddingBottom: "30px",
				display:'flex',justifyContent:'center',alignItems:'center'
			  }}>
				<div style={{justifyContent:'center',alignItems:'center'}}>
					<Row>
						Are you sure you want to logout ? 
					</Row>
					<Row  style={{paddingTop:'20px',justifyContent:'center',alignItems:'center'}}>
				
						
						<button
						style={{
							height: "37px",
							backgroundColor: "white",
							color:"rgb(137, 199, 74, 1)",
							fontSize: "16px",
							fontWeight: 600,
							border: "1px solid rgb(137, 199, 74, 1)",
							borderRadius:5,
						  }}
						  		type="button"
								id="submit-button"
								onClick={NoClick}
								variant="success">
								No
							</button>
						<Link to='/'>
							<button
							style={{
								height: "37px",
								backgroundColor:'rgb(137, 199, 74, 1)',
								color: "white",
								fontSize: "16px",
								fontWeight: 600,
								border: "none",
								borderRadius:5,
							  }}
								id="submit-button"
								onClick={LogoutClick}
								variant="success">
								Yes
							</button>
							</Link>
							{/* <Link to='/home'> */}
					
							{/* </Link> */}
					
					</Row>
				</div>
			</Container>

	
	);
};
export default Logout;
