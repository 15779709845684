import * as yup from 'yup';

export const validationSchema = yup.object({
    Productname: yup
      .string()
      .min(2,"Product name should be atleast 2 characters long")
      .max(100,"length exceeded")
      .trim("Space not allowed")
      .required('Product name is required'),
    Description: yup
      .string()
      .min(5,"Description should be atleast 5 characters long")
      .max(200,"length exceeded")
      .trim("Space not allowed")
      .required('Product description is required')
      ,
    barcode: yup
      .string()
      .trim("Space not allowed"),
      // .required('Barcode is required'),
   Price : yup.string()
      .trim("Space not allowed")
        .required('Price is required')
        .test('valid-number', 'Only numbers are allowed', value => {
          const numValue = parseFloat(value);
          return !isNaN(numValue); 
        })
        .test('not-negative', 'Positive numbers are allowed', value => {
          const numValue = parseFloat(value);
          return numValue >= 0;
        })
        .test('not-zero', 'Price cannot be zero', value => {
          const numValue = parseFloat(value);
          return numValue !== 0; 
        })
        .test('no-alphabet', 'Only numbers are allowed', value => {
          return /^\d+$/.test(value); 
        }),
    PromoPrice:yup
       .string()
       .trim()
       .matches(/^(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/, "Only positive numbers are allowed"),
    brand:yup
       .string()
       .min(2,"Brand should be atleast 2 characters long"),
    MfgName:yup
       .string()
       .min(2,"manufacturer name should be atleast 2 characters long"),
       Quantity: yup
       .string()
       .matches(/^([+]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?)$/, "Only positive numbers are allowed")
       .trim("Space not allowed")
       .required('Quantity is required'),     
    unit:yup
       .string()
       .oneOf(['No.s','kg','g','gal','l','ml'],'required')
       .required('Unit is required')
      ,
      department: yup.string().required('Category is required'),
      // salescategory: yup.string().required('Subcategory is required'),
   //  department:yup
   //     .string()
   //     .required('Department is required'),
  });