import { gql } from "@apollo/client";

export const STORE_WORKING = gql`
mutation($storeWorking: Boolean,$storeid:ID!) {
  updatestore(storeWorking:$storeWorking,storeid:$storeid){
  id
  storeWorking
}
}
`

export const STORE_WORKING_SUBSCRIPTION = gql`
mutation($storeWorking: Boolean,$storeid:ID!,$subscription:subscriptionInput!) {
  updatestore(storeWorking:$storeWorking,storeid:$storeid,subscription:$subscription){
  id
  storeWorking
}
}
`
export const PaperProps = () => ({
    style: {
      borderRadius: "15px",
      padding: "15px",
    },
  });