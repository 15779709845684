import React, { useEffect, useState } from "react";
import { Box, Typography } from "../MUIComponents/index";
import NavbarProfileimg from "../../utils/images/NavbarProfileimg.png";
import CustomPopover from "../Commoncomponents/popup/index";
import CustomDialog from "../Commoncomponents/dialog";
import { usePopupState, bindTrigger } from "material-ui-popup-state/hooks";
import { useNavigate, useLocation } from "react-router-dom";
import CustomButton from "../Commoncomponents/button";
import { contentFontSize_18_xs_13 } from "../Typography/index";
import "./NavProfile.css";
import { useTheme } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import { Dialog } from "../MUIComponents/index";
import { DialogActions } from "../MUIComponents/index";
import { DialogTitle } from "../MUIComponents/index";
import CloseIcon from "@mui/icons-material/Close";
import dummy from "../../utils/images/dummy.png";

export default function NavProfile(props) {
  const [ProfileColorChange, setProfileColorChange] = useState(false);
  const [HelpColorChange, setHelpColorChange] = useState(false);
  const [LogoutColorChange, setLogoutColorChange] = useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

  const location = useLocation();



  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const navigate = useNavigate(); // Initialize useHistory

  // const handleNavProfile=()=>{
  //   setProfileColorChange(false);
  //   setHelpColorChange(false);
  //   setLogoutColorChange(false);
  // }

  const handleProfile = () => {
    setProfileColorChange(true);
    setHelpColorChange(false);
    setLogoutColorChange(false);
    navigate("/home/Profile");
    popupState.close();
  };

  const handleHelp = () => {
    setHelpColorChange(true);
    setProfileColorChange(false);
    setLogoutColorChange(false);
    navigate("/home/Help");
    popupState.close();
  };

  const handleLogout = () => {
    setLogoutColorChange(!LogoutColorChange);
    setProfileColorChange(false);
    setHelpColorChange(false);
    //popupState.close();
    setOpenLogoutDialog(!openLogoutDialog);
  };
  const handleClose = () => {
    setOpenLogoutDialog(!openLogoutDialog);
    setProfileColorChange(false);
    setHelpColorChange(false);
    setLogoutColorChange(!LogoutColorChange);
    popupState.close();
    // navigate('/home/Dashboard')
  };

  const handleClosenew = () => {
    setOpenLogoutDialog(!openLogoutDialog);
    setProfileColorChange(false);
    setHelpColorChange(false);
    setLogoutColorChange(!LogoutColorChange);

    popupState.close();
    navigate("/home/Dashboard");
  };

  const handleClosenew1 = () => {
    setOpenLogoutDialog(!openLogoutDialog);
    setProfileColorChange(false);
    setHelpColorChange(false);
    setLogoutColorChange(!LogoutColorChange);

    popupState.close();
    navigate("/home/MyOrders");
  };

  const handleLogoutConfirmation = () => {
    props.setProducts([]);
    props.setOrders([]);
    props.closeSnackBars();
    localStorage.removeItem("retailerid");
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedin");
    localStorage.removeItem("storeid");
    localStorage.removeItem("imgUpload");
    localStorage.removeItem("loginformNumber");
    localStorage.removeItem("activeStep");
    localStorage.removeItem("newOrderCount")
    localStorage.removeItem("readyOrderCount")
    navigate({ pathname: `/` });
  };


  const role = localStorage.getItem("role");

  return (
    <>
      {role !== "Store Manager" ? (
        <>
          <Box sx={{ position: "relative", display: "inline-block" }}>
            <img
              src={dummy}
              alt="Navbar Profile"
              style={{
                cursor: "pointer",
                height: matches ? "24px" : "48px",
                width: matches ? "18px" : "48px",
                borderRadius: "50%",
              }}
              // onClick={handleNavProfile}
              {...bindTrigger(popupState)}
            />
            <CustomPopover
              popupState={popupState}
              text="Hello, I'm a popover!"
              borderRadius="10px"
              matchBackground={true}
              // width={matches?"150px":"200px"}
              // height={matches?"122px":"162px"}
              // width="0px"
              // height="0px"
              anchorOrigin={{
                vertical: 140,
                horizontal: -10,
              }}
              transformOrigin={{
                vertical: 90,
                horizontal: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  padding: "10px 15px 10px 15px",
                }}
              >
                <Typography
                  style={{
                    // borderBottom: "1px solid black",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    color: LogoutColorChange ? "red" : "#555",
                  }}
                  fontSize={contentFontSize_18_xs_13}
                  onClick={handleLogout}
                >
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    className="NavProfileFontStyle"
                  >
                    Logout
                  </span>

                  <Dialog
                    open={openLogoutDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                  >
                    <div
                      onClick={handleClose}
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          height: "27px",
                          width: "27px",
                          border: "2px solid #8B8989",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CloseIcon style={{ color: "#8B8989" }} />
                      </div>
                    </div>

                    <DialogTitle
                      id="alert-dialog-title"
                      style={{
                        padding: "34px  64px 14px 64px",
                        color: "#8B8989",
                      }}
                    >
                      {"Are you sure you want to logout?"}
                    </DialogTitle>
                    <DialogActions style={{ padding: "14px 64px 24px 64px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          width: "100%",
                        }}
                      >
                        <Typography
                          onClick={handleClosenew1}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "900",
                            cursor: "pointer",
                          }}
                        >
                          Cancel
                        </Typography>
                        <div>|</div>
                        <Typography
                          onClick={handleLogoutConfirmation}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "900",
                            color: "#D40000",
                            cursor: "pointer",
                          }}
                        >
                          Logout
                        </Typography>
                      </div>
                    </DialogActions>
                  </Dialog>
                </Typography>
              </div>
            </CustomPopover>
          </Box>
        </>
      ) : (
        <Box sx={{ position: "relative", display: "inline-block" }}>
          {props &&
          props.retailerDetails &&
          props.retailerDetails.data &&
          props.retailerDetails.data.retailerdetails &&
          props.retailerDetails.data.retailerdetails.profileImageUrl ? (
            <img
              src={
                props &&
                props.retailerDetails &&
                props.retailerDetails.data &&
                props.retailerDetails.data.retailerdetails &&
                props.retailerDetails.data.retailerdetails.profileImageUrl
              }
              alt="Navbar Profile"
              style={{
                cursor: "pointer",
                height: matches ? "24px" : "48px",
                width: matches ? "18px" : "48px",
                borderRadius: "50%",
              }}
              // onClick={handleNavProfile}
              {...bindTrigger(popupState)}
            />
          ) : (
            <img
              src={dummy}
              alt="Navbar Profile"
              style={{
                cursor: "pointer",
                height: matches ? "24px" : "48px",
                width: matches ? "18px" : "48px",
                borderRadius: "50%",
              }}
              // onClick={handleNavProfile}
              {...bindTrigger(popupState)}
            />
          )}
          <CustomPopover
            popupState={popupState}
            text="Hello, I'm a popover!"
            borderRadius="10px"
            matchBackground={true}
            width={matches ? "150px" : "200px"}
            height={matches ? "122px" : "162px"}
            anchorOrigin={{
              vertical: 140,
              horizontal: -130,
            }}
            transformOrigin={{
              vertical: 90,
              horizontal: 80,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                padding: "29px 10px 29px 10px",
              }}
            >
              <>
                <Typography
                  style={{
                    borderBottom: "1px solid black",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    color:
                      location.pathname === "/home/Profile" ||
                      location.pathname === "/home/UpdateProfile" ||
                      location.pathname === "/home/UpdateStore" ||
                      location.pathname === "/home/ManageSlots" ||
                      location.pathname === "/home/ChangePassword"
                        ? "red"
                        : "#555",
                    cursor: "pointer",
                  }}
                  onClick={handleProfile}
                  fontSize={contentFontSize_18_xs_13}
                  className="NavProfileFontStyle"
                >
                  <span>Profile</span>
                </Typography>

                <Typography
                  style={{
                    borderBottom: "1px solid black",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    color:
                      location.pathname === "/home/Help" ? "#659B1B" : "#555",
                    cursor: "pointer",
                  }}
                  onClick={handleHelp}
                  onMouseEnter={() => setHelpColorChange(true)}
                  onMouseLeave={() => setHelpColorChange(false)}
                  fontSize={contentFontSize_18_xs_13}
                  className="NavProfileFontStyle"
                >
                  Help
                </Typography>

                <Typography
                  style={{
                    borderBottom: "1px solid black",
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    color: LogoutColorChange ? "red" : "#555",
                  }}
                  fontSize={contentFontSize_18_xs_13}
                  onClick={handleLogout}
                >
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    className="NavProfileFontStyle"
                  >
                    Logout
                  </span>

                  <Dialog
                    open={openLogoutDialog}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{ sx: { borderRadius: "10px" } }}
                  >
                    <div
                      onClick={handleClose}
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          height: "27px",
                          width: "27px",
                          border: "2px solid #8B8989",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CloseIcon style={{ color: "#8B8989" }} />
                      </div>
                    </div>
                    <DialogTitle
                      id="alert-dialog-title"
                      style={{
                        padding: "34px  64px 14px 64px",
                        color: "#8B8989",
                      }}
                    >
                      {"Are you sure you want to logout?"}
                    </DialogTitle>
                    <DialogActions style={{ padding: "14px 64px 24px 64px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          width: "100%",
                        }}
                      >
                        <Typography
                          onClick={handleClosenew}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "900",
                            cursor: "pointer",
                          }}
                        >
                          Cancel
                        </Typography>
                        <div>|</div>
                        <Typography
                          onClick={handleLogoutConfirmation}
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "900",
                            color: "#D40000",
                            cursor: "pointer",
                          }}
                        >
                          Logout
                        </Typography>
                      </div>
                    </DialogActions>
                  </Dialog>
                </Typography>
              </>
            </div>
          </CustomPopover>
        </Box>
      )}
    </>
  );
}
