// import React from "react";
// import PropTypes from "prop-types";
// import {
//   Box,
//   Card,
//   CardContent,
//   CardHeader,
//   Divider,
//   useTheme,
//   Stack,
//   Typography,
// } from "@mui/material";
// import { EmptyProducts } from "../../utils/image";
// import {NoImage}  from '../../utils/image';

// const PopularProducts = (props) => {
//   const theme = useTheme();

//   const data = [
//     {
//       name:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProduct1,
//       image:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProductImg1,
//     },
//     {
//       name:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProduct2,
//       image:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProductImg2,
//     },
//     {
//       name:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProduct3,
//       image:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProductImg3,
//     },
//     {
//       name:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProduct4,
//       image:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProductImg4,
//     },
//     {
//       name:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProduct5,
//       image:
//         props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProductImg5,
//     },
//   ];

//   NewsItem.propTypes = {
//     news: PropTypes.object.isRequired,
//   };

//   function NewsItem({ news }) {
//     const { image, name } = news;

//     return (
//       name===null?
//       null
//       :
//       <Stack direction="row" alignItems="center" spacing={2}>
//         <Box
//           component="img"
//           alt={name}
//           src={image===null?NoImage:image}
//           sx={{ width: 48, height: 48, borderRadius: 1.5 }}
//         />
//         <Box sx={{ minWidth: 240 }}>
//           <Typography
//             variant="body2"
//             style={{ fontWeight: 500, lineHeight: 22 / 14 }}
//             noWrap
//           >
//             {name}
//           </Typography>
//         </Box>
//       </Stack>
//     );
//   }

//   return (
//     <Card
//       style={{
//         boxShadow: theme.customShadows.z16,
//         borderRadius: "16px",
//         position: "relative",
//         zIndex: 0,
//       }}
//       sx={{'@media screen and (min-width:600px) and (max-width:899px)':{
//         right:'40px',
//       }
//       }}
//     >
//       <CardHeader
//         style={{
//           padding: theme.spacing(3, 3, 0),
//           titleTypographyProps: { variant: "h6" },
//           subheaderTypographyProps: { variant: "body2" },
//         }}
//         sx={{'@media screen and (min-width:600px) and (max-width:899px)':{
//           position:"relative",right:'40px',
//         }
//         }}
//         title="Top Selling Products"
//       />
//        <CardContent>

//         { props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.popularProduct1===null ? (
//           <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
//           <img src={EmptyProducts} alt={"NoProducts"} height={300}/>
//           <div>

//           </div>
//           </Stack>
//         ) : (
//           <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
//           {data.map((news,index) => (
//             <NewsItem key={index} news={news} />
//           ))}
//         </Stack>

//         )}

//       </CardContent>
//       <Divider />
//     </Card>
//   );
// };
// export default PopularProducts;

import React from "react";
import { contentFontSize_16 } from "../Typography/index";
import { contentFontSize_16_xs_10 } from "../Typography/index";
import CustomCard from "../Commoncomponents/card";
import { Typography } from "../MUIComponents/index";
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import NoImage from '../../utils/images/NoImage.jpg'

export default function PopularProducts({ mdmatch, props }) {
  useEffect(() => {
    // console.log("entered in popularproducts");
    // props.getDashboard();
    props?.getNewDashboard(`${localStorage.getItem("storeid")}`);
  }, []);

 
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#F4F5F9",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#F4F5F9",
      color: "black",
      fontFamily: "Roboto",
      fontWeight: "400",
      fontSize: mdmatch ? "10px" : "16px",
    },
  }));

  const [footerSliderArray, setFooterSliderArray] = useState([]);
  // const newFooterSliderArray = [
  //   {
  //     name: props.DashboardDetails?.data?.dashboard?.popularProduct1,
  //     image: props.DashboardDetails?.data?.dashboard?.popularProductImg1,
  //   },
  //   {
  //     name: props.DashboardDetails?.data?.dashboard?.popularProduct2,
  //     image: props.DashboardDetails?.data?.dashboard?.popularProductImg2,
  //   },
  //   {
  //     name: props.DashboardDetails?.data?.dashboard?.popularProduct3,
  //     image: props.DashboardDetails?.data?.dashboard?.popularProductImg3,
  //   },
  //   {
  //     name: props.DashboardDetails?.data?.dashboard?.popularProduct4,
  //     image: props.DashboardDetails?.data?.dashboard?.popularProductImg4,
  //   },
  //   {
  //     name: props.DashboardDetails?.data?.dashboard?.popularProduct5,
  //     image: props.DashboardDetails?.data?.dashboard?.popularProductImg5,
  //   },
  // ];

  // newFooterSliderArray.map((item) => {
  //   // console.log("this is name",item.name.length)
  // });
  // setFooterSliderArray(newFooterSliderArray);

  // console.log("setFooterSliderArray", footerSliderArray);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          width: "100%",
        }}
      >
        {props?.NewDashboardDetails &&
         props?.NewDashboardDetails?.getDashboardNew &&
         props?.NewDashboardDetails?.getDashboardNew?.topSellingProducts.map((item) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              width: "110px",
              height: "100%",
            }}
          >
            <CustomCard padding="15px" boxShadow="none">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: mdmatch ? "50px" : "80px",
                    height: mdmatch ? "50px" : "80px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
             <img
  src={item?.productId?.image?.primary?(item?.productId?.image?.primary):NoImage}
  alt=""
  style={{
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    objectFit: "cover",
  }}
/>

                </div>
              </div>
            </CustomCard>
            <div style={{}}>
              <Typography
                style={{
                  textAlign: "center",
                  // whiteSpace: "nowrap",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  fontFamily: "Nunito",
                  fontWeight: "600",
                  cursor:"pointer"
                  // percentage also works
                }}
                fontSize={contentFontSize_16}
              >
                {item?.productId?.productname.length > 7 ? (
                  <BootstrapTooltip title={item?.productId?.productname} placement="top">
                    {item?.productId?.productname.substring(0, 6) + "..."}
                  </BootstrapTooltip>
                ) : (
                  item?.productId?.productname?(item?.productId?.productname):"No Name"
                )} 
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
