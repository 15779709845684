import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../Marker";

class SimpleMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			center: {
				lat: this.props.config[1].value
					? this.props.config[1].value.coordinates[1]
					: 10.850516,
				lng: this.props.config[1].value
					? this.props.config[1].value.coordinates[0]
					: 76.27108,
			},
		};
	}

	static defaultProps = {
		zoom: 15,
		center: {
			lat: 10.850516,
			lng: 76.27108,
		},
	};

	changeMarkerPostion = (e) => {
		let { lat, lng } = e;
		let MapValue = { lat, lng };
		this.setState({
			...this.state,
			center: {
				...this.state.center,
				lat: e.lat,
				lng: e.lng,
			},
		});

		this.props.handleMapChange(MapValue, this.props.config, 5);
	};
	render() {
		return (
			// Important! Always set the container height explicitly
			<div style={{ height: "90vh", width: "100%" }}>
				<GoogleMapReact
					bootstrapURLKeys={{ key: "AIzaSyA1Tzy_rLkPkeAkyk8yHPh-5GBU2NxUm5Y" }}
					defaultCenter={this.state.center}
					defaultZoom={this.props.zoom}
					onClick={this.changeMarkerPostion}>
					<Marker
						draggable={true}
						lat={this.state.center.lat}
						lng={this.state.center.lng}
						text="My Marker"
					/>
				</GoogleMapReact>
			</div>
		);
	}
}

export default SimpleMap;
