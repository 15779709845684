// import React, { Component } from 'react';
// import SortableTree, { addNodeUnderParent, getNodeAtPath, removeNodeAtPath } from '../Tree';
// import { message } from 'antd';
// import { Form } from 'semantic-ui-react';
// import { Row, Col } from 'react-bootstrap';
// // import Icon from '@material-ui/icons/Icon';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import {MenuItem} from "@mui/material"
// import Spinner from 'react-bootstrap/Spinner';
// import Snackbar from '@mui/material/Snackbar';
// import { AlertTitle } from '@mui/material';
// import Alert from '@mui/material/Alert';
// import client from "../../Configurations/apollo";
// import { CREATE_HEIRARCHY, UPDATE_HEIRARCHY, UPDATE_HEIRARCHY1, CREATE_FIRSTLEVEL_HEIRARCHY } from "./GqlQuery";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { green } from "@mui/material/colors";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import { Container } from "@mui/material";
// import './index.css';

// // function Alert(props) {

// //   return <Alert elevation={6} variant="filled" {...props} />;
// // }

// export class Heirarchy extends Component {
//   constructor(props) {
//     super(props);

//     // this.componentDidMount()
//     this.state = {
//       isMobile: window.innerWidth < 768,
//       treeData: [],
//       open: true,
//       tree: false,
//       addAsFirstChild: false,
//       nodeClicked: null,
//       isInput: false,
//       checkedA: true,
//       Depth: 3,
//       depParentid: null,
//       childCategoryPresent: false,
//       status: "active",
//       displayRank: null,
//       parent: false,
//       parentid: null,
//       nodename: "",
//       Heirarchyid: null,
//       newdep: false,
//       update: 1,
//       ErrorOpen: true,
//       error: false,
//       errMsg: "",
//       netError: false,
//       success: false,
//       updateSuccess: false,
//       successstatus: false,
//       nodeadded: false
//     };
//     this.handleTreeOnChange = this.handleTreeOnChange.bind(this);
//     this.handleNodeClick = this.handleNodeClick.bind(this);

//   }

//   updateIsMobile = () => {
//     this.setState({
//       isMobile: window.innerWidth < 768
//     });
//   }

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.updateIsMobile);
//   }
//   componentDidMount() {
//     window.addEventListener('resize', this.updateIsMobile);
//     this.props.getDepartmentList(`${localStorage.getItem('storeid')}`);
//   }

//   alertNodeInfo = ({ node, path, treeIndex }) => {
//     this.setState({ isInput: false });
//     const objectString = Object.keys(node)
//       .map((k) => (k === "childCategories" ? "childCategories: Array" : `${k}: '${node[k]}'`))
//       .join(",\n   ");

//     global.alert(
//       "Info passed to the button generator:\n\n" +
//       `node: {\n   ${objectString}\n},\n` +
//       `path: [${path.join(", ")}],\n` +
//       `treeIndex: ${treeIndex}`
//     );
//   };

//   handleTreeOnChange = (treeData) => {
//     this.setState({ treeData });
//   };

//   handleNodeClick = (node) => {
//     this.setState({
//       nodeClicked: node,
//       isInput: true
//     });
//   };

//   // componentDidMount() {

//   // }

//   componentDidUpdate(prevProps, prevState) {
//     if (prevProps.DepartmentList !== this.props.DepartmentList) {
//       this.setState({ treeData: this.props.DepartmentList.data.salesdepartments });
//       this.setState({ tree: true })

//     }
//     if (prevState.update !== this.state.update) {
//       this.props.getDepartmentList(`${localStorage.getItem('storeid')}`);

//     }
//   }

//   render() {
//     document.title = "Seller Portal | Manage Hierarchy";
//     let parentKey;
//     const token = localStorage.getItem('token');

//     const AddHeirarchy = (id) => {
//       let depParentid;
//       let level;
//       let parentChildCategory;
//       let parentName;

//       {
//         this.state.treeData.map(n =>
//           n.childCategories ?
//             (
//               n.childCategories.map(c =>
//                 c.childCategories ?
//                   (
//                     c.childCategories.map(s =>
//                       s.childCategories ?
//                         (
//                           s.childCategories.map(a =>
//                             a.id === id &&

//                             (parentName = s.name)
//                           )

//                         )
//                         :
//                         (
//                           s.id === id &&
//                           (parentName = c.name)
//                         )
//                     )
//                   )
//                   :
//                   (
//                     c.id === id &&
//                     (parentName = n.name)
//                   )
//               )
//             )
//             : (

//               null

//             )

//         )
//       }

//       {
//         this.state.treeData.map(n =>
//           n.childCategories ?
//             (
//               n.childCategories.map(c =>
//                 c.childCategories ?
//                   (
//                     c.childCategories.map(s =>
//                       s.childCategories ?
//                         (
//                           s.childCategories.map(a =>
//                             a.id === id &&

//                             (parentChildCategory = s.childCategoryPresent)
//                           )

//                         )
//                         :
//                         (
//                           s.id === id &&
//                           (parentChildCategory = c.childCategoryPresent)
//                         )
//                     )
//                   )
//                   :
//                   (
//                     c.id === id &&
//                     (parentChildCategory = n.childCategoryPresent)
//                   )
//               )
//             )
//             : (

//               null

//             )

//         )
//       }

//       {
//         this.state.treeData.map(n =>
//           n.childCategories ?
//             (
//               n.childCategories.map(c =>
//                 c.childCategories ?
//                   (
//                     c.childCategories.map(s =>
//                       s.childCategories ?
//                         (
//                           s.childCategories.map(a =>
//                             a.id === id&&

//                             (level = 4)
//                           )

//                         )
//                         :
//                         (
//                           s.id === id &&
//                           (level = 3)
//                         )
//                     )
//                   )
//                   :
//                   (
//                     c.id === id &&
//                     (level = 2)
//                   )
//               )
//             )
//             : (

//              null

//             )

//         )
//       }

//       {
//         this.state.treeData.map(n =>
//           n.childCategories ?
//             (
//               n.childCategories.map(c =>
//                 c.childCategories ?
//                   (
//                     c.childCategories.map(s =>
//                       s.childCategories ?
//                         (
//                           s.childCategories.map(a =>
//                             a.id === id &&

//                             (depParentid = s.id)
//                           )

//                         )
//                         :
//                         (
//                           s.id === id&&
//                           (depParentid = c.id)
//                         )
//                     )
//                   )
//                   :
//                   (
//                     c.id === id &&
//                     (depParentid = n.id)
//                   )
//               )
//             )
//             : (

//               null

//             )

//         )
//       }
//       let name = this.state.nodename;
//       let { childCategoryPresent, status, displayRank } = this.state;
//       if (this.state.nodename === "") {
//         message.error({
//           content: 'Please enter the name of heirarchy',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         }, 10);
//       }
//       else if(Math.sign(displayRank)===-1){
//         message.error({
//           content: 'Display rank must be a positive number',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         }, 10);
//       }
//       else {// this.props.AddDepartment({ name, depParentid, status,childCategoryPresent, displayRank });
//         client
//           .mutate({
//             mutation: CREATE_HEIRARCHY,
//             // mutate:{
//             fetchPolicy: "no-cache",
//             errorPolicy: "all",
//             // }
//             variables: {
//               storeid: localStorage.getItem('storeid'),
//               name: name,
//               level: level,
//               displayrank: Number(displayRank),
//               parentid: depParentid,
//               status: status,
//               childCategoryPresent: childCategoryPresent,
//               parentChildCategory: parentChildCategory
//             },
//             context: {
//               headers: {
//                 Authorization: 'Bearer ' + token
//               }
//             },
//           })
//           .then((res) => {

//             if (res.data && res.data.createSalesHierarchy && res.data.createSalesHierarchy !== null) {
//               this.setState({ update: this.state.update + 1 });
//               this.setState({ success: true });
//               UpdateChildcategoryPresent(depParentid, true, parentName);
//               status === "inactive" && this.props.getSellerWorking(this.props.retailerDetails && this.props.retailerDetails.data
//                 && this.props.retailerDetails.data.retailerdetails["id"])
//               status === "inactive" && localStorage.setItem('sworking', Boolean(this.retailerWorking &&
//                 this.props.retailerWorking.data &&
//                 this.props.retailerWorking.data.retailerdetails &&
//                 this.props.retailerWorking.data.retailerdetails.stores[0] &&
//                 this.props.retailerWorking.data.retailerdetails.stores[0].storeWorking &&
//                 this.props.retailerWorking.data.retailerdetails.stores[0].storeWorking))
//             }
//             else if (res.errors) {
//               this.setState({ success: false })
//               this.setState({ error: true });
//               this.setState({ errMsg: res.errors[0].message });
//             }

//           })
//           .catch((errors) => {

//             // console.log(errors.errorMessage)
//             this.setState({ success: false })
//             this.setState({ netError: true });
//             this.setState({ errMsg: errors });
//           })
//       }
//     }
//     const Add1 = () => {
//       let name = this.state.nodename;
//       let depParentid = null;
//       let { childCategoryPresent, status, displayRank } = this.state;
//       if (this.state.nodename === "") {
//         message.error({
//           content: 'Please enter the name of heirarchy',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         });
//       }
//       else if(Math.sign(displayRank)===-1){
//         message.error({
//           content: 'Display rank must be a positive number',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         }, 10);
//       }
//       else {
//         client
//           .mutate({
//             mutation: CREATE_FIRSTLEVEL_HEIRARCHY,
//             fetchPolicy: "no-cache",
//             errorPolicy: "all",
//             variables: {
//               storeid: localStorage.getItem('storeid'),
//               name: name,
//               displayrank: Number(displayRank),
//               parentid: depParentid,
//               status: status,
//               childCategoryPresent: childCategoryPresent,
//               level: 1
//             },
//             context: {
//               headers: {
//                 Authorization: 'Bearer ' + token
//               }
//             },
//           })
//           .then((res) => {
//             if (res.data && res.data.createSalesHierarchy && res.data.createSalesHierarchy !== null) {
//               this.setState({ update: this.state.update + 1 }, () => {
//                 this.setState({ success: true });
//               });
//               // window.location.reload(true)
//               // this.props.getDepartmentList(`${localStorage.getItem('storeid')}`);
//               // this.setState({ treeData: this.props.DepartmentList.data.salesdepartments });

//               status === "inactive" && this.props.getSellerWorking(this.props.retailerDetails && this.props.retailerDetails.data
//                 && this.props.retailerDetails.data.retailerdetails["id"])

//               // window.location.reload(true)
//             }
//             else if (res.errors) {
//               this.setState({ error: true });
//               this.setState({ errMsg: res.errors[0].message });
//             }
//           })
//           .catch((err) => {

//             this.setState({ netError: true });
//             this.setState({ errMsg: err });
//           })
//       }

//     }

// const Add = () => {
//   let name = this.state.nodename;
//   let depParentid = null;
//   let { childCategoryPresent, status, displayRank } = this.state;
//   if (this.state.nodename === "") {
//     message.error({
//       content: 'Please enter the name of heirarchy',
//       className: 'custom-class',
//       style: {
//         marginTop: '20vh',
//         marginLeft: '60vh'
//       },
//     });
//   }
//   else if(Math.sign(displayRank)===-1){
//     message.error({
//       content: 'Display rank must be a positive number',
//       className: 'custom-class',
//       style: {
//         marginTop: '20vh',
//         marginLeft: '60vh'
//       },
//     }, 10);
//   }
//   else {
//     client
//       .mutate({
//         mutation: CREATE_FIRSTLEVEL_HEIRARCHY,
//         fetchPolicy: "no-cache",
//         errorPolicy: "all",
//         variables: {
//           storeid: localStorage.getItem('storeid'),
//           name: name,
//           level: 1,
//           displayrank: Number(displayRank),
//           parentid: depParentid,
//           status: status,
//           childCategoryPresent: childCategoryPresent
//         },
//         context: {
//           headers: {
//             Authorization: 'Bearer ' + token
//           }
//         },
//       })
//       .then((res) => {
//         if (res.data && res.data.createSalesHierarchy && res.data.createSalesHierarchy !== null) {
//           this.setState({ update: this.state.update + 1 }, () => {
//             this.setState({ success: true });
//           });
//           //  window.location.reload(true)
//           // this.props.getDepartmentList(`${localStorage.getItem('storeid')}`);
//           // this.setState({ treeData: this.props.DepartmentList.data.salesdepartments });

//           status === "inactive" && this.props.getSellerWorking(this.props.retailerDetails && this.props.retailerDetails.data
//             && this.props.retailerDetails.data.retailerdetails["id"])

//         }
//         else if (res.errors) {
//           this.setState({ success: false })
//           this.setState({ error: true });
//           this.setState({ errMsg: res.errors[0].message });
//         }
//       }).catch((err) => {

//         this.setState({ success: false })
//         this.setState({ netError: true });
//         this.setState({ errMsg: err });
//       })
//   }
//   // .then(()=>{
//   //           this.setState({ update:this.state.update+1});
//   // })
//   // this.props.AddDepartment({ name, depParentid, status,childCategoryPresent,displayRank });
// }
//     const Update = (id, parentid, childCategoryPresent) => {
//       let hid = id;
//       let depParentid = parentid;
//       let name = this.state.nodename;
//       let { status, displayRank } = this.state;
//       if (this.state.nodename === "") {
//         message.error({
//           content: 'Please enter the name of heirarchy',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         });
//       }
//       else if(Math.sign(displayRank)===-1){
//         message.error({
//           content: 'Display rank must be a positive number',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         }, 10);
//       }
//       else {
//         client
//           .mutate({
//             mutation: UPDATE_HEIRARCHY,
//             variables: {
//               hierarchyid: hid,
//               name: name,
//               parentid: depParentid,
//               status: status,
//               displayrank: Number(displayRank),
//               childCategoryPresent: Boolean(childCategoryPresent)
//             },
//             context: {
//               headers: {
//                 Authorization: 'Bearer ' + token
//               }
//             },
//           })
//           .then((res) => {
//             if (res.data) {
//               this.setState({ update: this.state.update + 1 });
//               this.setState({ updateSuccess: true });
//               status === "inactive" && this.props.getSellerWorking(this.props.retailerDetails && this.props.retailerDetails.data
//                 && this.props.retailerDetails.data.retailerdetails
//                 && this.props.retailerDetails.data.retailerdetails["id"])

//             }
//             else if (res.errors) {
//               this.setState({ error: true });
//               this.setState({ errMsg: res.errors[0].message });
//             }
//           })
//           .catch((err) => {
//             this.setState({ netError: true });
//             this.setState({ errMsg: "Department or Category exists already" });

//           })
//       }
//     }
//     const UpdateChildcategoryPresent = (id, childCategoryPresent, hname) => {
//       let hid = id;
//       // let depParentid = parentid;
//       let name = hname;
//       let { status, displayRank } = this.state;
//       if (this.state.nodename === "") {
//         message.error({
//           content: 'Please enter the name of heirarchy',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         });
//       }
//          else if(Math.sign(displayRank)===-1){
//         message.error({
//           content: 'Display rank must be a positive number',
//           className: 'custom-class',
//           style: {
//             marginTop: '20vh',
//             marginLeft: '60vh'
//           },
//         }, 10);
//       }
//       else {
//         client
//           .mutate({
//             mutation: UPDATE_HEIRARCHY1,
//             variables: {
//               hierarchyid: hid,
//               name: name,
//               // parentid:depParentid,
//               status: status,
//               displayrank: Number(displayRank),
//               childCategoryPresent: Boolean(childCategoryPresent)
//             },
//             context: {
//               headers: {
//                 Authorization: 'Bearer ' + token
//               }
//             },
//           })
//           .then((res) => {
//             if (res.data) {
//               this.setState({ update: this.state.update + 1 });
//               // this.setState({ success:true});
//               status === "inactive" && this.setState({ successstatus: true });
//               status === "inactive" && this.props.getSellerWorking(this.props.retailerDetails && this.props.retailerDetails.data
//                 && this.props.retailerDetails.data.retailerdetails["id"])

//             }
//             else {
//               this.setState({ error: true });
//               this.setState({ errMsg: res.errors[0].message });
//             }
//           })
//           .catch((err) => {
//             this.setState({ netError: true });
//             this.setState({ errMsg: "Department or Category exists already" });
//           })
//       }
//       // this.props.UpdateDepartment({ hid, name, depParentid, childCategoryPresent, status, displayRank });
//       // window.location.reload(true)
//     }
//     const theme = createTheme({
//       palette: {
//         primary: green,
//       },
//       overrides: {
//         MuiInputBase: {
//           input: {
//             '&:-webkit-autofill': {
//               transitionDelay: '9999s',
//               transitionProperty: 'background-color, color',
//             },
//           },
//         },
//       },
//     })
//     return (
//       <>
//         {/* {this.props.isData&&console.log("this.props.WorkingError",this.props.WorkingError)} */}
//         {this.state.updateSuccess && this.props.isData && this.props.retailerWorking
//           && this.props.retailerWorking.data &&
//           this.props.retailerWorking.data.retailerdetails &&
//           this.props.retailerWorking.data.retailerdetails.stores[0] &&
//           this.props.retailerWorking.data.retailerdetails.stores[0].storeWorking === false ?

//           <Snackbar open={this.props.isData}
//             autoHideDuration={3000}
//             style={{ width: "auto", left: "50%",position:'absolute',zIndex:10 }}
//             anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
//             onClose={() => this.props.closeSnackBars()}>

//             <Alert severity="error">
//               {
//                 "Store Offline"}
//             </Alert>

//           </Snackbar>

//           : null}
//         {this.state.isMobile ?
//           <div style={{ paddingBottom: '10px', fontWeight: 600 }}>
//             <div style={{ paddingBottom: 8 }}>
//               <button
//                 style={{
//                   height: "45px",
//                   width: "228px",
//                   backgroundColor: 'rgb(137, 199, 74, 1)',
//                   boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                   borderWidth: '1px',
//                   borderStyle: ' solid',
//                   borderColor: 'transparent',
//                   borderImage: 'initial',
//                   overflow: 'hidden',
//                   outline: 'none',
//                   color: "white",
//                   fontSize: "16px",
//                   fontWeight: 600,
//                   border: "none",
//                   marginLeft: 44,
//                   borderBottomRightRadius: 15,
//                   borderTopRightRadius: 15
//                 }}
//                 onClick={() =>
//                   this.setState(state => ({
//                     newdep: true,
//                     treeData: [{
//                       name: null,
//                       childCategoryPresent: true
//                     }, ...this.state.treeData]
//                   }))
//                 }
//               >
//                 Add new department
//               </button>

//             </div>
//             {this.props.DepartmentList && this.props.DepartmentList.data.salesdepartments.length !== 0 && this.state.tree ?
//               (
//                 <Row>
//                   <Col md={7} sm={7} style={{ height: 800, width: '100%' }}>

//                     {/* <SortableTree
//                       treeData={this.state.treeData}
//                       onChange={treeData => this.setState({ treeData })}
//                       onMoveNode={({ node, treeIndex, path }) =>
//                         global.console.debug(
//                           "node:",
//                           node,
//                           "treeIndex:",
//                           treeIndex,
//                           "path:",
//                           path
//                         )
//                       }

//                       maxDepth={this.state.Depth}
//                       canDrag={treeData => false}
//                       canDrop={treeData => false}
//                       isVirtualized={true}
//                       generateNodeProps={({ node, path, rowInfo, treeIndex }) => ({
//                         className: `${node.className}`,
//                         title:
//                           this.state.isInput && node === this.state.nodeClicked ? (
//                             node.name

//                           ) : (
//                             node.name
//                           ),

//                         buttons: [
//                           path.length <= 3 && node.name &&
//                           <Button
//                             data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                             onClick={() => {
//                               this.setState(state => ({
//                                 newdep: false,
//                                 treeData: addNodeUnderParent({
//                                   treeData: this.state.treeData,
//                                   parentKey: path[path.length - 1],
//                                   getNodeKey: ({ treeIndex }) => treeIndex,
//                                   expandParent: true,
//                                   newNode: {
//                                     name: null,
//                                     needstitle: true,
//                                     id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
//                                   },
//                                   parent: parentKey,
//                                   addAsFirstChild: !this.state.addAsFirstChild,
//                                 }).treeData
//                               }))
//                             }
//                             }
//                           >
//                             <Icon onClick={() => {
//                               this.setState(state => ({
//                                 treeData: addNodeUnderParent({
//                                   treeData: this.state.treeData,
//                                   parentKey: path[path.length - 1],
//                                   getNodeKey: ({ treeIndex }) => treeIndex,
//                                   expandParent: true,
//                                   newNode: {
//                                     name: null,
//                                     needstitle: true,

//                                   },
//                                   parent: parentKey,
//                                   addAsFirstChild: !this.state.addAsFirstChild,
//                                 }).treeData,
//                               }))
//                             }

//                             }>add_circle</Icon>
//                           </Button >,
//                           !node.name &&
//                           <Button
//                             data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                             onClick={() => {
//                               this.setState({
//                                 treeData: removeNodeAtPath({
//                                   treeData: this.state.treeData,
//                                   path: path,   // You can use path from here
//                                   getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
//                                     // console.log(number);
//                                     return number;
//                                   },

//                                 })
//                               })
//                             }
//                             }
//                           >
//                             <DeleteIcon />
//                           </Button >
//                         ],
//                         onClick: () => {
//                           let parentNode = getNodeAtPath({
//                             treeData: this.state.treeData,
//                             path: path,
//                             getNodeKey: ({ treeIndex }) => treeIndex,
//                             ignoreCollapsed: true
//                           });
//                           let getNodeKey = ({ node: object, treeIndex: number }) => {
//                             return number;
//                           };
//                           let parentKey = getNodeKey(parentNode);
//                           this.handleNodeClick(node);
//                           this.setState((state) => ({
//                             displayRank: node.displayrank ? node.displayrank : 0,
//                             status: node.status ? node.status : "active",
//                             parentKey: parentNode,
//                             depParentid: parentKey,
//                             nodename: node.name ? node.name : ""
//                           }))

//                         },
//                         style:
//                           node === this.state.nodeClicked
//                             ? {
//                               border: "3px solid #75c224",
//                               borderBottomRightRadius: 16,
//                               borderTopRightRadius: 16
//                             }
//                             : node.status === "inactive"
//                               ? {
//                                 border: "3px solid  rgb(248, 134, 134)",
//                                 borderBottomRightRadius: 16,
//                                 borderTopRightRadius: 16
//                               } :
//                               {},
//                       })}
//                     /> */}

//                   </Col>
//                   <br />

//                   {this.state.nodeClicked ? (
//                     <Col md={5} sm={5} style={{ border: '0.15px solid #ece9e9' }} >
//                       <Form style={{ marginTop: 10 }}>
//                         {this.state.updateSuccess || this.state.success ? <Snackbar
//                           style={{ width: "100%",left:'50%',position:'absolute',zIndex:10 }}
//                           anchorOrigin={{
//                             vertical: "center",
//                             horizontal: "center"
//                           }}
//                           open={this.state.updateSuccess || this.state.success}
//                           autoHideDuration={2000}
//                           onClose={() => {
//                             this.setState({ updateSuccess: false });
//                             this.setState({ success: false })
//                           }}>
//                           <Alert severity="success">
//                             <AlertTitle>{this.state.updateSuccess ?
//                               "changes have been successfully saved" : this.state.success === true ? "Hierarchy added successfully" :
//                                 null}</AlertTitle>
//                           </Alert>
//                         </Snackbar> : null}
//                         {this.state.error || this.state.netError ?
//                           <Snackbar
//                             style={{ width: "100%",left:'50%',position:'absolute',zIndex:10 }}
//                             anchorOrigin={{
//                               vertical: "center",
//                               horizontal: "center"
//                             }}
//                             open={this.state.error || this.state.netError}
//                             autoHideDuration={2000}
//                             onClose={() => {
//                               this.setState({ error: false });
//                               this.setState({ netError: false })
//                             }}>
//                             <Alert severity="error">
//                               <AlertTitle>{this.state.errMsg}</AlertTitle>
//                             </Alert>
//                           </Snackbar>
//                           : null}

//                         <ThemeProvider theme={theme}>
//                           <Row className="col-Row">
//                             <TextField id="name"
//                               style={{ width: '100%' }}
//                               InputProps={{ style: { width: '100%', height: 35 } }}
//                               label="Name"
//                               variant="outlined"
//                               required
//                               value={this.state.nodename}
//                               // defaultValue={this.state.nodename}
//                               placeholder={'Name of  hierarchy '}
//                               onChange={(e) => {
//                                 this.setState({ nodename: e.target.value })
//                               }}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }} />
//                           </Row>
//                           <Row className="col-Row">
//                           <TextField id="name"
//                               type="number"
//                                 value={this.state.displayRank}
//                                 required
//                                 onKeyPress={(event) => {
//                                   if (event?.key === "-" || event?.key === "+") {
//                                     event.preventDefault();
//                                   }
//                                 }}

//                                 inputProps={{
//                                   autocomplete: 'new-password',

//                                   form: {
//                                     autocomplete: 'off',
//                                   },
//                                 }}
//                                 // defaultValue={this.state.displayrank}
//                                 placeholder={'Display Order'}
//                                 onChange={(e) => {
//                                   this.setState({ displayRank: e.target.value })
//                                 }}
//                                 style={{ width: '100%' }}
//                                 InputProps={{ style: { width: '100%', height: 35 } ,inputProps: { min: 1 }}}
//                                 label="Display order"
//                                 variant="outlined"
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               />
//                           </Row>
//                           <Row className="col-Row">

//                             <TextField
//                               onChange={(e) => {
//                                 this.setState({ status: e.target.value })
//                               }}
//                               value={this.state.status}
//                               defaultValue={this.state.nodeClicked.status}
//                               select
//                               style={{ width: '100%' }}
//                               variant="outlined"
//                               InputProps={{ style: { width: '100%', height: 35 } }}
//                               SelectProps={{ style: { width: '100%', height: 35 } }}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                               name="status"
//                               label="Status">
//                               <MenuItem value="active" style={{ width: '100%' }}>active</MenuItem>
//                               <MenuItem value="inactive" style={{ width: '100%' }}>inactive</MenuItem>
//                             </TextField>
//                           </Row>
//                           <Row className="col-Row">
//                             {this.state.nodeClicked.name !== null ? (
//                               <Col>
//                                 {this.state.treeData.map(n =>
//                                   n.childCategories && n.childCategories.length !== 0 ?
//                                     (
//                                       this.state.nodeClicked.id === n.id ?
//                                         <button
//                                           key={this.state.nodeClicked.id}
//                                           style={{
//                                             height: "37px",
//                                             width: "100%",
//                                             backgroundColor: 'rgb(137, 199, 74, 1)',
//                                             boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                             borderWidth: '1px',
//                                             borderStyle: ' solid',
//                                             borderColor: 'transparent',
//                                             borderImage: 'initial',
//                                             overflow: 'hidden',
//                                             outline: 'none',
//                                             color: "white",
//                                             fontSize: "16px",
//                                             fontWeight: 600,
//                                             border: "none",
//                                             borderRadius: 5
//                                           }}
//                                           type="button"
//                                           onClick={(e) => {
//                                             Update(n.id, null, true)
//                                           }}
//                                         >
//                                           {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                         </button>
//                                         :
//                                         n.childCategories.map(c =>
//                                           c.childCategories && c.childCategories.length !== 0 ?
//                                             (
//                                               this.state.nodeClicked.id === c.id ?
//                                                 <button
//                                                   key={this.state.nodeClicked.id}
//                                                   style={{
//                                                     height: "37px",
//                                                     width: "100%",
//                                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                     boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                     borderWidth: '1px',
//                                                     borderStyle: ' solid',
//                                                     borderColor: 'transparent',
//                                                     borderImage: 'initial',
//                                                     overflow: 'hidden',
//                                                     outline: 'none',
//                                                     color: "white",
//                                                     fontSize: "16px",
//                                                     fontWeight: 600,
//                                                     border: "none",
//                                                     borderRadius: 5
//                                                   }}
//                                                   type="button"
//                                                   onClick={(e) => {
//                                                     Update(c.id, null, c.childCategoryPresent)

//                                                   }}
//                                                 >
//                                                   {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                 </button>
//                                                 :

//                                                 c.childCategories.map(s =>
//                                                   s.childCategories && s.childCategories.length !== 0 ?
//                                                     (
//                                                       this.state.nodeClicked.id === s.id ?
//                                                         <button
//                                                           key={this.state.nodeClicked.id}
//                                                           style={{
//                                                             height: "37px",
//                                                             width: "100%",
//                                                             backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                             boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                             borderWidth: '1px',
//                                                             borderStyle: ' solid',
//                                                             borderColor: 'transparent',
//                                                             borderImage: 'initial',
//                                                             overflow: 'hidden',
//                                                             outline: 'none',
//                                                             color: "white",
//                                                             fontSize: "16px",
//                                                             fontWeight: 600,
//                                                             border: "none",
//                                                             borderRadius: 5
//                                                           }}
//                                                           type="button"
//                                                           onClick={(e) => {
//                                                             Update(s.id, null, s.childCategoryPresent)
//                                                           }}
//                                                         >
//                                                           {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                         </button>
//                                                         :
//                                                         s.childCategories.map(sc =>

//                                                           this.state.nodeClicked.id === sc.id &&
//                                                           <button
//                                                             key={this.state.nodeClicked.id}
//                                                             style={{
//                                                               height: "37px",
//                                                               width: "100%",
//                                                               backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                               boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                               borderWidth: '1px',
//                                                               borderStyle: ' solid',
//                                                               borderColor: 'transparent',
//                                                               borderImage: 'initial',
//                                                               overflow: 'hidden',
//                                                               outline: 'none',
//                                                               color: "white",
//                                                               fontSize: "16px",
//                                                               fontWeight: 600,
//                                                               border: "none",
//                                                               borderRadius: 5
//                                                             }}
//                                                             type="button"
//                                                             onClick={(e) => {
//                                                               Update(sc.id, null, sc.childCategoryPresent)
//                                                             }}
//                                                           >
//                                                             {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                           </button>
//                                                         )
//                                                     )
//                                                     :
//                                                     (
//                                                       this.state.nodeClicked.id === s.id &&
//                                                       <button
//                                                         key={this.state.nodeClicked.id}
//                                                         style={{
//                                                           height: "37px",
//                                                           width: "100%",
//                                                           backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                           boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                           borderWidth: '1px',
//                                                           borderStyle: ' solid',
//                                                           borderColor: 'transparent',
//                                                           borderImage: 'initial',
//                                                           overflow: 'hidden',
//                                                           outline: 'none',
//                                                           color: "white",
//                                                           fontSize: "16px",
//                                                           fontWeight: 600,
//                                                           border: "none",
//                                                           borderRadius: 5
//                                                         }}
//                                                         type="button"
//                                                         onClick={(e) => {
//                                                           Update(s.id, null, s.childCategoryPresent)
//                                                         }}
//                                                       >
//                                                         {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                       </button>
//                                                     )
//                                                 )

//                                             )
//                                             :
//                                             (
//                                               this.state.nodeClicked.id === c.id &&
//                                               <button
//                                                 key={this.state.nodeClicked.id}
//                                                 style={{
//                                                   height: "37px",
//                                                   width: "100%",
//                                                   backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                   boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                   borderWidth: '1px',
//                                                   borderStyle: ' solid',
//                                                   borderColor: 'transparent',
//                                                   borderImage: 'initial',
//                                                   overflow: 'hidden',
//                                                   outline: 'none',
//                                                   color: "white",
//                                                   fontSize: "16px",
//                                                   fontWeight: 600,
//                                                   border: "none",
//                                                   borderRadius: 5
//                                                 }}
//                                                 type="button"
//                                                 onClick={(e) => {
//                                                   Update(c.id, null, c.childCategoryPresent)
//                                                 }}
//                                               >
//                                                 {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                               </button>
//                                             )
//                                         ))
//                                     :
//                                     (
//                                       this.state.nodeClicked.id === n.id &&
//                                       <button
//                                         key={this.state.nodeClicked.id}
//                                         style={{
//                                           height: "37px",
//                                           width: "100%",
//                                           backgroundColor: 'rgb(137, 199, 74, 1)',
//                                           boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                           borderWidth: '1px',
//                                           borderStyle: ' solid',
//                                           borderColor: 'transparent',
//                                           borderImage: 'initial',
//                                           overflow: 'hidden',
//                                           outline: 'none',
//                                           color: "white",
//                                           fontSize: "16px",
//                                           fontWeight: 600,
//                                           border: "none",
//                                           borderRadius: 5
//                                         }}
//                                         type="button"
//                                         onClick={(e) => {
//                                           Update(n.id, null, false)
//                                         }}
//                                       >
//                                         {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                       </button>
//                                     )
//                                 )}
//                               </Col>
//                             ) : (

//                               this.state.newdep === true
//                                 ?
//                                 (
//                                   <Col >
//                                     <button
//                                       key={this.state.nodeClicked.id}
//                                       style={{
//                                         height: "37px",
//                                         width: "100%",
//                                         backgroundColor: 'rgb(137, 199, 74, 1)',
//                                         boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                         borderWidth: '1px',
//                                         borderStyle: ' solid',
//                                         borderColor: 'transparent',
//                                         borderImage: 'initial',
//                                         overflow: 'hidden',
//                                         outline: 'none',
//                                         color: "white",
//                                         fontSize: "16px",
//                                         fontWeight: 600,
//                                         border: "none",
//                                         borderRadius: 5
//                                       }}
//                                       type="button"
//                                       onClick={(e) => {
//                                         Add()

//                                       }}
//                                     >
//                                       {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
//                                     </button>
//                                   </Col>
//                                 )
//                                 :
//                                 (
//                                   <Col >

//                                     <button
//                                       key={this.state.nodeClicked.id}
//                                       style={{
//                                         height: "37px",
//                                         width: "100%",
//                                         backgroundColor: 'rgb(137, 199, 74, 1)',
//                                         boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                         borderWidth: '1px',
//                                         borderStyle: ' solid',
//                                         borderColor: 'transparent',
//                                         borderImage: 'initial',
//                                         overflow: 'hidden',
//                                         outline: 'none',
//                                         color: "white",
//                                         fontSize: "16px",
//                                         fontWeight: 600,
//                                         border: "none",
//                                         borderRadius: 5
//                                       }}
//                                       type="button"
//                                       onClick={() => {
//                                         AddHeirarchy(this.state.nodeClicked.id, this.state.nodeClicked.name)

//                                       }}>
//                                       {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
//                                     </button>

//                                   </Col>
//                                 )

//                             )}

//                           </Row>
//                           <Row className="col-Row">
//                             <h5 style={{ fontWeight: 600 }}>
//                               Note :
//                             </h5>
//                             <p>1. Category with low display order will be shown on the top.</p>
//                             <p>2. Categories with active status  will be displayed in the customer site.</p>
//                           </Row>
//                         </ThemeProvider>
//                       </Form>
//                     </Col>)
//                     :
//                     (null)}
//                 </Row>
//               )
//               : (
//                 this.props.DepartmentList && this.props.DepartmentList.data.salesdepartments.length === 0 ?
//                   (
//                     <>
//                       <Row>
//                         {/* <h6>

//    </h6>
//              </Row>
//              <Row>
//                <h6>

//  </h6>
//              </Row>
//              <Row>
//                <h5>

// </h5> */}
//                         <Card>
//                           <CardContent>
//                             <Typography sx={{ fontSize: 14 }} color="black" gutterBottom>
//                               You can organize your products by creating various levels.
//                             </Typography>
//                             <Typography sx={{ fontSize: 16 }} color="black" gutterBottom component="div">
//                               You need atleast one hierarchy to create your products.
//                             </Typography>
//                             <Typography sx={{ fontSize: 16 }} color="black" gutterBottom component="div">
//                               Click on the button above to create your first department .
//                             </Typography>
//                           </CardContent>
//                         </Card>
//                       </Row>
//                       <Row>
//                         <Col md={7} sm={7} style={{ height: 800, width: '100%' }}>
//                           {/* <SortableTree
//                             treeData={this.state.treeData}
//                             onChange={treeData => this.setState({ treeData })}
//                             onMoveNode={({ node, treeIndex, path }) =>
//                               global.console.debug(
//                                 "node:",
//                                 node,
//                                 "treeIndex:",
//                                 treeIndex,
//                                 "path:",
//                                 path
//                               )
//                             }

//                             maxDepth={this.state.Depth}
//                             canDrag={treeData => false}
//                             canDrop={treeData => false}
//                             isVirtualized={true}
//                             generateNodeProps={({ node, path, rowInfo, treeIndex }) => ({
//                               className: `${node.className}`,
//                               title:
//                                 this.state.isInput && node === this.state.nodeClicked ? (
//                                   node.name

//                                 ) : (
//                                   node.name
//                                 ),

//                               buttons: [
//                                 path.length <= 3 && node.name &&
//                                 <Button
//                                   data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                                   onClick={() => {
//                                     this.setState(state => ({
//                                       newdep: false,
//                                       treeData: addNodeUnderParent({
//                                         treeData: this.state.treeData,
//                                         parentKey: path[path.length - 1],
//                                         getNodeKey: ({ treeIndex }) => treeIndex,
//                                         expandParent: true,
//                                         newNode: {
//                                           name: null,
//                                           needstitle: true,
//                                           id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
//                                         },
//                                         parent: parentKey,
//                                         addAsFirstChild: !this.state.addAsFirstChild,
//                                       }).treeData,
//                                     }))
//                                   }
//                                   }
//                                 >
//                                   <Icon
//                                     onClick={() => {
//                                       this.setState(state => ({
//                                         treeData: addNodeUnderParent({
//                                           treeData: this.state.treeData,
//                                           parentKey: path[path.length - 1],
//                                           getNodeKey: ({ treeIndex }) => treeIndex,
//                                           expandParent: true,
//                                           newNode: {
//                                             name: null,
//                                             needstitle: true,

//                                           },
//                                           parent: parentKey,
//                                           addAsFirstChild: !this.state.addAsFirstChild,
//                                         }).treeData,
//                                       }))
//                                     }
//                                     }>add_circle</Icon>
//                                 </Button >,
//                                 !node.name &&
//                                 <Button
//                                   data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                                   onClick={() => {
//                                     this.setState({
//                                       treeData: removeNodeAtPath({
//                                         treeData: this.state.treeData,
//                                         path: path,   // You can use path from here
//                                         getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
//                                           // console.log(number);
//                                           return number;
//                                         },

//                                       })
//                                     })
//                                   }
//                                   }
//                                 >
//                                   <DeleteIcon />
//                                 </Button >
//                               ],
//                               onClick: () => {
//                                 let parentNode = getNodeAtPath({
//                                   treeData: this.state.treeData,
//                                   path: path,
//                                   getNodeKey: ({ treeIndex }) => treeIndex,
//                                   ignoreCollapsed: true
//                                 });
//                                 let getNodeKey = ({ node: object, treeIndex: number }) => {
//                                   return number;
//                                 };
//                                 let parentKey = getNodeKey(parentNode);
//                                 this.handleNodeClick(node);
//                                 this.setState((state) => ({
//                                   displayRank: node.displayrank ? node.displayrank : 0,
//                                   status: node.status ? node.status : "active",
//                                   parentKey: parentNode,
//                                   depParentid: parentKey,
//                                   nodename: node.name ? node.name : ""
//                                 }))

//                               },
//                               style:
//                                 node === this.state.nodeClicked
//                                   ? {
//                                     border: "3px solid #75c224",
//                                     borderBottomRightRadius: 16,
//                                     borderTopRightRadius: 16
//                                   }
//                                   : node.status === "inactive"
//                                     ? {
//                                       border: "3px solid  rgb(248, 134, 134)",
//                                       borderBottomRightRadius: 16,
//                                       borderTopRightRadius: 16
//                                     } :
//                                     {},
//                             })}
//                           /> */}

//                         </Col>
//                         <br />

//                         {this.state.nodeClicked ? (
//                           <Col md={5} sm={5} style={{ border: '0.15px solid #ece9e9' }} >
//                             <Form style={{ marginTop: 10 }}>
//                               <ThemeProvider theme={theme}>
//                                 <Row className="col-Row">
//                                   <TextField id="name"
//                                     style={{ width: '100%' }}
//                                     InputProps={{ style: { width: '100%', height: 35 } }}
//                                     label="Name"
//                                     variant="outlined"
//                                     value={this.state.nodename}
//                                     // defaultValue={this.state.nodename}
//                                     placeholder={'Name of the hierarchy '}
//                                     onChange={(e) => {
//                                       this.setState({ nodename: e.target.value })
//                                     }}
//                                     InputLabelProps={{
//                                       shrink: true,
//                                     }} />
//                                 </Row>
//                                 <Row className="col-Row">
//                                 <TextField id="name"
//                               type="number"
//                                 value={this.state.displayRank}
//                                 required
//                                 onKeyPress={(event) => {
//                                   if (event?.key === "-" || event?.key === "+") {
//                                     event.preventDefault();
//                                   }
//                                 }}
//                                 // defaultValue={this.state.displayrank}
//                                 placeholder={'Display Order'}
//                                 onChange={(e) => {
//                                   this.setState({ displayRank: e.target.value })
//                                 }}
//                                 inputProps={{
//                                   autocomplete: 'new-password',
//                                   form: {
//                                     autocomplete: 'off',
//                                   },
//                                 }}
//                                 style={{ width: '100%' }}
//                                 InputProps={{ style: { width: '100%', height: 35 },inputProps: { min: 1 } }}
//                                 label="Display order"
//                                 variant="outlined"
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               />
//                                 </Row>
//                                 <Row className="col-Row">
//                                   <TextField
//                                     defaultValue={this.state.nodeClicked.status}
//                                     select
//                                     style={{ width: '100%' }}
//                                     variant="outlined"
//                                     InputProps={{ style: { width: '100%', height: 35 } }}
//                                     SelectProps={{ style: { width: '100%', height: 35 } }}
//                                     InputLabelProps={{
//                                       shrink: true,
//                                     }}

//                                     value={this.state.status}
//                                     name="status"
//                                     label="Status">
//                                     <MenuItem onChange={(e) => {
//                                       this.setState({ status: e.target.value })
//                                       console.log(this.state.status)
//                                     }} value="active" style={{ width: '100%' }}>active</MenuItem>
//                                     <MenuItem
//                                       onChange={(e) => {
//                                         this.setState({ status: e.target.value })
//                                         console.log(this.state.status)
//                                       }}
//                                       value="inactive" style={{ width: '100%' }}>inactive</MenuItem>
//                                   </TextField>
//                                 </Row>
//                                 <Row className="col-Row">
//                                   {this.state.updateSuccess || this.state.success ? <Snackbar
//                                      style={{ width: "auto", left: "50%",position:'absolute',zIndex:10 }}
//                                      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
//                                     open={this.state.updateSuccess || this.state.success}
//                                     autoHideDuration={2000}
//                                     onClose={() => {
//                                       this.setState({ updateSuccess: false });
//                                       this.setState({ success: false })
//                                     }}>
//                                     <Alert severity="success">
//                                       <AlertTitle>{this.state.updateSuccess ?
//                                         "changes have been successfully saved" : this.state.success === true ? "Hierarchy added successfully" :
//                                           null}</AlertTitle>
//                                     </Alert>
//                                   </Snackbar> : null}
//                                   {this.state.error || this.state.netError ?
//                                     <Snackbar
//                                     style={{ width: "auto", left: "50%",position:'absolute',zIndex:10 }}
//                                     anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
//                                       open={this.state.error || this.state.netError}
//                                       autoHideDuration={2000}
//                                       onClose={() => {
//                                         this.setState({ error: false });
//                                         this.setState({ netError: false })
//                                       }}>
//                                       <Alert severity="error">
//                                         <AlertTitle>{this.state.errMsg}</AlertTitle>
//                                       </Alert>
//                                     </Snackbar>
//                                     : null}
//                                   {this.state.nodeClicked.name !== null ? (
//                                     <Col>
//                                       {this.state.treeData.map(n =>
//                                         n.childCategories ?
//                                           (
//                                             this.state.nodeClicked.id === n.id ?
//                                               <button
//                                                 key={this.state.nodeClicked.id}
//                                                 style={{
//                                                   height: "37px",
//                                                   width: "100%",
//                                                   backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                   boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                   borderWidth: '1px',
//                                                   borderStyle: ' solid',
//                                                   borderColor: 'transparent',
//                                                   borderImage: 'initial',
//                                                   overflow: 'hidden',
//                                                   outline: 'none',
//                                                   color: "white",
//                                                   fontSize: "16px",
//                                                   fontWeight: 600,
//                                                   border: "none",
//                                                   borderRadius: 5
//                                                 }}
//                                                 type="button"
//                                                 onClick={(e) => {
//                                                   Update(n.id, null, true)
//                                                 }}
//                                               >
//                                                 {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                               </button>
//                                               :
//                                               n.childCategories.map(c =>
//                                                 c.childCategories ?
//                                                   (
//                                                     this.state.nodeClicked.id === c.id ?
//                                                       <button
//                                                         key={this.state.nodeClicked.id}
//                                                         style={{
//                                                           height: "37px",
//                                                           width: "100%",
//                                                           backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                           boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                           borderWidth: '1px',
//                                                           borderStyle: ' solid',
//                                                           borderColor: 'transparent',
//                                                           borderImage: 'initial',
//                                                           overflow: 'hidden',
//                                                           outline: 'none',
//                                                           color: "white",
//                                                           fontSize: "16px",
//                                                           fontWeight: 600,
//                                                           border: "none",
//                                                           borderRadius: 5
//                                                         }}
//                                                         type="button"
//                                                         onClick={(e) => {
//                                                           Update(c.id, null, c.childCategoryPresent)
//                                                         }}
//                                                       >
//                                                         {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                       </button>
//                                                       :

//                                                       c.childCategories.map(s =>
//                                                         s.childCategories ?
//                                                           (
//                                                             this.state.nodeClicked.id === s.id ?
//                                                               <button
//                                                                 key={this.state.nodeClicked.id}
//                                                                 style={{
//                                                                   height: "37px",
//                                                                   width: "100%",
//                                                                   backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                                   boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                                   borderWidth: '1px',
//                                                                   borderStyle: ' solid',
//                                                                   borderColor: 'transparent',
//                                                                   borderImage: 'initial',
//                                                                   overflow: 'hidden',
//                                                                   outline: 'none',
//                                                                   color: "white",
//                                                                   fontSize: "16px",
//                                                                   fontWeight: 600,
//                                                                   border: "none",
//                                                                   borderRadius: 5
//                                                                 }}
//                                                                 type="button"
//                                                                 onClick={(e) => {
//                                                                   Update(s.id, null, s.childCategoryPresent)
//                                                                 }}
//                                                               >
//                                                                 {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                               </button>
//                                                               :
//                                                               s.childCategories.map(sc =>

//                                                                 this.state.nodeClicked.id === sc.id &&
//                                                                 <button
//                                                                   key={this.state.nodeClicked.id}
//                                                                   style={{
//                                                                     height: "37px",
//                                                                     width: "100%",
//                                                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                                     boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                                     borderWidth: '1px',
//                                                                     borderStyle: ' solid',
//                                                                     borderColor: 'transparent',
//                                                                     borderImage: 'initial',
//                                                                     overflow: 'hidden',
//                                                                     outline: 'none',
//                                                                     color: "white",
//                                                                     fontSize: "16px",
//                                                                     fontWeight: 600,
//                                                                     border: "none",
//                                                                     borderRadius: 5
//                                                                   }}
//                                                                   type="button"
//                                                                   onClick={(e) => {
//                                                                     Update(sc.id, null, sc.childCategoryPresent)
//                                                                   }}
//                                                                 >
//                                                                   {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                                 </button>
//                                                               )
//                                                           )
//                                                           :
//                                                           (
//                                                             this.state.nodeClicked.id === s.id &&
//                                                             <button
//                                                               key={this.state.nodeClicked.id}
//                                                               style={{
//                                                                 height: "37px",
//                                                                 width: "100%",
//                                                                 backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                                 boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                                 borderWidth: '1px',
//                                                                 borderStyle: ' solid',
//                                                                 borderColor: 'transparent',
//                                                                 borderImage: 'initial',
//                                                                 overflow: 'hidden',
//                                                                 outline: 'none',
//                                                                 color: "white",
//                                                                 fontSize: "16px",
//                                                                 fontWeight: 600,
//                                                                 border: "none",
//                                                                 borderRadius: 5
//                                                               }}
//                                                               type="button"
//                                                               onClick={(e) => {
//                                                                 Update(s.id, null, s.childCategoryPresent)
//                                                               }}
//                                                             >
//                                                               {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                             </button>
//                                                           )
//                                                       )

//                                                   )
//                                                   :
//                                                   (
//                                                     this.state.nodeClicked.id === c.id &&
//                                                     <button
//                                                       key={this.state.nodeClicked.id}
//                                                       style={{
//                                                         height: "37px",
//                                                         width: "100%",
//                                                         backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                         boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                         borderWidth: '1px',
//                                                         borderStyle: ' solid',
//                                                         borderColor: 'transparent',
//                                                         borderImage: 'initial',
//                                                         overflow: 'hidden',
//                                                         outline: 'none',
//                                                         color: "white",
//                                                         fontSize: "16px",
//                                                         fontWeight: 600,
//                                                         border: "none",
//                                                         borderRadius: 5
//                                                       }}
//                                                       type="button"
//                                                       onClick={(e) => {
//                                                         Update(c.id, null, c.childCategoryPresent)
//                                                       }}
//                                                     >
//                                                       {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                     </button>
//                                                   )
//                                               ))
//                                           :
//                                           (
//                                             this.state.nodeClicked.id === n.id &&
//                                             <button
//                                               key={this.state.nodeClicked.id}
//                                               style={{
//                                                 height: "37px",
//                                                 width: "100%",
//                                                 backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                 boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                 borderWidth: '1px',
//                                                 borderStyle: ' solid',
//                                                 borderColor: 'transparent',
//                                                 borderImage: 'initial',
//                                                 overflow: 'hidden',
//                                                 outline: 'none',
//                                                 color: "white",
//                                                 fontSize: "16px",
//                                                 fontWeight: 600,
//                                                 border: "none",
//                                                 borderRadius: 5
//                                               }}
//                                               type="button"
//                                               onClick={(e) => {
//                                                 Update(n.id, null, false)
//                                               }}
//                                             >
//                                               {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                             </button>
//                                           )
//                                       )}
//                                     </Col>
//                                   ) : (

//                                     this.state.newdep === true
//                                       ?
//                                       (
//                                         <Col >
//                                           <button
//                                             key={this.state.nodeClicked.id}
//                                             style={{
//                                               height: "37px",
//                                               width: "100%",
//                                               backgroundColor: 'rgb(137, 199, 74, 1)',
//                                               boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                               borderWidth: '1px',
//                                               borderStyle: ' solid',
//                                               borderColor: 'transparent',
//                                               borderImage: 'initial',
//                                               overflow: 'hidden',
//                                               outline: 'none',
//                                               color: "white",
//                                               fontSize: "16px",
//                                               fontWeight: 600,
//                                               border: "none",
//                                               borderRadius: 5
//                                             }}
//                                             type="button"
//                                             onClick={(e) => {
//                                               Add1()

//                                             }}
//                                           >
//                                             {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Add') : ('Adding')}
//                                           </button>
//                                         </Col>
//                                       )
//                                       :
//                                       (

//                                         <Col >

//                                           <button
//                                             key={this.state.nodeClicked.id}
//                                             style={{
//                                               height: "37px",
//                                               width: "100%",
//                                               backgroundColor: 'rgb(137, 199, 74, 1)',
//                                               boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                               borderWidth: '1px',
//                                               borderStyle: ' solid',
//                                               borderColor: 'transparent',
//                                               borderImage: 'initial',
//                                               overflow: 'hidden',
//                                               outline: 'none',
//                                               color: "white",
//                                               fontSize: "16px",
//                                               fontWeight: 600,
//                                               border: "none",
//                                               borderRadius: 5
//                                             }}
//                                             type="button"
//                                             onClick={() => {
//                                               AddHeirarchy(this.state.nodeClicked.id, this.state.nodeClicked.name)

//                                             }}>
//                                             {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
//                                           </button>

//                                         </Col>
//                                       )

//                                   )}

//                                 </Row>
//                                 <Row className="col-Row">
//                                   <h5 style={{ fontWeight: 600 }}>
//                                     Note :
//                                   </h5>
//                                   <p>1. Category with low display order will be shown on the top.</p>
//                                   <p>2. Categories with active status  will be displayed in the customer site.</p>
//                                 </Row>
//                               </ThemeProvider>
//                             </Form>
//                           </Col>)
//                           :
//                           (null)}
//                       </Row>
//                     </>
//                   ) :
//                   (
//                     <Row style={{ marginLeft: 70 }}>
//                       <Spinner size={40} animation="border" variant="success" />
//                     </Row>
//                   )
//               )

//             }
//           </div>

//           :

//           <Container maxWidth='md' style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             Top: "40px",
//             paddingTop: "30px",
//             paddingBottom: "10px"
//           }}>
//             <div style={{ paddingBottom: '10px', fontWeight: 600 }}>
//               <div style={{ paddingBottom: 8 }}>
//                 <button
//                   style={{
//                     height: "45px",
//                     width: "228px",
//                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                     boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                     borderWidth: '1px',
//                     borderStyle: ' solid',
//                     borderColor: 'transparent',
//                     borderImage: 'initial',
//                     overflow: 'hidden',
//                     outline: 'none',
//                     color: "white",
//                     fontSize: "16px",
//                     fontWeight: 600,
//                     border: "none",
//                     marginLeft: 44,
//                     borderBottomRightRadius: 15,
//                     borderTopRightRadius: 15
//                   }}
//                   onClick={() =>
//                     this.setState(state => ({
//                       newdep: true,
//                       treeData: [{
//                         name: null,
//                         childCategoryPresent: true
//                       }, ...this.state.treeData]
//                     }))
//                   }
//                 >
//                   Add new department
//                 </button>

//               </div>
//               {this.props.DepartmentList && this.props.DepartmentList.data.salesdepartments.length !== 0 && this.state.tree ?
//                 (
//                   <Row>
//                     <Col md={7} sm={7} style={{ height: 800, width: '100%' }}>

//                       {/* <SortableTree
//                         treeData={this.state.treeData}
//                         onChange={treeData => this.setState({ treeData })}
//                         onMoveNode={({ node, treeIndex, path }) =>
//                           global.console.debug(
//                             "node:",
//                             node,
//                             "treeIndex:",
//                             treeIndex,
//                             "path:",
//                             path
//                           )
//                         }

//                         maxDepth={this.state.Depth}
//                         canDrag={treeData => false}
//                         canDrop={treeData => false}
//                         isVirtualized={true}
//                         generateNodeProps={({ node, path, rowInfo, treeIndex }) => ({
//                           className: `${node.className}`,
//                           title:
//                             this.state.isInput && node === this.state.nodeClicked ? (
//                               node.name

//                             ) : (
//                               node.name
//                             ),

//                           buttons: [
//                             path.length <= 3 && node.name &&
//                             <Button
//                               data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                               onClick={() => {
//                                 this.setState(state => ({
//                                   newdep: false,
//                                   treeData: addNodeUnderParent({
//                                     treeData: this.state.treeData,
//                                     parentKey: path[path.length - 1],
//                                     getNodeKey: ({ treeIndex }) => treeIndex,
//                                     expandParent: true,
//                                     newNode: {
//                                       name: null,
//                                       needstitle: true,
//                                       id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
//                                     },
//                                     parent: parentKey,
//                                     addAsFirstChild: !this.state.addAsFirstChild,
//                                   }).treeData
//                                 }))
//                               }
//                               }
//                             >
//                               <Icon onClick={() => {
//                                 this.setState(state => ({
//                                   treeData: addNodeUnderParent({
//                                     treeData: this.state.treeData,
//                                     parentKey: path[path.length - 1],
//                                     getNodeKey: ({ treeIndex }) => treeIndex,
//                                     expandParent: true,
//                                     newNode: {
//                                       name: null,
//                                       needstitle: true,
//                                       id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
//                                     },
//                                     parent: parentKey,
//                                     addAsFirstChild: !this.state.addAsFirstChild,
//                                   }).treeData,
//                                 }))
//                               }

//                               }>add_circle</Icon>
//                             </Button >,
//                             !node.name &&
//                             <Button
//                               data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                               onClick={() => {
//                                 this.setState({
//                                   treeData: removeNodeAtPath({
//                                     treeData: this.state.treeData,
//                                     path: path,   // You can use path from here
//                                     getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
//                                       // console.log(number);
//                                       return number;
//                                     },

//                                   })
//                                 })
//                               }
//                               }
//                             >
//                               <DeleteIcon />
//                             </Button >
//                           ],
//                           onClick: () => {
//                             let parentNode = getNodeAtPath({
//                               treeData: this.state.treeData,
//                               path: path,
//                               getNodeKey: ({ treeIndex }) => treeIndex,
//                               ignoreCollapsed: true
//                             });
//                             let getNodeKey = ({ node: object, treeIndex: number }) => {
//                               return number;
//                             };
//                             let parentKey = getNodeKey(parentNode);
//                             this.handleNodeClick(node);
//                             this.setState((state) => ({
//                               displayRank: node.displayrank ? node.displayrank : 0,
//                               status: node.status ? node.status : "active",
//                               parentKey: parentNode,
//                               depParentid: parentKey,
//                               nodename: node.name ? node.name : ""
//                             }))

//                           },
//                           style:
//                             node === this.state.nodeClicked
//                               ? {
//                                 border: "3px solid #75c224",
//                                 borderBottomRightRadius: 16,
//                                 borderTopRightRadius: 16
//                               }
//                               : node.status === "inactive"
//                                 ? {
//                                   border: "3px solid  rgb(248, 134, 134)",
//                                   borderBottomRightRadius: 16,
//                                   borderTopRightRadius: 16
//                                 } :
//                                 {},
//                         })}
//                       /> */}

//                     </Col>
//                     <br />

//                     {this.state.nodeClicked ? (
//                       <Col md={5} sm={5} style={{ border: '0.15px solid #ece9e9' }} >
//                         <Form style={{ marginTop: 10 }}>
//                           {this.state.updateSuccess || this.state.success ? <Snackbar
//                             style={{ width: "auto", left: "50%",position:'absolute',zIndex:10 }}
//                             anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
//                             open={this.state.updateSuccess || this.state.success}
//                             autoHideDuration={2000}
//                             onClose={() => {
//                               this.setState({ updateSuccess: false });
//                               this.setState({ success: false })
//                             }}>
//                             <Alert severity="success">
//                               <AlertTitle>{this.state.updateSuccess ?
//                                 "changes have been successfully saved" : this.state.success === true ? "Hierarchy added successfully" :
//                                   null}</AlertTitle>
//                             </Alert>
//                           </Snackbar> : null}
//                           {this.state.error || this.state.netError ?
//                             <Snackbar
//                               style={{ width: "auto",left:'50%',position:'absolute',zIndex:10 }}
//                               anchorOrigin={{
//                                 vertical: "center",
//                                 horizontal: "center"
//                               }}
//                               open={this.state.error || this.state.netError}
//                               autoHideDuration={2000}
//                               onClose={() => {
//                                 this.setState({ error: false });
//                                 this.setState({ netError: false })
//                               }}>
//                               <Alert severity="error">
//                                 <AlertTitle>{this.state.errMsg}</AlertTitle>
//                               </Alert>
//                             </Snackbar>
//                             : null}

//                           <ThemeProvider theme={theme}>
//                             <Row className="col-Row">
//                               <TextField id="name"
//                                 style={{ width: '100%' }}
//                                 InputProps={{ style: { width: '100%', height: 35 } }}
//                                 label="Name"
//                                 variant="outlined"
//                                 required
//                                 value={this.state.nodename}
//                                 // defaultValue={this.state.nodename}
//                                 placeholder={'Name of  hierarchy '}
//                                 onChange={(e) => {
//                                   this.setState({ nodename: e.target.value })
//                                 }}
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }} />
//                             </Row>
//                             <Row className="col-Row">
//                               <TextField id="name"
//                               type="number"
//                                 value={this.state.displayRank}
//                                 required
//                                 onKeyPress={(event) => {
//                                   if (event?.key === "-" || event?.key === "+") {
//                                     event.preventDefault();
//                                   }
//                                 }}
//                                 // defaultValue={this.state.displayrank}
//                                 placeholder={'Display Order'}
//                                 onChange={(e) => {
//                                   this.setState({ displayRank: e.target.value })
//                                 }}
//                                 inputProps={{
//                                   autocomplete: 'new-password',
//                                   form: {
//                                     autocomplete: 'off',
//                                   },
//                                 }}
//                                 style={{ width: '100%' }}
//                                 InputProps={{ style: { width: '100%', height: 35 },inputProps: { min: 1 } }}
//                                 label="Display order"
//                                 variant="outlined"
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               />
//                             </Row>
//                             <Row className="col-Row">

//                               <TextField
//                                 onChange={(e) => {
//                                   this.setState({ status: e.target.value })
//                                 }}
//                                 value={this.state.status}
//                                 defaultValue={this.state.nodeClicked.status}
//                                 select
//                                 style={{ width: '100%' }}
//                                 variant="outlined"
//                                 InputProps={{ style: { width: '100%', height: 35 } }}
//                                 SelectProps={{ style: { width: '100%', height: 35 } }}
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                                 name="status"
//                                 label="Status">
//                                 <MenuItem value="active" style={{ width: '100%' }}>active</MenuItem>
//                                 <MenuItem value="inactive" style={{ width: '100%' }}>inactive</MenuItem>
//                               </TextField>
//                             </Row>
//                             <Row className="col-Row">
//                               {this.state.nodeClicked.name !== null ? (
//                                 <Col>
//                                   {this.state.treeData.map(n =>
//                                     n.childCategories && n.childCategories.length !== 0 ?
//                                       (
//                                         this.state.nodeClicked.id === n.id ?
//                                           <button
//                                             key={this.state.nodeClicked.id}
//                                             style={{
//                                               height: "37px",
//                                               width: "100%",
//                                               backgroundColor: 'rgb(137, 199, 74, 1)',
//                                               boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                               borderWidth: '1px',
//                                               borderStyle: ' solid',
//                                               borderColor: 'transparent',
//                                               borderImage: 'initial',
//                                               overflow: 'hidden',
//                                               outline: 'none',
//                                               color: "white",
//                                               fontSize: "16px",
//                                               fontWeight: 600,
//                                               border: "none",
//                                               borderRadius: 5
//                                             }}
//                                             type="button"
//                                             onClick={(e) => {
//                                               Update(n.id, null, true)
//                                             }}
//                                           >
//                                             {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                           </button>
//                                           :
//                                           n.childCategories.map(c =>
//                                             c.childCategories && c.childCategories.length !== 0 ?
//                                               (
//                                                 this.state.nodeClicked.id === c.id ?
//                                                   <button
//                                                     key={this.state.nodeClicked.id}
//                                                     style={{
//                                                       height: "37px",
//                                                       width: "100%",
//                                                       backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                       boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                       borderWidth: '1px',
//                                                       borderStyle: ' solid',
//                                                       borderColor: 'transparent',
//                                                       borderImage: 'initial',
//                                                       overflow: 'hidden',
//                                                       outline: 'none',
//                                                       color: "white",
//                                                       fontSize: "16px",
//                                                       fontWeight: 600,
//                                                       border: "none",
//                                                       borderRadius: 5
//                                                     }}
//                                                     type="button"
//                                                     onClick={(e) => {
//                                                       Update(c.id, null, c.childCategoryPresent)

//                                                     }}
//                                                   >
//                                                     {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                   </button>
//                                                   :

//                                                   c.childCategories.map(s =>
//                                                     s.childCategories && s.childCategories.length !== 0 ?
//                                                       (
//                                                         this.state.nodeClicked.id === s.id ?
//                                                           <button
//                                                             key={this.state.nodeClicked.id}
//                                                             style={{
//                                                               height: "37px",
//                                                               width: "100%",
//                                                               backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                               boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                               borderWidth: '1px',
//                                                               borderStyle: ' solid',
//                                                               borderColor: 'transparent',
//                                                               borderImage: 'initial',
//                                                               overflow: 'hidden',
//                                                               outline: 'none',
//                                                               color: "white",
//                                                               fontSize: "16px",
//                                                               fontWeight: 600,
//                                                               border: "none",
//                                                               borderRadius: 5
//                                                             }}
//                                                             type="button"
//                                                             onClick={(e) => {
//                                                               Update(s.id, null, s.childCategoryPresent)
//                                                             }}
//                                                           >
//                                                             {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                           </button>
//                                                           :
//                                                           s.childCategories.map(sc =>

//                                                             this.state.nodeClicked.id === sc.id &&
//                                                             <button
//                                                               key={this.state.nodeClicked.id}
//                                                               style={{
//                                                                 height: "37px",
//                                                                 width: "100%",
//                                                                 backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                                 boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                                 borderWidth: '1px',
//                                                                 borderStyle: ' solid',
//                                                                 borderColor: 'transparent',
//                                                                 borderImage: 'initial',
//                                                                 overflow: 'hidden',
//                                                                 outline: 'none',
//                                                                 color: "white",
//                                                                 fontSize: "16px",
//                                                                 fontWeight: 600,
//                                                                 border: "none",
//                                                                 borderRadius: 5
//                                                               }}
//                                                               type="button"
//                                                               onClick={(e) => {
//                                                                 Update(sc.id, null, sc.childCategoryPresent)
//                                                               }}
//                                                             >
//                                                               {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                             </button>
//                                                           )
//                                                       )
//                                                       :
//                                                       (
//                                                         this.state.nodeClicked.id === s.id &&
//                                                         <button
//                                                           key={this.state.nodeClicked.id}
//                                                           style={{
//                                                             height: "37px",
//                                                             width: "100%",
//                                                             backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                             boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                             borderWidth: '1px',
//                                                             borderStyle: ' solid',
//                                                             borderColor: 'transparent',
//                                                             borderImage: 'initial',
//                                                             overflow: 'hidden',
//                                                             outline: 'none',
//                                                             color: "white",
//                                                             fontSize: "16px",
//                                                             fontWeight: 600,
//                                                             border: "none",
//                                                             borderRadius: 5
//                                                           }}
//                                                           type="button"
//                                                           onClick={(e) => {
//                                                             Update(s.id, null, s.childCategoryPresent)
//                                                           }}
//                                                         >
//                                                           {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                         </button>
//                                                       )
//                                                   )

//                                               )
//                                               :
//                                               (
//                                                 this.state.nodeClicked.id === c.id &&
//                                                 <button
//                                                   key={this.state.nodeClicked.id}
//                                                   style={{
//                                                     height: "37px",
//                                                     width: "100%",
//                                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                     boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                     borderWidth: '1px',
//                                                     borderStyle: ' solid',
//                                                     borderColor: 'transparent',
//                                                     borderImage: 'initial',
//                                                     overflow: 'hidden',
//                                                     outline: 'none',
//                                                     color: "white",
//                                                     fontSize: "16px",
//                                                     fontWeight: 600,
//                                                     border: "none",
//                                                     borderRadius: 5
//                                                   }}
//                                                   type="button"
//                                                   onClick={(e) => {
//                                                     Update(c.id, null, c.childCategoryPresent)
//                                                   }}
//                                                 >
//                                                   {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                 </button>
//                                               )
//                                           ))
//                                       :
//                                       (
//                                         this.state.nodeClicked.id === n.id &&
//                                         <button
//                                           key={this.state.nodeClicked.id}
//                                           style={{
//                                             height: "37px",
//                                             width: "100%",
//                                             backgroundColor: 'rgb(137, 199, 74, 1)',
//                                             boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                             borderWidth: '1px',
//                                             borderStyle: ' solid',
//                                             borderColor: 'transparent',
//                                             borderImage: 'initial',
//                                             overflow: 'hidden',
//                                             outline: 'none',
//                                             color: "white",
//                                             fontSize: "16px",
//                                             fontWeight: 600,
//                                             border: "none",
//                                             borderRadius: 5
//                                           }}
//                                           type="button"
//                                           onClick={(e) => {
//                                             Update(n.id, null, false)
//                                           }}
//                                         >
//                                           {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                         </button>
//                                       )
//                                   )}
//                                 </Col>
//                               ) : (

//                                 this.state.newdep === true
//                                   ?
//                                   (
//                                     <Col >
//                                       <button
//                                         key={this.state.nodeClicked.id}
//                                         style={{
//                                           height: "37px",
//                                           width: "100%",
//                                           backgroundColor: 'rgb(137, 199, 74, 1)',
//                                           boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                           borderWidth: '1px',
//                                           borderStyle: ' solid',
//                                           borderColor: 'transparent',
//                                           borderImage: 'initial',
//                                           overflow: 'hidden',
//                                           outline: 'none',
//                                           color: "white",
//                                           fontSize: "16px",
//                                           fontWeight: 600,
//                                           border: "none",
//                                           borderRadius: 5
//                                         }}
//                                         type="button"
//                                         onClick={(e) => {
//                                           Add()

//                                         }}
//                                       >
//                                         {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
//                                       </button>
//                                     </Col>
//                                   )
//                                   :
//                                   (
//                                     <Col >
//                                     {console.log("this.state.nodeClicked",this.state.nodeClicked,this.state.treeData)}

//                                       <button
//                                         key={this.state.nodeClicked.id}
//                                         style={{
//                                           height: "37px",
//                                           width: "100%",
//                                           backgroundColor: 'rgb(137, 199, 74, 1)',
//                                           boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                           borderWidth: '1px',
//                                           borderStyle: ' solid',
//                                           borderColor: 'transparent',
//                                           borderImage: 'initial',
//                                           overflow: 'hidden',
//                                           outline: 'none',
//                                           color: "white",
//                                           fontSize: "16px",
//                                           fontWeight: 600,
//                                           border: "none",
//                                           borderRadius: 5
//                                         }}
//                                         type="button"
//                                         onClick={() => {
//                                           AddHeirarchy(this.state.nodeClicked.id)

//                                         }}>
//                                         {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
//                                       </button>

//                                     </Col>
//                                   )

//                               )}

//                             </Row>
//                             <Row className="col-Row">
//                               <h5 style={{ fontWeight: 600 }}>
//                                 Note :
//                               </h5>
//                               <p>1. Category with low display order will be shown on the top.</p>
//                               <p>2. Categories with active status  will be displayed in the customer site.</p>
//                             </Row>
//                           </ThemeProvider>
//                         </Form>
//                       </Col>)
//                       :
//                       (null)}
//                   </Row>
//                 )
//                 : (
//                   this.props.DepartmentList && this.props.DepartmentList.data.salesdepartments.length === 0 ?
//                     (
//                       <>
//                         <Row>
//                           {/* <h6>

//     </h6>
//               </Row>
//               <Row>
//                 <h6>

//   </h6>
//               </Row>
//               <Row>
//                 <h5>

//  </h5> */}
//                           <Card>
//                             <CardContent>
//                               <Typography sx={{ fontSize: 14 }} color="black" gutterBottom>
//                                 You can organize your products by creating various levels.
//                               </Typography>
//                               <Typography sx={{ fontSize: 16 }} color="black" gutterBottom component="div">
//                                 You need atleast one hierarchy to create your products.
//                               </Typography>
//                               <Typography sx={{ fontSize: 16 }} color="black" gutterBottom component="div">
//                                 Click on the button above to create your first department .
//                               </Typography>
//                             </CardContent>
//                           </Card>
//                         </Row>
//                         <Row>
//                           <Col md={7} sm={7} style={{ height: 800, width: '100%' }}>
//                             <SortableTree
//                               treeData={this.state.treeData}
//                               onChange={treeData => this.setState({ treeData })}
//                               onMoveNode={({ node, treeIndex, path }) =>
//                                 global.console.debug(
//                                   "node:",
//                                   node,
//                                   "treeIndex:",
//                                   treeIndex,
//                                   "path:",
//                                   path
//                                 )
//                               }

//                               maxDepth={this.state.Depth}
//                               canDrag={treeData => false}
//                               canDrop={treeData => false}
//                               isVirtualized={true}
//                               generateNodeProps={({ node, path, rowInfo, treeIndex }) => ({
//                                 className: `${node.className}`,
//                                 title:
//                                   this.state.isInput && node === this.state.nodeClicked ? (
//                                     node.name

//                                   ) : (
//                                     node.name
//                                   ),

//                                 buttons: [
//                                   path.length <= 3 && node.name &&
//                                   <Button
//                                     data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                                     onClick={() => {
//                                       this.setState(state => ({
//                                         newdep: false,
//                                         treeData: addNodeUnderParent({
//                                           treeData: this.state.treeData,
//                                           parentKey: path[path.length - 1],
//                                           getNodeKey: ({ treeIndex }) => treeIndex,
//                                           expandParent: true,
//                                           newNode: {
//                                             name: null,
//                                             needstitle: true,
//                                             id:`${Math.floor(Math.random()*(9999-1000+1)+1000)}`
//                                           },
//                                           parent: parentKey,
//                                           addAsFirstChild: !this.state.addAsFirstChild,
//                                         }).treeData,
//                                       }))
//                                     }
//                                     }
//                                   >
//                                     {/* <Icon
//                                       onClick={() => {
//                                         this.setState(state => ({
//                                           treeData: addNodeUnderParent({
//                                             treeData: this.state.treeData,
//                                             parentKey: path[path.length - 1],
//                                             getNodeKey: ({ treeIndex }) => treeIndex,
//                                             expandParent: true,
//                                             newNode: {
//                                               name: null,
//                                               needstitle: true,

//                                             },
//                                             parent: parentKey,
//                                             addAsFirstChild: !this.state.addAsFirstChild,
//                                           }).treeData,
//                                         }))
//                                       }
//                                       }>add_circle</Icon> */}
//                                   </Button >,
//                                   !node.name &&
//                                   <Button
//                                     data-toggle="tooltip" data-placement="top" title="Click to add a child category here."
//                                     onClick={() => {
//                                       this.setState({
//                                         treeData: removeNodeAtPath({
//                                           treeData: this.state.treeData,
//                                           path: path,   // You can use path from here
//                                           getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
//                                             // console.log(number);
//                                             return number;
//                                           },

//                                         })
//                                       })
//                                     }
//                                     }
//                                   >
//                                     <DeleteIcon />
//                                   </Button >
//                                 ],
//                                 onClick: () => {
//                                   let parentNode = getNodeAtPath({
//                                     treeData: this.state.treeData,
//                                     path: path,
//                                     getNodeKey: ({ treeIndex }) => treeIndex,
//                                     ignoreCollapsed: true
//                                   });
//                                   let getNodeKey = ({ node: object, treeIndex: number }) => {
//                                     return number;
//                                   };
//                                   let parentKey = getNodeKey(parentNode);
//                                   this.handleNodeClick(node);
//                                   this.setState((state) => ({
//                                     displayRank: node.displayrank ? node.displayrank : 0,
//                                     status: node.status ? node.status : "active",
//                                     parentKey: parentNode,
//                                     depParentid: parentKey,
//                                     nodename: node.name ? node.name : ""
//                                   }))

//                                 },
//                                 style:
//                                   node === this.state.nodeClicked
//                                     ? {
//                                       border: "3px solid #75c224",
//                                       borderBottomRightRadius: 16,
//                                       borderTopRightRadius: 16
//                                     }
//                                     : node.status === "inactive"
//                                       ? {
//                                         border: "3px solid  rgb(248, 134, 134)",
//                                         borderBottomRightRadius: 16,
//                                         borderTopRightRadius: 16
//                                       } :
//                                       {},
//                               })}
//                             />

//                           </Col>
//                           <br />

//                           {this.state.nodeClicked ? (
//                             <Col md={5} sm={5} style={{ border: '0.15px solid #ece9e9' }} >
//                               <Form style={{ marginTop: 10 }}>
//                                 <ThemeProvider theme={theme}>
//                                   <Row className="col-Row">
//                                     <TextField id="name"
//                                       style={{ width: '100%' }}
//                                       InputProps={{ style: { width: '100%', height: 35 } }}
//                                       label="Name"
//                                       variant="outlined"
//                                       value={this.state.nodename}
//                                       // defaultValue={this.state.nodename}
//                                       placeholder={'Name of the hierarchy '}
//                                       onChange={(e) => {
//                                         this.setState({ nodename: e.target.value })
//                                       }}
//                                       InputLabelProps={{
//                                         shrink: true,
//                                       }} />
//                                   </Row>
//                                   <Row className="col-Row">
//                                   <TextField id="name"
//                               type="number"
//                                 value={this.state.displayRank}
//                                 required
//                                 onKeyPress={(event) => {
//                                   if (event?.key === "-" || event?.key === "+") {
//                                     event.preventDefault();
//                                   }
//                                 }}
//                                 // defaultValue={this.state.displayrank}
//                                 placeholder={'Display Order'}
//                                 onChange={(e) => {
//                                   this.setState({ displayRank: e.target.value })
//                                 }}
//                                 style={{ width: '100%' }}
//                                 InputProps={{ style: { width: '100%', height: 35 } ,inputProps: { min: 1 }}}
//                                 label="Display order"
//                                 variant="outlined"
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               />
//                                   </Row>
//                                   <Row className="col-Row">
//                                     <TextField
//                                       defaultValue={this.state.nodeClicked.status}
//                                       select
//                                       style={{ width: '100%' }}
//                                       variant="outlined"
//                                       InputProps={{ style: { width: '100%', height: 35 } }}
//                                       SelectProps={{ style: { width: '100%', height: 35 } }}
//                                       InputLabelProps={{
//                                         shrink: true,
//                                       }}
//                                       value={this.state.status}
//                                       name="status"
//                                       label="Status">
//                                       <MenuItem onChange={(e) => {
//                                         this.setState({ status: e.target.value })
//                                         console.log(this.state.status)
//                                       }} value="active" style={{ width: '100%' }}>active</MenuItem>
//                                       <MenuItem
//                                         onChange={(e) => {
//                                           this.setState({ status: e.target.value })
//                                           console.log(this.state.status)
//                                         }}
//                                         value="inactive" style={{ width: '100%' }}>inactive</MenuItem>
//                                     </TextField>
//                                   </Row>
//                                   <Row className="col-Row">
//                                     {this.state.updateSuccess || this.state.success ? <Snackbar
//                                        style={{ width: "auto", left: "50%",position:'absolute',zIndex:10 }}
//                                        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
//                                       open={this.state.updateSuccess || this.state.success}
//                                       autoHideDuration={2000}
//                                       onClose={() => {
//                                         this.setState({ updateSuccess: false });
//                                         this.setState({ success: false })
//                                       }}>
//                                       <Alert severity="success">
//                                         <AlertTitle>{this.state.updateSuccess ?
//                                           "changes have been successfully saved" : this.state.success === true ? "Hierarchy added successfully" :
//                                             null}</AlertTitle>
//                                       </Alert>
//                                     </Snackbar> : null}
//                                     {this.state.error || this.state.netError ?
//                                       <Snackbar
//                                         style={{ width: "auto", left: "50%",position:'absolute',zIndex:10 }}
//                                         anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
//                                         open={this.state.error || this.state.netError}
//                                         autoHideDuration={2000}
//                                         onClose={() => {
//                                           this.setState({ error: false });
//                                           this.setState({ netError: false })
//                                         }}>
//                                         <Alert severity="error">
//                                           <AlertTitle>{this.state.errMsg}</AlertTitle>
//                                         </Alert>
//                                       </Snackbar>
//                                       : null}
//                                     {this.state.nodeClicked.name !== null ? (
//                                       <Col>
//                                         {this.state.treeData.map(n =>
//                                           n.childCategories ?
//                                             (
//                                               this.state.nodeClicked.id === n.id ?
//                                                 <button
//                                                   key={this.state.nodeClicked.id}
//                                                   style={{
//                                                     height: "37px",
//                                                     width: "100%",
//                                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                     boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                     borderWidth: '1px',
//                                                     borderStyle: ' solid',
//                                                     borderColor: 'transparent',
//                                                     borderImage: 'initial',
//                                                     overflow: 'hidden',
//                                                     outline: 'none',
//                                                     color: "white",
//                                                     fontSize: "16px",
//                                                     fontWeight: 600,
//                                                     border: "none",
//                                                     borderRadius: 5
//                                                   }}
//                                                   type="button"
//                                                   onClick={(e) => {
//                                                     Update(n.id, null, true)
//                                                   }}
//                                                 >
//                                                   {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                 </button>
//                                                 :
//                                                 n.childCategories.map(c =>
//                                                   c.childCategories ?
//                                                     (
//                                                       this.state.nodeClicked.id === c.id ?
//                                                         <button
//                                                           key={this.state.nodeClicked.id}
//                                                           style={{
//                                                             height: "37px",
//                                                             width: "100%",
//                                                             backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                             boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                             borderWidth: '1px',
//                                                             borderStyle: ' solid',
//                                                             borderColor: 'transparent',
//                                                             borderImage: 'initial',
//                                                             overflow: 'hidden',
//                                                             outline: 'none',
//                                                             color: "white",
//                                                             fontSize: "16px",
//                                                             fontWeight: 600,
//                                                             border: "none",
//                                                             borderRadius: 5
//                                                           }}
//                                                           type="button"
//                                                           onClick={(e) => {
//                                                             Update(c.id, null, c.childCategoryPresent)
//                                                           }}
//                                                         >
//                                                           {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                         </button>
//                                                         :

//                                                         c.childCategories.map(s =>
//                                                           s.childCategories ?
//                                                             (
//                                                               this.state.nodeClicked.id === s.id ?
//                                                                 <button
//                                                                   key={this.state.nodeClicked.id}
//                                                                   style={{
//                                                                     height: "37px",
//                                                                     width: "100%",
//                                                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                                     boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                                     borderWidth: '1px',
//                                                                     borderStyle: ' solid',
//                                                                     borderColor: 'transparent',
//                                                                     borderImage: 'initial',
//                                                                     overflow: 'hidden',
//                                                                     outline: 'none',
//                                                                     color: "white",
//                                                                     fontSize: "16px",
//                                                                     fontWeight: 600,
//                                                                     border: "none",
//                                                                     borderRadius: 5
//                                                                   }}
//                                                                   type="button"
//                                                                   onClick={(e) => {
//                                                                     Update(s.id, null, s.childCategoryPresent)
//                                                                   }}
//                                                                 >
//                                                                   {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                                 </button>
//                                                                 :
//                                                                 s.childCategories.map(sc =>

//                                                                   this.state.nodeClicked.id === sc.id &&
//                                                                   <button
//                                                                     key={this.state.nodeClicked.id}
//                                                                     style={{
//                                                                       height: "37px",
//                                                                       width: "100%",
//                                                                       backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                                       boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                                       borderWidth: '1px',
//                                                                       borderStyle: ' solid',
//                                                                       borderColor: 'transparent',
//                                                                       borderImage: 'initial',
//                                                                       overflow: 'hidden',
//                                                                       outline: 'none',
//                                                                       color: "white",
//                                                                       fontSize: "16px",
//                                                                       fontWeight: 600,
//                                                                       border: "none",
//                                                                       borderRadius: 5
//                                                                     }}
//                                                                     type="button"
//                                                                     onClick={(e) => {
//                                                                       Update(sc.id, null, sc.childCategoryPresent)
//                                                                     }}
//                                                                   >
//                                                                     {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                                   </button>
//                                                                 )
//                                                             )
//                                                             :
//                                                             (
//                                                               this.state.nodeClicked.id === s.id &&
//                                                               <button
//                                                                 key={this.state.nodeClicked.id}
//                                                                 style={{
//                                                                   height: "37px",
//                                                                   width: "100%",
//                                                                   backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                                   boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                                   borderWidth: '1px',
//                                                                   borderStyle: ' solid',
//                                                                   borderColor: 'transparent',
//                                                                   borderImage: 'initial',
//                                                                   overflow: 'hidden',
//                                                                   outline: 'none',
//                                                                   color: "white",
//                                                                   fontSize: "16px",
//                                                                   fontWeight: 600,
//                                                                   border: "none",
//                                                                   borderRadius: 5
//                                                                 }}
//                                                                 type="button"
//                                                                 onClick={(e) => {
//                                                                   Update(s.id, null, s.childCategoryPresent)
//                                                                 }}
//                                                               >
//                                                                 {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                               </button>
//                                                             )
//                                                         )

//                                                     )
//                                                     :
//                                                     (
//                                                       this.state.nodeClicked.id === c.id &&
//                                                       <button
//                                                         key={this.state.nodeClicked.id}
//                                                         style={{
//                                                           height: "37px",
//                                                           width: "100%",
//                                                           backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                           boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                           borderWidth: '1px',
//                                                           borderStyle: ' solid',
//                                                           borderColor: 'transparent',
//                                                           borderImage: 'initial',
//                                                           overflow: 'hidden',
//                                                           outline: 'none',
//                                                           color: "white",
//                                                           fontSize: "16px",
//                                                           fontWeight: 600,
//                                                           border: "none",
//                                                           borderRadius: 5
//                                                         }}
//                                                         type="button"
//                                                         onClick={(e) => {
//                                                           Update(c.id, null, c.childCategoryPresent)
//                                                         }}
//                                                       >
//                                                         {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                                       </button>
//                                                     )
//                                                 ))
//                                             :
//                                             (
//                                               this.state.nodeClicked.id === n.id &&
//                                               <button
//                                                 key={this.state.nodeClicked.id}
//                                                 style={{
//                                                   height: "37px",
//                                                   width: "100%",
//                                                   backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                   boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                   borderWidth: '1px',
//                                                   borderStyle: ' solid',
//                                                   borderColor: 'transparent',
//                                                   borderImage: 'initial',
//                                                   overflow: 'hidden',
//                                                   outline: 'none',
//                                                   color: "white",
//                                                   fontSize: "16px",
//                                                   fontWeight: 600,
//                                                   border: "none",
//                                                   borderRadius: 5
//                                                 }}
//                                                 type="button"
//                                                 onClick={(e) => {
//                                                   Update(n.id, null, false)
//                                                 }}
//                                               >
//                                                 {this.props.updateDep === false ? (' Update ') : (' Updating')}
//                                               </button>
//                                             )
//                                         )}
//                                       </Col>
//                                     ) : (

//                                       this.state.newdep === true
//                                         ?
//                                         (
//                                           <Col >
//                                             <button
//                                               key={this.state.nodeClicked.id}
//                                               style={{
//                                                 height: "37px",
//                                                 width: "100%",
//                                                 backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                 boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                 borderWidth: '1px',
//                                                 borderStyle: ' solid',
//                                                 borderColor: 'transparent',
//                                                 borderImage: 'initial',
//                                                 overflow: 'hidden',
//                                                 outline: 'none',
//                                                 color: "white",
//                                                 fontSize: "16px",
//                                                 fontWeight: 600,
//                                                 border: "none",
//                                                 borderRadius: 5
//                                               }}
//                                               type="button"
//                                               onClick={(e) => {
//                                                 Add1()

//                                               }}
//                                             >
//                                               {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Add') : ('Adding')}
//                                             </button>
//                                           </Col>
//                                         )
//                                         :
//                                         (

//                                           <Col >

//                                             <button
//                                               key={this.state.nodeClicked.id}
//                                               style={{
//                                                 height: "37px",
//                                                 width: "100%",
//                                                 backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                 boxShadow: '0px 10px 20px 0px rgba(93, 183, 96, 0.38)',
//                                                 borderWidth: '1px',
//                                                 borderStyle: ' solid',
//                                                 borderColor: 'transparent',
//                                                 borderImage: 'initial',
//                                                 overflow: 'hidden',
//                                                 outline: 'none',
//                                                 color: "white",
//                                                 fontSize: "16px",
//                                                 fontWeight: 600,
//                                                 border: "none",
//                                                 borderRadius: 5
//                                               }}
//                                               type="button"
//                                               onClick={() => {
//                                                 AddHeirarchy(this.state.nodeClicked.id, this.state.nodeClicked.name)

//                                               }}>
//                                               {this.props.addDep === false ? ('Add') : this.props.HeirarchyError ? ('Adding') : ('Add')}
//                                             </button>

//                                           </Col>
//                                         )

//                                     )}

//                                   </Row>
//                                   <Row className="col-Row">
//                                     <h5 style={{ fontWeight: 600 }}>
//                                       Note :
//                                     </h5>
//                                     <p>1. Category with low display order will be shown on the top.</p>
//                                     <p>2. Categories with active status  will be displayed in the customer site.</p>
//                                   </Row>
//                                 </ThemeProvider>
//                               </Form>
//                             </Col>)
//                             :
//                             (null)}
//                         </Row>
//                       </>
//                     ) :
//                     (
//                       <Row style={{ marginLeft: 70 }}>
//                         <Spinner size={40} animation="border" variant="success" />
//                       </Row>
//                     )
//                 )

//               }
//             </div>

//           </Container>

//         }

//       </>
//     );
//   }
// }
// export default Heirarchy
import { React, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import CustomButton from "../Commoncomponents/button";
import CustomCard from "../Commoncomponents/card";
import CustomTextField from "../Commoncomponents/input";
import { ReactComponent as Edit } from "../../utils/Icon/editicon.svg";
import { ReactComponent as Arrow } from "../../utils/Icon/Arrow.svg";
import { ReactComponent as Arrow1 } from "../../utils/Icon/Arrow1.svg";
import { ReactComponent as Down } from "../../utils/Icon/DownArrow.svg";
import { ReactComponent as Filter } from "../../utils/Icon/filtericon.svg";
import { ReactComponent as Addproduct } from "../../utils/Icon/add.svg";
import { ReactComponent as Addcategory } from "../../utils/Icon/Addicon.svg";
import { ReactComponent as Subarrow } from "../../utils/Icon/subarrow.svg";
import { ReactComponent as Subedit } from "../../utils/Icon/subedit.svg";
import {
  Grid,
  Typography,
  Box,
  InputAdornment,
  DialogContent,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  DialogTitle,
  TextField,
} from "../MUIComponents/index";
import "./add.css";
import "./category.css";
import { DndContext } from "@dnd-kit/core";
import { closestCenter } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
  PointerSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CustomSelect from "../Commoncomponents/selectButton";
import CustomDialog from "../Commoncomponents/dialog";
import Search from "../../utils/Icon/search.svg";
import Categorynext from "./Categorynext";
import {
  contentFontSize_24,
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
  ButtonFontSize_16,
  HeaderFontSize_24,
  HeaderFontSize_36_xs_25,
  HeaderFontSize_24_xs_17,
  HeaderfontSize_30,
} from "../Typography/index";
import { useNavigate } from "react-router-dom";
import {
  CREATE_HEIRARCHY,
  UPDATE_HEIRARCHY,
  UPDATE_HEIRARCHY1,
  CREATE_FIRSTLEVEL_HEIRARCHY,
} from "./GqlQuery";
import client from "../../Configurations/apollo";
import { message } from "antd";
import SortableItem from "./SortableItem";

const Categorypdt = (props) => {
  const [activeButton, setActiveButton] = useState("add");
  const [editIcon, setEditIcon] = useState(false);
  const [subArrowNext, setSubArrowNext] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [activeButtonMain, setActiveButtonMain] = useState("category");
  const [addSubCategory, setAddSubCategoy] = useState(false);
  const [categories, setCategories] = useState([]);
  const [showAddSubCategoryDialog, setShowAddSubCategoryDialog] =
    useState(false);
  const [showEditSubCategoryDialog, setShowEditSubCategoryDialog] =
    useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [newCategoryDisplayRank, setNewCategoryDisplayRank] = useState("");
  const [newCategoryStatus, setNewCategoryStatus] = useState("");
  const [department, setDepartment] = useState(null);
  const [category, setCategory] = useState(null);
  const [category1, setCategory1] = useState(null);
  const [category2, setCategory2] = useState(null);
  const [status, setStatus] = useState(null);
  const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
  const [key, setKey] = useState("");
  const [from, setFrom] = useState(0);
  const [first, setFirst] = useState(20);
  const theme = useTheme();
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (props.UpdateDepartment) {
      props.getDepartmentList(localStorage.getItem("storeid"));
      if (
        props.DepartmentList &&
        props.DepartmentList.data &&
        props.DepartmentList.data.salesdepartments
      ) {
       
        const departments = props.DepartmentList.data.salesdepartments.map(
          (department) => {
            const subcategories = department.childCategories.map((child) => ({
              id: child.id,
              name: child.name,
              displayrank: child.displayrank,
              status: child.status,
            }));
            return {
              id: department.id,
              name: department.name,
              displayrank: department.displayrank,
              status: department.status,
              subcategories: subcategories,
            };
          }
        );
        setCategories(departments);
      } else {
      }
    }
  }, [props.UpdateDepartment]);

  const initialState = {
    level: 1,
    open: true,
    tree: false,
    addAsFirstChild: false,
    nodeClicked: null,
    isInput: false,
    checkedA: true,
    Depth: 3,
    depParentid: null,
    childCategoryPresent: false,
    status: "active",
    displayRank: null,
    parent: false,
    parentid: null,
    nodename: "",
    Heirarchyid: null,
    newdep: false,
    update: 1,
    ErrorOpen: true,
    error: false,
    errMsg: "",
    netError: false,
    success: false,
    updateSuccess: false,
    successstatus: false,
    nodeadded: false,
  };

  const initialState1 = {
    level: 2,
    open: true,
    tree: false,
    addAsFirstChild: false,
    nodeClicked: null,
    isInput: false,
    checkedA: true,
    Depth: 3,
    depParentid: null,
    childCategoryPresent: false,
    status: "active",
    displayRank: null,
    parent: false,
    parentid: null,
    nodename: "",
    Heirarchyid: null,
    newdep: false,
    update: 1,
    ErrorOpen: true,
    error: false,
    errMsg: "",
    netError: false,
    success: false,
    updateSuccess: false,
    successstatus: false,
    nodeadded: false,
  };

  const [state, setState] = useState(initialState);
  const [state1, setState1] = useState(initialState1);
  const token = localStorage.getItem("token");

  const resetFields = () => {
    setState({
      ...state,
      nodename: "",
      displayRank: "",
      status: "",
    });
  };

  const Add = () => {
    let name = state.nodename;
    let displayRank = state.displayRank;
    if (name === "") {
      message.error({
        content: "Please enter the name of hierarchy",
        className: "custom-class",
        style: {
          marginTop: "15vh",
          marginLeft: "5vh",
        },
      });
    } else if (Math.sign(displayRank) === -1) {
      message.error(
        {
          content: "Display rank must be a positive number",
          className: "custom-class",
          style: {
            marginTop: "15vh",
            marginLeft: "5vh",
          },
        },
        10
      );
    } else {
      client
        .mutate({
          mutation: CREATE_HEIRARCHY,
          fetchPolicy: "no-cache",
          errorPolicy: "all",
          variables: {
            storeid: localStorage.getItem("storeid"),
            name: name,
            level: state.level,
            displayrank: Number(displayRank),
            parentid: state.depParentid,
            status: state.status,
            childCategoryPresent: state.childCategoryPresent,
          },
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then((res) => {
          if (
            res.data &&
            res.data.createSalesHierarchy &&
            res.data.createSalesHierarchy !== null
          ) {
            const newCategory = res.data.createSalesHierarchy;
            const updatedCategories = [...categories];
            updatedCategories.forEach((category) => {
              if (category.displayRank >= displayRank) {
                category.displayRank++;
              }
            });
            updatedCategories.splice(displayRank - 1, 0, newCategory);
            setCategories(updatedCategories);
            message.success({
              content: "Category Added Successfully",
              className: "custom-class",
              style: {
                marginTop: "15vh",
                marginLeft: "5vh",
              },
            });
            if (message.success) {
              setAddButton(false);
            }
          } else if (res.errors) {
            message.error(res.errors[0].message);
          }
        })
        .catch((err) => {
          message.error("Network error: " + err.message);
        });
    }
    resetFields();
  };

  const [updateHierarchyMutation] = useMutation(UPDATE_HEIRARCHY);

  const resetFields1 = () => {
    setState1({
      ...state1,
      nodename: "",
      displayRank: "",
      status: "",
    });
  };

  const handleAddSubCategory = (parentid, childCategoryPresent) => {
    const { nodename, displayRank, status } = state1;

    if (nodename.trim() === "") {
      message.error({
        content: "Please enter the name of the category",
        className: "custom-class",
        style: {
          marginTop: "15vh",
          marginLeft: "5vh",
        },
      });
      return;
    }

    if (isNaN(parseInt(displayRank)) || parseInt(displayRank) < 0) {
      message.error({
        content: "Display rank must be a positive number",
        className: "custom-class",
        style: {
          marginTop: "15vh",
          marginLeft: "5vh",
        },
      });
      return;
    }

    client
      .mutate({
        mutation: CREATE_FIRSTLEVEL_HEIRARCHY,
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        variables: {
          storeid: localStorage.getItem("storeid"),
          name: nodename,
          level: state1.level,
          displayrank: Number(displayRank),
          parentid: parentid,
          status: status,
          childCategoryPresent: childCategoryPresent,
        },
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.createSalesHierarchy &&
          res.data.createSalesHierarchy !== null
        ) {
          status === "inactive" &&
            props.getSellerWorking(
              props.retailerDetails &&
                props.retailerDetails.data &&
                props.retailerDetails.data.retailerdetails["id"]
            );
          setShowAddSubCategoryDialog(false);
          const newSubcategory = res.data.createSalesHierarchy;
          const updatedCategories = categories.map((category) => {
            if (category.id === parentid) {
              return {
                ...category,
                subcategories: [...category.subcategories, newSubcategory],
              };
            }
            return category;
          });
          setCategories(updatedCategories);
          message.success({
            content: "SubCategory Added Successfully",
            className: "custom-class",
            style: {
              marginTop: "15vh",
              marginLeft: "5vh",
            },
          });
        } else if (res.errors) {
          message.error({
            content: res.errors[0].message,
            className: "custom-class",
            style: {
              marginTop: "15vh",
              marginLeft: "5vh",
            },
          });
        }
      })
      .catch((err) => {
        message.error({
          content: err.message,
          className: "custom-class",
          style: {
            marginTop: "15vh",
            marginLeft: "5vh",
          },
        });
      });
    resetFields1();

    setShowAddSubCategoryDialog(false);
  };

  const handleChange = (
    categoryId,
    name,
    displayrank,
    status,
    parentid,
    childCategoryPresent
  ) => {
    if (name === "") {
      message.error({
        content: "Please enter the name of category",
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "5vh",
        },
      });
      return;
    }
    if (displayrank < 0) {
      message.error({
        content: "Display rank must be a positive number",
        className: "custom-class",
        style: {
          marginTop: "15vh",
          marginLeft: "5vh",
        },
      });
      return;
    }

    updateHierarchyMutation({
      variables: {
        hierarchyid: categoryId,
        name: name,
        parentid: parentid,
        status: status,
        displayrank: displayrank,
        childCategoryPresent: childCategoryPresent,
      },
    })
      .then((res) => {
        if (res.data) {
          const updatedCategories = categories.map((category) => {
            if (category.id === categoryId) {
              return {
                ...category,
                name: name,
                displayrank: displayrank,
                status: status,
                parentid: parentid,
                childCategories: childCategoryPresent,
              };
            }
            return category;
          });
          updatedCategories.sort((a, b) => a.displayrank - b.displayrank);
          setCategories(updatedCategories);
          message.success({
            content: "Category Updated Successfully",
            className: "custom-class",
            style: {
              marginTop: "20vh",
              marginLeft: "40vh",
            },
          });
        } else if (res.errors) {
          console.error("Mutation errors:", res.errors);
        }
      })
      .catch((err) => {
        console.error("Mutation error:", err);
      });
    setEditIcon(false);
  };

  const [categoryData, setCategoryData] = useState({
    name: "",
    displayrank: "",
    status: "",
  });

  const [subCategoryData, setSubCategoryData] = useState({
    name: "",
    displayrank: "",
    status: "",
  });

  const [displayRankOptions, setDisplayRankOptions] = useState();
  const [currentSubcategory, setCurrentSubcategory] = useState();

  const handleEditSubClick = (subcategory, categoryId) => {
    const numSubcategories = category?.subcategories?.length || 0;
    const displayRankOption = Array.from(
      { length: numSubcategories },
      (_, i) => i
    );
    setDisplayRankOptions(displayRankOption);
    setCurrentSubcategory(subcategory);
    setSubCategoryData({
      id: subcategory.id,
      name: subcategory.name,
      displayrank: subcategory.displayrank,
      status: subcategory.status,
      categoryId: categoryId,
    });
  };
  const handleEditSubCategory = (
    subcategoryId,
    name,
    displayrank,
    status,
    parentid
  ) => {
    if (name.trim() === "") {
      message.error({
        content: "Please enter the name of the subcategory",
        className: "custom-class",
        style: {
          marginTop: "15vh",
          marginLeft: "5vh",
        },
      });
      return;
    }
    client
      .mutate({
        mutation: UPDATE_HEIRARCHY1,
        variables: {
          hierarchyid: subcategoryId,
          name: name,
          status: status,
          displayrank: Number(displayrank),
          childCategoryPresent: true,
        },
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then((res) => {
        if (res.data && res.data.updateSalesHierarchy) {
          const updatedSubCategory = res.data.updateSalesHierarchy;
          const updatedCategories = categories.map((category) => {
            if (category.id === parentid) {
              return {
                ...category,
                subcategories: category.subcategories
                  .map((subcategory) => {
                    if (subcategory.id === subcategoryId) {
                      return {
                        ...subcategory,
                        name: name,
                        displayrank: displayrank,
                        status: status,
                      };
                    }
                    return subcategory;
                  })
                  .sort((a, b) => a.displayrank - b.displayrank),
              };
            }
            return category;
          });
          setCategories(updatedCategories);
          message.success({
            content: "Subcategory Updated Successfully",
            className: "custom-class",
            style: {
              marginTop: "20vh",
              marginLeft: "40vh",
            },
          });
        } else if (res.errors) {
          throw new Error(res.errors[0].message);
        } else {
          throw new Error("Unexpected response from server");
        }
      })
      .catch((err) => {
        message.error({
          content: err.message || "Network error occurred",
          className: "custom-class",
          style: {
            marginTop: "15vh",
            marginLeft: "5vh",
          },
        });
      });

    setShowEditSubCategoryDialog(false);
  };

  const handleEditClick = (category) => {
    setCategoryData({
      id: category.id,
      name: category.name,
      displayrank: category.displayrank,
      status: category.status,
    });
    setEditIcon(true);
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setCategoryData({
      ...categoryData,
      [name]: value,
    });
  };

  const handleSubChange = (event) => {
    const { name, value } = event.target;
    setSubCategoryData({
      ...subCategoryData,
      [name]: value,
    });
  };

  const nav = useNavigate();

  useEffect(() => {
    props.getDepartmentList(localStorage.getItem("storeid"));
  }, [props.getDepartmentList]);

  useEffect(() => {
    props.setCriteria({
      from,
      first,
      department,
      storeid,
      category,
      category1,
      category2,
      status,
      key,
    });
    props.ProductSearch({
      from,
      first,
      department,
      storeid,
      category,
      category1,
      category2,
      status,
      key,
    });
  }, [props.setCriteria, props.ProductSearch]);

  useEffect(() => {
    if (
      props.DepartmentList &&
      props.DepartmentList.data &&
      props.DepartmentList.data.salesdepartments
    ) {
    
      const departments = props.DepartmentList.data.salesdepartments.map(
        (department) => {
          const subcategories = department.childCategories.map((child) => ({
            id: child.id,
            name: child.name,
            displayrank: child.displayrank,
            status: child.status,
          }));
          return {
            id: department.id,
            name: department.name,
            displayrank: department.displayrank,
            status: department.status,
            subcategories: subcategories,
          };
        }
      );
      setCategories(departments);
    } else {
    }
  }, [props.DepartmentList]);

  const [selectCategory, setSelectCategory] = useState(null);

  const handleShowSubCategoryDialog = (index, category) => {
    setSelectCategory(category);
    setSelectedCategoryIndex(index);
    setShowAddSubCategoryDialog(true);
  };

  const toggleSubcategories = (index) => {
    setExpandedCategories((prevExpanded) => {
      const updatedExpanded = [...prevExpanded];
      updatedExpanded[index] = !updatedExpanded[index];
      return updatedExpanded;
    });
  };

  function handleCancelClick() {
    setEditIcon(false);
    setAddButton(false);
  }

  function handleClose() {
    setShowEditSubCategoryDialog(false);
    setEditIcon(false);
    setAddButton(false);
    setAddSubCategoy(false);
    setShowAddSubCategoryDialog(false);
  }
  const [items, setItems] = useState(categories);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 8 },
    }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = categories.findIndex(
        (category) => category.id === active.id
      );
      const newIndex = categories.findIndex(
        (category) => category.id === over.id
      );

      if (newIndex === -1 || oldIndex === -1) {
        return;
      }

      const newCategories = arrayMove(categories, oldIndex, newIndex);

      const updatedCategories = newCategories.map((category, index) => ({
        ...category,
        displayRank: index,
      }));

      setCategories(updatedCategories);

      const movedCategory = updatedCategories[newIndex];
      updateHierarchyMutation({
        variables: {
          hierarchyid: movedCategory.id,
          name: movedCategory.name,
          parentid: movedCategory.parentid || null,
          status: movedCategory.status,
          displayrank: movedCategory.displayRank,
          childCategoryPresent:
            movedCategory.childCategories &&
            movedCategory.childCategories.length > 0,
        },
      })
        .then((res) => {
          if (res.data) {
            props.getDepartmentList(localStorage.getItem("storeid"));
            if (
              props.DepartmentList &&
              props.DepartmentList.data &&
              props.DepartmentList.data.salesdepartments
            ) {
             
              const departments =
                props.DepartmentList.data.salesdepartments.map((department) => {
                  const subcategories = department.childCategories.map(
                    (child) => ({
                      id: child.id,
                      name: child.name,
                      displayrank: child.displayrank,
                      status: child.status,
                    })
                  );
                  return {
                    id: department.id,
                    name: department.name,
                    displayrank: department.displayrank,
                    status: department.status,
                    subcategories: subcategories,
                  };
                });
              setCategories(departments);
            } else {
            }
           

            message.success({
              content: "Category Updated Successfully",
              className: "custom-class",
              style: {
                marginTop: "20vh",
                marginLeft: "40vh",
              },
            });
          } else if (res.errors) {
            console.error("Mutation errors:", res.errors);
          }
        })
        .catch((err) => {
          console.error("Mutation error:", err);
        });
    }
  };

  const [departmentId, setDepartmentId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [categryName, setCategryName] = useState([]);
  const [subCategryName, setSubCategryName] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query.length >= 3) {
      const filteredCategories =
        props.DepartmentList.data.salesdepartments.filter((category) =>
          category.name.toLowerCase().startsWith(query)
        );
      setCategories(filteredCategories);
    } else {
      if (
        props.DepartmentList &&
        props.DepartmentList.data &&
        props.DepartmentList.data.salesdepartments
      ) {
        const departments = props.DepartmentList.data.salesdepartments.map(
          (department) => {
            const subcategories = department.childCategories.map((child) => ({
              id: child.id,
              name: child.name,
              displayrank: child.displayrank,
              status: child.status,
            }));
            return {
              id: department.id,
              name: department.name,
              displayrank: department.displayrank,
              status: department.status,
              subcategories: subcategories,
            };
          }
        );
        setCategories(departments);
      }
    }
  };

  function subarrowClick(departmentId, categoryId, categoryName, subCatName) {
    nav("/home/ManageHierarchy/Product", {
      state: {
        departmentId,
        categoryId,
        categoryName,
        subCatName,
      },
    });
    setDepartmentId(departmentId);
    setCategoryId(categoryId);
    setCategryName(categoryName);
    setSubCategryName(subCatName);
    setSubArrowNext(true);
  }

  function handleClickButton() {
    setAddButton(true);
  }
  function handleClickTwo() {}
  function handleClickProduct() {
    nav("/home/AddProducts");
  }

  const optionsrank = [
    { value: "", label: "" },
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];
  const optionsstatus = [
    { value: "", label: "" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  return (
    <>
      {!subArrowNext && (
        <>
          {/* First section */}
          <div>
            <div className="add-btn" style={{ width: "100%" }}>
              <CustomButton
                width="50%"
                height="100%"
                matchPadding={true}
                padding="8px"
                borderRadius="15px"
                onClick={handleClickProduct}
                color={activeButtonMain === "product" ? "white" : "grey"}
                variant={
                  activeButtonMain === "product" ? "contained" : "outlined"
                }
                background={
                  activeButtonMain === "product" ? "rgb(101, 155, 27)" : "white"
                }
                borderColor="#659B1B"
              >
                <Typography className="add-Button" fontSize={ButtonFontSize_18}>
                  Products
                </Typography>
              </CustomButton>
              <CustomButton
                width="50%"
                height="100%"
                matchPadding={true}
                padding="8px"
                borderRadius="15px"
                onClick={handleClickTwo}
                color={activeButtonMain === "category" ? "white" : "grey"}
                variant={
                  activeButtonMain === "category" ? "contained" : "outlined"
                }
                background={
                  activeButtonMain === "category"
                    ? "rgb(101, 155, 27)"
                    : "white"
                }
              >
                <Typography className="add-Button" fontSize={ButtonFontSize_18}>
                  Category
                </Typography>
              </CustomButton>
            </div>
            <div className="search-product">
              <CustomTextField
                width="100%"
                placeholder="Search Category"
                value={searchQuery}
                onChange={handleSearchChange}
                borderRadius="50%"
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <img
                      src={Search}
                      alt="Search"
                      style={{ marginRight: "8px" }}
                    />
                  </InputAdornment>
                }
              />
            </div>
            <div style={{ marginTop: "10px", textAlign: "right" }}>
              <CustomButton
                matchPadding={true}
                padding="10px"
                width={smmatch ? "40%" : "25%"}
                borderRadius="15px"
                onClick={handleClickButton}
              >
                <Addproduct className="add-icn" />
                <Typography
                  fontSize={ButtonFontSize_16}
                  className="Add-product-btn"
                >
                  Add New
                </Typography>
              </CustomButton>
            </div>
          </div>
          {/* First Section Ends */}

          {/* Adding New category dialog starts */}

          {addButton && (
            <>
              <CustomDialog
                width="800px"
                height="600px"
                borderRadius="20px"
                open={addButton}
                onClose={handleClose}
                titleAlign="center"
                top={"27px"}
                right={"27px"}
                padding="10px 0px 10px 0px"
                contentpadding="0px 20px 0px 20px"
              >
                <DialogTitle>
                  <Typography
                    sx={{
                      color: "#170F49",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontWeight: "600",
                      lineHeight: "35px",
                    }}
                    fontSize={HeaderFontSize_24}
                  >
                    Add Category
                  </Typography>
                </DialogTitle>
                <DialogContent style={{ padding: "5px 20px 15px 20px" }}>
                  <div className="edit-pdt">
                    <label className="text">
                      <Typography
                        fontSize={contentFontSize_16}
                        className="text-label"
                      >
                        Name of Category
                      </Typography>
                    </label>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: "100%",

                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#85BE49",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#85BE49",
                          },
                        },
                        "& .MuiInputBase-input": {
                          backgroundColor: "white",
                          borderRadius: "10px",
                        },
                      }}
                      InputProps={{
                        sx: {
                          fontSize: matches && "13px",
                        },
                      }}
                      size={matches ? "small" : "normal"}
                      name="nodename"
                      required
                      value={state.nodename}
                      // onChange={(e)=>setState({nodename:e.target.value})}
                      onChange={(e) =>
                        setState({ ...state, nodename: e.target.value })
                      }
                    />
                    <div className="display-rank">
                      <label className="text">
                        <Typography
                          fontSize={contentFontSize_16}
                          className="text-label"
                        >
                          Display Rank
                        </Typography>
                      </label>
                      <label className="text">
                        <Typography
                          fontSize={contentFontSize_16}
                          className="text-label"
                        >
                          Status
                        </Typography>
                      </label>
                      <Select
                        className="custom-select_1"
                        onChange={(e) =>
                          setState({ ...state, displayRank: e.target.value })
                        }
                        id="name"
                        options={optionsrank}
                        buttonColor="success"
                        variant="outlined"
                        borderRadius="10px"
                        type="number"
                        // value={newCategoryDisplayRank}
                      >
                        <MenuItem value={0} label={0}>
                          0
                        </MenuItem>
                        {Array.from({ length: categories.length }, (_, i) => (
                          <MenuItem key={i + 1} value={i + 1} label={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select
                        className="custom-select_1"
                        onChange={(e) =>
                          setState({ ...state, status: e.target.value })
                        }
                        id="name"
                        name="status"
                        options={optionsstatus}
                        buttonColor="success"
                        width="100%"
                        variant="outlined"
                        borderRadius="10px"
                        // value={newCategoryStatus}
                        // onChange={handleCategoryStatusChange}
                      >
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"inactive"}>Inactive</MenuItem>
                      </Select>
                    </div>
                    <div className="edit-button">
                      <CustomButton
                        width="230px"
                        height="40px"
                        borderRadius="20px"
                        borderColor="#72B01D"
                        onClick={handleCancelClick}
                        color={activeButton === "cancel" ? "white" : "grey"}
                        variant={
                          activeButton === "cancel" ? "contained" : "outlined"
                        }
                        background={
                          activeButton === "cancel"
                            ? "rgb(101, 155, 27)"
                            : "white"
                        }
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        width="230px"
                        height="40px"
                        borderRadius="20px"
                        color="white"
                        variant={
                          activeButton === "add" ? "contained" : "outlined"
                        }
                        background={
                          activeButton === "add" ? "rgb(101, 155, 27)" : "white"
                        }
                        onClick={Add}
                      >
                        Add
                      </CustomButton>
                    </div>
                  </div>
                </DialogContent>
              </CustomDialog>
            </>
          )}

          {/* adding new Category dialog ends */}

          {/* Added category Card display */}
          <div style={{ clear: "both" }}></div>
          <div>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={categories.map((category, index) => category.id)}
              >
                {categories.length != 0 ? (
                  categories.map((category, index) => (
                    <SortableItem
                      key={category.id}
                      id={category.id}
                      index={index}
                    >
                      <>
                        <Grid
                          key={index}
                          sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                        >
                          <div className="card-pdt-ctgry">
                            <CustomCard
                              padding={"30px"}
                              width="100%%"
                              height="100%"
                              borderLeft={
                                category.status === "active"
                                  ? "8px solid #FDD502"
                                  : "8px solid #A9A9A9"
                              }
                              // borderLeft="8px solid #FDD502"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Box>
                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={10}
                                    md={10}
                                    lg={11}
                                    xl={11}
                                  >
                                    <div>
                                      <div>
                                        <Typography
                                          className="Card-category-text"
                                          fontSize={contentFontSize_16}
                                          color={
                                            category.status === "active"
                                              ? "black"
                                              : "#6D6D6D"
                                          }
                                        >
                                          {category.name}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={0} sm={2} md={2} lg={1} xl={1}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        paddingRight: "15px",
                                      }}
                                    >
                                      <div
                                        style={{ display: "flex", gap: "20px" }}
                                      >
                                        <div
                                          style={{
                                            // paddingBottom: "7px",
                                            marginRight: "7px",
                                          }}
                                        >
                                          <Addcategory
                                            onClick={() =>
                                              handleShowSubCategoryDialog(
                                                index,
                                                category
                                              )
                                            }
                                          />
                                        </div>
                                        <div
                                          style={{
                                            // paddingBottom: "7px",
                                            marginRight: "7px",
                                          }}
                                        >
                                          <Edit
                                            onClick={() =>
                                              handleEditClick(category)
                                            }
                                          />
                                        </div>
                                        <div style={{ marginRight: "7px" }}>
                                          {category?.subcategories?.length >
                                          0 ? (
                                            <div
                                              onClick={() =>
                                                toggleSubcategories(index)
                                              }
                                            >
                                              {expandedCategories[index] ? (
                                                <Down />
                                              ) : (
                                                <Arrow />
                                              )}
                                            </div>
                                          ) : (
                                            <div>
                                              <Arrow1 />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Box>
                            </CustomCard>
                          </div>

                          {/* Subcategory Card display */}

                          {expandedCategories[index] && (
                            <>
                              {/* {category.subcategories.map((subcategory, subIndex) => ( */}
                              {category.subcategories
                                .slice()
                                .sort((a, b) => a.displayRank - b.displayRank)
                                .map((subcategory, subIndex) => (
                                  <Grid
                                    key={subIndex}
                                    sx={{
                                      paddingLeft: "0px",
                                      paddingTop: "0px",
                                    }}
                                  >
                                    <div className="card-pdt-ctgry">
                                      <CustomCard
                                        padding={"20px"}
                                        width="100%"
                                        background={
                                          subcategory.status === "active"
                                            ? "rgba(133, 190, 73, 0.35)"
                                            : "#A9A9A9"
                                        }
                                        // onClick={() =>
                                        //   subarrowClick(
                                        //     category.id,
                                        //     subcategory.id,
                                        //     category.name,
                                        //     subcategory.name
                                        //   )}
                                      >
                                        <Box
                                          sx={{ flexGrow: 1 }}
                                          className="card-color"
                                        >
                                          <Grid
                                            container
                                            spacing={2}
                                            sx={{
                                              paddingLeft: "0px",
                                              paddingTop: "0px",
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={10}
                                              sm={10}
                                              md={10}
                                              lg={11}
                                              xl={11}
                                              sx={{
                                                paddingLeft: "0px",
                                                paddingTop: "0px",
                                              }}
                                            >
                                              <div>
                                                <Typography
                                                  className="category-card-1"
                                                  fontSize={contentFontSize_16}
                                                >
                                                  {subcategory.name}
                                                </Typography>
                                              </div>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={2}
                                              sm={2}
                                              md={2}
                                              lg={1}
                                              xl={1}
                                              sx={{
                                                paddingLeft: "0px",
                                                paddingTop: "0px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  height: "100%",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "30px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      // paddingBottom: "7px",
                                                      marginRight: "7px",
                                                    }}
                                                  >
                                                    <Subedit
                                                      style={{
                                                        color: "black",
                                                      }}
                                                      onClick={() => {
                                                        handleEditSubClick(
                                                          subcategory,
                                                          category.id
                                                        );
                                                        setShowEditSubCategoryDialog(
                                                          true
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    style={{
                                                      // paddingBottom: "7px",
                                                      marginRight: "7px",
                                                    }}
                                                  >
                                                    <Subarrow
                                                      onClick={() =>
                                                        subarrowClick(
                                                          category.id,
                                                          subcategory.id,
                                                          category.name,
                                                          subcategory.name
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      </CustomCard>
                                    </div>
                                  </Grid>
                                ))}
                            </>
                          )}
                          {/* Subcategory card ending */}
                        </Grid>
                      </>
                    </SortableItem>
                  ))
                ) : (
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      fontSize={HeaderFontSize_24}
                      className="Add-product-btn"
                    >
                      No Categories to display
                    </Typography>
                  </div>
                )}
              </SortableContext>
            </DndContext>

            {/* Category Card display ending */}

            {/* Sub Category adding dialog  */}

            {showAddSubCategoryDialog && (
              <>
                <CustomDialog
                  width="1200px"
                  height="600px"
                  borderRadius="20px"
                  open={showAddSubCategoryDialog}
                  onClose={handleClose}
                  titleAlign="center"
                  top={"27px"}
                  right={"27px"}
                  padding="10px 0px 10px 0px"
                  contentpadding="0px 20px 0px 20px"
                >
                  <DialogTitle>
                    <Typography
                      sx={{
                        color: "#170F49",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: "600",
                        lineHeight: "35px",
                      }}
                      fontSize={HeaderFontSize_24}
                    >
                      Add Sub Category
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <div className="edit-pdt">
                      <label className="text">
                        <Typography
                          fontSize={contentFontSize_16}
                          className="text-label"
                        >
                          Name of Sub Category
                        </Typography>
                      </label>
                      <TextField
                        variant="outlined"
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        InputProps={{
                          sx: {
                            fontSize: matches && "13px",
                          },
                        }}
                        size={matches ? "small" : "normal"}
                        name="nodename"
                        required
                        value={state1.nodename}
                        // onChange={(e)=>setState({nodename:e.target.value})}
                        onChange={(e) =>
                          setState1({ ...state1, nodename: e.target.value })
                        }
                      />
                      <div className="display-rank">
                        <label className="text">
                          <Typography
                            fontSize={contentFontSize_16}
                            className="text-label"
                          >
                            Display Rank
                          </Typography>
                        </label>
                        <label className="text">
                          <Typography
                            fontSize={contentFontSize_16}
                            className="text-label"
                          >
                            Status
                          </Typography>
                        </label>
                        <Select
                          className="custom-select_1"
                          borderColor="#72B01D"
                          onChange={(e) =>
                            setState1({
                              ...state1,
                              displayRank: e.target.value,
                            })
                          }
                          options={optionsrank}
                          // buttonColor="success"
                          width="100%"
                          variant="outlined"
                          borderRadius="10px"
                          sx={{
                            color: "white",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#85BE49",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#85BE49",
                            },
                          }}
                        >
                          <MenuItem value={0} label={0}>
                            0
                          </MenuItem>
                          {[
                            ...Array(
                              selectCategory.subcategories.length + 1
                            ).keys(),
                          ]
                            .slice(1)
                            .map((index) => (
                              <MenuItem key={index} value={index}>
                                {index}
                              </MenuItem>
                            ))}
                        </Select>
                        <Select
                          className="custom-select_1"
                          borderColor="#72B01D"
                          onChange={(e) =>
                            setState1({ ...state1, status: e.target.value })
                          }
                          id="name"
                          options={optionsstatus}
                          width="100%"
                          variant="outlined"
                          borderRadius="10px"
                          sx={{
                            color: "white",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#85BE49",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#85BE49",
                            },
                            // '.MuiSvgIcon-root ': {
                            //   fill: "white !important",
                            // }
                          }}
                        >
                          <MenuItem value={"active"}>Active</MenuItem>
                          <MenuItem value={"inactive"}>Inactive</MenuItem>
                        </Select>
                      </div>
                      <div className="edit-button">
                        <CustomButton
                          width="230px"
                          height="40px"
                          borderRadius="20px"
                          borderColor="#72B01D"
                          onClick={handleClose}
                          color={activeButton === "cancel" ? "white" : "grey"}
                          variant="outlined"
                          background={
                            activeButton === "cancel"
                              ? "rgb(101, 155, 27)"
                              : "white"
                          }
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          onClick={() =>
                            handleAddSubCategory(selectCategory.id, true)
                          }
                          width="230px"
                          height="40px"
                          borderRadius="20px"
                          color={activeButton === "add" ? "white" : "grey"}
                          variant="contained"
                          background={
                            activeButton === "add"
                              ? "rgb(101, 155, 27)"
                              : "white"
                          }
                        >
                          Add
                        </CustomButton>
                      </div>
                    </div>
                  </DialogContent>
                </CustomDialog>
              </>
            )}
            {/* Sub Category dialog end */}

            {/* {(newSub || subArrowOne ) && ( */}

            {/* Edit category dialog starting */}

            {editIcon && (
              <>
                <CustomDialog
                  width="600px"
                  height="600px"
                  borderRadius="20px"
                  open={editIcon}
                  onClose={handleClose}
                  titleAlign="center"
                  top={"27px"}
                  right={"27px"}
                  padding="10px 0px 10px 0px"
                  contentpadding="0px 20px 0px 20px"
                >
                  <DialogTitle>
                    <Typography
                      sx={{
                        color: "#170F49",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: "600",
                        lineHeight: "35px",
                      }}
                      fontSize={HeaderFontSize_24}
                    >
                      Edit Category
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{ padding: "5px 20px 15px 20px" }}>
                    <div className="edit-pdt">
                      <label className="text">Name of Category</label>
                      <TextField
                        className="custom-select_1"
                        name="name"
                        value={categoryData.name}
                        onChange={handleFieldChange}
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        InputProps={{
                          sx: {
                            fontSize: matches && "13px",
                          },
                        }}
                        size={matches ? "small" : "normal"}
                      />

                      <div className="display-rank">
                        <label className="text">
                          <Typography
                            fontSize={contentFontSize_16}
                            className="text-label"
                          >
                            Display Rank
                          </Typography>
                        </label>
                        <label className="text">
                          <Typography
                            fontSize={contentFontSize_16}
                            className="text-label"
                          >
                            Status
                          </Typography>
                        </label>
                        <Select
                          className="custom-select_1"
                          name="displayrank"
                          value={categoryData.displayrank}
                          onChange={handleFieldChange}
                          defaultValue={categoryData.displayrank}
                          buttonColor="success"
                          width="100%"
                          variant="outlined"
                          borderRadius="10px"
                          SelectProps={{
                            style: {
                              borderColor: "#85BE49",
                            },
                          }}
                        >
                          <MenuItem value={0}>0</MenuItem>
                          {Array.from(
                            { length: parseInt(categories.length) },
                            (_, i) => (
                              <MenuItem key={i + 1} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <Select
                          className="custom-select_1"
                          name="status"
                          value={categoryData.status}
                          onChange={handleFieldChange}
                          defaultValue={categoryData.status}
                          // buttonColor="success"
                          width="100%"
                          variant="outlined"
                          borderRadius="10px"
                        >
                          <MenuItem value={"active"}>Active</MenuItem>
                          <MenuItem value={"inactive"}>Inactive</MenuItem>
                        </Select>
                      </div>
                      <div className="edit-button">
                        <CustomButton
                          width="230px"
                          height="40px"
                          borderRadius="20px"
                          borderColor="#72B01D"
                          onClick={handleClose}
                          color={activeButton === "cancel" ? "white" : "grey"}
                          variant="outlined"
                          background={
                            activeButton === "cancel"
                              ? "rgb(101, 155, 27)"
                              : "white"
                          }
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          onClick={() =>
                            handleChange(
                              categoryData.id,
                              categoryData.name,
                              categoryData.displayrank,
                              categoryData.status,
                              null,
                              true
                            )
                          }
                          // onClick={handleSubmitOne}
                          width="230px"
                          height="40px"
                          borderRadius="20px"
                          color={activeButton === "add" ? "white" : "grey"}
                          variant="contained"
                          background={
                            activeButton === "add"
                              ? "rgb(101, 155, 27)"
                              : "white"
                          }
                        >
                          Change
                        </CustomButton>
                      </div>
                    </div>
                  </DialogContent>
                </CustomDialog>
              </>
            )}

            {/* Edit Category dialog ends */}

            {/* Edit SubCategory dialog */}

            {showEditSubCategoryDialog && (
              <>
                <CustomDialog
                  width="600px"
                  height="600px"
                  borderRadius="20px"
                  open={showEditSubCategoryDialog}
                  onClose={handleClose}
                  titleAlign="center"
                  top={"30px"}
                  right={"20px"}
                  padding="10px 0px 10px 0px"
                  contentpadding="0px 20px 0px 20px"
                >
                  <DialogTitle>
                    <Typography
                      sx={{
                        color: "#170F49",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: "600",
                        lineHeight: "35px",
                      }}
                      fontSize={HeaderFontSize_24}
                    >
                      Edit Sub Category
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{ padding: "5px 20px 15px 20px" }}>
                    <div className="edit-pdt">
                      <label className="text">Name of Sub Category</label>
                      <TextField
                        className="custom-select_1"
                        name="name"
                        value={subCategoryData.name}
                        onChange={handleSubChange}
                        sx={{
                          width: "100%",
                          borderRadius: "9px",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49", // This will change the border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49", // This will change the border color when focused
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                          },
                        }}
                        InputProps={{
                          sx: {
                            fontSize: matches && "13px",
                          },
                        }}
                        size={matches ? "small" : "normal"}
                      />
                      <div className="display-rank">
                        <label className="text">Display Rank</label>
                        <label className="text">Status</label>
                        
                        <Select
                          className="custom-select_1"
                          onChange={handleSubChange}
                          name="displayrank"
                          value={subCategoryData.displayrank}
                          width="100%"
                          variant="outlined"
                          borderRadius="10px"
                          defaultValue={subCategoryData.displayrank}
                        >
                          <MenuItem value="0">0</MenuItem>
                          {selectCategory &&
                            Array.from(
                              {
                                length: parseInt(
                                  selectCategory.subcategories.length
                                ),
                              },
                              (_, i) => (
                                <MenuItem key={i + 1} value={i + 1}>
                                  {i + 1}
                                </MenuItem>
                              )
                            )}
                        </Select>
                        <Select
                          className="custom-select_1"
                          id="name"
                          name="status"
                          value={subCategoryData.status}
                          buttonColor="success"
                          width="100%"
                          variant="outlined"
                          borderRadius="10px"
                          onChange={handleSubChange}
                          defaultValue={subCategoryData.status}
                        >
                          <MenuItem value={"active"}>Active</MenuItem>
                          <MenuItem value={"inactive"}>Inactive</MenuItem>
                        </Select>
                      </div>
                      <div className="edit-button">
                        <CustomButton
                          width="230px"
                          height="40px"
                          borderRadius="20px"
                          onClick={handleClose}
                          color={activeButton === "cancel" ? "white" : "grey"}
                          variant="outlined"
                          background={
                            activeButton === "cancel"
                              ? "rgb(101, 155, 27)"
                              : "white"
                          }
                          borderColor="#72B01D"
                        >
                          Cancel
                        </CustomButton>
                        <CustomButton
                          onClick={() =>
                            handleEditSubCategory(
                              subCategoryData.id,
                              subCategoryData.name,
                              subCategoryData.displayrank,
                              subCategoryData.status,
                              subCategoryData.categoryId
                            )
                          }
                          width="230px"
                          height="40px"
                          borderRadius="20px"
                          color={activeButton === "add" ? "white" : "grey"}
                          variant="contained"
                          background={
                            activeButton === "add"
                              ? "rgb(101, 155, 27)"
                              : "white"
                          }
                        >
                          Change
                        </CustomButton>
                      </div>
                    </div>
                  </DialogContent>
                </CustomDialog>
              </>
            )}

            {/* Edit subcategory dialog ends */}
          </div>
        </>
      )}
      {/* {subArrowNext && (
        <Categorynext
          setSubArrowNext={setSubArrowNext}
          props={props}
          categoryId={categoryId}
          departmentId={departmentId}
          categryName={categryName}
          subCategryName={subCategryName}
        />
      )} */}
    </>
  );
};

export default Categorypdt;
