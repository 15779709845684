import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import './EditRegister.css';
import {HeaderNavigationFontSize_20_xs_13,HeaderNavigationFontSize_20,contentFontSize_16,ButtonFontSize_18} from '../Typography/index';
import { Typography,useTheme,useMediaQuery,Grid,InputAdornment} from '../MUIComponents/index';
import { useNavigate } from "react-router-dom";
import EditIcon1 from "../../utils/Icon/EditIcon1.svg";
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import CustomTextField from '../Commoncomponents/input';
import CustomButton from '../Commoncomponents/button';
import indiaImage from "../../utils/images/indiaImage.svg";
import { useFormik } from 'formik';
import { editvalidationSchema } from './editvalidation';
import { TextField } from '../MUIComponents/index';
import { message } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import dummy from '../../utils/images/dummy.png';
import {Modal } from "../MUIComponents/index";
import EditShopAssistatnEditImage from '../ImageEditModal/EditShopAssistatnEditImage.js'


function EditRegister(props) {
  // Accessing the state from props.location.state
  const [formSubmitted,setFormSubmitted]=useState(false); 
  const [open6, setOpen6] = useState(false);
  const [ProfileImageTemp4, setProfileImageTemp4] = useState(null);
  const [openImageEdit1, setOpenImageEdit1] = useState(false)
  const [open3, setOpen3] = useState(true);
  const [state, setState] = React.useState({
    mapEditModal: false,
  });
  const [open8, setOpen8] = useState(false);
  const [open7,setOpen7]=useState(false);
  const [profileEdit2, setProfileEdit2] = useState(null)
  const location =useLocation()
  const { shopAssistantId, fname, lname, profilePicUrl,phoneNumber } = location.state;


  const nav = useNavigate();
  const dispatch=useDispatch();
  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  useEffect(()=>{
    setProfileImageTemp4(null);
    dispatch({
      type: "CLEAR_LOGO_URL"
    });
    dispatch({
      type:"CLEAR_EDIT_SHOPASSISTANT"
    })
  },[])

  useEffect(() => {

    // if (props?.newSignedLogoUrl) {
      // localStorage.setItem("ProfileImageTemp3", props.newSignedLogoUrl);

      setProfileImageTemp4(props?.newSignedLogoUrl);
   // }

  
}, [props.newSignedLogoUrl]);


const handleImageEdit = () => {
  //setState({ imageEditModal: !state.imageEditModal });
  if (props?.newSignedLogoUrl) {
    setState({ imageEditModal: true })
    localStorage.setItem("ProfileImagelocal", true)
    props?.newSignedUrl &&
      props.postNewUrl({
        url: props?.newSignedUrl,
        details: props?.retailerDetails?.data?.retailerdetails,

      });
  }
};

  useEffect(()=>{
    if(props.editShopassistantregistration){
      message.success({
        content: "Shop Assistant Updated Successfully",
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
      dispatch({
        type:"CLEAR_EDIT_SHOPASSISTANT"
      })

 setTimeout(() => {
        nav('/home/ShopAssistant');
        dispatch({
          type: "CLEAR_LOGO_URL"
        });
      }, 4000);


    }
  },[props.editShopassistantregistration])

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleBack=()=>{
    nav("/home/ShopAssistant/Viewshopassistants")
  }

  const handleClose6 = () => {
    setOpen6(false)
  }

 

  const handleClose8 = () => {
    setOpen8(false)
  }
  
  const handleClose7=()=>{
    setOpen7(false)
  }

  const handleOpen6 = () => {
    setOpen6(true)
  }
  const handleOpen8 = () => {
    setOpen8(true)
  }

  const handleOpen7=()=>{
    setOpen7(true)
  }


  const formik = useFormik({
	initialValues: {
      shopAssistantId:shopAssistantId,
	firstname:fname,
      lastname:lname,
      mobilenumber:phoneNumber,
     		},
		validationSchema: editvalidationSchema,
    onReset:(values)=>{
      formik.setValues({
         shopAssistantId:"",
	firstname:"",
      lastname:"",
      })
    },
		onSubmit: (values,{ resetForm}) => {
      props.editShopAssistant(
	values.shopAssistantId,
        values.firstname,
        values.lastname,
        profileEdit2 ? profileEdit2 : (profilePicUrl ? profilePicUrl : dummy)
      )
     
      setFormSubmitted(true)

      setTimeout(() => {
        dispatch({
          type:"CLEAR_EDIT_SHOPASSISTANT"
        })
      }, 500);
     
      
      //Wait for 3 seconds and then navigate to the next page
     
//       setTimeout(() => {
//         resetForm()
// }, 3500)
	},
	  });

    
  const editStoreLogo = () => {
    setOpenImageEdit1(true)
    setOpen3(true)
    dispatch({
      type: "CLEAR_LOGO_URL"
    });
  }

    useEffect(() => {
      if (!formik.isSubmitting) return;
      if (Object.keys(formik.errors).length > 0) {
        document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
      }
    }, [formik]);

  return (
    <>
    <div className="navigationstyleassitant">
            <Typography
              onClick={handleBack}
              style={{ cursor: "pointer",color:"#646464" }}
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              Shop Assistants
            </Typography>
            <Typography className="shopNavigationStyle" fontSize={HeaderNavigationFontSize_20_xs_13}>/</Typography>
           
              <Typography fontSize={HeaderNavigationFontSize_20_xs_13} style={{ color: "#659B1B" }} className="shopNavigationStyle">Edit Shop Assistant</Typography>
           
          </div>
          <div style={{margin:"20px 0px"}}>
          <Typography fontSize={HeaderNavigationFontSize_20} className="ShopAssistantFontStyle">Edit Shop Assistant</Typography>
          </div>
          <div className="updateProfileProfileImage">
             <div className="updateProfileProfileImage1">
               <div className="profile">
                 <div
                   style={{
                     width: matches? "80px":"100px",
                     height: matches? "80px":"100px",
                     borderRadius: "50%",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                   }}
                 >
                   {/* <img
                     src={examplemanimage}
                     alt="user profile image"
                     style={{width: matches? "80px":"100px",
                     height: matches? "80px":"100px",borderRadius: "50%",
                     border: "3px solid #72B01D"}}
                   /> */}
                   
{ProfileImageTemp4 ? (
                <img
                  src={
                    profileEdit2
                  }
                  alt="user profile image"
                  style={{
                    width: matches ? "80px" : "100px",
                    height: matches ? "80px" : "100px",
                    borderRadius: "50%",
                    border: "3px solid #72B01D",
                    cursor: "pointer"
                  }}
                  onClick={handleOpen6}
                />
              ) :

                (
                  profilePicUrl ? (<img
                    src={
                      profilePicUrl
                    }
                    alt="user profile image"
                    style={{
                      width: matches ? "80px" : "100px",
                      height: matches ? "80px" : "100px",
                      borderRadius: "50%",
                      border: "3px solid #72B01D",
                      cursor: "pointer",
                    }}
                    onClick={handleOpen7}
                  />) : (
                    <img
                      src={
                        dummy
                      }
                      alt="user profile image"
                      style={{
                        width: matches ? "80px" : "100px",
                        height: matches ? "80px" : "100px",
                        borderRadius: "50%",
                        border: "3px solid #72B01D",
                        cursor: "pointer",
                      }}
                      onClick={handleOpen8}
                    />
                  )
                )}



                   <div className="svg-icon" style={{width:matches?"20px":"29px",height:matches?"21px":"30px"}}>
                     {/* <img src={EditIcon1} alt="edit pen" /> */}

                                       
<img src={EditIcon1} alt="edit pen" onClick={editStoreLogo} style={{ cursor: "pointer" }} />
                {openImageEdit1 ? <EditShopAssistatnEditImage {...props} handleImageEdit={handleImageEdit} open3={open3} setProfileEdit2={setProfileEdit2} profileEdit2={profileEdit2} setOpen3={setOpen3} style={{ width: "100%" }} /> : null}
  
                   </div>
                 </div>
               </div>
             </div>
             </div>

             <Modal
            open={open6}
            style={modalStyle}
            onClose={handleClose6}

          >
            <img
              src={
                profileEdit2
                  ? profileEdit2
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen6}
            ></img>

          </Modal>
          <Modal
            open={open7}
            style={modalStyle}
            onClose={handleClose7}

          >
            <img
              src={
                profilePicUrl
                  ? profilePicUrl
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen7}
            ></img>

          </Modal>
          <Modal
            open={open8}
            style={modalStyle}
            onClose={handleClose8}

          >
            <img
              src={
                dummy
                  ? dummy
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen8}
            ></img>

          </Modal>
             <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
             <div  style={{ marginTop: "30px" }}>
             
              <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:"0px"}}>
              <div className="ShopAssistantFlex">
                <Typography fontSize={contentFontSize_16} className="shopNavigationStyle">
                  First Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                
                  name="firstname"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                  variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                      },
                  }}
                      size={matches ? "small" : "normal"}


                 
                />
              </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:smmatch&&"0px"}}>
              <div className="ShopAssistantFlex">
                <Typography fontSize={contentFontSize_16} className="shopNavigationStyle">
                  Last Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  
                  variant="outlined"
                 
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                  
                  sx={{
                    width: '100%',
                
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#85BE49', 
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#85BE49', 
                      },
                    },
                    '& .MuiInputBase-input': {
                      backgroundColor: 'white',
                      borderRadius:"10px" 
                    },
                  }}
                  InputProps={{
                   sx: {
                     fontSize: matches && '13px', 
                   },
               }}
                   size={matches ? "small" : "normal"}


                />
              </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:"0px"}}>
              <div className="ShopAssistantFlex">
                <Typography fontSize={contentFontSize_16} className="shopNavigationStyle">
                  Mobile No <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  
                 
                  
                  name="mobilenumber"
                  value={formik.values.mobilenumber}
                //  onChange={formik.handleChange}
                 // error={formik.touched.mobilenumber && Boolean(formik.errors.mobilenumber)}
                 // helperText={formik.touched.mobilenumber && formik.errors.mobilenumber}
                  disabled
                  variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                        background:"white"
                      },
                      startAdornment:(
                        <InputAdornment position="start">
                          <img
                            src={indiaImage}
                            alt="India flag"
                            style={{ marginRight: "8px" }}
                            width={matches&&"20px"}
                          />
                          <div style={{ color: "#919EAB" }}>+91 |</div>
                        </InputAdornment>
                      )
                  }}
                      size={matches ? "small" : "normal"}

                  
                 
                />
              </div>
                  </Grid>
              
                  </Grid>
            </div>
            <div style={{ marginTop: "40px", textAlign: "center" }}>
        
        <CustomButton  width={ upXlmatch ? '358px' : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? '250px' : '250px') : '310px') : '330px') : '340px') : '358px'} borderRadius="20px"  type='submit' matchPadding={true} padding="10px" background={formSubmitted?"gray":'#659B1B'} disabled={formSubmitted&&"disabled"}>
           <Typography fontSize={ButtonFontSize_18} className="ShopUpdateButtonStyle">  Edit Shop Assistant</Typography>
         
        </CustomButton>
      
    </div>
    </form>
     
    </>
  )
}

export default EditRegister
