import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";
import {
  TextField,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { ThemeProvider ,createTheme} from '@mui/material/styles';
import { green } from "@mui/material/colors";

function SelectField(props) {
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const  theme = createTheme({
		palette: {
		  primary: green,
		},
		overrides: {
      MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
        },
      },
      },
		},
	})

  return (
    <ThemeProvider theme={theme}>
      <TextField
       key={`${Math.floor(Math.random()*(9999-1000+1)+1000)}`}
      style={{marginBottom:'30px',marginLeft:10}}
      InputProps={{style:{width:'100%'}}}
      label={label}
        select
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        error={meta.touched && meta.error && true}
        helperText={_renderHelperText()}
        {...field}
        {...rest}
        SelectProps={{ style: { width: 285,height:35} }}
        value={selectedValue ? selectedValue : ""}
      >
        {data.map((item, index) => (
          <MenuItem key={index}
          style={{width:'fit-content',cursor:'pointer'}}
           value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
      </ThemeProvider>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
