import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../Marker";

const GoogleMap = ({ mapCoordinates }) => {
  const [center, setCenter] = useState({
    lat: 28.6139298,
    lng: 77.2088282,
  });

  useEffect(()=>{
    if(mapCoordinates){
        setCenter({
            lat: Number(mapCoordinates.lat),
            lng: Number(mapCoordinates.lng),
        });
    }
 
},[mapCoordinates])

  const changeMarkerPosition = (e) => {
    const { lat, lng } = e;
    setCenter({
      ...center,
      lat: Number(lat),
      lng: Number(lng),
    });
  };

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC8fl3_hu8DuU1UUlpRpyLQssuN0ZSYunU" }}
        defaultCenter={center}
        defaultZoom={15}
        center={center}
        onClick={changeMarkerPosition}
      >
        <Marker draggable={true} lat={center.lat} lng={center.lng} text="My Marker" />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
