// import React from "react";
// import { TextField } from "@mui/material";
// import {  Row, Col } from "react-bootstrap";

// import PersonIcon from '@mui/icons-material/Person';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { green } from "@mui/material/colors";
// import { useStyles } from "./style";
// import {Snackbar} from "@mui/material";
// import { AlertTitle } from '@mui/material';
// import Alert from '@mui/material/Alert';
// import { useFormik } from 'formik';
// import { validationSchema } from './validation';
// import { useMediaQuery } from 'react-responsive';
// import { Container } from "@mui/material";

// // function Alert(props) {
// // 	return <MuiAlert elevation={6} variant="filled" {...props} />;
// // }

// const Register = (props) => {
//   // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
//   const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
//   document.title ="Seller Portal | Shop Assistant";

//   const formik = useFormik({
// 		initialValues: {
// 		  firstname:"",
//       lastname:"",
//       mobilenumber:""
// 		},
// 		validationSchema: validationSchema,
//     onReset:(values)=>{
//       formik.setValues({
//         firstname:"",
//         lastname:"",
//         mobilenumber:""
//       })
//     },
// 		onSubmit: (values,{ resetForm}) => {
//       props.shopassistantregister(
//         values.firstname,
//         values.lastname,
//         values.mobilenumber,
//         localStorage.getItem("storeid")
//       )
//       setTimeout(() => {
//         resetForm()
// }, 2000)
// 		},
// 	  });

//   // const [Logopen, setLogOpen] = React.useState(true);

//   // const classes = useStyles();

//   const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//       overrides: {
//         MuiInputBase: {
//           input: {
//             '&:-webkit-autofill': {
//               transitionDelay: '9999s',
//               transitionProperty: 'background-color, color',
//             },
//           },
//         },
//       },
//   });

//   // const [state, setState] = React.useState({
//   //   firstname: "",
//   //   lastname: "",
//   //   mobilenumber: "",
//   // });

//   // const textChange = (e) => {
//   //   setState({
//   //     ...state,
//   //     [e.target.name]: e.target.value,
//   //   });
//   // };

//   // const submitClick = () => {

//   //     props.shopassistantregister(
//   //       state.firstname,
//   //       state.lastname,
//   //       state.mobilenumber,
//   //       localStorage.getItem("storeid")
//   //     );
//   // };
//   return (
//     isSmallScreen?
//     <div  style={{
//       backgroundColor: "white",
// 				border: "1px solid rgba(0, 0, 0, 0.16)",
// 				borderRadius: "10px",
//       Top: "40px",
//       padding: "30px",
//       width: "100%",
//     }}>

//       <Row>
//        {/* className={classes.headingRow}> */}
//       {props.shopassistantregistration||props.shopassistantFailed ?
// 				(

// 					<Snackbar
// 					style={{ width: "fit-content", }}
// 						   anchorOrigin={{
// 							 vertical: "top",
// 							 horizontal: "center"
// 						  }}
// 						open={props.shopassistantregistration||props.shopassistantFailed}
// 						autoHideDuration={3000}
// 						ContentProps={{
// 							'aria-describedby': 'message-id',
// 						  }}
// 						onClose={() => props.closeSnackBars() }>
// 						<Alert severity={props.shopassistantregistration?"success":"error"}>
// 							<AlertTitle>{props.shopassistantregistration
//               ?"Shop Assistant is registered successfully"
//             :props.shopassistantFailed
//             ?"Shop assistant exists already"
//           :null}</AlertTitle>
// 						</Alert>
// 					</Snackbar>

// 				) : null}
//          {/* {props.shopassistantregistration === true && props.error ?
// 				(

// 					<Snackbar
// 						style={{  width: "fit-content", left: "55%", bottom: "80%" }}
// 						open={Logopen}
// 						autoHideDuration={1000}
// 						ContentProps={{
// 							'aria-describedby': 'message-id',
// 						  }}
// 						onClose={() => setLogOpen(false) }>
// 						<Alert severity="success">
// 							<AlertTitle>{`Shop Assistant is registered successfully`}</AlertTitle>
// 						</Alert>
// 					</Snackbar>

// 				) : null} */}
//         <h4 >
//         {/* className={classes.heading}> */}
//           berlin up</h4>
//       </Row>
//       <form onSubmit={formik.handleSubmit} onReset={formik.handleReset} style={{width:'100%'}}>
//         <ThemeProvider theme={theme}>
//           <Row
//           //  className={classes.Row}
//           >
//             <Col md={11} sm={12}>
//               <TextField
//                 autoFocus
//                 InputProps={{
//                   style: { height: 35, width: "100%" },
//                   autoComplete: "off",
//                 }}
//                 autoComplete="off"
//                 fullWidth
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 variant="outlined"
//                 label="First Name"
//                 name="firstname"
//                 value={formik.values.firstname}
//                 onChange={formik.handleChange}
//                 error={formik.touched.firstname && Boolean(formik.errors.firstname)}
//                 helperText={formik.touched.firstname && formik.errors.firstname}
//                 // value={state.firstname}
//                 // onChange={(e) => textChange(e)}
//               />
//             </Col>
//           </Row>
//           <Row>
//            {/* className={classes.Row}> */}
//             <Col md={11} sm={11}>
//               <TextField
//                 InputProps={{
//                   style: { height: 35, width: "100%" },
//                 }}
//                 autoComplete="off"
//                 fullWidth
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 variant="outlined"
//                 label="Last Name"
//                 name="lastname"
//                 value={formik.values.lastname}
//                 onChange={formik.handleChange}
//                 error={formik.touched.lastname && Boolean(formik.errors.lastname)}
//                 helperText={formik.touched.lastname && formik.errors.lastname}
//                 // value={state.lastname}
//                 // onChange={(e) => textChange(e)}
//               />
//             </Col>
//           </Row>
//           <Row>
//            {/* className={classes.Row}> */}
//             <Col md={11} sm={11}>
//               <TextField
//                 InputProps={{
//                   style: { height: 35, width: "100%" },
//                 }}
//                 autoComplete="off"
//                 fullWidth
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 variant="outlined"
//                 label="Mobile Number"
//                 name="mobilenumber"
//                 value={formik.values.mobilenumber}
//                 onChange={formik.handleChange}
//                 error={formik.touched.mobilenumber && Boolean(formik.errors.mobilenumber)}
//                 helperText={formik.touched.mobilenumber && formik.errors.mobilenumber}
//                 // value={state.mobilenumber}
//                 // onChange={(e) => textChange(e)}
//               />
//             </Col>
//           </Row>
//           <Row>
//            {/* className={classes.buttonRow}> */}
//             <button type="submit"
//             //  className={classes.button}
//               >
//               Register
//             </button>
//           </Row>
//         </ThemeProvider>
//       </form>

//     </div>

//     :
//      <Container  maxWidth="sm"
//     //  className={classes.root}
//      >

//      <Row >
//      {/* className={classes.headingRow}> */}
//      {props.shopassistantregistration||props.shopassistantFailed ?
//        (

//          <Snackbar
//            style={{  width: "fit-content", left: "55%", bottom: "80%" }}
//            open={props.shopassistantregistration||props.shopassistantFailed}
//            autoHideDuration={3000}
//            ContentProps={{
//              'aria-describedby': 'message-id',
//              }}
//            onClose={() => props.closeSnackBars() }>
//            <Alert severity={props.shopassistantregistration?"success":"error"}>
//              <AlertTitle>{props.shopassistantregistration
//              ?"Shop Assistant is registered successfully"
//            :props.shopassistantFailed
//            ?"Shop assistant exists already"
//          :null}</AlertTitle>
//            </Alert>
//          </Snackbar>

//        ) : null}
//         {/* {props.shopassistantregistration === true && props.error ?
//        (

//          <Snackbar
//            style={{  width: "fit-content", left: "55%", bottom: "80%" }}
//            open={Logopen}
//            autoHideDuration={1000}
//            ContentProps={{
//              'aria-describedby': 'message-id',
//              }}
//            onClose={() => setLogOpen(false) }>
//            <Alert severity="success">
//              <AlertTitle>{`Shop Assistant is registered successfully`}</AlertTitle>
//            </Alert>
//          </Snackbar>

//        ) : null} */}
//        <h4 >
//        {/* className={classes.heading}> */}
//         berlin up</h4>
//      </Row>
//      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
//        <ThemeProvider theme={theme}>
//          <Row>
//            {/* className={classes.Row}> */}
//            <Col md={1} sm={1} >
//            {/* className={classes.icon}> */}
//              <PersonIcon fontSize="default" />
//            </Col>
//            <Col md={11} sm={11}>
//              <TextField
//                autoFocus
//                InputProps={{
//                  style: { height: 35, width: "100%" },
//                  autoComplete: "off",
//                }}
//                autoComplete="off"
//                fullWidth
//                InputLabelProps={{
//                  shrink: true,
//                }}
//                variant="outlined"
//                label="First Name"
//                name="firstname"
//                value={formik.values.firstname}
//                onChange={formik.handleChange}
//                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
//                helperText={formik.touched.firstname && formik.errors.firstname}
//                // value={state.firstname}
//                // onChange={(e) => textChange(e)}
//              />
//            </Col>
//          </Row>
//          <Row>
//            {/* className={classes.Row}> */}
//            <Col md={1} sm={1}>
//             {/* className={classes.icon}> */}
//              <PersonIcon fontSize="default" />
//            </Col>
//            <Col md={11} sm={11}>
//              <TextField
//                InputProps={{
//                  style: { height: 35, width: "100%" },
//                }}
//                autoComplete="off"
//                fullWidth
//                InputLabelProps={{
//                  shrink: true,
//                }}
//                variant="outlined"
//                label="Last Name"
//                name="lastname"
//                value={formik.values.lastname}
//                onChange={formik.handleChange}
//                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
//                helperText={formik.touched.lastname && formik.errors.lastname}
//                // value={state.lastname}
//                // onChange={(e) => textChange(e)}
//              />
//            </Col>
//          </Row>
//          <Row >
//          {/* className={classes.Row}> */}
//            <Col md={1} sm={1} >
//            {/* className={classes.icon}> */}
//              <LocalPhoneIcon fontSize="default" />
//            </Col>
//            <Col md={11} sm={11}>
//              <TextField
//                InputProps={{
//                  style: { height: 35, width: "100%" },
//                }}
//                autoComplete="off"
//                fullWidth
//                InputLabelProps={{
//                  shrink: true,
//                }}
//                variant="outlined"
//                label="Mobile Number"
//                name="mobilenumber"
//                value={formik.values.mobilenumber}
//                onChange={formik.handleChange}
//                error={formik.touched.mobilenumber && Boolean(formik.errors.mobilenumber)}
//                helperText={formik.touched.mobilenumber && formik.errors.mobilenumber}
//                // value={state.mobilenumber}
//                // onChange={(e) => textChange(e)}
//              />
//            </Col>
//          </Row>
//          <Row>
//            {/* className={classes.buttonRow}> */}
//            <button type="submit" >
//             {/* className={classes.button} > */}
//              Register
//            </button>
//          </Row>
//        </ThemeProvider>
//      </form>

//    </Container>
//   );
// };
// export default Register;






//new code




import React, { useEffect, useState } from "react";
import CustomCard from "../Commoncomponents/card/index";
import AssitantProfileImage from "../../utils/images/AssitantProfileImage.svg";
import "./Register.css";

import Arrow from "../../utils/Icon/Arrow.svg";
import threedot from "../../utils/Icon/threedot.svg";
import CustomButton from "../Commoncomponents/button/index";
// import editIconSA1 from '../../utils/Icon/editIconSA1.svg';
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import { Grid, Box, Typography } from "../MUIComponents/index";
import CustomTextField from "../Commoncomponents/input/index";
import { InputAdornment } from "../MUIComponents/index";
import indiaImage from "../../utils/images/indiaImage.svg";
import AssignmentPage from "./AssignmentPage";
import { usePopupState,Snackbar,AlertTitle,Alert } from "../MUIComponents/index";
import { popupState } from "../MUIComponents/index";
import editIconSA from "../../utils/Icon/editIconSA.svg";
import editIconSA1 from "../../utils/Icon/editIconSA1.svg";
import deleteIconSA from "../../utils/Icon/deleteIconSA.svg";
import { bindTrigger } from "../MUIComponents/index";
import EditIcon1 from "../../utils/Icon/EditIcon1.svg";
import CustomPopover from "../Commoncomponents/popup/index";
import {HeaderNavigationFontSize_20} from '../Typography/index';
import { useTheme } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import {ButtonFontSize_18} from '../Typography/index';
import {contentFontSize_16} from '../Typography/index';
import {HeaderNavigationFontSize_20_xs_13} from '../Typography/index';
import {contentFontSize_18_xs_13} from '../Typography/index';
import { useFormik } from 'formik';
import { validationSchema } from './validation';
import { useNavigate } from "react-router-dom";
import { TextField } from "../MUIComponents/index";
import { message } from "antd";
import { useDispatch } from "react-redux";
import dummy from '../../utils/images/dummy.png';
import {Modal } from "../MUIComponents/index";
import CreateShopAssistantEditImage from '../ImageEditModal/CreateShopAssistantEditImage.js'

export default function Register(props) {
  const [createPage, setCreatePage] = useState(true);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneno: "",
  });
  const [dataArray, setDataArray] = useState([]);
  const [threeDotState, setThreeDotState] = useState(false);
  const [ArrowState, setArrowState] = useState(false);
  const [registerPageState, setRegisterPageState] = useState(false);
  const [ArrowID, setArrowID] = useState("");
  const [registerEditState, setRegisterEditState] = useState("");
  const [editedArrayState, setEditedArrayState] = useState([]);
  const [popUpID,setPopUpID]=useState('');
  const [formSubmitted,setFormSubmitted]=useState(false); 
  const [open6, setOpen6] = useState(false);

  const [open8, setOpen8] = useState(false);

  
  const [ProfileImageTemp3, setProfileImageTemp3] = useState(null);
  useEffect(()=>{
    setProfileImageTemp3(null);
    dispatch({
      type: "CLEAR_LOGO_URL"
    });
    setProfileImageTemp3(null);
    dispatch({type:"CLEAR_CREATE_SHOPASSISTANT"})
  },[])
  const [profileEdit1, setProfileEdit1] = useState(null)
  const [openImageEdit1, setOpenImageEdit1] = useState(false)
  const [open3, setOpen3] = useState(true);
  const dispatch=useDispatch();

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [state, setState] = React.useState({
    mapEditModal: false,
  });
  
  const handleClose6 = () => {
    setOpen6(false)
  }

 

  const handleClose8 = () => {
    setOpen8(false)
  }
  

  const handleOpen6 = () => {
    setOpen6(true)
  }
  const handleOpen8 = () => {
    setOpen8(true)
  }



 

useEffect(()=>{

  if(props.shopassistantFailed){
    message.error({
      content: `${props.createerror}`,
      className: "custom-class",
      style: {
        marginTop: "10vh",
        marginLeft: "15vw",
      },
      duration: 3.5,
    });
    setTimeout(() => {
      setFormSubmitted(false)
      formik.resetForm()
    }, 3500);
    

  }else if(props.shopassistantregistration){
  setFormSubmitted(true)
  message.success({
    content: "Shop Assistant created Successfully",
    className: "custom-class",
    style: {
      marginTop: "10vh",
      marginLeft: "15vw",
    },
    duration: 3.5,
  });
  dispatch({ type: "CLEAR_CREATE_SHOPASSISTANT" });
  //Wait for 3 seconds and then navigate to the next page
  setTimeout(() => {
    nav('/home/ShopAssistant');
    dispatch({
      type: "CLEAR_LOGO_URL"
    });
  }, 4000);}

},[props.shopassistantFailed,props.shopassistantregistration])


const handleImageEdit = () => {
  //setState({ imageEditModal: !state.imageEditModal });
  if (props?.newSignedLogoUrl) {
    setState({ imageEditModal: true })
    localStorage.setItem("ProfileImagelocal", true)
    props?.newSignedUrl &&
      props.postNewUrl({
        url: props?.newSignedUrl,
        details: props?.retailerDetails?.data?.retailerdetails,

      });
  }
};



  const nav = useNavigate();

  const popupState1 = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });
  const handleCreateEvent = () => {
    setCreatePage(false);
    setRegisterPageState(true);
    setRegisterEditState("");
    setEditedArrayState([]);
  };

  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, phoneno } = formData;
    const newAssistant = {
      id: dataArray.length,
      firstName,
      lastName,
      phoneno,
    };
    setDataArray((prevArray) => [...prevArray, newAssistant]);

    setFormData({
      firstName: "",
      lastName: "",
      phoneno: "",
    });
    setCreatePage(true);
  };



  const handleRedDot = (id) => {
    setArrowState(!ArrowState);
    setCreatePage(false);
    setThreeDotState(false);
    setRegisterPageState(false);
    setArrowID(id);
  };

  const handleRegisterEditState = (id) => {
    const index = dataArray.findIndex((item) => item.id === id);
    const editedItem = dataArray[index];

    setEditedArrayState([editedItem]);

    setRegisterEditState(id + 1);
    setCreatePage(false);
    setRegisterPageState(true);
  };

  const handleRegisterDeleteState = (deleteid) => {
    const filteredArray = dataArray.filter((e) => e.id !== deleteid);
    setDataArray(filteredArray);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedArrayState((prevState) => ({
      ...prevState,
      [0]: {
        ...prevState[0],
        [name]: value,
      },
    }));
  };

  
  const editStoreLogo = () => {
    setOpenImageEdit1(true)
    setOpen3(true)
    dispatch({
      type: "CLEAR_LOGO_URL"
    });
  }

  const ProfileImageLocal = localStorage.getItem("ProfileImagelocal")
  useEffect(() => {

      // if (props?.newSignedLogoUrl) {
        // localStorage.setItem("ProfileImageTemp3", props.newSignedLogoUrl);
  
        setProfileImageTemp3(props?.newSignedLogoUrl);
     // }
  
    
  }, [props.newSignedLogoUrl]);

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const updatedDataArray = dataArray.map((item) => {
      if (item.id === registerEditState - 1) {
        return {
          ...item,
          firstName: editedArrayState[0].firstName,
          lastName: editedArrayState[0].lastName,
          phoneno: editedArrayState[0].phoneno,
        };
      }
      return item;
    });

    setDataArray(updatedDataArray);
    setRegisterEditState("");
    setCreatePage(true);
  };

  const handlePopUpID=(id)=>{ 
      setPopUpID(id)
  }

  const handleBack=()=>{
    nav("/home/ShopAssistant/Viewshopassistants")
  }

    const formik = useFormik({
		initialValues: {
		  firstname:"",
      lastname:"",
      mobilenumber:""
		},
		validationSchema: validationSchema,
    onReset:(values)=>{
      formik.setValues({
        firstname:"",
        lastname:"",
        mobilenumber:""
      })
    },
		onSubmit: (values,{ resetForm}) => {
      props.shopassistantregister(
        values.firstname,
        values.lastname,
        values.mobilenumber,
        localStorage.getItem("storeid"),
        profileEdit1?profileEdit1:dummy,
      )

      setFormSubmitted(true);

  setTimeout(() => {
    dispatch({ type: "CLEAR_CREATE_SHOPASSISTANT" });
  }, 500);

  
      
      
		},
	  });

    useEffect(() => {
      if (!formik.isSubmitting) return;
      if (Object.keys(formik.errors).length > 0) {
        document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
      }
    }, [formik]);
  return (
    <>
    

      {/* next page */}

        <>
          <div className="navigationstyleassitant">
            <Typography
              onClick={handleBack}
              style={{ cursor: "pointer",color:"#646464" }}
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              Shop Assistants
            </Typography>
            <Typography className="shopNavigationStyle" fontSize={HeaderNavigationFontSize_20_xs_13}>/</Typography>
           
              <Typography fontSize={HeaderNavigationFontSize_20_xs_13} style={{ color: "#659B1B" }} className="shopNavigationStyle">Create Shop Assistants</Typography>
           
          </div>
          <div style={{margin:"20px 0px"}}>
            <Typography fontSize={HeaderNavigationFontSize_20} className="ShopAssistantFontStyle">Create Shop Assistant</Typography>
            </div>

          
            <div className="updateProfileProfileImage">
            <div className="updateProfileProfileImage1">
              <div className="profile">
                <div
                  style={{
                    width: matches? "80px":"100px",
                    height: matches? "80px":"100px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <img
                    src={examplemanimage}
                    alt="user profile image"
                    style={{width: matches? "80px":"100px",
                    height: matches? "80px":"100px",borderRadius: "50%",
                    border: "3px solid #72B01D"}}
                  /> */}
                  {ProfileImageTemp3 ? (
                <img
                  src={
                    profileEdit1
                  }
                  alt="user profile image"
                  style={{
                    width: matches ? "80px" : "100px",
                    height: matches ? "80px" : "100px",
                    borderRadius: "50%",
                    border: "3px solid #72B01D",
                    cursor: "pointer"
                  }}
                  onClick={handleOpen6}
                />
              ) :

                 (
                    <img
                      src={
                        dummy
                      }
                      alt="user profile image"
                      style={{
                        width: matches ? "80px" : "100px",
                        height: matches ? "80px" : "100px",
                        borderRadius: "50%",
                        border: "3px solid #72B01D",
                        cursor: "pointer",
                      }}
                      onClick={handleOpen8}
                    />
                  )
                }

                  <div className="svg-icon" style={{width:matches?"20px":"29px",height:matches?"21px":"30px"}}>
                    {/* <img src={EditIcon1} alt="edit pen" /> */}
                    
<img src={EditIcon1} alt="edit pen" onClick={editStoreLogo} style={{ cursor: "pointer" }} />
                {openImageEdit1 ? <CreateShopAssistantEditImage {...props} handleImageEdit={handleImageEdit} open3={open3} setProfileEdit1={setProfileEdit1} profileEdit1={profileEdit1} setOpen3={setOpen3} style={{ width: "100%" }} /> : null}
                  </div>
                </div>
              </div>
            </div>
            </div>

                <div>
                <Modal
            open={open6}
            style={modalStyle}
            onClose={handleClose6}

          >
            <img
              src={
                profileEdit1
                  ? profileEdit1
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen6}
            ></img>

          </Modal>
        
          <Modal
            open={open8}
            style={modalStyle}
            onClose={handleClose8}

          >
            <img
              src={
                dummy
                  ? dummy
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen8}
            ></img>

          </Modal>
                </div>

           
            {/* {props.shopassistantregistration||props.shopassistantFailed ?
				(

					<Snackbar
					style={{ width: "fit-content", }}
						   anchorOrigin={{
							 vertical: "top",
							 horizontal: "center"
						  }}
						open={props.shopassistantregistration||props.shopassistantFailed}
						autoHideDuration={3000}
						ContentProps={{
							'aria-describedby': 'message-id',
						  }}
						onClose={() => props.closeSnackBars() }>
						<Alert severity={props.shopassistantregistration?"success":"error"}>
							<AlertTitle>{props.shopassistantregistration
              ?"Shop Assistant is registered successfully"
            :props.shopassistantFailed
            ?"Shop assistant exists already"
          :null}</AlertTitle>
						</Alert>
					</Snackbar>

				) : null} */}




         
            <div>
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <div style={{ marginTop: "30px" }}>
              
               <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:"0px"}}>
                   
              <div className="ShopAssistantFlex">
                <Typography fontSize={contentFontSize_16} className="shopNavigationStyle">
                  First Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  
                  name="firstname"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                  variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                      },
                  }}
                      size={matches ? "small" : "normal"}

                />
              </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:smmatch&&"0px"}}>
              <div className="ShopAssistantFlex">
                <Typography fontSize={contentFontSize_16} className="shopNavigationStyle">
                  Last Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                 
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                  variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                      },
                  }}
                      size={matches ? "small" : "normal"}

                />
              </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:"0px"}}>
              <div className="ShopAssistantFlex">
                <Typography fontSize={contentFontSize_16} className="shopNavigationStyle">
                  Mobile No <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  
                 
                  
                  name="mobilenumber"
                  value={formik.values.mobilenumber}
                  onChange={formik.handleChange}
                  error={formik.touched.mobilenumber && Boolean(formik.errors.mobilenumber)}
                  helperText={formik.touched.mobilenumber && formik.errors.mobilenumber}
                  
                  variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                        background:"white"
                      },
                      startAdornment:(
                        <InputAdornment position="start">
                          <img
                            src={indiaImage}
                            alt="India flag"
                            style={{ marginRight: "8px" }}
                            width={matches&&"20px"}
                          />
                          <div style={{ color: "black" }}>+91 |</div>
                        </InputAdornment>
                      )
                  }}
                      size={matches ? "small" : "normal"}

                  
                 
                />
              </div>
                  </Grid>
                  </Grid>
             
            </div>
          
          {/* end */}
          <div style={{ marginTop: "40px", textAlign: "center" }}>
        
              <CustomButton  width={ upXlmatch ? '358px' : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? '250px' : '250px') : '310px') : '330px') : '340px') : '358px'} borderRadius="20px"  type='submit' matchPadding={true} padding="10px"    background={formSubmitted?"gray":'#659B1B'}
              disabled={formSubmitted&&"disabled"}>
                 <Typography fontSize={ButtonFontSize_18} className="ShopUpdateButtonStyle">  Create Shop Assistant</Typography>
               
              </CustomButton>
            
          </div>
          </form>
          </div>
       
        </>
    












      {/* {threeDotState && !createPage && !registerPageState && (
        <div>New Page for Three Dot State</div>
      )}

      {ArrowState && !createPage && !registerPageState && (
        <div>
          <AssignmentPage dataArray={dataArray} ArrowID={ArrowID} />
        </div>
      )} */}
    </>
  );
}
