import { gql } from "@apollo/client";
export const UPDATE_HEIRARCHY = gql`
  mutation (
    $hierarchyid: ID!
    $name: String
    $parentid: ID
    $status: String
    $displayrank: Int
    $childCategoryPresent: Boolean
  ) {
    updateSalesHierarchy(
      hierarchyid: $hierarchyid
      name: $name
      parentid: $parentid
      status: $status
      displayrank: $displayrank
      childCategoryPresent: $childCategoryPresent
    ) {
      id
      name
      parentid {
        id
        name
      }
      status
      storeid
      displayrank
      childCategoryPresent
    }
  }
`;
export const UPDATE_HEIRARCHY1 = gql`
  mutation (
    $hierarchyid: ID!
    $name: String
    $status: String
    $displayrank: Int
    $childCategoryPresent: Boolean
  ) {
    updateSalesHierarchy(
      hierarchyid: $hierarchyid
      name: $name
      status: $status
      displayrank: $displayrank
      childCategoryPresent: $childCategoryPresent
    ) {
      id
      name
      parentid {
        id
        name
      }
      status
      storeid
      displayrank
      childCategoryPresent
    }
  }
`;
export const CREATE_HEIRARCHY = gql`
  mutation (
    $storeid: ID!
    $name: String!
    $displayrank: Int!
    $parentid: ID
    $status: String!
    $childCategoryPresent: Boolean!
    $level:Int
    $parentChildCategory:Boolean
  ) {
    createSalesHierarchy(
      storeid: $storeid
      level:$level
      parentChildCategory:$parentChildCategory
      name: $name
      displayrank: $displayrank
      parentid: $parentid
      status: $status
      childCategoryPresent: $childCategoryPresent
    ) {
      id
      name
      parentid {
        id
        name
      }
      status
      storeid
      displayrank
      childCategoryPresent
    }
  }
`;

export const CREATE_FIRSTLEVEL_HEIRARCHY = gql`
  mutation (
    $storeid: ID!
    $name: String!
    $displayrank: Int!
    $parentid: ID
    $status: String!
    $childCategoryPresent: Boolean!
    $level:Int
  ) {
    createSalesHierarchy(
      storeid: $storeid
      level:$level
      name: $name
      displayrank: $displayrank
      parentid: $parentid
      status: $status
      childCategoryPresent: $childCategoryPresent
    ) {
      id
      name
      parentid {
        id
        name
      }
      status
      storeid
      displayrank
      childCategoryPresent
    }
  }
`;