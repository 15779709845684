export const MAIN_HEADING = "PRIVACY POLICY ";
export const DESCRIPTION = `This Privacy Policy (“Policy”) describes the policies and procedures on the collection, use, disclosure and protection of YOUR information when YOU use our website located at www.nearshopz.com, or the NearShopz mobile application (collectively, “NearShopz Platform”) made available by 4LabsTechnologies Private Limited (“4Labs”, “Company”, “We”, “Us” and “Our”), a private company established under the laws of India having its registered office at VII/443A, Manakkad PO, Thodupuzha 685608. The terms “YOU” and “YOUR” refer to the user of the NearShopz Platform. The term “Services” refers to any services offered by us whether on the Platform or otherwise. Please read this Policy before using the NearShopz Platform or submitting any personal information to NearShopz. This Policy is a part of and incorporated within, and is to be read along with, the Terms of Use.
`;
export const privacypolicy = [
    {
        index:1,
        heading:"Your Consent",
        description:`By using the NearShopz Platform and the Services, YOU agree and consent to the collection, transfer, use, storage, disclosure and sharing of YOUR information as described and collected by us in accordance with this Policy. If YOU do not agree with the Policy, please do not use or access the NearShopz Platform.`,
    },
    {
        index:2,
        heading:"Policy Changes",
        description:`We reserve the unconditional right to change, modify, add, or remove portions of this Privacy Policy at any time, without specifically notifying You of such changes. Any changes or updates will be effective immediately. If we make any significant changes, we will endeavor to provide YOU with reasonable notice of such changes, such as via prominent notice on the Platform or to YOUR email address on record and where required by applicable law, we will obtain YOUR consent. To the extent permitted under the applicable law, YOUR continued use of our Services after we publish or send a notice about our changes to this Policy shall constitute YOUR consent to the updated Policy.`,
    },
    {
        index:3,
        heading:"Links to Other Sites",
        description:`Our Website links to other websites that may collect personally identifiable information about you. NearShopz is not responsible for the privacy practices or the content of those linked websites. `,
    },
    {
        index:4,
        heading:"Collection of Information ",
        description:`In order to enhance the user experience and provide Our Services, Company collects information about You: (a) information You affirmatively and voluntarily give to us; (b) information automatically collected when You visit Our Platform or use any of Our Services; and (c) information we receive from other sources `,
    },
    {
        index:5,
        heading:"Cookies",
        description:`A "cookie" is a small piece of information stored on a web browser/device so it can be later read back. Cookies are useful for enabling the browser/device to remember information specific to a given user. We place both permanent and temporary cookies. The cookies do not contain any of your personally identifiable information. `,
    },
    {
        index:6,
        heading:" Use of Personal Information ",
        description:`The information collected by Us through our Platform maybe used for the following purposes: 
       (i) To allow You to use Services on the Platform; 

    (ii) To allow You to undertake or initiate any transactions on the Platform 

(iii) For internal record keeping of the Company; 

(iv) To improve our Services; 

(v) To improve Your usage of the Platform, to manage Your Account, etc 

(vi) To process payments with respect to transactions initiated on the Platform; 

(vii) To respond to Your comments, reviews and questions and provide better Service; 

(viii) To communicate important notices or changes to the Services provided by Company on the Platform, use of the Platform and the terms/policies which govern the relationship between You and the Company; 

(ix) or internal purposes of the Company such as auditing, data analysis and research conducted either indirectly/directly by Company 

(x) or promotion and marketing purposes by Company; 

for any other purposes with Your consent 
        From time to time, we may use Your information to customize, develop, improve the Platform according to your interests 

We may use personal information to resolve disputes that may arise with the use of Our services on the Platform, help promote a safe service to You on the Platform, measure consumer interest in Our services, customize Your experience, detect and protect Us against error, fraud and other illegal activity, enforce Our terms and conditions. 

We identify and use your IP address to help diagnose problems with our server, and to administer our Platform. Your IP address is also used to help identify You and to gather broad demographic information 

We shall be entitled to retain Your Personal Information for such duration as may be required for the purposes specified hereunder and shall be used by Us only in consonance with this Privacy Policy `,
    },
    {
        index:7,
        heading:" Sharing of Personal Information",
        description:`We share Your information with third parties and third-party service providers including Merchants or insurance service providers upon receipt of request by You to initiate a transaction to carry out specific service requests and fulfil such service requests. 

        We may also share your information with payment service providers for the purpose of fulfilling Services to you. 
        
        We may disclose Your personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our Users or the general public. 
        
        Company may need to disclose Your information, to 
        (i) protect and defend the rights or property of the Company, including to enforce Our agreements, policies, and Terms and; 

(ii) protect the personal safety of You, the Company, its members and employees, or any person, in an emergency; and 

(iii) protect Company from incurring any legal liability. In such an event Company shall be under no obligation to specifically inform You or seek Your approval or consent.
Any content or personal information that You share or upload on any current or future publicly viewable portion of the Platform (on discussion boards, in messages and chat areas, etc.) will be publicly available, and can be viewed by others. 

If You provide a mobile phone number and/or e-mail address, the Company, or other parties registered on the Platform may call You or send You communications in relation to Your use of the Platform or Your any transaction initiated by You on the Platform. We and our affiliates will share/transfer some or all of the collected information (personal or otherwise) with another business entity should We (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business, in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction or proceeding. Should such a transaction occur, that other business entity (or the new combined entity) will be contractually bound to comply with the terms of this Privacy Policy.  `,
    },
    {
        index:8,
        heading:"Security Precautions",
        description:`All information is saved and stored on servers, which are secured with passwords and pins to ensure no unauthorised person has access to it. Once your information is in Our possession, we adhere to strict security guidelines, protecting it against unauthorized access. Company shall take reasonable steps to help protect Your rights of privacy and Your information (personal or otherwise) in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration, or destruction of such information, in compliance with the applicable laws. You expressly consent to the sharing of Your information with third party service providers, including Merchants, insurance agents and insurers, financial service providers, payment gateways, to process payments and manage payment card information. Company does not itself store Your payment card account information and does not have direct control over or responsibility for Your payment card account information. Hence, Company cannot guarantee that transmissions of Your payment card account information or personal information will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by Company or Company’s third-party service providers. Company assumes no liability or responsibility for disclosure of Your information due to any reason, including but not limited to errors in transmission, unauthorized third-party access, or other causes beyond its control. Although we shall try our best to protect Your data, we cannot take any guarantee for the security of Your data transmitted through the Platform. You play an important role in keeping Your personal information secure. You shall not share Your username, password, or other security information for Your account with anyone.  `,
    },
    {
        index:9,
        heading:"Grievances",
        description:`In the event You have any grievances relating to the Privacy Policy, please inform within 24 hours of occurrence of the instance from which the grievance has arisen, by writing an email to the Grievance Officer at support@nearshopz `,
    },
    {
        index:10,
        heading:"Transportation/ Delivery Services from Merchant Establishments",
        description:`You acknowledge that the Users may place orders with Merchants listed on the Platform through the Platform to purchase products/avail services (“Items”) from the Merchants. In this regard, You shall provide transportation/delivery services from the Merchant establishment to the location specified by the User with respect to the transactions initiated by the User on this Platform (“Delivery Services”). Unless otherwise specified, You hereby agree that while performing Delivery Services pertaining to the Item from the Merchant to the User, You acknowledge that NearShopz is only a facilitator of the transactions that take place on the Platform and NearShopz shall not be a party to any transaction that is initiated on the Platform. You shall be responsible for safely delivering the Items to the Users or to any person as may be specified by the User.
        You agree that Nearshopz will not be liable for delivery.It is the responsibility of the merchant.It is your responsibility to count and check all the products while accepting the Products as per your Order. No dispute will be entertained by Nearshopz once you accept the products at the Delivery Point. 
        `,
    },
    ];
