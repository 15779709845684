import React from "react";
// import { makeStyles } from "@mui/system";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FormLabel from "@mui/material/FormLabel";
import ImageEdit from "./ImageEdit";


// const useStyles = makeStyles({
// 	root: {
// 		maxWidth:300,
// 	},
// 	media: {
	
// 		width:300,
// 		height: 300,
// 	},
// });

const EditImageField = (props) => {
	// const classes = useStyles();
	const [state, setState] = React.useState({ isModalUp: false });
	let Logo = `${props.retailerDetails&&
		props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
		props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl}`;
	return (
		<>
			<FormLabel component="legend">Store Logo</FormLabel>
			<Card 
			// className={classes.root}
			>
				<CardActionArea>
					<CardMedia
						// className={classes.media}
						image={Logo}
						title="Store Logo"
					/>
					<CardContent></CardContent>
				</CardActionArea>
				<CardActions>
				<ImageEdit {...props}/>
					{/* <button 
					type="button"
					 style={{
						height: "37px",
						width: "250px",
						backgroundColor: "white",
						color: "#75c224",
						fontSize: "20px",
						fontWeight: 600,
						border: "none",
					  }}
					   onClick={props.handleImageEdit}>
						Upload New
					</button> */}
				</CardActions>
			</Card>
		</>
	);
};
export default EditImageField;

