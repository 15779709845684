import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



const SearchProductCard = (props) =>{
	// const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
return (

	isSmallScreen ? (
		<Grid item xs={12} md={12}>
			<TableContainer
          style={{
            marginTop: 20,
			width: "100%"
          }}
          component={Paper}
        >
  <Table  >
    <TableHead>
      <TableRow style={{textAlign:'center',alignItems:"center",justifyContent:'center'}}>
        <TableCell>Image</TableCell>
        <TableCell>Product name</TableCell>
        <TableCell>Qty</TableCell>
		<TableCell>Ordered</TableCell>
		<TableCell>Delivered Qty</TableCell>
		<TableCell>Unit price</TableCell>
		<TableCell>Total</TableCell>
      </TableRow>
    </TableHead>

    <TableBody style={{textAlign:'center',alignItems:"center",justifyContent:'center'}}>
     
	  {props.searchDetails &&
								props.searchDetails.products.length &&
								props.searchDetails.products.map((product,index) => {
									return (
										<TableRow key={index}>						
        <TableCell>
	<img src={product.productid.image&&product.productid.image.primary} alt='prodct.png' style={{width:'20px',height:'30px'}}/>
		</TableCell>
        <TableCell>{product.productid.productname}
		</TableCell>
		<TableCell >
		{`${product.productid.quantity}`}{'\t'}{product.productid.uom!==null&&`${product.productid.uom}`}
        </TableCell>
		<TableCell >
		{product.quantity}
        </TableCell>
		<TableCell >
		{product.shopAssistantQuantity===null?"---":product.shopAssistantQuantity}
        </TableCell>
        <TableCell >
		{parseFloat(product.productPrice).toFixed(2)}
        </TableCell>
		<TableCell >
		{parseFloat(product.price).toFixed(2)}
        </TableCell>
		</TableRow>
									)})}
    
    </TableBody>
  </Table>
  </TableContainer>
  </Grid>
	)
	:
	(
		<TableContainer component={Paper}>
		<Table>
		<TableHead>
        <TableRow>
        <TableCell   align="left">Image</TableCell>
        <TableCell  align="left">Product name</TableCell>
        <TableCell  align="left">Qty</TableCell>
		<TableCell   align="left">Ordered</TableCell>
		<TableCell  align="left">Delivered Qty</TableCell>
		<TableCell  align="left">Unit price</TableCell>
		<TableCell  align="left">Total</TableCell>
		</TableRow>
      </TableHead>

    <TableBody style={{textAlign:'center',alignItems:"center",justifyContent:'center'}}>
     
	  {props.searchDetails &&
								props.searchDetails.products.length &&
								props.searchDetails.products.map((product,index) => {
									return (
										<TableRow key={index}>						
        <TableCell>
	<img src={product.productid&&product.productid.image&&product.productid.image.primary} alt='prodct.png' style={{width:'20px',height:'30px'}}/>
		</TableCell>
        <TableCell>{product.productid&&product.productid.productname}
		</TableCell>
		<TableCell >
		{`${product.productid&&product.productid.quantity}`}{'\t'}{product.productid&&product.productid.uom!==null&&`${product.productid&&product.productid.uom}`}
        </TableCell>
		<TableCell >
		{product.quantity}
        </TableCell>
		<TableCell >
		{product.shopAssistantQuantity===null?"---":product.shopAssistantQuantity}
        </TableCell>
        <TableCell >
		{parseFloat(product.productPrice).toFixed(2)}
        </TableCell>
		<TableCell >
		{parseFloat(product.price).toFixed(2)}
        </TableCell>
		</TableRow>
									)})}
    
    </TableBody>
  </Table>
  </TableContainer>

	)
)}

export default SearchProductCard