import React from 'react'
import { Typography } from '../MUIComponents/index';
import {HeaderFontSize_24} from '../Typography/index';
import {HeaderNavigationFontSize_12} from '../Typography/index';
import { CircularProgressbar } from "react-circular-progressbar";
import { fShortenNumber } from "./formatNumbers";

export default function InactiveProducts({ upXlmatch, xlmatch, lgmatch, mdmatch, smmatch, xsmatch,...props }) {
    const percentage = 66;
   

  return (
    <>
    <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  height:"100%",
                  width:upXlmatch&&"230px",
                  gap: upXlmatch
                    ? "0px"
                    : xlmatch
                    ? lgmatch
                      ? mdmatch
                        ? smmatch
                          ? xsmatch
                            ? "5px"
                            : "3px"
                          : "5px"
                        : "5px"
                      : "40px"
                    : "40px",
                }}
                
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // gap: upXlmatch
                    //   ? "8px"
                    //   : xlmatch
                    //   ? lgmatch
                    //     ? mdmatch
                    //       ? smmatch
                    //         ? xsmatch
                    //           ? "0px"
                    //           : "1px"
                    //         : "4px"
                    //       : "5px"
                    //     : "6px"
                    //   : "7px",
                      justifyContent: "space-between",
                  }}
                >
                  <Typography
                    fontSize={HeaderFontSize_24}
                    className="dashboardFontFamily"
                  >
                    {props?.NewDashboardDetails
                    &&props?.NewDashboardDetails?.getDashboardNew
                    &&
                    props?.NewDashboardDetails?.getDashboardNew?.totalInactiveProducts}
                  </Typography>
                  <Typography
                    fontSize={HeaderNavigationFontSize_12}
                    className="dashboardFontFamily"
                    style={{ color: "#B4B1B1" }}
                  >
                    Inactive Products
                  </Typography>
                  <Typography
                    fontSize={HeaderNavigationFontSize_12}
                    className="dashboardFontFamily"
                    style={{ color: "#659B1B" }}
                  >
                    +456.65
                  </Typography>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: smmatch && "flex-end" || (upXlmatch ? "flex-start" : "center"),
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      styles={{
                        root: {
                          height: upXlmatch
                            ? "59px"
                            : xlmatch
                            ? lgmatch
                              ? mdmatch
                                ? smmatch
                                  ? xsmatch
                                    ? "45px"
                                    : "45px"
                                  : "45px"
                                : "45px"
                              : "50px"
                            : "100px",
                          width: upXlmatch
                            ? "59px"
                            : xlmatch
                            ? lgmatch
                              ? mdmatch
                                ? smmatch
                                  ? xsmatch
                                    ? "45px"
                                    : "45px"
                                  : "45px"
                                : "45px"
                              : "50px"
                            : "100px",
                        },
                        path: {
                          stroke: `#0095C5`,
                          strokeLinecap: "butt",
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          transform: "rotate(0.15turn)",
                          transformOrigin: "center center",
                        },
                        trail: {
                          stroke: "rgba(0, 149, 197, 0.25)",
                          strokeLinecap: "butt",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        text: {
                          fill: "green",
                          fontSize: "13px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

    </>
  )
}
