// import React from 'react';
// import { Container } from "@mui/material";
// import { Row, Col } from 'react-bootstrap';
// import Checkbox from "@mui/material/Checkbox";
// // import { withStyles } from "@mui/styles";
// import { relativeTimeRounding } from 'moment';

// export default function BxgyProducts(props) {
//     const [CheckProducts, setCheckProducts] = React.useState([]);
//     const [CategoryCount, setCategoryCount] = React.useState(null)
//     const [CategoryId, setCategoryId] = React.useState(null)
//     const [checkAll, setCheckAll] = React.useState(false);
//     const [first, setFirst] = React.useState(50);

//     React.useEffect(() => {
//         if (props.addCatalogProductSuccess === true) {
//             setCheckProducts([]);
//             setCheckAll(false)
//         }
//     },// eslint-disable-next-line react-hooks/exhaustive-deps
//         [props.addCatalogProductSuccess])

//         React.useEffect(() => {
//             if(props.BXGYCheck&&props.BXGYCheck.length!==0)
//             setCheckProducts(props.BXGYCheck);
//         },// eslint-disable-next-line react-hooks/exhaustive-deps
//             [])

//     const ProductsCheck = (e, id) => {
//         let newArray = [...CheckProducts, id];
//         if (CheckProducts.includes(id)) {
//             newArray = newArray.filter(product => product !== id);
//         }
//         setCheckProducts(newArray);
//         props.BxgycheckBox(newArray);
//     }

//     const SelectAll = (event, products) => {
//         setCheckAll(event.target.checked);
//         let newArray = [];
//         if (event.target.checked === true) {
//             products.map((p) =>
//                 newArray = [...newArray, p.id])
//             setCheckProducts(newArray);
//             props.BxgycheckBox(newArray);
//         }
//         else {
//             setCheckProducts([]);
//             props.BxgycheckBox([])
//         }

//     }

//     const LoadMore = () => {
//         setFirst(first + 50);
//         props.getBxgyProducts({  first: first+ 50 });
//     }

//     return (
//         <>

//             <Container maxWidth='md'
//                 style={{
//                     backgroundColor: "white",
//                     border: "1px solid rgba(0, 0, 0, 0.16)",
//                     borderRadius: "10px",
//                     Top: "40px",
//                     padding: "30px",
//                     display: 'flex',
//                     flexDirection: 'column',
//                     flex: ' 1 1 0',
//                     width: "100%",

//                 }}
//             >

//                 <Row style={{
//                     borderRadius: '8px',
//                     boxShadow: '0 2px 6px #1a181e0a',
//                     backgroundColor: '#fff',
//                     display:'flex',
//                     flexDirection:'column',
//                 }}>
//                      <ul style={{ listStyleType: 'none',paddingRight:'10px',justifyContent:'center'}}>
//                         {props.BxgyProducts && props.BxgyProducts.data &&
//                             props.BxgyProducts.data.bxgyProductsListing.items.map((p) =>

//                                 <div  key={p.id} style={{ display: 'flex', flexDirection:'row',marginBottom:"20px", justifyContent:'center',position:'relative',right:'20px'}} id="scrollableDiv">

//                                     <img src={p.image && p.image.primary} alt={p.productname} style={{ height: '90px', width: '80px', borderRadius: '6px', objectFit: 'cover',border: 'solid 1px #e5e5e5'  }} />
//                                     <div style={{ paddingLeft: '10px', fontFamily: "Varela Round, sans-serif", color: '#999',paddingTop:"30px" }}>
//                                         <p style={{ fontSize: '14px', lineHeight: '24px' }}> {p.productname}</p>
//                                         {/* <p >
//                                             <span style={{
//                                                 fontSize: '14px',
//                                                 lineHeight: '18px',
//                                                 fontWeight: 600
//                                             }}>
//                                                 {p.price}
//                                             </span>
//                                             <span style={{
//                                                 paddingleft: '5px', fontSize: '14px',
//                                                 lineHeight: '20px'
//                                             }}>
//                                                 {p.uom}
//                                             </span>
//                                         </p> */}
//                                     </div>
//                                     <div style={{ flex: '1 1' }}></div>
//                                     <Checkbox

//                                         style={{
//                                             color: '#75c224',
//                                             appearance: 'none',
//                                             width: '16px',
//                                             height: '16px',
//                                             borderRadius: '3px',
//                                             border: '1px solid #75c224',
//                                             outline: 'none',
//                                             marginRight: '4px', // Adjust as needed
//                                             verticalAlign: 'middle', // To align with text properly
//                                         }}
//                                         onChange={(e) => {
//                                             ProductsCheck(e, p.id)
//                                         }}
//                                         checked={props.BXGYCheck&&props.BXGYCheck.includes(p.id) ? true : false}
//                                         name={p.id}
//                                         inputProps={{ "aria-label": "primary checkbox" }}
//                                         value={p.productname}
//                                         defaultChecked={
//                                             CheckProducts.includes(p.id) ? true : false
//                                         }
//                                     />

//                                 </div>)

//                         }
//                         </ul>

//                         {props.BxgyProducts && props.BxgyProducts.data &&
//                             props.BxgyProducts.data.bxgyProductsListing.count > first+1 ?
//                             <button type='button' onClick={LoadMore}
//                                 style={{
//                                     justifyContent: ' center',
//                                     color: 'rgb(255, 255, 255)',
//                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                     width: '100%',
//                                     float: 'right',
//                                     height: '37px',
//                                     fontSize: '16px',
//                                     fontWeight: '600',
//                                     borderRadius: '5px',
//                                     borderWidth: '1px',
//                                     borderStyle: ' solid',
//                                     borderColor: 'transparent',
//                                     borderImage: 'initial',
//                                     overflow: 'hidden',
//                                     outline: 'none',
//                                     minHeight: ' 14px',
//                                     fontFamily: 'Varela Round, sans-serif'
//                                 }}>
//                                 Load More
//                             </button>
//                             :
//                             null}

//                 </Row>
//             </Container>
//         </>
//     );
// }




//new code


import React, { useEffect, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
// import { withStyles } from "@mui/styles";
import { relativeTimeRounding } from "moment";
import CustomCard from "../Commoncomponents/card";
import {Grid, Typography,useTheme,useMediaQuery,} from '../MUIComponents/index';
import './BxgyProducts.css';

import {contentFontSize_18_xs_13,contentFontSize_16,ButtonFontSize_18} from '../Typography/index';
import CustomButton from "../Commoncomponents/button";

export default function BxgyProducts({props,setCheckedData,checkedData}) {
  const [CheckProducts, setCheckProducts] = useState([]);
  const [CategoryCount, setCategoryCount] = useState(null);
  const [CategoryId, setCategoryId] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [first, setFirst] = useState(50);
  const [tempData,setTempData]=useState(()=>props.BXGYCheck)
 

  const theme = useTheme();
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (props.addCatalogProductSuccess === true) {
      setCheckProducts([]);
      setCheckAll(false);
    }
  }, [props.addCatalogProductSuccess]);

  useEffect(() => {
    if (props.BXGYCheck && props.BXGYCheck.length !== 0)
      setCheckProducts(props.BXGYCheck);
  }, []);

  const ProductsCheck = (e, id) => {
    let newArray = [...CheckProducts, id];
    if (CheckProducts.includes(id)) {
      newArray = newArray.filter((product) => product !== id);
    }
    setCheckProducts(newArray);
    setCheckedData(newArray)
    setTempData(newArray)
    // props.BxgycheckBox(newArray);
  };

  const SelectAll = (event, products) => {
    setCheckAll(event.target.checked);
    let newArray = [];
    if (event.target.checked === true) {
      products.map((p) => (newArray = [...newArray, p.id]));
      setCheckProducts(newArray);
    
      props.BxgycheckBox(newArray);
    } else {
      setCheckProducts([]);
      props.BxgycheckBox([]);
    }
  };

  const LoadMore = () => {
    setFirst(first + 50);
    props.getBxgyProducts({ first: first + 50 });
  };

  return (
    <>
      <div>
      <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
              }}
            >
        {props.BxgyProducts &&
          props.BxgyProducts.data &&
          props.BxgyProducts.data.bxgyProductsListing.items.map((p) => (
            
                <div>
              <CustomCard width="100%" height="100%" padding="10px" boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px">
                <Grid container>
                    <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                  <div
                    style={{
                      height: smmatch?"45px":"60px",
                      width: smmatch?"45px":"60px",
                      padding: smmatch?"7px":"10px",
                      background: "#F2F2F2",
                      borderRadius:"5px"
                    }}
                  >
                    <img
                      src={p.image && p.image.primary}
                      alt={p.productname}
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  </div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                 
                    <Typography className="BxgyProductName" fontSize={contentFontSize_18_xs_13} style={{overflow:smmatch&&"scroll"}}>
                      {p.productname}
                    </Typography>
                    
                    {/* <p >
                                            <span style={{
                                                fontSize: '14px',
                                                lineHeight: '18px',
                                                fontWeight: 600
                                            }}>
                                                {p.price}
                                            </span>
                                            <span style={{
                                                paddingleft: '5px', fontSize: '14px',
                                                lineHeight: '20px'
                                            }}>
                                                {p.uom}
                                            </span>
                                        </p> */}
                
                  </Grid>
                  <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                  
                  <Typography className="BxgyProductPrice" style={{justifyContent:smmatch?"flex-start":"center"}} fontSize={contentFontSize_16}>
                    ₹ {p.price}
                    </Typography>
                   
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2} xl={2}>
                 <div style={{display:"flex",justifyContent:smmatch?"flex-start":"center",alignItems:"center",height:"100%"}}>
                  <Checkbox
                    style={{
                     color: "#75c224",
                      height: "100%",
                      borderRadius: "3px",
                    //   border: "1px solid #75c224",
                      
                    }}
                    onChange={(e) => {
                      ProductsCheck(e, p.id);
                    }}
                    checked={
                      tempData && tempData.includes(p.id)
                        ? true
                        : false
                    }
                    name={p.id}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    value={p.productname}
                    defaultChecked={CheckProducts.includes(p.id) ? true : false}
                  />
                  </div>
                  </Grid>
                </Grid>
              </CustomCard>
              </div>
            
          ))}
          </div>
      </div>

      {props.BxgyProducts &&
      props.BxgyProducts.data &&
      props.BxgyProducts.data.bxgyProductsListing.count > first + 1 ? (
        <div style={{margin:"15px 0px",display:"flex",justifyContent:"center"}}>
        <CustomButton
          type="button"
          width= "50%"
          borderRadius="15px"
          onClick={LoadMore}
          style={{
            justifyContent: " center",
            color: "rgb(255, 255, 255)",
            backgroundColor: "#659B1B",  
          }}
        >
          <Typography fontSize={ButtonFontSize_18}  style={{ fontWeight: "600",fontFamily:"Roboto",}}>
          Load More
          </Typography>
        </CustomButton>
        </div>
      ) : null}
    </>
  );
}




