import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Grid from '@mui/material/Grid';
import customer from '../../utils/images/customer.svg';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import TimeField from '../../Components/StatusStepper/TimeField';
import {TextareaAutosize} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AddBill } from './Query';
import client from "../apollo";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import './index.css';
import { FormControl, FormLabel } from '@mui/material';


const steps = [
  { label: "Order-Placed", partner: false },
  { label: "Accept", partner: true },
  { label: "Shopping-In-Progress", partner: false },
  { label: "Order-Ready", partner: true },
  { label: "Complete", partner: false },
]

const status = [
  "Order-Placed",
  "Accept",
  "Shopping-In-Progress",
  "Order-Ready",
  "Completed",
]
class CriticalModal extends Component {
  constructor(props) {
    super(props)
  }
  state = {
    open: false,
    sid: null,
    partnerid: null,
    num: null,
    selectedValue: null,
    partnerValue: null,
    orderid: null,
    RejectOpen: false,
    comment: null,
    order: null,
    lastStatus: null,
    bill: null,
    shopassistanterror: '',
    finalbillamounterror: '',
  }

  setAlert = (v) => {
    if (v === "Shopping-In-Progress") {
      if (this.state.sid === null && this.state.partnerid === null) {
        this.setState({ ...this.state, shopassistanterror: "Please select a shop assistant to continue", finalbillamounterror: '' });
      }
      else if (this.state.bill === null) {
        this.setState({ ...this.state, finalbillamounterror: "Please enter final bill amount", shopassistanterror: '' });
      }
      else {
        this.setState({ ...this.state, open: false, finalbillamounterror: '', shopassistanterror: '' });
        this.handleClose(this.state.lastStatus !== "Shopping-In-Progress" ? this.state.sid : this.state.partnerid, this.state.lastStatus !== "Shopping-In-Progress" ? 2 : 4)
      }
    }
    else {
      this.handleClose(this.state.lastStatus !== "Shopping-In-Progress" ? this.state.sid : this.state.partnerid, this.state.lastStatus !== "Shopping-In-Progress" ? 2 : 4)
    }
  }

  handleListItemClick = (value, id, num, orderid) => {
    if (num === 2) {
      // console.log(" handleListItemClickIf")
      this.setState({ sid: id })
      this.props.AssignOrder(id, orderid);
      // TO CHANGE THE ORDER STATUS
      this.props.postStatusChange({ orderid: orderid, status: "Order-Accepted" })
      // TO SCHEDULE ALERT
      this.props.scheduleAlert({
        orderid: orderid,
        status: "Order-Accepted",
        deliveryDate: this.state.order.deliveryDate,
        deliveryTime: this.state.order.deliveryTime,
        orderNumber: this.state.order.orderNumber,
        shopassistantId: id
      });
      // ELIMINATE THE ORDER FROM CRITICAL ORDERS ARRAY
      this.props.EliminateOrder(orderid)
      this.setState({ open: false, finalbillamounterror: '', shopassistanterror: '' });
      //  this.handleClose(value, num);

    } else if (num === 4) {
      this.setState({ partnerid: id })
      this.props.AssignPartnerOrder(id, orderid, this.props.travelTime);
    }



  };

  handleClickOpen = () => {
    this.setState({ open: true })
    this.setState({ bill: this.state.order.totalPrice })
  };

  handleClose = (value, num) => {
    this.setState({ ...this.state, open: false, finalbillamounterror: '', shopassistanterror: '' });
    if (num === 2) {
      this.setState({ selectedValue: value });
    } else if (num === 4) {
      this.setState({ partnerValue: value });
      client
        .mutate({
          mutation: AddBill,
          variables: {
            orderid: `${this.state.orderid}`,
            finalBillAmount: Number(this.state.bill),
          },
        })
      // TO CHANGE THE ORDER STATUS
      this.props.postStatusChange({ orderid: this.state.orderid, status: "Order-Ready" })
      // TO SCHEDULE ALERT
      this.props.scheduleAlert({
        orderid: this.state.orderid,
        status: "Order-Ready",
        deliveryDate: this.state.order.deliveryDate,
        deliveryTime: this.state.order.deliveryTime,
        orderNumber: this.state.order.orderNumber,
        shopassistantId: this.state.partnerid
      });
      // ELIMINATE THE ORDER FROM CRITICAL ORDERS ARRAY
      this.props.EliminateOrder(this.state.orderid)
      //   setpartnerValue(value);
    }
  };
  handleChange = (event) => {
    this.setState({ partnerid: event.target.value })

  };
  Accept = (r, row) => {
    if (steps[r?.stats?.length].partner === true) {
      // TO SHOW MODAL FOR SELECTING SHOP-ASSISTANT
      this.setState({ open: true })
      this.setState({ orderid: r.id })
      this.setState({ order: r })
      this.setState({ lastStatus: row.lastStatus })
      this.props.getShopassistantlist(localStorage.getItem('storeid'))
      // this.setState({bill:this.state.order.totalPrice})
    }
    else {
      // TO CHANGE THE ORDER STATUS
      this.props.postStatusChange({ orderid: r.id, status: status[r?.stats?.length] })
      // TO SCHEDULE ALERT
      this.props.scheduleAlert({
        orderid: r.id,
        status: status[r?.stats?.length],
        deliveryDate: r.deliveryDate,
        deliveryTime: r.deliveryTime,
        orderNumber: r.orderNumber,
        shopassistantId: r.deliveryPartnerId !== null ? r.deliveryPartnerId.id :
          r.shopAssistantId !== null ? r.shopAssistantId.id : null
      });
      // ELIMINATE THE ORDER FROM CRITICAL ORDERS ARRAY
      this.props.EliminateOrder(r.id)
    }

  }
  // submit = (r, stat, id) => {
  //   this.props.postStatusChange({ orderid: r.id, status: stat })
  //   // TO SCHEDULE ALERT
  //   this.props.scheduleAlert({
  //     orderid: r.id,
  //     status: stat,
  //     deliveryDate: r.deliveryDate,
  //     deliveryTime: r.deliveryTime,
  //     orderNumber: r.orderNumber,
  //     shopassistantId: id
  //   });
  //   this.props.EliminateOrder(r.id)
  // }
  Reject = (r) => {
    this.setState({ order: r })
    this.setState({ orderid: r.id })
    this.setState({ RejectOpen: true })
  }
  rejectClick = () => {
    this.setState({ RejectOpen: false });
    this.props.rejectedStatusHandler({
      comment: this.state.comment,
      status: "Order-Rejected",
      orderId: this.state.orderid,
    });

    this.props.scheduleAlert({
      orderid: this.state.orderid,
      status: "Order-Rejected",
      deliveryDate: `${this.state.order.deliveryDate}`,
      deliveryTime: `${this.state.order.deliveryTime}`,
      orderNumber: this.state.order.orderNumber,
      shopassistantId: null
    })
    this.props.EliminateOrder(this.state.orderid)
  }

  changeComment = (e) => {
    this.setState({ comment: e.target.value });

  };
  render() {
    return (
      <>
        <Carousel
          showIndicators={false}
          statusFormatter={(current, total) =>
            <p
              style={{
                color: '#69A85C',
                fontFamily: 'Nunito',
                fontStyle: 'normal',
                fontWeight: 600,
                fontAize: '18px',
                left: 15,
                lineHeight: '18px',
                paddingRight: '15px'
              }}>{`${current} / ${total}`}</p>
          }
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <ArrowLeftIcon role="button" onClick={onClickHandler} title={'Previous order'}
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  top: 'calc(50% - 15px)',
                  background: '#FFFFFF',
                  border: 'none',
                  color: '#69A85C',
                  width: 30,
                  height: 30,
                  cursor: 'pointer', left: 15
                }} />
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <ArrowRightIcon
                type="button"
                onClick={onClickHandler}
                title={'Next order'}
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  top: 'calc(50% - 15px)',
                  background: '#FFFFFF',
                  border: 'none',
                  color: '#69A85C',
                  width: 30,
                  height: 30,
                  cursor: 'pointer',
                  right: 15
                }} />
            )
          }
        >
          {this.props?.ActiveOrder?.data?.criticalOrders?.map((row, index) => (
            <div className="OrderBox">
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: 'left',
                  alignItems: 'left'
                }}>
                <Grid item xs={12} md={6} className='orderSection'>
                  <span className='order'>
                    Order#:
                  </span>
                  <span className='OrderNumber'>
                    {row?.orderid?.orderNumber}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} className='PriceSection'>
                  <span className='price'>
                    {'Total Estimated :'}
                  </span>
                  <span className='PriceValue'>
                    &nbsp;{` ₹ ${row?.orderid?.totalPrice}`}
                  </span>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '20px'
                }}>
                <Grid item xs={6} md={6} className='AddressSection'>
                  <Grid container className='customer'>
                    <Grid item xs={0} md={2}>
                      <img 
                      src={customer} 
                      alt="Account" 
                      style={{ 
                        width: '39px', 
                        height: '39px' 
                        }} />
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <div className='Firstname'>
                        {row?.orderid?.userid?.firstName}
                      </div>
                      <div className='Address'>
                        {row?.orderid?.deliveryAddress}
                      </div>
                      <div className='products'>
                        {row?.orderid?.products.length <= 2 ?
                            (row?.orderid?.products.map(({ productid }) => (
                              `${productid.productname},`
                            )))
                            :
                            (row?.orderid?.products.slice(0, 2).map(({ productid }) =>
                              (`${productid.productname},`)
                            )
                            )
                        }
                        {row?.orderid?.products.length > 2 &&
                          <p>& {row?.orderid?.products.length - 2} item</p>}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} className='Delivery' >
                  <div className='Date'>
                    {row?.orderid?.deliveryDate}
                  </div>
                  <div className='Time'>
                    {row?.orderid?.deliveryTime}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ justifyContent: 'center', alignItems: 'center', paddingTop: '30px', paddingBottom: '20px' }}>
                <Grid item xs={12} md={6} >
                  {row.lastStatus === "Order-Placed" &&
                    <button className='reject' onClick={() => this.Reject(row.orderid)}>
                      Reject
                    </button>}
                </Grid>
                <Grid item xs={12} md={6}>
                  <button className='accept' onClick={() => this.Accept(row.orderid, row)}>
                    {row.orderid?.lastStatus}
                  </button>
                </Grid>
                {/* {this.state.sid!==null&&this.state.orderid===row.orderid.id?
                        <span style={{paddingLeft:'10px'}}>
                        <button className='accept' onClick={()=>this.submit(row.orderid,"Order-Accepted",this.state.sid)}>
                           submit
                        </button>
                        </span>
                        :
                        this.state.partnerid!==null&&this.state.orderid===row.orderid.id?
                        <span style={{paddingLeft:'10px'}}>
                        <button className='accept' onClick={()=>this.submit(row.orderid,"Order-Ready",this.state.partnerid)}>
                            submit
                        </button>
                        </span>
                        :
                        null
                    } */}
              </Grid>
            </div>
          ))
          }
        </Carousel>

        {/* DIALOG TO OPEN TEXT AREA FOR REJECT ORDERS */}

        <Dialog open={this.state.RejectOpen}
          fullWidth={true}
          maxWidth={'sm'}>
          <DialogTitle style={{ justifyContent: 'center', alignItems: 'center' }}>Reason To Reject</DialogTitle>
          <Grid 
          container 
          spacing={2} 
          style={{ 
            justifyContent: 'center', 
            alignItems: 'center', 
            paddingTop: '30px', 
            paddingBottom: '20px'
            }}>
            <TextareaAutosize
              style={{ width: "500px", height: "100px" }}
              aria-label="minimum height"
              name="comment"
              onChange={(e) => {
                this.changeComment(e);
              }}
              value={this.props.commentsSeller ? this.props.commentsSeller : this.state.comment}
              rowsMin={3}
              placeholder="Please provide reason for reject incase you would like to reject this orders*"
            />
          </Grid>
          <Grid 
          container 
          spacing={2} 
          style={{ 
            justifyContent: 'center', 
            alignItems: 'center', 
            paddingTop: '30px', 
            paddingBottom: '20px' 
            }}>
            <span style={{ paddingRight: '10px' }}>
              <button className='reject' onClick={() => this.setState({ RejectOpen: false })}>
                close
              </button>
            </span>
            <span style={{ paddingLeft: '10px' }}>
              <button className='accept' onClick={this.rejectClick}>
                Reject
              </button>
            </span>
          </Grid>
        </Dialog>

        {/* DIALOG FOR ASSIGING SHOPPING AND DELIVERY PARTNER */}

        <Dialog
          onClose={this.handleClose}
          open={this.state.open}
          fullWidth={true}
          maxWidth={'sm'}
          style={{ padding: '15px' }}
        >
          <DialogTitle>
            <Grid 
            container 
            spacing={2} 
            style={{ 
              justifyContent: 'center', 
              alignItems: 'center', 
              paddingTop: '30px', 
              paddingBottom: '20px' 
              }}>
              <span style={{ paddingRight: '10px' }}>
                Shop Assistant List
              </span>
              <span style={{ paddingLeft: '10px' }}>
                <button className='reject' onClick={() => {
                  this.setAlert(this.state.lastStatus);
                }}>
                  {this.state.lastStatus !== "Shopping-In-Progress" ? 'Close' : 'Save'}
                </button>
              </span>
            </Grid>
          </DialogTitle>
          {this.state.lastStatus !== "Shopping-In-Progress" ?
            null
            :
            <>
              <TimeField {...this.props} orderid={this.state.orderid} />
              <div style={{ paddingTop: '10px' }}>
                <TextField
                  type="number"
                  onKeyPress={(event) => {
                    if (event?.key === "-" || event?.key === "+") {
                      event.preventDefault();
                    }
                  }}
                  id="Final Bill Amount"
                  placeholder="Final Bill Amount "
                  required
                  value={this.state.bill}
                  onChange={(e) => {
                    this.setState({ bill: e.target.value })
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  helperText={this.state.finalbillamounterror !== '' ? this.state.finalbillamounterror : ''}
                  error={this.state.finalbillamounterror === '' ? false : true}
                />
              </div>
            </>
          }
          <div style={{ paddingTop: '20px', paddingLeft: '30px' }}>
            <FormControl sx={{ m: 3 }} error={this.state.shopassistanterror === '' ? false : true} variant="standard">
              {this.state.shopassistanterror === '' ? null :
                <FormLabel id="demo-error-radios">{this.state.shopassistanterror}</FormLabel>
              }
              <RadioGroup
                aria-label="ringtone"
                name="ringtone"
                value={this.state.lastStatus !== "Shopping-In-Progress" ? this.state.sid : this.state.partnerid}
              >
                {this.props.Shopassistantlist &&
                  this.props.Shopassistantlist.data &&
                  this.props.Shopassistantlist.data
                    .shopAssistantsList &&
                  this.props.Shopassistantlist.data.shopAssistantsList.map(
                    (s) => (
                      <FormControlLabel
                        value={s.id}
                        key={s.id}
                        onClick={() =>
                          this.handleListItemClick(
                            `${s.firstName} ${s.lastName}`,
                            s.id,
                            this.state.lastStatus !== "Shopping-In-Progress" ? 2 : 4, this.state.orderid
                          )
                        }
                        control={<Radio />}
                        label={`${s.firstName} ${s.lastName}`}
                      />
                    )
                  )}
              </RadioGroup>
            </FormControl>
            <List sx={{ pt: 0 }}>

            </List>
          </div>
        </Dialog>
      </>
    );
  }
}

export default CriticalModal;
