// import React from "react";
// import { Table, Row } from "react-bootstrap";
// import Orders from "./Orders";
// import { no_result_found } from "../../utils/image";
// // import Orderslist from "./Orderslist";
// import { useStyles } from "./style";
// import { useMediaQuery } from "react-responsive";
// import Grid from "@mui/material/Grid";
// import TableContainer from "@mui/material/TableContainer";
// import Paper from "@mui/material/Paper";
// import { Container } from "@mui/material";

// const View = (props) => {

//   // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
//   const isSmallScreen = useMediaQuery({ query: "(max-width: 1224px)" });
//   document.title = "Seller Portal | View Shop Assistant";
//   const classes = useStyles();
//   const [orderlist] = React.useState(false);
//   const AssistantOrders = (id, fn, ln, ph) => {

//     props.history.push({
//       pathname: "/home/ShopAssistant/orders",
//       state: {
//         shopAssistantId: id,
//         fname: fn,
//         lname: ln,
//         phno: ph,
//       },
//     });
//   };
//   const handleId = (id) =>{
//     props.getshopAssistantID(id)
//   }

//   React.useEffect(
//     () => {
//       props.getShopassistantlist(localStorage.getItem("storeid"));
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     []
//   );
//   return isSmallScreen ? (
//     <>
//       {props.Shopassistantlist &&
//       props.Shopassistantlist.data &&
//       props.Shopassistantlist.data.shopAssistantsList.length !== 0 ? (
//         orderlist === false ? (
//           <div
//             style={{
//               backgroundColor: "white",
//               border: "1px solid rgba(0, 0, 0, 0.16)",
//               borderRadius: "10px",
//               padding: "20px",
//               width: "90vw",
//             }}
//           >
//             <Row className={classes.headingRow}>
//               <h4 className={classes.heading}>Shop Assistants</h4>
//             </Row>

//             <Grid item xs={12} md={12}>
//               <TableContainer
//                 style={{
//                   marginTop: 20,
//                 }}
//                 component={Paper}
//               >
//                 <Table striped bordered hover style={{ width: "100%" }}>
//                   <thead>
//                     <tr>
//                       <th>No.</th>
//                       <th>First Name</th>
//                       <th>Last Name</th>
//                       <th>Phone Number</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {props.Shopassistantlist &&
//                       props.Shopassistantlist.data &&
//                       props.Shopassistantlist.data.shopAssistantsList &&
//                       props.Shopassistantlist.data.shopAssistantsList.map(
//                         (s, index) => (
//                           <tr
//                             className={classes.tableCell}
//                             key={s.id}
//                             onClick={() => {
//                               AssistantOrders(s.id, s.firstName, s.lastName, s.phoneNumber);
//                               handleId(s.id);
//                             }}
//                           >
//                             <td>{index + 1}</td>
//                             <td>{s.firstName}</td>
//                             <td>{s.lastName}</td>
//                             <td>{s.phoneNumber}</td>
//                           </tr>
//                         )
//                       )}
//                   </tbody>
//                 </Table>
//               </TableContainer>
//             </Grid>
//           </div>
//         ) : (
//           <Orders {...props} />
//         )
//       ) : (
//         <Container className={classes.root}>
//           <img
//             style={{ maxWidth: "600px", paddingLeft: 80 }}
//             src={no_result_found}
//             alt="noresult"
//           ></img>
//           <div className={classes.noshopAssisstants}>
//             No shop assistants found
//           </div>
//         </Container>
//       )}
//     </>
//   ) : (
//     <>
//       {props.Shopassistantlist &&
//       props.Shopassistantlist.data &&
//       props.Shopassistantlist.data.shopAssistantsList.length !== 0 ? (
//         orderlist === false ? (
//           <Container maxWidth="md" className={classes.root}>
//             <Row className={classes.headingRow}>
//               <h4 className={classes.heading}>Shop Assistants</h4>
//             </Row>
//             <Table striped bordered hover size="sm">
//               <thead>
//                 <tr>
//                   <th>No.</th>
//                   <th>First Name</th>
//                   <th>Last Name</th>
//                   <th>Phone Number</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {props.Shopassistantlist &&
//                   props.Shopassistantlist.data &&
//                   props.Shopassistantlist.data.shopAssistantsList &&
//                   props.Shopassistantlist.data.shopAssistantsList.map(
//                     (s, index) => (
//                       <tr
//                         className={classes.tableCell}
//                         key={s.id}
//                         onClick={() =>{
//                           AssistantOrders(
//                             s.id,
//                             s.firstName,
//                             s.lastName,
//                             s.phoneNumber
//                           )
//                           {handleId(s.id)}
//                         }

//                         }
//                       >
//                         <td>{index + 1}</td>
//                         <td>{s.firstName}</td>
//                         <td>{s.lastName}</td>
//                         <td>{s.phoneNumber}</td>
//                       </tr>
//                     )
//                   )}
//               </tbody>
//             </Table>
//           </Container>
//         ) : (
//           <Orders {...props} />
//         )
//       ) : (
//         <Container className={classes.root}>
//           <img
//             style={{ maxWidth: "600px", paddingLeft: 80 }}
//             src={no_result_found}
//             alt="noresult"
//           ></img>
//           <div className={classes.noshopAssisstants}>
//             No shop assistants found
//           </div>
//         </Container>
//       )}
//     </>
//   );
// };

// export default View;

//new code

import React, { useEffect, useState } from "react";
import {
  useTheme,
  useMediaQuery,
  Typography,
  Grid,
  Box,
  bindTrigger,
  usePopupState,
} from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card";
import CustomButton from "../Commoncomponents/button";
import {
  ButtonFontSize_18,
  contentFontSize_16,
  HeaderNavigationFontSize_20,
  contentFontSize_18_xs_13,
  contentFontSize_16_xs_10,
} from "../Typography/index";
import threedot from "../../utils/Icon/threedot.svg";
import Arrow from "../../utils/Icon/Arrow.svg";
import editIconSA from "../../utils/Icon/editIconSA.svg";
import deleteIconSA from "../../utils/Icon/deleteIconSA.svg";
import AssitantProfileImage from "../../utils/images/AssitantProfileImage.svg";
import CustomPopover from "../Commoncomponents/popup/index";
import { useNavigate } from "react-router-dom";
import "./view.css";
import { message } from "antd";
import CustomDialog from "../Commoncomponents/dialog/index"
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {Dialog} from "../MUIComponents/index";
import dummy from '../../utils/images/dummy.png';
export default function View(props) {
  const [popupid, setPopupid] = useState();
  const [deleteShopAssistantState,setDeleteShopAssistantState]=useState(false);
  const [newPopupid,setNewPopupid]=useState();
  const { shopassistantloading } = useSelector((state) => state.SignInReducer);

  const handleShopAssistantState=(item)=>{
    setNewPopupid(item.id)
    setDeleteShopAssistantState(true)
  }

  const handleShopAssistantStatefalse=()=>{
    setDeleteShopAssistantState(false)
  }



  useEffect(
    () => {
      props.getShopassistantlist(localStorage.getItem("storeid"));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(()=>{
    props.getShopassistantlist(localStorage.getItem("storeid"));
  },[props.deleteShopAssistantSuccess])


  useEffect(()=>{
    if(props.deleteShopAssistantSuccess){
      message.success({
        content: "Shop Assistant Deleted Successfully",
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });


    }

  },[props.deleteShopAssistantSuccess])



  const nav = useNavigate();
  const dispatch=useDispatch();

  const handlePopUpID = (id) => {
    setPopupid(id);
  };

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const popupState1 = usePopupState({
    variant: "popover",
    popupId: "demo-popup-popover",
  });

  const handleNavigateEvent = () => {
    nav("/home/ShopAssistant/Register");
  };

  const AssistantOrders = (id, fn, ln, ph,pr) => {
    nav("/home/ShopAssistant/orders", {
      state: {
        shopAssistantId: id,
        fname: fn,
        lname: ln,
        phno: ph,
        profilePicUrl:pr,
      },
    });
  };
  const handleId = (id) => {
    props.getshopAssistantID(id);
  };

  const handleRegisterEditState = (item) => {
    
    nav("/home/ShopAssistant/EditRegister", {
      state: {
        shopAssistantId: item.id,
        fname: item.firstName,
        lname: item.lastName,
        profilePicUrl: item.profilePicUrl,
        phoneNumber:item.phoneNumber,
      },
    });
  };
  const storeid=localStorage.getItem('storeid')

  const handleDeleteState=()=>{
    // console.log('this is the deleteid',item.id)
    props.deleteShopAssistant(
    newPopupid
    )
   
    popupState1.close();
    
      setDeleteShopAssistantState(false)

      // setTimeout(() => {
      //   props.getShopassistantlist(storeid)
      // }, 500);
      setTimeout(() => {
        dispatch({type:"CLEAR_DELETE_SHOPASSISTANT"})
      }, 500);
     
 
  };


useEffect(()=>{
  props.getShopassistantlist(storeid)
},[props.deleteShopAssistant])

  return (
    <>
      <Typography
        fontSize={HeaderNavigationFontSize_20}
        className="ShopAssistantFontStyle"
      >
        Shop Assistants
      </Typography>

      {props.Shopassistantlist &&
      props.Shopassistantlist.data &&
      props.Shopassistantlist.data.shopAssistantsList.length !== 0 ? (
        <>
          <div>
            {props.Shopassistantlist &&
              props.Shopassistantlist.data &&
              props.Shopassistantlist.data.shopAssistantsList &&
              props.Shopassistantlist.data.shopAssistantsList.map((item) => (
                <div
                  className="columnFlexRegister"
                  style={{ marginTop: "15px" }}
                  
                  key={item.id}
                >
                  <CustomCard
                    padding={"20px"}
                    boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
                    onClick={() => handlePopUpID(item)}
                    flex="flex"
                  >
                    <>
                      <div style={{ flex: 1, paddingRight: "10px" }}>
                        <div style={{ display: "flex", gap: "30px" }}>
                          <div>
                            <CustomCard
                              padding="2px"
                              width={mdmatch ? "80px" : "100px"}
                              background="#F2F2F2"
                              boxShadow="none"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height:"100%"
                                }}
                              >
                                {/* <img
                                  src={AssitantProfileImage}
                                  alt="Shop Assistant profile"
                                  width={matches && "50px"}
                                /> */}
                                {item.profilePicUrl?(
                                   <img
                                   src={item.profilePicUrl}
                                   alt="Shop Assistant profile"
                                   width={matches ? "40px":"50px"}
                                   height={matches ? "40px":"50px"}
                                   style={{borderRadius:"50%"}}
                                 /> 
                                ):(
                                  <img
                                  src={dummy}
                                  alt="Shop Assistant profile"
                                  width={matches ? "40px":"50px"}
                                  height={matches ? "40px":"50px"}
                                  style={{borderRadius:"50%"}}
                                /> 
                                )}
                              </div>
                            </CustomCard>
                          </div>
                          {/* <Grid item xs={6} sm={5} md={3} lg={3} xl={3} style={{paddingLeft:matches?"16px":"0px"}}> */}
                          <div>
                            <div
                              style={{
                                display: "flex",
                                // justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: upXlmatch
                                    ? "8px"
                                    : xlmatch
                                    ? lgmatch
                                      ? mdmatch
                                        ? smmatch
                                          ? xsmatch
                                            ? "2px"
                                            : "4px"
                                          : "6px"
                                        : "6px"
                                      : "8px"
                                    : "8px",
                                }}
                              >
                                <Typography
                                  fontSize={contentFontSize_18_xs_13}
                                  className="ShopCardStyle"
                                >
                                  {item.firstName} {item.lastName}
                                </Typography>
                                <Typography
                                  fontSize={contentFontSize_18_xs_13}
                                  className="ShopCardStyle"
                                >
                                  {item.phoneNumber}
                                </Typography>
                              </div>
                            </div>
                            {/* </Grid> */}
                          </div>
                        </div>
                      </div>

                      <div style={{ flex: 1, paddingRight: "10px" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div style={{ display: "flex", gap: matches?"15px":"30px" }}>
                              <div style={{cursor:"pointer"}}>
                                <img
                                  src={threedot}
                                  alt="three dot icon"
                                  height={
                                    upXlmatch
                                      ? "20px"
                                      : xlmatch
                                      ? lgmatch
                                        ? mdmatch
                                          ? smmatch
                                            ? xsmatch
                                              ? "12px"
                                              : "12px"
                                            : "14px"
                                          : "16px"
                                        : "18px"
                                      : "20px"
                                  }
                                  {...bindTrigger(popupState1, item.id)}
                                />
                              </div>
                              <div style={{cursor:"pointer"}}>
                                <img
                                  src={Arrow}
                                  alt="arrow icon"
                                  height={
                                    upXlmatch
                                      ? "20px"
                                      : xlmatch
                                      ? lgmatch
                                        ? mdmatch
                                          ? smmatch
                                            ? xsmatch
                                              ? "12px"
                                              : "12px"
                                            : "14px"
                                          : "16px"
                                        : "18px"
                                      : "20px"
                                  }
                                  // onClick={() => handleRedDot(item.id)}
                                  onClick={() => {
                                    AssistantOrders(
                                      item.id,
                                      item.firstName,
                                      item.lastName,
                                      item.phoneNumber,
                                      item.profilePicUrl
                                    );
                                    handleId(item.id);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {end} */}
                    </>
                  </CustomCard>

                  {/* popup code */}
                  <CustomPopover
                    popupState={popupState1}
                    height={matches ? "70px" : "88.82px"}
                    width={matches ? "115px" : "146px"}
                    borderRadius="12.17px"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    padding="15px"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: matches ? "5px" : "10px",
                      }}
                    >
                      <div
                        onClick={() => handleRegisterEditState(popupid)}
                        key={item.id}
                      >
                        <div style={{ display: "flex", gap: "5px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                          
                            }}
                          >
                            <img
                              src={editIconSA}
                              alt="edit icon"
                              width={matches && "14px"}
                              height={matches && "14px"}
                            />
                          </div>
                          <Typography
                            fontSize={contentFontSize_16}
                            className="ShopPopupStyle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor:"pointer"
                            }}
                          >
                            Edit
                          </Typography>
                        </div>
                      </div>

                      <div
                        // onClick={() => handleDeleteState(popupid)}
                        onClick={()=>handleShopAssistantState(popupid)}
                        key={item.id}
                      >
                        <div style={{ display: "flex", gap: "5px" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              
                            }}
                          >
                            <img
                              src={deleteIconSA}
                              alt="delete icon"
                              width={matches && "14px"}
                              height={matches && "14px"}
                            />
                          </div>
                          <Typography
                            fontSize={contentFontSize_16}
                            className="ShopPopupStyle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#EA4335",
                              cursor:"pointer"
                            }}
                          >
                            Delete
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </CustomPopover>
                </div>
              ))}
          </div>

          {deleteShopAssistantState && (
          <div>
            <Dialog
              width="100%"
              // maxHeight="50%"
              
              open={deleteShopAssistantState}
              onClose={handleShopAssistantStatefalse}
              titleAlign="center"
              padding="0px"
              showCloseButton={false}
              style={{borderRadius:"20px"}}
            >
              <div style={{textAlign:"center"}}>
                <Typography
                  style={{
                   padding:"24px 20px",
                    boxSizing: "border-box",
                    color:"rgb(139, 137, 137)",
                    fontFamily:'Roboto',
                    fontWeight:"900"
                  }}
              
                  fontSize={contentFontSize_18_xs_13}
                >
                  Do you want to delete this Shop Assistant?
                </Typography>
              </div>
 
              <div style={{ display: "flex", justifyContent: "space-evenly" ,padding:"5px 40px 20px 40px" }}>
                <div onClick={handleShopAssistantStatefalse}><Typography  style={{ cursor:"pointer",fontFamily:'Roboto',fontWeight:"900",color:"rgb(139, 137, 137)",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} fontSize={contentFontSize_16_xs_10}>Cancel</Typography></div>
                <div>|</div>
                <div onClick={handleDeleteState}><Typography  style={{color:"red", cursor:"pointer",fontFamily:"Roboto",fontWeight:"900",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} fontSize={contentFontSize_16_xs_10}>Delete</Typography></div>
              </div>
            </Dialog>
          </div>
        )}
        
        </>
      ) : (

       shopassistantloading? (<div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <CircularProgress color="success" /></div>)
       :
        (<>No registered shop assistants are found</>)
      )}
        <div style={{ marginTop: "40px", textAlign: "center" }}>
            <CustomButton
              // onClick={handleCreateEvent}
              onClick={handleNavigateEvent}
              width={
                upXlmatch
                  ? "358px"
                  : xlmatch
                  ? lgmatch
                    ? mdmatch
                      ? smmatch
                        ? xsmatch
                          ? "250px"
                          : "250px"
                        : "310px"
                      : "330px"
                    : "340px"
                  : "358px"
              }
              borderRadius="20px"
              matchPadding={true}
              padding="10px"
            >
              <Typography
                fontSize={ButtonFontSize_18}
                className="ShopUpdateButtonStyle"
              >
                Create Shop Assistant
              </Typography>
            </CustomButton>
          </div>
    </>
  );
}
