import * as yup from "yup";
export const validationSchema = yup.object({
    firstName: yup
      .string()
      .matches(/^[A-Za-z]+$/, {
        message: "Only alphabets are allowed",
        excludeEmptyString: true
      })
      .max(20, "maximum length exceeded than required")
      .required(`${"First Name is required"}`),
  
    lastName: yup
      .string()
      .matches(/^[A-Za-z]+$/, {
        message: "Only alphabets are allowed",
        excludeEmptyString: true
      })
      .max(20, "maximum length exceeded than required")
      .required("Last name is required"),
    mobileNumber: yup
      .string()
      .matches(/^\S*$/, "Space is not allowed")
      //eslint-disable-next-line
      .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
      .min(10, "Please enter 10 digit Mobile number")
      .max(10, "Please enter 10 digit Mobile number")
      .required("Please enter Mobile number"),
    secondaryNumber: yup
      .string()
      .matches(/^\S*$/, "Space is not allowed")
      //eslint-disable-next-line
      .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
      .min(10, "Please enter 10 digit Mobile number")
      .max(10, "Please enter 10 digit Mobile number"),
    //  .required("please provide alternative mobile number"),
    emailAddress: yup
      .string()
      .matches(/^\S*$/, "Space is not allowed")
      //eslint-disable-next-line
      .matches( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,"Email Address must be valid")
      .trim()
      .email("please enter a valid email"),
    // .required('Email is required'),
    // PANnumber: yup
    //   .string()
    //   .matches(/^\S*$/, "Space is not allowed")
    //   .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "please enter valid PAN number"),
    // VATnumber: yup.string().matches(/^\S*$/, "Space is not allowed"),
    aadharNumber: yup
    .string()
    .required('Aadhaar number is required')
    .matches(/^[2-9]{1}[0-9]{11}$/, 'Aadhaar number is not valid'),
    GSTnumber: yup
      .string()
      .matches(
        /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "please enter valid GST number"
      )
      .matches(/^\S*$/, "Space is not allowed"),
      storeManagerFirstName: yup
        .string()
        .matches(/^\S*$/, "Space is not allowed")
        .max(20, "Length exceeded")
        .matches(/^[a-zA-Z]+$/, "only alphabets are allowed")
        .required("Store manager first name is required"),
      storeManagerLastName: yup
        .string()
        .max(20, "Length exceeded")
        .matches(/^\S*$/, "Space is not allowed")
        .matches(/^[a-zA-Z]+$/, "only alphabets are allowed")
        .required("Store manager last name is required"),
      storeManagerPhone: yup
        .string()
        .matches(/^\S*$/, "Space is not allowed")
    
        //eslint-disable-next-line
        .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
        .min(10, "Please enter 10 digit Mobile number")
        .max(10, "Please enter 10 digit Mobile number"),
        // .required("Please enter Mobile number"),
      storeManagerMobile: yup
        .string()
        .matches(/^\S*$/, "Space is not allowed")
        //eslint-disable-next-line
        .matches(/^[6-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]*$/, "Please enter valid phone number")
        .min(10, "Please enter 10 digit Mobile number")
        .max(10, "Please enter 10 digit Mobile number")
        .required("Please enter Mobile number"),
        bankAccountNumber:yup.string().matches(/^\S*$/,'Space is not allowed')
        .matches(/^\d{9,18}$/,"please enter valid Account Number")
        .required("Please Enter Bank Account Number"),
        accountHolderName: yup
        .string()
      .min(4, "Account Holder Name must be at least 4 characters")
      .matches(/^[A-Za-z][A-Za-z\s]*[A-Za-z]$/, {
        message: "Only alphabets are allowed with spaces in between",
        excludeEmptyString: true
      })
      .required("Account Holder Name is required"),
        IFSCcode:yup.string().matches(/^\S*$/,'Space is not allowed')
        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/,"please enter valid IFSC Code")
        .required("IFSC Code is required"), 
  });    