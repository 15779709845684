// import Chart from 'react-apexcharts';
// import "./DonutChart.css";

// const DonutChart = ({ upXlmatch, xlmatch, lgmatch, mdmatch, smmatch, xsmatch }) => {

//     const options = {
//         series: [80, 15, 5],
//         labels: ['Accepted', 'Cancelled', 'Rejected'],
//         chart: {
//             type: 'donut',
//         },
//         colors: ['#008FFB', '#00E396', '#FF4560'],
//         plotOptions: {
//             pie: {
//                 expandOnClick: false,
//                 customScale: upXlmatch ? 0.4 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 3 : 3) : 0.6) : 0.7) : 0.4) :0.4,
//                 offsetY: upXlmatch ? -120 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 60 : 60) : -10) : -10) : -110) : -80,
//             },
          
//         },
//         dataLabels: {
//             enabled:false, 
//         },
//         stroke:{
//             width:0,
//         },
//         tooltip: {
//             enabled: true,
//             shared: true,
//             fillSeriesColor:false,
//             theme:true,
//             style:{
//                fontSize:"12px",
//             },
//             y: {
//                 formatter: function (value) {
//                     return value + "%";
//                 },
//                 title:{
//                     formatter:() => null,
//                 }
//             },
//             marker:{
//                 show:false,
//             },
//             fixed:{
//                 enabled:false,
//             }
//         },
//         legend: {
//             position: 'bottom',
//             offsetY:upXlmatch ? 0 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? -60 : -60) : -50) : -40) : -20) : -100,
//             markers: {
//                 radius: 3,
//                 width:upXlmatch ? 45.92 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 25 : 25) : 30) : 35) : 40) : 45.92,
//                 height: upXlmatch ? 24.17 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 9 : 9) : 12) : 16) : 20) : 24.17,
//                 offsetX: upXlmatch ? -10 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 8 : 8) : 6) : 7) : -7) : -10,
                
//             },
//             itemMargin:{
//                 horizontal:upXlmatch ? 30 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 20 : 20) : 8) : 5) : 10) : 30,
//                 vertical:upXlmatch ? 0 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 100 : 100) : 0) : 0) : 0) : 30,
//             }
//         },
 
//     };

//     const internalStyle = `
//     .apexcharts-legend-text {
//         font-size: ${upXlmatch ? '16px' : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? '10px' : '10px') : '12px') : '13px') : '14px') : '16px'} !important;
//         font-family: Roboto !important;
//         font-weight: 700 !important;
//         color: #000000 !important;
//     }
//     foreignObject {
//         height: 220px ;
//     }
// `;

//     return (
//         <div style={{ width: '100%', height: '100%' }}>
//             <style>{internalStyle}</style>
//             <Chart options={options} series={options.series} type="donut"/>
//         </div>
//     );
// }

// export default DonutChart;





// new code rechart 


import React from 'react';

import {
    PieChart,
    Pie,
    ResponsiveContainer,
    Legend,
    Cell,
    Tooltip,
  } from "recharts";
  import "./DonutChart.css";
  import { useEffect, useState } from "react";
  import CustomTooltip from "./CustomTooltip";
  function DonutChart({ upXlmatch, xlmatch, lgmatch,uplgmatch ,mdmatch, smmatch, xsmatch,props}) {
    // const [outerRadius, setOuterRadius] = useState(80);
   
    // useEffect(() => {
    //   const handleResize = () => {
    //     let newOuterRadius = upXlmatch ?  80 : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? 65 : 65) : 70) : 72) :30) :  80; // Default outerRadius value
   
    //     // // Adjust outerRadius based on screen size
    //     // if (window.innerWidth <= 480) {
    //     //   newOuterRadius = 50;
    //     // } else if (window.innerWidth <= 768) {
    //     //   newOuterRadius = 60;
    //     // }
   
    //     setOuterRadius(newOuterRadius);
    //   };
   
    //   window.addEventListener("resize", handleResize);
   
    //   // Initial call to set the outerRadius based on screen size
    //   handleResize();
   
    //   return () => window.removeEventListener("resize", handleResize);
    // }, []);
   
    

    const data = [
      { name: "Completed", value: props.NewDashboardDetails &&
      props.NewDashboardDetails.getDashboardNew &&
      props.NewDashboardDetails.getDashboardNew.completedOrders },
      { name: "Cancelled", value: props.NewDashboardDetails &&
      props.NewDashboardDetails.getDashboardNew &&
      props.NewDashboardDetails.getDashboardNew.cancelledOrders},
      { name: "Rejected", value:props.NewDashboardDetails &&
      props.NewDashboardDetails.getDashboardNew &&
      props.NewDashboardDetails.getDashboardNew.rejectedOrders} 
    ];
    
    const data1=[
      { name: "Completed", value: 100 },
      { name: "Cancelled", value: 100},
      { name: "Rejected", value:100},
    ]
    const totalPercentage = data.reduce((total, dataPoint) => total + dataPoint.value, 0);
    


    

var COLORS=[] 
    if (totalPercentage){
       COLORS = ["#46A46C", "#FEC807", "#EA4335"];
    }else{
      COLORS = ["#646464", "#646464", "#646464"];
    }

    const LEGEND_COLORS=["#46A46C", "#FEC807", "#EA4335"];
    
   
    const renderLegendText = (value, entry) => {
      return <span style={{ color: "black", marginLeft: "0px" }}>{value}</span>;
    };

    const renderLegendText1 = (value, entry, index) => {
      return <span style={{ color: LEGEND_COLORS[index % LEGEND_COLORS.length], marginLeft: "0px" }}>{value}</span>;
    };


    const CustomLegend = (props) => {
      const { payload } = props; 
      return (
        <ul style={{ listStyleType: "none", padding: 0, margin: 0, textAlign: 'center',display:"flex",justifyContent:"space-around",flexWrap:"wrap"}}>
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
              <div style={{
                width: lgmatch?30:45.92,
                height: lgmatch?14:24.17,
                borderRadius:3,
                backgroundColor: LEGEND_COLORS[index % LEGEND_COLORS.length],
                marginRight: 8
              }} />
              <span style={{
                color: "black",
                fontFamily: "Roboto",
                fontWeight: '700',
                fontSize: upXlmatch ? "16px" : xlmatch ? (lgmatch ? (mdmatch ? (smmatch ? (xsmatch ? "12px" : "12px") : "13px") : "14px") : "15px") : "16px"
              }}>
                {entry.value}
              </span>
            </li>
          ))}
        </ul>
      );
    };
  
   
    return (
      <div className='testing-container' style={{height:"100%"}}>
     
        {totalPercentage?<ResponsiveContainer width="100%"
        height="100%"
       
         >
          <PieChart className="donutChart" >
            <Pie
              data={data}
              dataKey="value"
              innerRadius={mdmatch||smmatch||xsmatch||lgmatch?50:70}
              outerRadius={mdmatch||smmatch||xsmatch||lgmatch?70:100}
              fill="#82ca9d"
              label={false}

            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  stroke="none"
                  strokeWidth={0}
                />
              ))}
            </Pie>
              <Legend content={CustomLegend} />
            <Tooltip content={<CustomTooltip totalPercentage={totalPercentage} />} />
          </PieChart>
        </ResponsiveContainer>:(<ResponsiveContainer width="100%"
        height="100%"
       
         >
          <PieChart className="donutChart" >
            <Pie
              data={data1}
               dataKey="value"
              innerRadius={mdmatch||smmatch||xsmatch||lgmatch?50:70}
              outerRadius={mdmatch||smmatch||xsmatch||lgmatch?70:100}
              fill="#82ca9d"
              label={false}

            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                   fill={COLORS[index % COLORS.length]}
                  // fill={'gray'}
                  stroke="none"
                  strokeWidth={0}
                />
              ))}
            </Pie>
            <Legend content={CustomLegend} />
            
          </PieChart>
          
        </ResponsiveContainer>
      )}
      </div>
    );
  }
   
  export default  DonutChart;