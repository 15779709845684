// import React from "react";
// import { Row, Col } from "react-bootstrap";
// import Divider from "@mui/material/Divider";
// // import { useStyles } from "./style";
// // import { Container } from "react-bootstrap";
// import { Container } from "@mui/material";
// import CloseIcon from '@mui/icons-material/Close';
// import PropTypes from "prop-types";
// import AppBar from "@mui/material/AppBar";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// //import Stepper from "react-vertical-stepper";

//  import Stepper1 from "react-stepper-horizontal";
//  import {Stepper} from "@mui/material";
// import {Step} from "@mui/material";
// import {StepLabel} from "@mui/material";
// import {StepContent} from "@mui/material";
// // import { Button } from "react-bootstrap";
// import NoOrders from "../../utils/images/NoOrders.png";
// import NoImage from "../../utils/images/NoImage.png";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ListItem from "@mui/material/ListItem";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Slide from "@mui/material/Slide";
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import RadioGroup from "@mui/material/RadioGroup";
// import Radio from "@mui/material/Radio";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import "./index.css";
// import { useMediaQuery } from 'react-responsive';
// import { Icon, IconButton, Toolbar } from "@mui/material";
// import Grid from '@mui/material/Grid';
// import  Button  from "@mui/material/Button";
// // import  {makeStyle} from "@mui/styles/makeStyles";
// import { useLocation } from 'react-router-dom';
// import { ThemeProvider, createTheme } from "@mui/material/styles";

// function TabPanel(props) {

//   document.title = "Seller Portal | Shop Assistant Orders";
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// // const Transition = React.forwardRef(function Transition(props, ref) {
// //   return <Slide direction="up" ref={ref} {...props} />;
// // });

// export default function Orders(props) {
//   const location = useLocation();
//   console.log("the props of id in orders:",location.state.shopAssistantId)
//   const assistantId = location.state.shopAssistantId;

//   // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
//   const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })

//   const [value, setValue] = React.useState(0);
//   const [reassignopen, setReassignOpen] = React.useState(false);
//   const [shopAssistantId, setShopAssistantId] = React.useState(assistantId)
//   // const [reassignvalue, setReassignValue] = React.useState(props.getshopAssistantID());
//   // const [assistantID, setshopAssistantId] = React.useState(shopAssistantId)
//   const radioGroupRef = React.useRef(null);

//   // const handleClose = (newValue) => {
//   //   setReassignOpen(false);
//   // };

//   const handleEntering = () => {
//     if (radioGroupRef.current != null) {
//       radioGroupRef.current.focus();
//     }
//   };
//   const handleClickListItem = () => {
//     setReassignOpen(true);
//   };
//   const handleCancel = () => {
//     handleReassignClose();
//   };

//   const handleReassignClose = () => {
//     setReassignOpen(false);
//   };

//   const handleOk = (oid) => {

//     handleReassignClose(shopAssistantId);
//     props.AssignOrder(shopAssistantId, oid);
//     // setOpen(false);
//     props.history.push({
//       pathname: "/home/ShopAssistant/Viewshopassistants",
//     });

//   };

//   const handleReassignChange = (event) => {
//     setShopAssistantId(event.target.value);

//     // setReassignValue(event.target.value);
//   };

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//     if (newValue === 0) {
//       props.getShopassistantOrders(
//         props.location.state.shopAssistantId,
//         "Assigned"
//       );
//     } else if (newValue === 1) {
//       props.getShopassistantOrders(
//         props.location.state.shopAssistantId,
//         "Order-Ready"
//       );
//     } else if (newValue === 2) {
//       props.getShopassistantCompletedOrders(
//         props.location.state.shopAssistantId,
//         "Completed"
//       );
//     }
//   };
//   const [open, setOpen] = React.useState(false);
//   const [cancel, setCancel] = React.useState(false);

//   const handleClickOpen = (id) => {
//     setOpen(true);
//     props.getOrderDetails(id);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   // const handleCancelDialog = () => {
//   //   setCancel(true);
//   // };

//   const handleCancelClose = () => {
//     setCancel(false);
//   };
//   // const CancelOrder = (id) => {
//   //   props.cancelorder(id, "Order-Cancelled");
//   //   setCancel(false);
//   // };
//   const muiTheme = createTheme({
//     overrides: {
//       MuiStepIcon: {
//         root: {
//           color: "#b3b3b4", // or 'rgba(0, 0, 0, 1)'
//           "&$active": {
//             color: 'rgb(137, 199, 74, 1)',
//           },
//           "&$completed": {
//             color: "rgb(137, 199, 74, 1)",
//           },
//         },
//       },
//     },
//   });
//    const steps=[
//     { title: "order-placed" },
//     { title: "order-accepted" },
//     { title: "shopping-in-progress" },
//     { title: "order-ready" },
//     { title: "completed" },
//   ]
//   // const classes = useStyles();
//   let step;
//   const checkIndex = (status) => {
//     if (status === "Order-Placed") step = 0;
//     else if (status === "Order-Accepted") step = 1;
//     else if (status === "Shopping-In-Progress") step = 2;
//     else if (status === "Order-Ready") step = 3;
//     else if (status === "Completed") step = 4;
//     return step;
//   };
//   const GoBack = () => {
//     props.history.push({
//       pathname: "/home/ShopAssistant/Viewshopassistants",
//     });
//   };
//   React.useEffect(() => {
//     props.getShopassistantOrders(
//       props.location.state.shopAssistantId,
//       "Assigned"
//     );
//   },
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   []);
//   return (
//     isSmallScreen ?
//     <div  style={{
//       backgroundColor: "white",
//       border: "1px solid rgba(0, 0, 0, 0.16)",
//       borderRadius: "10px",
//       Top: "40px",
//       padding: "30px",
//       width: "100%",
//     }}>
//       <Row
//         style={{ color: 'rgb(137, 199, 74, 1)', fontWeight: 600, paddingLeft: "5px" }}
//       >
//         <ArrowBackIcon
//           style={{ color: 'rgb(137, 199, 74, 1)', float: "left", cursor: "pointer" }}
//           onClick={GoBack}
//         />
//         <p style={{ cursor: "pointer" }} onClick={GoBack}>
//           {" "}
//           Back
//         </p>
//       </Row>
//       <Row
//       // className={classes.Assistant}
//       >
//         <h4
//         // className={classes.heading}
//         >
//           {`${props.location.state.fname}` +
//             " " +
//             `${props.location.state.lname}`}
//         </h4>
//       </Row>
//       <Row
//       // className={classes.Assistant}
//       >
//         <h6>{props.location.state.phno}</h6>
//       </Row>

//       <AppBar position="static" color="default">
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           aria-label="simple tabs example"
//           // classes={{
//           //   indicator: classes.indicator,
//           // }}
//         >
//           <Tab label="Assigned" {...a11yProps(0)} />
//           <Tab label="Ready" {...a11yProps(1)} />
//           <Tab label="Completed" {...a11yProps(2)} />
//         </Tabs>
//       </AppBar>
//       <Row>
//         {props.ShopassistantOrderslist &&
//         props.ShopassistantOrderslist.data &&
//         props.ShopassistantOrderslist.data.shopAssistantActiveOrdersList ? (
//           props.ShopassistantOrderslist &&
//           props.ShopassistantOrderslist.data &&
//           props.ShopassistantOrderslist.data.shopAssistantActiveOrdersList
//             .length !== 0 ? (
//             props.ShopassistantOrderslist.data.shopAssistantActiveOrdersList.map(
//               (order,index) => (
//                 <Col sm={12} md={12} xs={12} key={index}>
//                   <div
//                     style={{
//                       backgroundColor: "white",
//                       boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 6px",
//                       padding: "16px",
//                       cursor: "pointer",
//                       marginBottom: "10px",
//                     }}
//                     key={`${order.id}${'div'}`}
//                   >
//                     <div
//                     key={`${order.id}${'2div'}`}
//                       style={{
//                         color: "rgb(13, 17, 54)",
//                         fontSize: "18px",
//                         fontWeight: "700px",
//                         justifyContent: "center",
//                         paddingBottom: "10px",
//                       }}
//                     >
//                       <Row style={{ paddingBottom: "10px" }} key={`${order.id} ${'row'}`}>
//                         <Col sm={3} key={`${order.id} ${'order'}`}>
//                           <div
//                           key={`${order.id}${'colDiv'}`}
//                             style={{
//                               backgroundColor: "rgb(245,245,245)",
//                               paddingLeft: "7px",
//                               paddingRight: "7px",
//                               paddingTop: "5px",
//                               paddingBottom: "5px",
//                               borderRadius: "15px",
//                               fontSize: "16px",
//                             }}
//                           >
//                             <span
//                             key={`${order.id}${'spn'}`}
//                             style={{ color: "black" }}>
//                               <b>Order</b>
//                             </span>
//                             <span
//                             key={`${order.id}${'span'}`}
//                               style={{
//                                 color: 'rgb(137, 199, 74, 1)',
//                                 float: "right",
//                               }}
//                             >
//                               <b
//                               key={`${order.id}${'bold'}`}>
//                                 {"\t#" + order.orderNumber}
//                               </b>
//                             </span>
//                           </div>
//                         </Col>
//                         <Col sm={6} style={{ textAlign: "center" }} key={`${order.orderNumber}`}></Col>
//                         <Col sm={3} style={{ textAlign: "right" }}key={`${order.orderNumber} ${'rightcol'}`}>
//                           <button
//                           key={`${order.id}${'button'}`}
//                             style={{
//                               borderRadius: "20px",
//                               fontSize: "14px",
//                               padding: "7px 25px",
//                               border: "none",
//                               color: "white",
//                               backgroundColor:'rgb(137, 199, 74, 1)',
//                             }}
//                             onClick={() => {
//                               handleClickOpen(order.id);
//                             }}
//                           >
//                             <b key={`${order.id}${'view'}`}>
//                               View Order
//                             </b>
//                           </button>
//                         </Col>
//                       </Row>
//                     </div>

//                     <Row style={{ paddingTop: "10px" }} key={`${order.id}${'delivery'}`}>
//                       <Col
//                       key={`${order.id}${'delcol'}`} sm={5}>Delivery Date :</Col>
//                       <Col
//                       key={`${order.id}${'del'}`}
//                       sm={7} style={{ textAlign: "right" }}>
//                         {order.deliveryDate}
//                       </Col>
//                     </Row>
//                     <Row
//                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
//                      key={`${order.id}${'deliRow'}`}>
//                       <Col key={`${order.id}${'Address'}`}
//                       sm={5}>Delivery Address :</Col>
//                       <Col
//                       key={`${order.id}${'Add'}`}
//                       sm={7} style={{ textAlign: "right" }}>
//                         {order.deliveryAddress}
//                       </Col>
//                     </Row>

//                     <Row
//                     key={`${order.id}${'priceRow'}`}
//                     style={{ paddingTop: "10px", paddingBottom: "10px" }}>
//                       <Col
//                       key={`${order.id}${'priceCol'}`}
//                         sm={5}
//                         style={{ textAlign: "left", cursor: "pointer" }}
//                       >
//                         <b key={`${order.id}${'span'}`}>
//                           {"Total price"}
//                         </b>
//                       </Col>
//                       <Divider horizontal="true" />
//                       <Col key={`${order.id}${'totl'}`}
//                       sm={7} style={{ textAlign: "right" }}>
//                         <b key={`${order.id}${'priceBold'}`}>{"₹ " + order.totalPrice}</b>
//                       </Col>
//                     </Row>
//                   </div>
//                 </Col>
//               )
//             )
//           ) : (
//             <div
//               style={{
//                 backgroundColor: "white",
//                 paddingTop: "30px",
//                 paddingBottom: "10px",
//                 width: "100%",
//                 justifyContent: "center",
//               }}
//             >
//               <div
//                 style={{
//                   textAlign: "center",
//                   justifyContent: "center",
//                   paddingBottom: "10px",
//                   width: "100%",
//                 }}
//               >
//                 <img
//                   src={NoOrders}
//                   alt="No-Orders!"
//                   style={{
//                     maxHeight: "100%",
//                     maxWidth: "100%",
//                     width: "170px",
//                     height: "150px",
//                   }}
//                 />
//                 <h5
//                   style={{
//                     color: "#324658",
//                     textAlign: "center",
//                     fontFamily: "Oxygen,sanSerif",
//                   }}
//                 >
//                   <b>No Orders!</b>
//                 </h5>
//               </div>
//             </div>
//           )
//         ) : props.ShopassistantOrderslist &&
//           props.ShopassistantOrderslist.data &&
//           props.ShopassistantOrderslist.data.shopAssistantCompletedOrdersList &&
//           props.ShopassistantOrderslist.data.shopAssistantCompletedOrdersList
//             .count !== 0 ? (
//           props.ShopassistantOrderslist.data.shopAssistantCompletedOrdersList.items.map(
//             (order,index) => (
//               <Col sm={12} md={12} xs={12} key={index}>
//                 <div
//                   style={{
//                     backgroundColor: "white",
//                     boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 6px",
//                     padding: "16px",
//                     cursor: "pointer",
//                     marginBottom: "10px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       color: "rgb(13, 17, 54)",
//                       fontSize: "18px",
//                       fontWeight: "700px",
//                       justifyContent: "center",
//                       paddingBottom: "10px",
//                     }}
//                   >
//                     <Row style={{ paddingBottom: "10px" }} >
//                       <Col sm={3}>
//                         <div
//                         key={`${order.id}${'C-DIV'}`}
//                           style={{
//                             backgroundColor: "rgb(245,245,245)",
//                             paddingLeft: "7px",
//                             paddingRight: "7px",
//                             paddingTop: "5px",
//                             paddingBottom: "5px",
//                             borderRadius: "15px",
//                             fontSize: "16px",
//                           }}
//                         >
//                           <span
//                           key={`${order.id}${'Comp-spn'}`}
//                           style={{ color: "black" }}>
//                             <b>Order</b>
//                           </span>
//                           <span
//                           key={`${order.id}${'Rightspan'}`}
//                             style={{ color: 'rgb(137, 199, 74, 1)', float: "right" }}
//                           >
//                             <b>{"\t#" + order.orderNumber}</b>
//                           </span>
//                         </div>
//                       </Col>
//                       <Col
//                       key={`${order.id}${'C_Col'}`}
//                       sm={6} style={{ textAlign: "center" }}></Col>
//                       <Col
//                       sm={3} style={{ textAlign: "right" }}>
//                         <button
//                           style={{
//                             borderRadius: "20px",
//                             fontSize: "14px",
//                             padding: "7px 25px",
//                             border: "none",
//                             color: "white",
//                             backgroundColor:'rgb(137, 199, 74, 1)',
//                           }}
//                           onClick={() => {
//                             handleClickOpen(order.id);
//                           }}
//                         >
//                           <b>View Order</b>
//                         </button>
//                       </Col>
//                     </Row>
//                   </div>

//                   <Row
//                   key={`${order.id}${'CDElivery'}`}
//                    style={{ paddingTop: "10px" }}>
//                     <Col
//                      sm={5}>Delivery Date :</Col>
//                     <Col
//                     sm={7} style={{ textAlign: "right" }}>
//                       {order.deliveryDate}
//                     </Col>
//                   </Row>
//                   <Row
//                   style={{ paddingTop: "10px", paddingBottom: "10px" }}>
//                     <Col
//                      sm={5}>Delivery Address :</Col>
//                     <Col
//                     sm={7} style={{ textAlign: "right" }}>
//                       {order.deliveryAddress}
//                     </Col>
//                   </Row>

//                   <Row
//                   style={{ paddingTop: "10px", paddingBottom: "10px" }}>
//                     <Col
//                       sm={5}
//                       style={{ textAlign: "left", cursor: "pointer" }}
//                     >
//                       <b>{"Total price"}</b>
//                     </Col>
//                     <Divider horizontal="true" />
//                     <Col sm={7}
//                      style={{ textAlign: "right" }}>
//                       <b>{"₹ " + order.totalPrice}</b>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//             )
//           )
//         ) : (
//           <div
//             style={{
//               backgroundColor: "white",
//               paddingTop: "30px",
//               paddingBottom: "10px",
//               width: "100%",
//               justifyContent: "center",
//             }}
//           >
//             <div
//               style={{
//                 textAlign: "center",
//                 justifyContent: "center",
//                 paddingBottom: "10px",
//                 width: "100%",
//               }}
//             >
//               <img
//                 src={NoOrders}
//                 alt="No-Orders!"
//                 style={{
//                   maxHeight: "100%",
//                   maxWidth: "100%",
//                   width: "170px",
//                   height: "150px",
//                 }}
//               />
//               <h5
//                 style={{
//                   color: "#324658",
//                   textAlign: "center",
//                   fontFamily: "Oxygen,sanSerif",
//                 }}
//               >
//                 <b>No Orders!</b>
//               </h5>
//             </div>
//           </div>
//         )}
//       </Row>

//       <Dialog
//       sm={12} md={12} xs={12}
//        scroll={'paper'}
//       open={open}
//       onClose={handleClose}
//       // TransitionComponent={Transition}
//     >
//       <Grid container direction="column" spacing={2}>
//         <Grid item container justifyContent="space-between" alignItems="center">
//           <Grid item>
//           <DialogTitle id="scroll-dialog-title">Order Details</DialogTitle>
//           </Grid>
//           <Grid item>
//          {/* <AppBar className={classes.appBar}> */}
//         {/* <Toolbar>
//           <IconButton size="large" edge="start" color="inherit" onClick={handleClose} aria-label="close">
//             <CloseIcon className="close-icon" />
//           </IconButton>
//           </Toolbar> */}
//           {/* </AppBar> */}
//           </Grid>
//           </Grid>
//         <Grid container spacing={2} >
//           <Grid item xs={12}>
//         <div
//           style={{
//             paddingLeft: "20px",
//             paddingTop: "15px",
//             fontSize: "15px",
//             fontWeight: 500,
//             color: "rgb(66, 69, 97)",
//             textAlign: "left",
//           }}
//         >
//           <b>Order Status : </b>
//           {props.orderDetails &&
//             props.orderDetails.data &&
//             props.orderDetails.data.orderdetails.lastStatus}
//           {props.orderDetails &&
//           props.orderDetails.data &&
//           props.orderDetails.data.orderdetails.lastStatus ===
//             "Completed" ? null : (
//               <Grid container justifyContent="flex-start" alignItems="center">
//               <Grid item xs={12} sm={6} md={6}>
//               <button
//               style={{
//                 height: "37px",
//                 width: "200px",
//                 backgroundColor:'rgb(137, 199, 74, 1)',
//                 color: "white",
//                 fontSize: "16px",
//                 fontWeight: 600,
//                 border: "none",
//                 borderRadius: 5,
//               }}
//                 className="reassign-button"
//                 onClick={handleClickListItem}
//               >
//                 Reassign Order
//               </button>
//               </Grid>
//             </Grid>
//           )}
//         </div>
//         </Grid>
//       </Grid>
//         <Grid item xm={12}>
//         <div
//           style={{
//             paddingTop: "15px",
//             fontSize: "15px",
//             fontWeight: 400,
//             color: "rgb(66, 69, 97)",
//           }}
//         >
//           {props.orderDetails &&
//             props.orderDetails.data &&
//             props.orderDetails.data.orderdetails && (
//               <ThemeProvider theme={muiTheme}>
//               <Stepper
//                 orientation="vertical"
//                 // completeTitleColor='rgb(137, 199, 74, 1)'
//                 // completeColor='rgb(137, 199, 74, 1)'
//                 // activeTitleColor='rgb(137, 199, 74, 1)'
//                 // activeColor='rgb(137, 199, 74, 1)'
//                 activeStep={checkIndex(
//                   props.orderDetails.data.orderdetails.lastStatus
//                 )}
//                 style={{width:'100%'}}
//               >
//                {steps.map((title) =>(
//                 <Step >
//                   <StepLabel >{title.title}</StepLabel>
//                 </Step>
//                ))}

//               </Stepper>
//               </ThemeProvider>
//             )}
//         </div>
//         </Grid>
//         <Grid item>
//         <div
//           style={{
//             paddingLeft: "20px",
//             paddingTop: "30px",
//             fontSize: "15px",
//             color: "rgb(66, 69, 97)",
//           }}
//         >
//           <b>Order Details</b>
//         </div>
//         </Grid>
//         {props.orderDetails &&
//           props.orderDetails.data &&
//           props.orderDetails.data.orderdetails && (
//             <Grid item container spacing={2}>
//               <Grid item xs={12} sm={6} md={4} lg={3}>
//                 <div className="ordernumber">
//                   <>
//                     <p
//                       className="value"
//                       style={{ fontSize: "14px", paddingBottom: "8px" }}
//                     >
//                       <b> {"Name"}</b>
//                     </p>
//                     <p className="value" style={{ fontWeight: "700px" }}>
//                       {props.orderDetails.data.orderdetails.customerName}
//                     </p>
//                   </>
//                 </div>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4} lg={3}>
//                 <div className="ordernumber">
//                  <>
//                     <p
//                       className="value"
//                       style={{ fontSize: "14px", paddingBottom: "8px" }}
//                     >
//                       <b> {"Order Number"}</b>
//                     </p>
//                     <p className="value" style={{ fontWeight: "700px" }}>
//                       {" "}
//                       {props.orderDetails.data.orderdetails.orderNumber}
//                     </p>
//                   </>
//                 </div>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4} lg={3}>
//                 <div className="ordernumber">
//                <>
//                     <p
//                       className="value"
//                       style={{ fontSize: "14px", paddingBottom: "8px" }}
//                     >
//                       <b> {"Delivery Date"}</b>
//                     </p>
//                     <p className="value" style={{ fontWeight: "700px" }}>
//                       {" "}
//                       {props.orderDetails.data.orderdetails.deliveryDate}
//                     </p>
//                 </>
//                 </div>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4} lg={3}>
//                 <div className="ordernumber">
//                   <>
//                     <p
//                       className="value"
//                       style={{ fontSize: "14px", paddingBottom: "8px" }}
//                     >
//                       <b> {"Delivery Time"}</b>
//                     </p>
//                     <p className="value" style={{ fontWeight: "700px" }}>
//                       {" "}
//                       {props.orderDetails.data.orderdetails.deliveryTime}
//                     </p>
//                   </>
//                 </div>
//                 </Grid>
//                 <Grid item xs={12}>
//                 <div className="ordernumber">
//                   <>
//                     <p
//                       className="value"
//                       style={{ fontSize: "14px", paddingBottom: "8px" }}
//                     >
//                       <b> {"Delivery Address"}</b>
//                     </p>
//                     <p className="value" style={{ fontWeight: "700px" }}>
//                       {" "}
//                       {props.orderDetails &&
//                         props.orderDetails.data &&
//                         props.orderDetails.data.orderdetails.deliveryAddress}
//                     </p>
//                   </>
//                 </div>
//                 </Grid>
//            </Grid>
//           )}
//         <Grid item>
//         <div
//           style={{
//             paddingLeft: "20px",
//             paddingTop: "50px",
//             fontSize: "15px",
//             color: "rgb(66, 69, 97)",
//           }}
//         >
//           <b>Order Items</b>
//         </div>
//       </Grid>
//         {props.orderDetails &&
//           props.orderDetails.data.orderdetails.products.map((p,index) => (
//             <Grid item xs={12} key={index}>
//            <ListItem style={{ paddingLeft: "50px" }} key={index}>
//               <Row style={{ fontSize: "15px" }}>
//                 <img
//                   src={(p.productid&&p.productid.image
//                     &&p.productid.image.primary===null)||(p.productid&&p.productid.image
//                       &&p.productid.image.primary==="null")?
//                     NoImage
//                     :p.productid&&
//                     p.productid.image&&p.productid.image.primary}
//                   alt="product"
//                   style={{ width: "70px", height: "70px" }}
//                 />

//                 <span style={{ paddingLeft: "15px", paddingTop: "20px" }}>
//                   {p.productid.productname}
//                   <p>
//                     <b>₹</b>
//                     {" " + p.productPrice}
//                     {}
//                   </p>
//                   {"Qty : " + p.quantity}
//                 </span>
//               </Row>
//             </ListItem>
//             </Grid>
//           ))}
//           </Grid>
//       </Dialog>

//       {/* cancel your order */}
//       <Dialog
//         open={cancel}
//         onClose={handleCancelClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"Are you Sure ?"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             This will cancel your order !
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Grid container spacing={2} justifyContent="center">
//           <Grid item xs={6}>
//           <Button
//             style={{
//               justifyContent: " center",
//               color: "rgb(255, 255, 255)",
//               backgroundColor:'rgb(137, 199, 74, 1)',
//               boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//               minWidth: " auto",
//               /* width: 50%; */
//               height: "37px",
//               fontSize: "16px",
//               fontWeight: "600",
//               borderRadius: "5px",
//               borderWidth: "1px",
//               borderStyle: " solid",
//               borderColor: "transparent",
//               borderImage: "initial",
//               overflow: "hidden",
//               outline: "none",
//               minHeight: " 14px",
//               fontFamily: "Varela Round, sans-serif",
//             }}
//             basic
//             color="rgb(137, 199, 74, 1)"
//             // onClick={() => { CancelOrder(props.successData.data.orderdetails.id)}}
//             autoFocus
//           >
//             yes
//           </Button>
//           </Grid>
//           <Grid item xs={6}>
//           <button
//             style={{
//               justifyContent: " center",
//               color: "rgb(255, 255, 255)",
//               backgroundColor:'rgb(137, 199, 74, 1)',
//               boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//               minWidth: " auto",
//               /* width: 50%; */
//               height: "37px",
//               fontSize: "16px",
//               fontWeight: "600",
//               borderRadius: "5px",
//               borderWidth: "1px",
//               borderStyle: " solid",
//               borderColor: "transparent",
//               borderImage: "initial",
//               overflow: "hidden",
//               outline: "none",
//               minHeight: " 14px",
//               fontFamily: "Varela Round, sans-serif",
//             }}
//             onClick={handleCancelClose}
//             basic
//             color="red"
//           >
//             No
//           </button>
//           </Grid>
//         </Grid>
//         </DialogActions>
//       </Dialog>

//       {/* REASSIGNING ORDERS */}
//       <Dialog
//         disableBackdropClick
//         disableEscapeKeyDown
//         maxWidth="lg"
//         onEntering={handleEntering}
//         aria-labelledby="confirmation-dialog-title"
//         open={reassignopen}
//         // classes={classes.reassign}
//         // {...other}
//       >
//         <DialogTitle id="confirmation-dialog-title">
//           Shop Assistant List
//         </DialogTitle>
//         <DialogContent dividers>
//           <RadioGroup
//             ref={radioGroupRef}
//             aria-label="ringtone"
//             name="ringtone"
//             value={shopAssistantId}
//             onChange={handleReassignChange}

//           >
//             {props.Shopassistantlist &&
//               props.Shopassistantlist.data &&
//               props.Shopassistantlist.data.shopAssistantsList&&
//               props.Shopassistantlist.data.shopAssistantsList.length!==0?
//               props.Shopassistantlist.data.shopAssistantsList.map((option,index) => (
//                 <FormControlLabel
//                   value={option.id}
//                   key={index}
//                   control={<Radio />}
//                   label={option.firstName}
//                   checked={shopAssistantId === option.id}
//                 />
//               ))
//             :
//             "No Shop Assistants"}
//           </RadioGroup>
//         </DialogContent>
//         <DialogActions>
//         <Grid container justifyContent="space-between">
//          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
//           <button
//             style={{
//               justifyContent: " center",
//               color: "rgb(255, 255, 255)",
//               backgroundColor:'rgb(137, 199, 74, 1)',
//               boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//               minWidth: " auto",
//               /* width: 50%; */
//               height: "37px",
//               fontSize: "16px",
//               fontWeight: "600",
//               borderRadius: "5px",
//               borderWidth: "1px",
//               borderStyle: " solid",
//               borderColor: "transparent",
//               borderImage: "initial",
//               overflow: "hidden",
//               outline: "none",
//               minHeight: " 14px",
//               fontFamily: "Varela Round, sans-serif",
//             }}
//             autoFocus
//             onClick={handleCancel}
//           >
//             Cancel
//           </button>
//           </Grid>
//           <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
//           <button
//             style={{
//               justifyContent: " center",
//               color: "rgb(255, 255, 255)",
//               backgroundColor:'rgb(137, 199, 74, 1)',
//               boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//               minWidth: " auto",
//               /* width: 50%; */
//               height: "37px",
//               fontSize: "16px",
//               fontWeight: "600",
//               borderRadius: "5px",
//               borderWidth: "1px",
//               borderStyle: " solid",
//               borderColor: "transparent",
//               borderImage: "initial",
//               overflow: "hidden",
//               outline: "none",
//               minHeight: " 14px",
//               fontFamily: "Varela Round, sans-serif",
//             }}
//             onClick={() => handleOk(props.orderDetails.data.orderdetails.id)}
//           >
//             Ok
//           </button>
//           </Grid>
//           </Grid>
//         </DialogActions>
//       </Dialog>
//     </div>

//     :

//     <Container maxWidth="md"  style={{
//       backgroundColor: "white",
//       border: "1px solid rgba(0, 0, 0, 0.16)",
//       borderRadius: "10px",
//       Top: "40px",
//       padding: "30px",
//       width: "100%",
//     }}>
//     <Row
//       style={{ color: 'rgb(137, 199, 74, 1)', fontWeight: 600, paddingLeft: "5px" }}
//     >
//       <ArrowBackIcon
//         style={{ color: 'rgb(137, 199, 74, 1)', float: "left", cursor: "pointer" }}
//         onClick={GoBack}
//       />
//       <p style={{ cursor: "pointer" }} onClick={GoBack}>
//         {" "}
//         Back
//       </p>
//     </Row>
//     <Row
//     // className={classes.Assistant}
//     >
//       <h4
//       // className={classes.heading}
//       >
//         {`${props.location.state.fname}` +
//           " " +
//           `${props.location.state.lname}`}
//       </h4>
//     </Row>
//     <Row
//     //  className={classes.Assistant}
//      >
//       <h6>{props.location.state.phno}</h6>
//     </Row>

//     <AppBar position="static" color="default">
//       <Tabs
//         value={value}
//         onChange={handleChange}
//         aria-label="simple tabs example"

//         // classes={{
//         //   indicator: classes.indicator,
//         // }}

//       >
//         <Tab label="Assigned" {...a11yProps(0)} />
//         <Tab label="Ready" {...a11yProps(1)} />
//         <Tab label="Completed" {...a11yProps(2)} />
//       </Tabs>
//     </AppBar>
//     <Row>
//       {props.ShopassistantOrderslist &&
//       props.ShopassistantOrderslist.data &&
//       props.ShopassistantOrderslist.data.shopAssistantActiveOrdersList ? (
//         props.ShopassistantOrderslist &&
//         props.ShopassistantOrderslist.data &&
//         props.ShopassistantOrderslist.data.shopAssistantActiveOrdersList
//           .length !== 0 ? (
//           props.ShopassistantOrderslist.data.shopAssistantActiveOrdersList.map(
//             (order,index) => (
//               <Col sm={12} md={12} xs={12} key={index}>
//                 <div
//                   style={{
//                     backgroundColor: "white",
//                     boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 6px",
//                     padding: "16px",
//                     cursor: "pointer",
//                     marginBottom: "10px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       color: "rgb(13, 17, 54)",
//                       fontSize: "18px",
//                       fontWeight: "700px",
//                       justifyContent: "center",
//                       paddingBottom: "10px",
//                     }}
//                   >
//                     <Row style={{ paddingBottom: "10px" }}>
//                       <Col sm={3}>
//                         <div
//                           style={{
//                             backgroundColor: "rgb(245,245,245)",
//                             paddingLeft: "7px",
//                             paddingRight: "7px",
//                             paddingTop: "5px",
//                             paddingBottom: "5px",
//                             borderRadius: "15px",
//                             fontSize: "16px",
//                           }}
//                         >
//                           <span
//                           style={{ color: "black" }}>
//                             <b>Order</b>
//                           </span>
//                           <span
//                             style={{
//                               color: 'rgb(137, 199, 74, 1)',
//                               float: "right",
//                             }}
//                           >
//                             <b>
//                               {"\t#" + order.orderNumber}
//                             </b>
//                           </span>
//                         </div>
//                       </Col>
//                       <Col sm={6} style={{ textAlign: "center" }}></Col>
//                       <Col sm={3} style={{ textAlign: "right" }}>
//                         <button
//                           style={{
//                             borderRadius: "20px",
//                             fontSize: "14px",
//                             padding: "7px 25px",
//                             border: "none",
//                             color: "white",
//                             backgroundColor:'rgb(137, 199, 74, 1)',
//                             boxShadow: "rgba(0, 0, 0, 0.26) 0px 0px 6px",
//                           }}
//                           onClick={() => {
//                             handleClickOpen(order.id);
//                           }}
//                         >
//                           <b>
//                             View Order
//                           </b>
//                         </button>
//                       </Col>
//                     </Row>
//                   </div>

//                   <Row style={{ paddingTop: "10px" }}>
//                     <Col sm={5}>Delivery Date :</Col>
//                     <Col
//                     sm={7} style={{ textAlign: "right" }}>
//                       {order.deliveryDate}
//                     </Col>
//                   </Row>
//                   <Row
//                    style={{ paddingTop: "10px", paddingBottom: "10px" }}>
//                     <Col
//                     sm={5}>Delivery Address :</Col>
//                     <Col
//                     sm={7} style={{ textAlign: "right" }}>
//                       {order.deliveryAddress}
//                     </Col>
//                   </Row>

//                   <Row
//                   style={{ paddingTop: "10px", paddingBottom: "10px" }}>
//                     <Col
//                       sm={5}
//                       style={{ textAlign: "left", cursor: "pointer" }}
//                     >
//                       <b>
//                         {"Total price"}
//                       </b>
//                     </Col>
//                     <Divider horizontal="true" />
//                     <Col
//                     sm={7} style={{ textAlign: "right" }}>
//                       <b>{"₹ " + order.totalPrice}</b>
//                     </Col>
//                   </Row>
//                 </div>
//               </Col>
//             )
//           )
//         ) : (
//           <div
//             style={{
//               backgroundColor: "white",
//               paddingTop: "30px",
//               paddingBottom: "10px",
//               width: "100%",
//               justifyContent: "center",
//             }}
//           >
//             <div
//               style={{
//                 textAlign: "center",
//                 justifyContent: "center",
//                 paddingBottom: "10px",
//                 width: "100%",
//               }}
//             >
//               <img
//                 src={NoOrders}
//                 alt="No-Orders!"
//                 style={{
//                   maxHeight: "100%",
//                   maxWidth: "100%",
//                   width: "170px",
//                   height: "150px",
//                 }}
//               />
//               <h5
//                 style={{
//                   color: "#324658",
//                   textAlign: "center",
//                   fontFamily: "Oxygen,sanSerif",
//                 }}
//               >
//                 <b>No Orders!</b>
//               </h5>
//             </div>
//           </div>
//         )
//       ) : props.ShopassistantOrderslist &&
//         props.ShopassistantOrderslist.data &&
//         props.ShopassistantOrderslist.data.shopAssistantCompletedOrdersList &&
//         props.ShopassistantOrderslist.data.shopAssistantCompletedOrdersList
//           .count !== 0 ? (
//         props.ShopassistantOrderslist.data.shopAssistantCompletedOrdersList.items.map(
//           (order,index) => (
//             <Col sm={12} md={12} xs={12} key={index}>
//               <div
//                 style={{
//                   backgroundColor: "white",
//                   boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 6px",
//                   padding: "16px",
//                   cursor: "pointer",
//                   marginBottom: "10px",
//                 }}
//               >
//                 <div
//                   style={{
//                     color: "rgb(13, 17, 54)",
//                     fontSize: "18px",
//                     fontWeight: "700px",
//                     justifyContent: "center",
//                     paddingBottom: "10px",
//                   }}
//                 >
//                   <Row style={{ paddingBottom: "10px" }}>
//                     <Col sm={3}>
//                       <div
//                         style={{
//                           backgroundColor: "rgb(245,245,245)",
//                           paddingLeft: "7px",
//                           paddingRight: "7px",
//                           paddingTop: "5px",
//                           paddingBottom: "5px",
//                           borderRadius: "15px",
//                           fontSize: "16px",
//                         }}
//                       >
//                         <span
//                         style={{ color: "black" }}>
//                           <b>Order</b>
//                         </span>
//                         <span
//                           style={{ color: 'rgb(137, 199, 74, 1)', float: "right" }}
//                         >
//                           <b>{"\t#" + order.orderNumber}</b>
//                         </span>
//                       </div>
//                     </Col>
//                     <Col
//                     sm={6} style={{ textAlign: "center" }}></Col>
//                     <Col
//                     sm={3} style={{ textAlign: "right" }}>
//                       <button
//                         style={{
//                           borderRadius: "20px",
//                           fontSize: "14px",
//                           padding: "7px 25px",
//                           border: "none",
//                           color: "white",
//                           backgroundColor:'rgb(137, 199, 74, 1)',
//                           boxShadow: "rgba(0, 0, 0, 0.26) 0px 0px 6px",
//                         }}
//                         onClick={() => {
//                           handleClickOpen(order.id);
//                         }}
//                       >
//                         <b>View Order</b>
//                       </button>
//                     </Col>
//                   </Row>
//                 </div>

//                 <Row
//                  style={{ paddingTop: "10px" }}>
//                   <Col
//                    sm={5}>Delivery Date :</Col>
//                   <Col
//                   sm={7} style={{ textAlign: "right" }}>
//                     {order.deliveryDate}
//                   </Col>
//                 </Row>
//                 <Row
//                 style={{ paddingTop: "10px", paddingBottom: "10px" }}>
//                   <Col
//                    sm={5}>Delivery Address :</Col>
//                   <Col
//                   sm={7} style={{ textAlign: "right" }}>
//                     {order.deliveryAddress}
//                   </Col>
//                 </Row>

//                 <Row
//                 style={{ paddingTop: "10px", paddingBottom: "10px" }}>
//                   <Col
//                     sm={5}
//                     style={{ textAlign: "left", cursor: "pointer" }}
//                   >
//                     <b>{"Total price"}</b>
//                   </Col>
//                   <Divider horizontal="true" />
//                   <Col sm={7}
//                    style={{ textAlign: "right" }}>
//                     <b >{"₹ " + order.totalPrice}</b>
//                   </Col>
//                 </Row>
//               </div>
//             </Col>
//           )
//         )
//       ) : (
//         <div
//           style={{
//             backgroundColor: "white",
//             paddingTop: "30px",
//             paddingBottom: "10px",
//             width: "100%",
//             justifyContent: "center",
//           }}
//         >
//           <div
//             style={{
//               textAlign: "center",
//               justifyContent: "center",
//               paddingBottom: "10px",
//               width: "100%",
//             }}
//           >
//             <img
//               src={NoOrders}
//               alt="No-Orders!"
//               style={{
//                 maxHeight: "100%",
//                 maxWidth: "100%",
//                 width: "170px",
//                 height: "150px",
//               }}
//             />
//             <h5
//               style={{
//                 color: "#324658",
//                 textAlign: "center",
//                 fontFamily: "Oxygen,sanSerif",
//               }}
//             >
//               <b>No Orders!</b>
//             </h5>
//           </div>
//         </div>
//       )}
//     </Row>

//     <Dialog
//        scroll={'paper'}
//       open={open}
//       onClose={handleClose}
//       // TransitionComponent={Transition}
//     >
//        <Grid container direction="row" alignItems={"center"}  justifyContent="space-between">
//     <Grid item>
//     <DialogTitle id="scroll-dialog-title">Order Details</DialogTitle>
//     </Grid>
//     {/* <Grid item>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton size="large" edge="start" color="inherit" onClick={handleClose} aria-label="close">
//             <CloseIcon className="close-icon" />
//           </IconButton>
//         </Toolbar>
//         </AppBar>
//     </Grid> */}
//     <Grid item>
//       <div style={{ paddingLeft: "20px", paddingTop: "15px", fontSize: "15px", fontWeight: 500, color: "rgb(66, 69, 97)", textAlign: "left" }}>
//         <b>Order Status : </b>
//         {props.orderDetails && props.orderDetails.data && props.orderDetails.data.orderdetails.lastStatus}
//         {props.orderDetails && props.orderDetails.data && props.orderDetails.data.orderdetails.lastStatus === "Completed" ? null : (
//           <span style={{ paddingLeft: "100px" }}>
//             <button  style={{
//                 height: "37px",
//                 width: "200px",
//                 backgroundColor:'rgb(137, 199, 74, 1)',
//                 color: "white",
//                 fontSize: "16px",
//                 fontWeight: 600,
//                 border: "none",
//                 borderRadius: 5,
//               }} className="reassign-button" onClick={handleClickListItem}>Reassign Order </button>
//           </span>
//         )}
//       </div>
//     </Grid>
//     <Grid item>
//       <div
//         style={{
//           paddingLeft: "20px",
//           paddingTop: "15px",
//           fontSize: "15px",
//           fontWeight: 400,
//           color: "rgb(66, 69, 97)",
//         }}
//       >
//         {props.orderDetails &&
//           props.orderDetails.data &&
//           props.orderDetails.data.orderdetails && (
//             <Stepper1
//             orientation='vertical'
//               completeTitleColor='rgb(137, 199, 74, 1)'
//               completeColor='rgb(137, 199, 74, 1)'
//               activeColor='rgb(137, 199, 74, 1)'
//               activeTitleColor='rgb(137, 199, 74, 1)'
//               steps={[
//                 { title: "order-placed" },
//                 { title: "order-accepted" },
//                 { title: "shopping-in-progress" },
//                 { title: "order-ready" },
//                 { title: "completed" },
//               ]}
//               activeStep={checkIndex(
//                 props.orderDetails.data.orderdetails.lastStatus
//               )}
//             />
//           )}
//       </div>
//       </Grid>
//       <Grid item>
//       <div
//         style={{
//           paddingLeft: "20px",
//           paddingTop: "30px",
//           fontSize: "15px",
//           color: "rgb(66, 69, 97)",
//         }}
//       >
//         <b>Order Details</b>
//       </div>
//       {props.orderDetails &&
//         props.orderDetails.data &&
//         props.orderDetails.data.orderdetails && (
//           <>
//             <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 550 }} aria-label="simple table">
//         <TableHead>
//           <TableRow>
//             <TableCell>{"Name"}</TableCell>
//             <TableCell align="right">{"Order Number"}</TableCell>
//             <TableCell align="right">{"Delivery Date"}</TableCell>
//             <TableCell align="right">{"Delivery Time"}</TableCell>
//             <TableCell align="right"> {"Delivery Address"}</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {/* {rows.map((row) => ( */}
//             <TableRow
//               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
//             >
//               <TableCell component="th" scope="row">
//               {props.orderDetails.data.orderdetails.customerName}
//               </TableCell>
//               <TableCell align="right">{props.orderDetails.data.orderdetails.orderNumber}</TableCell>
//               <TableCell align="right">{props.orderDetails.data.orderdetails.deliveryDate}</TableCell>
//               <TableCell align="right">{props.orderDetails.data.orderdetails.deliveryTime}</TableCell>
//               <TableCell align="right"> {props.orderDetails.data.orderdetails.deliveryAddress}</TableCell>
//             </TableRow>
//           {/* ))} */}
//         </TableBody>
//       </Table>
//     </TableContainer>

//           </>
//         )}
//         </Grid>
//         <Grid item>
//       <div
//         style={{
//           paddingLeft: "20px",
//           paddingTop: "50px",
//           fontSize: "15px",
//           color: "rgb(66, 69, 97)",
//         }}
//       >
//         <b>Order Items</b>
//       </div>
//       {props.orderDetails &&
//         props.orderDetails.data.orderdetails.products.map((p,index) => (
//           <ListItem style={{ paddingLeft: "50px" }} key={index}>
//             <Row style={{ fontSize: "15px" }}>
//               <img
//                 src={(p.productid&&p.productid.image
//                   &&p.productid.image.primary===null)||(p.productid&&p.productid.image
//                     &&p.productid.image.primary==="null")?
//                   NoImage
//                   :p.productid&&
//                   p.productid.image&&p.productid.image.primary}
//                 alt="product"
//                 style={{ width: "70px", height: "70px" }}
//               />

//               <span style={{ paddingLeft: "15px", paddingTop: "20px" }}>
//                 {p.productid.productname}
//                 <p>
//                   <b>₹</b>
//                   {/* {`${'\t'} ${p.productid.price}`} */}
//                   {" " + p.productPrice}
//                 </p>
//                 {`${'Qty :' }  ${p.quantity}`}
//               </span>
//             </Row>
//           </ListItem>
//         ))}
//         </Grid>
//         </Grid>
//     </Dialog>
//     <Dialog
//       open={cancel}
//       onClose={handleCancelClose}
//       aria-labelledby="alert-dialog-title"
//       aria-describedby="alert-dialog-description"
//     >
//       <DialogTitle id="alert-dialog-title">{"Are you Sure ?"}</DialogTitle>
//       <DialogContent>
//         <DialogContentText id="alert-dialog-description">
//           This will cancel your order !
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <button
//           style={{
//             justifyContent: " center",
//             color: "rgb(255, 255, 255)",
//             backgroundColor:'rgb(137, 199, 74, 1)',
//             boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//             minWidth: " auto",
//             /* width: 50%; */
//             height: "37px",
//             fontSize: "16px",
//             fontWeight: "600",
//             borderRadius: "5px",
//             borderWidth: "1px",
//             borderStyle: " solid",
//             borderColor: "transparent",
//             borderImage: "initial",
//             overflow: "hidden",
//             outline: "none",
//             minHeight: " 14px",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//           basic
//           color="rgb(137, 199, 74, 1)"
//           // onClick={() => { CancelOrder(props.successData.data.orderdetails.id)}}
//           autoFocus
//         >
//           yes
//         </button>
//         <button
//           style={{
//             justifyContent: " center",
//             color: "rgb(255, 255, 255)",
//             backgroundColor:'rgb(137, 199, 74, 1)',
//             boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//             minWidth: " auto",
//             /* width: 50%; */
//             height: "37px",
//             fontSize: "16px",
//             fontWeight: "600",
//             borderRadius: "5px",
//             borderWidth: "1px",
//             borderStyle: " solid",
//             borderColor: "transparent",
//             borderImage: "initial",
//             overflow: "hidden",
//             outline: "none",
//             minHeight: " 14px",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//           onClick={handleCancelClose}
//           basic
//           color="red"
//         >
//           No
//         </button>
//       </DialogActions>
//     </Dialog>
//     {/* REASSIGNING ORDERS */}
//     <Dialog
//       disableBackdropClick
//       disableEscapeKeyDown
//       maxWidth="lg"
//       onEntering={handleEntering}
//       aria-labelledby="confirmation-dialog-title"
//       open={reassignopen}
//       // classes={classes.reassign}
//       // {...other}
//     >
//       <DialogTitle id="confirmation-dialog-title">
//         Shop Assistant List
//       </DialogTitle>
//       <DialogContent dividers>
//         <RadioGroup
//           ref={radioGroupRef}
//           aria-label="ringtone"
//           name="ringtone"
//           value={shopAssistantId}
//           onChange={handleReassignChange}
//         >
//           {props.Shopassistantlist &&
//             props.Shopassistantlist.data &&
//             props.Shopassistantlist.data.shopAssistantsList&&
//             props.Shopassistantlist.data.shopAssistantsList.length!==0?
//             props.Shopassistantlist.data.shopAssistantsList.map((option,index) => (
//               <FormControlLabel
//                 value={option.id}
//                 key={index}
//                 control={<Radio />}
//                 label={option.firstName}
//                 checked={option.id === shopAssistantId}
//               />
//             ))
//           :
//           "No Shop Assistants"}
//         </RadioGroup>
//       </DialogContent>
//       <DialogActions>
//         <button
//           style={{
//             justifyContent: " center",
//             color: "rgb(255, 255, 255)",
//             backgroundColor:'rgb(137, 199, 74, 1)',
//             boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//             minWidth: " auto",
//             /* width: 50%; */
//             height: "37px",
//             fontSize: "16px",
//             fontWeight: "600",
//             borderRadius: "5px",
//             borderWidth: "1px",
//             borderStyle: " solid",
//             borderColor: "transparent",
//             borderImage: "initial",
//             overflow: "hidden",
//             outline: "none",
//             minHeight: " 14px",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//           autoFocus
//           onClick={handleCancel}
//         >
//           Cancel
//         </button>
//         <button
//           style={{
//             justifyContent: " center",
//             color: "rgb(255, 255, 255)",
//             backgroundColor:'rgb(137, 199, 74, 1)',
//             boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//             minWidth: " auto",
//             /* width: 50%; */
//             height: "37px",
//             fontSize: "16px",
//             fontWeight: "600",
//             borderRadius: "5px",
//             borderWidth: "1px",
//             borderStyle: " solid",
//             borderColor: "transparent",
//             borderImage: "initial",
//             overflow: "hidden",
//             outline: "none",
//             minHeight: " 14px",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//           onClick={() => handleOk(props.orderDetails.data.orderdetails.id)}
//         >
//           Ok
//         </button>
//       </DialogActions>
//     </Dialog>
//   </Container>
//   );
// }

//new code

import React, { useEffect, useState } from "react";
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import "./AssignmentPage.css";
import CustomButton from "../Commoncomponents/button/index";
import CustomBadge from "../Commoncomponents/badge/index";
import { Grid, Typography } from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card/index";
import ShopAssistantClock from "../../utils/Icon/ShopAssistantClock.svg";
import ClockAnimation from "../../utils/Icon/ClockAnimation.svg";
import Arrow from "../../utils/Icon/Arrow.svg";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { HeaderFontSize_24 } from "../Typography/index";
import { contentFontsize_18_xs_11 } from "../Typography/index";
import {HeaderNavigationFontSize_20_xs_13} from "../Typography/index";
import { contentFontsize_14_xs_10 } from "../Typography/index";
import { ButtonFontSize_16_xs_11 } from "../Typography/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {useSelector} from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';
import dummy from '../../utils/images/dummy.png';
 

export default function Orders(props) {
  // Filtering the dataArray based on ArrowID
  const [assignedState, setAssignedState] = useState(false);
  const [readyState, setReadyState] = useState(false);
  const [completedState, setCompletedState] = useState(false);
  const [assignedColorState, setAssignedColorState] = useState(false);
  const [assignedOrderAPI, setAssignedOrderAPI] = useState();
  const [readyOrderAPI, SetReadyOrderAPI] = useState();
  const [completedOrderAPI, setCompletedOrderAPI] = useState();
  const [orderReady,setOrderReady]=useState(false);
  const [outforDelivery,setOutforDelivery]=useState(false);


  const location = useLocation();
  const navigate=useNavigate();
  
  const { shopassistanOrderLoading,shopassistantCompletedOrderLoading } = useSelector((state) => state.SignInReducer);

  const assistantId = location.state.shopAssistantId;

  useEffect(() => {
   // props.getShopassistantOrders(assistantId, "Order-Accepted");
    // props.getShopassistantOrders(assistantId, "Order-Ready");
    // props.getShopassistantCompletedOrders(assistantId, "Completed");
    if(location.state.moveReady){
      setAssignedState(false);
      setReadyState(true);
      setOrderReady(false);
      setOutforDelivery(false);
      setCompletedState(false);
      setAssignedColorState(!assignedColorState);
      props.getShopassistantOrders(assistantId, "Order-Ready");
    }else if(location.state.moveDelivery){
      setAssignedState(false);
    setReadyState(false);
    setOrderReady(false);
    setOutforDelivery(true);
    setCompletedState(false);
    props.getShopassistantOrders(assistantId, "Out-For-Delivery");
    }else if(location.state.moveCompleted) {
      setAssignedState(false);
      setReadyState(false);
      setOrderReady(false);
      setOutforDelivery(false);
      setCompletedState(true);
      setAssignedColorState(!assignedColorState);
      props.getShopassistantCompletedOrders(assistantId, "Completed");
    }
    
    else{
    setAssignedState(true);
    setReadyState(false);
    setOrderReady(false);
    setOutforDelivery(false);
    setCompletedState(false);
    setAssignedColorState(!assignedColorState);
    props.getShopassistantOrders(assistantId, "Order-Accepted");}
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const assignedOrdersPromise = props.getShopassistantOrders(assistantId, "Assigned");
  //       const completedOrdersPromise = props.getShopassistantCompletedOrders(assistantId, "Completed");

  //       // Fetch both data sets concurrently
  //       const [assignedOrders, completedOrders] = await Promise.all([assignedOrdersPromise, completedOrdersPromise]);

  //       // Now you have both data sets available
  //       console.log("Assigned Orders:", assignedOrders);
  //       console.log("Completed Orders:", completedOrders);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const handleAssignedState = () => {
    setAssignedState(true);
    setReadyState(false);
    setOrderReady(false);
    setOutforDelivery(false);
    setCompletedState(false);
    setAssignedColorState(!assignedColorState);
    props.getShopassistantOrders(assistantId, "Order-Accepted");
  };

  const handleReadyState = () => {
    setAssignedState(false);
    setReadyState(true);
    setOrderReady(false);
    setOutforDelivery(false);
    setCompletedState(false);
    setAssignedColorState(!assignedColorState);
    props.getShopassistantOrders(assistantId, "Order-Ready");
  };

  const handleReadyOrder=()=>{
    setAssignedState(false);
    setReadyState(true);
    setOrderReady(false);
    setOutforDelivery(false);
    setCompletedState(false);
    props.getShopassistantOrders(assistantId, "Order-Ready");
  }

  const handleOutforDelivery=()=>{
    setAssignedState(false);
    setReadyState(false);
    setOrderReady(false);
    setOutforDelivery(true);
    setCompletedState(false);
    props.getShopassistantOrders(assistantId, "Out-For-Delivery");
  }

  const handleCompletedState = () => {
    setAssignedState(false);
    setReadyState(false);
    setOrderReady(false);
    setOutforDelivery(false);
    setCompletedState(true);
    setAssignedColorState(!assignedColorState);
    props.getShopassistantCompletedOrders(assistantId, "Completed");
  };


  const handleShopAssistantReadyOrder = (orderId) => {
    navigate("/home/MyOrders/ReadyForDeliveryDetails", { state: {
      orderid:orderId,
    shopAssistantOrder:true,
    shopAssistantId: location.state.shopAssistantId,
    fname: location.state.fname,
    lname: location.state.lname,
    phno: location.state.phno,
    profilePicUrl:location.state.profilePicUrl,} });   
}

const handleShopAssistantAssigned=(orderid)=>{
  navigate("/home/MyOrders/ActiveOrdersDetails",{state:{orderid:orderid,
    shopAssistantOrder:true,
    shopAssistantId: location.state.shopAssistantId,
    fname: location.state.fname,
    lname: location.state.lname,
    phno: location.state.phno,
    profilePicUrl:location.state.profilePicUrl,
  }})
}

const handleShopAssistantDelivery=(orderid)=>{
  navigate("/home/MyOrders/OutForDeliveryDetails",{state:{orderid:orderid,shopAssistantOrder:true,
    shopAssistantId: location.state.shopAssistantId,
    fname: location.state.fname,
    lname: location.state.lname,
    phno: location.state.phno,
    profilePicUrl:location.state.profilePicUrl,
  }})
}

const handleShopAssistantCompletedOrder=(orderid)=>{
  navigate('/home/MyOrders/OrderHistoryDetails',{state:{orderid:orderid,
    shopAssistantOrder:true,
    shopAssistantId: location.state.shopAssistantId,
    fname: location.state.fname,
    lname: location.state.lname,
    phno: location.state.phno,
    profilePicUrl:location.state.profilePicUrl,
  }})
}

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchessm = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));


  
  
    const assignedNumber =   props?.ShopassistantOrderslist?.assigned?.length; 
    const completedOrders = props?.ShopassistantCompletedOrderslist?.data?.shopAssistantCompletedOrdersList?.items;

  
 


  return (
    <>
      <div className="AssignmentPageheader" style={{ marginTop: "30px" }}>
        <div className="AssignmentFlex">
          <div
            style={{
              width: matches ? "80px" : "100px",
              height: matches ? "80px" : "100px",
              borderRadius: "50%",
              border: "3px solid #72B01D",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={location.state.profilePicUrl?location.state.profilePicUrl:dummy}
              alt="user profile image"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <div className="ProfileName">
          <div className="profileNameFlexAssignment">
            <Typography
              fontSize={HeaderFontSize_24}
              className="AssistantProfileStyle"
            >
              {" "}
              {location.state.fname}
            </Typography>
            <Typography
              fontSize={HeaderFontSize_24}
              className="AssistantProfileStyle"
            >
              {location.state.lname}
            </Typography>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "80px",
          display: "flex",
          justifyContent: "space-between",
          gap: matches ? (matchessm ? "4px" : "15px") : "20px",
        }}
      >
        <CustomButton
          width="100%"
          borderRadius="15px"
          background={assignedState ? "#659B1B" : "transparent"}
          variant={assignedState ? "contained" : "outlined"}
          color={assignedState ? "white" : "#646464"}
          borderColor="#659B1B"
          size={matches ? "small" : "large"}
          onClick={handleAssignedState}
          matchbtn={matchessm && true}
          matchPadding={smmatch && true}
          padding="3px 12px"
        >
          <Typography
            fontSize={contentFontsize_18_xs_11}
            className="AssistantProfileStyle"
          >
            Assigned
          </Typography>

          <CustomBadge
            // badgeContent={
            //   props?.ShopassistantOrderslist?.assigned?.length === 0
            //     ? "0"
            //     : props?.ShopassistantOrderslist?.assigned?.length
            // }
           // badgeContent="2"
            backgroundColor={assignedState ? "white" : "none"}
            color={assignedState ? "#659B1B" : "#646464"}
            borderColor={assignedState ? "none" : "#646464"}
            border="2px solid"
            fontSize={matches ? "8px" : "12px"}
            height={matches ? "15px" : "20px"}
            minWidth={matches ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matches ? (matchessm ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>
        <CustomButton
          width="100%"
          borderRadius="15px"
          background={readyState===true||outforDelivery===true ? "#659B1B" : "transparent"}
          color={readyState===true||outforDelivery===true ? "white" : "#646464"}
          variant={readyState===true||outforDelivery===true ? "contained" : "outlined"}
          borderColor="#659B1B"
          size={matches ? "small" : "large"}
          onClick={handleReadyState}
          matchbtn={matchessm && true}
          matchPadding={smmatch && true}
          padding="3px 12px"
        >
          <Typography
            fontSize={contentFontsize_18_xs_11}
            className="AssistantProfileStyle"
          >
            Ready
          </Typography>
          <CustomBadge
            // badgeContent={
            //   props?.ShopassistantOrderslist?.ready?.length === 0
            //     ? "0"
            //     : props?.ShopassistantOrderslist?.ready?.length
            // }
           // badgeContent="2"
            backgroundColor={readyState===true||outforDelivery===true ? "white" : "none"}
            color={readyState===true||outforDelivery===true ? "#659B1B" : "#646464"}
            borderColor={readyState===true||outforDelivery===true ? "none" : "#646464"}
            border="2px solid"
            fontSize={matches ? "8px" : "12px"}
            height={matches ? "15px" : "20px"}
            minWidth={matches ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matches ? (matchessm ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>
        <CustomButton
          width="100%"
          borderRadius="15px"
          background={completedState ? "#659B1B" : "transparent"}
          variant={completedState ? "contained" : "outlined"}
          color={completedState ? "white" : "#646464"}
          borderColor="#659B1B"
          size={matches ? "small" : "large"}
          onClick={handleCompletedState}
          matchbtn={matchessm && true}
          matchPadding={smmatch && true}
          padding="3px 12px"
        >
          <Typography
            fontSize={contentFontsize_18_xs_11}
            className="AssistantProfileStyle"
          >
            Completed
          </Typography>
          <CustomBadge
            // badgeContent={
            //   props?.ShopassistantCompletedOrderslist?.data
            //     ?.
            //     shopAssistantCompletedOrdersList
            //     ?.items?.length === 0
            //     ? "0"
            //     : props?.ShopassistantCompletedOrderslist?.data
            //         ?.
            //         shopAssistantCompletedOrdersList
            //         ?.items?.length
            // }
           //badgeContent="2"
            backgroundColor={completedState ? "white" : "none"}
            color={completedState ? "#659B1B" : "#646464"}
            borderColor={completedState ? "none" : "#646464"}
            border="2px solid"
            fontSize={matches ? "8px" : "12px"}
            height={matches ? "15px" : "20px"}
            minWidth={matches ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={matches ? (matchessm ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>
      </div>

      {/* //code for bottom cards */}
      <div style={{ marginTop: "30px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          {assignedState &&
          props.ShopassistantOrderslist &&
          props.ShopassistantOrderslist.assigned ? (
            props.ShopassistantOrderslist &&
            props.ShopassistantOrderslist.assigned &&
            props.ShopassistantOrderslist.assigned.length !== 0 ? (
              props.ShopassistantOrderslist.assigned.map((order, index) => (
                <div>
                  <CustomCard
                    padding="20px"
                    // boxShadow="none"
                    borderLeft="8px solid 
#1D89E1"
onClick={()=>handleShopAssistantAssigned(order.id)}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={4}>
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3} style={{paddingLeft:matches?"32px":"62px"}}>
                          <div className="assignedColumnFlex">
                            <Typography
                              fontSize={HeaderNavigationFontSize_20_xs_13}
                              className="AssistantFontStyle"
                            >
                              #{order.orderNumber}
                            </Typography>
                            <Typography
                              fontSize={contentFontsize_14_xs_10}
                              className="AssistantFontStyle1"
                            >
                              {order.deliveryDate}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={ShopAssistantClock}
                                alt="clock icon"
                                width={matcheslg && matches ? "10px" : "15px"}
                              />{" "}
                              <Typography
                                fontSize={contentFontsize_14_xs_10}
                                className="AssistantFontStyle1"
                              >
                                15 mins
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            <CustomButton
                              variant="contained"
                              background="
#1D89E1"
                            >
                              <Typography
                                fontSize={ButtonFontSize_16_xs_11}
                                className="AssistantFontStyleButton"
                              >
                                In Progress
                              </Typography>
                            </CustomButton>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            {" "}
                            <img
                              src={ClockAnimation}
                              alt="clockAnimation"
                              width={matches ? "60px" : "80px"}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment1">
                            <img
                              src={Arrow}
                              alt="arrow icon"
                              height={
                                upXlmatch
                                  ? "20px"
                                  : xlmatch
                                  ? lgmatch
                                    ? mdmatch
                                      ? smmatch
                                        ? xsmatch
                                          ? "12px"
                                          : "12px"
                                        : "14px"
                                      : "16px"
                                    : "18px"
                                  : "20px"
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                </div>
              ))
            ) : (
              assignedState && shopassistanOrderLoading ?<div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <CircularProgress color="success" /></div>:<div>No data found</div>
            )
          ) : (
            <div></div>
          )}
        </div>
        <>
        { (readyState === true || outforDelivery === true) && (
  <div style={{ display: "flex", gap: "30px",margin:"0px 0px 20px 0px" }}>
    <div onClick={handleReadyOrder}>
      <Typography style={{fontFamily:"Roboto",fontWeight:"700",color:readyState?"#000000":"#8B8989",borderBottom:readyState&&"3px solid #659B1B",cursor:"pointer"}} fontSize={HeaderNavigationFontSize_20_xs_13}>
      Ready for Delivery
      </Typography>
     
      </div>
    <div onClick={handleOutforDelivery}>
      <Typography style={{fontFamily:"Roboto",fontWeight:"700",color:outforDelivery?"#000000":"#8B8989",borderBottom:outforDelivery&&"3px solid #659B1B",cursor:"pointer"}} fontSize={HeaderNavigationFontSize_20_xs_13}>
      Out For delivery
      </Typography>
     
      </div>
  </div>
)}

       
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          {readyState &&
          props.ShopassistantOrderslist &&
          props.ShopassistantOrderslist.ready ? (
            props.ShopassistantOrderslist &&
            props.ShopassistantOrderslist.ready &&
            props.ShopassistantOrderslist.ready.length !== 0 ? (
              props.ShopassistantOrderslist.ready.map((order, index) => (
                <div>
                  <CustomCard
                    padding="20px"
                   // boxShadow="none"
                    borderLeft="8px solid 
#FF9900"
onClick={() => handleShopAssistantReadyOrder(order.id)}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={4}  >
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3} style={{paddingLeft:matches?"32px":"62px"}} >
                          <div className="assignedColumnFlex">
                            <Typography
                              fontSize={HeaderNavigationFontSize_20_xs_13}
                              className="AssistantFontStyle"
                            >
                              #{order.orderNumber}
                            </Typography>
                            <Typography
                              fontSize={contentFontsize_14_xs_10}
                              className="AssistantFontStyle1"
                            >
                              {order.deliveryDate}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={ShopAssistantClock}
                                alt="clock icon"
                                width={matcheslg && matches ? "10px" : "15px"}
                              />{" "}
                              <Typography
                                fontSize={contentFontsize_14_xs_10}
                                className="AssistantFontStyle1"
                              >
                                15 mins
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            <CustomButton
                              variant="contained"
                              background="
#FF9900"
                            >
                              <Typography
                                fontSize={ButtonFontSize_16_xs_11}
                                className="AssistantFontStyle1"
                              >
                                Ready for Delivery
                              </Typography>
                            </CustomButton>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            {" "}
                            <img
                              src={ClockAnimation}
                              alt="clockAnimation"
                              width={matches ? "60px" : "80px"}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment1">
                            <img
                              src={Arrow}
                              alt="arrow icon"
                              height={
                                upXlmatch
                                  ? "20px"
                                  : xlmatch
                                  ? lgmatch
                                    ? mdmatch
                                      ? smmatch
                                        ? xsmatch
                                          ? "12px"
                                          : "12px"
                                        : "14px"
                                      : "16px"
                                    : "18px"
                                  : "20px"
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                </div>
              ))
            ) : (
              readyState && shopassistanOrderLoading ?<div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <CircularProgress color="success" /></div>:<div>No data found</div>
            )
          ) : (
            <div></div>
          )}
        </div>



        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          {outforDelivery &&
          props.ShopassistantOrderslist &&
          props.ShopassistantOrderslist.outforDelivery ? (
            props.ShopassistantOrderslist &&
            props.ShopassistantOrderslist.outforDelivery &&
            props.ShopassistantOrderslist.outforDelivery.length !== 0 ? (
              props.ShopassistantOrderslist.outforDelivery.map((order, index) => (
                <div>
      
                  <CustomCard
                    padding="20px"
                   // boxShadow="none"
                    borderLeft="8px solid 
                    #85BE49"
                    onClick={()=>handleShopAssistantDelivery(order.id)}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={4}>
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3} style={{paddingLeft:matches?"32px":"62px"}}>
                          <div className="assignedColumnFlex">
                            <Typography
                              fontSize={HeaderNavigationFontSize_20_xs_13}
                              className="AssistantFontStyle"
                            >
                              #{order.orderNumber}
                            </Typography>
                            <Typography
                              fontSize={contentFontsize_14_xs_10}
                              className="AssistantFontStyle1"
                            >
                              {order.deliveryDate}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={ShopAssistantClock}
                                alt="clock icon"
                                width={matcheslg && matches ? "10px" : "15px"}
                              />{" "}
                              <Typography
                                fontSize={contentFontsize_14_xs_10}
                                className="AssistantFontStyle1"
                              >
                                15 mins
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            <CustomButton
                              variant="contained"
                              background="
                              #85BE49"
                            >
                              <Typography
                                fontSize={ButtonFontSize_16_xs_11}
                                className="AssistantFontStyle1"
                              >
                                Ready for Delivery
                              </Typography>
                            </CustomButton>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            {" "}
                            <img
                              src={ClockAnimation}
                              alt="clockAnimation"
                              width={matches ? "60px" : "80px"}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment1">
                            <img
                              src={Arrow}
                              alt="arrow icon"
                              height={
                                upXlmatch
                                  ? "20px"
                                  : xlmatch
                                  ? lgmatch
                                    ? mdmatch
                                      ? smmatch
                                        ? xsmatch
                                          ? "12px"
                                          : "12px"
                                        : "14px"
                                      : "16px"
                                    : "18px"
                                  : "20px"
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                </div>
              ))
            ) : (
              outforDelivery && shopassistanOrderLoading ?<div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <CircularProgress color="success" /></div>:<div>No data found</div>
            )
          ) : (
            <div></div>
          )}
        </div>




        
        </>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          {completedState &&
          completedOrders?.length !== 0
            ? completedOrders?.map(
                (order, index) => (
                  <CustomCard
                    padding="20px"
                   // boxShadow="none"
                    borderLeft="8px solid 
#85BE49"
onClick={()=>handleShopAssistantCompletedOrder(order.id)}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={4}>
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3} style={{paddingLeft:matches?"32px":"62px"}}>
                          <div className="assignedColumnFlex">
                            <Typography
                              fontSize={HeaderNavigationFontSize_20_xs_13}
                              className="AssistantFontStyle"
                            >
                              #{order.orderNumber}
                            </Typography>
                            <Typography
                              fontSize={contentFontsize_14_xs_10}
                              className="AssistantFontStyle1"
                            >
                              {order.deliveryDate}
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={ShopAssistantClock}
                                alt="clock icon"
                                width={matcheslg && matches ? "10px" : "15px"}
                              />{" "}
                              <Typography
                                fontSize={contentFontsize_14_xs_10}
                                className="AssistantFontStyle1"
                              >
                                15 mins
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            <CustomButton
                              variant="contained"
                              background="
#85BE49"
                            >
                              <Typography
                                fontSize={ButtonFontSize_16_xs_11}
                                className="AssistantFontStyle1"
                              >
                                Out for Delivery
                              </Typography>
                            </CustomButton>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment">
                            {" "}
                            <img
                              src={ClockAnimation}
                              alt="clockAnimation"
                              width={matches ? "60px" : "80px"}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} lg={3} xl={3}>
                          <div className="inProgressAssignment1">
                            <img
                              src={Arrow}
                              alt="arrow icon"
                              height={
                                upXlmatch
                                  ? "20px"
                                  : xlmatch
                                  ? lgmatch
                                    ? mdmatch
                                      ? smmatch
                                        ? xsmatch
                                          ? "12px"
                                          : "12px"
                                        : "14px"
                                      : "16px"
                                    : "18px"
                                  : "20px"
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                )
              )
            : completedState && (shopassistantCompletedOrderLoading ?<div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <CircularProgress color="success" /></div>: <div>No data found</div>)}
        </div>
      </div>
    </>
  );
}
