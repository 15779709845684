// import React, { Component } from 'react';
// import Container from '@mui/material/Container';
// import {
//   LOGO,
//   fb2,
//   // twitter,
//   Insta,
//   Linkedin
// } from '../../utils/image'
// import { Row, Col } from 'react-bootstrap';
// import Divider from "@mui/material/Divider"
// import Box from "@mui/material/Box";
// import './index.css';

// export class index extends Component {
//   constructor(props) {
//     super(props);
//     this.myRef = React.createRef() 
//     this.state = {

//     }
//   }
//   componentDidMount() {
//     this.myRef.current.scrollTo(0, 0);
//   }
//   render() {
//     const scrollToTop = () =>{
//       // React.useEffect(()=>{
//         document.body.scrollTop = 0;
//       // },
//       // [])
//     };
//     const privacyClick = () => {
//       this.props.history.push('/privacy-policy');
//     }
//     const termsClick = () => {
//       this.props.history.push('/termsofuse');
//     }
//     return (
//       <div className="footer-div" ref={this.myRef}>
//         <div className="footer_content">
//           <Container maxWidth="md" >
//             <Row >
//               <Col md={4} xs={12} sm={12} className="footer-col" >
//                 <img src={LOGO} width='130px' height='40px'
//                   style={{ maxWidth: "100%", maxHeight: '100%', }} alt="NearShoz.png" />
//               </Col>
//               <Col md={6} xs={12} className="footer-col" >
//                 <Row style={{ paddingBottom: '10px' }}>
//                   <Col md={4} xs={12}>
//                     <button className="footer-buttons"
//                     onClick={scrollToTop}
//                     >Home</button>
//                   </Col>
//                   {/* <Col md={4} xs={12}>
//                  <button className="footer-buttons">Quick Links</button>
//                   </Col> */}
//                   {/* <Col md={4} xs={12}>
//                     <button className="footer-buttons">FAQ</button>
//                   </Col> */}
//                 </Row>
//                 <Row style={{ paddingBottom: '10px' }}>
//                   <Col md={4} xs={12}>
//                     <button className="footer-buttons" onClick={termsClick}>Terms of use</button>
//                   </Col>
//                   <Col md={4} xs={12}>
//                     <button className="footer-buttons" onClick={privacyClick}>Privacy policy</button>
//                   </Col>
//                   {/* <Col md={4} xs={12}>
//                  <button className="footer-buttons"> Terms & Conditions</button>
//                   </Col> */}
//                 </Row>
//               </Col>
//               <Col md={2} xs={12}sm={12}  className="footer-col">
//                 <span style={{ paddingRight: "5px",cursor:'pointer' }}>
//                   <a href="https://www.facebook.com/4Labs-Technologies-108323803917676" target="blank">
//                   <img src={fb2} width="20px" height="20px" alt="fb.png"></img>
//                   </a>
//                 </span>
//                 {/* <span style={{paddingRight:"5px"}}>
//                   <img src={twitter} width="25px" height="25px" alt="twitter.png"></img>
//                   </span> */}
//                 <span style={{ paddingRight: "5px" ,cursor:'pointer'}}>
//                 <a href="https://www.instagram.com/accounts/login/?next=/nearshopz_india/" target="blank">
//                   <img src={Insta} width="20px" height="20px" alt="insta.png"></img>
//                   </a>
//                 </span>
//                 <span style={{ paddingRight: "5px" ,cursor:'pointer'}}>
//                 <a href="https://www.linkedin.com/company/4labstechnologies" target="blank">
//                   <img src={Linkedin} width="20px" height="20px" alt="Linkedin.png"></img>
//                   </a>
//                 </span>
//               </Col>
//             </Row>
//             <Divider />
//             <Row className="footer_Row">
//               <Col sm={12} md={12} xs={12}>
//                 <Box
//                   textAlign={"center"}
//                   mt={{ xs: 2, md: 0 }}
//                   my={2}
//                   style={{ color: "#83919e", fontSize: "12px", paddingTop: "10px" }}
//                 >
//                   © Copyright 2022 NearShopz - All Rights Reserved.
//                 </Box>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </div>
//     )
//   }
// }

// export default index
import React, { useState } from "react";
import { TextField, Typography, InputAdornment } from "../MUIComponents/index";
import indiaImage from "../../utils/images/indiaImage.svg";
import CustomButton from "../Commoncomponents/button";
import Facebook from "../../utils/Icon/facebook.svg";
import Instagram from "../../utils/Icon/Instagram.svg";
import Youtube from "../../utils/Icon/Youtube.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const nav = useNavigate();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [subject,setSubject]= useState("")
  const [buttonColor, setButtonColor] = useState("green");

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleMobileChange = (e) => setPhoneNumber(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const values = { email, phoneNumber ,firstName,lastName,message,subject };

    try {
      const response = await fetch('https://testserver.nearshopz.com/api/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        setEmail('');
        setPhoneNumber('');
        setButtonColor('grey');
        setTimeout(() => {
          setButtonColor('green');
        }, 1000);
      }
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  const handleFbClick = () => {
    window.location.href = "https://www.facebook.com/NearShopz";
  };
  const handleYoutbClick = () => {
    window.location.href = "https://www.youtube.com/@nearshopz1078?app=desktop";
  };
  const handleInstaClick = () => {
    window.location.href = "https://www.instagram.com/nearshopz_india/";
  };

  const handleAboutClick = () => {
    const secondPage = document.getElementById("aboutus");
    if (secondPage) {
      secondPage.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleBlogClick = () => {
    nav("/Blogs");
  };
  const handleTermsClick = () => {
    nav("/Terms&Conditions");
  };
  const handleContactClick = () => {
    nav("/Contact");
  };
  const handlePrivacyClick = () => {
    nav("/Privacy&Policy");
  };

  return (
    <div className="footer-container">
      <div className="input-section">
        <div style={{ display: "flex", flexDirection: "column" ,gap:"10px"}}>
          <TextField
            size={"small"}
            variant="outlined"
            placeholder="Enter your Email Address"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              className: "custom-placeholder",
              style: { backgroundColor: "white" },
            }}

          />
          <TextField
            size={"small"}
            variant="outlined"
            value={phoneNumber}
            onChange={handleMobileChange}
            placeholder="Enter your mobile number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={indiaImage} alt="India flag" style={{ marginRight: "8px" }} />
                  <Typography style={{ color: "black", fontSize: "14px", marginTop: "3px" }}>+91 |</Typography>
                </InputAdornment>
              ),
              style: { backgroundColor: "white" },
            }}
            fullWidth
          />
        </div>
        <div style={{marginTop:"10px"}}>
        <CustomButton
          borderColor={"white"}
          color={"white"}
          background={buttonColor}
          borderRadius={"10px"}
          width={"100%"}
          height={"40px"}
          onClick={handleSubmit}
          style={{ marginTop: "10px" }}
        >
          Connect
        </CustomButton></div>
        <Typography style={{ fontSize: "12px", marginTop: "10px" }}>
          This site is intended for US consumers. By signing up, you understand and agree that your data will be collected and used subject to our US policy and terms of use.
        </Typography>
      </div>
    
      

      <div className="menu-links" style={{gap:"10px",display:"flex",flexDirection:"column"}}>
        <div className="menu-row" style={{display:"flex",justifyContent:"space-evenly"}}>
          <Typography className="Menu" onClick={handleAboutClick}>About</Typography>
          <Typography className="Menu" onClick={handleTermsClick}>Terms & Conditions</Typography>
          <Typography className="Menu" onClick={handleBlogClick}>Blog</Typography>
        </div>
        <div className="menu-row" style={{display:"flex",marginLeft:"78px",gap:"10px"}}>
          <Typography className="Menu" onClick={handlePrivacyClick}>Privacy & Policy</Typography>
          <Typography className="Menu" onClick={handleContactClick}>Contact</Typography>
        </div>
      </div>
      <div className="social-icons" style={{marginTop:"20px", display:"flex",gap:"20px",justifyContent:"center"}}>
        <img src={Facebook} onClick={handleFbClick} alt="Facebook" />
        <img src={Instagram} onClick={handleInstaClick} alt="Instagram" />
        <img src={Youtube} onClick={handleYoutbClick} alt="Youtube" />
      </div>
      <div className="footer-line"></div>
      
      <div className="footer-copyright">
        <Typography style={{ fontSize: "12px" }}>
          © Copyright 2024 NearShopz - All Rights Reserved.
        </Typography>
      </div>
    </div>
  );
};

export default Footer;

