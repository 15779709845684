import * as yup from "yup";

export const storevalidationSchema = yup.object({
    storeName: yup
    .string()
    // .min(4, "Store Name must be at least 4 characters")
    // .matches(/^[A-Za-z][A-Za-z\s]*[A-Za-z]$/, {
    //   message: "Only alphabets are allowed with spaces in between",
    //   excludeEmptyString: true
    // })
    .required("Store Name is required"),
  

  
    storeDescription: yup
      .string()
      .min(3,"Description should be atleast 3 characters long")
      .max(100, "Length exceeded")
      .required("Store description is required"),
      businessType: yup
      .array()
      .required("Business Type is required"),
})