// import React from "react";
// import {
//   TextField,
//   MenuItem,
// } from "@mui/material";
// import { ThemeProvider ,createTheme} from '@mui/material/styles';
// import { green } from "@mui/material/colors";
// import { Grid } from "@mui/material";

// function DeliveryDayAfter(props) {
//   const { label, data, ...rest } = props;
  
 

//   const  theme = createTheme({
// 		palette: {
// 		  primary: green,
// 		},
// 		overrides: {
//       MuiInputBase: {
//       input: {
//         '&:-webkit-autofill': {
//         transitionDelay: '9999s',
//         transitionProperty: 'background-color, color',
//         },
//       },
//       },
// 		},
// 	})

//     const [DeliveryDay,setDeliveryDays]=React.useState(props.DeliveryAfter?props.DeliveryAfter:props.retailerDetails&&props.retailerDetails.data&&props.retailerDetails.data.retailerdetails.stores[0]
//         &&props.retailerDetails.data.retailerdetails.stores[0].deliveryAfter)
// const handleChange = (e) => {
//     setDeliveryDays(e.target.value)
//     props.setDeliveryDays(e.target.value)
// }

//   return (
//     props.DeliveryAfter===0?
// null:
// <Grid item xs={12}>
//     <ThemeProvider theme={theme}>
//       <TextField
//       fullWidth
//        key={`${Math.floor(Math.random()*(9999-1000+1)+1000)}`}
//       style={{marginBottom:'30px'}}
//       InputProps={{style:{width:'100%'}}}
//       label={'Deliver after'}
//         select
//         variant="outlined"
//         InputLabelProps={{
//           shrink: true,
//         }}
//         onChange={(e)=>handleChange(e)}
//         SelectProps={{ style: { width: 285,height:35} }}
//         value={DeliveryDay}
//       >
//         {data.map((item, index) => (
//           <MenuItem key={index}
//           style={{width:'fit-content',cursor:'pointer'}}
//            value={item.value}>
//             {item.label}
//           </MenuItem>
//         ))}
//       </TextField>
//       </ThemeProvider>
//       </Grid>
//   );
// }

// export default DeliveryDayAfter;


import React, { useState } from 'react'
import {MenuItem,Select,useTheme,useMediaQuery,Box,FormControl,Typography} from '../MUIComponents/index';
import {HeaderNavigationFontSize_20} from '../Typography/index';
import '../Profile/ManageSlots.css'

function DeliveryDayAfter(props) {
  const { label, data, ...rest } = props;
  const [DeliveryDay,setDeliveryDays]=useState(props.DeliveryAfter?props.DeliveryAfter:props.retailerDetails&&props.retailerDetails.data&&props.retailerDetails.data.retailerdetails.stores[0]
    &&props.retailerDetails.data.retailerdetails.stores[0].deliveryAfter)
const handleChange = (e) => {
setDeliveryDays(e.target.value)
props.setDeliveryDays(e.target.value)
}
const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
    <Box 
     style={{
      width: "100%",
      background: "white",
      borderRadius: "10px",
      border: "1px solid #85BE49"}}>
<FormControl fullWidth>
      <Select
 key={`${Math.floor(Math.random()*(9999-1000+1)+1000)}`}
		value={DeliveryDay}
                    size={matches ? "small" : "normal"}
                    background="white"
                    inputProps={{
                      sx: {
                        fontSize: matches && '13px'},
                        }}
                    // match={matches ? true : false}
 onChange={(e)=>handleChange(e)}
>
{data.map((item, index) => (
          <MenuItem key={index}
          style={{width:'fit-content',cursor:'pointer'}}
           value={item.value}>
             <Typography
                          className="kmStyle"
                         
                        >
                               {item.label}
                        </Typography>
         
          </MenuItem>
        ))}
                                     

</Select>
</FormControl>
</Box>

    </>
  )
}

export default DeliveryDayAfter