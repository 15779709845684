import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  InputAdornment,
  DialogContent,
  useTheme,
  useMediaQuery,
  MenuItem,
  DialogTitle,
  Select,
} from "../MUIComponents/index";
import CustomToggleButton from "../Commoncomponents/toggleButton";
import { ReactComponent as Filter } from "../../utils/Icon/filtericon.svg";
import CustomTextField from "../Commoncomponents/input";
import SearchIcon from "../../utils/Icon/search.svg";
import CustomCard from "../Commoncomponents/card";
import { NoImage } from "../../utils/image";
import "./Categorynext.css";
import {
  contentFontsize_24,
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
  contentFontsize_14,
} from "../Typography/index";
import CustomDialog from "../Commoncomponents/dialog";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Commoncomponents/button";
import { message } from "antd";
import ProductToggle from "../UpdateProduct/ProductToggle";

function Categorynext(props) {
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterProduct, setFilterProduct] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState(true);
  const location = useLocation();
  const { departmentId, categoryId, categoryName, subCatName } =
    location.state || {};

  useEffect(() => {
    props.ProductSearch({
      from: 0,
      first: 20,
      department: departmentId,
      storeid: localStorage.getItem("storeid"),
      category: categoryId,
      category1: null,
      category2: null,
      status: null,
      key: "",
    });
  }, []);
  const [inactiveProducts, setInactiveProducts] = useState(false);
  const [activeProducts, setActiveProducts] = useState(false);
  useEffect(() => {
    setInactiveProducts(false)
    setActiveProducts(false)
    if (props.searchProductData) {
      const newProducts =
        props.searchProductData &&
        props.searchProductData.data &&
        props.searchProductData.data.searchProducts &&
        props.searchProductData.data.searchProducts.items
          ? props.searchProductData.data.searchProducts.items
          : [];
      setProducts(newProducts);
    }
  }, [props.searchProductData]);

 
  useEffect(() => {
    setInactiveProducts(false);
    setActiveProducts(false);
  }, []);
  useEffect(() => {
    if (
      props.searchProductData &&
      props.searchProductData.data &&
      props.searchProductData.data.searchProducts &&
      props.searchProductData.data.searchProducts.items
    ) {
      const newStatusOnline =
        props.searchProductData.data.searchProducts.items.filter(
          (product) => product.status === "inactive"
        );

     
      if (
        newStatusOnline.length ===
        props.searchProductData.data.searchProducts.items.length
      ) {
        setInactiveProducts(true);
      } else {
        setActiveProducts(true);
      }
    }
  }, [props.searchProductData]);

  const [initialProducts, setInitialProducts] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialProducts(activeProducts ? true : false);
    }, 500);

    return () => clearTimeout(timer);
  }, [activeProducts, inactiveProducts]);

  const [state, setState] = useState({
    department: location.state.departmentId,
    storeid: localStorage.getItem("storeid"),
    category: location.state.categoryId,
    key: "",
    first: 20,
    page: null,
    products: [],
    status: null,
  });
  const [wordEntered, setWordEntered] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
  const [key, setKey] = useState("");
  const [from, setFrom] = useState(0);
  const [first, setFirst] = useState(50);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const handleFilter = (event) => {
    let { value } = event.target;
    setState({
      ...state,
      [event.target.name]: value,
    });

    const searchWord = event.target.value;
    setWordEntered(searchWord);
    if (searchWord.length >= 3) {
      props.setCriteria(state);
      props.ProductSearch(state);
    }

    if (searchWord === "") {
      setFilteredData([]);
      props.ProductSearch({
        department: location.state.departmentId,
        storeid: localStorage.getItem("storeid"),
        category: location.state.categoryId,
        key: "",
        first: 20,
        page: null,
        products: [],
        status: null,
      });
    } else {
      setFilteredData(filteredData);
    }
  };

  const handleFilterClick = () => {
    setFilter(true);
  };

  const searchProductHandle = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      from: 0,
      products: [],
    }));
    props.setCriteria(state);
    props.ProductSearch(state);
    const newProduct =
      props.searchProductData &&
      props.searchProductData.data &&
      props.searchProductData.data.searchProducts &&
      props.searchProductData.data.searchProducts.items;

    if (newProduct) {
      props.setProducts(newProduct);
    }

    setFilterProduct(newProduct);
    setFilter(false);
  };


  const handleClear = () => {
    props.ProductSearch({
      from: 0,
      first: 20,
      department: departmentId,
      storeid: localStorage.getItem("storeid"),
      category: categoryId,
      category1: null,
      category2: null,
      status: null,
      key: "",
    });
    setFilter(false);
  };

  const customStyles = {
    toggleBtnCirStyle: {
      display: "inline-block",
      border: "1px solid transparent",
      borderRadius: "20px",
      width: "110px",
      height: "39px",
      padding: "5px",
      cursor: "pointer",
      boxSizing: "border-box",
    },
    boxStyle: {
      display: "flex",
      justifyContent: "space-around",
    },
    disOfflineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    offlineCirStyle: {
      display: "block",
      height: "26.25px",
      width: "26.25px",
      borderRadius: "50%",
      backgroundColor: "gray",
      marginRight: "15px",
    },
    disOnlineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    onlineCirStyle: {
      display: "block",
      height: "26.25px",
      width: "26.25px",
      borderRadius: "50%",
      backgroundColor: "#72B01D",
      marginLeft: "15px",
    },
    active: {
      display: "none",
    },
  };
  const customStylespdt = {
    toggleBtnCirStyle: {
      display: "inline-block",
      border: "1px solid black",
      borderRadius: "15px",
      width: "50px",
      height: "20px",
      cursor: "pointer",
      boxSizing: "content-box",
      padding: "3px",
    },
    disOfflineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    offlineCirStyle: {
      display: "block",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      backgroundColor: "gray",
      marginRight: "10px",
    },
    disOnlineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    onlineCirStyle: {
      display: "block",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      backgroundColor: "#72B01D",
      marginLeft: "10px",
    },
    active: {
      display: "none",
    },
  };

  const handleSearchField = (e) => {
    let { value } = e.target;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const nav = useNavigate();
  function handleClose() {
    setFilter(false);
  }

  const handleToggle = (products, online) => {
    const newStatus = online ? "inactive" : "active";
    const updatedProducts = products.map((product) => ({
      ...product,
      status: newStatus,
    }));
    setProducts(updatedProducts);
    props.Editallproductstatus(
      updatedProducts.map((p) => p.id),
      newStatus
    );
    props.ProductSearch({
      from: 0,
      first: 20,
      department: departmentId,
      storeid: localStorage.getItem("storeid"),
      category: categoryId,
      key: "",
    });
    if (props.searchProductData) {
      const newProducts =
        props.searchProductData &&
        props.searchProductData.data &&
        props.searchProductData.data.searchProducts &&
        props.searchProductData.data.searchProducts.items
          ? props.searchProductData.data.searchProducts.items
          : [];
      setProducts(newProducts);
    }
    if(props.Editallproductstatus){
      nav("/home/ManageHierarchy");
        message.success({
          content: "Product Status Updated",
          className: "custom-class",
          style: {
            marginTop: "20vh",
          },
        });
        setTimeout(() => {
        }, 3000);
      }
  };
  useEffect(()=>{
       props.ProductSearch({
      from: 0,
      first: 20,
      department: departmentId,
      storeid: localStorage.getItem("storeid"),
      category: categoryId,
      key: "",
    });
    if (props.searchProductData) {
      const newProducts =
        props.searchProductData &&
        props.searchProductData.data &&
        props.searchProductData.data.searchProducts &&
        props.searchProductData.data.searchProducts.items
          ? props.searchProductData.data.searchProducts.items
          : [];
      setProducts(newProducts);
    }
  },[props.allProductStatus])

  function handleCategory() {
    nav("/home/ManageHierarchy");
  }
  return (
    <>
      <Grid container>
        <Grid item xs={10} sm={10} md={11} lg={10} xl={10}>
          <div
            style={{
              display: "flex",
              gap: "3px",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ cursor: "pointer" }} onClick={handleCategory}>
              <Typography className="Main">
                {location.state.categoryName}
              </Typography>
            </div>
            <div>
              <Typography className="Main">/</Typography>
            </div>
            <div style={{ cursor: "pointer" }}>
              <Typography className="Main" style={{ color: "#85BE49" }}>
                {location.state.subCatName}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={2} xl={2}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <>
              {initialProducts !== null && (
                <CustomToggleButton
                  initialOnline={initialProducts}
                  onClick={() => handleToggle(products, initialProducts)}
                  textOne="Online"
                  textTwo="Offline"
                  textFont="19px"
                  {...customStyles}
                />
              )}
            </>
          </div>
        </Grid>
      </Grid>
      <div className="add-txt">
        <CustomTextField
          name="key"
          placeholder="search product"
          type="text"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={state.key}
          onChange={(event) => {
            handleFilter(event);
          }}
          width={"90%"}
          borderRadius="50%"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <img
                src={SearchIcon}
                alt="Search"
                style={{ marginRight: "8px" }}
              />
            </InputAdornment>
          }
        />
        <div className="filter1">
          <Filter onClick={handleFilterClick} style={{cursor:"pointer"}}/>
          <Typography
            className="filter-text"
            fontSize={contentFontSize_16}
          >
            Filters
          </Typography>
        </div>
      </div>
      <div>
        {products.length === 0 ? (
          <div style={{ textAlign: "center" }}>
            <Typography
              className="Businesstype"
              fontSize={HeaderNavigationFontSize_20}
            >
              No Products To Display
            </Typography>
          </div>
        ) : (
          products.map((source, index) => {
            const price = parseFloat(source.price);
            const promoPrice = parseFloat(source.promoprice);
            const discount = price - promoPrice;
            const discountPercentage = ((discount / price) * 100).toFixed(2);
            const formattedPrice = price.toFixed(2);
            const isInactive = source.status === "inactive";
            const color = isInactive ? "#6D6D6D" : "black";

            const handleStatusChange = (productId, newStatus) => {
              const updatedProducts = products.map(product =>
                product.id === productId ? { ...product, status: newStatus } : product
              );
              setProducts(updatedProducts); 
            };

            return(
            <div className="card-pdt" key={source.id}>
              <CustomCard padding={"20px"} width="98%"  background={isInactive ? '#F2F2F2' : 'white'}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ paddingLeft: "0px", paddingTop: "0px" }}
                  >
                    <Grid item xs={4} sm={2} md={2} lg={1} xl={1}>
                      <CustomCard
                        padding="2px"
                        width={"70px"}
                        height={"70px"}
                        // background="#F2F2F2"
                        boxShadow="none"
                        borderRadius="10px"
                        justifyContent="flex-end"
                        background={isInactive ? '#F2F2F2' : 'white'} 
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "left",
                            alignItems: "center",
                            padding: "4px",
                          }}
                        >
                          <img
                          className={isInactive ? 'added-image' : ''}
                            src={
                              (source?.image &&
                                source?.image.primary === null) ||
                              (source?.image &&
                                source?.image?.primary === "null")
                                ? NoImage
                                : source?.image?.primary
                            }
                            alt="milk"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                              objectFit: "cover",
                              marginBottom: "10px",
                              // padding: "8px",
                            }}
                          />
                        </div>
                      </CustomCard>
                    </Grid>
                    <Grid item xs={7} sm={3} md={3} lg={4} xl={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div style={{ display: "flex", gap: "15px" }}>
                          <div className="price-pdt">{source.productname}</div>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={0}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={2}
                      style={{ paddingLeft: matches ? "16px" : "36px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flexStart",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "15px",
                            }}
                          >
                            <Typography
                              className="price-pdt"
                              fontSize={contentFontSize_16}
                              color={color}
                            >
                              ₹ {formattedPrice}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={0} sm={3} md={3} lg={3} xl={3}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            paddingTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "15px",
                            }}
                          >
                            <div
                              style={{
                                display: source.promoprice ? "block" : "none",
                              }}
                            >
                              <CustomCard
                                padding="0"
                                borderRadius="3px"
                                height="70%"
                                alignItems="center"
                                width="100%"
                                background="#EA4335"
                                boxShadow="none"
                              >
                                <Typography fontSize={contentFontsize_14}>
                                  <p className="off-100">
                                  {discountPercentage} % OFF
                                  </p>
                                </Typography>
                              </CustomCard>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={0} sm={2} md={2} lg={2} xl={2}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div style={{ display: "flex", gap: "15px" }}>
                          <div>
                          <ProductToggle id={source.id} status={source.status === 'inactive' ? false : true} {...props} onStatusChange={handleStatusChange}/>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </CustomCard>
            </div>
          )})
        )}
      </div>
      <>
        {filter && (
          <>
            <CustomDialog
              width="90%"
              maxHeight="70%"
              borderRadius="20px"
              open={filter}
              onClose={handleClose}
              titleAlign="center"
              padding="0px"
              showCloseButton={false}
            >
              <DialogTitle>
                <Typography
                  fontSize={contentFontsize_24}
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    display: "flex",
                    justifyContent: "center",
                    color: "black",
                  }}
                >
                  Filters
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{ paddingTop: 0 }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "8px",
                          color: "black",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography>Status</Typography>
                      </div>
                      <div
                        style={{
                          color: "#85BE49",
                          textDecoration: "underline",
                        }}
                        onClick={handleClear}
                      >
                        <Typography>Reset</Typography>
                      </div>
                    </div>

                    <Select
                      name="status"
                      width="100%"
                      match={matches ? true : false}
                      size={matches ? "small" : "normal"}
                      buttonColor="success"
                      sx={{
                        width: "100%",
                        variant: "outlined",
                        background: "white",
                        borderRadius: "10px",
                      }}
                      onChange={(e) => {
                        handleSearchField(e);
                      }}
                    >
                      <MenuItem value={"active"} style={{ cursor: "pointer" }}>
                        {"active"}
                      </MenuItem>
                      <MenuItem
                        value={"inactive"}
                        style={{ cursor: "pointer" }}
                      >
                        {"inactive"}
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
                    <CustomButton
                      width="230px"
                      height="40px"
                      borderRadius="20px"
                      color={"black"}
                      variant="contained"
                      background={"white"}
                      borderColor="#659B1B"
                      onClick={handleClose}
                    >
                      cancel
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
                    <CustomButton
                      width="230px"
                      height="40px"
                      borderRadius="20px"
                      color={"white"}
                      variant="contained"
                      background={"rgb(101, 155, 27)"}
                      onClick={(e) => {
                        searchProductHandle(e);
                      }}
                    >
                      Apply
                    </CustomButton>
                  </Grid>
                </Grid>
              </DialogContent>
            </CustomDialog>
          </>
        )}
      </>
    </>
  );
}

export default Categorynext;
