export const viewTable = () => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_VIEW_TABLE",
		});
	};
};
export const setMaxDistance = (val) => {
	return (dispatch) => {
		dispatch({
			type: "SET_MAX_DISTANCE",
			payload: parseInt(val, 10),
		});
	};
};
export const setScheduleChange = (key, index) => {
	return (dispatch) => {
		dispatch({
			type: "CHANGE_SCHEDULE",
			payload: { key, index },
		});
	};
};
