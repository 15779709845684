import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState, useEffect } from 'react';
import CustomToggleButton from '../Commoncomponents/toggleButton';

const ProductToggle = (props) => {
  const customStyles = {
    toggleBtnCirStyle: {
      display: "inline-block",
      border: "1px solid black",
      borderRadius: "15px",
      width: "50px",
      height: "20px",
      cursor: "pointer",
      boxSizing: "content-box",
      padding: "3px",
    },
    disOfflineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    offlineCirStyle: {
      display: "block",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      backgroundColor: "gray",
      marginRight: "10px",
    },
    disOnlineStyle: {
      display: "flex",
      justifyContent: "space-between",
    },
    onlineCirStyle: {
      display: "block",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      backgroundColor: "#72B01D",
      marginLeft: "10px",
    },
    active: {
      display: "none",
    },
  };


  const [online, setOnline] = useState(props.status);

  
 

  const handleStatusChange = () => {
    const newStatus = online ? 'inactive' : 'active';
    setOnline(!online);
    props.Editproductstatus(props.id, newStatus);


    setTimeout(()=>{ 
      if (props.ProductStatusUpdateSuccess) {
      const updatedProducts = props.Products.newProducts.params.map((product) =>
        product.map((prod) => (prod.id === props.id ? { ...prod, status: newStatus } : prod))
      );
      props.setProducts(updatedProducts);
    }
  },1000)
   
    props.onStatusChange(props.id, newStatus);
  };

  return (
    <FormControlLabel
      control={
        <CustomToggleButton initialOnline={online} onClick={handleStatusChange} {...customStyles} />
      }
      label=""
    />
  );
};

export default ProductToggle;
