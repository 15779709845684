import { createSelector } from "reselect";

const getSignUpReducer = (state) => state.SignUpReducer;

const mergeSelectedReducers = (SignUpReducer) => ({ ...SignUpReducer });

export const SelectState = createSelector(
	[getSignUpReducer],
	mergeSelectedReducers
);
