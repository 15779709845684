import React, { useState } from "react";
// import ReactDOM from 'react-dom';
import ImgCrop from "antd-img-crop";
import { Upload, Modal } from "antd";
import {Row,Col} from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import "antd/dist/antd.css";
import "./index.css";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const MultipleImg = (props) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [previewVisible, setPreviewVisible] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  React.useEffect(()=>{
    props.productAdded&& setFileList([]);
      },[props.productAdded])
  // const [cropSize, setCropSize] = useState({ width: 512, height: 512 });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleCancel = () => {
   
    setPreviewVisible(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    if(fileList.length!==0)
    {
      props.MultipleImageReset();
      props.MultipleImage();
    setFileList(newFileList);
    }
    else{
    props.MultipleImage();
    setFileList(newFileList);
    }
  };

  const handleRemove = () => {
    // props.RemoveLogo();
    props.multipleDelete();
  };
  const SubmitNow = () => {
    props.secondaryImage({ files: fileList });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
     <Row>
    <Col>
      <ImgCrop
        modalTitle="product"
        modalWidth={700}
        modalHeight={700}
        minZoom={0.1}
        aspect={1 / 1}
        rotate={true}
        // cropSize={cropSize}
        grid
      >
        <Upload
          maxCount={4}
          customRequest={dummyRequest}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onRemove={handleRemove}
          onPreview={handlePreview}
          accept="image/*"
        >
          {fileList.length < 4 && "Click here to choose the image"}
        </Upload>
      </ImgCrop>
      </Col>
      <Col>
      <Card>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Remember
        </Typography>
        <Typography sx={{ fontSize: 12 }} component="div">
        You can upload upto 3 secondary images
        </Typography>
      </CardContent>
    </Card>
      </Col>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      </Row>
      <Row>
      <Col>
      {fileList.length !== 0 &&props.multiImage===null? (
        <button
          style={{
            justifyContent: " center",
            color: "rgb(255, 255, 255)",
            backgroundImage:
              "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
            boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
            width: "80px",
            height: "30px",
            fontSize: "16px",
            fontWeight: "600",
            borderRadius: "5px",
            borderWidth: "1px",
            borderStyle: " solid",
            borderColor: "transparent",
            borderImage: "initial",
            overflow: "hidden",
            outline: "none",
            minHeight: " 14px",
            fontFamily: "Varela Round, sans-serif",
          }}
          type="button"
          onClick={SubmitNow}
        >
          Upload
        </button>
      ) : null}
      </Col>
      </Row>
    </>
  );
};
export default MultipleImg;
