// import React, { Component } from "react";
// import OrderCircle from "./OrderCircle";
// import { Box, Grid, Typography, Container } from "@mui/material";
// import Income from "./Income";
// import ProductsTotal from "./ProductsTotal";
// import UsersTotal from "./UsersTotal";
// import OrderTotal from "./OrderTotal";
// import DeptGraph from "./DeptGraph";
// import PopularProducts from "./PopularProducts";

// // import Multiple from '../Multiple';

// export class index extends Component {
//   constructor(props){
//       super(props)
//       this.state={
//         count:0,
//         isMobile: window.innerWidth < 768
//       }
//   }
//   updateIsMobile=()=> {
//     this.setState({
//         isMobile: window.innerWidth < 768
//     });
// }

// componentWillUnmount() {
//   window.removeEventListener('resize', this.updateIsMobile);
// }
// componentDidMount() {
//   window.addEventListener('resize', this.updateIsMobile);
// }

//   // componentDidMount(){
//   //   this.props.getDashboard();
//   // }

//   render() {
//     document.title = "Seller Portal | Dashboard";
//     return (
//       this.state.isMobile ?
//       <Box>
//       <div style={{
//       Top: "40px",
//       width: "90vw",
//       overflowX:'auto',
//       justifyContent:'center',
//       alignContent:'center',
//       textAlign:'center'
//     }}>
//           <Typography variant="h4" style={{ fontWeight: 700, lineHeight: 1.5 }}
//           sx={{'@media screen and (min-width:600px) and (max-width:899px)':{
//             paddingRight:'239px',
//           }
//           }}>
//             Hi, Welcome back
//           </Typography>
//         <Grid style={{
//       width: "100%",
//     }}>
//           <Grid item xs={12} sm={6} md={3} style={{
//      paddingTop:'5px',
//     }}>
//             <Income {...this.props}/>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3} style={{
//      paddingTop:'5px',
//     }}>
//             <ProductsTotal {...this.props} count={this.state.count}/>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3} style={{
//      paddingTop:'5px',
//     }}>
//             <UsersTotal {...this.props}/>
//           </Grid>
//           <Grid item xs={12} sm={6} md={3} style={{
//      paddingTop:'5px',
//     }}>
//             <OrderTotal {...this.props}/>
//           </Grid>
//           <Grid item xs={12} md={6} lg={6} style={{
//      paddingTop:'5px',
//     }}
//     // sx={{'@media screen and (min-width:600px) and (max-width:899px)':{
//     //   paddingRight:'239px',
//     // }
//     // }}
//     >
//             <PopularProducts {...this.props}/>
//           </Grid>
//           <Grid item xs={12} md={6} lg={6} style={{
//      paddingTop:'5px',
//     }}
//    >
//             <OrderCircle {...this.props}/>
//           </Grid>
//           <Grid item xs={12} md={6} lg={12} style={{
//      paddingTop:'5px',
//     }}>
//             <DeptGraph {...this.props}/>
//           </Grid>
//         </Grid>
//         {/* <img style={{ maxWidth: "550px", paddingLeft:120 }} src={DAS} alt="dashboard"/> */}

//         {/* <br/>
//               <h3 style={{
//                   fontSize:'20px',
//                   fontWeight:700,
//                   textAlign:'center',
//                   justifyContent:'center'
//                   }}>
//                 {'No data yet.'}
//                  </h3>
//                  <Multiple {...this.props}/> */}
//       </div>
//       </Box>

// :

// <Box>
// <Container maxWidth="xl">
//   <Box sx={{ pb: 5 }}>
//     <Typography variant="h4" style={{ fontWeight: 700, lineHeight: 1.5 }}
//     >
//       Hi, Welcome back
//     </Typography>
//   </Box>
//   <Grid container spacing={3}>
//     <Grid item xs={12} sm={6} md={3}>
//       <Income {...this.props}/>
//     </Grid>
//     <Grid item xs={12} sm={6} md={3}>
//       <ProductsTotal {...this.props} count={this.state.count}/>
//     </Grid>
//     <Grid item xs={12} sm={6} md={3}>
//       <UsersTotal {...this.props}/>
//     </Grid>
//     <Grid item xs={12} sm={6} md={3}>
//       <OrderTotal {...this.props}/>
//     </Grid>
//     <Grid item xs={12} md={6} lg={6}>
//       <PopularProducts {...this.props}/>
//     </Grid>
//     <Grid item xs={12} md={6} lg={6}
//      >
//       <OrderCircle {...this.props}/>
//     </Grid>
//     <Grid item xs={12} md={6} lg={12}>
//       <DeptGraph {...this.props}/>
//     </Grid>
//   </Grid>
//   {/* <img style={{ maxWidth: "550px", paddingLeft:120 }} src={DAS} alt="dashboard"/> */}

//   {/* <br/>
//         <h3 style={{
//             fontSize:'20px',
//             fontWeight:700,
//             textAlign:'center',
//             justifyContent:'center'
//             }}>
//           {'No data yet.'}
//            </h3>
//            <Multiple {...this.props}/> */}
// </Container>
// </Box>
//     );
//   }
// }

// export default index;







//new code

import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CustomCard from "../Commoncomponents/card/index";
import { CircularProgress, Grid, Typography } from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import MyChartComponent from "./MyChartComponent";
import DonutChart from "./DonutChart";
import "./index.css";
import CustomButton from "../Commoncomponents/button/index";
import CustomBadge from "../Commoncomponents/badge/index";
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import { HeaderFontSize_24 } from "../Typography/index";
import { HeaderNavigationFontSize_12 } from "../Typography/index";
import { useTheme } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import { HeaderNavigationFontSize_20 } from "../Typography/index";
import { contentFontsize_18_xs_11 } from "../Typography/index";
import { contentFontSize_16 } from "../Typography/index";
import Income from "./Income";
import OrderTotal from "./OrderTotal";
import ProductsTotal from "./ProductsTotal";
import InactiveProducts from "./InactiveProducts";
import TotalPayout from "./TotalPayout";
import PopularProducts from "./PopularProducts";
import { useNavigate } from "react-router-dom";
import {Dialog} from "../MUIComponents/index";
import {DialogTitle} from "../MUIComponents/index";
import {DialogActions} from "../MUIComponents/index";
import new_Order from '../../utils/images/new_Order.svg'
import { useSelector } from "react-redux";

function Dashboard(props) {
  
  const { newDashboardLoader } = useSelector((state) => state.SignInReducer);

  
  useEffect(()=>{
    props?.getNewDashboard(`${localStorage.getItem("storeid")}`);
    props?.criticalOrders();
  },[]) 
  
  
  
  const [newState, setNewState] = useState(false);
  const [activeState, setActiveState] = useState(false);
  const [readyState, setReadyState] = useState(false);
  const [openDialog,setOpenDialog]=useState(false);

  const theme = useTheme();

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const uplgmatch=useMediaQuery(theme.breakpoints.up('lg'));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

 

 
   





  const navigate =useNavigate();


  const percentage = 66;

 

  const handleNewState = () => {
    setNewState(true);
    setActiveState(false);
    setReadyState(false);
    navigate('/home/MyOrders')
  };

  const handleActiveState = () => {
    setActiveState(true);
    setNewState(false);
    setReadyState(false);
    navigate('/home/MyOrders/ActiveOrders')
  };

  const handleReadyState = () => {
    setReadyState(true);
    setNewState(false);
    setActiveState(false);
    navigate('/home/MyOrders/ReadyForDelivery')
  };

  const footerSliderArray = [
    "parotta is my fav food",
    "beef is full of calaries",
    "mutton",
    "boolii is yellow in color",
    "noodles",
    "briyani",
    "manthi",
    "dosa",
    "idli",
    "aapam",
    "cut fruit is good for health",
    "rice",
  ];
  return (
    <div style={{background:"#F4F5F9"}}>
      {props?.NewDashboardDetails?.getDashboardNew?<>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4} style={{paddingLeft:"0px"}}>
            <CustomCard padding={smmatch?"10px 5px":"10px"} boxShadow="none">
              <Income 
              
              upXlmatch={upXlmatch}
              xlmatch={xlmatch}
              lgmatch={lgmatch}
              mdmatch={mdmatch}
              smmatch={smmatch}
              xsmatch={xsmatch}
              {...props}
              />
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4}>
            <CustomCard padding={smmatch?"10px 5px":"10px"} boxShadow="none">
             <OrderTotal
              upXlmatch={upXlmatch}
              xlmatch={xlmatch}
              lgmatch={lgmatch}
              mdmatch={mdmatch}
              smmatch={smmatch}
              xsmatch={xsmatch}
              {...props}/>
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4} style={{paddingLeft:smmatch&&"0px"}}>
            <CustomCard padding={smmatch?"10px 5px":"10px"} boxShadow="none">
                <ProductsTotal
                upXlmatch={upXlmatch}
                xlmatch={xlmatch}
                lgmatch={lgmatch}
                mdmatch={mdmatch}
                smmatch={smmatch}
                xsmatch={xsmatch}
                {...props}/>
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4}  style={{paddingLeft: smmatch ? "16px" : xlmatch ? "0px" : undefined}}>
            <CustomCard padding={smmatch?"10px 5px":"10px"} boxShadow="none">
              <InactiveProducts
              upXlmatch={upXlmatch}
              xlmatch={xlmatch}
              lgmatch={lgmatch}
              mdmatch={mdmatch}
              smmatch={smmatch}
              xsmatch={xsmatch}
              {...props}/>
            </CustomCard>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={4} xl={2.4} style={{paddingLeft:smmatch&&"0px"}}>
            <CustomCard padding={smmatch?"10px 5px":"10px"} boxShadow="none">
              <TotalPayout
               upXlmatch={upXlmatch}
               xlmatch={xlmatch}
               lgmatch={lgmatch}
               mdmatch={mdmatch}
               smmatch={smmatch}
               xsmatch={xsmatch}
               {...props}/>
            </CustomCard>
          </Grid>
        </Grid>

      </Box>
      <div style={{ marginTop: "15px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:"0px"}}>
              
                <CustomCard padding="20px" height={upXlmatch?"430px":'370px'} boxShadow="none" >
                
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      height:"100%"
                    }}
                  >
                
                    <Typography
                      fontSize={HeaderNavigationFontSize_20}
                      className="dashboardChartFontFamily"
                    >
                      Past Week Order Details
                    </Typography>
                    <div style={{height:"100%"}}>
                      <MyChartComponent 
                       upXlmatch={upXlmatch}
                       xlmatch={xlmatch}
                       lgmatch={lgmatch}
                       mdmatch={mdmatch}
                       uplgmatch={uplgmatch}
                       smmatch={smmatch}
                       xsmatch={xsmatch}
                       props={props}/>
                    </div>
                   
                  </div>
                </CustomCard>
           
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingLeft:smmatch&&"0px"}}>
             
                <CustomCard padding={uplgmatch?"20px 20px 30px 20px":"20px"} height={upXlmatch?"430px":'370px'} boxShadow="none">
               
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      height:"100%"
                    }}
                  >
                   
                    <Typography
                      fontSize={HeaderNavigationFontSize_20}
                      className="dashboardChartFontFamily"
                    >
                      Order Status
                    </Typography>
                    {/* <div style={{ height: "100%" }}> */}
                      <DonutChart
                        upXlmatch={upXlmatch}
                        xlmatch={xlmatch}
                        lgmatch={lgmatch}
                        mdmatch={mdmatch}
                        smmatch={smmatch}
                        xsmatch={xsmatch}
                        uplgmatch={uplgmatch}
                        props={props}
                      />
                   
                    {/* </div> */}
                  </div>
                </CustomCard>
             
            </Grid>
          </Grid>
        </Box>
      </div>

      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          gap: mdmatch ? (smmatch ? "4px" : "15px") : "20px",
        }}
      >
        <CustomButton
          width="100%"
          borderRadius="15px"
          background={newState ? "#659B1B" : "transparent"}
          variant={newState ? "contained" : "outlined"}
          color={newState ? "white" : "#646464"}
          borderColor="#659B1B"
          size={mdmatch ? "small" : "large"}
          onClick={handleNewState}
          matchbtn={smmatch && true}
          matchPadding={true}
          padding="10px"
        >
          <Typography
            fontSize={contentFontsize_18_xs_11}
            className="AssistantProfileStyle"
          >
            New Orders
          </Typography>

          <CustomBadge
            // badgeContent={orderList&&orderList?.length}
            badgeContent={props.NewDashboardDetails &&
              props.NewDashboardDetails.getDashboardNew &&
              props.NewDashboardDetails.getDashboardNew.newOrders}
            backgroundColor={newState ? "white" : "none"}
            color={newState ? "#659B1B" : "#646464"}
            borderColor={newState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "20px"}
            minWidth={mdmatch ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="100%"
          borderRadius="15px"
          background={activeState ? "#659B1B" : "transparent"}
          color={activeState ? "white" : "#646464"}
          variant={activeState ? "contained" : "outlined"}
          borderColor="#659B1B"
          size={mdmatch ? "small" : "large"}
          onClick={handleActiveState}
          matchbtn={smmatch && true}
          matchPadding={true}
          padding="10px"
        >
          <Typography
            fontSize={contentFontsize_18_xs_11}
            className="AssistantProfileStyle"
          >
            {" "}
            Active Orders
          </Typography>
          <CustomBadge
            // badgeContent={ActiveorderList&&ActiveorderList?.length}
            badgeContent={props.NewDashboardDetails &&
              props.NewDashboardDetails.getDashboardNew &&
              props.NewDashboardDetails.getDashboardNew.activeOrders}
            backgroundColor={activeState ? "white" : "none"}
            color={activeState ? "#659B1B" : "#646464"}
            borderColor={activeState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "20px"}
            minWidth={mdmatch ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>

        <CustomButton
          width="100%"
          borderRadius="15px"
          background={readyState ? "#659B1B" : "transparent"}
          variant={readyState ? "contained" : "outlined"}
          color={readyState ? "white" : "#646464"}
          borderColor="#659B1B"
          size={mdmatch ? "small" : "large"}
          onClick={handleReadyState}
          matchbtn={smmatch && true}
          matchPadding={true}
          padding="10px"
        >
          <Typography
            fontSize={contentFontsize_18_xs_11}
            className="AssistantProfileStyle"
          >
            Ready for Delivery
          </Typography>
          <CustomBadge
            // badgeContent={ReadyorderList&&ReadyorderList?.length}
            badgeContent={props.NewDashboardDetails &&
              props.NewDashboardDetails.getDashboardNew &&
              props.NewDashboardDetails.getDashboardNew.readyOrders}
            backgroundColor={readyState ? "white" : "none"}
            color={readyState ? "#659B1B" : "#646464"}
            borderColor={readyState ? "none" : "#646464"}
            border="2px solid"
            fontSize={mdmatch ? "8px" : "12px"}
            height={mdmatch ? "15px" : "20px"}
            minWidth={mdmatch ? "15px" : "20px"}
            borderRadius="50%"
            marginLeft={mdmatch ? (smmatch ? "8px" : "20px") : "25px"}
          ></CustomBadge>
        </CustomButton>
      </div>

      <Typography
        fontSize={HeaderNavigationFontSize_20}
        style={{
          margin: "20px 0px 20px 0px",
          fontFamily: "Roboto",
          fontWeight: "500",
        }}
      >
        Most Selling Item
      </Typography>

      <div className="scrollContainer">

        <PopularProducts footerSliderArray={footerSliderArray}  mdmatch={mdmatch} props={props}/>

      </div>
      
      </>:<div style={{height:"100vh",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}><CircularProgress/></div>}

      {/* new order popup */}

    
        

    </div>
  );
}

export default Dashboard;
