// import React from "react";
// import { Grid } from "@mui/material";
// import { InputField, SelectField } from "../../FormFields";
// import ModalWindow from "../../Modal";
// import FormGenerators from "../../FormGeneratorNew";
// import ImageCropper from "../ImageCropper";
// import Alert from "@mui/material/Alert";
// import Snackbar from "@mui/material/Snackbar";
// import BusinessType from "../../FormFields/BusinessType";
// import { AlertTitle } from "@mui/material";
// import { useLocation } from "react-router-dom";

// const Business = [
//   {
//     id: 1,
//     name: "Groceries & Essentials",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Groceries+%26+Essentials.png",
//   },
//   {
//     id: 2,
//     name: "Restaurants & Bakery",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Restaurant+%26+bakery.jpg",
//   },
//   {
//     id: 3,
//     name: "Fish & Meat",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Meat+%26+Fish.jpg",
//   },
//   {
//     id: 4,
//     name: "Fruits & Vegetables",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Fruits+%26+Vegetables.png",
//   },
//   {
//     id: 5,
//     name: "Medicines",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Medicines.webp",
//   },
//   {
//     id: 6,
//     name: "Electronics & Home Appliances",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Electronics+%26+Home+Appliances.jpg",
//   },
//   {
//     id: 7,
//     name: "Lifestyle & Fashion",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Lifestyle+%26+Fashion.jpg",
//   },
//   {
//     id: 8,
//     name: "Sports & Fitness",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/sports+%26+fitness.jpg",
//   },
//   {
//     id: 9,
//     name: "Pet Care",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Pet+Care.jpg",
//   },
//   {
//     id: 10,
//     name: "Home decor & Furnishing",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+decor+%26+Furnishing.png",
//   },
//   {
//     id: 11,
//     name: "Home Business",
//     coverImage: "imageUrl",
//     iconImage:
//       "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+Business.jpg",
//   },
// ];

// export default function PaymentForm(props) {
//   console.log("paymentprops", props);
//   React.useEffect(() => {
//     document.body.scrollTop = 0;
//   }, []);

//   const storeDescription = props.formField?.storeDescription;

//   const [state, setState] = React.useState({
//     isModal: false,
//     isImageModal: false,
//   });

//   const location = useLocation();

//   const { storeName, mobileNumber, emailId, password } = location.state || {};
//   console.log("values:", storeName, mobileNumber, emailId, password);

//   const handleStoreNameChange = (e) => {
//     const newName = e.target.value;
//     props.setStoreName(newName);
//   };
//   const handleStoreDescriptionChange = (e) => {
//     const newDescription = e.target.value;
//     props.setStoreDescription(newDescription);
//   };

//   function handleModal() {
//     setState({ isModal: !state.isModal });
//   }
//   function handleImageModal() {
//     setState({ isImageModal: !state.isImageModal });
//   }
//   const initialValues = {
//     [storeName]: storeName,
//   };
//   return (
//     <React.Fragment>
//       <Grid container>
//         <h5 style={{ paddingLeft: 10, paddingTop: 10 }}>Store details</h5>
//       </Grid>
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={12}>
//           <InputField
//             name={storeName.name}
//             label={storeName.label}
//             value={initialValues[storeName.name]}
//             fullWidth
//           />
//         </Grid>
//         <Grid item xs={12} md={12}>
//           <InputField
//             name={storeDescription?.name}
//             label={storeDescription?.label}
//             onChange={handleStoreDescriptionChange}
//             value={storeDescription?.values}
//             fullWidth
//           />
//         </Grid>
//         <Grid item xs={12} md={12}>
//           {state.isModal && (
//             <ModalWindow
//               handleModal={handleModal}
//               formConfiguration={
//                 props.formConfiguration.storeDetailConfiguration
//               }
//               handleMapChange={props.getCoordinates}
//             />
//           )}
//           <FormGenerators
//             formConfiguration={props.formConfiguration.storeDetailConfiguration}
//             handleChange={props.handleChange}
//             getLocationChange={props.getLocationChange}
//             handleModal={handleModal}
//             handleImageModal={handleImageModal}
//           />
//         </Grid>
//         <Grid item xs={12} md={12}>
//           {props.newImageUpload || props.newImageFailed ? (
//             <Snackbar
//               anchorOrigin={{
//                 vertical: "top",
//                 horizontal: "center",
//               }}
//               // style={{ width: "fit-content", left: "50%", bottom: "50%" }}
//               open={props.newImageUpload || props.newImageFailed}
//               autoHideDuration={1000}
//               onClose={() => props.closeSnack()}
//             >
//               <div
//                 style={{
//                   backgroundColor: props.newImageUpload
//                     ? "rgb(83,170,73)"
//                     : "red",
//                   color: "white",
//                   textAlign: "center",
//                   padding: "10px",
//                   borderRadius: "4px",
//                 }}
//               >
//                 {props?.newImageUploaded
//                   ? "Logo uploaded successfully"
//                   : props?.newImageFailed
//                   ? "Logo upload failed"
//                   : null}
//               </div>
//             </Snackbar>
//           ) : null}
//           <h6 style={{ paddingLeft: 10, paddingTop: 10 }}>Logo Upload</h6>
//           <ImageCropper {...props} />
//         </Grid>
//         <Grid item xs={12} md={6}>
//           <BusinessType {...props} />
//         </Grid>
//       </Grid>
//     </React.Fragment>
//   );
// }
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Businesstype from "../../CheckoutPage/Forms/Businesstype";
import { contentFontSize_16, contentFontSize_22 } from "../../Typography/index";
import "./PaymentForm.css";
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
} from "../../MUIComponents/index";
import { useLocation } from "react-router-dom";
import { useTheme, useMediaQuery } from "../../MUIComponents/index";
import StoreLocation from "../StoreLocation";
import GoogleMap from "../GoogleMap";
import MapEdit from "../MapEdit";
import { storevalidationSchema } from "../Validation/StoreValidation";
import ImageCropper from "../ImageCropper/index";
import { message } from "antd";
import {SIGNUP_FORM2} from '../../../Redux/Action/NewSignUp/GqlQuery'
import client from '../../../Configurations/apollo'
import { useDispatch } from "react-redux";

const RegisterForm1 = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formvalues=JSON.parse(localStorage.getItem("formValues"));

  const nav = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch()
  const [businessType, setBusinessType] = useState([]);
  const [mapCoordinates, setMapCoordinates] = useState({ lat: 0, lng: 0 });
  const [mapaddress, setMapaddress] = useState("");
  const [mapDetails, setMapDetails] = useState();
  const [selectedBusinessTypes, setSelectedBusinessTypes] = useState([]);
  const [logo,setLogo]= useState()

  useEffect(() => {
    if (location.pathname === "/RegisterForm1") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
    return () => {
      window.removeEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [location.pathname]);

  const [state, setState] = React.useState({
    mapEditModal: false,
  });

  const mapEditModal = () => {
    setState({ ...state, mapEditModal: !state.mapEditModal });
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));




  const initialValues = {
    storeName: formvalues?.storeName || "",
    storeDescription: "",
    storeLocation: mapaddress,
    storeCoordinates: mapCoordinates,
    storeLogoUrl: localStorage.getItem("imageUrl"),
    businessType: selectedBusinessTypes,
  };

  useEffect(() => {
    formik.setFieldValue("storeLocation", mapaddress);
    formik.setFieldValue("storeCoordinates", mapCoordinates);
    formik.setFieldValue("businessType", selectedBusinessTypes);
    formik.setFieldValue("logo", logo); 
  }, [mapaddress, mapCoordinates, selectedBusinessTypes,logo]);


  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: storevalidationSchema,
    onSubmit: (values) => {
      if (!logo) {
        message.error({
          content: 'Store Logo is required',
          className: 'custom-class',
          style: {
            marginTop: '20vh',
          },
        });
        return
      }
      dispatch({
        type:"SIGNUP_FORM2_REQ"
      })
      client
      .mutate({
        mutation:SIGNUP_FORM2,
        variables:{
          storeName:formvalues.storeName,
          storeDescription:values.storeDescription,
          businessTypeId:values.businessType,
          storeLocation:values.storeLocation,
          storeLogoUrl:localStorage.getItem("imageUrl"),
          storeCoordinates:{
            coordinates:[Number(values.storeCoordinates.lat),
            Number(values.storeCoordinates.lng)]
            }
  
        }
      })
      .then((response)=>{
        dispatch({type:"SIGNUP_FORM2_SUCCESS",payload:response.data});
      })
      .catch((error)=>{
        dispatch({type:"SIGNUP_FORM2_FAILURE", payload: error.message})
      })
      nav("/RegisterForm2");
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  return (
    <div style={{ height: "100%" }}>
      <div style={{ background: "#85BE49", padding: "10px" }}>
        <Typography variant="h4" align="center" gutterBottom color={"white"}>
          Registration
        </Typography>
      </div>
      <div style={{ padding: "10px" }}>
        <Typography
          align="center"
          gutterBottom
          fontSize={contentFontSize_22}
          className="chooseHolidaysStyle"
        >
          Please enter your details to create your account
        </Typography>
        <div style={{ paddingLeft: "30px", paddingTop: "30px" }}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            fontSize={contentFontSize_22}
            className="chooseHolidaysStyle"
          >
            Store Information
          </Typography>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          style={{ padding: "30px", paddingTop: "0px" }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="StoreNameStyle">
                <Typography
                  className="StoreLabelStyles"
                  fontSize={contentFontSize_16}
                >
                  Store Name <span className="redstart">*</span>
                </Typography>

                <TextField
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#85BE49",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#85BE49",
                      },
                    },
                    "& .MuiInputBase-input": {
                      backgroundColor: "white",
                      borderRadius: "10px",
                    },
                  }}
                  InputProps={{
                    sx: {
                      fontSize: matches && "13px",
                    },
                  }}
                  size={matches ? "small" : "normal"}
                  name="storeName"
                  onChange={formik.handleChange}
                  value={formik.values.storeName}
                  // disabled={Boolean(formik.values.storeName)}
                  error={
                    formik.touched.storeName && Boolean(formik.errors.storeName)
                  }
                  helperText={
                    formik.touched.storeName && formik.errors.storeName
                  }
                ></TextField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="StoreNameStyle">
                <Typography
                  className="StoreLabelStyles"
                  fontSize={contentFontSize_16}
                >
                  Store Description <span className="redstart">*</span>
                </Typography>
                <TextField
                  multiline={true}
                  rows="3"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#85BE49",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#85BE49",
                      },
                    },
                    "& .MuiInputBase-input": {
                      backgroundColor: "white",
                      borderRadius: "10px",
                    },
                  }}
                  InputProps={{
                    sx: {
                      fontSize: matches && "13px",
                      background: "white",
                    },
                  }}
                  size={matches ? "small" : "normal"}
                  name="storeDescription"
                  onChange={formik.handleChange}
                  value={formik.values.storeDescription}
                  error={
                    formik.touched.storeDescription &&
                    Boolean(formik.errors.storeDescription)
                  }
                  helperText={
                    formik.touched.storeDescription &&
                    formik.errors.storeDescription
                  }
                ></TextField>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                Business Type <span className="redstart">*</span>
              </Typography>
              <div style={{ height: "100%" }}>
                <Businesstype
                  businessType={businessType}
                  setBusinessType={setBusinessType}
                  selectedBusinessTypes={selectedBusinessTypes}
                  setSelectedBusinessTypes={setSelectedBusinessTypes}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <StoreLocation
                setMapaddress={setMapaddress}
                setMapCoordinates={setMapCoordinates}
                setMapDetails={setMapDetails}
                mapaddress={mapaddress}
              />
              <div className="StoreNameStyle" style={{marginTop:"10px"}}>
                <div
                  style={{
                    border: "1px solid rgba(133, 190, 73, 0.35)",
                    borderRadius: "9px",
                  }}
                >
                  <GoogleMap
                    mapCoordinates={mapCoordinates}
                    setMapaddress={setMapaddress}
                    setMapCoordinates={setMapCoordinates}
                    setMapDetails={setMapDetails}
                    mapaddress={mapaddress}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="Namestyles">
                <Typography
                  className="StoreLabelStyles"
                  fontSize={contentFontSize_16}
                >
                  Store Coordinates <span className="redstart">*</span>
                </Typography>
                <MapEdit
                  mapCoordinates={mapCoordinates}
                  setMapCoordinates={setMapCoordinates}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="StoreNameStyle">
                <Typography
                  className="StoreLabelStyles"
                  fontSize={contentFontSize_16}
                >
                  Upload Store Logo <span className="redstart">*</span>
                </Typography>
                <ImageCropper logo={logo} setLogo={setLogo}/>
              </div>
            </Grid>

            <Grid container justifyContent="flex-end" sx={{ marginTop: 2 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" ,background:"#85BE49"}}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm1;
