
export const fieldState = {
	userName: localStorage.getItem('rememberMe') ? localStorage.getItem('user') : '',
	password: localStorage.getItem('rememberMe')? localStorage.getItem('password') : ''
};
export const fields = [
	{
		label: "Email",
		type: "email",
		name: "userName",
		placeholder: "Type your registered email",
	},
	{
		label: "Password",
		type: "password",
		name: "password",
		placeholder: "************",
	},
];
