// import React from 'react';
// import { Row , Col } from 'react-bootstrap';
// import { SUB1 , SUB2, Whyfields } from './Constants';
// // import Carousel from 'react-material-ui-carousel'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// import './index.css';


// export default function index(props)
// {
//     const sliderItems = Whyfields.length > 6 ? 6 : Whyfields.length;
//     const items= [];
  
//     for (let i = 0; i < Whyfields.length; i += sliderItems) {
//       if (i % sliderItems === 0) {
//         items.push(
//             <Row style={{paddingTop:'20px'}} key={i}>
//               {Whyfields.slice(i, i + sliderItems).map((da, index) => {
//                 return(
                    
//                     <Col md={2} xs={12} key={index+1}
//                     style={{paddingBottom:'5px',display:'flex',textAlign:'center',justifyContent:'center',alignItems:'center'}}>
//                  <Items  item={da} />
//                  </Col>
                        
//                 )
//               })}
//              </Row>
//         );
//       }
//     }

//     return (
//         <div className="full-div">
//             <Row className="bg-div">
//                 <Col xs={12}>
//                          <b className="bg-heading">Why Choose NearShopz ?</b>
//                          </Col>
//                          </Row>
//                          <Row className="bg-div">
//                 <Col xs={12}>
//                         <div className="sub-heading">
//                              {`${SUB1}`}
//                              <br/>
//                              {`${SUB2}`}
//                          </div>
//                          </Col>
//                          </Row>
                      
//            <div className="carousel-div">
               
//                          {/* <Carousel animation="slide" interval={5000} cycleNavigation={false} swipe={true}
//                          NextIcon={<ArrowForwardIosIcon/>}
//                          PrevIcon={<ArrowBackIosNewIcon/>}
//                             navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
//                                 style: {
//                                     backgroundColor: 'white',
//                                     color:'green',
//                                     borderRadius: 0
//                                 }
//                             }} 
//                             indicatorIconButtonProps={{
//                                 style:{
//                                     color:'rgb(175, 175, 175)', 
//                                     float:'left'
//                                 }
//                             }}
//                             activeIndicatorIconButtonProps={{
//                                 style:{
//                                     color:'white', 
//                                 }
//                             }}
//                              >
                          
//                              {items}
//                         </Carousel> */}
//                         </div>
//         </div>
//     )
// }

// function Items(props)
// {
//     return (
      
//            <div className="why-row">
//                             <div>
//                          <img className="why-img" 
//     width='50px' height='50px' style={{maxWidth:'100%',
//     maxHeight:'100%'}} src={props.item.image} alt="why.png"/>
//                          </div>
//                          <div className="why-heading">
//                            <b>  {`${props.item.heading}`} </b>
//                          </div>
//                          </div>
                        
       
//     )
// }
// // export class index extends Component {
// //     render() {
// //         return (
// //             <div className="bg-div">
// //                 <b className="bg-heading">Why Choose Near Shopz ?</b>
// //                 <div className="sub-heading">
// //                     {`${SUB1}`}
// //                     <br/>
// //                     {`${SUB2}`}
// //                 </div>
// //                 <Row style={{paddingTop:'20px'}}>
// //                     {Whyfields.map((w)=>(

// //                         <Col md={2} xs={12}>
// //                             <div className="why-row">
// //                             <div>
// //                          <img className="why-img" src={w.image} alt="why.png"/>
// //                          </div>
// //                          <div className="why-heading">
// //                            <b>  {`${w.heading}`} </b>
// //                          </div>
// //                          </div>
// //                         </Col>
// //                     ))}
// //                 </Row>
// //             </div>
// //         )
// //     }
// // }

// // export default index

import React, { useEffect, useState } from "react";
import CustomCard from "../Commoncomponents/card";
import partner from "../../utils/images/partner.png";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Card,
} from "../MUIComponents/index";
import {
  HeaderNavigationFontSize_22,
  contentFontSize_18,
  ButtonFontSize_18,
  HeaderfontSize_30,
  contentFontSize_15,
} from "../Typography";
// import "./LandingPage.css";
import CustomButton from "../Commoncomponents/button";
import rideimg from "../../utils/images/rideimg.jpeg";
import Shopimg from "../../utils/images/Shopwithus.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

function WhyChoose() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const [color, setColor] = useState("#13801C");
  const nav = useNavigate();

  const handleConnectClick = (subject) => {
    nav("/Contact", { state: { subject } });
  };

  useEffect(() => {
    AOS.init({
      once: true,
      disable: smmatch, 
      duration: 1500,
      easing: "ease-out-cubic",
    });
  }, [smmatch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor((prevColor) => (prevColor === "#13801C" ? "black" : "#13801C"));
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="linear-gradient-background">
      <div style={{ marginTop: "100px" }}>
        <Typography
          fontSize={HeaderfontSize_30}
          className="header"
          style={{ color: color }}
        >
          Let's Grow Together with NearShopz!
        </Typography>
      </div>
      <div
        style={{
          marginTop: "50px",
          display: "flex",
          flexDirection: smmatch ? "column" : "row", 
          gap: "30px",
          alignItems: "center",
          padding: "20px",
          justifyContent: "space-evenly",
        }}
      >
        <div data-aos={smmatch ? "" : "slide-right"}>
          <Card
            className="card"
            sx={{
              padding: "20px",
              paddingTop: "50px",
              height: "600px",
              width: "450px",
              background: "white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={partner}
                  alt="no image"
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Typography
                  fontSize={HeaderNavigationFontSize_22}
                  className="header-1"
                >
                  Partner with Us
                </Typography>
              </div>
              <div style={{ paddingTop: "20px", height: "150px" }}>
                <Typography fontSize={contentFontSize_15} className="para">
                  Calling all ambitious business owners! Expand your reach and
                  unlock new sales potential by partnering with NearShopz, the
                  leading local ecommerce platform. With low markups and
                  convenient services, NearShopz helps your business grow. Join
                  our network of successful sellers and attract more customers!
                </Typography>
              </div>
              <div style={{ paddingTop: "50px", textAlign: "center" }}>
                <Button
                  className="custom-button1"
                  sx={{
                    width: {
                      xs: "180px",
                      sm: "160px",
                      md: "130px",
                      lg: "130px",
                      xl: "160px",
                      upXl: "170px",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                    height: "40px",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                  }}
                  onClick={() => handleConnectClick("Partner with Us")}
                >
                  <Typography
                    fontSize={ButtonFontSize_18}
                    className="Add-product-btn"
                  >
                    Connect
                  </Typography>
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div data-aos={smmatch ? "" : "fade-down"}>
          <Card
            className="card"
            sx={{
              padding: "20px",
              paddingTop: "50px",
              height: "600px",
              width: "450px",
              background: "white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={Shopimg}
                  alt="no image"
                  style={{ width: "100%", height: "300px", borderRadius: "10px" }}
                />
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Typography
                  fontSize={HeaderNavigationFontSize_22}
                  className="header-1"
                >
                  Shop with Us
                </Typography>
              </div>
              <div style={{ paddingTop: "20px", height: "150px" }}>
                <Typography fontSize={contentFontSize_15} className="para">
                  Elevate your everyday with NearShopz! From fresh groceries to
                  delightful treats, essential daily items to pampering pet
                  supplies, NearShopz offers endless options to buy and get it
                  all delivered straight to your door. Download NearShopz and
                  simplify your life - shop smarter, not harder!
                </Typography>
              </div>
              <div style={{ paddingTop: "50px", textAlign: "center" }}>
                <Button
                  className="custom-button1"
                  sx={{
                    width: {
                      xs: "180px",
                      sm: "1600px",
                      md: "130px",
                      lg: "130px",
                      xl: "160px",
                      upXl: "170px",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                    height: "40px",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                  }}
                  onClick={() => handleConnectClick("Shop with Us")}
                >
                  <Typography
                    fontSize={ButtonFontSize_18}
                    className="Add-product-btn"
                  >
                    Connect
                  </Typography>
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div data-aos={smmatch ? "" : "slide-left"}>
          <Card
            className="card"
            sx={{
              padding: "20px",
              paddingTop: "50px",
              height: "600px",
              width: "450px",
              background: "white",
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={rideimg}
                  alt="no image"
                  style={{ width: "100%", height: "300px", borderRadius: "10px" }}
                />
              </div>
              <div style={{ paddingTop: "20px" }}>
                <Typography
                  fontSize={HeaderNavigationFontSize_22}
                  className="header-1"
                >
                  Ride with Us
                </Typography>
              </div>
              <div style={{ paddingTop: "20px", height: "150px" }}>
                <Typography fontSize={contentFontSize_15} className="para">
                  Looking for a flexible and rewarding opportunity? Join
                  NearShopz as a delivery rider! Earn competitive income with a
                  flexible schedule and full support. Deliver food, groceries,
                  treats, fresh produce, and pet items to our customers.
                  Download the NearShopz app to apply now and start earning
                  today!
                </Typography>
              </div>
              <div style={{ paddingTop: "50px", textAlign: "center" }}>
                <Button
                  className="custom-button1"
                  sx={{
                    width: {
                      xs: "180px",
                      sm: "160px",
                      md: "130px",
                      lg: "130px",
                      xl: "160px",
                      upXl: "170px",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                    height: "40px",
                    borderRadius: "20px",
                    background:
                      "linear-gradient(to right, #13801C 100%, #FFFFFF 0%)",
                  }}
                  onClick={() => handleConnectClick("Ride with Us")}
                >
                  <Typography
                    fontSize={ButtonFontSize_18}
                    className="Add-product-btn"
                  >
                    Connect
                  </Typography>
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default WhyChoose;

