import { shopassistantregister } from "../../Action/SignInActions/SignInActions";

export const SignInReducer = (
	state = {
		newSignedUrl: null,
		proImage: null,
		multiImage: null,
		isActiveOrder: false,
		isData: false,
		isOrderNotification: false,
		multipleImageLoading: false,
		multipleImageUploaded: false,
		productUpdateFailed: false,
		multiplefailed: false,
		productAddFail: false,
		addingProduct: false,
		productAdded: false,
		proupdating: false,
    isOrderLoaded:false,
		isDataLoaded: false,
		newImageLoading: false,
		newImageUploaded: false,
		newImageFailed: false,
    orderImageLoading: false,
		orderImageUploaded: false,
		orderImageFailed: false,
		editRetailerSuccess: false,
		editStoreSuccess: false,
		editRetailerFailed: false,
		editStoreFailed: false,
		passwordResetSuccess: false,
		passwordResetFailed: false,
		searchSuccess: false,
		searchFailed: false,
    lastStatusChangedSuccess:false,
    lastStatusChangedfail:false,
		productUpdate: false,
		searchDetailsSuccess: false,
		searchDetailsFailed: false,
		logout: false,
		HeirarchyError: false,
		isDepartmentLoaded: false,
		isCategoryLoaded: false,
		isCategory1Loaded: false,
		isCategory2Loaded: false,
		searchProductSuccess: false,
		updateDep: false,
		addDep: false,
    deleteShopAssistantSuccess:false,
    deleteShopAssistantFailed:false,
		updateSuccess: false,
		addSuccess: false,
		Successload: true,
		shopassistantregistration: false,
		shopassistantFailed: false,
		shopassistantregistrationwaiting: false,
    editShopassistantregistration: false,
    editShopassistantregistrationwaiting: false,
    editShopassistantFailed: false,
		isOrderList: false,
		isAssigned: false,
		AssignSuccess: false,
		isAssignedPartner: false,
		AssignPartnerSuccess: false,
		network: false,
		NetworkError: false,
		isstoreWorking: false,
		multiFileEdit: false,
		// productAdded:false,
		// productAddFail:false,CatalogProducts
		searchLoading: false,
		searchingOrder: false,
		fileListLogo: false,
		multiFileEdited: false,
		newLogoImageUploaded: false,
		gstFile: false,
		GSTImageUploaded: false,
		GSTEditUrl: null,
		addCatalogProductSuccess: false,
		MasterproductCheck: [],
		RejectClick: false,
		searchProductSuccess: false,
		BXGYCheck: [],
		MasterProducts:[],
		CatalogProducts:[],
		businessId:[],
		storeName:null,
		storeDescription:null,
    ChangeToActiveLoading:false,
    ChangeToReadyLoading:false,
    ChangeToDeliveryLoading:false,
    ChangeToCompletedLoading:false,
    newOrderSuccess:false,
    newOrdersFailed:false,
    newOrders:[],
    activeOrderSuccess:false,
    activeOrdersFailed:false,
    lastStatusChangedfail:false,
    activeOrders:[],
    readyOrderSuccess:false,
    readyOrdersFailed:false,
    readyOrders:[],
    lastStatusChangedAllSuccess:false,
    lastStatusChangedSuccess:false,
    completedOrderSuccess:false,
    completedOrdersFailed:false,
    completedOrders:[],
    ChangetoActiveStatusSuccess:false,
    isOrderNotificationSeen:false,
    ChangetoActiveStatusFailed:false,
    ChangetoActiveStatus: [],
    ChangetoReadyStatusSuccess:false,
    ChangetoReadyStatusFailed:false,
    lastStatusChangedAllFail:false,
    ChangetoReadyStatus: [],
    isNewDashboardLoaded:false,
NewDashboardError:false,
    ChangetoDeliveryStatusSuccess:false,
    ChangetoDeliveryStatusFailed:false,
    ChangetoDeliveryStatus: [],
    ChangetoCompletedStatusSuccess:false,
    ChangetoCompletedStatusFailed:false,
    ChangetoCompletedStatus: [],
		criteria: {
			from: 0,
			first: 20,
			storeid: localStorage.getItem('storeid'),
			department: '',
			category: '',
			category1: '',
			category2: '',
			status: '',
			key: ''
		},
		BxgyProductssuccess: false,
		BxgyProductsFailed: false,
		ActiveOrder:[],
		ProductStatusUpdateSuccess:false,
		searchProductData:[],
		Products:[],
		shopAssistantsID:null,
    AllUpcomingCouponsLoading: false,
    AllUpcomingCouponssuccess: false,
    AllUpcomingCouponsFailed: false,
    AllUpcomingCoupons: [],
    AllOngoingCouponsLoading: false,
				AllOngoingCouponssuccess: false,
				AllOngoingCouponsFailed: false,
				AllOngoingCoupons:[],
	},
	action
) => {
	switch (action.type) {
    case "Dashboard_SUCCESS":
      return {
        ...state,
        isDashboardLoaded: true,
        DashboardDetails: action.payload,
      };
    case "Dashboard_FAILED":
      return {
        ...state,
        DashboardError: true,
        isDashboardLoaded: false,
        DashboardErrorMessage: "Failed to Load Data",
      };
      case "NEW_Dashboard_loader":
        return{
          ...state,
          newDashboardLoader:true,
          newDashboardData:false,
          NewDashboardError:false,

        }
      
      case "NEW_Dashboard_SUCCESS":
        return{
          ...state,
          newDashboardData:true,
          newDashboardLoader:false,
          NewDashboardDetails:action.payload,
        };
        case "NEW_Dashboard_FAILED":
          return{
            ...state,
            NewDashboardError:true,
            newDashboardData:false,
            newDashboardLoader:false,
            NewDashboardErrorMessage:"Failed to Load Data",
          }
    case "SELLER_VIEW_SUCCESS":
      const role=localStorage.getItem("role")
      let newAddedSchedule = {};
      if(role==="Store Manager")
     { let { holidays } = action?.payload?.data?.retailerdetails?.stores[0];
       newAddedSchedule = { ...action.payload.data.retailerdetails };
	// holidays.splice(key, 1,key);
	newAddedSchedule.stores[0].holidays =[...new Set(holidays)];}
      return {
        ...state,
        isDataLoaded: true,
        retailerDetails: {data:{
          retailerdetails:{
            ...newAddedSchedule,
          },
        }
      },
      };
    case "SELLER_VIEW_FAILED":
      return {
        ...state,
        NetworkError: true,
        isDataLoaded: false,
        ErrorMessage: "Failed to Load Data",
      };
      case "CLEAR_PROFILE_URL":
        return {
          ...state,
          profileUploadSuccess:false,
        }
    case "MASTER_DEPARTMENTS_VIEW_SUCCESS":
      return {
        ...state,
        isMasterDepartmentLoaded: true,
        MasterDepartments: action.payload,
      };
    case "MASTER_DEPARTMENTS_VIEW_FAILED":
      return {
        ...state,
        MasterDepartmentNetworkError: true,
        isMasterDepartmentLoaded: false,
        MasterDepartmentErrorMessage: "Failed to Load Data",
      };
    case "MASTER_DEPARTMENTS_VIEW_SUCCESS":
      return {
        ...state,
        isMasterCategoryLoaded: true,
        MasterCategory: action.payload,
      };
    case "MASTER_CATEGORY_VIEW_FAILED":
      return {
        ...state,
        MasterCategoryNetworkError: true,
        isMasterCategoryLoaded: false,
        MasterCategoryErrorMessage: "Failed to Load Data",
      };

    case "ORDER_VIEW_SUCCESS":
      return {
        ...state,
        isOrderLoaded: true,
        Alerts: action.payload,
      };
      case "CLEAR_ALERTORDERS":
        return{
          ...state,
          isOrderLoaded:false,
        }
    case "ORDER_VIEW_FAILED":
      return {
        ...state,
        isOrderLoaded:false,
        NetworkOrder: true,
      };
    case "CRITICALORDER_VIEW_LOADING":
      return {
        ...state,
        isActiveOrder: false,
        criticalLoading: true,
      };
    case "CRITICALORDER_VIEW_SUCCESS":
      return {
        ...state,
        isActiveOrder: true,
        criticalLoading: false,
        ActiveOrder: action.payload,
      };
    case "CRITICALORDER_VIEW_FAILED":
      return {
        ...state,
        isActiveOrder: false,
        criticalLoading: false,
        NetworkCriticalOrder: true,
      };
    case "ORDER_NOTIFICATION_SUCCESS":
      return {
        ...state,
        isOrderNotification: true,
        OrderNotification: action.payload,
      };
    case "ORDER_NOTIFICATION_FAILED":
      return {
        ...state,
        isOrderNotification: false,
        NetworkOrderNotification: true,
      };
      case "ORDER_NOTIFICATION_SEEN_SUCCESS":
        return{
          ...state,
          isOrderNotificationSeen:true,
          
        };
        case "ORDER_NOTIFICATION_SEEN_FAILED":
          return{
            ...state,
            isOrderNotificationSeen:false
          }
      case "LAST_STATUS_CHANGED_SUCCESSFULLY":
        return{
          ...state,
          lastStatusChangedSuccess:true,
          lastStatusChangedAllSuccess:false,
          lastStatusChangedfail:false,
          lastStatusMessage:action.payload,
        };
        case "LAST_STATUS_CHANGED_FAILED":
          return{
            ...state,
            lastStatusChangedSuccess:false,
            lastStatusChangedAllFail:false,
            lastStatusChangedfail:true,
            lastStatusMessage:action.payload,
          }
          case "LAST_STATUS_CHANGED_ALL_SUCCESSFULLY":
            return{
              ...state,
              lastStatusChangedAllSuccess:true,
              lastStatusChangedSuccess:false,
              lastStatusChangedfail:false,
              lastStatusChangedAllFail:false,
            }
            case "LAST_STATUS_CHANGED_ALL_FAILED":
              return{
                ...state,
                lastStatusChangedAllSuccess:false,
                lastStatusChangedAllFail:true,
                lastStatusChangedfail:false,

              }
              case "CLEAR_SCENCE_STATUS":
                return{
                  ...state,
                  lastStatusChangedAllFail:false,
                  lastStatusChangedAllSuccess:false,
                  lastStatusChangedSuccess:false,
                  lastStatusChangedfail:false,
                }
    case "SELLER_WORKING_SUCCESS":
      return {
        ...state,
        isData: true,
        storeworkingState:true,
        retailerWorking: action.payload,
      };
    case "SELLER_WORKING_FAILED":
      return {
        ...state,
        isData: false,
        storeworkingState:false,
        // WorkingError: action.payload
      };
    case "SELLER_WORKING_NETWORK":
      return {
        ...state,
        isData: false,
        storeworkingState:false,
        WorkingError: action.payload,
      };
    case "DEPARTMENT_LIST_SUCCESS":
      return {
        ...state,
        isDepartmentLoaded: true,
        DepartmentList: action.payload,
      };
    case "DEPARTMENT_LIST_FAILED":
      return {
        ...state,
        isDepartmentLoaded: false,
        ErrorMessage: "Failed to Load Data",
      };
    case "CATEGORY_LIST_SUCCESS":
      return {
        ...state,
        isCategoryLoaded: true,
        CategoryList: action.payload,
      };
    case "CATEGORY_LIST_FAILED":
      return {
        ...state,
        isCategoryLoaded: false,
        CategoryList: action.payload,
      };
    case "CATEGORY1_LIST_SUCCESS":
      return {
        ...state,
        isCategory1Loaded: true,
        Category1List: action.payload,
      };
    case "CATEGORY1_LIST_FAILED":
      return {
        ...state,
        isCategory1Loaded: false,
        Category1List: action.payload,
      };
    case "CATEGORY2_LIST_SUCCESS":
      return {
        ...state,
        isCategory2Loaded: true,
        Category2List: action.payload,
      };
    case "CATEGORY2_LIST_FAILED":
      return {
        ...state,
        isCategory2Loaded: false,
        Category2List: action.payload,
      };
    case "CLEAR_CATEGORIES":
      return {
        ...state,
        isCategory2Loaded: false,
        Category2List: null,
        isCategory1Loaded: false,
        Category1List: null,
        isCategoryLoaded: false,
        CategoryList: null,
      };
    case "SELLER_VIEW_ERROR":
      return {
        ...state,
        isDataLoaded: false,
        ErrorMessage: "Failed to Load Data",
      };
    case "TEXT_EDIT_CHANGE":
      return {
        ...state,
        retailerDetails: action.payload,
      };
    case "TEXT_STORE_CHANGE":
      return {
        ...state,
        retailerDetails: action.payload,
      };
    case "SET_URL":
      return {
        ...state,
        url: action.payload.data.url,
      };
    case "Product_Search":
      return {
        ...state,
        Products: action.payload,
      };
    case "Catalog_Product_Search":
      return {
        ...state,
        CatalogProducts: action.payload.params,
      };
      case "UPDATE_STATUS_SUCCESS": {
        return {
          ...state,
          ProductCouponsLoading: false,
          ProductCouponssuccess: true,
          ProductCouponsFailed: false,
        };
      }
    // case "UPDATE_STATUS_SUCCESS":
    //   const { productstatus, id } = action.payload;
    //   const ProductStatus = productstatus ? "active" : "inactive";
    //   let indexOfObject = state.searchProductData.data.searchProducts.items.findIndex(obj => obj.id === id);
    //   let foundObject = state.searchProductData.data.searchProducts.items.find(obj => obj.id === id);
    //   console.log("FOUND_OBJECT",foundObject)
    //   foundObject.status = ProductStatus;
    //   return {
    //     ...state,
    //     ProductStatusUpdateSuccess: true,
    //     searchProductData: {
    //       data: {
    //         searchProducts: { 
    //           items:[
    //             ...state.searchProductData.data.searchProducts.items.slice(0, indexOfObject),
    //             foundObject,
    //             ...state.searchProductData.data.searchProducts.items.slice(indexOfObject + 1)
    //           ]
    //         },
    //       },
    //     },
    //     Products: {
    //       newProducts: {
    //         params: { 
    //         items: [
    //           ...state.searchProductData.data.searchProducts.items.slice(0, indexOfObject),
    //           foundObject,
    //           ...state.searchProductData.data.searchProducts.items.slice(indexOfObject + 1)
    //         ]
    //       },
    //     },
    //   }
    // }
    case "UPDATE_PRODUCT_STATUS_SUCCESS":{
    return{
      ...state,
      allProductStatus: action.payload,
    }
    }
    case "Order_Search": {
      return {
        ...state,
        Orders: action.payload,
      };
    }
    case "CHECKBOX_EDIT": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "MASTER_CHECK": {
      return {
        ...state,
        MasterproductCheck: action.payload.params,
      };
    }

    case "BXGY_CHECK": {
      return {
        ...state,
        BXGYCheck: action.payload.params,
      };
    }
    case "ADDING_CATALOG_PRODUCTS":
      return {
        ...state,
        addDCatalogProducts: true,
        addCatalogProductSuccess: false,
      };
    case "ADD_CATALOG_PRODUCTS_FAILURE":
      return {
        ...state,
        addDCatalogProducts: false,
        CatalogProductError: action.payload,
        addCatalogProductSuccess: true,
      };
    case "ADD_CATALOG_PRODUCTS_SUCCESS":
      return {
        ...state,
        addDCatalogProducts: false,
        CatalogProductData: action.payload,
        addCatalogProductSuccess: false,
      };
    case "HOLIDAY_SLOT_EDIT":
      return {
        ...state,
        retailerDetails: action.payload,
      };
    case "SCHEDULE_EDIT":
      return {
        ...state,
        retailerDetails: action.payload,
      };
    case "LOADING_NEWIMAGE_UPLOAD":
      return {
        ...state,
        newImageLoading: true,
      };
    case "NEWIMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: true,
        newSignedUrl: action.payload.signS3.Location,
        fileListLogo: false,
      };
    case "GSTEDIT_UPLOAD_SUCCESS":
      return {
        ...state,
        GSTImageLoading: false,
        GSTImageUploaded: true,
        GSTEditUrl: action.payload.imageEditAndUpload.Location,
      };
    case "GSTEDIT_UPLOAD_FAILURE":
      return {
        ...state,
        GSTImageLoading: false,
        GSTImageFailed: true,
      };
    case "LOGOIMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        newImageLoading: false,
        newLogoImageUploaded: true,
        newSignedLogoUrl: action.payload.imageEditAndUpload.Location,
        fileListLogo: false,
      };
      case "CLEAR_LOGO_URL":
  return {
    ...state,
    newSignedLogoUrl: null
  };

    case "LOGO_DELETE_SUCCESS":
      return {
        ...state,
        // newSignedLogoUrl: null,
        fileListLogo: false,
      };
    case "LOGO_DELETE_NFAILURE":
      return {
        ...state,
        fileListLogo: false,
      };
    case "REMOVE_LOGO":
      return {
        ...state,
        fileListLogo: true,
        newSignedLogoUrl: null,
      };
    case "SET_MASTER":
      return {
        ...state,
        MasterProductDetailsSuccess: false,
      };
    case "DELETE_IMG":
      return {
        ...state,
        fileListLogo: false,
      };
    case "MULTIPLE_FILELIST":
      return {
        ...state,
        multiFile: true,
      };
    case "MULTIPLE_FILELIST_RESET":
      return {
        ...state,
        multiImage: null,
      };
    case "MULTIPLE_DELETE":
      return {
        ...state,
        multiImage: null,
      };
    case "MULTI_EDIT_DELETE":
      return {
        ...state,
        multiFile: false,
      };
    case "SINGLE_DELETE": {
      return {
        ...state,
        proImage: null,
        newSignedUrl: false,
        masterUrl: null,
      };
    }
    case "NEW_MULTI_FILE": {
      return {
        ...state,
        newMultiFile: action.payload,
      };
    }
    case "MULTIPLE_UPLOADEDIT_SUCCESS":
      return {
        ...state,
        multipleEditImageLoading: false,
        multipleEditImageUploaded: true,
        multipleEditSignedUrl: action.payload,
        multiFileEdit: true,
        multiFileEdited: true,
        multiFile: false,
        multiEditImage: action.payload,
      };
    case "MULTIPLE_UPLOADEDIT_FAILURE":
      return {
        ...state,
        multipleEditImageLoading: false,
        multipleEditImageUploaded: false,
        multipleEditfailed: true,
        multipleEditError: action.payload,
      };
    case "MULTIPLE_UPLOAD_SUCCESS":
      return {
        ...state,
        multipleImageLoading: false,
        multipleImageUploaded: true,
        multipleSignedUrl: action.payload,
        multiFile: false,
        multiImage: action.payload,
      };
    case "MULTIPLE_UPLOAD_FAILURE":
      return {
        ...state,
        multipleImageLoading: false,
        multipleImageUploaded: false,
        multiplefailed: true,
        multipleError: action.payload,
      };

    case "IMAGE_DELETE_NFAILURE":
      return {
        ...state,
        network: true,
      };
    case "NEWIMAGE_UPLOAD_FAILURE":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: false,
        newImageFailed: true,
        newUrlError: action.payload,
      };
    case "NEWTHUMBNAIL_UPLOAD_SUCCESS":
      return {
        ...state,
        newthumbnailLoading: false,
        newthumbnailUploaded: true,
        thumbnailImage: action.payload,
        newthumbnailUrl: action.payload,
        newthumbnailFailed: false,
      };

    case "NEWTHUMBNAIL_UPLOAD_FAILURE":
      return {
        ...state,
        newthumbnailLoading: false,
        newthumbnailUploaded: false,
        newthumbnailFailed: true,
        newthumbnailUrlError: action.payload,
      };
    case "PROIMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: true,
        proImage: action.payload,
        newSignedUrl: action.payload,
        fileListLogo: false,
        newImageFailed: false,
      };

    case "PROIMAGE_UPLOAD_FAILURE":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: false,
        newImageFailed: true,
        newUrlError: action.payload,
      };
    case "CHANGED_TO_REJECTED":
      return {
        ...state,
        rejected: true,
        rejectedData:action.payload
      };
      case "REJECTION_FAILED":
        return{
          ...state,
          rejected:false,
          
        }
    case "PRODUCTIMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: true,
        proImage: action.payload,
        newSignedUrl: action.payload,
        fileListLogo: false,
        newImageFailed: false,
        masterUrl: null,
      };

    case "PRODUCTIMAGE_UPLOAD_FAILURE":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: false,
        newImageFailed: true,
        newUrlError: action.payload,
      };
      case "ORDERIMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        orderImageLoading: false,
        orderImageUploaded: true,
        proImage: action.payload,
        newSignedUrl: action.payload,
        fileListLogo: false,
        orderImageFailed: false,
        masterUrl: null,
      };

    case "ORDERIMAGE_UPLOAD_FAILURE":
      return {
        ...state,
        orderImageLoading: false,
        orderImageUploaded: false,
        orderImageFailed: true,
        orderUrlError: action.payload,
      };
    case "THUMBNAIL_UPLOAD_SUCCESS":
      return {
        ...state,
        thumbnailLoading: false,
        thumbnailUploaded: true,
        Thumbnail: action.payload,
        thumbnailUrl: action.payload,
        thumbnailFailed: false,
      };

    case "THUMBNAIL_UPLOAD_FAILURE":
      return {
        ...state,
        thumbnailLoading: false,
        thumbnailUploaded: false,
        thumbnailErrorUrl: action.payload,
        thumbnailFailed: true,
      };
    case "NEW_IMAGE_URL": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "EDIT_LOCATION": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "NEW_COORDINATES": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "NEW_DELIVERY_DETAIL": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "NEW_MAX_DISTANCE": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "NEW_EDIT_CHARGES": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "RESET_DETAILS": {
      return {
        ...state,
        retailerDetails: action.payload,
      };
    }
    case "EDIT_RETAILER_SUCCESS": {
      return {
        ...state,
        retailerDetails: {
          data: { retailerdetails: action.payload.updateretailer },
        },
        editRetailerSuccess: true,
        editRetailerFailed: false,
      };
    }
    case "EDIT_RETAILER_FAILED": {
      return {
        ...state,
        editRetailerSuccess: false,
        editRetailerFailed: true,
      };
    }
    case "EDIT_STORE_SUCCESS": {
      return {
        ...state,
        editStoreSuccess: true,
        editStoreFailed: false,
      };
    }
      case "CLEAR_EDITSTORE":{
        return{
          ...state,
          editStoreSuccess:false,
          editStoreFailed:false,
        };
      }
    case "EDIT_STORE_FAILED": {
      return {
        ...state,
        editStoreSuccess: false,
        editStoreFailed: true,
        editStoreMessage: action.payload,
      };
    }

    case "PASSWORD_RESET_SUCCESS": {
      return {
        ...state,
        passwordResetSuccess: true,
        passwordResetFailed: false,
      };
    }
    case "PASSWORD_RESET_FAILED": {
      return {
        ...state,
        passwordResetSuccess: false,
        passwordResetFailed: true,
        passErrMsg: action.payload,
      };
    }
    case "PASSWORD_FAILED": {
      return {
        ...state,
        passwordResetSuccess: false,
        passwordResetFailed: true,
        passErrMsg: action.payload,
      };
    }
    case "CLEAR_RESET_FAILED":{
      return{
        ...state,
        passwordResetFailed:false,
        passwordResetSuccess:false,
      }
    }
    case "CLOSE_SNACKBARS": {
      return {
        ...state,
        editRetailerSuccess: false,
        editStoreSuccess: false,
        editRetailerFailed: false,
        editStoreFailed: false,
        passwordResetSuccess: false,
        passwordResetFailed: false,
        shopassistantregistration: false,
        shopassistantFailed: false,
        UpdateError: false,
        HeirarchyError: false,
        addSuccess: false,
        updateSuccess: false,
        productUpdate: false,
        productUpdateFailed: false,
        productAdded: false,
        productAddFail: false,
        isData: false,
        newImageUploaded: false,
        newLogoImageUploaded: false,
        newImageFailed: false,
        multipleImageUploaded: false,
        multiplefailed: false,
        searchSuccess: false,
        multiFileEdit: false,
        GSTImageUploaded: false,
        GSTImageFailed: false,
        addCatalogProductSuccess: false,
        MasterproductCheck: [],
        percentagecouponsuccess: false,
        percentagecouponFailed: false,
        Flatcouponsuccess: false,
        FlatcouponFailed: false,
        Deliverycouponsuccess: false,
        DeliverycouponFailed: false,
        AssigningCouponssuccess: false,
        AssigningCouponsFailed: false,
        EditingCouponssuccess: false,
        EditingCouponsFailed: false,
        BXGYCouponssuccess: false,
        BXGYCouponsFailed: false,
        BXGYCheck: [],
      };
    }
    case "SEARCH_ORDER_LOADING": {
      return {
        ...state,
        searchingOrder: true,
      };
    }
    case "SET_CRITERIA": {
      return {
        ...state,
        criteria: {
          from: 0,
          first: action.payload.from + 20,
          storeid: action.payload.storeid,
          department: action.payload.department,
          category: action.payload.category,
          category1: action.payload.category1,
          category2: action.payload.category2,
          status: action.payload.status,
          key: action.payload.key,
        },
      };
    }
    case "SEARCH_SUCCESS": {
      return {
        ...state,
        searchingOrder: false,
        searchSuccess: true,
        searchData: action.payload.data,
      };
    }
    case "SEARCH_PRODUCT_LOADING": {
      return {
        ...state,
        searchLoading: true,
        searchProductSuccess: false,
      };
    }
    case "SEARCH_PRODUCT_SUCCESS": {
      return {
        ...state,
        searchLoading: false,
        searchProductSuccess: true,
        searchProductData: action.payload,
      };
    }
    case "SEARCH_PRODUCT_LOADING": {
      return {
        ...state,
        searchLoading: true,
        searchProductSuccess: false,
      };
    }
    case "PRODUCT_SEARCH_LOADING": {
      return {
        ...state,
        searchLoading: true,
        searchProductSuccess: false,
      };
    }
    case "PRODUCT_SEARCH_SUCCESS": {
      return {
        ...state,
        searchLoading: false,
        searchProductSuccess: true,
        searchProductData: action.payload,
      };
    }
    case "PRODUCT_DETAILS_SUCCESS": {
      return {
        ...state,
        ProductDetailsSuccess: true,
        ProductDetailsData: action.payload,
      };
    }
    case "PRODUCT_DETAILS_FAILED": {
      return {
        ...state,
        ProductDetailsFailed: true,
      };
    }
    case "MASTER_PRODUCT_DETAILS_LOADING": {
      return {
        ...state,
        MasterProductDetailsSuccess: false,
      };
    }
    case "MASTER_PRODUCT_DETAILS_SUCCESS": {
      return {
        ...state,
        MasterProductDetailsSuccess: true,
        MasterProductDetailsData: action.payload,
      };
    }
    case "MASTER_PRODUCT_DETAILS_FAILED": {
      return {
        ...state,
        MasterProductDetailsFailed: true,
      };
    }
    case "SET_REJECT": {
      return {
        ...state,
        RejectClick: action.payload,
      };
    }

    case "SEARCH_FAILED": {
      return {
        ...state,
        searchFailed: true,
      };
    }
    case "SEARCH_ERROR": {
      return {
        ...state,
        searchFailed: true,
      };
    }
    case "SEARCH_DETAILS_SUCCESS": {
      return {
        ...state,
        searchDetailsSuccess: true,
        searchDetails: action.payload.data.orderdetails,
        rejected: false,
      
      };
    }
    case "SET_ORDER_DETAILS": {
      return {
        ...state,
        searchDetailsSuccess: true,
        searchDetails: action.payload,
        rejected: false,
      };
    }
    case "SEARCH_DETAILS_FAILED": {
      return {
        ...state,
        searchDetailsFailed: true,
        searchDetailsSuccess: false,
        loader:true
      };
    }
    case "SEARCH_DETAILS_ERROR": {
      return {
        ...state,
        searchDetailsFailed: true,
        searchDetailsSuccess: false,
      };
    }
    case "LOGOUT_SUCCESS": {
      return {
        ...state,
        logout: true,
        searchProductData: null,
        searchData: null,
        searchProductSuccess: false,
        searchSuccess: false,
        Products: [],
        Orders: [],
      };
    }
    case "LOGOUT_FAILED": {
      return {
        ...state,
        logout: false,
      };
    }
    case "LOGOUT_NETWORK_ERROR": {
      return {
        ...state,
        logout: false,
      };
    }
    case "UPDATING_PRODUCTS": {
      return {
        ...state,
        proupdating: true,
      };
    }
    case "UPDATE_PRODUCT_SUCCESS": {
      return {
        ...state,
        productUpdate: true,
        productUpdateFailed: false,
        ProductUpdateData: action.payload,
        proupdating: false,
        multiFileEdit: false,
        multiFileEdited: false,
        newMultiFile: null,
      };
    }
    case "CLEAR_UPDATE_PRODUCT": {
      return {
        ...state,
        productUpdate: false,
        productUpdateFailed: false,
      };
    }
    case "UPDATE_PRODUCT_FAILED": {
      return {
        ...state,
        productUpdate: false,
        productUpdateFailed: true,
        ProductUpadteError: action.payload,
        proupdating: false,
        newMultiFile: null,
      };
    }
    case "ADDING_PRODUCTS": {
      return {
        ...state,
        addingProduct: true,
      };
    }
    case "ADD_PRODUCT_SUCCESS": {
      return {
        ...state,
        productAdded: true,
        addingProduct: false,
        ProductData: action.payload,
        proImage: null,
        multiImage: null,
        fileListLogo: false,
        multiFile: false,
        MasterProductDetailsData: null,
        multipleSignedUrl: null,
        thumbnailUrl: null,
      };
    }
    case "CLEAR_ADD_PRODUCT": {
      return {
        ...state,
        productAdded: false,
        addingProduct: false,
      };
    }
    case "CREATING_MASTER_PRODUCTS": {
      return {
        ...state,
        MasteraddingProduct: true,
      };
    }
    case "CREATING_MASTER_SUCCESS": {
      return {
        ...state,
        MasterproductAdded: true,
        MasteraddingProduct: false,
        MasterProductData: action.payload,
        proImage: null,
        multiImage: null,
        fileListLogo: false,
        multiFile: false,
      };
    }

    case "CREATING_MASTER_FAILED": {
      return {
        ...state,
        addDep: false,
        MasterproductAdded: false,
        MasterproductAddFail: true,
        MasterProductErrorData: action.payload,
        proImage: null,
        multiImage: null,
        fileListLogo: false,
        multiFile: false,
      };
    }
    case "ADDING_DEPARTMENT": {
      return {
        ...state,
        addDep: true,
      };
    }
    case "DELETE_PRODUCT_SUCCESS":{
      return{
        ...state,
        deleteProductSuccess:true,
        deleteProductFailed:false,
        DeleteProductSuccessData:action.payload,
      };
    }
    case "DELETE_PRODUCT_FAILED":{
      return{
        ...state,
        deleteProductSuccess:false,
        deleteProductFailed:true,
        DeleteProductFailedData:action.payload,
      };
    }
    case "SET_IMG": {
      return {
        ...state,
        masterUrl: action.payload.params,
        fileListLogo: false,
      };
    }
    case "ADD_PRODUCT_FAILED": {
      return {
        //
        ...state,
        addDep: false,
        addingProduct: false,
        productAdded: false,
        productAddFail: true,
        ProductErrorData: action.payload,
        multiImage: null,
        fileListLogo: false,
        multiFile: false,
        MasterProductDetailsData: null,
        multipleSignedUrl: null,
        thumbnailUrl: null,
        proImage: null,
      };
    }
    case "ADD_DEPARTMENT_SUCCESS": {
      return {
        ...state,
        addDep: false,
        HeirarchyData: action.payload,
        addSuccess: true,
      };
    }
    case "ADD_DEPARTMENT_FAILED": {
      return {
        ...state,
        HeirarchyError: true,
        HeirarchyErrorData: action.payload,
      };
    }
    case "UPDATING_DEPARTMENT": {
      return {
        ...state,
        updateDep: true,
      };
    }
    case "UPDATE_DEPARTMENT_SUCCESS": {
      return {
        ...state,
        updateDep: false,
        UpdateDepartment: action.payload,
        updateSuccess: true,
        Successload: false,
      };
    }
    case "UPDATE_DEPARTMENT_FAILED": {
      return {
        ...state,
        updateDep: false,
        UpdateError: true,
        UpdateDepartmentError: action.payload,
        updateSuccess: false,
      };
    }
    case "SHOPASSISTANT_REGISTRATION_SUCCESS": {
      return {
        ...state,
        shopassistantregistration: true,
        shopassistantregistrationwaiting: false,
        shopassistantFailed: false,
        createassistant: action.payload,
      };
    }
    case "SHOPASSISTANT_REGISTRATION_FAILURE": {
      return {
        ...state,
        shopassistantregistration: false,
        shopassistantFailed: true,
        shopassistantregistrationwaiting: false,
        createerror: action.payload,
      };
    }
    case "CLEAR_CREATE_SHOPASSISTANT":{
      return{
        ...state,
        shopassistantregistration:false,
        shopassistantFailed:false,
       
      }
    }
    case 'SHOPASSISTANT_REGISTERING':{
      return{
        ...state,
        shopassistantregistration:false,
        shopassistantFailed:false,
      
      }
    }
    case "EDIT_SHOPASSISTANT_SUCCESS": {
      return {
        ...state,
        editShopassistantregistration: true,
        editShopassistantregistrationwaiting: false,
        editShopassistantFailed: false,
        editCreateassistant: action.payload,
      };
    }
    case "EDIT_SHOPASSISTANT_FAILURE": {
      return {
        ...state,
        editShopassistantregistration: false,
       editShopassistantFailed: true,
        editShopassistantregistrationwaiting: false,
        editerror: action.payload,
      };
    }
    case "CLEAR_EDIT_SHOPASSISTANT":{
      return{
        ...state,
        editShopassistantregistration:false,
      }
    }
    case "DELETE_SHOPASSISTANT_SUCCESS":{
      return{
        ...state,
        deleteShopAssistantSuccess:true,
        deleteShopAssistantFailed:false,
        DeleteShopAssistantSuccessData:action.payload,
      };
    }
    case "CLEAR_DELETE_SHOPASSISTANT":{
      return{
        ...state,
        deleteShopAssistantSuccess:false,
      }
    }
    case "DELETE_SHOPASSISTANT_FAILURE":{
      return{
        ...state,
        deleteShopAssistantSuccess:false,
        deleteShopAssistantFailed:true,
        DeleteShopASSistantFailedData:action.payload,
      };
    }
    case "SHOPASSISTANT_LIST_SUCCESS": {
      return {
        ...state,
        shopassistantlist: true,
        shopassistantloading:false,
        deleteShopAssistantSuccess:false,
        shopassistantlistingwaiting: false,
        Shopassistantlist: action.payload,
      };
    }
    case "SHOPASSISTANT_LIST_LOADING":{
      return {
        ...state,
        shopassistantlist:false,
        shopassistantloading:true,
      }
    }

    case "SHOPASSISTANT_ORDERS_SUCCESS": {
      return {
        ...state,
        shopassistantorderlist: true,
        shopassistantlistingwaiting: false,
        
        shopassistanOrderLoading:false,
        ShopassistantOrderslist: action.payload,
      };
    }
    case "SHOPASSISTANT_ORDERS_LOADING":{
      return{
        ...state,
        shopassistanOrderLoading:true,
        shopassistantorderlist:false,

      }
    }
    case "SHOPASSISTANT_ORDERS_FAILURE": {
      return {
        ...state,
        shopassistantorderlist: false,
        shopassistanOrderLoading:false,
        shopassistantlistnwaiting: false,
        ShopassistantOrderslist: action.payload,
      };
    }
    case "SHOPASSISTANT_COMPLETED_ORDERS_SUCCESS":{
      return{
        ...state,
        shopassistantCompletedOrderList: true,
        shopassistantCompletedOrderLoading:false,
        shopassistantCompletedOrderListWaiting:false,
        ShopassistantCompletedOrderslist:action.payload,
      }
    }
    case "SHOPASSISTANT_COMPLETED_ORDER_LOADING":{
      return {
        ...state,
        shopassistantCompletedOrderLoading:true,
        shopassistantCompletedOrderList:false,
        
      }
    }
    case "SHOPASSISTANT_COMPLETED_ORDERS_FAILURE":{
      return{
        ...state,
        shopassistantCompletedOrderList: false,
        shopassistantCompletedOrderLoading:false,
        shopassistantCompletedOrderListWaiting:false,
        ShopassistantCompletedOrderslist:action.payload,

      }
    }
    case "ORDERLIST_SUCCESS": {
      return {
        ...state,
        isOrderList: true,
        orderDetails: action.payload,
      };
    }
    case "ORDERLIST_FAILURE": {
      return {
        ...state,
        isOrderList: false,
        orderDetails: action.payload,
      };
    }
    case "ASSIGN_SUCCESS": {
      return {
        ...state,
        isAssigned: true,
        AssignSuccess: true,
      };
    }
    case "ASSIGN_CLEAR":{
      return{
        ...state,
        isAssigned:false,
      }
    }
    case "ASSIGN_FAILURE": {
      return {
        ...state,
        isAssigned: false,
        AssignSuccess: false,
      };
    }
    case "ASSIGN_PARTNER_SUCCESS": {
      return {
        ...state,
        isAssignedPartner: true,
        AssignPartnerSuccess: true,
      };
    }
    case "ASSIGN_PARTNER_FAILURE": {
      return {
        ...state,
        isAssignedPartner: false,
        AssignPartnerSuccess: false,
      };
    }
    case "STORE_WORKING_PENDING": {
      return {
        ...state,
        isstoreWorking: true,
      };
    }
    case "STORE_WORKING_SUCCESS": {
      return {
        ...state,
        isstoreWorking: true,
        WorkingData: action.payload,
      };
    }
    case "STORE_WORKING_ERROR": {
      return {
        ...state,
        isstoreWorking: false,
        StoreError: action.payload,
      };
    }
    case "GSTChange":
      return {
        ...state,
        gstFile: action.payload,
        GSTEditUrl: null,
      };

		// case "NEW_IMAGE_URL": {
		// 	return {
		// 		...state,
		// 		retailerDetails: action.payload,
		// 	};
		// }
		// case "EDIT_LOCATION": {
		// 	return {
		// 		...state,
		// 		retailerDetails: action.payload,
		// 	};
		// }
		// case "NEW_COORDINATES": {
		// 	return {
		// 		...state,
		// 		retailerDetails: action.payload,
		// 	};
		// }
		// case "NEW_DELIVERY_DETAIL": {
		// 	return {
		// 		...state,
		// 		retailerDetails: action.payload,
		// 	};
		// }
		// case "NEW_MAX_DISTANCE": {
		// 	return {
		// 		...state,
		// 		retailerDetails: action.payload,
		// 	};
		// }
		// case "NEW_EDIT_CHARGES": {
		// 	return {
		// 		...state,
		// 		retailerDetails: action.payload,
		// 	};
		// }
		// case "RESET_DETAILS": {
		// 	return {
		// 		...state,
		// 		retailerDetails: action.payload,
		// 	};
		// }
		// case "EDIT_RETAILER_SUCCESS": {
		// 	return {
		// 		...state,
		// 		retailerDetails: {
		// 			data: { retailerdetails: action.payload.updateretailer },
		// 		},
		// 		editRetailerSuccess: true,
		// 		editRetailerFailed: false,
		// 	};
		// }
		// case "EDIT_RETAILER_FAILED": {
		// 	return {
		// 		...state,
		// 		editRetailerSuccess: false,
		// 		editRetailerFailed: true,
		// 	};
		// }
		// case "EDIT_STORE_SUCCESS": {
		// 	return {
		// 		...state,
		// 		editStoreSuccess: true,
		// 		editStoreFailed: false,
		// 	};
		// }
		// case "EDIT_STORE_FAILED": {
		// 	return {
		// 		...state,
		// 		editStoreSuccess: false,
		// 		editStoreFailed: true,
		// 		editStoreMessage:action.payload
		// 	};
		// }

		// case "PASSWORD_RESET_SUCCESS": {
		// 	return {
		// 		...state,
		// 		passwordResetSuccess: true,
		// 		passwordResetFailed: false,
		// 	};
		// }
		// case "PASSWORD_RESET_FAILED": {
		// 	return {
		// 		...state,
		// 		passwordResetSuccess: false,
		// 		passwordResetFailed: true,
		// 		passErrMsg: action.payload
		// 	}
		// }
		// case "PASSWORD_FAILED": {
		// 	return {
		// 		...state,
		// 		passwordResetSuccess: false,
		// 		passwordResetFailed: true,
		// 		passErrMsg: action.payload
		// 	}
		// }
		// case "CLOSE_SNACKBARS": {
		// 	return {
		// 		...state,
		// 		editRetailerSuccess: false,
		// 		editStoreSuccess: false,
		// 		editRetailerFailed: false,
		// 		editStoreFailed: false,
		// 		passwordResetSuccess: false,
		// 		passwordResetFailed: false,
		// 		shopassistantregistration: false,
		// 		shopassistantFailed: false,
		// 		UpdateError: false,
		// 		HeirarchyError: false,
		// 		addSuccess: false,
		// 		updateSuccess: false,
		// 		productUpdate: false,
		// 		productUpdateFailed: false,
		// 		productAdded: false,
		// 		productAddFail: false,
		// 		isData: false,
		// 		newImageUploaded: false,
		// 		newLogoImageUploaded: false,
		// 		newImageFailed: false,
		// 		multipleImageUploaded: false,
		// 		multiplefailed: false,
		// 		searchSuccess: false,
		// 		multiFileEdit: false,
		// 		GSTImageUploaded: false,
		// 		GSTImageFailed: false,
		// 		addCatalogProductSuccess: false,
		// 		MasterproductCheck: [],
		// 		percentagecouponsuccess: false,
		// 		percentagecouponFailed: false,
		// 		Flatcouponsuccess: false,
		// 		FlatcouponFailed: false,
		// 		Deliverycouponsuccess: false,
		// 		DeliverycouponFailed: false,
		// 		AssigningCouponssuccess: false,
		// 		AssigningCouponsFailed: false,
		// 		EditingCouponssuccess: false,
		// 		EditingCouponsFailed: false,
		// 		BXGYCouponssuccess: false,
		// 		BXGYCouponsFailed: false,
		// 		BXGYCheck: [],

		// 	};
		// }
		// case "SEARCH_ORDER_LOADING": {
		// 	return {
		// 		...state,
		// 		searchingOrder: true,
		// 	};
		// }
		// case "SET_CRITERIA": {
		// 	return {
		// 		...state,
		// 		criteria: {
		// 			from: 0,
		// 			first: action.payload.from + 20,
		// 			storeid: action.payload.storeid,
		// 			department: action.payload.department,
		// 			category: action.payload.category,
		// 			category1: action.payload.category1,
		// 			category2: action.payload.category2,
		// 			status: action.payload.status,
		// 			key: action.payload.key
		// 		}

		// 	}
		// }
		// case "SEARCH_SUCCESS": {
		// 	return {
		// 		...state,
		// 		searchingOrder: false,
		// 		searchSuccess: true,
		// 		searchData: action.payload.data,
		// 	};
		// }
		case "SEARCH_PRODUCT_LOADING": {
			return {
				...state,
				searchLoading: true,
				searchProductSuccess: false,
			};
		}
		case "SEARCH_PRODUCT_SUCCESS": {
			return {
				...state,
				searchLoading: false,
				searchProductSuccess: true,
				searchProductData: action.payload,
			};
		}
		case "SEARCH_PRODUCT_LOADING": {
			return {
				...state,
				searchLoading: true,
				searchProductSuccess: false,
			};
		}
		case "PRODUCT_SEARCH_LOADING": {
			return {
				...state,
				searchLoading: true,
				searchProductSuccess: false,
			};
		}
		case "PRODUCT_SEARCH_SUCCESS": {
			return {
				...state,
				searchLoading: false,
				searchProductSuccess: true,
				searchProductData: action.payload,
			};
		}
		case "PRODUCT_DETAILS_SUCCESS": {
			return {
				...state,
				ProductDetailsSuccess: true,
				ProductDetailsData: action.payload,
			};
		}
		case "PRODUCT_DETAILS_FAILED": {
			return {
				...state,
				ProductDetailsFailed: true,
			};
		}
		case "MASTER_PRODUCT_DETAILS_LOADING": {
			return {
				...state,
				MasterProductDetailsSuccess: false
			};
		}
		case "MASTER_PRODUCT_DETAILS_SUCCESS": {
			return {
				...state,
				MasterProductDetailsSuccess: true,
				MasterProductDetailsData: action.payload,
			};
		}
		case "MASTER_PRODUCT_DETAILS_FAILED": {
			return {
				...state,
				MasterProductDetailsFailed: true,
			};
		}
		// case "SET_REJECT": {
		// 	return {
		// 		...state,
		// 		RejectClick: action.payload,
		// 	};
		// }

		// case "SEARCH_FAILED": {
		// 	return {
		// 		...state,
		// 		searchFailed: true,
		// 	};
		// }
		// case "SEARCH_ERROR": {
		// 	return {
		// 		...state,
		// 		searchFailed: true,
		// 	};
		// }
		// case "SEARCH_DETAILS_SUCCESS": {
		// 	return {
		// 		...state,
		// 		searchDetailsSuccess: true,
		// 		searchDetails: action.payload.data.orderdetails,
		// 		rejected: false
		// 	};
		// }
		// case "SET_ORDER_DETAILS": {
		// 	return {
		// 		...state,
		// 		searchDetailsSuccess: true,
		// 		searchDetails: action.payload,
		// 		rejected: false
		// 	};
		// }
		// case "SEARCH_DETAILS_FAILED": {
		// 	return {
		// 		...state,
		// 		searchDetailsFailed: true,
		// 		searchDetailsSuccess: false,
		// 	};
		// }
		// case "SEARCH_DETAILS_ERROR": {
		// 	return {
		// 		...state,
		// 		searchDetailsFailed: true,
		// 		searchDetailsSuccess: false,
		// 	};
		// }
		// case "LOGOUT_SUCCESS": {
		// 	return {
		// 		...state,
		// 		logout: true,
		// 		searchProductData: null,
		// 		searchData: null,
		// 		searchProductSuccess: false,
		// 		searchSuccess: false,
		// 		Products: [],
		// 		Orders: []

		// 	};
		// }
		// case "LOGOUT_FAILED": {
		// 	return {
		// 		...state,
		// 		logout: false,

		// 	};
		// }
		// case "LOGOUT_NETWORK_ERROR": {
		// 	return {
		// 		...state,
		// 		logout: false,
		// 	};
		// }
		// case "UPDATING_PRODUCTS": {
		// 	return {
		// 		...state,
		// 		proupdating: true
		// 	};
		// }
		// case "UPDATE_PRODUCT_SUCCESS": {
		// 	return {
		// 		...state,
		// 		productUpdate: true,
		// 		productUpdateFailed: false,
		// 		ProductUpdateData: action.payload,
		// 		proupdating: false,
		// 		multiFileEdit: false,
		// 		multiFileEdited: false,
		// 		newMultiFile: null
		// 	};
		// }
		// case "UPDATE_PRODUCT_FAILED": {
		// 	return {
		// 		...state,
		// 		productUpdate: false,
		// 		productUpdateFailed: true,
		// 		ProductUpadteError: action.payload,
		// 		proupdating: false,
		// 		newMultiFile: null
		// 	};
		// }
		// case "ADDING_PRODUCTS": {
		// 	return {
		// 		...state,
		// 		addingProduct: true
		// 	}
		// }
		// case "ADD_PRODUCT_SUCCESS": {
		// 	return {
		// 		...state,
		// 		productAdded: true,
		// 		addingProduct: false,
		// 		ProductData: action.payload,
		// 		proImage: null,
		// 		multiImage: null,
		// 		fileListLogo: false,
		// 		multiFile: false,
		// 		MasterProductDetailsData: null,
		// 		multipleSignedUrl: null,
		// 		thumbnailUrl: null
		// 	};
		// }
		// case "CREATING_MASTER_PRODUCTS": {
		// 	return {
		// 		...state,
		// 		MasteraddingProduct: true
		// 	}
		// }
		// case "CREATING_MASTER_SUCCESS": {
		// 	return {
		// 		...state,
		// 		MasterproductAdded: true,
		// 		MasteraddingProduct: false,
		// 		MasterProductData: action.payload,
		// 		proImage: null,
		// 		multiImage: null,
		// 		fileListLogo: false,
		// 		multiFile: false
		// 	};
		// }

		// case "CREATING_MASTER_FAILED": {
		// 	return {
		// 		...state,
		// 		addDep: false,
		// 		MasterproductAdded: false,
		// 		MasterproductAddFail: true,
		// 		MasterProductErrorData: action.payload,
		// 		proImage: null,
		// 		multiImage: null,
		// 		fileListLogo: false,
		// 		multiFile: false
		// 	};
		// }
		// case "ADDING_DEPARTMENT": {
		// 	return {
		// 		...state,
		// 		addDep: true
		// 	};
		// }
		// case "SET_IMG": {
		// 	return {
		// 		...state,
		// 		masterUrl: action.payload.params,
		// 		fileListLogo: false
		// 	};
		// }
		// case "ADD_PRODUCT_FAILED": {
		// 	return {
		// 		// 
		// 		...state,
		// 		addDep: false,
		// 		addingProduct: false,
		// 		productAdded: false,
		// 		productAddFail: true,
		// 		ProductErrorData: action.payload,
		// 		multiImage: null,
		// 		fileListLogo: false,
		// 		multiFile: false,
		// 		MasterProductDetailsData: null,
		// 		multipleSignedUrl: null,
		// 		thumbnailUrl: null,
		// 		proImage: null
		// 	};
		// }
		// case "ADD_DEPARTMENT_SUCCESS": {
		// 	return {
		// 		...state,
		// 		addDep: false,
		// 		HeirarchyData: action.payload,
		// 		addSuccess: true,

		// 	};
		// }
		// case "ADD_DEPARTMENT_FAILED": {
		// 	return {
		// 		...state,
		// 		HeirarchyError: true,
		// 		HeirarchyErrorData: action.payload
		// 	};
		// }
		// case "UPDATING_DEPARTMENT": {
		// 	return {
		// 		...state,
		// 		updateDep: true
		// 	};
		// }
		// case "UPDATE_DEPARTMENT_SUCCESS": {
		// 	return {
		// 		...state,
		// 		updateDep: false,
		// 		UpdateDepartment: action.payload,
		// 		updateSuccess: true,
		// 		Successload: false

		// 	};
		// }
		// case "UPDATE_DEPARTMENT_FAILED": {
		// 	return {
		// 		...state,
		// 		updateDep: false,
		// 		UpdateError: true,
		// 		UpdateDepartmentError: action.payload,
		// 		updateSuccess: false,

		// 	};
		// }
		// case "SHOPASSISTANT_REGISTRATION_SUCCESS": {
		// 	return {
		// 		...state,
		// 		shopassistantregistration: true,
		// 		shopassistantregistrationwaiting: false,
		// 		shopassistantFailed: false,
		// 		createassistant: action.payload,
		// 	};
		// }
		// case "SHOPASSISTANT_REGISTRATION_FAILURE": {
		// 	return {
		// 		...state,
		// 		shopassistantregistration: false,
		// 		shopassistantFailed: true,
		// 		shopassistantregistrationwaiting: false,
		// 		error: action.payload,
		// 	};
		// }
		// case "SHOPASSISTANT_LIST_SUCCESS": {
		// 	return {
		// 		...state,
		// 		shopassistantlist: true,
		// 		shopassistantlistingwaiting: false,
		// 		Shopassistantlist: action.payload,
		// 	};
		// }
		// case "SHOPASSISTANT_ORDERS_SUCCESS": {
		// 	return {
		// 		...state,
		// 		shopassistantorderlist: true,
		// 		shopassistantlistingwaiting: false,
		// 		ShopassistantOrderslist: action.payload,
		// 	};
		// }
		// case "SHOPASSISTANT_ORDERS_FAILURE": {
		// 	return {
		// 		...state,
		// 		shopassistantorderlist: false,
		// 		shopassistantlistnwaiting: false,
		// 		ShopassistantOrderslist: action.payload,
		// 	};
		// }
		// case "GET_SHOPASSISATNT_ID" :{
		// 	return{
		// 		...state,
		// 		shopAssistantsID: action.payload,
				
		// 	}
		// }

		// case "ORDERLIST_SUCCESS": {
		// 	return {
		// 		...state,
		// 		isOrderList: true,
		// 		orderDetails: action.payload,
		// 	}
		// }
		// case "ORDERLIST_FAILURE": {
		// 	return {
		// 		...state,
		// 		isOrderList: false,
		// 		orderDetails: action.payload,
		// 	}
		// }
		// case "ASSIGN_SUCCESS": {
		// 	return {
		// 		...state,
		// 		isAssigned: true,
		// 		AssignSuccess: true
		// 	}
		// }
		// case "ASSIGN_FAILURE": {
		// 	return {
		// 		...state,
		// 		isAssigned: false,
		// 		AssignSuccess: false
		// 	}
		// }
		// case "ASSIGN_PARTNER_SUCCESS": {
		// 	return {
		// 		...state,
		// 		isAssignedPartner: true,
		// 		AssignPartnerSuccess: true
		// 	}
		// }
		// case "ASSIGN_PARTNER_FAILURE": {
		// 	return {
		// 		...state,
		// 		isAssignedPartner: false,
		// 		AssignPartnerSuccess: false
		// 	}
		// }
		// case "STORE_WORKING_PENDING": {
		// 	return {
		// 		...state,
		// 		isstoreWorking: true,
		// 	}
		// }
		// case "STORE_WORKING_SUCCESS": {
		// 	return {
		// 		...state,
		// 		isstoreWorking: true,
		// 		WorkingData: action.payload

		// 	}
		// }
		// case "STORE_WORKING_ERROR": {
		// 	return {
		// 		...state,
		// 		isstoreWorking: false,
		// 		StoreError: action.payload
		// 	}
		// }
		// case "GSTChange":
		// 	return {
		// 		...state,
		// 		gstFile: action.payload,
		// 		GSTEditUrl: null
		// 	};

		case "MASTER_DEPARTMENTS_VIEW_SUCCESS":
			return {
				...state,
				isMasterDepartmentLoaded: true,
				MasterDepartments: action.payload,
				MasterProducts: [],
				MasterproductCheck: []
			};
		case "MASTER_DEPARTMENTS_VIEW_FAILED":
			return {
				...state,
				MasterDepartmentNetworkError: true,
				isMasterDepartmentLoaded: false,
				MasterDepartmentErrorMessage: "Failed to Load Data",
			};
		case "MASTER_CATEGORY_VIEW_SUCCESS":
			return {
				...state,
				isMasterCategoryLoaded: true,
				MasterCategory: action.payload,
				MasterProducts: [],
				MasterproductCheck: []
			};
		case "MASTER_CATEGORY_VIEW_FAILED":
			return {
				...state,
				MasterCategoryNetworkError: true,
				isMasterCategoryLoaded: false,
				MasterCategoryErrorMessage: "Failed to Load Data",
			};
		case "MASTER_PRODUCTS_VIEW_SUCCESS":
			return {
				...state,
				isMasterProductsLoaded: true,
				MasterProducts: action.payload,
			};
		case "MASTER_PRODUCTS_VIEW_FAILED":
			return {
				...state,
				MasterProductsNetworkError: true,
				isMasterProductsLoaded: false,
				MasterProductsErrorMessage: "Failed to Load Data",
			};
		case "RESET_PRODUCTS":
			return {
				...state,
				searchProductSuccess: false,
				Products: []
			};
		case "Number of Delivery days":
			return {
				...state,
				DeliveryAfter: action.payload,
			};
		case "SameDayDeliverys":
			if (state.retailerDetails && state.retailerDetails.data && state.retailerDetails.data.retailerdetails && state.retailerDetails.data.retailerdetails.stores && state.retailerDetails.data.retailerdetails.stores.length > 0) {
        state.retailerDetails.data.retailerdetails.stores[0].sameDayDelivery = action.payload;
    }
			return {
				...state,
				SameDayDeliveryy: action.payload,
				retailerDetails:state.retailerDetails,
			};
		case "LISTALLCOUPONS_LOADING": {
			return {
				...state,
				AllCouponsLoading: true,
				AllCouponssuccess: false,
				AllCouponsFailed: false,
			};
		}
		case "LISTALLCOUPONS_SUCCESS": {
			return {
				...state,
				AllCouponsLoading: false,
				AllCouponssuccess: true,
				AllCouponsFailed: false,
				AllCoupons: action.payload,
			};
		}
		case "LISTALLCOUPONS_FAILED": {
			return {
				...state,
				AllCouponsLoading: false,
				AllCouponssuccess: false,
				AllCouponsFailed: true
			};
		}
    case "LISTALLUPCOMINGCOUPONS_LOADING": {
			return {
				...state,
				AllUpcomingCouponsLoading: true,
				AllUpcomingCouponssuccess: false,
				AllUpcomingCouponsFailed: false,
			};
		}
		case "LISTALLUPCOMINGCOUPONS_SUCCESS": {
			return {
				...state,
				AllUpcomingCouponsLoading: false,
				AllUpcomingCouponssuccess: true,
				AllUpcomingCouponsFailed: false,
				AllUpcomingCoupons: action.payload,
			};
		}
		case "LISTALLUPCOMINGCOUPONS_FAILED": {
			return {
				...state,
				AllUpcomingCouponsLoading: false,
				AllUpcomingCouponssuccess: false,
				AllUpcomingCouponsFailed: true
			};
		}


case "LISTALLONGOINGCOUPONS_LOADING": {
			return {
				...state,
				AllOngoingCouponsLoading: true,
				AllOngoingCouponssuccess: false,
				AllOngoingCouponsFailed: false,
			};
		}
		case "LISTALLONGOINGCOUPONS_SUCCESS": {
			return {
				...state,
				AllOngoingCouponsLoading: false,
				AllOngoingCouponssuccess: true,
				AllOngoingCouponsFailed: false,
				AllOngoingCoupons: action.payload,
			};
		}
		case "LISTALLONGOINGCOUPONS_FAILED": {
			return {
				...state,
				AllOngoingCouponsLoading: false,
				AllOngoingCouponssuccess: false,
				AllOngoingCouponsFailed: true
			};
		}
		case "ASSIGNING_COUPON": {
			return {
				...state,
				AssigningCouponsLoading: true,
				AssigningCouponssuccess: false,
				AssigningCouponsFailed: false,
			};
		}
		case "ASSIGNCOUPON_SUCCESS": {
			return {
				...state,
				AssigningCouponsLoading: false,
				AssigningCouponssuccess: true,
				AssigningCouponsFailed: false,
			};
		}
		case "ASSIGNCOUPON_FAILED": {
			return {
				...state,
				AssigningCouponsLoading: false,
				AssigningCouponssuccess: false,
				AssigningCouponsFailed: true,
				Assignerror: action.payload
			};
		}
		case "CREATING_BXGY_COUPON": {
			return {
				...state,
				BXGYCouponsLoading: true,
				BXGYCouponssuccess: false,
				BXGYCouponsFailed: false,
			};
		}
		case "BXGYCOUPON_CREATED": {
			return {
				...state,
				BXGYCouponsLoading: false,
				BXGYCouponssuccess: true,
				BXGYCouponsFailed: false,
			};
		}
		case "BXGYCOUPON_CREATION_FAILED": {
			return {
				...state,
				BXGYCouponsLoading: false,
				BXGYCouponssuccess: false,
				BXGYCouponsFailed: true,
			};
		}
		case "EDITING_COUPON": {
			return {
				...state,
				EditingCouponsLoading: true,
				EditingCouponssuccess: false,
				EditingCouponsFailed: false,
			};
		}
		case "EDIT_COUPON_SUCCESS": {
			return {
				...state,
				EditingCouponsLoading: false,
				EditingCouponssuccess: true,
				EditingCouponsFailed: false,
			};
		}
    case "CLEAR_COUPON_DELETE":{
      return{
        ...state,
        EditingCouponssuccess:false,
      }
    }
		case "EDIT_COUPON_FAILED": {
			return {
				...state,
				EditingCouponsLoading: false,
				EditingCouponssuccess: false,
				EditingCouponsFailed: true,
				Editingerror: action.payload
			};
		}
		case "BXGY_PRODUCTS_LOADING": {
			return {
				...state,
				BxgyProductsLoading: true,
				BxgyProductssuccess: false,
				BxgyProductsFailed: false,
			};
		}
		case "BXGY_PRODUCTS_SUCCESS": {
			return {
				...state,
				BxgyProductsLoading: false,
				BxgyProductssuccess: true,
				BxgyProductsFailed: false,
				BxgyProducts: action.payload,
			};
		}
		case "BXGY_PRODUCTS_FAILED": {
			return {
				...state,
				BxgyProductsLoading: false,
				BxgyProductssuccess: false,
				BxgyProductsFailed: true
			};
		}
		case  "GET_BUSINESSTYPES_LOADING":{
			return {
				...state,
				BusinessTypeLoading: true,
				BusinessTypesuccess: false,
				BusinessTypeFailed: false,
			};	
		}
		case "GET_BUSINESSTYPES_SUCCESS": {
			return {
				...state,
				BusinessTypeLoading: false,
				BusinessTypesuccess: true,
				BusinessTypeFailed: false,
				BusinessType: action.payload,
			};
		}
		case "GET_BUSINESSTYPES_FAILED": {
			return {
				...state,
				BusinessTypeLoading: false,
				BusinessTypesuccess: false,
				BusinessTypeFailed: true
			};
		}
		case "CREATING_PERCENTAGE_COUPON": {
			return {
				...state,
				percentagecouponLoading: true,
				percentagecouponsuccess: false,
				percentagecouponFailed: false,
			};
		}
    case "CLEAR_PERCENTAGE_URL":{
      return{
        ...state,
        percentagecouponsuccess:false,
      }
    }
    case "CLEAR_BUYARRAY":{
      return{
        ...state,
        BXGYCheck:[],
      }
    }
    case "CLEAR_FLAT_URL":{
      return{
        ...state,
        Flatcouponsuccess:false,
      }
    }
    case "CLEAR_FREE_URL":{
      return{
        ...state,
        Deliverycouponsuccess:false,
      }
    }
    case "CLEAR_BUYXGETY_URL":{
      return{
        ...state,
        BXGYCouponssuccess:false,
      }
    }
		case "PERCENTAGECOUPON_CREATED": {
			return {
				...state,
				percentagecouponLoading: false,
				percentagecouponsuccess: true,
				percentagecouponFailed: false,
			};
		}
		case "PERCENTAGECOUPON_CREATION_FAILED": {
			return {
				...state,
				percentagecouponLoading: false,
				percentagecouponsuccess: false,
				percentagecouponFailed: true,
			};
		}
		case "CREATING_FLAT_COUPON": {
			return {
				...state,
				FlatcouponLoading: true,
				Flatcouponsuccess: false,
				FlatcouponFailed: false,
			};
		}
		case "FLATCOUPON_CREATED": {
			return {
				...state,
				FlatcouponLoading: false,
				Flatcouponsuccess: true,
				FlatcouponFailed: false,
			};
		}
		case "FLATCOUPON_CREATION_FAILED": {
			return {
				...state,
				FlatcouponLoading: false,
				Flatcouponsuccess: false,
				FlatcouponFailed: true,
			};
		}
		case "CREATING_DELEIVERYFREE_COUPON": {
			return {
				...state,
				DeliverycouponLoading: true,
				Deliverycouponsuccess: false,
				DeliverycouponFailed: false,
			};
		}
		case "DELIVERYFREECOUPON_CREATED": {
			return {
				...state,
				DeliverycouponLoading: false,
				Deliverycouponsuccess: true,
				DeliverycouponFailed: false,
			}; 
		}
		case "DELEIVERYFREECOUPON_CREATION_FAILED": {
			return {
				...state,
				DeliverycouponLoading: false,
				Deliverycouponsuccess: false,
				DeliverycouponFailed: true,
			};
		}
		case "SET_BUSINESS_ID_TYPE" :
			return{
				...state,
				businessId:action.payload
			}
		case "SET_BUSINESS_TYPE_ID_ERROR" :
			return{
				...state,
				businessError:action.payload
			}
		case "GETTING_ESTIMATED_TIME": {
				return {
					...state,
					EstimatedTimeLoading: true,
					EstimatedTimesuccess: false,
					EstimatedTimeFailed: false,
				};
			}
		case "GET_ESTIMATED_TIME_SUCCESS": {
				return {
					...state,
					EstimatedTimeLoading: false,
					EstimatedTimesuccess: true,
					EstimatedTimeFailed: false,
					EstimatedTime:action.payload.findDeliveryTimeAndDistance

				};
			}
		case "GET_ESTIMATED_TIME_FAILURE": {
				return {
					...state,
					EstimatedTimeLoading: false,
					EstimatedTimesuccess: false,
					EstimatedTimeFailed: true,
				};
			}
		case "SET_ESTIMATED_TIME": {
			return{
				...state,
				travelTime:action.payload.$d?action.payload.$d:action.payload

			}
		}
		case "GETTING_LINK": {
			return {
				...state,
				DynamicLinkLoading: true,
			    DynamicLinksuccess: false,
				DynamicLinkFailed: false,
			};
		}
		case "GET_LINK_SUCCESS": {
			return {
				...state,
				DynamicLinkLoading: false,
				DynamicLinksuccess: true,
				DynamicLink:action.payload.shareMyLink,
				DynamicLinkFailed: false,
			};
		}
		case "GET_LINK_FAILURE": {
			return {
				...state,
				DynamicLinkLoading: false,
				DynamicLinksuccess: false,
				DynamicLinkFailed: true,
			};
	
		}
		case "ELIMINATE_ORDER" : {
			// let a=state.ActiveOrder?.data?.criticalOrders.filter(
			// 	(c) => c.orderid.id !== action.payload
			//   )
			return {
				...state,
				ActiveOrder:{data:{criticalOrders:state.ActiveOrder?.data?.criticalOrders.filter(
					(c) => c.orderid.id !== action.payload
				  )}}
			}
		}
    case "ALL_COUNT_SUCCESS":{
      return{
        ...state,
        allCountSuccess:true,
        allCountFailed:false,
        allCount:action.payload || []
      }
    }
    case "ALL_COUNT_FAILED":{
      return{
        ...state,
        allCountSuccess:false,
        allCountFailed:true,
        
      }
    }
    case "NEWORDERS_LIST_SUCCESS":{
      return {
        ...state,
        newOrderSuccess:true,
        newOrdersFailed:false,
        newOrders:action.payload || [] 
       }
    }
    case "NEWORDERS_LIST_FAILED":{
      return {
        ...state,
        newOrderSuccess:false,
        newOrdersFailed:true
      }
    }
    case "ACTIVEORDERS_LIST_SUCCESS":{
      return {
        ...state,
        activeOrderSuccess:true,
        activeOrdersFailed:false,
        activeOrders:action.payload || [] 
       }
    }
    case "ACTIVEORDERS_LIST_FAILED":{
      return {
        ...state,
        activeOrderSuccess:false,
        activeOrdersFailed:true
      }
    }
    case "READYORDERS_LIST_SUCCESS":{
      return {
        ...state,
        readyOrderSuccess:true,
        readyOrdersFailed:false,
        readyOrders:action.payload || [] 
       }
    }
    case "READYORDERS_LIST_FAILED":{
      return {
        ...state,
        readyOrderSuccess:false,
        readyOrdersFailed:true
      }
    }
    case "OUTFORDELIVERY_LIST_SUCCESS":{
      return {
        ...state,
        outForDeliverySuccess:true,
        outForDeliveryFailed:false,
        outForDeliveryOrders:action.payload || [] 
       }
    }
    case "OUTFORDELIVERY_LIST_FAILED":{
      return {
        ...state,
        outForDeliverySuccess:false,
        outForDeliveryFailed:true
      }
    }
    case "COMPLETEDORDERS_LIST_SUCCESS":{
      return {
        ...state,
        completedOrderSuccess:true,
        completedOrdersFailed:false,
        completedOrders:action.payload || [] 
       }
    }
    case "COMPLETEDORDERS_LIST_FAILED":{
      return {
        ...state,
        completedOrderSuccess:false,
        completedOrdersFailed:true
      }
    }
    case "CHANGE_TO_ACTIVE":{
      return{
        ...state,
        ChangeToActiveLoading:true,
        ChangetoActiveStatusSuccess:false,
        ChangetoActiveStatusFailed:false,
      }
    }
    case "CHANGE_TO_ACTIVE_STATUS_SUCCESS":{
      return {
        ...state,
        ChangetoActiveStatusSuccess:true,
        ChangetoActiveStatusFailed:false,
        ChangeToActiveLoading:false,
        ChangetoActiveStatus:action.payload || [] 
       }
    }
    case "CHANGE_TO_ACTIVE_STATUS_FAILED":{
      return {
        ...state,
        ChangetoActiveStatusSuccess:false,
        ChangetoActiveStatusFailed:true,
        ChangeToActiveLoading:false,
      }
    }
    case "CHANGE_TO_READY":{
      return{
        ...state,
        ChangeToReadyLoading:true,
        ChangetoReadyStatusSuccess:false,
        ChangetoReadyStatusFailed:false,
      }
    }
    case "CHANGE_ORDER_STATUS_TO_READY_SUCCESS":{
      return {
        ...state,
        ChangetoReadyStatusSuccess:true,
        ChangetoReadyStatusFailed:false,
        ChangeToReadyLoading:false,
        ChangetoReadyStatus:action.payload || [] 
       }
    }
    case "CHANGE_ORDER_STATUS_TO_READY_FAILED":{
      return {
        ...state,
        ChangetoReadyStatusSuccess:false,
        ChangeToReadyLoading:false,
        ChangetoReadyStatusFailed:true
      }
    }
    case "CHANGE_TO_DELIVERY":{
      return{
        ...state,
        ChangeToDeliveryLoading:true,
        ChangetoDeliveryStatusSuccess:false,
        ChangetoDeliveryStatusFailed:false,
      }
    }
    case "CHANGE_ORDER_STATUS_TO_DELIVERY_SUCCESS":{
      return {
        ...state,
        ChangeToDeliveryLoading:false,
        ChangetoDeliveryStatusSuccess:true,
        ChangetoDeliveryStatusFailed:false,
        ChangetoDeliveryStatus:action.payload || [] 
       }
    }
    case "CHANGE_ORDER_STATUS_TO_DELIVERY_FAILED":{
      return {
        ...state,
        ChangeToDeliveryLoading:false,
        ChangetoDeliveryStatusSuccess:false,
        ChangetoDeliveryStatusFailed:true
      }
    }
    case "CHANGE_TO_COMPLETED":{
      return{
        ...state,
        ChangeToCompletedLoading:true,
        ChangetoCompletedStatusSuccess:false,
        ChangetoCompletedStatusFailed:false,
      }
    }
    case "CHANGE_ORDER_STATUS_TO_COMPLETED_SUCCESS":{
      return {
        ...state,
        ChangeToCompletedLoading:false,
        ChangetoCompletedStatusSuccess:true,
        ChangetoCompletedStatusFailed:false,
        ChangetoCompletedStatus:action.payload || [] 
       }
    }
    case "CHANGE_ORDER_STATUS_TO_COMPLETED_FAILED":{
      return {
        ...state,
        ChangeToCompletedLoading:false,
        ChangetoCompletedStatusSuccess:false,
        ChangetoCompletedStatusFailed:true
      }
    }
    case "ORDER_COUNT_SUCCESS":{
      return{
        ...state,
        OrderCountLoading:false,
        OrderCountSuccess: true,
        OrderCountFailed:false,
        OrderCount:action.payload || []
      }
    }
    case "ORDER_COUNT_FAILED":{
      return{
        ...state,
        OrderCountLoading:false,
        OrderCountSuccess: false,
        OrderCountFailed:true,
      }
    }
		default:
			return state;
	}
};


