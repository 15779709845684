import { React, useState,useEffect } from "react";
import CustomDialog from "../Commoncomponents/dialog"
import {
  TextField,
  DialogContent,
  Typography,
  useTheme,
  useMediaQuery,
  DialogTitle
} from "../MUIComponents/index";
import { Select, MenuItem, Grid } from "../MUIComponents/index";
import CustomTextField from "../Commoncomponents/input";
import CustomButton from "../Commoncomponents/button"
import { contentFontsize_24 ,ButtonFontSize_18} from "../Typography";
import './Filter.css'
 
function Filter(props) {

  const [optionsSubcategory, setOptionsSubcategory] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [filterProduct,setFilterProduct] = useState([])
  const [category, setCategory] = useState(null);
  const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
  const [key, setKey] = useState("");
  const [from, setFrom] = useState(0);
  const [first, setFirst] = useState(50);
  const [department, setDepartment] = useState(null);
  
const handleLoadMore = () =>{

}
 
  const [state, setState] = useState({
    department: null,
    category: null,
    category1: null,
    category2: null,
    storeid: localStorage.getItem("storeid"),
    key: "",
    from: 0,
    first: 20,
    page: null,
    products: [],
    status: null,
  });
 
 
  const handleSearchField = (e) => {
    let { value } = e.target;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };
 
  const searchProductHandle = (e) => {
    props.setShow1(true)
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      from: 0,
      products: [],
    }));
    props.setCriteria(state);
    props.ProductSearch(state);
    const newProduct =
      props.searchProductData &&
      props.searchProductData.data &&
      props.searchProductData.data.searchProducts &&
      props.searchProductData.data.searchProducts.items;
    
      if (newProduct){
        props.setProducts(newProduct)
     
      }
     setFilterProduct(newProduct)  
     props.setFilter(false)
  };
 
  const getCategory1List = (categoryId) => {
    const selectedCategory = props.DepartmentList.data.salesdepartments.find(
      (category) => category.id === categoryId
    );
 
    if (selectedCategory) {
      const subcategories = selectedCategory.childCategories || [];
      setOptionsSubcategory(
        subcategories.map((subcategory) => ({
          value: subcategory.id,
          label: subcategory.name,
        }))
      );
    }
  };


  const getCategoryList = (id) => {
    props.getCategoryList(id);
  };
 
 const handleClear = () => {
  setState({
    department: null,
    category: null,
    category1: null,
    category2: null,
    storeid: localStorage.getItem("storeid"),
    key: "",
    from: 0,
    first: 20,
    page: null,
    products: [],
    status: null,
  });
  props.ProductSearch({
    from,
    first,
    department,
    storeid,
    category,
    key,
  });
  };
 
  function handleClose() {
    props.setFilter(false);
  }
  return (
    <div>
      <CustomDialog
        width="90%"
        maxHeight="70%"
        borderRadius="20px"
        open={props.filter}
        onClose={handleClose}
        titleAlign="center"
        padding="10px 0px 0px 0px"
        showCloseButton={false}
      >
        <DialogTitle>
                <Typography fontSize={contentFontsize_24} sx={{fontFamily:"Roboto",fontWeight:"700",display:"flex",justifyContent:"center",color:"black"}}>Filters</Typography>
              </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ paddingTop: 0 }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    marginBottom: "8px",
                    color: "black",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography>Product Name</Typography>
                </div>
                <div
                  style={{ color: "#85BE49", textDecoration: "underline",cursor:"pointer" }}
                  onClick={handleClear}
                >
                  <Typography>Reset</Typography>
                </div>
              </div>
 
              <TextField
               sx={{ background:"white",width:"100%",borderRadius:"10px"}}
               className="custom-select_1"
                value={state.key}
                background="white"
                size={matches ? "small" : "normal"}
                name="key"
                onChange={(e) => {
                  handleSearchField(e);
                }}
                match={matches ? true : false}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} sx={{ paddingTop: 0 }}>
              <div style={{ marginBottom: "8px", color: "black" }}>
                <Typography>Status</Typography>
              </div>
              <div style={{ flex: "1" }}>
                <Select
                 className="custom-select_1"
                  name="status"
                  width="100%"
                  match={matches ? true : false}
                  size={matches ? "small" : "normal"}
                  buttonColor="success"
                  sx={{
                    width: "100%",
                    variant: "outlined",
                    background: "white",
                    borderRadius: "10px",
                    color: "white",
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#85BE49',
                      
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#85BE49',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#85BE49',
                    },
                  }}
                  onChange={(e) => {
                    handleSearchField(e);
                  }}

                >
                  <MenuItem value={"active"} style={{ cursor: "pointer" }}>
                    {"Active"}
                  </MenuItem>
                  <MenuItem value={"inactive"} style={{ cursor: "pointer" }}>
                    {"Inactive"}
                  </MenuItem>
                </Select>
              </div>
            </Grid>
 
            <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
              <div style={{ marginBottom: "8px", color: "black" }}>
                <Typography>Category</Typography>
              </div>
              {props.DepartmentList !== "" && (
                <div style={{ flex: "1", marginBottom: "8px" }}>
                  <Select
                   className="custom-select_1"
                    match={matches ? true : false}
                    size={matches ? "small" : "normal"}
                    name="department"
                    // key={state.department}
                    onChange={(e) => {
                      handleSearchField(e);
                      getCategoryList(e.target.value);
                    }}
                    sx={{
                      width: "100%",
                      buttonColor: "success",
                      variant: "outlined",
                      background: "white",
                      borderRadius: "10px",
                    }}
                  >
                    {props.DepartmentList &&
                      props.DepartmentList.data &&
                      props.DepartmentList.data.salesdepartments !== null &&
                      props.DepartmentList.data.salesdepartments.map((s) => (
                        <MenuItem value={`${s.id}`} key={s.id}>
                          {s.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
              <div style={{ marginBottom: "8px", color: "black" }}>
                <Typography>Sub-Category</Typography>
              </div>
              <div style={{ flex: "1" }}>
                <Select
                 className="custom-select_1"
                  name="category"
                  // key={state.category}
                  onChange={(e) => {
                    handleSearchField(e);
                    getCategory1List(e.target.value);
                  }}
                  SelectProps={{
                    style: { width: "100%", height: 35 },
                    value: state.category === null ? "" : state.category,
                  }}
                  width="100%"
                  match={matches ? true : false}
                  size={matches ? "small" : "normal"}
                  buttonColor="success"
                  sx={{
                    width: "100%",
                    variant: "outlined",
                    background: "white",
                    borderRadius: "10px",
                  }}
                >
                  {props.CategoryList &&
                    props.CategoryList.data.salescategory.map((c) => (
                      <MenuItem value={`${c.id}`} key={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </Grid>
            
            <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
              <CustomButton
                width="230px"
                height="40px"
                borderRadius="20px"
                color={"black"}
                variant="contained"
                background={"white"}
                borderColor="#659B1B"
                onClick={handleClose}
              >
                Cancel
              </CustomButton>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
              <CustomButton
                width="230px"
                height="40px"
                borderRadius="20px"
                color={"white"}
                variant="contained"
                background={"rgb(101, 155, 27)"}
                onClick={(e) => {
                  searchProductHandle(e);
                }}
              >
                Apply
              </CustomButton>
            </Grid>
          </Grid>
        </DialogContent>
      </CustomDialog>
      <>
    
      {props.load === false && props?.Products?.newProducts?.length > first +1 ?(
      <div style={{ marginTop: "10px" }}>
      <CustomButton width={"20%"} borderRadius="10px">
        <Typography
          fontSize={ButtonFontSize_18}
          className="Add-product-btn"
          onClick={handleLoadMore}
        >
          Load More
        </Typography>
      </CustomButton>
    </div>): null
      }
      </>
    </div>
  );
}
 
export default Filter;