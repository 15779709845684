// import React from "react";
// import HomeBanner from "../../Components/Banner";
// import "./index.css";
// import AboutUs from "../../Components/AboutUs";
// // import LoginForm from "../../Components/LoginForm";
// import HowTo from "../../Components/HowTo";
// import WhyChoose from "../../Components/WhyChoose";
// import WhoCan from "../../Components/WhoCan";
// import Footer from "../../Components/Footer";
// // import SignupModal from "./Signupmodal";
// // import { Row, Col, Image, Container } from "react-bootstrap";

// class LaptopScreen extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   componentDidMount() {
//     setTimeout(() => {
//       console.log("This will run after 3 seconds");
//     }, 3000);
//   }


//   render() {
//     if (localStorage.getItem("token") && this.props.successData) {
//       // this.props.history.push({
//       //   pathname: `/home/Dashboard`,
//       // });
//     }
//     return (
//       <>
//         {/* <SignupModal {...this.props}/> */}
//         <div 
        
//         >
//           <HomeBanner {...this.props} />
//         </div>

//         {/* <div style={{ paddingTop: "40px" }}>
//           <AboutUs {...this.props} />
//         </div>
//         <div>
//           <HowTo />
//         </div>
//         <div>
//           <WhyChoose />
//         </div>
//         <div>
//           <WhoCan />
//         </div>
//         <div>
//           <Footer {...this.props} />
//         </div> */}
//       </>


//     );
//   }
// }
// export default LaptopScreen;
import React from "react";
import HomeBanner from "../../Components/Banner";
import "./index.css";

class LaptopScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBanner: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showBanner: true });
    }, 1000); 
  }

  render() {
    if (localStorage.getItem("token") && this.props.successData) {
      // this.props.history.push({
      //   pathname: `/home/Dashboard`,
      // });
    }

    return (
      <>
        {this.state.showBanner && (
          <div>
            <HomeBanner {...this.props} />
          </div>
        )}
      </>
    );
  }
}

export default LaptopScreen;
