import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Row, Col } from "react-bootstrap";
import {MenuItem} from "@mui/material";
import ProductCard from "./ProductCard";
import { ThemeProvider} from "@mui/material/styles";
import { createTheme } from '@mui/material/styles';
import { green } from "@mui/material/colors";
import { loader } from "../../utils/image";
import { Container } from "@mui/material";
// import Box from '@mui/material/Box';
import "./index.css";

class UpdateProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: null,
      category: null,
      category1: null,
      category2: null,
      status: null,
      storeid: localStorage.getItem("storeid"),
      key: "",
      from: 0,
      first: 20,
      page: null,
      products: [],
      isMobile: window.innerWidth < 768,
    };
  }

  componentDidMount = () => {
    document.body.scrollTop = 0;
  
    if (
      this.props.searchProductData &&
      this.props.searchProductData.data &&
      this.props.searchProductData.data.searchProducts &&
      this.props.searchProductData.data.searchProducts.length !== 0
    ) {
      let {
        from,
        first,
        department,
        storeid,
        category,
        category1,
        category2,
        status,
        key,
      } = this.props.criteria;
      // let products= this.state.products;
      this.props.ProductSearch({
        from,
        first,
        storeid,
        department,
        category,
        category1,
        category2,
        status,
        key,
      });
    }
  };
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    //
    if (this.props.searchProductData !== prevProps.searchProductData) {
      this.setState(
        {
          products: [
            ...this.state.products,
            this.props.searchProductData &&
            this.props.searchProductData.data &&
            this.props.searchProductData.data.searchProducts &&
            this.props.searchProductData.data.searchProducts.items.map(
              (p) => p
            ),
          ],
        },
        () => {
          const newArr = Array.from(
            new Set(this.state.products.map(JSON.stringify)),
            JSON.parse
          );
          this.props.setProducts(newArr);
        }
      );
    }
  }

  render() {
    // const { classes } = this.props;
    document.title = "Seller Portal | Edit Products";
    const theme = createTheme({
      palette: {
        primary: green,
      },
      overrides: {
        MuiInputBase: {
          input: {
            "&:-webkit-autofill": {
              transitionDelay: "9999s",
              "-webkit-text-fill-color": "white",
              "-webkit-box-shadow": "0 0 0px 1000px #00000000 inset",
            },
          },
        },
      },
    });

    const handleSearchField = (e) => {
      let { value } = e.target;
      this.setState({
        ...this.state,
        [e.target.name]: value,
      });
    };
    const Clear = () => {
      this.setState({
        department: null,
        category: null,
        category1: null,
        category2: null,
        status: null,
        storeid: localStorage.getItem("storeid"),
        key: "",
        from: 0,
        page: null,
        products: [],
      });
      let {
        from,
        first,
        department,
        storeid,
        category,
        category1,
        category2,
        status,
        key,
      } = this.state;
      this.props.setCriteria({
        from,
        first,
        storeid,
        department,
        category,
        category1,
        category2,
        status,
        key,
      });
    };
    const LoadMore = () => {
      this.setState(
        {
          ...this.state,
          from: this.state.from + 20,
        },
        () => {
          let {
            from,
            first,
            department,
            storeid,
            category,
            category1,
            category2,
            status,
            key,
          } = this.state;
          this.props.setCriteria({
            from,
            first,
            storeid,
            department,
            category,
            category1,
            category2,
            status,
            key,
          });
          this.props.ProductSearch({
            from,
            first,
            storeid,
            department,
            category,
            category1,
            category2,
            status,
            key,
          });
        }
      );
    };
    const searchProductHandle = (e) => {
      e.preventDefault();

      this.setState(
        {
          products: [],
          from: 0,
        },
        () => {
          // this.props.setProducts([]);
          let {
            from,
            first,
            department,
            storeid,
            category,
            category1,
            category2,
            status,
            key,
          } = this.state;
          this.props.setCriteria({
            from,
            first,
            storeid,
            department,
            category,
            category1,
            category2,
            status,
            key,
          });
          // 		let { from,first,department, storeid, category, category1, category2, status, key } = this.state;
          // // this.props.getProductSearch({ from,storeid, department, category, category1, category2, status, key });
          this.props.ProductSearch({
            from,
            first,
            storeid,
            department,
            category,
            category1,
            category2,
            status,
            key,
          });
        }
      );
    };
    const getCategoryList = (id) => {
      this.props.getCategoryList(id);
    };
    const getCategory1List = (id) => {
      this.props.getCategory1List(id);
    };
    const getCategory2List = (id) => {
      this.props.getCategory2List(id);
    };

    return this.state.isMobile ? (
      <>
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            paddingBottom: "30px",
          }}
        >
          <div
          >
            <h5
              style={{ fontWeight: 600, paddingBottom: 20, fontSize: "23px" }}
            >
              {"Search products"}
            </h5>
            <form
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <ThemeProvider theme={theme}>
                <ul>
                  <Row
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Col md={12} xs={12}>
                      <TextField
                        id="select"
                        key={this.state.department}
                        select
                        variant="outlined"
                        style={{ marginBottom: "15px", width: "100%" }}
                        SelectProps={{
                          style: { width: "100%", height: 35 },
                          value:
                            this.state.department === null
                              ? ""
                              : this.state.department,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="department"
                        label="Department"
                        onChange={(e) => {
                          handleSearchField(e);
                          getCategoryList(e.target.value);
                          // getProduct(e.target.value);
                          this.setState({
                            category: null,
                            category1: null,
                            category2: null,
                          });
                        }}
                      >
                        {this.props.DepartmentList &&
                          this.props.DepartmentList.data &&
                          this.props.DepartmentList.data.salesdepartments !==
                          null &&
                          this.props.DepartmentList.data.salesdepartments.map(
                            (s) => (
                              <MenuItem
                                key={s.id}
                                style={{ width: "fit-content" }}
                                value={s.id}
                              >
                                {s.name}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    </Col>
                    <Col md={12} xs={12}>
                      {this.props.CategoryList &&
                        this.props.CategoryList.data.salescategory.length !==
                        0 && (
                          <TextField
                            id="select"
                            key={this.state.category}
                            select
                            variant="outlined"
                            style={{ width: "100%", marginBottom: "15px" }}
                            // InputProps={{
                            // 	style: { height: 35, width: "100%" },
                            // 	autoComplete: "off",
                            //   }}
                            SelectProps={{
                              style: { width: "100%", height: 35 },
                              value:
                                this.state.category === null
                                  ? ""
                                  : this.state.category,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="category"
                            label="Category"
                            onChange={(e) => {
                              handleSearchField(e);
                              getCategory1List(e.target.value);
                            }}
                          >
                            {this.props.CategoryList &&
                              this.props.CategoryList.data.salescategory.map(
                                (c) => (
                                  <MenuItem
                                    key={c.id}
                                    style={{ width: "fit-content" }}
                                    value={c.id}
                                  >
                                    {c.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        )}
                    </Col>
                    <Col md={12} xs={12}>
                      {this.state.category !== null &&
                        this.props.CategoryList &&
                        this.props.CategoryList.data.salescategory.length !==
                        0 &&
                        this.props.Category1List &&
                        this.props.Category1List.data.salescategory.length !==
                        0 && (
                          <TextField
                            id="select"
                            select
                            variant="outlined"
                            style={{ marginBottom: "15px", width: "100%" }}
                            SelectProps={{
                              style: { width: "100%", height: 35 },
                              value:
                                this.state.category1 === null
                                  ? ""
                                  : this.state.category1,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            // label="sub-category1"

                            name="category1"
                            label="Sub-Category 1"
                            onChange={(e) => {
                              handleSearchField(e);
                              getCategory2List(e.target.value);
                            }}
                          >
                            {this.props.Category1List &&
                              this.props.Category1List.data.salescategory.map(
                                (c) => (
                                  <MenuItem
                                    key={c.id}
                                    style={{ width: "fit-content" }}
                                    value={c.id}
                                  >
                                    {c.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        )}
                    </Col>
                    <Col md={12} xs={12}>
                      {this.state.category1 !== null &&
                        this.props.CategoryList &&
                        this.props.CategoryList.data.salescategory.length !==
                        0 &&
                        this.props.Category2List &&
                        this.props.Category2List.data.salescategory.length !==
                        0 && (
                          <TextField
                            id="select"
                            key={this.state.category2}
                            select
                            variant="outlined"
                            style={{ marginBottom: "15px", width: "100%" }}
                            SelectProps={{
                              style: { width: "100%", height: 35 },
                              value:
                                this.state.category2 === null
                                  ? ""
                                  : this.state.category2,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="category2"
                            label="Sub-Category 2"
                            onChange={(e) => {
                              handleSearchField(e);
                            }}
                          >
                            {this.props.Category2List &&
                              this.props.Category2List.data.salescategory.map(
                                (i) => (
                                  <MenuItem
                                    key={i.id}
                                    style={{ width: "fit-content" }}
                                    value={i.id}
                                  >
                                    {i.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        )}
                    </Col>
                  </Row>
                  <Grid item xs={12} md={12}>
                    <Row
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Col md={12} style={{ paddingTop: 20 }}>
                        <TextField
                          InputProps={{ style: { height: 35 } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ marginBottom: "15px", width: "100%" }}
                          id="filled-basic"
                          label="Search by Product name"
                          variant="outlined"
                          value={this.state.key}
                          inputProps={{
                            style: {
                              height: 10,
                              background: "transparent",
                            },
                          }}
                          name="key"
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                        />
                      </Col>
                      <Col xs={12} md={12} style={{ paddingTop: 20 }}>
                        <TextField
                          select
                          id="select"
                          variant="outlined"
                          style={{ marginBottom: "15px", width: "100%" }}
                          SelectProps={{
                            style: { width: "100%", height: 35 },
                            value:
                              this.state.status === null
                                ? ""
                                : this.state.status,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="status"
                          label="Status"
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                        >
                          <MenuItem
                            style={{ width: "fit-content" }}
                            value={"active"}
                          >
                            {"active"}
                          </MenuItem>
                          <MenuItem
                            style={{ width: "fit-content" }}
                            value={"inactive"}
                          >
                            {"inactive"}
                          </MenuItem>
                        </TextField>
                      </Col>
                      <Col xs={12} md={2} style={{ paddingTop: 20 }}>
                        <button
                          type="button"
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: "rgb(137, 199, 74, 1)",
                            width: "100%",
                            float: "right",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          onClick={(e) => {
                            searchProductHandle(e);
                          }}
                        >
                          Search
                        </button>
                      </Col>
                      <Col xs={12} md={2} style={{ paddingTop: 20 }}>
                        <button
                          type="button"
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: "rgb(137, 199, 74, 1)",
                            width: "100%",
                            float: "right",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          onClick={(e) => {
                            Clear();
                          }}
                        >
                          Clear
                        </button>
                      </Col>
                    </Row>
                  </Grid>
                </ul>
              </ThemeProvider>
            </form>

            {this.props.searchProductSuccess ? (
              <>
                <Row>
                  <ProductCard products={this.props.Products} {...this.props} />
                </Row>
                <Row
                  style={{
                    textAlign: "right",
                    justifyContent: "right",
                    alignItems: "right",
                  }}
                >
                  {
                    <div
                      style={{
                        textAlign: "right",
                        justifyContent: "right",
                        alignItems: "right",
                        fontSize: "16px",
                      }}
                    >
                      Displaying results {`${1}`} - {`${this.state.from + 20}`}
                    </div>
                  }
                </Row>
                <Row
                  style={{
                    paddingTop: 20,
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  {this.props.searchProductData &&
                    this.props.searchProductData.data &&
                    this.props.searchProductData.data.searchProducts &&
                    this.props.searchProductData.data.searchProducts.hasNext ===
                    true && (
                      <div
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <button
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: "rgb(137, 199, 74, 1)",
                            width: "150px",
                            height: "45px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "40px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          onClick={LoadMore}
                        >
                          Load More
                        </button>
                      </div>
                    )}
                </Row>
              </>
            ) : null}
            {this.props.searchLoading && (
              <div
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={loader} alt="Loading" />
              </div>
            )}
          </div>
        </Container>
      </>
    ) : (
      <>
        <Container
          maxWidth="xl"
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              padding: 15,
            }}
          >
            <h5
              style={{
                fontWeight: 600,
                paddingBottom: 20,
                fontSize: "23px"
              }}
            >
              {"Search products"}
            </h5>
            <form style={{ width: "100%" }}>
              <ThemeProvider theme={theme}>
                <ul>
                  <Row>
                    <Col md={3} xs={12}>
                      <TextField
                        id="select"
                        key={this.state.department}
                        select
                        variant="outlined"
                        style={{ width: "100%" }}
                        SelectProps={{
                          style: { width: "100%", height: 35 },
                          value:
                            this.state.department === null
                              ? ""
                              : this.state.department,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: {
                            height: 35,
                            width: "100%"
                          }
                        }}
                        name="department"
                        label="Department"
                        onChange={(e) => {
                          handleSearchField(e);
                          getCategoryList(e.target.value);
                          // getProduct(e.target.value);
                          this.setState({
                            category: null,
                            category1: null,
                            category2: null,
                          });
                        }}
                      >
                        {this.props.DepartmentList &&
                          this.props.DepartmentList.data &&
                          this.props.DepartmentList.data.salesdepartments !==
                          null &&
                          this.props.DepartmentList.data.salesdepartments.map(
                            (s) => (
                              <MenuItem key={s.id} value={s.id}>
                                {s.name}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    </Col>
                    <Col md={3} xs={12}>
                      {this.props.CategoryList &&
                        this.props.CategoryList.data.salescategory.length !==
                        0 && (
                          <TextField
                            id="select"
                            key={this.state.category}
                            select
                            variant="outlined"
                            style={{ width: "100%" }}
                            // InputProps={{
                            // 	style: { height: 35, width: "100%" },
                            // 	autoComplete: "off",
                            //   }}
                            SelectProps={{
                              style: { width: "100%", height: 35 },
                              value:
                                this.state.category === null
                                  ? ""
                                  : this.state.category,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              style: { height: 35, width: "100%" },
                            }}
                            name="category"
                            label="Category"
                            onChange={(e) => {
                              handleSearchField(e);
                              getCategory1List(e.target.value);
                            }}
                          >
                            {this.props.CategoryList &&
                              this.props.CategoryList.data.salescategory.map(
                                (c) => (
                                  <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        )}
                    </Col>
                    <Col md={3} xs={12}>
                      {this.state.category !== null &&
                        this.props.CategoryList &&
                        this.props.CategoryList.data.salescategory.length !==
                        0 &&
                        this.props.Category1List &&
                        this.props.Category1List.data.salescategory.length !==
                        0 && (
                          <TextField
                            id="select"
                            key={`${Math.floor(
                              Math.random() * (9999 - 1000 + 1) + 1000
                            )}`}
                            select
                            variant="outlined"
                            style={{ width: "100%" }}
                            SelectProps={{
                              style: { width: "100%", height: 35 },
                              value:
                                this.state.category1 === null
                                  ? ""
                                  : this.state.category1,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              style: { height: 35, width: "100%" },
                            }}
                            name="category1"
                            label="Sub-Category 1"
                            onChange={(e) => {
                              handleSearchField(e);
                              getCategory2List(e.target.value);
                            }}
                          >
                            {/* <TextField
											id="select"
												select
												variant="outlined"
												style={{ width: '100%' }}
												SelectProps={{
													 style: { width: '100%', height: 35 } ,
													 value:this.state.category1===null?'':this.state.category1
													}}
												InputLabelProps={{
													shrink: true,
												}}
												// label="sub-category1"
												InputProps={{style:{height:35,width:'100%'}}}
												name="category1"
												label="Sub-Category 1"
												onChange={(e) => {
													handleSearchField(e)
													getCategory2List(e.target.value);
												}}
											> */}
                            {this.props.Category1List &&
                              this.props.Category1List.data.salescategory.map(
                                (c) => (
                                  <MenuItem key={c.id} value={c.id}>
                                    {c.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        )}
                    </Col>
                    <Col md={3} xs={12}>
                      {this.state.category1 !== null &&
                        this.props.CategoryList &&
                        this.props.CategoryList.data.salescategory.length !==
                        0 &&
                        this.props.Category2List &&
                        this.props.Category2List.data.salescategory.length !==
                        0 && (
                          <TextField
                            id="select"
                            key={this.state.category2}
                            select
                            variant="outlined"
                            style={{ width: "100%" }}
                            SelectProps={{
                              style: { width: "100%", height: 35 },
                              value:
                                this.state.category2 === null
                                  ? ""
                                  : this.state.category2,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              style: { height: 35, width: "100%" },
                            }}
                            name="category2"
                            label="Sub-Category 2"
                            onChange={(e) => {
                              handleSearchField(e);
                            }}
                          >
                            {/* <TextField
											id="select"
											    key={this.state.category2}
												select
												variant="outlined"
												style={{ width: '100%' }}
												SelectProps={{ 
													style: { width: '100%', height: 35 },
													value:this.state.category2===null?'':this.state.category2
												}}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{style:{height:35,width:'100%'}}}
												name="category2"
												label="Sub-Category 2"
												onChange={(e) => {
													handleSearchField(e,);
												}}
											> */}
                            {this.props.Category2List &&
                              this.props.Category2List.data.salescategory.map(
                                (i) => (
                                  <MenuItem key={i.id} value={i.id}>
                                    {i.name}
                                  </MenuItem>
                                )
                              )}
                          </TextField>
                        )}
                    </Col>
                  </Row>
                  <Grid item xs={12} md={12}>
                    <Row>
                      <Col md={4} style={{ paddingTop: 20 }}>
                        <TextField
                          InputProps={{ style: { height: 35 } }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%" }}
                          id="filled-basic"
                          label="Search by Product name"
                          variant="outlined"
                          value={this.state.key}
                          // inputProps={{
                          // 	style: {
                          // 		height: 10
                          // 	}
                          // }}
                          name="key"
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                        />
                      </Col>
                      <Col xs={12} md={3} style={{ paddingTop: 20 }}>
                        {/* <TextField
												select
												id="outlined-select-currency"
												variant="outlined"
												style={{ width: '100%' }}
												SelectProps={{ 
													style: { width: '100%', height: 35 ,backgroundColor:'white'},
													value:this.state.status===null?'':this.state.status
												 }}
												 autoComplete="off"
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{style:{height:35,width:'100%',}}}
												// InputProps={{
												// 	className: classes.input
												//   }}
												inputProps={{
													
													  autocomplete: 'off',
													
												  }}
												name="status"
												label="Status"
												onChange={(e) => {
													handleSearchField(e,);
												}}
											>
												
														<MenuItem 
														value={'active'}>{'active'}</MenuItem>
														<MenuItem 
														 value={'inactive'}>{'inactive'}</MenuItem>
													
											</TextField> */}
                        <TextField
                          id="select"
                          key={`${Math.floor(
                            Math.random() * (9999 - 1000 + 1) + 1000
                          )}`}
                          select
                          variant="outlined"
                          style={{ width: "100%" }}
                          SelectProps={{
                            style: { width: "100%", height: 35 },
                            value:
                              this.state.status === null
                                ? ""
                                : this.state.status,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ style: { height: 35, width: "100%" } }}
                          name="status"
                          label="Status"
                          onChange={(e) => {
                            handleSearchField(e);
                          }}
                        >
                          <MenuItem value={"active"}>{"active"}</MenuItem>
                          <MenuItem value={"inactive"}>{"inactive"}</MenuItem>
                        </TextField>
                      </Col>
                      <Col xs={12} md={2} style={{ paddingTop: 20 }}>
                        <button
                          type="button"
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: "rgb(137, 199, 74, 1)",
                            width: "100%",
                            float: "right",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          onClick={(e) => {
                            searchProductHandle(e);
                          }}
                        >
                          Search
                        </button>
                      </Col>
                      <Col xs={12} md={2} style={{ paddingTop: 20 }}>
                        <button
                          type="button"
                          style={{
                            justifyContent: " center",
                            color: "rgb(255, 255, 255)",
                            backgroundColor: "rgb(137, 199, 74, 1)",
                            width: "100%",
                            float: "right",
                            height: "37px",
                            fontSize: "16px",
                            fontWeight: "600",
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: " solid",
                            borderColor: "transparent",
                            borderImage: "initial",
                            overflow: "hidden",
                            outline: "none",
                            minHeight: " 14px",
                            fontFamily: "Varela Round, sans-serif",
                          }}
                          onClick={(e) => {
                            Clear();
                          }}
                        >
                          Clear
                        </button>
                      </Col>
                    </Row>
                  </Grid>

                  {this.props.searchProductSuccess === true ? (
                    <>
                      <Grid item xs={12} md={12} classname="center-div">
                        <Row>
                          <ProductCard
                            products={this.props.Products}
                            {...this.props}
                          />
                        </Row>
                      </Grid>
                      <Row style={{
                        textAlign: 'right',
                        justifyContent: 'right',
                        alignItems: 'right'
                      }}>
                        {
                          <div style={{
                            textAlign: 'right',
                            justifyContent: 'right',
                            alignItems: 'right',
                            fontSize: '16px'
                          }}>
                            Displaying results {`${1}`} - {`${this.state.from + 20}`}

                          </div>
                        }
                      </Row>
                      <Row
                        style={{
                          paddingTop: 20,
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        {this.props.searchProductData &&
                          this.props.searchProductData.data &&
                          this.props.searchProductData.data.searchProducts &&
                          this.props.searchProductData.data.searchProducts
                            .hasNext === true && (
                            <div
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  justifyContent: " center",
                                  color: "rgb(255, 255, 255)",
                                  backgroundColor: "rgb(137, 199, 74, 1)",
                                  width: "150px",
                                  height: "45px",
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  borderRadius: "40px",
                                  borderWidth: "1px",
                                  borderStyle: " solid",
                                  borderColor: "transparent",
                                  borderImage: "initial",
                                  overflow: "hidden",
                                  outline: "none",
                                  minHeight: " 14px",
                                  fontFamily: "Varela Round, sans-serif",
                                }}
                                onClick={LoadMore}
                              >
                                Load More
                              </button>
                            </div>
                          )}
                      </Row>
                    </>
                  ) : null}
                  {this.props.searchLoading && (
                    <div
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={loader} alt="Loading" />
                    </div>
                  )}
                </ul>
              </ThemeProvider>
            </form>
          </div>
        </Container>
      </>
    );
  }
}
export default UpdateProduct;
