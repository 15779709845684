import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./Configurations";
import * as serviceWorker from "./serviceWorker";
import ReduxProvider from "./Configurations/redux";

if ('Notification' in window &&
'serviceWorker' in navigator &&
'PushManager' in window) {

  navigator.serviceWorker
    .register("firebase-messaging-sw.js")
    .then(function (registration) {
     
    })
    .catch(function (err) {
     
    });
}

const root = createRoot(document.getElementById("root"));


root.render(   
  <React.StrictMode>
    <ReduxProvider>
      <App />
    </ReduxProvider>
  </React.StrictMode>
);

serviceWorker.register();
