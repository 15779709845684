import {React,useEffect }from 'react';
import PropTypes from 'prop-types';
import {Popover} from "../../MUIComponents"
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';

import  {bindPopover } from 'material-ui-popup-state';
import { propTypes } from 'react-bootstrap/esm/Image';

const CustomPopover = ({ popupState, text, height, width, borderRadius, anchorOrigin, transformOrigin, children,onClick ,padding,matchBackground}) => {
  useEffect(() => {
    const handleScroll = () => {
      if (popupState.isOpen) {
        popupState.close();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [popupState]);
  return ( 
    <div>
       {/* {<Backdrop open={popupState.isOpen} onClick={() => this.setState({ open: false })} /> } */}
   <div
  style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: popupState.isOpen && matchBackground&& 'rgba(0,0,0,0.5) ',
    zIndex: popupState.isOpen && '1200',
    display: popupState.isOpen ? 'block' : 'none',
    padding:padding,
   
  }}
/>


      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={anchorOrigin}
        disableScrollLock={true}
        transformOrigin={transformOrigin}
      >
        {/* <Typography sx={{ p: 2 }}>
          {text}
        </Typography> */}
        <div style={{ height, width, borderRadius,padding }} onClick={onClick}>
          {children}
        </div>
      </Popover>
    </div>
  );
};

CustomPopover.propTypes = {
  popupState: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  children: PropTypes.node.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    horizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    horizontal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onClick:PropTypes.func,
  padding:PropTypes.string,
  matchBackground:propTypes.bool,
 
};

export default CustomPopover;
