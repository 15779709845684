import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
  DialogTitle,
  DialogContent,
  DemoContainer,
  DateTimePicker,
  Dialog,
  TextField,
} from "../../MUIComponents/index";
import React, { useEffect, useRef, useState } from "react";
import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import CustomTextField from "../../Commoncomponents/input";
import filtericon from "../../../utils/Icon/filtericon.svg";
import search from "../../../utils/Icon/search.svg";
import "./OrderHistory.css";
import CustomCard from "../../Commoncomponents/card";
import Arrow from "../../../utils/Icon/Arrow.svg";
import Calendar from "../../../utils/Icon/Calendar.svg";
import CustomButton from "../../Commoncomponents/button";
import {
  ButtonFontSize_16_xs_11,
  contentFontSize_16,
  contentFontsize_24,
  contentFontsize_28,
  contentFontsize_36,
} from "../../Typography";
import ClockAnimation from "../../../utils/Icon/ClockAnimation.svg";
import { HeaderNavigationFontSize_20_xs_13 } from "../../Typography/index";
import ShopAssistantClock from "../../../utils/Icon/ShopAssistantClock.svg";
import { contentFontsize_14_xs_10 } from "../../Typography/index";
import CustomDialog from "../../Commoncomponents/dialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomSelect from "../../Commoncomponents/selectButton";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SearchResults from "../../SearchResults/index";
import Loader from "../Loader";
import { green } from "@mui/material/colors";
import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";
import dayjs from "dayjs";
import * as Yup from "yup";

import AdvancedFormat from "dayjs/plugin/advancedFormat";
import { items } from "../../NavBar/utils";
dayjs.extend(AdvancedFormat);

function OrderHistory(props) {
  useEffect((storeid) => {
    props.getCompletedOrdertlist(storeid);
  }, []);
  const completedOrdersList =
    ("props",
    props &&
      props.completedOrders &&
      props.completedOrders.data &&
      props.completedOrders.data.completedOrdersList);
  
  const location = useLocation();
  const name = location.state?.name;
  const name2 = location.state?.name2;
  const [orderNumber, setOrderNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [status, setStatus] = useState(null);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [deliveryDateTo, setDeliveryDateTo] = useState("");
  const [deliveryDateFrom, setDeliveryDateFrom] = useState("");
  const [offset, setOffset] = useState(0);
  const [first, setFirst] = useState(15);
  const [orders, setOrders] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [isMobileError, setIsMobileError] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  let storeid = localStorage.getItem("storeid");
 
 

  useEffect(() => {
    props.getSearchResult(storeid);
  }, []);
  useEffect(() => {
    document.title = "Seller Portal | My Orders";
    window.addEventListener("resize", updateIsMobile);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const [prevProps, setPrevProps] = useState(null);

  useEffect(() => {
    setPrevProps(props);
  }, [props]);

  useEffect(() => {
    if (props?.searchData !== prevProps?.searchData) {
      setOrders([
        ...orders,
        props?.searchData?.searchorder?.items?.map((p) => p),
      ]);
      props.setOrders(orders);
    }
  }, [props?.searchData]);
 
  const Clear = () => {
    setOrderNumber("");
    setMobileNumber(null);
    setStatus("");
    formik.resetForm(); 
    setDeliveryDateFrom(null);
    setDeliveryDateTo(null);
    setOffset(0);
    setOrders([]);
  };

  const [show, setShow] = useState(false);


  
  const LoadMores = () => {
    setFirst(first + 5);
    setStatus("Completed");
    props.getCompletedOrdertlist({ first: first + 10, storeid });
  };
  const updateIsMobile = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const statusFilters = [
    { option: "Order-Placed", value: "Order-Placed" },
    { option: "Order-Accepted", value: "Order-Accepted" },
    { option: "Order-Ready", value: "Order-Ready" },
    { option: "Order-Rejected", value: "Order-Rejected" },
    { option: "Order-Cancelled", value: "Order-Cancelled" },
    { option: "Out-For-Delivery", value: "Out-For-Delivery" },
    { option: "Order-Completed", value: "Completed" },
  ];

  const nav = useNavigate();
  function handleClickOne() {
    nav("/home/MyOrders/OrderHistory");
  }
  function handleClickTwo() {
    nav("/home/MyOrders");
  }
  const [selectedCompletedOrder, setSelectedCompletedOrder] = useState([]);
  const [selectedActiveOrderDetails, setSelectedCompletedOrderDetails] =
    useState([]);

  function handleOrderHistory(orderId, orders) {
    let selectedCompletedOrder;

    if (!show) {
      selectedCompletedOrder = completedOrdersList.items?.find(
        (order) => order.id === orderId
      );
    } else {
      {
        orders?.map((one) => (
          <>
            {Array.isArray(one) &&
              one.map((two) => {

                if (two.id === orderId) {
                  selectedCompletedOrder = { ...two }; // Copy 'two' object to selectedCompletedOrder
                }
              })}
          </>
        ));
      }
    }

    // Update state with the selected order details
    setSelectedCompletedOrderDetails((prevSelected) => [
      ...prevSelected,
      selectedCompletedOrder,
    ]);

    // Set selectedActiveOrder state
    setSelectedCompletedOrder(selectedCompletedOrder);

    // Log the selected order details

    // Navigate to another component passing order id in state
    if (selectedCompletedOrder && selectedCompletedOrder.id) {
      nav("/home/MyOrders/OrderHistoryDetails", {
        state: { orderid: selectedCompletedOrder.id },
      });

      // Store selected order ID in localStorage
      localStorage.setItem("Oid", JSON.stringify(selectedCompletedOrder.id));
    } else {
      console.error("Invalid selectedActiveOrder or selectedActiveOrder.id");
    }
  }

  // const OrderHistory = JSON.parse(localStorage.getItem('OrderReady')) || [];
  const [open, setOpen] = useState(false);
  const [onClose, setonClose] = useState(false);
  function handleFilter() {
    setOpen(true);
    setonClose(false);
  }
  function handleClose() {
    setOpen(false);
  }

  function MuiIcon() {
    return <img src={Calendar} alt="Calendar icon" width={25} />;
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const matchessm = useMediaQuery(theme.breakpoints.down("md"));
  const matcheslg = useMediaQuery(theme.breakpoints.down("lg"));

  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const styles = (theme) => ({
    icon: {
      fill: green,
    },
  });

  function handleClickOne() {
    {
      name
        ? nav("/home/MyOrders/ReadyForDelivery")
        : name2
        ? nav("/home/MyOrders/OutForDelivery")
        : nav("/home/MyOrders/ActiveOrders");
    }
  }
  function handleCancel() {
    setOpen(false);
  }

  const validationSchema = Yup.object().shape({
    dateFrom: Yup.date().nullable(),
    dateTo: Yup.date().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      dateFrom: null,
      dateTo: null,
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setHasNext(true);

      setOrders([]);
      setOffset(0);
      props.getSearchResult(
        {
          orderNumber,
          mobileNumber,
          status: values.status,
          dateFrom: values.dateFrom,
          dateTo: values.dateTo,
          deliveryDateFrom,
          deliveryDateTo,
          hasNext,
          first,
          offset,
        },
        localStorage.getItem("storeid")
      );

      setShow(true);
      setOpen(false);
      setOrders([]);
      setOffset(0);
    },
  });

  const LoadMore = () => {
    setFirst(first + 10);
    props.getSearchResult({
      first: first + 10,
      status: formik.values.status, // Use formik.values to get the current status
    });
  };

  

  
  return (
    <>
      <div style={{ marginLeft: "0px", marginRight: "0px" }}>
        <div style={{ display: "flex", gap: "3px" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={handleClickOne}
            className="MainActiveOrder"
          >
            <Typography
              className="MainActive"
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              {name ? name : name2 ? name2 : "Active Orders"}
            </Typography>
          </div>
          <div>
            <Typography
              className="MainActive"
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              /
            </Typography>
          </div>
          <div style={{ cursor: "pointer" }}>
            <Typography
              className="MainActives"
              fontSize={HeaderNavigationFontSize_20_xs_13}
            >
              Order History
            </Typography>
          </div>
        </div>
        <div className="add-txt-orderHistory">
          <CustomTextField
            width="80%"
            placeholder="Search Order History"
            borderRadius="50%"
            size="small"
            startAdornment={
              <InputAdornment position="start">
                <img src={search} alt="Search" style={{ marginRight: "8px" }} />
              </InputAdornment>
            }
          />
          <div className="filter-orderHistory">
            <img
              src={filtericon}
              style={{
                width: "30px",
                height: "17px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={handleFilter}
            />
            <Typography
              fontSize={contentFontSize_16}
              className="filter-text-OrderHistory"
              sx={{ cursor: "pointer" }}
            >
              Filters
            </Typography>
            {open && (
              <Dialog
                width="800px"
                height="800px"
                borderRadius="20px"
                open={open}
                onClose={handleClose}
                showCloseButton={false}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "700px",
                      height: "100%",
                      maxHeight: "450px",
                      padding: "0px 10px 0px 10px",
                      borderRadius: "20px", // Set your width here
                    },
                  },
                }}
              >
                <DialogTitle>
                  <Typography
                    fontSize={contentFontsize_28}
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "center",
                      color: "black",
                    }}
                  >
                    Filters
                  </Typography>
                </DialogTitle>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <DialogTitle style={{ color: "black" }}>
                      <Typography
                        sx={{
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "20px",
                        }}
                      >
                        Choose Date
                      </Typography>
                    </DialogTitle>
                  </div>
                  <div>
                    <DialogTitle
                      style={{
                        color: "#85BE49",
                        cursor: "pointer",
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        fontSize: "18px",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        Clear();
                      }}
                    >
                      Reset
                    </DialogTitle>
                  </div>
                </div>
                <DialogContent sx={{ padding: "0px 24px" }}>
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              value={formik.values.dateFrom}
                              onChange={(newValue) =>
                                formik.setFieldValue("dateFrom", newValue)
                              }
                              renderInput={(props) => <TextField {...props} />}
                              error={
                                formik.touched.dateFrom &&
                                Boolean(formik.errors.dateFrom)
                              }
                              helperText={
                                formik.touched.dateFrom &&
                                formik.errors.dateFrom
                              }
                              slots={{ openPickerIcon: MuiIcon }}
                              slotProps={{
                                textField: {
                                  placeholder: "From",
                                  size: matches ? "small" : "medium",
                                },
                                openPickerIcon: { sx: { fontSize: "50px" } },
                                action: {
                                  sx: { marginRight: "-8px", padding: "4px" },
                                },
                              }}
                              sx={{
                                width: "300px",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": { borderColor: "#85BE49" },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#85BE49",
                                  },
                                },
                                "& .MuiInputBase-input": {
                                  backgroundColor: "white",
                                  borderRadius: "10px",
                                  fontSize: matches ? "13px" : "16px",
                                  padding: "15px 20px",
                                },
                                "& .MuiIconButton-root": { marginRight: "5px" },
                              }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              value={formik.values.dateTo}
                              onChange={(newValue) =>
                                formik.setFieldValue("dateTo", newValue)
                              }
                              renderInput={(props) => <TextField {...props} />}
                              error={
                                formik.touched.dateTo &&
                                Boolean(formik.errors.dateTo)
                              }
                              helperText={
                                formik.touched.dateTo && formik.errors.dateTo
                              }
                              slots={{ openPickerIcon: MuiIcon }}
                              slotProps={{
                                textField: {
                                  placeholder: "To",
                                  size: matches ? "small" : "medium",
                                },
                              }}
                              sx={{
                                width: "300px",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": { borderColor: "#85BE49" },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#85BE49",
                                  },
                                },
                                "& .MuiInputBase-input": {
                                  backgroundColor: "white",
                                  borderRadius: "10px",
                                  fontSize: matches ? "13px" : "16px",
                                  padding: "15px 20px",
                                },
                                "& .MuiIconButton-root": { marginRight: "5px" },
                              }}
                              InputLabelProps={{ shrink: true }}
                              minDate={formik.values.dateFrom}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Typography sx={{ color: "black" }}>Status</Typography>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      <Select
                        className="custom-select_1"
                        variant="outlined"
                        style={{ width: "100%" }}
                        SelectProps={{ style: { width: "100%", height: 35 } }}
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.status}
                        name="status"
                        onChange={formik.handleChange}
                      >
                        {statusFilters.map((statusFilter) => (
                          <MenuItem
                            key={statusFilter.value}
                            style={{ width: "fit-content", cursor: "pointer" }}
                            value={statusFilter.value}
                          >
                            {statusFilter.option}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div
                      style={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                      }}
                    >
                      <div>
                        <CustomButton
                          color="black"
                          background="transparent"
                          height="50px"
                          borderRadius="20px"
                          borderColor="rgb(137, 199, 74, 1)"
                          width="280px"
                          onClick={handleCancel}
                        >
                          Cancel
                        </CustomButton>
                      </div>
                      <div>
                        <CustomButton
                          color="rgb(255, 255, 255)"
                          backgroundColor="rgb(137, 199, 74, 1)"
                          height="50px"
                          borderRadius="20px"
                          width="280px"
                          type="submit"
                        >
                          Apply
                        </CustomButton>
                      </div>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            )}
          </div>
        </div>

        <div className="containerscrollflex">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "30px",
            }}
            className="scrollable-content"
          >
            {!show ? (
              <>
              {completedOrdersList?.items?.length === 0 ? (
                "No results Found"
              ):(
                <>
                {completedOrdersList?.items?.map((each, index) => (
                  <CustomCard
                    key={index}
                    borderLeft="8px solid green"
                    width="100%"
                    height="120px"
                    padding="28px 0px 25px 40px"
                    onClick={() => handleOrderHistory(each.id)}
                    boxShadow="none"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={4}>
                        <Grid
                          item
                          xs={4}
                          sm={3}
                          md={3}
                          lg={3}
                          xl={3}
                          sx={{ paddingLeft: "0px" }}
                        >
                          <div className="OutColumnFlex">
                            <Typography
                              fontSize={HeaderNavigationFontSize_20_xs_13}
                              className="orderIdFontStyle"
                            >
                              #{each.orderNumber}
                            </Typography>
                            <Typography
                              fontSize={contentFontsize_14_xs_10}
                              className="dateFontStyle1"
                            >
                              {each.stats[5]?.created}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}></Grid>
                        <Grid item xs={3} sm={5} md={5} lg={5} xl={5}></Grid>
                        <Grid item xs={2} sm={1} md={1} lg={1} xl={1}>
                          <div className="inProgressOut2">
                            <img
                              src={Arrow}
                              alt="arrow icon"
                              style={{ marginTop: "0px", marginRight: "30px" }}
                              height="20px"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </CustomCard>
                ))}
                </>
              )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
                className="scrollable-content"
              >
                {orders[0]?.length === 0 ? (
                  "no results found"
                ) : (
                  <>
                    {orders.map((details, index) => (
                      <React.Fragment key={index}>
                        {details?.map((each, innerIndex) => (
                          <CustomCard
                            key={innerIndex}
                            borderLeft={
                              each.lastStatus === "Order-Placed"
                                ? "8px solid #EA4335"
                                : each.lastStatus === "Order-Accepted"
                                ? "8px solid #1D89E1"
                                : each.lastStatus === "Order-Ready"
                                ? "8px solid #FF9900"
                                : each.lastStatus === "Order-Rejected"
                                ? "8px solid red"
                                : "8px solid green"
                            }
                            onClick={() => handleOrderHistory(each.id, orders)}
                            width="100%"
                            height="100px"
                            padding="28px 0px 25px 40px"
                            boxShadow="none"
                          >
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid container spacing={4}>
                                <Grid
                                  item
                                  xs={4}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  sx={{ paddingLeft: "0px" }}
                                >
                                  <div className="OutColumnFlex">
                                    <Typography
                                      fontSize={
                                        HeaderNavigationFontSize_20_xs_13
                                      }
                                      className="orderIdFontStyle"
                                    >
                                      #{each.orderNumber}
                                    </Typography>
                                    {/* {each.stats.map((item)=>( */}
                                      <Typography
                                      fontSize={contentFontsize_14_xs_10} 
                                      className="dateFontStyle1"
                                      key={innerIndex}
                                    >
                                     {each.lastStatus === "Order-Accepted" ? each.stats[0].created : 
                                     each.lastStatus === "Order-Accepted" ? each.stats[1].created :
                                     each.lastStatus === "Order-Ready" ? each.stats[3].created
                                      :
                                     each.lastStatus === "Out-For-Delivery" ? each.stats[4].created :
                                     each.lastStatus === "Completed" ? each.stats[5].created :"null"}
                                    </Typography>
                                     {/* ))}  */}
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                ></Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                ></Grid>
                                <Grid item xs={2} sm={3} md={3} lg={3} xl={3}>
                                  <div className="inProgressOut2">
                                    <img
                                      src={Arrow}
                                      alt="arrow icon"
                                      style={{
                                        marginBottom: "0px",
                                        marginRight: "30px",
                                      }}
                                      height="20px"
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </CustomCard>
                        ))}
                      </React.Fragment>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {props?.searchData?.searchorder?.count >= first && show ? (
            <div>
              <CustomButton onClick={LoadMore}>
                <Typography className="Add-product-btn">Load More</Typography>
              </CustomButton>
            </div>
          ) : null}
          {completedOrdersList?.count >= first && !show ? (
            <div>
              <CustomButton onClick={LoadMores}>
                <Typography className="Add-product-btn">Load More</Typography>
              </CustomButton>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default OrderHistory;

// import React, { useState, useEffect } from "react";
// import { TextField, MenuItem, Select, Button, Modal } from "@mui/material";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { green } from "@mui/material/colors";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
// // import loader from '../../utils/image';
// import { Row, Col } from "react-bootstrap";
// import SearchResults from "../../SearchResults/index";
// import CustomTextField from "../../Commoncomponents/input";
// import CustomButton from "../../Commoncomponents/button";
// const OrderHistory = (props) => {
//   const [orderNumber, setOrderNumber] = useState(null);
//   const [mobileNumber, setMobileNumber] = useState(null);
//   const [status, setStatus] = useState(null);
//   const [dateTo, setDateTo] = useState("");
//   const [dateFrom, setDateFrom] = useState("");
//   const [deliveryDateTo, setDeliveryDateTo] = useState("");
//   const [deliveryDateFrom, setDeliveryDateFrom] = useState("");
//   const [offset, setOffset] = useState(0);
//   const [orders, setOrders] = useState([]);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [MobileError, setMobileError] = useState("");
//   const [isMobileError, setIsMobileError] = useState(false);
//   const [hasNext,setHasNext] = useState(false)
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   let storeid = localStorage.getItem('storeid')
//   useEffect(()=>{
//     props.getSearchResult(storeid)
//   },[])
//   useEffect(() => {
//       document.title = "Seller Portal | My Orders";
//       window.addEventListener('resize', updateIsMobile);
//       return () => {
//           window.removeEventListener('resize', updateIsMobile);
//       };
//   }, []);

//   const [prevProps, setPrevProps] = useState(null);

//   useEffect(() => {
//       setPrevProps(props);
//   }, [props]);

//   useEffect(() => {
//       if (props?.searchData !== prevProps?.searchData) {
//           setOrders([...orders, props?.searchData?.searchorder?.items?.map(p => p)]);
//           props.setOrders(orders);
//       }
//   }, [props?.searchData]);
//  console.log("order",orders)

//   const handleSearchField = (e) => {
//       const { name, value } = e.target;
//       setHasNext(true)
//       switch (name) {
//           case "orderNumber":
//               setOrderNumber(value);
//               if (isNaN(value)) {
//                   setErrorMessage("Please enter only numbers");
//               } else if (!isNaN(value) && errorMessage !== "") {
//                   setErrorMessage("");
//               }
//               break;
//           case "dateFrom":
//               setDateFrom(value);
//               break;
//           case "dateTo":
//               setDateTo(value);
//               break;
//           case "mobileNumber":
//               setMobileNumber(value);
//               if (!/^[0-9\b]+$/.test(value)) {
//                   setIsMobileError(true);
//                   setMobileError("Please enter only numbers");
//               } else if (value.length !== 10) {
//                   setIsMobileError(true);
//                   setMobileError("Please enter valid phone number.");
//               } else if (/^[0-9\b]+$/.test(value) && MobileError !== "") {
//                   setIsMobileError(false);
//                   setMobileError("");
//               } else if (value.length !== 10 && MobileError !== "") {
//                   setIsMobileError(false);
//                   setMobileError("");
//               }
//               break;
//           case "deliveryDateFrom":
//               setDeliveryDateFrom(value);
//               break;
//           case "deliveryDateTo":
//               setDeliveryDateTo(value);
//               break;
//           case "status":
//               setStatus(value);
//               break;
//           default:
//               break;
//       }
//   };

//   const Clear = () => {
//       setOrderNumber("");
//       setMobileNumber(null);
//       setStatus("");
//       setDateFrom("");
//       setDateTo("");
//       setDeliveryDateFrom("");
//       setDeliveryDateTo("");
//       setOffset(0);
//       setOrders([]);
//   };

//   const searchOrderHandle = () => {
//       if ((deliveryDateTo !== "" && deliveryDateFrom === "") || (deliveryDateTo === "" && deliveryDateFrom !== "")) {
//           Modal.confirm({
//               title: "Confirm",
//               icon: <ExclamationCircleOutlined />,
//               content: "Please select both Delivery date from and to.",
//               okText: "Ok",
//           });
//       } else if ((dateTo !== "" && dateFrom === "") || (dateTo === "" && dateFrom !== "")) {
//           Modal.confirm({
//               title: "Confirm",
//               icon: <ExclamationCircleOutlined />,
//               content: "Please select both Order From and To dates.",
//               okText: "Ok",
//           });
//       } else if (new Date(dateFrom) > new Date(dateTo) || new Date(deliveryDateFrom) > new Date(deliveryDateTo)) {
//           Modal.confirm({
//               title: "Confirm",
//               icon: <ExclamationCircleOutlined />,
//               content: "From date must be less than To date",
//               okText: "Ok",
//           });
//       } else {
//           setOrders([]);
//           setOffset(0);
//           props.getSearchResult({ orderNumber, mobileNumber, status, dateFrom, dateTo, deliveryDateFrom, deliveryDateTo ,hasNext}, localStorage.getItem('storeid'));
//       }
//   };

//   const LoadMore = () => {
//       setOffset(offset + 10);
//       props.getSearchResult({ status,offset, storeid });
//   };

//   const updateIsMobile = () => {
//       setIsMobile(window.innerWidth < 768);
//   };

//   const statusFilters = [
//       { option: "Order-Placed", value: "Order-Placed" },
//       { option: "Order-Accepted", value: "Order-Accepted" },
//       { option: "Order-Ready", value: "Order-Ready" },
//       { option: "Order-Rejected", value: "Order-Rejected" },
//       { option: "Order-Cancelled", value: "Order-Cancelled" },
//       { option: "Shopping-In-Progress", value: "Shopping-In-Progress" },
//       { option: "Order-Completed", value: "Completed" },
//   ];

//     return (
//         <>

//                 <div
//                     style={{
//                         backgroundColor: "white",
//                         border: "1px solid rgba(0, 0, 0, 0.16)",
//                         borderRadius: "10px",
//                         Top: "40px",
//                         // padding: "30px",
//                         width: "90vw",
//                         justifyContent: 'center',
//                         alignItems: 'center',
//                         textAlign: 'center'
//                     }}
//                 >
//                     <div>
//                         <h5 style={{ fontWeight: 600, paddingBottom: 20, fontSize: "23px" }}>
//                             Search Orders
//                         </h5>
//                         <div style={{ width: '100%' }}>
//                             <ThemeProvider theme={theme}>
//                                 <ul>
//                                     <Row style={{ width: '100%' }}>

//                                         <Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// <CustomTextField
//     variant="outlined"
//     InputProps={{ style: { height: 35 } }}
//     label="Orders from"
//     value={dateFrom}
//     name="dateFrom"
//     style={{ width: '100%' }}
//     onChange={(e) => {
//         handleSearchField(e);
//     }}
//     type="date"
//     InputLabelProps={{
//         shrink: true,
//     }}
// />
//                                         </Col>
//                                         <Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// <CustomTextField
//     variant="outlined"
//     InputProps={{ style: { height: 35 } }}
//     label="Orders to"
//     type="date"
//     value={dateTo}
//     name="dateTo"
//     onChange={(e) => {
//         handleSearchField(e);
//     }}
//     style={{ width: '100%' }}
//     InputLabelProps={{
//         shrink: true,
//     }}
// />
//                                         </Col>
//                                     </Row>

//                                                                             <Row style={{ width: '100%' }}>
//                                         <Col md={4} sm={8} xs={12} style={{ paddingTop: 15 }}>
// <Select
//     variant="outlined"
//     style={{ width: '100%' }}
//     SelectProps={{ style: { width: '100%', height: 35 } }}
//     InputLabelProps={{
//         shrink: true,
//     }}
//     label="Status"
//     value={status === null ? '' : status}
//     name="status"
//     onChange={(e) => {
//         handleSearchField(e);
//     }}
// >
//     {statusFilters.map((statusFilter) => (
//         <MenuItem
//             key={statusFilter.value}
//             style={{ width: 'fit-content', cursor: 'pointer' }}
//             value={statusFilter.value}
//         >
//             {statusFilter.option}
//         </MenuItem>
//     ))}
// </Select>
//                                         </Col>
//                                     </Row>
//                                     <Row style={{ width: '100%', paddingTop: 15 }}>
//                                         <Col xs={12} md={2} >
// <CustomButton
//     type="button"
//     style={{
//         justifyContent: ' center',
//         color: 'rgb(255, 255, 255)',
//         backgroundColor: 'rgb(137, 199, 74, 1)',
//         height: '37px',
//         fontSize: '16px',
//         fontWeight: '600',
//         borderRadius: '5px',
//         borderWidth: '1px',
//         borderStyle: ' solid',
//         borderColor: 'transparent',
//         borderImage: 'initial',
//         overflow: 'hidden',
//         outline: 'none',
//         minHeight: ' 14px',
//         fontFamily: 'Varela Round, sans-serif',
//         width: '100%',
//     }}
//     variant="success"
//     onClick={() => {
//         searchOrderHandle();
//     }}
// >
//     Search
// </CustomButton>
//                                         </Col>
//                                     </Row>
//                                     <Row style={{ width: '100%', paddingTop: 15 }}>
//                                         <Col xs={12} md={2} >
// <CustomButton
//     type="button"
//     style={{
//         justifyContent: ' center',
//         color: 'rgb(255, 255, 255)',
//         backgroundColor: 'rgb(137, 199, 74, 1)',
//         height: '37px',
//         fontSize: '16px',
//         fontWeight: '600',
//         borderRadius: '5px',
//         borderWidth: '1px',
//         borderStyle: ' solid',
//         borderColor: 'transparent',
//         borderImage: 'initial',
//         overflow: 'hidden',
//         outline: 'none',
//         minHeight: ' 14px',
//         fontFamily: 'Varela Round, sans-serif',
//         width: '100%',
//     }}
//     variant="success"
//     onClick={() => {
//         Clear();
//     }}
// >
//     Clear
// </CustomButton>
//                                         </Col>
//                                     </Row>
//                                 </ul>
//                             </ThemeProvider>

//                             {props?.searchingOrder && (
//                                 <div style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
//                                     no results                                </div>
//                             )}
//                             {props?.searchSuccess && (
//                                 <>
//                                     <Row style={{ width: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
//                                         <SearchResults orders={props.Orders} {...props} />
//                                     </Row>
//                                     <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}>
//                                         {`${1}`} - {`${offset + 10}`} of {props?.searchData?.searchorder?.count}
//                                     </div>

//                                     {props?.searchData?.searchorder?.items?.length >= 10 && (
//                                         <Row style={{ width: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
//                                             <Button
//                                                 style={{
//                                                     justifyContent: ' center',
//                                                     color: 'rgb(255, 255, 255)',
//                                                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                                                     boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
//                                                     width: '100px',
//                                                     height: '45px',
//                                                     fontSize: '16px',
//                                                     fontWeight: '600',
//                                                     borderWidth: '1px',
//                                                     borderStyle: ' solid',
//                                                     borderColor: 'transparent',
//                                                     borderImage: 'initial',
//                                                     overflow: 'hidden',
//                                                     outline: 'none',
//                                                     minHeight: ' 14px',
//                                                     fontFamily: 'Varela Round, sans-serif'
//                                                 }}
//                                                 onClick={() => LoadMore()}
//                                             >
//                                                 Load More
//                                             </Button>
//                                         </Row>
//                                     )}
//                                 </>
//                             )}

//                     </div>

//                 </div>
//            </div>
//         </>
//     );
// }

// export default OrderHistory;
