import { Default } from "./initialState";
export const SignUpReducer = (state = Default(), action) => {
  switch (action.type) {
    case "SIGNUP_FAILURE":
      return {
        ...state,
        gstFile: false,
        GstUrl: null,
        signupLoading: false,
        signupSuccess: false,
        signupError: action.payload,
        isSignLoading: false,
        isSignSuccess: false,
        isSignFailes: true,
        failureMessage: action.payload,
        subsriptionPlan: false,
        aadharUrl: null,
        LicenseFile: false,
        licenseUrl: null,
        PanFile:false,
        PanUrl:null,
        fssaiFile: false,
        fssaiUrl: null,
        imageSignedUrl: null,
        formsuccess:false,
        sameday:false
      };
    case "SIGNUP_NETWORK_FAILURE":
      return {
        ...state,
        signupLoading: false,
        signupSuccess: false,
        signupNetworError: action.payload,
        subsriptionPlan: false,
      };
    case "SUBSCRIPTION_CLICK":
      return {
        ...state,
        subsriptionPlan: action.payload,
        formsuccess:true

      };
    case "LOADING_IMAGE_UPLOAD":
      return {
        ...state,
        isImageLoading: true,
      };
    case "IMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        isImageLoading: false,
        isImageUploaded: true,
        imageSignedUrl: action.payload.signS3.Location,
      };
    case "IMAGE_UPLOAD_FAILURE":
      return {
        ...state,
        isImageLoading: false,
        isImageUploaded: false,
        isImageFailed: true,
        signedUrlError: action.payload,
      };
    case "HANDLE_CLOSE":
      return {
        ...state,
        isImageLoading: false,
        isImageUploaded: false,
        isImageFailed: false,
        isSignLoading: false,
        isSignSuccess: false,
        isSignFailes: false,
      };
    case "SIGNUP_WAITING":
      return {
        ...state,
        signupLoading: true,
        isSignLoading: true,
        isSignSuccess: false,
        isSignFailes: false,
        subsriptionPlan: false,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        isSignLoading: false,
        isSignSuccess: true,
        isSignFailes: false,
        successMessage: action.payload,
        signupLoading: false,
        signupSuccess: true,
        newImageUploaded: false,
        newImageUpload: false,
        multipleImageUploaded: false,
        subsriptionPlan: false,
      };
    case "CHANGE_VIEW_TABLE":
      return {
        ...state,
        chargeTableView: !state.chargeTableView,
      };
    case "SameDayDelivery":
      return {
        ...state,
        SameDayDelivery: action.payload,
      };
    case "Number of days":
      return {
        ...state,
        DeliveryDays: action.payload,
      };
    case "SET_MAX_DISTANCE":
      return {
        ...state,
        maxDistanceValue: action.payload,
      };
    case "OPENING_TIME":
      return {
        ...state,
        openTime: action.payload,
      };
    case "CLOSING_TIME":
      return {
        ...state,
        closeTime: action.payload,
      };
    case "CHANGE_SCHEDULE":
      let { key, index } = action.payload;
      const status = !state.timings[key].slots[index].status;
      let timings = [...state.timings];
      timings[key].slots[index].status = status;
      return {
        ...state,
        isChangeschedule: true,
        timings,
      };
    case "SLOT_CHECKBOX":
      let { label, value } = action.payload;
      let slotsChecked = [
        ...state.slotsChecked.slice(0, label),
        value,
        ...state.slotsChecked.slice(label + 1),
      ];
      return {
        ...state,
        slotsChecked,
      };
    case "LOADING_LOGOIMAGE_UPLOAD":
      return {
        ...state,
        newImageLoading: true,
        newImageUploaded: false,
        newImageFailed: false,
      };
    case "LOGOIMAGE_UPLOAD_SUCCESS":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: true,
        newImageUpload: true,
        newSignedUrl: action.payload,
        storeLogoUrl: action.payload.imageEditAndUpload.ETag,
        newImageFailed: false,
        newFile: false,
      };
    case "LOGOIMAGE_UPLOAD_FAILURE":
      return {
        ...state,
        newImageLoading: false,
        newImageUploaded: false,
        newImageFailed: true,
        newUrlError: action.payload,
        newUploadError: action.payload,
        storeLogoUrl: null,
      };
    // case "LOGOIMAGE_UPLOAD_FAILURE":
    // 	return {
    // 		...state,
    // 		newImageLoading: false,
    // 		newImageUploaded: false,
    // 		newImageFailed: true,

    // 	};
    case "CLOSE_SNACK":
      return {
        ...state,
        newImageFailed: false,
        newImageUpload: false,
        aadharFailed: false,
        aadharUploaded: false,
        licenseUploaded: false,
        licenseFailed: false,
        fssaiUploaded: false,
        fssaiFailed: false,
        GstUploaded: false,
        GstFailed: false,
        sellerRegSuccess: null,
      };

    case "DELIVERY_CHARGES":
      return {
        ...state,
        deliverycharges: true,
        DeliveryCharges: action.payload.charges,
      };
    case "AADHAR_UPLOAD_SUCCESS":
      return {
        ...state,
        aadharUploaded: true,
        aadharFailed: false,
        aadharUrl: action.payload.imageEditAndUpload.Location,
        aadharFile: false,
      };
    case "AADHAR_UPLOAD_FAILURE":
      return {
        ...state,
        aadharUploaded: false,
        aadharFailed: true,
        aadharError: action.payload,
      };
    case "aadharChange":
      return {
        ...state,
        aadharFile: action.payload,
      };
    case "aadharDelete":
      return {
        ...state,
        aadharFile: false,
        aadharUrl: null,
      };
    case "LicenseChange":
      return {
        ...state,
        LicenseFile: action.payload,
      };
    case "LicenseDelete":
      return {
        ...state,
        LicenseFile: false,
        licenseUrl: null,
      };
    case "LICENSE_UPLOAD_SUCCESS":
      return {
        ...state,
        licenseUploaded: true,
        licenseFailed: false,
        licenseUrl: action.payload.imageEditAndUpload.Location,
      };
    case "LICENSE_UPLOAD_FAILURE":
      return {
        ...state,
        licenseUploaded: false,
        licenseFailed: true,
        licenseError: action.payload,
      };
      case "PAN_UPLOAD_SUCCESS":
      return {
        ...state,
        panUploaded: true,
        panFailed: false,
        panUrl: action.payload.imageEditAndUpload.Location,
      };
      case "PAN_UPLOAD_FAILURE":
      return {
        ...state,
        panUploaded: false,
        panFailed: true,
        panError: action.payload,
      };
      case "PanChange":
      return {
        ...state,
        PanFile: action.payload,
      };
    case "PanDelete":
      return {
        ...state,
        PanFile: false,
        PanUrl: null,
      };
    case "fssaiChange":
      return {
        ...state,
        fssaiFile: action.payload,
      };
    case "fssaiDelete":
      return {
        ...state,
        fssaiFile: false,
        fssaiUrl: null,
      };
    case "FSSAI_UPLOAD_SUCCESS":
      return {
        ...state,
        fssaiUploaded: true,
        fssaiFailed: false,
        fssaiUrl: action.payload.imageEditAndUpload.Location,
      };
    case "FSSAI_UPLOAD_FAILURE":
      return {
        ...state,
        fssaiUploaded: false,
        fssaiFailed: true,
        fssaiError: action.payload,
      };
    case "GST_UPLOAD_SUCCESS":
      return {
        ...state,
        GstUploaded: true,
        GstFailed: false,
        GstUrl: action.payload.imageEditAndUpload.Location,
      };
    case "GST_UPLOAD_FAILURE":
      return {
        ...state,
        GstUploaded: false,
        GstFailed: true,
        GstError: action.payload,
      };
    case "GSTChange":
      return {
        ...state,
        gstFile: action.payload,
      };
    case "GSTDelete":
      return {
        ...state,
        gstFile: false,
        GstUrl: null,
      };
    case "LogoDelete":
      return {
        ...state,
        newSignedUrl: null,
        newImageUploaded: false,
        newImageUpload: false,
        newFile: false,
      };
    case "LogoChange":
      return {
        ...state,
        newFile: true,
      };
    case "LOGO_DELETE_SUCCESS":
      return {
        ...state,
        newSignedUrl: null,
        newImageUploaded: false,
        newImageUpload: false,
        newFile: false,
      };
    case "Image Change":
      return {
        ...state,
        newImageUploaded: false,
      };
      case "SameDayDelivery":
      return {
        ...state,
        sameday:true,
      };
    case "SET_BUSINESS_TYPE":
      return {
        ...state,
        businessTypeId: action.payload,
      };
    case "SET_BUSINESS_TYPE_ERROR":
      return {
        ...state,
        businessTypeError: action.payload,
      };
    case "SELLER_REG_REQUEST":
      return {
        ...state,
        loading: true,
        data: null,
        formNumber:null,
        error: null,
      };
    case "SELLER_REG_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        formNumber:action.payload.retailerSignup.formNumber,
        storestatus:action.payload.retailerSignup.storeStatus,
        error: null,
        sellerRegSuccess: true,
      };
    case "SELLER_REG_FAILURE":
      return {
        ...state,
        loading: false,
        data: null,
        formNumber:null,
        error: action.payload,
        sellerRegSuccess: false,
      };
    case "SEND_OTP_REQUEST":
      return {
        ...state,
        loading: true,
        message: null,
        error: null,
      };
    case "SEND_OTP_SUCCESS":
      return {
        ...state,
        loading: false,
        message: action.payload,
        error: null,
      };
    case "SEND_OTP_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.payload,
      };
    case "SIGNUP_FORM1_REQUEST":
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case "SIGNUP_FORM1_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case "SIGNUP_FORM1_FAILURE":
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case "SIGNUP_FORM2_REQ":
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case "SIGNUP_FORM2_SUCCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,

        error: null,
      };
    case "SIGNUP_FORM2_FAILURE":
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    case "SET_STORE_NAME":
      return { ...state, storeName: action.payload };
    case "SET_STORE_DESCRIPTION":
      return { ...state, storeDescription: action.payload };
    case "SET_STORE_LOCATION":
      return { ...state, storeLocation: action.payload };
    case "SET_STORE_COORDINATION":
      return { ...state, storeCoordinates: action.payload };
    case "SIGNUP_FORM3_REQ":
      return { ...state, loading: true, data: null, error: null };
    case "SIGNUP_FORM3_SUCCESS":
      return { ...state, loading: false, data: action.payload, error: null };
    case "SIGNUP_FORM3_FAILURE":
      return { ...state, loading: false, data: null, error: action.payload };
    case "SIGNUP_FORM4_SUCCESS":
      return { ...state, loading: false, data: action.payload,form4Success:true, error: null };
    case "SIGNUP_FORM4_FAILURE":
      return { ...state, loading: false, data: null, form4Success:false,error: action.payload };
    default:
      return state;
  }
};
