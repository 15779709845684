// import React from "react";
// import { Icon } from "@iconify/react";
// // material
// import { alpha, styled } from "@mui/material/styles";
// import { Card, Typography } from "@mui/material";
// import { fShortenNumber } from "./formatNumbers";
// // utils

// // ----------------------------------------------------------------------

// const RootStyle = styled(Card)(({ theme }) => ({
//   boxShadow: "none",
//   textAlign: "center",
//   padding: theme.spacing(5, 0),
//   color: "#005249",
//   backgroundColor: "#C8FACD",
//   borderRadius: "16px",
// }));

// const IconWrapperStyle = styled("div")(({ theme }) => ({
//   margin: "auto",
//   display: "flex",
//   borderRadius: "50%",
//   alignItems: "center",
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: "center",
//   marginBottom: theme.spacing(3),
//   color: '#007B55',
//   backgroundImage: `linear-gradient(135deg, ${alpha(
//     '#007B55',
//     0
//   )} 0%, ${alpha('#007B55', 0.24)} 100%)`,
// }));

// // ----------------------------------------------------------------------



// export default function Income(props) {
//   return (
//     <RootStyle>
//       <IconWrapperStyle>
//         <Icon icon="fa-solid:rupee-sign" width={24} height={24} />
//       </IconWrapperStyle>
//       <Typography variant="h3" style={{ fontWeight: 700, lineHeight: 1.5, color: "#005249" }}>
//         {fShortenNumber(props.DashboardDetails &&
//         props.DashboardDetails.data &&
//         props.DashboardDetails.data.dashboard &&
//         props.DashboardDetails.data.dashboard.totalAmountRecieved)}
//       </Typography>
//       <Typography
//         variant="subtitle2"
//         style={{ fontWeight: 600, lineHeight: 22 / 14, color: "#005249" }}
//         sx={{ opacity: 0.72 }}
//       >
//         Total Income
//       </Typography>
//     </RootStyle>
//   );
// }





//new code

import React from 'react'
import { Typography } from '../MUIComponents/index';
import {HeaderFontSize_24} from '../Typography/index';
import {HeaderNavigationFontSize_12} from '../Typography/index';
import { CircularProgressbar } from "react-circular-progressbar";
import { fShortenNumber } from "./formatNumbers";
import { symbol } from 'prop-types';

export default function Income({ upXlmatch, xlmatch, lgmatch, mdmatch, smmatch, xsmatch,...props}) {
  const percentage = 66;


  function formatIncome(income) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      {value:1e9,symbol:"B"},
    ];
  
    const item = lookup.find((item) => income >= item.value);
  
    if (item) {
      let formattedValue = (income / item.value).toFixed(1);
      if (formattedValue.includes(".")) {
        formattedValue = parseFloat(formattedValue).toFixed(1);
      }
      return `${formattedValue}${item.symbol}`;
    } else {
      return income?.toString(); 
    }
  }
  return (
    <>
    <div
                style={{
                  display: "grid",
                 
                  gridTemplateColumns: "auto auto",
                  height:"100%",
                  gap: upXlmatch
                    ? "50px"
                    : xlmatch
                    ? lgmatch
                      ? mdmatch
                        ? smmatch
                          ? xsmatch
                            ? "5px"
                            : "3px"
                          : "5px"
                        : "5px"
                      : "40px"
                    : "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    fontSize={HeaderFontSize_24}
                    className="dashboardFontFamily"
                  >
                   
                   {formatIncome(props?.NewDashboardDetails &&
         props?.NewDashboardDetails?.getDashboardNew &&
         props?.NewDashboardDetails?.getDashboardNew?.totalIncome)}
                  </Typography>
                  <Typography
                    fontSize={HeaderNavigationFontSize_12}
                    className="dashboardFontFamily"
                    style={{ color: "#B4B1B1" }}
                  >
                    Income
                  </Typography>
                  <Typography
                    fontSize={HeaderNavigationFontSize_12}
                    className="dashboardFontFamily"
                    style={{ color: "#659B1B" }}
                  >
                    +456.65
                  </Typography>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: smmatch?"flex-end":"center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      styles={{
                        root: {
                          height: upXlmatch
                            ? "59px"
                            : xlmatch
                            ? lgmatch
                              ? mdmatch
                                ? smmatch
                                  ? xsmatch
                                    ? "45px"
                                    : "45px"
                                  : "45px"
                                : "45px"
                              : "50px"
                            : "100px",
                          width: upXlmatch
                            ? "59px"
                            : xlmatch
                            ? lgmatch
                              ? mdmatch
                                ? smmatch
                                  ? xsmatch
                                    ? "45px"
                                    : "45px"
                                  : "45px"
                                : "45px"
                              : "50px"
                            : "100px",
                        },
                        path: {
                          stroke: `#20866E`,
                          strokeLinecap: "butt",
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          transform: "rotate(0.15turn)",
                          transformOrigin: "center center",
                        },
                        trail: {
                          stroke: "rgba(32, 134, 110, 0.25",
                          strokeLinecap: "butt",
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        text: {
                          fill: "green",
                          fontSize: "13px",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

    
    </>
  )
}
