

// import React from 'react';
// import Button from '@mui/material/Button';
// import PropTypes from 'prop-types';
// import DeleteIcon from '@mui/icons-material/Delete';
// const CustomButton = ({
//   width,
//   height,
//   borderRadius,
//   hasIcon,
//   textColor,
//   backgroundColor,
//   borderColor,
//   children,
//   onClick,
// }) => {
  
//   const buttonStyles = {
//     width,
//     height,
//     borderRadius,
//     color: backgroundColor === 'white' ? 'green' : textColor,
//     backgroundColor: backgroundColor === 'white' ? 'white' : backgroundColor,
//     border: `2px solid ${backgroundColor === 'white' ? 'green' : borderColor}`,
    
//   };

//   return (
//       <Button style={buttonStyles} onClick={onClick}  >
//       {children}
//     </Button>
//   );
// };

// CustomButton.propTypes = {
//   width: PropTypes.string,
//   height: PropTypes.string,
//   hasIcon: PropTypes.bool,
//   borderRadius: PropTypes.string,
//   textColor: PropTypes.string,
//   backgroundColor: PropTypes.string,
//   borderColor: PropTypes.string,
//   icon:PropTypes.node,
//   children: PropTypes.node.isRequired,
//   onClick: PropTypes.func,
// };

// CustomButton.defaultProps = {
//   width: 'auto',
//   height: 'auto',
//   icon: null,
//   borderRadius: '10px',
//   textColor: 'white',
//   backgroundColor: 'green',
//   borderColor: 'green',
//   onClick: () => {},
// };

// export default CustomButton;




import React from 'react';
import { Button } from '../../MUIComponents/index';
import PropTypes from 'prop-types';

const CustomButton = ({
  width,
  height,
  hasIcon,
  borderRadius,
  color,
  background,
  iconPosition,
  icon,
  children,
  onClick,
  variant,
  borderColor,
  padding,
  size,
  matchbtn,
  matchPadding,
  type,
  disabled,
}) => {
  
  const buttonStyles = {
    width,
    height,
    borderRadius,
    borderColor,
    color,
    background,
    border: `1px solid ${borderColor}`,
    boxShadow: 'none',
    justifyContent: matchbtn ? 'space-around' : undefined,
    padding: matchPadding ? padding : undefined,
    outline: 'none',
  };

  const iconComponent = hasIcon && icon ? React.cloneElement(icon) : null;

  return (
    <Button
      style={buttonStyles}
      onClick={onClick}
      size={size}
      startIcon={hasIcon && iconPosition === 'startIcon' ? iconComponent : null}
      endIcon={hasIcon && iconPosition !== 'startIcon' ? iconComponent : null}
      type={type}
      disabled={disabled}
      disableRipple
      sx={{
        '&:focus': {
          outline: 'none',
          borderColor,
        },
        '&:active': {
          outline: 'none',
          borderColor,
        },
        '&:hover': {
          borderColor,
        },
      }}
    >
      {children}
    </Button>
  );
};

CustomButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  hasIcon: PropTypes.bool,
  borderRadius: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.string,
  iconPosition: PropTypes.oneOf(['startIcon', 'endIcon']),
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  borderColor: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  variant: PropTypes.oneOf(['contained', 'outlined']),
  innerColor: PropTypes.string,
  size: PropTypes.string,
  matchbtn: PropTypes.bool,
  matchPadding: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.string,
};

CustomButton.defaultProps = {
  width: 'auto',
  height: 'auto',
  hasIcon: false,
  borderRadius: '4px',
  color: 'white',
  background: '#659B1B',
  iconPosition: 'startIcon',
  onClick: () => {},
  variant: 'contained',
  matchbtn: false,
};

export default CustomButton;
