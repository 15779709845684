import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import FormHeaders from "../../Components/FormHeaders";
import "../ImageModal/index.css";
import EditShopAssistantIndex from "./EditShopAssistantIndex";
import { message } from "antd";
import { useTheme,useMediaQuery } from "../MUIComponents/index";

const EditShopAssistatnEditImage = (params) => {

	const {open3,setOpen3,profileEdit2,setProfileEdit2}=params

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	
const handleUpload=()=>{
	if(params.newSignedLogoUrl){
		
		message.success({
			content: "Image uploaded successfully",
			className: "custom-class",
			style: {
				marginTop: "10vh",
				marginLeft: "15vw",
			},
		  });
		  
		  setProfileEdit2(params.newSignedLogoUrl)
		  params.handleImageEdit();
		setOpen3(false)
	}
}

	return (
		<Modal show={open3} onHide={()=>{
			setOpen3(false)
		}} >
			<Modal.Body style={{padding:matches?"20px 20px":"20px 40px"}}>
				<FormHeaders header="Logo Upload"/>
				<EditShopAssistantIndex {...params}/>
				<div style={{display:"flex",gap:"20px",justifyContent:"flex-end"}}>
				{params.newSignedLogoUrl&&(<div
					style={{
						
						
						color: "#D40000",
						fontSize: "18px",
						fontWeight: 600,
						cursor:"pointer",
						fontFamily:"Roboto"
						
					  }}
					  onClick={handleUpload}>
					Upload
				</div>)}
				<div style={{color:"#8B8989"}}>|</div>
				<div
					style={{
						
						color: "#8B8989",
						fontSize: "18px",
						fontWeight: 600,
						cursor:"pointer",
						fontFamily:"Roboto"
						
					  }}
					  onClick={() => {
						
						setOpen3(false); // Set open3 to false to close the modal
					}}>
					Close
				</div>

				</div>
				
			</Modal.Body>
		</Modal>
	);
};
export default EditShopAssistatnEditImage;

