import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";

const MapEdit = ({ props, mapCoordinates }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  // Helper function to safely get coordinates
  const getCoordinatesString = (coordinates) => {
    if (Array.isArray(coordinates) && coordinates.length >= 2) {
      return `lat:${Number(coordinates[1])},lng:${Number(coordinates[0])}`;
    }
    return "Select Location from Map";
  };

  return (
    <Grid item xs={12}>
      <div>
        <TextField
          variant="outlined"
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#85BE49',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#85BE49',
              },
            },
            '& .MuiInputBase-input': {
              backgroundColor: 'white',
              borderRadius: "10px"
            },
          }}
          InputProps={{
            sx: {
              fontSize: matches && '13px',
            },
          }}
          size={matches ? "small" : "normal"}
          InputLabelProps={{
            shrink: true,
          }}
          id="standard-basic"
          value={
            mapCoordinates
              = `lat:${Number(mapCoordinates.lat)},lng:${Number(mapCoordinates.lng)}`
              
          }
          // onClick={props.mapEditModal}
        />
      </div>
    </Grid>
  );
};

export default MapEdit;