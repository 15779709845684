import React from "react";
import { Row, Col } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import SearchProductCard from "../SearchDetailsCards";
import OrderStatusStepper from "../StatusStepper";
import "./index.css";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import client from "../../Configurations/apollo";
import "./index.css"
import {
	
	SET_PAYMENT
} from "../SearchDetails/Query";

const customerFields = [
	{ label: "Customer Name", value: "customerName" },
	{ label: "Mobile Number", value: "mobileNumber" },
];
const deliveryFields = [
	{ label: "Delivery type", value: "deliveryType" },
	{ label: "Delivery address", value: "deliveryAddress" },
	{ label: "Delivery date", value: "deliveryDate" },
	{ label: "Delivery slot", value: "deliveryTime" },
	{ label: "Instructions from customer ", value: "specialInstructions" },
];

const OrderDetails = (props) => {
	const [open, setOpen] = React.useState(false);
	const [finalBillAmount, setFinalBill] = React.useState(null);
	const [paymentClick,setPayment]= React.useState(false);

	const handleClickOpen = () => {
		setFinalBill(props.searchDetails&& props.searchDetails.totalPrice)
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const isSmallScreen = window.innerWidth <= 768; 



	const ActivateClick = () => {
		client
			.mutate({
				mutation: SET_PAYMENT,
				variables: {
					orderid:props.location.state.orderId,
					finalBillAmount:Number(finalBillAmount)
		},	
			})
			.then((res) => {
				if (res) {	
				
					handleClose()
					setFinalBill(null)
					setPayment(false)
						props.getSearchDetails(props.location.state.orderId)
				} 
			});
	}

	// 

	const { getSearchDetails } = props;
	React.useEffect(() => {
		setFinalBill(props.searchDetails&&props.searchDetails.finalBillAmount)
		if(props.searchDetailsSuccess===true)
		{
			if(props.searchDetails&&props.searchDetails.finalBillAmount===null)
			{
				setPayment(true)}
			else if(finalBillAmount===null)
			{
				setPayment(true)}
			else if(props.RejectClick===true)
			{
				setPayment(true)}
			else if(props.rejected===true)
			{
				setPayment(true)}
			else if(props.searchDetails&&props.searchDetails.stats[props.searchDetails.stats.length-1].status==="Order-Rejected")
			{
				setPayment(false)}
			else if(props.searchDetails&&props.searchDetails.stats[props.searchDetails.stats.length-1].status=="Order-Cancelled")
			{
				setPayment(false)}
			else
			{
				setPayment(false)}
		}
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.searchDetailsSuccess]);


	React.useEffect(() => {

		// if(props.searchDetailsSuccess===true)
		// {
			setFinalBill(props.searchDetails&&props.searchDetails.finalBillAmount)
			if(props.searchDetails&&props.searchDetails.finalBillAmount===null)
			{
				setPayment(true)}
			
			else if(props.RejectClick===true)
			{
				setPayment(true)}
			else if(props.rejected===true)
			{
				setPayment(true)}
			else if(props.searchDetails&&props.searchDetails.stats[props.searchDetails.stats.length-1].status==="Order-Rejected")
			{
				setPayment(false)}
			else if(props.searchDetails&&props.searchDetails.stats[props.searchDetails.stats.length-1].status=="Order-Cancelled")
			{
				setPayment(false)}
			else
			{
				setPayment(false)}
		// }
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.searchDetails]);



	React.useEffect(() => {
		props.getSearchDetails(props.location.state.orderId);
	
		setFinalBill(props.searchDetails&&props.searchDetails.finalBillAmount)
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getSearchDetails]);
	return (
		<div>
			{isSmallScreen ?(
				<div style={{ 
			width:'60%', 
			backgroundColor:'white',
			boxShadow:'0 4px 8px 0 rgba(53, 105, 128, 0.3),0 6px 20px 0 rgba(165, 200, 213, 0.41)',
			borderRadius:'18px',
			Top: "40px",
			padding: "20px",
			display:'flex',
			flexDirection: 'column',
			}} className="orderdetails">
			<div >
			<div  style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
				<b style={{fontWeight:600,justifyContent:'center',alignItems:'center',textAlign:'center',fontSize:'23px'}}>
					{"Order details - " + props.location.state.orderNumber} 
				</b>
				</div>
				<form id="search-details" style={{width:'100%'}}>
					<div className="delivery-section">
					<h5 style={{fontWeight:600}}>{"Customer details"}</h5>
						<ul  style={{padding:'0px', marginTop:'-1rem'}}>
							{props.searchDetails &&
								customerFields.map((detail,index) => {
									return (
										<React.Fragment key={index}>
											<Row style={{paddingBottom:'8px'}}>
												<Col>{detail.label}</Col>
												<Col >{':\t\t'+ props.searchDetails[detail.value]}</Col>
												 
												  </Row>
										</React.Fragment>
									);
								})}
						</ul>
					</div>
					<div  style={{marginTop:'2rem'}}>
					<h5 style={{fontWeight:600}}>{"Order details"}</h5> 
						<ul  style={{padding:"0px",marginTop:'-1rem'}}>
							{props.searchDetails &&
								deliveryFields.map((detail) => {
									return (
										<>
										<Row style={{paddingBottom:'8px'}}>
												<Col>{detail.label}</Col>
												<Col >
												{props.searchDetails[detail.value]?':\t '+ props.searchDetails[detail.value]:':\t ----------'}
												</Col> 
												  </Row>
										</>
									);
								})}
						</ul>
					</div>
					<div style={{marginTop:'2rem'}}>
					<h5 style={{fontWeight:600}}>{"Product Details"}</h5> 
					<ul  style={{padding:"0px",marginTop:'-2rem'}}>
								{props.searchDetails &&
									props.searchDetails.products.length &&
									<SearchProductCard {...props} />
								}
							</ul>
							<ul style={{ float: 'right',padding:"0px", marginTop:'0rem', marginRight:'3rem' }}>
								<li style={{margin:'0px'}}>
									<div>
										<Typography variant="button" >Total product price :
											<i
												style={{ paddingLeft: 5, paddingRight: 5 }}
												className="fa fa-inr"
												aria-hidden="true" />
											{
												parseFloat(props.searchDetails && props.searchDetails.totalPrice).toFixed(2)
												}
										</Typography>
									</div>
									<div>
										<Typography variant="button">Delivery charge :
											<i
												style={{ paddingLeft: 5, paddingRight: 5 }}
												className="fa fa-inr"
												aria-hidden="true" />
											{props.searchDetails && props.searchDetails.deliveryCharge=== null ?
												0
												: parseFloat(props.searchDetails && props.searchDetails.deliveryCharge).toFixed(2)}
										</Typography>
									</div>
									<div>
										<Typography variant="button">Discount Price :
											<i
												style={{ paddingLeft: 5, paddingRight: 5 }}
												className="fa fa-inr"
												aria-hidden="true" />
											{props.searchDetails && props.searchDetails.discountPrice=== null ?
												0
												: parseFloat(props.searchDetails && props.searchDetails.discountPrice).toFixed(2)}
										</Typography>
									</div>
									<div>
										<Typography variant="button">Total amount payable :
											<i
												style={{ paddingLeft: 5, paddingRight: 5 }}
												className="fa fa-inr"
												aria-hidden="true" />
											{(props.searchDetails && props.searchDetails.deliveryCharge )+( props.searchDetails&& props.searchDetails.totalPrice) - (props.searchDetails && props.searchDetails.discountPrice)}
										</Typography>
									</div>
									{/* {paymentClick===true?
									
									<div style={{ paddingTop: '10px' }}>
									<button type='button' className="ActivateButton" onClick={handleClickOpen}>
										Activate Payment
									</button>
								</div>
								:null} */}
									
								</li>
							</ul>
					
										<div>
						<Typography variant="button">Payment status :
											
											{(props.searchDetails && props.searchDetails.paymentStatus!==0)?"Paid":"Not-Paid"}
										</Typography>
									</div>

					</div>
					<br/>
					<div  style={{marginTop:'1rem'}}>
					<h5 style={{fontWeight:600}} sx={{'@media screen and (min-width:600px) and (max-width:899px)':{
      position:"relative",right:'40px',} }}
					>{"Order Status"}</h5> 
					<div style={{marginTop:'-2rem'}}>
						<OrderStatusStepper
						    scheduleAlert={props.scheduleAlert}
							orderNumber={props.searchDetails && props.searchDetails.orderNumber}
						     deliveryDate={props.searchDetails && props.searchDetails.deliveryDate}
							 deliveryTime={props.searchDetails && props.searchDetails.deliveryTime}
						    commentsSeller={props.searchDetails && props.searchDetails.commentsSeller}
							status={props.searchDetails && props.searchDetails.stats}
							statusChangeHandler={props.postStatusChange}
							orderid={props.searchDetails && props.searchDetails.id}
							rejectedStatus={props.rejectedStatusHandler}
							paymentStatus={props.searchDetails && props.searchDetails.paymentStatus}
							shopAssistant={props.searchDetails && props.searchDetails.shopAssistantId}
							{...props}
						/>
						</div>
					</div>
				</form>
				<Dialog open={open} onClose={handleClose} onBackdropClick="false">
					<DialogTitle>Final bill amount</DialogTitle>
					<div style={{padding:'20px'}}>
						<TextField
							type="number"
							variant="outlined"
							onKeyPress={(event) => {
								if (event?.key === "-" || event?.key === "+") {
								  event.preventDefault();
								}
							  }}
							InputProps={{inputProps: { min: 0 } }}
							InputLabelProps={{
								shrink: true,
							  }}
							label="Final bill amount"
							onChange={(e) => {
								setFinalBill(e.target.value)
							}}
							name='finalBillAmount'
							value={finalBillAmount}
						/>
						</div>
					<DialogActions>
						<button className="CloseButton" onClick={handleClose}>Cancel</button>
						<button className="ActivateButton" onClick={() => {
							ActivateClick()
							
						}
						}>Submit</button>
					</DialogActions>
				</Dialog>
			</div>
		</div>
			) : (
				<div style={{ 
					width:'100%', 
					backgroundColor:'white',
					boxShadow:'0 4px 8px 0 rgba(53, 105, 128, 0.3),0 6px 20px 0 rgba(165, 200, 213, 0.41)',
					borderRadius:'18px',
					Top: "40px",
					padding: "20px",
					display:'flex',
					flexDirection: 'column',
					}} className="orderdetails">
					<div >
					<div  style={{justifyContent:'center',alignItems:'center',textAlign:'center'}}>
						<b style={{fontWeight:600,justifyContent:'center',alignItems:'center',textAlign:'center',fontSize:'23px'}}>
							{"Order details - " + props.location.state.orderNumber} 
						</b>
						</div>
						<form id="search-details">
							<div className="delivery-section">
							<h5 style={{fontWeight:600}}>{"Customer details"}</h5>
								<ul className="details-list">
									{props.searchDetails &&
										customerFields.map((detail,index) => {
											return (
												<React.Fragment key={index}>
													<Row style={{paddingBottom:'8px'}}>
														<Col>{detail.label}</Col>
														<Col >{':\t\t'+ props.searchDetails[detail.value]}</Col>
														 
														  </Row>
												</React.Fragment>
											);
										})}
								</ul>
							</div>
							<div className="delivery-section">
							<h5 style={{fontWeight:600}}>{"Order details"}</h5> 
								<ul className="details-list">
									{props.searchDetails &&
										deliveryFields.map((detail) => {
											return (
												<>
												<Row style={{paddingBottom:'8px'}}>
														<Col>{detail.label}</Col>
														<Col >
														{props.searchDetails[detail.value]?':\t '+ props.searchDetails[detail.value]:':\t ----------'}
														</Col> 
														  </Row>
												</>
											);
										})}
								</ul>
							</div>
							<div className="product-section">
							<h5 style={{fontWeight:600}}>{"Product Details"}</h5> 
							<ul>
										{props.searchDetails &&
											props.searchDetails.products.length &&
											<SearchProductCard {...props} />
										}
									</ul>
									<ul style={{ float: 'right' }}>
										<li>
											<div>
												<Typography variant="button">Total product price :
													<i
														style={{ paddingLeft: 5, paddingRight: 5 }}
														className="fa fa-inr"
														aria-hidden="true" />
													{
														parseFloat(props.searchDetails && props.searchDetails.totalPrice).toFixed(2)
														}
												</Typography>
											</div>
											<div>
												<Typography variant="button">Delivery charge :
													<i
														style={{ paddingLeft: 5, paddingRight: 5 }}
														className="fa fa-inr"
														aria-hidden="true" />
													{props.searchDetails && props.searchDetails.deliveryCharge=== null ?
														0
														: parseFloat(props.searchDetails && props.searchDetails.deliveryCharge).toFixed(2)}
												</Typography>
											</div>
											<div>
												<Typography variant="button">Discount Price :
													<i
														style={{ paddingLeft: 5, paddingRight: 5 }}
														className="fa fa-inr"
														aria-hidden="true" />
													{props.searchDetails && props.searchDetails.discountPrice=== null ?
														0
														: parseFloat(props.searchDetails && props.searchDetails.discountPrice).toFixed(2)}
												</Typography>
											</div>
											<div>
												<Typography variant="button">Total amount payable :
													<i
														style={{ paddingLeft: 5, paddingRight: 5 }}
														className="fa fa-inr"
														aria-hidden="true" />
													{(props.searchDetails && props.searchDetails.deliveryCharge )+( props.searchDetails&& props.searchDetails.totalPrice) - (props.searchDetails && props.searchDetails.discountPrice)}
												</Typography>
											</div>
											{/* {paymentClick===true?
											
											<div style={{ paddingTop: '10px' }}>
											<button type='button' className="ActivateButton" onClick={handleClickOpen}>
												Activate Payment
											</button>
										</div>
										:null} */}
											
										</li>
									</ul>
							
												<div>
								<Typography variant="button">Payment status :
													
													{(props.searchDetails && props.searchDetails.paymentStatus!==0)?"Paid":"Not-Paid"}
												</Typography>
											</div>
		
							</div>
							<br/>
							<div className="product-section">
							<h5 style={{fontWeight:600}} sx={{'@media screen and (min-width:600px) and (max-width:899px)':{
			  position:"relative",right:'40px',} }}
							>{"Order Status"}</h5> 
								<OrderStatusStepper
									scheduleAlert={props.scheduleAlert}
									orderNumber={props.searchDetails && props.searchDetails.orderNumber}
									 deliveryDate={props.searchDetails && props.searchDetails.deliveryDate}
									 deliveryTime={props.searchDetails && props.searchDetails.deliveryTime}
									commentsSeller={props.searchDetails && props.searchDetails.commentsSeller}
									status={props.searchDetails && props.searchDetails.stats}
									statusChangeHandler={props.postStatusChange}
									orderid={props.searchDetails && props.searchDetails.id}
									rejectedStatus={props.rejectedStatusHandler}
									paymentStatus={props.searchDetails && props.searchDetails.paymentStatus}
									shopAssistant={props.searchDetails && props.searchDetails.shopAssistantId}
									{...props}
								/>
							</div>
						</form>
						<Dialog open={open} onClose={handleClose} onBackdropClick="false">
							<DialogTitle>Final bill amount</DialogTitle>
							<div style={{padding:'20px'}}>
								<TextField
									type="number"
									variant="outlined"
									onKeyPress={(event) => {
										if (event?.key === "-" || event?.key === "+") {
										  event.preventDefault();
										}
									  }}
									InputProps={{inputProps: { min: 0 } }}
									InputLabelProps={{
										shrink: true,
									  }}
									label="Final bill amount"
									onChange={(e) => {
										setFinalBill(e.target.value)
									}}
									name='finalBillAmount'
									value={finalBillAmount}
								/>
								</div>
							<DialogActions>
								<button className="CloseButton" onClick={handleClose}>Cancel</button>
								<button className="ActivateButton" onClick={() => {
									ActivateClick()
									
								}
								}>Submit</button>
							</DialogActions>
						</Dialog>
					</div>
				</div>
			)}
		</div>
		
	);
};
export default OrderDetails;
