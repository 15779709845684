import {React,useState,useEffect} from "react";
import { Grid } from "@mui/material";
import { InputField } from "../../../FormFields";
import AadharUpload from "../../ImageCropper/AadharUpload";
import LicenseUpload from "../../ImageCropper/LicenseUpload";
import FssaiUpload from "../../ImageCropper/FssaiUpload";
import GstUpload from "../../ImageCropper/GstUpload";
import { Row, Col } from "react-bootstrap";
import { Typography } from "../../../MUIComponents/index";
import { contentFontSize_16 } from "../../../Typography";

export default function UploadForm() {

  const [aadhar,setAadhar]= useState()

  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);
  // const { formValues } = props;
  // const {
  //   storeName,
  //   businessType,
  //   // fssaiNumber
  // } = formValues;
  // const {
  //   formField: { fssaiNumber },
  // } = props;
  const storeName = localStorage.getItem("storeName");
  return (
    <>
      <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
        <Row>
          <Col>
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Upload your FSSAI (Store){" "}
              {/* <span style={{ color: "#EA4335" }}>*</span> */}
            </Typography>

            <FssaiUpload storeName={storeName} />
          </Col>
        </Row>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Row>
          <Col>
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Upload your Aadhar for KYC verification{" "}
              {/* <span style={{ color: "#EA4335" }}>*</span> */}
            </Typography>
            <AadharUpload aadhar={aadhar} setAadhar={setAadhar} storeName={storeName} />
          </Col>
        </Row>
      </Grid>
      <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
        <Row>
          <Col>
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Upload your License (Store){" "}
              {/* <span style={{ color: "#EA4335" }}>*</span> */}
            </Typography>
            <LicenseUpload storeName={storeName} />
          </Col>
        </Row>
      </Grid>
      <Grid item xs={12} sm={12} style={{ paddingTop: 10 }}>
        <Row>
          <Col>
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              Upload your GST{" "}
              {/* <span style={{ color: "#EA4335" }}>*</span> */}
            </Typography>

            <GstUpload  storeName={storeName} />
          </Col>
        </Row>
      </Grid>
      

      {/* <Grid item xs={12} sm={12} style={{ paddingTop: 30 }}>
      
        {props.businessTypeId.includes(1) ? (
          <>
          <Row>
            <Col md={6}>
            <InputField
              name={fssaiNumber.name}
              label={fssaiNumber.label}
              fullWidth
            />
            </Col>
            </Row>
            <Row>
            <Col md={6}>
            <h6 style={{ paddingLeft: 10, paddingTop: 10 }}>
          Upload your FSSAI document
        </h6>
            <FssaiUpload {...props} storeName={storeName} />
            </Col>
         </Row>
         </>
        ) : props.businessTypeId.includes(2) ? (
          <>
          <Row>
            <Col md={6}>
            <InputField
              name={fssaiNumber.name}
              label={fssaiNumber.label}
              fullWidth
            />
            </Col>
            </Row>
            <Row>
            <Col md={6}>
            <h6 style={{ paddingLeft: 10, paddingTop: 10 }}>
          Upload your FSSAI document
        </h6>
            <FssaiUpload {...props} storeName={storeName} />
            </Col>
         </Row>
         </>
        ) : props.businessTypeId.includes(4) ? (
          <>
          <Row>
            <Col md={6}>
            <InputField
              name={fssaiNumber.name}
              label={fssaiNumber.label}
              fullWidth
            />
            </Col>
            </Row>
            <Row>
            <Col md={6}>
            <h6 style={{ paddingLeft: 10, paddingTop: 10 }}>
          Upload your FSSAI document
        </h6>
            <FssaiUpload {...props} storeName={storeName} />
            </Col>
         </Row>
         </>
        ) : props.businessTypeId.includes(3) ? (
          <>
          <Row>
            <Col md={6}>
            <InputField
              name={fssaiNumber.name}
              label={fssaiNumber.label}
              fullWidth
            />
            </Col>
            </Row>
            <Row>
            <Col md={6}>
            <h6 style={{ paddingLeft: 10, paddingTop: 10 }}>
          Upload your FSSAI document
        </h6>
            <FssaiUpload {...props} storeName={storeName} />
            </Col>
         </Row>
         </>
        ) : props.businessTypeId.includes(5) ? (
          <>
          <Row>
            <Col md={6}>
            <InputField
              name={fssaiNumber.name}
              label={fssaiNumber.label}
              fullWidth
            />
            </Col>
            </Row>
            <Row>
            <Col md={6}>
            <h6 style={{ paddingLeft: 10, paddingTop: 10 }}>
          Upload your FSSAI document
        </h6>
            <FssaiUpload {...props} storeName={storeName} />
            </Col>
         </Row>
         </>
        ) : null}
      </Grid> */}
    </>
  );
}
