import React, { useState, useEffect } from 'react';
import { Typography, Checkbox, useTheme, useMediaQuery, FormControl, Select, MenuItem, InputLabel, Box } from '../../MUIComponents/index';
import { contentFontSize_16 } from '../../Typography';

export default function SameDayDelivery({ onDeliveryChange }) {
  const [option, setOption] = useState(true); 
  const [deliveryTime, setDeliveryTime] = useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));

  const Days = [
    { value: 1, label: "Within 24 hours" },
    { value: 2, label: "Within 2 days" },
    { value: 3, label: "Within 3 days" },
  ];

  useEffect(() => {
    if (!option) {
      onDeliveryChange(false, 1); 
    } else {
      onDeliveryChange(true, null); 
    }
  }, [option]); 

  const handleCheckboxChange = (value) => {
    setOption(value);
    if (value) {
      setDeliveryTime(null); 
      onDeliveryChange(true, null);
    } else {
      setDeliveryTime(1); 
      onDeliveryChange(false, 1);
    }
  };

  const handleDropdownChange = (event) => {
    const newDeliveryTime = event.target.value;
    setDeliveryTime(newDeliveryTime);
    onDeliveryChange(false, newDeliveryTime);
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <Typography
       className="StoreLabelStyles"
       fontSize={contentFontSize_16}
      >
        Do you support same day delivery?<span style={{ color: "#EA4335" }}>*</span>
      </Typography>

      <Box style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Box style={{ display: "flex", gap: smmatch ? "20px" : "10px" }}>
          <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Checkbox
              checked={option === true}
              style={{
                padding: 0,
                borderRadius: 0,
                color: "#85BE49",
              }}
              size={matches ? "small" : "medium"}
              onChange={() => handleCheckboxChange(true)}
            />
            <Typography
               className="StoreLabelStyles"
               fontSize={contentFontSize_16}
            >
              Yes
            </Typography>
          </Box>

          <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Checkbox
              checked={option === false}
              style={{
                padding: 0,
                borderRadius: 0,
                color: "#85BE49",
              }}
              size={matches ? "small" : "medium"}
              onChange={() => handleCheckboxChange(false)}
            />
            <Typography
              className="StoreLabelStyles"
              fontSize={contentFontSize_16}
            >
              No
            </Typography>
          </Box>
        </Box>

        {option === false && (
          <FormControl style={{ minWidth: 200 }}>
            {/* <InputLabel id="delivery-time-label">Delivery Time</InputLabel> */}
            <Select
              labelId="delivery-time-label"
              id="delivery-time-select"
              value={deliveryTime}
              onChange={handleDropdownChange}
            >
              {Days.map((day) => (
                <MenuItem key={day.value} value={day.value}>
                  {day.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
    </Box>
  );
}
