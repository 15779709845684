import { Typography } from "../MUIComponents/index";
import {React ,useEffect}from "react";
import {
  contentFontSize_20,
  contentFontsize_40,
  contentFontSize_36,
  contentFontSize_16,
  contentFontSize_15,
  contentFontsize_14,
  contentFontsize_18_xs_11,
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_18,
  contentFontSize_22,
} from "../Typography";
import Group from "../../utils/images/Group.jpg";
import logo_new from "../../utils/images/logo_new.svg";
import vegi from "../../utils/images/vegi.svg";
import { useTheme, useMediaQuery } from "../MUIComponents/index";
import grill from "../../utils/images/Grill.jpg";
import Link1 from '../../utils/images/Link1.png'
import Link2 from '../../utils/images/Link2.png'
import Link3 from '../../utils/images/Link3.png'
import pack from '../../utils/images/pack.png'
import grocery from '../../utils/images/grocery.png'
import fish from '../../utils/images/fish.png'
import cake from '../../utils/images/cake.png'
import pastry from '../../utils/images/pastry.png'
import { useNavigate } from "react-router-dom";

function About() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const upLgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nav=useNavigate()

  const handleHomeClick =()=>{
     nav('/')
  }

  return (
    <div style={{ padding: "60px", height: "100%", width: "100%" }}>
      <div style={{ display: "flex", gap: "20px" }}>
        <Typography
          style={{ color: "#2D2A6E", fontFamily: "Nunito", fontWeight: "700", cursor:"pointer"}}
          fontSize={contentFontSize_20}
          onClick={handleHomeClick}
        >
          Home
        </Typography>
        <Typography
          style={{ color: "#69A85C", fontFamily: "Nunito", fontWeight: "700" }}
          fontSize={contentFontSize_20}
        >
          Blogs
        </Typography>
      </div>

      <div
        style={{
          marginTop: "20px",
          width: "100%",
          height: lgmatch ? "100%" : "485px",
          background: "rgba(133, 190, 73, 0.06)",
          padding: " 30px 30px 60px 30px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            height: "100%",
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "40px" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "800",
                  color: "#69A85C",
                }}
                fontSize={contentFontsize_40}
              >
                NearShopz App: Your One-Stop{" "}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "800",
                  color: "#69A85C",
                }}
                fontSize={contentFontsize_40}
              >
                Solution for Everything You Need
              </Typography>
            </div>
            <Typography
              style={{
                fontFamily: "Nunito",
                fontWeight: "400",
                color: "#676767",
              }}
              fontSize={contentFontSize_20}
            >
              Discover the convenience of NearShopz App, your ultimate
              destination
              <br /> for food, groceries, cakes, fish, meat, and even pet
              stores. Experience a <br /> seamless shopping experience like
              never before.
            </Typography>
          </div>
          <div>
            <img src={Group} alt="no image" width="100%" height="100%" />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "60px" }}>
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            gap: "170px",
          }}
        >
          <div style={{ paddingRight: "30px" }}>
            <img src={logo_new} alt="no logo" />
            <Typography
              style={{
                marginTop: "20px",
                fontFamily: "Nunito",
                color: "#2D2A6E",
                fontWeight: "700",
              }}
              fontSize={contentFontSize_36}
            >
              Introduction
            </Typography>
            <Typography
              style={{
                marginTop: "15px",
                color: "#4D5574",
                fontFamily: "Nunito",
                fontWeight: "400",
              }}
              fontSize={contentFontSize_16}
            >
              In the fast-paced world we live in, convenience is paramount.
              Enter NearShopz App, the epitome of a comprehensive solution for
              all your needs. From essential groceries to yummy food to
              specialty items like cakes, fish and meat, and even pet supplies,
              NearShopz App has revolutionized the way we shop. In this article,
              we'll delve into the myriad offerings of NearShopz App,
              highlighting its versatility and efficiency.
            </Typography>
            <Typography
              style={{
                marginTop: "70px",
                fontFamily: "Nunito",
                fontWeight: "700",
                color: "#2D2A6E",
              }}
              fontSize={contentFontSize_36}
            >
              NearShopz App: A Versatile Marketplace
            </Typography>
            <Typography
              style={{
                marginTop: "30px",
                fontFamily: "Nunito",
                fontWeight: "400",
                color: "#4D5574",
              }}
              fontSize={contentFontSize_16}
            >
              NearShopz App is not just another shopping platform; it's a
              comprehensive marketplace that caters to a wide array of needs.
              Let's explore its diverse offerings:
            </Typography>

            <div style={{ marginTop: "70px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <img src={vegi} alt="no image" />
                  <Typography
                    style={{
                      color: "#4E4E4E",
                      fontFamily: "Nunito",
                      fontWeight: "800",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    Food Galore
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <img
                    src={grocery}
                    alt="no image"
                    style={{ paddingRight: "30px" }}
                  />
                  <Typography
                    style={{
                      color: "#4E4E4E",
                      fontFamily: "Nunito",
                      fontWeight: "800",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    Grocerry Essentials
                  </Typography>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <img src={cake} alt="no image" />
                  <Typography
                    style={{
                      color: "#4E4E4E",
                      fontFamily: "Nunito",
                      fontWeight: "800",
                    }}
                    fontSize={contentFontSize_16}
                  >
                  Cakes for Every Occasion
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <img
                    src={fish}
                    alt="no image"
                    style={{ paddingRight: "30px" }}
                  />
                  <Typography
                    style={{
                      color: "#4E4E4E",
                      fontFamily: "Nunito",
                      fontWeight: "800",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    Fresh Fish and Meat
                  </Typography>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "30px" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <img src={pack} alt="no image" />
                  <Typography
                    style={{
                      color: "#4E4E4E",
                      fontFamily: "Nunito",
                      fontWeight: "800",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    Home - Based Business
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <img
                    src={pack}
                    alt="no image"
                    style={{ paddingRight: "30px" }}
                  />
                  <Typography
                    style={{
                      color: "#4E4E4E",
                      fontFamily: "Nunito",
                      fontWeight: "800",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    Pet paradise
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "60px" }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <img src={grill} />
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#96AE00",
                        cursor: "pointer",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      LIFESTYLE
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#4D5574",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      ADMIN
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#4D5574",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      SEP 15. 2022
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: "700",
                      color: "#4D5574",
                    }}
                    fontSize={contentFontSize_20}
                  >
                    Avocado Grilled Salmon, Rich In Nutrients For The Body
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: "400",
                      color: "#4D5574",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    These are the people who make your lifeeasier. Egestas is
                    tristique vestibulum...
                  </Typography>
                </div>
              </div>
              <div>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#96AE00",
                    cursor: "pointer",
                  }}
                  fontSize={contentFontsize_14}
                >
                  CONTINUE READING
                </Typography>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <img src={grill} />
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#96AE00",
                        cursor: "pointer",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      LIFESTYLE
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#4D5574",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      ADMIN
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#4D5574",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      SEP 15. 2022
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: "700",
                      color: "#4D5574",
                    }}
                    fontSize={contentFontSize_20}
                  >
                    Avocado Grilled Salmon, Rich In Nutrients For The Body
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: "400",
                      color: "#4D5574",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    These are the people who make your lifeeasier. Egestas is
                    tristique vestibulum...
                  </Typography>
                </div>
              </div>
              <div>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#96AE00",
                    cursor: "pointer",
                  }}
                  fontSize={contentFontsize_14}
                >
                  CONTINUE READING
                </Typography>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <img src={grill} />
                <div style={{ display: "flex", gap: "20px" }}>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#96AE00",
                        cursor: "pointer",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      LIFESTYLE
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#4D5574",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      ADMIN
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      style={{
                        fontFamily: "Nunito",
                        fontWeight: "500",
                        color: "#4D5574",
                      }}
                      fontSize={contentFontsize_14}
                    >
                      SEP 15. 2022
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: "700",
                      color: "#4D5574",
                    }}
                    fontSize={contentFontSize_20}
                  >
                    Avocado Grilled Salmon, Rich In Nutrients For The Body
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      fontFamily: "Nunito",
                      fontWeight: "400",
                      color: "#4D5574",
                    }}
                    fontSize={contentFontSize_16}
                  >
                    These are the people who make your lifeeasier. Egestas is
                    tristique vestibulum...
                  </Typography>
                </div>
              </div>
              <div>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#96AE00",
                    cursor: "pointer",
                  }}
                  fontSize={contentFontsize_14}
                >
                  CONTINUE READING
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style={{display:"flex",marginTop:"70px",justifyContent:"space-between"}}>
          
            <Typography style={{
                    fontFamily: "Nunito",
                    fontWeight: "700",
                    color: "#2D2A6E",
                    justifyContent:"flex-start"
                  }}
                  fontSize={contentFontSize_22}>Related Articles</Typography>
         
            <Typography style={{
                    fontFamily: "Nunito",
                    fontWeight: "700",
                    color: "#85BE49",
                    justifyContent:"flex-end",
                    cursor:"pointer"
                  }}
                  fontSize={contentFontSize_20}>View all most recent posts</Typography>
          
        </div>
        <div style={{ display: "flex", gap:"50px", marginTop:"30px"}}>
          {" "}
          <div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
            <img src={Link1} alt="no image" style={{borderRadius:"10px"}}/>
            <div style={{ display: "flex", gap: "20px" }}>
              <div >
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#96AE00",
                    cursor: "pointer",
                  }}
                  fontSize={contentFontsize_14}
                >
                  LIFESTYLE
                </Typography>
              </div>
              <div >
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontsize_14}
                >
                  ADMIN
                </Typography>
              </div>
              <div>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontsize_14}
                >
                  SEP 15. 2022
                </Typography>
              </div>
            </div>
            <div style={{display:"flex",flexDirection:"column"}}>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "700",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontSize_20}
                >
                  Avocado Grilled Salmon, Rich In Nutrients For The Body
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontSize_16}
                >
                  These are the people who make your lifeeasier. Egestas is
                  tristique vestibulum...
                </Typography>
              </div>
            <div>
              <Typography
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "500",
                  color: "#96AE00",
                  cursor: "pointer",
                }}
                fontSize={contentFontsize_14}
              >
                CONTINUE READING
              </Typography>
            </div>
          </div>
          <div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
            <img src={Link2} alt="no image" style={{borderRadius:"10px"}}/>
            <div style={{ display: "flex", gap: "20px" }}>
              <div >
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#96AE00",
                    cursor: "pointer",
                  }}
                  fontSize={contentFontsize_14}
                >
                  LIFESTYLE
                </Typography>
              </div>
              <div >
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontsize_14}
                >
                  ADMIN
                </Typography>
              </div>
              <div>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontsize_14}
                >
                  SEP 15. 2022
                </Typography>
              </div>
            </div>
            <div style={{display:"flex",flexDirection:"column"}}>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "700",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontSize_20}
                >
                  Avocado Grilled Salmon, Rich In Nutrients For The Body
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontSize_16}
                >
                  These are the people who make your lifeeasier. Egestas is
                  tristique vestibulum...
                </Typography>
              </div>
            <div>
              <Typography
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "500",
                  color: "#96AE00",
                  cursor: "pointer",
                }}
                fontSize={contentFontsize_14}
              >
                CONTINUE READING
              </Typography>
            </div>
          </div>
          <div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
            <img src={Link3} alt="no image" style={{borderRadius:"10px"}}/>
            <div style={{ display: "flex", gap: "20px" }}>
              <div >
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#96AE00",
                    cursor: "pointer",
                  }}
                  fontSize={contentFontsize_14}
                >
                  LIFESTYLE
                </Typography>
              </div>
              <div >
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontsize_14}
                >
                  ADMIN
                </Typography>
              </div>
              <div>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "500",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontsize_14}
                >
                  SEP 15. 2022
                </Typography>
              </div>
            </div>
            <div style={{display:"flex",flexDirection:"column"}}>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "700",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontSize_20}
                >
                  Avocado Grilled Salmon, Rich In Nutrients For The Body
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "400",
                    color: "#4D5574",
                  }}
                  fontSize={contentFontSize_16}
                >
                  These are the people who make your lifeeasier. Egestas is
                  tristique vestibulum...
                </Typography>
              </div>
            <div>
              <Typography
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "500",
                  color: "#96AE00",
                  cursor: "pointer",
                }}
                fontSize={contentFontsize_14}
              >
                CONTINUE READING
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
