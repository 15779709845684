import { React, useState } from "react";
import { TextField, Typography, InputAdornment } from "../MUIComponents/index";
import "./LandingFooter.css";
import indiaImage from "../../utils/images/indiaImage.svg";
import CustomButton from "../Commoncomponents/button";
import { HeaderNavigationFontSize_12, HeaderNavigationFontSize_20, contentFontSize_15, contentFontSize_16, contentFontSize_18, contentFontsize_14, contentFontsize_14_xs_10, contentFontsize_18_xs_11 } from "../Typography";
import Facebook from "../../utils/Icon/facebook.svg";
import Instagram from "../../utils/Icon/Instagram.svg";
import Youtube from "../../utils/Icon/Youtube.svg";
import { useNavigate } from "react-router-dom";

const LandingFooter = () => {
  const nav = useNavigate();
  const handleAboutClick = () => {
    const secondPage = document.getElementById("aboutus");
    if (secondPage) {
      secondPage.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [buttonColor, setButtonColor] = useState("green");
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleMobileChange = (e) => setPhoneNumber(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const values = {
      email,
      phoneNumber,
      firstName,
      lastName,
      message,
      subject,
    };

    try {
      const response = await fetch(
        "https://testserver.nearshopz.com/api/contactus",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      } else {
        setEmail("");
        setPhoneNumber("");
        setButtonColor("grey");
        setTimeout(() => {
          setButtonColor("green");
        }, 1000);
        // window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  };

  const handleFbClick = () => {
    window.location.href = "https://www.facebook.com/NearShopz";
  };
  const handleYoutbClick = () => {
    window.location.href =
      " https://www.youtube.com/@nearshopz1078?app=desktop";
  };
  const handleInstaClick = () => {
    window.location.href = "https://www.instagram.com/nearshopz_india/";
  };

  const handleBlogClick = () => {
    nav("/Blogs");
  };
  const handleTermsClick = () => {
    nav("/Terms&Conditions");
  };
  const handleContactClick = () => {
    nav("/Contact");
  };
  const handlePrivacyClick = () => {
    nav("/Privacy&Policy");
  };
  return (
    <div className="footer-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "94%",
        }}
      >
        <div className="input-section">
          <div className="input-fields">
            <TextField
              size={"small"}
              variant="outlined"
              placeholder="Enter your Email Address"
              InputProps={{
                className: "custom-placeholder",
                style: { backgroundColor: "white" },
              }}
              fullWidth
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              size={"small"}
              height="20px"
              variant="outlined"
              value={phoneNumber}
              onChange={handleMobileChange}
              placeholder="Enter your mobile number"
              InputProps={{
                className: "custom-placeholder",
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={indiaImage}
                      alt="India flag"
                      style={{ marginRight: "8px" }}
                    />
                    <div
                      style={{
                        color: "black",
                        fontSize: "14px",
                        marginTop: "3px",
                      }}
                    >
                      +91 |
                    </div>
                  </InputAdornment>
                ),
                style: { backgroundColor: "white" },
              }}
              fullWidth
            />
          </div>
          <div style={{ justifyContent: "flex-start", paddingTop: "40px" }}>
            <CustomButton
              borderColor={"white"}
              color={"white"}
              background={buttonColor}
              borderRadius={"10px"}
              width={"30%"}
              height={"40px"}
              onClick={handleSubmit}
            >
              Connect
            </CustomButton>
          </div>
          <div style={{ justifyContent: "flex-start", paddingTop: "40px" }}>
            <Typography fontSize={contentFontSize_15} className="content-font">
              This site is intended for our valued users. By signing up, you
              acknowledge and agree that your data will be collected and managed<br/>
              in accordance with our Privacy Policy and Terms of Use.
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              gap: "60px",
              paddingTop: "80px",
              paddingLeft: "40px",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "30px" }}
            >
              <div>
                <Typography className="Menu" style={{ fontWeight: "700" }} fontSize={contentFontSize_16}>
                  MENU
                </Typography>
              </div>
              <div>
                <Typography
                  className="Menu"
                  style={{ cursor: "pointer" }}
                  onClick={handleAboutClick}
                >
                  About
                </Typography>
              </div>
              <div>
                <Typography
                  className="Menu"
                  onClick={handleTermsClick}
                  style={{ cursor: "pointer" }}
                >
                  Terms & Conditions
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Typography
                  className="Menu"
                  onClick={handleBlogClick}
                  style={{ cursor: "pointer" }}
                >
                  Blog
                </Typography>
              </div>
              <div>
                <Typography
                  className="Menu"
                  style={{ cursor: "pointer" }}
                  onClick={handlePrivacyClick}
                >
                  Privacy & policy
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                justifyContent: "space-around",
              }}
            >
              <div>
                <Typography
                  className="Menu"
                  style={{ cursor: "pointer" }}
                  onClick={handleContactClick}
                >
                  Contact
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                gap: "10px",
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "40px",
              }}
            >
              <div>
                <img src={Facebook} onClick={handleFbClick} />
              </div>
              <div>
                <img src={Instagram} onClick={handleInstaClick} />
              </div>
              <div>
                <img src={Youtube} onClick={handleYoutbClick} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-line"></div>
      <div className="footer-copyright">
        <Typography fontSize={contentFontsize_14_xs_10}>
          © Copyright 2024 NearShopz - All Rights Reserved.
        </Typography>
      </div>
    </div>
  );
};

export default LandingFooter;
