export const LoginReducer = (state = { 
										isLoggedIn: false ,
										
										isPasswordset: false,
										isPasswordsetFailed: false,
										isError:false,
										isNetError:false,
										isPasswordsetnetwork: false,
										loginformNumber:null,
										ShopAssistantisLoggedin:false,
										ShopAssistantisError:false,
										ShopAssistantisNetError:false,
										shopAssistantId:null,
									}, action) => {
										
	
	switch (action.type) {
		case "LOGIN_ACCESS_SUCCESS":
			return {
				...state,
				isLoggedIn: localStorage.getItem('isLoggedin'),
				role:localStorage.getItem('role'),
				shopAssistantId:localStorage.setItem('shopAssistantId', "undefined"),
				loginformNumber:action.payload.sellerlogin.formNumber,
				storestatus:action.payload.sellerlogin.storeStatus,
				successData: action.payload,
			};

		case "LOGIN_ACCESS_FAILED":
			return {
				...state,
				errorMessage: action.payload,
				loginformNumber:null,
				isLoggedIn: false,
				isError:true,
			};
		case "LOGIN_ACCESS_ERROR":
			return {
				...state,
				isLoggedIn: false,
				loginformNumber:null,
				isNetError:true,
				incorrectMessage: action.payload,
			};
			case "RESET_SUCCESS":
				return {
					...state,
					isPasswordset: true,
					passwordData: action.payload,
				};
				case "RESET_FAILED":
					return {
						...state,
						isPasswordsetFailed: true,
						incorrectPassMessage: action.payload,
					};
					case "RESET_NETWORK_FAILED":
					return {
						...state,
						isPasswordsetnetwork: true,
						incorrectPassMessage: action.payload,
					};
					case "CLOSE_SNACKBARS": {
						return {
							...state,
							isLoggedIn: false ,
							isPasswordset: false,
							isError:false,
							isNetError:false,
							isPasswordsetFailed: false,
							isPasswordsetnetwork: false
						};
					}
					case "SHOPASSISTANT_LOGIN_ACCESS_SUCCESS":
			return {
				...state,
				ShopAssistantisLoggedin: localStorage.getItem('ShopAssistantisLoggedin'),
				role:localStorage.getItem('role'),
				ShopAssistantLoginSuccess:true,
				shopAssistantId:action.payload.loginShopAssistant.shopAssistantId,
				storeid:action.payload.loginShopAssistant.storeid,
				ShopAssistantsuccessData: action.payload,
			}; 

		case "SHOPASSISTANT_LOGIN_ACCESS_ERROR":
			return {
				...state,
				ShopAssistanterrorMessage: action.payload,
				shopAssistantId:null,
				ShopAssistantLoginSuccess:false,
				ShopAssistantisLoggedin: false,
				ShopAssistantisError:true,
			};
		case "SHOPASSISTANT_LOGIN_ACCESS_FAILED":
			return {
				...state,
				ShopAssistantisLoggedin: false,
				ShopAssistantLoginSuccess:false,
				shopAssistantId:null,
				ShopAssistantisNetError:true,
				ShopAssistantincorrectMessage: action.payload,
			};
			case "SHOPASSISTANT_CLEAR":
				return{
					...state,
					ShopAssistantisError:false,
					ShopAssistantLoginSuccess:false,
				}

		default:
			return state;
	}
};
// export const ShopAssistantLoginReducer = (state = { 
// 										ShopAssistantisLoggedin:false,
// 										ShopAssistantisError:false,
// 										ShopAssistantisNetError:false,
// 										shopAssistantId:null,
// 									}, action) => {
										
	
// 	switch (action.type) {
// 		case "SHOPASSISTANT_LOGIN_ACCESS_SUCCESS":
// 			console.log("this is action",action.payload)
// 			return {
// 				...state,
// 				ShopAssistantisLoggedin: localStorage.getItem('ShopAssistantisLoggedin'),
// 				shopAssistantId:action.payload.loginShopAssistant.shopAssistantId,
// 				storeid:action.payload.loginShopAssistant.storeid,
// 				ShopAssistantsuccessData: action.payload,
// 			}; 

// 		case "SHOPASSISTANT_LOGIN_FAILED":
// 			return {
// 				...state,
// 				ShopAssistanterrorMessage: action.payload,
// 				shopAssistantId:null,
// 				ShopAssistantisLoggedin: false,
// 				ShopAssistantisError:true,
// 			};
// 		case "SHOPASSISTANT_LOGIN_ERROR":
// 			return {
// 				...state,
// 				ShopAssistantisLoggedin: false,
// 				shopAssistantId:null,
// 				ShopAssistantisNetError:true,
// 				ShopAssistantincorrectMessage: action.payload,
// 			};
			
// 		default:
// 			return state;
// 	}
// };
