import { gql } from '@apollo/client';

export const GET_PRODUCT_SEARCH = gql`
query(
        $searchKey:String
        $storeid:ID!
        $departmentid:ID
        $categoryid:ID
        $categoryid1:ID
        $categoryid2:ID
        $status:String
        $from:Int
        $size:Int)
        {
            searchproducts(
                searchKey:$searchKey
                storeid:$storeid
                departmentid:$departmentid
                categoryid:$categoryid
                categoryid1:$categoryid1
                categoryid2:$categoryid2
                status:$status
                from:$from
                size:$size)
                {

                    _id
                    _source{
                        storeid
                        productname
                        barcode
                        id
                        desc
                        mfgname
                        brand
                        price
                        promoprice
                        quantity
                        uom
                        images
                        image{
                          primary
                        }
                        status
                        displayrank
                        status
                           salesdepartment{
                             id
                             name
                            }
                           salescategory{
                             id
                             name
                            }
                            salescategory1{
                                id
                                name
                            }
                            salescategory2{
                                id
                                name
                            }
                }
        }
    }

`

export const GET_PRODUCT_DETAILS = gql`
query($id:ID!)
{
  salesproducts(id:$id){
    id
    productname
    desc
    mfgname
    price
    promoprice
    quantity
    uom
    brand
    images
    image{
      primary
    }
    status
    barcode
    displayrank
    storeid{
      id
    }
    salesdepartment{
      id
      name
      childCategoryPresent
    }
    salescategory
    {
      id
      name
    }
    salescategory1{
      id
      name
    }
    salescategory2{
      id
      name
    }
  }
}

`;


export const deplist = gql`
query{
  salesdepartments(storeid:"5f634a0f2d482b05dc1c61dd"){
    id
    name
    childCategoryPresent
    childCategories{
     id
     name
    }
    Products{
      items{
        id
        productname
        desc
        mfgname
        brand
        price
        promoprice
        quantity
        uom
        images
        displayrank
        
      }
    }
  }
}
`

export const CATEGORY_LIST = gql`
query($storeid:ID!,$id:ID!){
  salescategory(storeid:$storeid,id:$id){
  id
   name
   childCategories{
     name
     childCategories{
       name
     }
     
 }
  
 }
 }

`

export const IMAGE_UPLOAD =  gql`
mutation($file:Upload,$size:Int,$filename:String,$type:Int,$storeid:String){
  imageEditAndUpload(file:$file,size:$size,filename:$filename,type:$type,storeid:$storeid)
 {
   ETag
   Location
}
}
`