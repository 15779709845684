import React, { Component } from "react";
import { Row, Col } from'react-bootstrap';
import { Container } from "@mui/material";

export default class index extends Component {
 

updateIsMobile=()=> {
    this.setState({
        isMobile: window.innerWidth < 768
    });
}

  componentDidMount=()=>{
    window.addEventListener('resize', this.updateIsMobile);
    this.props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
}
componentWillUnmount() {
  window.removeEventListener('resize', this.updateIsMobile);
}
   
  constructor(props) {
    super(props)

    this.state = {
      isMobile: window.innerWidth < 768,
        url:`https://nearshopz.com/stores/${this.props.retailerDetails && this.props.retailerDetails.data&&
        this.props.retailerDetails.data.retailerdetails&&
        this.props.retailerDetails.data.retailerdetails.stores&&
        this.props.retailerDetails.data.retailerdetails.stores[0].storeLocation.toLowerCase().split(",")[0].replace(/ /g,"-")}/${this.props.retailerDetails && this.props.retailerDetails.data&&
          this.props.retailerDetails.data.retailerdetails&&
          this.props.retailerDetails.data.retailerdetails.stores&&
          this.props.retailerDetails.data.retailerdetails.stores[0].businessType.toLowerCase().replace(/ /g,"-")}/${this.props.retailerDetails &&
            this.props.retailerDetails.data&&this.props.retailerDetails.data.retailerdetails&&
            this.props.retailerDetails.data.retailerdetails.stores&&
            this.props.retailerDetails.data.retailerdetails.stores[0].storeName.toLowerCase().replace(/ /g,"-")}`,
      copySuccess: false
    }
  }
 
  copyCodeToClipboard = () => {
    const el = this.input
    el.select()
    document.execCommand("copy")
    this.setState({copySuccess: true})
  }

  render() {
    document.title ="Seller Portal | Share my link"; 
    return (
      this.state.isMobile ? 
        <div
        style={{  
            backgroundColor:'white',
            boxShadow:'0 4px 8px 0 rgba(53, 105, 128, 0.3),0 6px 20px 0 rgba(165, 200, 213, 0.41)',
            borderRadius:'18px',
            width:'100%',
            Top: "40px",
            }}>
            <Row
            style={{ 
                padding: 20,
               
            }}
            >
           <Col sm={8} xs={8}>    
        <input
        style={{width:'100%'}}
        ref={(input) => this.input = input}
        type="text"
        value={this.state.url}
        readOnly
      />
      </Col> 
      </Row>
      <Row
            style={{ 
                padding: 20,
               
            }}
            >
      <Col md={4} sm={4} xs={4}>
      <div>
          <button 
          style={{
            justifyContent:' center',
            color: 'rgb(255, 255, 255)',
            backgroundImage: '-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
            boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
            width:'150px',
            height: '37px',
            fontSize: '16px',
            fontWeight: '600',
            borderRadius: '5px',
            borderWidth: '1px',
            borderStyle:' solid',
            borderColor: 'transparent',
            borderImage: 'initial',
            overflow: 'hidden',
            outline: 'none',
            minHeight:' 14px',
            fontFamily: 'Varela Round, sans-serif'
            }}
            onClick={() => this.copyCodeToClipboard()}>
           Get my Url
          </button>
          {
            this.state.copySuccess ?
            <div style={{"color": "rgb(83,170,73)"}}>
              Copied to Clipboard
            </div> : null
          }
        </div>
      </Col>
      </Row>
      
       
      </div>
      :
      <Container maxWidth='sm'
      style={{  
          backgroundColor:'white',
          boxShadow:'0 4px 8px 0 rgba(53, 105, 128, 0.3),0 6px 20px 0 rgba(165, 200, 213, 0.41)',
          borderRadius:'18px',
          Top: "40px",
          }}>
          <Row
          style={{ 
              padding: 20,
             
          }}
          >
         <Col md={8} sm={8} xs={8}>    
      <input
      style={{width:'100%'}}
      ref={(input) => this.input = input}
      type="text"
      value={this.state.url}
      readOnly
    />
    </Col> 
    <Col md={4} sm={4} xs={4}>
    <div>
        <button 
        style={{
          justifyContent:' center',
          color: 'rgb(255, 255, 255)',
          backgroundImage: '-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)',
          boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
          width:'150px',
          height: '37px',
          fontSize: '16px',
          fontWeight: '600',
          borderRadius: '5px',
          borderWidth: '1px',
          borderStyle:' solid',
          borderColor: 'transparent',
          borderImage: 'initial',
          overflow: 'hidden',
          outline: 'none',
          minHeight:' 14px',
          fontFamily: 'Varela Round, sans-serif'
          }}
          onClick={() => this.copyCodeToClipboard()}>
         Get my Url
        </button>
        {
          this.state.copySuccess ?
          <div style={{"color": "rgb(83,170,73)"}}>
            Copied to Clipboard
          </div> : null
        }
      </div>
    </Col>
      </Row>
     
    </Container>
    )
  }
}