// import React from "react";
// import { Container } from "@mui/material";

// export default function Catalog(props) {
//   const isMobileScreen = window.innerWidth < 768;

//   React.useEffect(
//     () => {
//       // props.getBusinessType();
//       props.getMasterDepartment(localStorage.getItem("CatalogBusinesId"));
//     }, // eslint-disable-next-line react-hooks/exhaustive-deps
//     []
//   );

//   const CatalogDepartmentClick = (name, id) => {
//     localStorage.setItem("CatalogDepartment", name);
//     localStorage.setItem("CatalogId", id);
//     props.history.push("/home/CategoryBuilder/Products");
//   };

//   return (
//     <>
//       {isMobileScreen ? (
//         <Container
//           maxWidth="md"
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             Top: "40px",
//             padding: "20px",
//             display: "flex",
//             flexDirection: "column",
//             flex: " 1 1 0",
//             width: "300px",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//         >
//           {props.MasterDepartments &&
//           props.MasterDepartments.data &&
//           props.MasterDepartments.data.departmentWiseListing &&
//           props.MasterDepartments.data.departmentWiseListing.length === 0 ? (
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <p>{"No Departments to show"}</p>
//             </div>
//           ) : (
//             <div
//               style={{
//                 display: "grid",
//                 gridGap: "24px",
//                 gap: "24px",
//                 gridTemplateColumns: "repeat(auto-fill,minmax(368px,1fr))",
//               }}
//             >
//               {props.MasterDepartments &&
//                 props.MasterDepartments.data &&
//                 props.MasterDepartments.data.departmentWiseListing &&
//                 props.MasterDepartments.data.departmentWiseListing.map((m) => (
//                   <>
//                     <div
//                       type="button"
//                       onClick={() => {
//                         CatalogDepartmentClick(m.name, m.id);
//                       }}
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                         padding: "16px 0 16px 20px",
//                         backgroundColor: "#fafafa",
//                         border: "solid 1px #d9d9d9",
//                         borderRadius: "8px",
//                         cursor: "pointer",
//                         width: "70%",
//                       }}
//                     >
//                       <div
//                         style={{
//                           display: "flex",
//                           // alignItems: 'center',
//                           justifyContent: "space-between",

//                           flexDirection: "column",
//                         }}
//                       >
//                         <span
//                           style={{
//                             color: "#061303",
//                             fontWeight: 600,
//                             fontSize: "16px",
//                           }}
//                         >
//                           {`${m.name}`}
//                         </span>
//                         <span
//                           style={{
//                             color: "rgb(153, 153, 153)",
//                             fontSize: "12px",
//                           }}
//                         >{`choose from ${m.departmentProductsCount} products`}</span>
//                       </div>
//                       <div style={{ marginLeft: "auto", marginRight: 14 }}>
//                         <span
//                           style={{
//                             color: "white",
//                             fontSize: "14px",
//                             borderRadius: "100%",
//                             backgroundColor: "rgb(137, 199, 74, 1)",
//                             width: "35px",
//                             height: "30px",
//                             alignItems: "center",
//                             textAlign: "center",
//                             justifyContent: "center",
//                             fontWeight: 550,
//                             display: "flex",
//                           }}
//                         >
//                           {" "}
//                           {`${m.departmentProductsCount}`}
//                         </span>
//                       </div>
//                     </div>
//                   </>
//                 ))}
//             </div>
//           )}
//         </Container>
//       ) : (
//         <Container
//           maxWidth="md"
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             Top: "40px",
//             padding: "30px",
//             display: "flex",
//             flexDirection: "column",
//             flex: " 1 1 0",
//             width: "100%",
//             fontFamily: "Varela Round, sans-serif",
//           }}
//         >
//           {props.MasterDepartments &&
//           props.MasterDepartments.data &&
//           props.MasterDepartments.data.departmentWiseListing &&
//           props.MasterDepartments.data.departmentWiseListing.length === 0 ? (
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <p>{"No Departments to show"}</p>
//             </div>
//           ) : (
//             <div
//               style={{
//                 display: "grid",
//                 gridGap: "24px",
//                 gap: "24px",
//                 gridTemplateColumns: "repeat(auto-fill,minmax(368px,1fr))",
//               }}
//             >
//               {props.MasterDepartments &&
//                 props.MasterDepartments.data &&
//                 props.MasterDepartments.data.departmentWiseListing &&
//                 props.MasterDepartments.data.departmentWiseListing.map((m) => (
//                   <>
//                     <div
//                       type="button"
//                       onClick={() => {
//                         CatalogDepartmentClick(m.name, m.id);
//                       }}
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                         padding: "16px 0 16px 20px",
//                         backgroundColor: "#fafafa",
//                         border: "solid 1px #d9d9d9",
//                         borderRadius: "8px",
//                         cursor: "pointer",
//                         width: "80%",
//                       }}
//                     >
//                       <div
//                         style={{
//                           display: "flex",
//                           // alignItems: 'center',
//                           justifyContent: "space-between",

//                           flexDirection: "column",
//                         }}
//                       >
//                         <span
//                           style={{
//                             color: "#061303",
//                             fontWeight: 600,
//                             fontSize: "16px",
//                           }}
//                         >
//                           {`${m.name}`}
//                         </span>
//                         <span
//                           style={{
//                             color: "rgb(153, 153, 153)",
//                             fontSize: "12px",
//                           }}
//                         >{`choose from ${m.departmentProductsCount} products`}</span>
//                       </div>
//                       <div style={{ marginLeft: "auto", marginRight: 14 }}>
//                         <span
//                           style={{
//                             color: "white",
//                             fontSize: "14px",
//                             borderRadius: "100%",
//                             backgroundColor: "rgb(137, 199, 74, 1)",
//                             width: "35px",
//                             height: "30px",
//                             alignItems: "center",
//                             textAlign: "center",
//                             justifyContent: "center",
//                             fontWeight: 550,
//                             display: "flex",
//                           }}
//                         >
//                           {" "}
//                           {`${m.departmentProductsCount}`}
//                         </span>
//                       </div>
//                     </div>
//                   </>
//                 ))}
//             </div>
//           )}
//         </Container>
//       )}
//     </>
//   );
// }
import React, { useState, useEffect } from "react";
import "./Business.css";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card";
import Arrow from "../../utils/Icon/Arrow.svg";
import Bevarages from "../../utils/Icon/Bevarages.svg";
import Nonveg from "../../utils/Icon/nonveg.svg";
import Veg from "../../utils/Icon/veg.svg";
import Subcategory from "./Subcategory";
import {
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  contentFontSize_18,
} from "../Typography";
import { useNavigate } from "react-router-dom";
import { dummyData } from "../Data/Dummydata";

function Business(props) {
  const [subCategory, setSubCategory] = useState(false);
  const nav = useNavigate();

  useEffect(
    () => {
      props.getMasterDepartment(localStorage.getItem("CatalogBusinesId"));
    }, 
    []
  );

  const CatalogDepartmentClick = (name, id) => {
    localStorage.setItem("CatalogDepartment", name);
    localStorage.setItem("CatalogId", id);
    nav("/home/CategoryBuilder/Products");
  };

  function handleBack() {
    nav("/home/CatalogBuilder");
  }
  function handleClickSub() {
    setSubCategory(true);
  }
 

 


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {subCategory ? (
        <Subcategory />
      ) : (
        <div>
          <div className="Business-sub">
            <div style={{ cursor: "pointer" }} onClick={handleBack}>
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                Business Type
              </Typography>
            </div>
            <div>
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
            </div>
            <div>
              <Typography
                className="main"
                style={{ color: "#85BE49" }}
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                Category
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              className="Businesstype"
              fontSize={HeaderNavigationFontSize_20}
            >
              Choose your Category
            </Typography>
          </div>

          <>
            <div>
              {props.MasterDepartments &&
              props.MasterDepartments.data &&
              props.MasterDepartments.data.departmentWiseListing &&
              props.MasterDepartments.data.departmentWiseListing.length ===
                0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
              className="Businesstype"
              fontSize={HeaderNavigationFontSize_20}
            >
              No Department To Display
            </Typography>
                </div>
              ) : (
                <>
                  {props.MasterDepartments &&
                    props.MasterDepartments.data &&
                    props.MasterDepartments.data.departmentWiseListing &&
                    props.MasterDepartments.data.departmentWiseListing.map(
                      (m) => (
                        <>
                          <div style={{ paddingTop: "29px" }}>
                            <CustomCard
                              padding={"20px"}
                              onClick={() => {
                                CatalogDepartmentClick(m.name, m.id);
                              }}
                              width={"100%"}
                            >
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}  sx={{paddingLeft:"0px",paddingTop:"0px"}}>
                                  <Grid item xs={3} sm={2} md={2} lg={1} xl={1} sx={{paddingLeft:"0px",paddingTop:"0px"}}>
                                    <CustomCard
                                      padding="2px"
                                      height={"70px"}
                                      background="#F2F2F2"
                                      boxShadow="none"
                                      borderRadius="10px"
                                      width={matches ? "60px" : "70px"}
                                      sx={{ alignItems: "center" }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          //   padding:"6px"
                                        }}
                                      >
                                        <img
                                          src={Bevarages}
                                          alt="Bevarages"
                                          style={{
                                            width: matches ? "50px" : "80%",
                                          }}
                                        />
                                      </div>
                                    </CustomCard>
                                  </Grid>
                                  <Grid item xs={8} sm={8} md={8} lg={9} xl={9} sx={{paddingLeft:"0px",paddingTop:"0px"}}>
                                    <div
                                      style={{
                                        display: "flex",
                                        // justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        paddingLeft: "20px",
                                        boxSizing: "border-box",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "8px",
                                        }}
                                      >
                                        <Typography
                                          className="Bevarages"
                                          fontSize={contentFontSize_18}
                                        >
                                          {`${m.name}`}
                                        </Typography>
                                        <Typography
                                          className="product"
                                          fontSize={contentFontSize_16}
                                        >
                                          {`choose from ${m.departmentProductsCount} products`}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                        height: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "15px",
                                          }}
                                        >
                                          <div onClick={handleClickSub}>
                                            <img src={Arrow} alt="arrowicon" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Box>
                            </CustomCard>
                          </div>
                        </>
                      )
                    )}
                </>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default Business;
