import React, { useEffect, useState } from "react";
import CouponCard from "./CouponCard";
import CustomButton from "../Commoncomponents/button/index";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "../MUIComponents/index";
import { ButtonFontSize_18_xs_10 } from "../Typography/index";
import Coupontype from "./Coupontype";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {CircularProgress} from '../MUIComponents/index';

function UpcomingCoupons(props) {

  const [upcomingCoupons, setUpcomingCoupons] = useState([]);




  const nav = useNavigate();
  const { AllUpcomingCouponsLoading } = useSelector((state) => state.SignInReducer);

  useEffect(() => {
    // props.getAllCoupons();
    props.getAllUpComingCoupons();

  }, []);





  function createCoupounPage() {
    
    nav("/home/Coupontype");
  }


  function handleSlotsColor() {

    nav('/home/Coupons')
  }

  function handleSettingsColor() {

    nav('/home/UpcomingCoupons')
  }

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  function calculateRemainingDays(endDate) {
    const currentDate = new Date().getTime();
    const diffTime = endDate - currentDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div>
              <CustomButton
                width="100%"
                height="100%"
                borderRadius="15px"
                onClick={handleSlotsColor}

                borderColor="#659B1B"

                variant="outlined"
                background="white"
                color="#646464"
                size="large"
                matchPadding={true}
                padding="10px"
              >
                <Typography
                  fontSize={ButtonFontSize_18_xs_10}
                  className="ManageInitialBtnStyle"
                >
                  Ongoing
                </Typography>
              </CustomButton>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div>
              <CustomButton
                width="100%"
                height="100%"
                borderRadius="15px"
                onClick={handleSettingsColor}
                borderColor="#659B1B"
                variant="contained"
                matchPadding={true}
                padding="10px"
                background="#659B1B"
                color="white"
              >
                <Typography
                  fontSize={ButtonFontSize_18_xs_10}
                  className="ManageInitialBtnStyle"
                >
                  Upcoming
                </Typography>
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>

      <div style={{ marginTop: "30px" }}>

        {props &&
            props?.AllUpcomingCoupons &&
            props?.AllUpcomingCoupons?.data &&
            props?.AllUpcomingCoupons?.data?.listAllUpcomingCoupons.length!=0?
          <Grid container spacing={5}>
          {props &&
            props?.AllUpcomingCoupons &&
            props?.AllUpcomingCoupons?.data &&
            props?.AllUpcomingCoupons?.data?.listAllUpcomingCoupons.map((C) => (
              <Grid item key={C.id} xs={12} sm={6} md={6} lg={6} xl={6}>
                <CouponCard
                  couponCode={C.couponCode}
                  totalCustUseCount={C.totalCustUseCount}
                  custUseCount={C.custUseCount}
                  type={C.type}
                  disAmt={C.disAmt}
                  discountPercent={C.discountPercent}
                  minPurAmt={C.minPurAmt}
                  status={C.status}
                  id={C.id}
                  appliedCount={C.appliedCount}
                  buyItemNo={C.buyItemNo}
                  getItemNo={C.getItemNo}
                  endDate={C.endDate}
                  startDate={C.startDate}
                  remainingDays={calculateRemainingDays(C.endDate)}
                  {...props}
                />
              </Grid>))}
        </Grid>:( AllUpcomingCouponsLoading? (<div style={{height:"100%",width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}> <CircularProgress color="success" /></div>)
       :
        (<>No upcoming coupons</>))}
      </div>
      <div style={{ marginTop: "140px", textAlign: "center" }}>
        <CustomButton
          width={
            upXlmatch
              ? "358px"
              : xlmatch
                ? lgmatch
                  ? mdmatch
                    ? smmatch
                      ? xsmatch
                        ? "250px"
                        : "250px"
                      : "310px"
                    : "330px"
                  : "340px"
                : "358px"
          }
          borderRadius="20px"
          onClick={createCoupounPage}
          matchPadding={true}
          padding="10px"
        >
          <Typography
            fontSize={ButtonFontSize_18_xs_10}
            className="create-coupon"
          >
            Create Coupon
          </Typography>
        </CustomButton>
      </div>
    </>
  )
}
export default UpcomingCoupons;