import React, { useState, useReducer, useEffect } from "react";
import CustomButton from "../Commoncomponents/button/index";
import "./ManageSlots.css";
import CustomCard from "../Commoncomponents/card/index";
import CustomTextField from "../Commoncomponents/input/index";
import CustomSelect from "../Commoncomponents/selectButton/index";
import { Checkbox } from "../MUIComponents/index";
import ClockIcon from "../../utils/Icon/ClockIcon.svg";
import { InputAdornment } from "../MUIComponents/index";
import { AdapterDateFns } from "../MUIComponents/index";

import {
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { useMediaQuery } from "../MUIComponents/index";
import {
  Grid,
  TimePicker,
  LocalizationProvider,
  DemoContainer,
  AdapterDayjs,
  TextField,
  Alert,
  Stack,
} from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import { ButtonFontSize_18_xs_10 } from "../Typography/index";
import { HeaderNavigationFontSize_20, contentFontSize_18 } from "../Typography/index";
import { ButtonFontSize_17 } from "../Typography/index";
import { HeaderFontSize_25 } from "../Typography/index";
import { contentFontSize_16 } from "../Typography/index";
import { ButtonFontSize_18 } from "../Typography/index";
import EditDeliveryCharge from "../EditDeliveryCharge";
import SameDayDelivery from "../AdditionalDetailsEdit/SameDayDelivery";
import DeliveryDayAfter from "../AdditionalDetailsEdit/DeliveryDayAfter";
import { useFormik } from "formik";
import { ManageSettingSchema } from './ManageValidation';
import { ManageValidationSchema } from './ManageValidation';
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { wrapHook } from "@apollo/client/react/hooks/internal/wrapHook";
const ActionTypes = {
  TOGGLE_BUTTON: "TOGGLE_BUTTON",
  TOGGLE_SLIDER_BUTTON: "TOGGLE_SLIDER_BUTTON",
};

// function reducer(state, action) {
//   switch (action.type) {
//     case ActionTypes.TOGGLE_BUTTON:
//       return {
//         ...state,
//         [action.payload.day]: !state[action.payload.day],
//       };
//     default:
//       return state;
//   }
// }

// function sliderReducer(state, action) {
//   switch (action.type) {
//     case ActionTypes.TOGGLE_SLIDER_BUTTON:
//       return {
//         ...state,
//         [action.payload.day]: {
//           ...state[action.payload.day],
//           [action.payload.timeSlot]:
//             !state[action.payload.day][action.payload.timeSlot],
//         },
//       };
//     default:
//       return state;
//   }
// }

export default function ManageSlots(props) {

  const [manageData, setManageData] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitted1, setFormSubmitted1] = useState(false);

  const nav = useNavigate();
  const dispatch = useDispatch();
  useEffect(
    () => {
      props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
      props.getSameDayDelivery(
        props?.retailerDetails &&
        props?.retailerDetails?.data &&
        props?.retailerDetails?.data?.retailerdetails?.stores[0] &&
        props?.retailerDetails?.data?.retailerdetails?.stores[0]
          .sameDayDelivery
      );
      props.setDeliveryDays(
        props?.retailerDetails &&
        props?.retailerDetails?.data &&
        props?.retailerDetails?.data?.retailerdetails?.stores[0] &&
        props?.retailerDetails?.data?.retailerdetails?.stores[0].deliveryAfter
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (
      props?.retailerDetails?.data?.retailerdetails?.stores[0]
        .sameDayDelivery === true
    )
      props.setDeliveryDays(0);
  }, []);

  useEffect(() => {
    // if (props.editStoreSuccess === true) {
    //   setEdit(!edit);
    //   props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
    // }
    props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
  }, [props.editStoreSuccess]);

  // let { stores } =
  //   props?.retailerDetails &&
  //   props?.retailerDetails?.data &&
  //   props?.retailerDetails?.data?.retailerdetails;
  let { stores } = props?.retailerDetails?.data?.retailerdetails || { stores: [] };


  const [minAmt, setMinAmt] = useState(
    props?.retailerDetails?.data?.retailerdetails?.stores[0].minAmount
  );

  useEffect(() => {
    if (isNaN(minAmt)) {
      setErrorMessage("Please enter only numbers");
    }
  }, [minAmt]);

  useEffect(() => {
    if (isNaN(packingCharge)) {
      setErrorMessage("Please enter only numbers");
    }
  });

  const [edit, setEdit] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [packingCharge, setPackingCharge] = useState(null);
  const [distance, setDistance] = useState(stores?.[0]?.deliveryMaxDistance);
  const [openingTime, setTime] = useState(
    stores?.[0]?.openingTime
      ? new Date(`2014-08-18T${stores[0].openingTime}`)
      : null
  );
  const [closingTime, setCTime] = useState(
    stores?.[0]?.closingTime
      ? new Date(`2014-08-18T${stores[0].closingTime}`)
      : null
  );
  
  useEffect(() => {
    // Check if stores is populated and update distance accordingly
    if (stores && stores.length > 0) {
      setDistance(stores[0].deliveryMaxDistance);
      setTime(
        stores[0].openingTime ? new Date(`2014-08-18T${stores[0].openingTime}`) : null
      );
      setCTime(
        stores[0].closingTime ? new Date(`2014-08-18T${stores[0].closingTime}`) : null
      );
    }
  }, [stores]);

  useEffect(() => {
    if (props.editStoreSuccess) {
      const newOpeningTime = stores[0].openingTime
        ? new Date(`2014-08-18T${stores[0].openingTime}`)
        : null;
      const newClosingTime = stores[0].closingTime
        ? new Date(`2014-08-18T${stores[0].closingTime}`)
        : null;


      // setTime(newOpeningTime);
      // setCTime(newClosingTime);

      // Assuming props.editStoreSuccess is set to false after the navigation
      dispatch({
        type: "CLEAR_EDITSTORE"
      })

      // Navigate to the next page after updating the state

    }
  }, [props.editStoreSuccess]);


  React.useEffect(() => {
    // Set empty erroMessage only if text is less than MAX_LENGTH
    // and errorMessage is not empty.
    // avoids setting empty errorMessage if the errorMessage is already empty
    if (!isNaN(minAmt) && errorMessage) {
      setErrorMessage("");
    }
  }, [minAmt, errorMessage]);

  const [buttonSlotsColor, setButtonSlotsColor] = useState(true);
  const [buttonSettingsColor, setButtonSettingsColor] = useState(false);
  // const [isCheckedYes, setIsCheckedYes] = useState(true);
  // const [isCheckedNo, setIsCheckedNo] = useState(false);

  // const handleYesChange = () => {
  //   setIsCheckedYes(true);
  //   setIsCheckedNo(false);
  // };

  // const handleNoChange = () => {
  //   setIsCheckedYes(false);
  //   setIsCheckedNo(true);
  // };

  // const initialState = {
  //   Sunday: false,
  //   Monday: false,
  //   Tuesday: false,
  //   Wednesday: false,
  //   Thursday: false,
  //   Friday: false,
  //   Saturday: false,
  // };

useEffect(()=>{
 if(props.editStoreSuccess){
  message.success({
    content: "Saved changes successfully",
    className: "custom-class",
    style: {
      marginTop: "10vh",
      marginLeft: "15vw",
    },
    duration: 3.5,
  });
  setTimeout(() => {
    nav('/home/Profile')
  }, 4000);
 }else if(props.editStoreFailed
 ){
  message.error({
    content: props.editStoreMessage,
    className: "custom-class",
    style: {
      marginTop: "10vh",
      marginLeft: "15vw",
    },
    duration: 3.5,
  });
  setFormSubmitted(false)
 }
},[props.editStoreSuccess,props.editStoreFailed])


  const Days = [
    {
      value: 1,
      label: "with in 24 hours",
    },
    {
      value: 2,
      label: " within 2 Day",
    },
    {
      value: 3,
      label: " within 3 Day",
    },
  ];

  const distanceTable = {
    label: "Delivery Max-Distance",
    name: "deliveryMaxDistance",
    type: "selectbox",
    value: "3",
    options: ["3", "6", "9", "12", "15"],
  };

  const holidays = [
    { name: "Sunday", status: false, val: 0 },
    { name: "Monday", status: false, val: 1 },
    { name: "Tuesday", status: false, val: 2 },
    { name: "Wednesday", status: false, val: 3 },
    { name: "Thursday", status: false, val: 4 },
    { name: "Friday", status: false, val: 5 },
    { name: "Saturday", status: false, val: 6 },
  ];

  // const initialSliderState = {
  //   Monday: {
  //     "09 AM - 12 PM": false,
  //     "12 PM - 03 PM": false,
  //     "03 PM - 06 PM": false,
  //     "06 PM - 09 PM": false,
  //   },
  //   Tuesday: {
  //     "09 AM - 12 PM": false,
  //     "12 PM - 03 PM": false,
  //     "03 PM - 06 PM": false,
  //     "06 PM - 09 PM": false,
  //   },
  //   Wednesday: {
  //     "09 AM - 12 PM": false,
  //     "12 PM - 03 PM": false,
  //     "03 PM - 06 PM": false,
  //     "06 PM - 09 PM": false,
  //   },
  //   Thursday: {
  //     "09 AM - 12 PM": false,
  //     "12 PM - 03 PM": false,
  //     "03 PM - 06 PM": false,
  //     "06 PM - 09 PM": false,
  //   },
  //   Friday: {
  //     "09 AM - 12 PM": false,
  //     "12 PM - 03 PM": false,
  //     "03 PM - 06 PM": false,
  //     "06 PM - 09 PM": false,
  //   },
  //   Saturday: {
  //     "09 AM - 12 PM": false,
  //     "12 PM - 03 PM": false,
  //     "03 PM - 06 PM": false,
  //     "06 PM - 09 PM": false,
  //   },
  //   Sunday: {
  //     "09 AM - 12 PM": false,
  //     "12 PM - 03 PM": false,
  //     "03 PM - 06 PM": false,
  //     "06 PM - 09 PM": false,
  //   },
  //   // Repeat for other days...
  // };

  // const [state, dispatch] = useReducer(reducer, initialState);
  // const [sliderState, dispatchSlider] = useReducer(
  //   sliderReducer,
  //   initialSliderState
  // );

  // function handleButtonClick(day) {
  //   dispatch({ type: ActionTypes.TOGGLE_BUTTON, payload: { day } });
  // }

  // function handleSliderButton(day, timeSlot) {
  //   dispatchSlider({
  //     type: ActionTypes.TOGGLE_SLIDER_BUTTON,
  //     payload: { day, timeSlot },
  //   });
  // }

  function MuiIcon1() {
    return <img src={ClockIcon} alt="clock icon" width={25} />;
  }

  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const getTimeChange = (e) => {
    setTime(e);
  };
  const getCTimeChange = (e) => {
    setCTime(e);
  };

  function handleSlotsColor() {
    setButtonSlotsColor(true);
    setButtonSettingsColor(false);
  }

  function handleSettingsColor() {
    setButtonSettingsColor(true);
    setButtonSlotsColor(false);
  }

  const checkHoliday = (i, holiday) => {
    let isHoliday = false;
    if (holiday.length === 0) {
      return false;
    } else {
      holiday.map((h) => {
        if (Number(h) === i) isHoliday = true;
      });
    }
    return isHoliday;
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matches1 = useMediaQuery(theme.breakpoints.down("sm"));
  const matches2 = useMediaQuery(theme.breakpoints.up("lg"));
  const matches3 = useMediaQuery(theme.breakpoints.down("xl"));
  const matches4 = useMediaQuery(theme.breakpoints.down("lg"));
  const upxlmatch = useMediaQuery(theme.breakpoints.up("xl"));


  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      firstKm: props?.retailerDetails && props?.retailerDetails?.data?.retailerdetails?.stores[0]?.deliveryCharge[0]?.charge,
      secondKm: props?.retailerDetails && props?.retailerDetails?.data?.retailerdetails?.stores[0]?.deliveryCharge[1]?.charge,
      thirdKm: props?.retailerDetails && props?.retailerDetails?.data?.retailerdetails?.stores[0]?.deliveryCharge[2]?.charge,
      fourthKm: props?.retailerDetails && props?.retailerDetails1?.data?.retailerdetails?.stores[0]?.deliveryCharge[3]?.charge,
      fifthKm: props?.retailerDetails && props?.retailerDetails?.data?.retailerdetails?.stores[0]?.deliveryCharge[4]?.charge,
    },
    validationSchema: ManageValidationSchema(distance),
    onSubmit: (values) => {
      let {
        firstKm,
        secondKm,
        thirdKm,
        fourthKm,
        fifthKm,
      } = values;

      setManageData(values.fifthKm)


      if (props?.retailerDetails?.data?.retailerdetails?.stores[0].deliveryMaxDistance == 0) {
        message.error({
          content: "choose any one Delivery Type",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      } else {
        props.editDeliveryCharge({
          value: values.firstKm,
          index: 0,
          details: props.retailerDetails.data.retailerdetails,
        });
        props.editDeliveryCharge({
          value: values.secondKm,
          index: 1,
          details: props.retailerDetails.data.retailerdetails,
        });
        props.editDeliveryCharge({
          value: values.thirdKm,
          index: 2,
          details: props.retailerDetails.data.retailerdetails,
        });
        props.editDeliveryCharge({
          value: values.fourthKm,
          index: 3,
          details: props.retailerDetails.data.retailerdetails,
        });
        props.editDeliveryCharge({
          value: values.fifthKm,
          index: 4,
          details: props.retailerDetails.data.retailerdetails,
        });

        setFormSubmitted(true)

        props.StoreEdit(
          props?.retailerDetails?.data?.retailerdetails?.stores[0],
          openingTime,
          closingTime
        );
      setTimeout(() => {
        dispatch({
          type:"CLEAR_EDITSTORE"
        })
      }, 1000);
      }


    }
  })

  const minimumformik = useFormik({
    enableReinitialize:true,
    initialValues: {
      minimumOrder: props?.retailerDetails?.data?.retailerdetails?.stores[0]?.minAmount,
      packingChargeState:props?.retailerDetails?.data?.retailerdetails?.stores[0]?.packingCharge,
    },
    validationSchema: ManageSettingSchema,
    onSubmit: (values) => {
      let {
        minimumOrder,
        packingChargeState,
      } = values;

 
      


      if (props?.retailerDetails?.data?.retailerdetails?.stores[0].deliveryType
        .length === 0) {
        message.error({
          content: "choose any one Delivery Type",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      } else if (openingTime.getTime() === closingTime.getTime()) {
        message.error({
          content: "Opening and closing time must be different",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
      } else if (isNaN(openingTime.getTime())) {
        message.error({
          content: "Opening time is required",
          className: "custom-class",
          style: { marginTop: "10vh", marginLeft: "15vw" },
          duration: 3.5,
        });
      } else if (isNaN(closingTime.getTime())) {
        message.error({
          content: "Closing time is required",
          className: "custom-class",
          style: { marginTop: "10vh", marginLeft: "15vw" },
          duration: 3.5,
        });
      }
      else {

        props.textStoreChanges(values.minimumOrder,values.packingChargeState, props.retailerDetails);
        setFormSubmitted1(true)
        props.StoreEdit(
          props?.retailerDetails?.data?.retailerdetails?.stores[0],
          openingTime,
          closingTime,
          props.retailerDetails.data.retailerdetails.stores[0]
            .sameDayDelivery,
          props.DeliveryAfter
        );

      setTimeout(() => {
        dispatch({
          type:"CLEAR_EDITSTORE"
        })
      }, 500);

      }

    }
  })


  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorFieldName = Object.keys(formik.errors)[0];
      const errorField = document.getElementsByName(errorFieldName)[0];
      if (errorField) {
        errorField.focus();
      } else {
        console.error(`Element with name ${errorFieldName} not found.`);
      }
    }
  }, [formik.isSubmitting, formik.errors]);

  useEffect(() => {
    if (!minimumformik.isSubmitting) return;
    if (Object.keys(minimumformik.errors).length > 0) {
      const errorFieldName = Object.keys(minimumformik.errors)[0];
      const errorField = document.getElementsByName(errorFieldName)[0];
      if (errorField) {
        errorField.focus();
      } else {
        console.error(`Element with name ${errorFieldName} not found.`);
      }
    }
  }, [minimumformik.isSubmitting, minimumformik.errors]);

  return (
    <div style={{ background: "#F4F5F9", width: "100%" }}>
      {/* {start} */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={6}
            style={{ paddingLeft: "0px" }}
          >
            <div>
              <CustomButton
                width="100%"
                height="100%"
                borderRadius="15px"
                onClick={handleSlotsColor}
                variant={buttonSlotsColor ? "contained" : "outlined"}
                borderColor="#659B1B"
                background={buttonSlotsColor ? "#659B1B" : "white"}
                color={buttonSlotsColor ? "white" : "#646464"}
                size="large"
                matchPadding={true}
                padding="10px 0px"
              >
                <Typography
                  fontSize={ButtonFontSize_18_xs_10}
                  className="ManageInitialBtnStyle"
                >
                  Delivery Slots
                </Typography>
              </CustomButton>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div>
              <CustomButton
                width="100%"
                height="100%"
                borderRadius="15px"
                onClick={handleSettingsColor}
                borderColor="#659B1B"
                variant={buttonSettingsColor ? "contained" : "outlined"}
                background={buttonSettingsColor ? "#659B1B" : "white"}
                color={buttonSettingsColor ? "white" : "#646464"}
                size="large"
                matchPadding={true}
                padding="10px 0px"
              >
                <Typography
                  fontSize={ButtonFontSize_18_xs_10}
                  className="ManageInitialBtnStyle"
                >
                  Delivery Settings
                </Typography>
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
      {/* {end} */}

      <div style={{ width: "100%" }}>
        {buttonSlotsColor ? (
          <div className="deliverySlotStyle" style={{ marginTop: "15px" }}>
            <form onSubmit={formik.handleSubmit}>
              <Typography
                className="chooseHolidaysStyle"
                fontSize={HeaderNavigationFontSize_20}
              >
                Choose Holidays <span style={{ color: " #EA4335" }}>*</span>
              </Typography>
              <div style={{ width: "100%", padding: matches ? "10px 0px 10px 0px" : "30px 0px 30px 0px" }}>
                <Grid container spacing={1} justifyContent="space-between">
                  {holidays.map((h, index) => (
                    <Grid
                      item
                      key={index}
                      xs={6}
                      sm={6}
                      md={1.714}
                      lg={1.714}
                      xl={1.714}
                    >
                      <CustomButton
                        variant={
                          stores?.[0]?.holidays.includes(h.val)
                            ? "contained"
                            : "outlined"
                        }
                        color={
                          stores?.[0]?.holidays.includes(h.val) ? "white" : "#85BE49"
                        }
                        borderColor="#85BE49"
                        width="100%"
                        height="100%"
                        borderRadius="11.88px"
                        size={matches ? "small" : "large"}
                        background={
                          stores?.[0]?.holidays.includes(h.val)
                            ? "#85BE49"
                            : "#F4F5F9"
                        }
                        onClick={(e) => {
                          props.setHolidaySlot(
                            index,
                            props?.retailerDetails?.data?.retailerdetails
                          );
                        }}
                      // onClick={() => handleButtonClick(day)}
                      >
                        <Typography
                          fontSize={ButtonFontSize_17}
                          className="weekFontStyle"
                        >
                          {h.name}
                        </Typography>
                      </CustomButton>
                    </Grid>
                  ))}
                </Grid>
                {stores?.[0]?.holidays?.length === 7 && (
                  <>
                    <div style={{ marginTop: "20px" }}>
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="warning">
                          Choosing all days is not allowed
                        </Alert>
                      </Stack>
                    </div>
                  </>
                )}
              </div>

              <Typography
                className="chooseHolidaysStyle"
                fontSize={HeaderNavigationFontSize_20}
              >
                Choose Delivery Schedule{" "}
                <span style={{ color: " #EA4335" }}>*</span>
              </Typography>
              <div style={{ width: "100%" }}>
                <div className="scrollContainer">
                  {/* {start} */}

                  <div className="SliderHeaderflex">
                    {stores?.[0]?.deliveryDays.map(
                      (day, key) =>
                        checkHoliday(key, stores[0].holidays) === false && (

                          <CustomCard
                            padding="20px"
                            key={key}
                            // boxShadow="none"
                            width="400px"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: matches ? "15px" : "30px",
                                width: matches1 ? "250px" : "350px",
                              }}
                            >
                              <Typography
                                style={{ textAlign: "center" }}
                                fontSize={HeaderFontSize_25}
                                className="DeliveryStyle"
                              >
                                {day.deliveryDay !== null && day.deliveryDay}
                              </Typography>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "auto auto",
                                  gap: matches1 ? "20px" : "30px",
                                }}
                              >
                                <CustomButton
                                  key={"slot1"}
                                  // variant="outlined"
                                  borderRadius="14.41px"
                                  size={matches ? "small" : "large"}
                                  textColor={
                                    day.T9amTO12pm === "Yes" ? "white" : "white"
                                  }
                               
                                    background={
                                      day.T9amTO12pm === "Yes"
                                        ? "#85BE49"
                                        : "#8B8989"
                                    }
                                  onClick={() => {
                                    props.scheduleEdit({
                                      key,
                                      timing: "T9amTO12pm",
                                      scheduleDetail:
                                        props.retailerDetails.data
                                          .retailerdetails,
                                    });
                                  }}
                                  matchPadding={true}
                                  padding={matches ? "5px 15px" : "10px 60px"}
                                >
                                  <Typography
                                    className="DeliveryStyle"
                                    fontSize={ButtonFontSize_18_xs_10}
                                  >
                                    {" "}
                                    09 AM - 12 PM
                                  </Typography>
                                </CustomButton>
                                <CustomButton
                                  key={"slot2"}
                                  // variant="outlined"
                                  borderRadius="14.41px"
                                  size={matches ? "small" : "large"}
                                  textColor={
                                    day.T12pmTO3pm === "Yes" ? "white" : "white"
                                  }
                                  background={
                                    day.T12pmTO3pm === "Yes"
                                      ? "#85BE49"
                                      : "#8B8989"
                                  }
                                  onClick={() => {
                                    props.scheduleEdit({
                                      key,
                                      timing: "T12pmTO3pm",
                                      scheduleDetail:
                                        props.retailerDetails.data
                                          .retailerdetails,
                                    });
                                  }}
                                  matchPadding={true}
                                  padding={matches ? "5px 15px" : "10px 60px"}
                                >
                                  <Typography
                                    className="DeliveryStyle"
                                    fontSize={ButtonFontSize_18_xs_10}
                                  >
                                    {" "}
                                    12 PM - 03 PM
                                  </Typography>
                                </CustomButton>
                                <CustomButton
                                  key={"slot3"}
                                  // variant="outlined"
                                  borderRadius="14.41px"
                                  size={matches ? "small" : "large"}
                                  textColor={
                                    day.T3pmTO6pm === "Yes" ? "white" : "white"
                                  }
                                  background={
                                    day.T3pmTO6pm === "Yes"
                                      ? "#85BE49"
                                      : "#8B8989"
                                  }
                                  onClick={() => {
                                    props.scheduleEdit({
                                      key,
                                      timing: "T3pmTO6pm",
                                      scheduleDetail:
                                        props.retailerDetails.data
                                          .retailerdetails,
                                    });
                                  }}
                                  matchPadding={true}
                                  padding={matches ? "5px 15px" : "10px 60px"}
                                >
                                  <Typography
                                    className="DeliveryStyle"
                                    fontSize={ButtonFontSize_18_xs_10}
                                  >
                                    {" "}
                                    03 PM - 06 PM
                                  </Typography>
                                </CustomButton>
                                <CustomButton
                                  key={"slot4"}
                                  // variant="outlined"
                                  borderRadius="14.41px"
                                  size={matches ? "small" : "large"}
                                  textColor={
                                    day.T6pmTO9pm === "Yes" ? "white" : "white"
                                  }
                                  background={
                                    day.T6pmTO9pm === "Yes"
                                      ? "#85BE49"
                                      : "#8B8989"
                                  }
                                  onClick={() => {
                                    props.scheduleEdit({
                                      key,
                                      timing: "T6pmTO9pm",
                                      scheduleDetail:
                                        props.retailerDetails.data
                                          .retailerdetails,
                                    });
                                  }}
                                  matchPadding={true}
                                  padding={matches ? "5px 15px" : "10px 60px"}
                                >
                                  <Typography
                                    className="DeliveryStyle"
                                    fontSize={ButtonFontSize_18_xs_10}
                                  >
                                    {" "}
                                    06 PM - 09 PM
                                  </Typography>
                                </CustomButton>
                              </div>
                            </div>
                          </CustomCard>
                        )
                    )}
                  </div>
                  {/* {end} */}
                </div>
              </div>
              <Typography
                className="chooseHolidaysStyle"
                fontSize={HeaderNavigationFontSize_20}
                style={{ paddingTop: matches ? "10px" : "20px" }}
              >
                Delivery Details (Maximum distance for delivery){" "}
                <span style={{ color: " #EA4335" }}>*</span>
              </Typography>
              <div>
                <Box
                  style={{
                    width: "100%",
                    background: "white",
                    borderRadius: "10px",
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      size={matches ? "small" : "normal"}
                      // match={matches ? true : false}
                      sx={{
                        color: "white",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#85BE49",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#85BE49",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#85BE49",
                        },
                      }}

                      MenuProps={{
                        disableScrollLock: true,
                        marginThreshold: null,
                      }}
                      value={stores?.[0]?.deliveryMaxDistance}
                      onChange={(e) => {
                        setDistance(e.target.value);
                        props.changeMaxDistance({
                          value: e.target.value,
                          details: props.retailerDetails.data.retailerdetails,
                        });
                      }}
                    >
                      {distanceTable.options.map((option, index) => {
                        return (
                          <MenuItem
                            style={{ width: "100%" }}
                            value={option}
                            key={index}
                          >
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              
              {/* {start} */}
              <div style={{ marginTop: matches ? "20px" : "25px" }}>
                <EditDeliveryCharge props={props} distance={distance} formik={formik} />
              </div>
              {/* {end} */}

              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <CustomButton
                  width={matches ? "40%" : "30%"}
                  borderRadius="20px"
                  size={matches ? "medium" : "large"}
                  type="submit"
                  matchPadding={true}
                  padding="10px 0px"
                  background={formSubmitted ? "gray" : '#659B1B'}
                  disabled={formSubmitted && "disabled"}
                >
                  <Typography
                    fontSize={ButtonFontSize_18}
                    className="manageSlotButton"
                  >
                    Save Changes
                  </Typography>
                </CustomButton>
              </div>
            </form>
          </div>
        ) : (
          <div style={{ marginTop: "35px" }}>
            <form onSubmit={minimumformik.handleSubmit}>
              <div style={{display: "flex",flexDirection: "column",gap:matches1?"10px":"25px"}}>
                {/* {start} */}
                <Grid container spacing={1} justifyContent="space-between">
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {/* {start} */}
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12} 
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{ paddingLeft: "0px", paddingTop: "0px" }}
                      >
                        {stores[0].businessTypeId.includes(11) ? (
                          <SameDayDelivery {...props} />
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        style={{
                          paddingLeft: matches1 ? "0px" : "16px",
                          paddingTop: "0px",
                        }}
                      >
                        {stores[0].sameDayDelivery === true ? null : (
                          stores[0].businessTypeId.includes(11) &&
                          (<DeliveryDayAfter
                            {...props}
                            name={"DeliveryDays"}
                            label={Days.label}
                            data={Days}
                            fullWidth
                          />)
                        )}
                      </Grid>
                    </Grid>

                    {/* {end} */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: matches1 ? "flex-start" : "flex-end",
                      }}
                    >
                      <div className="DeliSettingsColFlex">
                        <Typography
                          className="chooseHolidaysStyle"
                          fontSize={contentFontSize_18}
                        >
                          Type of delivery you support?{" "}
                          <span style={{ color: "#EA4335" }}>*</span>
                        </Typography>
                        <div className="flexboxforcheckbox">
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              style={{
                                padding: 0,
                                borderRadius: 0,
                                color: "#85BE49",
                              }}
                              onChange={(e) => {
                                props.checkBoxEdit(
                                  e,
                                  props.retailerDetails.data.retailerdetails
                                );
                              }}
                              name="deliveryType"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              value="delivery"
                              checked={
                                stores[0].deliveryType.includes("delivery")
                                  ? true
                                  : false
                              }
                              size={matches ? "small" : "medium"}
                            />
                            <Typography
                              className="kmStyle"
                              fontSize={HeaderNavigationFontSize_20}
                            >
                              Delivery
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              style={{
                                padding: 0,
                                borderRadius: 0,
                                color: "#85BE49",
                              }}
                              onChange={(e) => {
                                props.checkBoxEdit(
                                  e,
                                  props.retailerDetails.data.retailerdetails
                                );
                              }}
                              name="deliveryType"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              value="pick-up"
                              checked={
                                stores[0].deliveryType.includes("pick-up")
                                  ? true
                                  : false
                              }
                              size={matches ? "small" : "medium"}
                            />
                            <Typography
                              className="kmStyle"
                              fontSize={HeaderNavigationFontSize_20}
                            >
                              Pick Up
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                {/* {end} */}

                <div style={{display:"flex",justifyContent:"space-between",width:"100%",gap:'20px',flexWrap:"wrap"}}>
                  <div style={{ paddingTop: "0px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        // marginBottom:matches4&&"10px",
                      }}
                    >
                      <Typography
                        className="chooseHolidaysStyle"
                        fontSize={HeaderNavigationFontSize_20}
                      >
                        Enter the minimum order amount{" "}
                        <span style={{ color: "#EA4335" }}>*</span>
                      </Typography>
                      <div>
                        <TextField
                          sx={{
                            width: matches2 ? "450px" : "100%",

                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#85BE49",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#85BE49",
                              },
                            },
                            "& .MuiInputBase-input": {
                              backgroundColor: "white",
                              borderRadius: "10px",
                            },
                          }}
                          InputProps={{
                            sx: {
                              fontSize: matches && "13px",
                              //  height: 50
                            },
                          }}
                          size={matches ? "small" : "normal"}
                          value={minimumformik.values.minimumOrder}
                          onChange={minimumformik.handleChange}
                          error={
                            minimumformik.touched.minimumOrder &&
                            Boolean(minimumformik.errors.minimumOrder)
                          }
                          helperText={
                            minimumformik.touched.minimumOrder &&
                            minimumformik.errors.minimumOrder
                          }
                          name="minimumOrder"

                          // InputProps={{ style: { height: 50 } }}
                          variant="outlined"


                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: "0px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        // marginBottom:matches4&&"10px",
                      }}
                    >
                      <Typography
                        className="chooseHolidaysStyle"
                        fontSize={HeaderNavigationFontSize_20}
                      >
                       Enter the Packing Charges{" "}
                        <span style={{ color: "#EA4335" }}>*</span>
                      </Typography>
                      <div>
                        <TextField
                          sx={{
                            width: matches2 ? "450px" : "100%",

                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#85BE49",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#85BE49",
                              },
                            },
                            "& .MuiInputBase-input": {
                              backgroundColor: "white",
                              borderRadius: "10px",
                            },
                          }}
                          InputProps={{
                            sx: {
                              fontSize: matches && "13px",
                              //  height: 50
                            },
                          }}
                          size={matches ? "small" : "normal"}
                          value={minimumformik.values.packingChargeState}
                          onChange={minimumformik.handleChange}
                          error={
                            minimumformik.touched.packingChargeState &&
                            Boolean(minimumformik.errors.packingChargeState)
                          }
                          helperText={
                            minimumformik.touched.packingChargeState &&
                            minimumformik.errors.packingChargeState
                          }
                          name="packingChargeState"

                          // InputProps={{ style: { height: 50 } }}
                          variant="outlined"


                        ></TextField>
                      </div>
                    </div>
                  </div>
                 
                </div>
                {/* {end} */}


                <div style={{ display: "flex",gap:"10px",flexWrap: "wrap", paddingLeft: "0px", marginTop: matches3 && "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        className="chooseHolidaysStyle"
                        fontSize={HeaderNavigationFontSize_20}
                      >
                        Opening Time <span style={{ color: "#EA4335" }}>*</span>
                      </Typography>
                      <div>
                        {/* {Start} */}

                        <div
                        >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                slots={{
                                  openPickerIcon: MuiIcon1,


                                }}
                                slotProps={{
                                  textField: {
                                    size: matches && "small",
                                  },
                                }}
                                sx={{
                                  width: "80%",

                                  "& .MuiOutlinedInput-root": {
                                    paddingRight: "25px",
                                    "& fieldset": {
                                      borderColor: "#85BE49",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#85BE49",
                                    },
                                  },
                                  "& .MuiInputBase-input": {
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    fontSize: matches && "13px",
                                    paddingLeft: "25px"
                                  },
                                }}
                                name="openingTime"
                                value={openingTime}
                                onChange={(e) => {
                                  getTimeChange(e);
                                }}
                                renderInput={(props) => (
                                  <TextField
                                    onKeyDown={onKeyDown}
                                    variant="outlined"
                                    {...props}
                                  />
                                )}
                              // onClickOpenPicker={() => setTime('')}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>

                      </div>
                    </div>

                    <div
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Typography
                          className="chooseHolidaysStyle"
                          fontSize={HeaderNavigationFontSize_20}
                        >
                          Closing Time <span style={{ color: "#EA4335" }}>*</span>
                        </Typography>
                        <div>
                          {/* {Start} */}

                          <div
                          >
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DemoContainer components={["TimePicker"]}>
                                <TimePicker
                                  slots={{
                                    openPickerIcon: MuiIcon1,
                                  }}
                                  slotProps={{
                                    textField: {
                                      placeholder: "Closing Time",
                                      size: matches && "small",

                                    },
                                  }}
                                  sx={{
                                    width: "80%",

                                    "& .MuiOutlinedInput-root": {
                                      paddingRight: "25px",
                                      "& fieldset": {
                                        borderColor: "#85BE49",

                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#85BE49",
                                      },
                                    },
                                    "& .MuiInputBase-input": {
                                      backgroundColor: "white",
                                      borderRadius: "10px",
                                      fontSize: matches && "13px",
                                      paddingLeft: "25px"
                                    },
                                  }}
                                  name={"closingTime"}
                                  value={closingTime || ""}
                                  onChange={(e) => {
                                    getCTimeChange(e);
                                  }}
                                  renderInput={(props) => (
                                    <TextField
                                      onKeyDown={onKeyDown}
                                      variant="outlined"
                                      {...props}
                                    />
                                  )}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div style={{ textAlign: "center", marginTop: "105px" }}>
                <CustomButton

                  width={matches ? "40%" : "30%"}
                  borderRadius="20px"
                  size={matches ? "medium" : "large"}

                  matchPadding={true}
                  padding="10px 0px"
                  type="submit"
                  background={formSubmitted1 ? "gray" : '#659B1B'}
                  disabled={formSubmitted1 && "disabled"}
                >
                  <Typography
                    fontSize={ButtonFontSize_18}
                    className="manageSlotButton"
                  >
                    Save Changes
                  </Typography>
                </CustomButton>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
