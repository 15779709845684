import * as yup from 'yup';

export const validationSchema = yup.object({
    productname: yup
      .string()
      .required('Product name is required'),
    desc: yup
      .string()
      .required('Product description is required'),
    barcode: yup
      .string()
      .trim("Space not allowed"),
      // .required('Barcode is required'),
      price:yup
       .string()
       .trim()
       .matches(/^(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/, "Only positive numbers are allowed")
       .required('Price is required'),
       promoprice:yup
       .string()
       .trim("Space not allowed")
       .matches(/^(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/, "Only positive numbers are allowed"),
    brand:yup
       .string(),
       mfgname:yup
       .string(),
       quantity:yup
       .string()
       .matches(/^(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/, "Only positive numbers are allowed")
       .trim("Space not allowed")
       .required('Quantity is required'),  
       uom:yup
       .string()
       .oneOf(['No.s','kg','g','gal','l','ml']),
      //  displayrank:yup
      //  .string()
      //  .trim("Space not allowed")
      //  .matches(/^\d+$/,'Only numbers are allowed')
      //  .required('Display rank is required'),
    // status:yup
    //    .string()
    //    .trim("Space not allowed")
    //    .required('Status is required'),
   //  department:yup
   //     .string()
   //     .required('Department is required'),
   //  salescategory:yup
   //     .string(),
    //    .required('Category is required'),
   //  salescategory1:yup
   //     .string(),
    //    .required('Subcategory is required'),
   //  salescategory2:yup
   //     .string()
    //    .required('Subcategory 1 is required'),
  });