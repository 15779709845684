// // import React from 'react';
// // import PropTypes from 'prop-types';
// // import Box from '@mui/material/Box';
// // import InputLabel from '@mui/material/InputLabel';
// // import FormControl from '@mui/material/FormControl';
// // import Select from '@mui/material/Select';

// // function CustomSelect({ width, height, label, defaultValue, options, background, variant, borderRadius, border,size,onChange,match }) {
// //   return (
// //     <Box style={{ width: width, height: height, background: background, borderRadius: borderRadius, border: border }}>
// //       <FormControl fullWidth>
// //         <InputLabel htmlFor="custom-native">{label}</InputLabel>
// //         <Select
// //           native  // Set native prop to true
// //           variant={variant}
// //           size={size}
// //           onChange={onChange}
// //           defaultValue={defaultValue}
// //           inputProps={{
// //             sx: {
// //               fontSize: match && '13px', // Adjust font sizes accordingly
// //             },
// //             name: 'custom-native',
// //             id: 'custom-native',
// //           }}
// //         >
// //           {options.map(option => (
// //             <option key={option.value} value={option.value}>{option.label}</option>
// //           ))}
// //         </Select>
// //       </FormControl>
// //     </Box>
// //   );
// // }

// // CustomSelect.propTypes = {
// //   width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// //   height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// //   label: PropTypes.string.isRequired,
// //   background: PropTypes.string.isRequired,
// //   variant: PropTypes.string.isRequired,
// //   size: PropTypes.string.isRequired,
// //   match:PropTypes.bool,
// //   borderRadius: PropTypes.isRequired,
// //   border: PropTypes.isRequired,
// //   onChange:PropTypes.func,
// //   defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// //   options: PropTypes.arrayOf(PropTypes.shape({
// //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// //     label: PropTypes.string.isRequired
// //   })).isRequired,
// // };

// // CustomSelect.defaultProps = {
// //   defaultValue: "",
// // };

// // export default CustomSelect;
// import React from 'react';
// import PropTypes from 'prop-types';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// function CustomSelect({ width, height, label, defaultValue, options, background, variant, borderRadius, border,size,onChange,match }) {
//   const handleChange = (event) => {
//     onChange(event); // Call the provided onChange function
//   };
//   return (
//     <Box style={{ width: width, height: height, background: background, borderRadius: borderRadius, border: border }}>
//       <FormControl fullWidth>
//         <InputLabel htmlFor="custom-native">{label}</InputLabel>
//         <Select
//           native  // Set native prop to true
//           variant={variant}
//           size={size}
//           onChange={handleChange}
//           defaultValue={defaultValue}
//           inputProps={{
//             sx: {
//               fontSize: match && '13px', // Adjust font sizes accordingly
//             },
//             name: 'custom-native',
//             id: 'custom-native',
//           }}
//         >
//           {options.map(option => (
//             <option key={option.value} value={option.value}>{option.label}</option>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   );
// }

// CustomSelect.propTypes = {
//   width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   label: PropTypes.string.isRequired,
//   background: PropTypes.string.isRequired,
//   variant: PropTypes.string.isRequired,
//   size: PropTypes.string.isRequired,
//   match:PropTypes.bool,
//   borderRadius: PropTypes.isRequired,
//   border: PropTypes.isRequired,
//   onChange:PropTypes.func,
//   defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   options: PropTypes.arrayOf(PropTypes.shape({
//     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     label: PropTypes.string.isRequired
//   })).isRequired,
// };

// CustomSelect.defaultProps = {
//   defaultValue: "",
// };

// export default CustomSelect;
import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function CustomSelect({ width, height, label, defaultValue, options, background, variant, borderRadius, border, size, onChange, match }) {
  const handleChange = (event) => {
    onChange(event); // Call the provided onChange function
  };

  return (
    <Box style={{ width: width, height: height, background: background, borderRadius: borderRadius, border: border }}>
      <FormControl fullWidth>
        <InputLabel htmlFor="custom-native">{label}</InputLabel>
        <Select
          native  // Set native prop to true
          variant={variant}
          size={size}
          onChange={handleChange}
          defaultValue={defaultValue}
          inputProps={{
            sx: {
              fontSize: match && '13px', // Adjust font sizes accordingly
            },
            name: 'custom-native',
            id: 'custom-native',
            style: {
              color: 'black', // Arrow color
            }
          }}
          MenuProps={{
            PaperProps: {
              style: {
                border: '1px rgba(133, 190, 73, 1)', // Border color for menu
              },
            },
          }}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

CustomSelect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  match: PropTypes.bool,
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string.isRequired
  })).isRequired,
};

CustomSelect.defaultProps = {
  defaultValue: "",
};

export default CustomSelect;
