import * as yup from 'yup';

export const ManageValidationSchema = (distance) => {
  const schema = {};
  
  if (distance >= 3) {
    schema.firstKm = yup.string()
      .trim("Space not allowed")
      .required('0-3 km is required')
      .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0)
      .matches(/^[1-9]\d*$/, 'Please enter a valid number where the starting digit is not zero');
  }
  if (distance >= 6) {
    schema.secondKm = yup.string()
      .trim("Space not allowed")
      .required('3-6 km is required')
      .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0)
      .matches(/^[1-9]\d*$/, 'Please enter a valid number where the starting digit is not zero');
  }
  if (distance >= 9) {
    schema.thirdKm = yup.string()
      .trim("Space not allowed")
      .required('6-9 km is required')
      .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0)
      .matches(/^[1-9]\d*$/, 'Please enter a valid number where the starting digit is not zero');
  }
  if (distance >= 12) {
    schema.fourthKm = yup.string()
      .trim("Space not allowed")
      .required('9-12 km is required')
      .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0)
      .matches(/^[1-9]\d*$/, 'Please enter a valid number where the starting digit is not zero');
  }
  if (distance >= 15) {
    schema.fifthKm = yup.string()
      .trim("Space not allowed")
      .required('12-15 km is required')
      .test('not-zero', 'Value cannot be zero', value => parseFloat(value) !== 0)
      .matches(/^[1-9]\d*$/, 'Please enter a valid number where the starting digit is not zero');
  }

  return yup.object(schema);
}



export const ManageSettingSchema = yup.object({
minimumOrder:yup.string()
.trim("Space not allowed")
  .required('Minimum Order value is required')
  .test('valid-number', 'Only numbers are allowed', value => {
    const numValue = parseFloat(value);
    return !isNaN(numValue); // Check if it's a valid number
  })
  .test('not-negative', 'Positive numbers are allowed', value => {
    const numValue = parseFloat(value);
    return numValue >= 0; // Check if it's not negative
  })
  .test('not-zero', 'Value cannot be zero', value => {
    const numValue = parseFloat(value);
    return numValue !== 0; // Check if it's not zero
  })
  .test('no-alphabet', 'Only numbers are allowed', value => {
    return /^\d+$/.test(value); // Check if there are only numbers
  }),
  packingChargeState:yup.string()
.trim("Space not allowed")
  .required('packingCharge value is required')
  .test('valid-number', 'Only numbers are allowed', value => {
    const numValue = parseFloat(value);
    return !isNaN(numValue); // Check if it's a valid number
  })
  .test('not-negative', 'Positive numbers are allowed', value => {
    const numValue = parseFloat(value);
    return numValue >= 0; // Check if it's not negative
  })
  .test('not-zero', 'Value cannot be zero', value => {
    const numValue = parseFloat(value);
    return numValue !== 0; // Check if it's not zero
  })
  .test('no-alphabet', 'Only numbers are allowed', value => {
    return /^\d+$/.test(value); // Check if there are only numbers
  }),


})