export const dummyData = {
  business_types: [
    {
      name: "Retail",
      categories: [
        {
          name: "Clothing",
          subcategories: [
            {
              name: "Men's Clothing",
              products: [
                { name: "Shirts", price: 25.99, qty: 100 },
                { name: "Trousers", price: 35.99, qty: 80 },
                { name: "Jackets", price: 59.99, qty: 50 },
                { name: "Suits", price: 199.99, qty: 30 },
              ],
            },
            {
              name: "Women's Clothing",
              products: [
                { name: "Dresses", price: 49.99, qty: 120 },
                { name: "Skirts", price: 29.99, qty: 90 },
                { name: "Blouses", price: 39.99, qty: 80 },
                { name: "Coats", price: 99.99, qty: 40 },
              ],
            },
          ],
        },
        {
          name: "Electronics",
          subcategories: [
            {
              name: "Mobile Phones",
              products: [
                { name: "Smartphones", price: 699.99, qty: 50 },
                { name: "Feature Phones", price: 199.99, qty: 30 },
                { name: "Accessories", price: 29.99, qty: 100 },
              ],
            },
            {
              name: "Computers",
              products: [
                { name: "Laptops", price: 999.99, qty: 40 },
                { name: "Desktops", price: 799.99, qty: 30 },
                { name: "Tablets", price: 499.99, qty: 60 },
                { name: "Accessories", price: 49.99, qty: 150 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Hospitality",
      categories: [
        {
          name: "Hotels",
          subcategories: [
            {
              name: "Luxury Hotels",
              products: [
                { name: "Rooms", price: 199.99, qty: 50 },
                { name: "Suites", price: 299.99, qty: 30 },
                { name: "Dining", price: 49.99, qty: 100 },
                { name: "Spa Services", price: 99.99, qty: 80 },
              ],
            },
            {
              name: "Budget Hotels",
              products: [
                { name: "Rooms", price: 79.99, qty: 100 },
                { name: "Basic Amenities", price: 19.99, qty: 80 },
              ],
            },
          ],
        },
        {
          name: "Restaurants",
          subcategories: [
            {
              name: "Fine Dining",
              products: [
                { name: "Appetizers", price: 9.99, qty: 50 },
                { name: "Main Courses", price: 19.99, qty: 80 },
                { name: "Desserts", price: 7.99, qty: 70 },
                { name: "Beverages", price: 3.99, qty: 150 },
              ],
            },
            {
              name: "Fast Food",
              products: [
                { name: "Burgers", price: 4.99, qty: 120 },
                { name: "Fries", price: 2.99, qty: 150 },
                { name: "Sandwiches", price: 5.99, qty: 100 },
                { name: "Soft Drinks", price: 1.99, qty: 200 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Cold Storage",
      categories: [
        {
          name: "Fruits",
          subcategories: [
            {
              name: "Citrus Fruits",
              products: [
                { name: "Oranges", price: 1.99, qty: 200 },
                { name: "Lemons", price: 0.99, qty: 150 },
                { name: "Grapefruits", price: 2.49, qty: 100 },
              ],
            },
            {
              name: "Berries",
              products: [
                { name: "Strawberries", price: 3.99, qty: 80 },
                { name: "Blueberries", price: 4.99, qty: 70 },
                { name: "Raspberries", price: 5.99, qty: 60 },
              ],
            },
          ],
        },
        {
          name: "Vegetables",
          subcategories: [
            {
              name: "Leafy Greens",
              products: [
                { name: "Spinach", price: 2.49, qty: 100 },
                { name: "Kale", price: 2.99, qty: 90 },
                { name: "Lettuce", price: 1.99, qty: 120 },
              ],
            },
            {
              name: "Root Vegetables",
              products: [
                { name: "Carrots", price: 1.49, qty: 150 },
                { name: "Potatoes", price: 1.29, qty: 200 },
                { name: "Onions", price: 0.99, qty: 180 },
              ],
            },
          ],
        },
        {
          name: "Meat",
          subcategories: [
            {
              name: "Beef",
              products: [
                { name: "Steaks", price: 9.99, qty: 50 },
                { name: "Ground Beef", price: 6.99, qty: 80 },
                { name: "Roasts", price: 12.99, qty: 30 },
              ],
            },
            {
              name: "Poultry",
              products: [
                { name: "Chicken", price: 5.99, qty: 100 },
                { name: "Turkey", price: 7.99, qty: 70 },
                { name: "Duck", price: 9.99, qty: 50 },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Technology",
      categories: [
        {
          name: "Software",
          subcategories: [
            {
              name: "Productivity Software",
              products: [
                { name: "Word Processing", price: 49.99, qty: 200 },
                { name: "Spreadsheets", price: 39.99, qty: 150 },
                { name: "Presentation Software", price: 59.99, qty: 100 },
              ],
            },
            {
              name: "Operating Systems",
              products: [
                { name: "Windows", price: 99.99, qty: 80 },
                { name: "macOS", price: 129.99, qty: 70 },
                { name: "Linux", price: 0, qty: 0 }, // Price for Linux OS may vary
              ],
            },
          ],
        },
        {
          name: "Hardware",
          subcategories: [
            {
              name: "Computer Peripherals",
              products: [
                { name: "Keyboards", price: 29.99, qty: 120 },
                { name: "Mice", price: 19.99, qty: 150 },
                { name: "Monitors", price: 199.99, qty: 50 },
              ],
            },
            {
              name: "Networking Devices",
              products: [
                { name: "Routers", price: 49.99, qty: 80 },
                { name: "Switches", price: 59.99, qty: 70 },
                { name: "Access Points", price: 79.99, qty: 60 },
              ],
            },
          ],
        },
      ],
    },
  ],
};
