export const fieldState ={
    storeName:"",
    emailId:"",
    mobileNumber:"",
    password:"",
} 

export const fields=[
    {
        label:"Store Name",
        type:"input",
        name:"storeName",
        required:true,
        placeholder:"Enter your store name"
    },
    {
        label:"Email",
        type:"email",
        required:true,
        placeholder:"Enter email address related to your store",
        name:"emailId"
    },
    {
        label:"Mobile number",
        type:"input",
        required:true,
        placeholder:"Enter store mobile number ",
        name:"mobileNumber"
    },
    {
        label: "Password",
		type: "password",
		name: "password",
		placeholder: "",
    },
   
  
];