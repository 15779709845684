import {React,useEffect} from "react";
import Rectangle from "../../utils/images/Rectangle.svg";
import sectionbreak from "../../utils/images/sectionbreak.svg";
import sharedimage from "../../utils/images/sharedimage.png";
import { Typography, Container, Box } from "../MUIComponents";
import { contentFontsize_30,contentFontSize_20 } from "../Typography";
import { useNavigate } from "react-router-dom";
function Terms() {

  const nav=useNavigate()

  const handleHomeClick =()=>{
     nav('/')
  }

useEffect(()=>{
  window.scrollTo(0,0)
},[])


  return (
    <>
      <div className="parent" style={{ position: "relative" }}>
        <img src={sharedimage} width="100%" className="image1" />
        <div className="top-right">
        <img src="/static/media/Logo.8e4fa8338e24da3e1e2e.png" width="210px" height="70px" alt="shop and deliver.png" />
        </div>
        <div class="top-left">
        <Typography
          style={{ color: "#2D2A6E", fontFamily: "Nunito", fontWeight: "700", cursor:"pointer"}}
          fontSize={contentFontSize_20}
          onClick={handleHomeClick}
        >
          Home
        </Typography>
        <Typography
          style={{ color: "#69A85C", fontFamily: "Nunito", fontWeight: "700" }}
          fontSize={contentFontSize_20}
        >
       Terms & conditions
        </Typography>
        </div>
        <div class="top-left1">
          <Typography sx={{fontFamily:"Roboto",fontWeight:"800"}} fontSize={contentFontsize_30}>Terms & conditions</Typography>
        </div>
      </div>
      <div
        style={{ paddingLeft: "50px", paddingRight: "35px", marginTop: "90px" }}
      >
        <Box mb={4}>
          <Typography
            variant="h5"
            gutterBottom
            fontFamily="Roboto"
            sx={{ paddingBottom: "0px" }}
          >
            1. Acceptance of Terms
          </Typography>
          {/* <Typography variant="h6" gutterBottom>1.1</Typography> */}
          <Typography paragraph fontFamily="Roboto" fontWeight="400">
            1.1. By accessing or utilizing the NearShopz platform, inclusive of
            its website and mobile applications, you hereby agree to adhere to
            the following Terms and Conditions. Should you disagree with these
            terms, kindly refrain from using our services.
          </Typography>

          <Typography paragraph>
            1.2. These Terms and Conditions establish a legally binding agreement
            between yourself ("User," "you," "your") and NearShopz
            (“NearShopz”), operated by 4Labs Technologies Pvt, Ltd. By utilizing
            our platform, you assert that you are of legal age to enter such a
            contract.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            2. Services
          </Typography>

          <Typography paragraph>
            2.1. NearShopz provides a hyperlocal delivery platform facilitating
            connections between users and delivery providers. The range of
            services offered may encompass order placement, payment processing,
            and delivery of goods.
          </Typography>

          <Typography paragraph>
            2.2. NearShopz reserves the prerogative to amend, suspend, or
            discontinue services, either wholly or partially, without prior
            notice. We shall not be held liable for any resultant losses or
            inconveniences.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            3. User Accounts
          </Typography>

          <Typography paragraph>
            3.1. To access specific features and services on NearShopz, users may
            be mandated to create a user account. It is the user's
            responsibility to maintain the confidentiality of their account
            information.
          </Typography>

          <Typography paragraph>
            3.2. Users are obliged to furnish accurate and current information
            during the registration process. NearShopz bears no responsibility
            for inaccuracies in the information provided.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            4. User Conduct
          </Typography>

          <Typography paragraph>
            4.1. Users undertake to utilize the NearShopz platform and services
            in accordance with all pertinent laws and regulations.
          </Typography>

          <Typography paragraph>
            4.2. Users must refrain from conduct deemed offensive, harmful, or
            infringing on others' rights, including harassment, fraud, and
            unauthorized use of intellectual property.
          </Typography>

          <Typography paragraph>
            4.3. Inappropriate behavior and fraudulent activities may result in
            the suspension or termination of user accounts.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            5. Orders and Deliveries
          </Typography>

          <Typography paragraph>
            5.1. Users can place orders through the NearShopz platform for goods
            and services offered by merchants.
          </Typography>

          <Typography paragraph>
            5.2. By placing an order, users affirm their commitment to provide
            accurate delivery information, comprising the delivery address and
            contact particulars.
          </Typography>

          <Typography paragraph>
            5.3. Users acknowledge that delivery times stipulated on the platform
            are approximate and actual delivery times may deviate based on
            various factors such as traffic and weather conditions.
          </Typography>

          <Typography paragraph>
            5.4. NearShopz and its delivery partners will exert reasonable
            efforts to fulfill orders within the estimated delivery time.
            However, NearShopz shall not be held accountable for delays beyond
            its control.
          </Typography>

          <Typography paragraph>
            5.5. In instances of non-delivery attributable to user-related
            factors (e.g., incorrect address provided), users may incur
            additional fees for redelivery, or the order may be canceled, at
            NearShopz's discretion.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            6. Payments
          </Typography>

          <Typography paragraph>
            6.1. In case of payment disputes, encompassing unauthorized
            transactions, billing errors, or charge discrepancies, users are
            encouraged to promptly contact NearShopz customer support for
            resolution.
          </Typography>

          <Typography paragraph>
            6.2. NearShopz will promptly investigate payment disputes upon
            receipt of a complaint from the user. Users may be required to
            furnish relevant information and documentation to facilitate the
            investigation.
          </Typography>

          <Typography paragraph>
            6.3. If a payment dispute is substantiated, NearShopz will take
            appropriate measures to rectify the error, which may involve issuing
            refunds, adjusting charges, or providing compensation, as deemed
            necessary.
          </Typography>

          <Typography paragraph>
            6.4. Users are obligated to cooperate with NearShopz during the
            investigation of payment disputes and to provide accurate
            information to expedite resolution.
          </Typography>

          <Typography paragraph>
            6.5. NearShopz reserves the right to suspend or terminate user
            accounts or limit access to the platform in cases of suspected
            fraudulent activity or misuse of the payment system.
          </Typography>

          <Typography paragraph>
            6.6. Users acknowledge that NearShopz may be subject to the policies
            and procedures of third-party payment processors, and disputes
            related to payments processed through such entities may be governed
            by their terms and conditions.
          </Typography>

          <Typography paragraph>
            6.7. Users are accountable for payments associated with orders and
            deliveries. Payment processing is facilitated by NearShopz, and
            users agree to furnish accurate payment information.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            7. Payment Terms
          </Typography>

          <Typography paragraph>
            7.1. Users agree to remunerate the purchase price for goods and
            services ordered through the platform, alongside any applicable
            taxes and delivery charges.
          </Typography>

          <Typography paragraph>
            7.2. Payments for orders may be executed through accepted payment
            methods, encompassing credit/debit cards, net banking, digital
            wallets, or cash on delivery (where available).
          </Typography>

          <Typography paragraph>
            7.3. Users recognize that NearShopz may engage third-party payment
            processors to facilitate transactions. By effecting a payment, users
            assent to abide by the terms and conditions of such third-party
            processors.
          </Typography>

          <Typography paragraph>
            7.4. All payments are definitive and non-refundable, except as
            stipulated in our refund policy.
          </Typography>

          <Typography paragraph>
            7.5. In instances of cash on delivery, users must tender the exact
            amount due upon delivery. NearShopz and its delivery partners
            reserve the right to withhold delivery if the exact amount is not
            provided or if payment concerns arise.
          </Typography>

          <Typography variant="h5" sx={{ paddingBottom: "0px" }} gutterBottom>
            8. Intellectual Property
          </Typography>

          <Typography paragraph>
            8.1. The NearShopz website, mobile applications, and affiliated
            content are safeguarded by intellectual property rights, including
            copyrights and trademarks.
          </Typography>

          <Typography paragraph>
            8.2. Users are prohibited from reproducing, distributing, or
            utilizing NearShopz's intellectual property without explicit
            permission.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            9. Privacy
          </Typography>

          <Typography paragraph>
            9.1. User utilization of the NearShopz platform is subject to our
            Privacy Policy, delineating the collection, usage, and safeguarding
            of personal information.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            10.Liability and Disclaimers
          </Typography>

          <Typography paragraph>
            10.1. NearShopz furnishes its services on an "as is" and "as
            available" basis. We do not warrant that our services will be devoid
            of errors, secure, or uninterrupted.
          </Typography>
          {/* <Typography variant="h6" gutterBottom>10.2</Typography> */}
          <Typography paragraph>
            10.2. NearShopz bears no responsibility for the quality or safety of
            goods or services delivered by third-party providers.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            11. Termination
          </Typography>

          <Typography paragraph>
            11.1. NearShopz reserves the right to terminate or suspend user
            accounts for violations of these Terms and Conditions.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            12. Changes to Terms and Conditions
          </Typography>
          {/* <Typography variant="h6" gutterBottom>12.1</Typography> */}
          <Typography paragraph>
            12.1. NearShopz may revise these Terms and Conditions to reflect
            alterations in our practices. Users will be notified of significant
            revisions.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ paddingBottom: "0px" }}>
            13. Governing Law and Jurisdiction
          </Typography>
          {/* <Typography variant="h6" gutterBottom>13.1</Typography> */}
          <Typography paragraph>
            13.1. These Terms and Conditions are governed by and construed in
            accordance with the laws of India. Any disputes arising under these
            terms shall fall within the exclusive jurisdiction of the courts of
            India.
          </Typography>
        </Box>
      </div>
    </>
  );
}

export default Terms;
