import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Business = [

    {
      id: 1,
      name: "Groceries & Essentials",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Groceries+%26+Essentials.png"
    },
    {
      id: 2,
      name: "Restaurants & Bakery",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Restaurant+%26+bakery.jpg"
    },
    {
      id: 3,
      name: "Fish & Meat",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Meat+%26+Fish.jpg"
    },
    {
      id: 4,
      name: "Fruits & Vegetables",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Fruits+%26+Vegetables.png"
    },
    {
      id: 5,
      name: "Medicines",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Medicines.webp"
    },
    {
      id: 6,
      name: "Electronics & Home Appliances",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Electronics+%26+Home+Appliances.jpg"
    },
    {
      id: 7,
      name: "Lifestyle & Fashion",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Lifestyle+%26+Fashion.jpg"
    },
    {
      id: 8,
      name: "Sports & Fitness",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/sports+%26+fitness.jpg"
    },
    {
      id: 9,
      name: "Pet Care",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Pet+Care.jpg"
    },
    {
      id: 10,
      name: "Home decor & Furnishing",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+decor+%26+Furnishing.png"
    },
    {
      id: 11,
      name: "Home Business",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+Business.jpg"
    }
  ];
  

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
 class BusinessTypeDisable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			BusinessType:[]

		};
	}
	



  render(){



    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        this.setState({BusinessType:
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value},
          ()=>{
            this.props.setBusinessType(this.state.BusinessType);
            this.props.setBusinessError(false);
        }
        )
        
      };

    return(
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
       
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          disabled
          value={this.props.businessId}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip"  />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} 
                  label={Business?.find(e => e.id === value).name} 
                // label={value}
                 />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
    {this.props.BusinessType&&this.props.BusinessType.data&&this.props.BusinessType.data.businessTypeListing.map((b)=>
     <MenuItem key={b.id} value={b.id}>
     <Checkbox checked={this.props.businessId.includes(b.id)} />
     <ListItemText primary={b.name} />
   </MenuItem>
    //  <MenuItem value={b.id} style={{ width: '100%' }}>{b.name}</MenuItem>
    )}
        </Select>
        {
            this.props.businessError===true?
            <p style={{color:'red'}}>Please select atleast one business type</p>
            :
            null
        }
      </FormControl>
    </div>
    )
  }
}
  
export default BusinessTypeDisable;