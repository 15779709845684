import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid, Typography, TextField, Button } from "@mui/material";
import UploadForm from "./UploadForm";
import { contentFontSize_22, contentFontSize_16 } from "../../../Typography";
import { validationSchema } from "../../Validation/DocumentValidation";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SIGNUP_FORM4} from '../../../../Redux/Action/NewSignUp/GqlQuery';
import client from "../../../../Configurations/apollo";
import { useLocation } from "react-router-dom";

export default function DocumentUpload() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();

  const nav = useNavigate();

  const location = useLocation()
  useEffect(() => {
    if (location.pathname === '/RegisterForm3') {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
    return () => {
      window.removeEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    localStorage.removeItem("GSTUrl");
    localStorage.removeItem("AadharUrl");
    localStorage.removeItem("LicenceUrl");
    localStorage.removeItem("FssaiUrl")
  }, []);

  const formik = useFormik({
    initialValues: {
      fssaiNo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch({
        type:"SIGNUP_FORM4_REQ"
      })
      client
      .mutate({
        mutation:SIGNUP_FORM4,
        variables:{
          fssaiUrl:localStorage.getItem("FssaiUrl"),
          aadharUrl:localStorage.getItem("AadharUrl"),
          GSTurl:localStorage.getItem("GSTUrl"),
          licenseUrl:localStorage.getItem("LicenceUrl"),
          fssaiNo :values.fssaiNo!==""?values.fssaiNo:null,
        }
      })
      .then((response)=>{
        dispatch({type:"SIGNUP_FORM4_SUCCESS" , payload:response.data});    
        // localStorage.removeItem('retailerid')
        // localStorage.removeItem('token')
        // localStorage.removeItem('isLoggedin')
        // localStorage.removeItem('storeid')
        // localStorage.removeItem('imgUpload')
        // localStorage.removeItem('loginformNumber')
        // window.location.reload();
  
      })
      .catch((error)=>{
        dispatch({type:"SIGNUP_FORM4_SUCCESS",payload:error.message})
      })
      // localStorage.setItem("formdata4", JSON.stringify(values));
      nav("/Subscription");
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  return (
    <>
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ background: "#85BE49", padding: "10px" }}>
          <Typography variant="h4" align="center" gutterBottom color="white">
            Registration
          </Typography>
        </div>
        <div style={{ padding: "10px" }}>
          <Typography
            align="center"
            gutterBottom
            fontSize={contentFontSize_22}
            className="chooseHolidaysStyle"
          >
            Please enter your details to create your account
          </Typography>
        </div>

        <div style={{ paddingLeft: "30px", paddingTop: "30px" }}>
          <Typography variant="h6" gutterBottom sx={{ paddingLeft: "30px" }}>
            Document upload
          </Typography>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={3}
            sx={{ padding: "60px", paddingTop: "0px" }}
          >
            <Grid item xs={12}>
              <Typography
                className="StoreLabelStyles"
                fontSize={contentFontSize_16}
              >
                FSSAI NO <span style={{ color: "#EA4335" }}>*</span>
              </Typography>
              <TextField
                fullWidth
                name="fssaiNo"
                value={formik.values.fssaiNo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.fssaiNo && Boolean(formik.errors.fssaiNo)}
                // helperText={formik.touched.fssaiNo && formik.errors.fssaiNo}
              />
              {formik.touched.fssaiNo &&
                formik.errors.fssaiNo ? (
                  <div style={{color:"red",fontSize:"12px"}}>{formik.errors.fssaiNo}</div>
                ) : null}
            </Grid>
            <UploadForm />
            <Grid
              container
              justifyContent="flex-end"
              sx={{ marginTop: 2, paddingRight: "30px" }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: "20px",background:"#85BE49" }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
}
