import { useState, useLayoutEffect, useEffect } from 'react';
 
const ImageLoader = (imageUrls, interval) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentBackground, setCurrentBackground] = useState(imageUrls[0]);
  const [isLoaded, setIsLoaded] = useState(false);
 
  useLayoutEffect(() => {
    const preloadImage = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(url);
      });
    };
 
    const loadImage = async (url) => {
      await preloadImage(url);
      setIsLoaded(true);
      setCurrentBackground(url);
    };
 
    loadImage(imageUrls[currentIndex]);
  }, [currentIndex, imageUrls]);
 
  useEffect(() => {
    const changeImage = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
    };
 
    const intervalId = setInterval(changeImage, interval);
 
    return () => clearInterval(intervalId);
  }, [imageUrls, interval]);
 
  useEffect(() => {
    if (isLoaded) {
      setIsLoaded(false); 
    }
  }, [isLoaded]);
 
  return currentBackground;
};
 
export default ImageLoader;