import client from "../../../Configurations/apollo";
import { GET_IMAGE_SIGN, SIGNUP_MUTATION,SIGNUP_P,SIGNUP_G,
	DELETE_S3,
	SIGNUP_PG,AADHAR_UPLOAD,GST_UPLOAD, SELLER_REG,SEND_OTP ,SIGNUP_FORM1,SIGNUP_FORM2,SET_STORE_NAME,SET_STORE_DESCRIPTION, SET_STORE_COORDINATES,SET_STORE_LOCATION,
	SIGNUP_FORM3,
	SIGNUP_FORM4
} from "./GqlQuery";
// import { gql } from "@apollo/client";

// const UPLOAD_FILE = gql`
// mutation($file: Upload!,$type:Int!) {
//   signS3(file: $file,type:$type) {
//       ETag
//       Location
//       Key
//       Bucket
//   }
// }
// `;

const charges = [
	{ label: "0-3 Km", distanceValue: 3, value: null },
	{ label: "3-6 Km", distanceValue: 6, value: null },
	{ label: "6-9 Km", distanceValue: 9, value: null },
	{ label: "9-12 Km", distanceValue: 12, value: null },
	{ label: "12-15 Km", distanceValue: 15, value: null },
];
export const handleInputChange = (params) => {
	return (dispatch) => {
		dispatch({
			type: "INPUT_SIGNUP_CHANGE",
			payload: params,
		});
	};
};

export const handleUploadImage = (filedata) => {
	let Imgdata = filedata.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_IMAGE_UPLOAD",
		});
		client
			.mutate({
				mutation: GET_IMAGE_SIGN,
				variables: {
					file: Imgdata,
					
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.setItem('imageUrl',res.data.signS3.Location)
					dispatch({
						type: "IMAGE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "IMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "IMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const handleAlertClose = () => {
	return (dispatch) => {
		dispatch({
			type: "HANDLE_CLOSE",
		});
	};
};

export const submitSignUp = (param,params,params1,params2,params3,params4,params5,params6,params7,params8) => {
	let {
		emailAddress,
		password,
		firstName,
		lastName,
		AlternateContactNumber,
		MobileNumber,
		storeName,
		PANnumber,
		GSTnumber,
		storeDescription,
		storeManagerFirstName,
		storeManagerLastName,
		storeManagerMobile,
		ManagerAlternateContactNumber,
	    bankAccountNumber,
		accountHolderName,
		IFSCcode,
		fssaiNumber,
	} = param;
	let {
		storeCoordinates,
		storeLocation,
		deliveryType,
		paymentSupported,
		deliveryDays,
		holidays,
		minAmount,
		openingTime,
		closingTime,
	}=params[0]
	let storeLogoUrl=localStorage.getItem('imageUrl');
	
	let deliverDay = deliveryDays.map((day) => ({
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	let { deliveryMaxDistance } = params[2];
	
	let charge = params2?(params2.map((char) => {
		return {
			kmRange: `${char.kmRange}`,
			charge:char.value?`${char.value}`:"0",
		};
	}))
	:(charges.map((c)=>{
		
		return{
			
		kmRange: `${c.label}`,
		charge:c.distanceValue<=deliveryMaxDistance?"0":null,
	}}))
	
	return (dispatch) => {
		dispatch({
			type: "SIGNUP_WAITING",
		});
		client
			.mutate({
				mutation: SIGNUP_MUTATION,
				variables: {
					firstName: firstName,
					lastName: lastName,
					mobileNumber: `${MobileNumber}`,
					secondaryNumber: `${AlternateContactNumber}`==="undefined"?null:`${AlternateContactNumber}`,
					emailAddress: `${emailAddress}`.toLowerCase(),
					password: `${password}`,
					PANnumber:`${PANnumber}`,
					GSTnumber:`${GSTnumber}`,
					bankDetails:{
						bankAccountNumber:`${bankAccountNumber}`===''?null:`${bankAccountNumber}`,
						accountHolderName:`${accountHolderName}`===''?null:`${accountHolderName}`,
						IFSCcode:`${IFSCcode}`===''?null:`${IFSCcode}`
					},
					storeCoordinates: storeCoordinates,
					storeLocation: `${storeLocation}`,
					storeName: `${storeName}`,
					storeDescription: `${storeDescription}`,
					storeLogoUrl: `${storeLogoUrl}`,
					storeManagerFirstName: `${storeManagerFirstName}`,
					storeManagerLastName: `${storeManagerLastName}`,
					storeManagerMobile: `${storeManagerMobile}`,
					storeManagerPhone:`${ManagerAlternateContactNumber}`==="undefined"?null:`${ManagerAlternateContactNumber}`,
					deliveryType: deliveryType,
					deliveryMaxDistance: Number(deliveryMaxDistance),
					deliveryCharge: charge,
					paymentSupported: paymentSupported,
					deliveryDays: deliverDay,
					businessTypeId:params8,
					holidays:holidays,
					minAmount:minAmount?Number(minAmount):1,
					openingTime:openingTime,
					closingTime:closingTime,
					fssaiNo :fssaiNumber!==""?fssaiNumber:null,
					fssaiUrl :params4,
					licenseUrl :params3,
					aadharUrl:params1,
					GSTurl:params5,
					subscription:{type:1,amount:1000},
					sameDayDelivery:params6==="false"?false:true,
        			deliveryAfter:params7,
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('imageUrl')
					localStorage.setItem("newUser", true);
					dispatch({
						type: "SIGNUP_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SIGNUP_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SIGNUP_NETWORK_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const submitSignUpP = (param,params,params1,params2,params3,params4,params5,params6,params7,params8) => {
	let {
		emailAddress,
		password,
		firstName,
		lastName,
		AlternateContactNumber,
		MobileNumber,
		storeName,
		GSTnumber,
		storeDescription,
		storeManagerFirstName,
		storeManagerLastName,
		storeManagerMobile,
		ManagerAlternateContactNumber,
		bankAccountNumber,
		accountHolderName,
		IFSCcode,
		fssaiNumber
	} = param;
	let {
		storeCoordinates,
		storeLocation,
		deliveryType,
		paymentSupported,
		deliveryDays,
		minAmount,
		holidays,
		openingTime,
		closingTime
	}=params[0]
	let storeLogoUrl=localStorage.getItem('imageUrl');
	
	let deliverDay = deliveryDays.map((day) => ({
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	let { deliveryMaxDistance } = params[2];
	
	let charge = params2?(params2.map((char) => {
		return {
			kmRange: `${char.kmRange}`,
			charge:char.value?`${char.value}`:"0",
		};
	}))
	:(charges.map((c)=>{
		
		return{
			
		kmRange: `${c.label}`,
		charge:c.distanceValue<=deliveryMaxDistance?"0":null,
	}}))
	
	return (dispatch) => {
		dispatch({
			type: "SIGNUP_WAITING",
		});
		client
			.mutate({
				mutation: SIGNUP_P,
				variables: {
					firstName: firstName,
					lastName: lastName,
					mobileNumber: `${MobileNumber}`,
					secondaryNumber: `${AlternateContactNumber}`==="undefined"?null:`${AlternateContactNumber}`,
					emailAddress: `${emailAddress}`.toLowerCase(),
					password: `${password}`,
					GSTnumber:`${GSTnumber}`===''?"": `${GSTnumber}`,
					bankDetails:{
						bankAccountNumber:`${bankAccountNumber}`===''?null:`${bankAccountNumber}`,
						accountHolderName:`${accountHolderName}`===''?null:`${accountHolderName}`,
						IFSCcode:`${IFSCcode}`===''?null:`${IFSCcode}`
					},
					storeCoordinates: storeCoordinates,
					storeLocation: `${storeLocation}`,
					storeName: `${storeName}`,
					storeDescription: `${storeDescription}`,
					storeLogoUrl: `${storeLogoUrl}`,
					storeManagerFirstName: `${storeManagerFirstName}`,
					storeManagerLastName: `${storeManagerLastName}`,
					storeManagerMobile: `${storeManagerMobile}`,
					storeManagerPhone:`${ManagerAlternateContactNumber}`==="undefined"?null:`${ManagerAlternateContactNumber}`,
					deliveryType: deliveryType,
					deliveryMaxDistance: Number(deliveryMaxDistance),
					deliveryCharge: charge,
					paymentSupported: paymentSupported,
					deliveryDays: deliverDay,
					businessTypeId:params8,
					minAmount:minAmount?Number(minAmount):1,
					holidays:holidays,
					openingTime:openingTime,
		            closingTime:closingTime,
					fssaiNo :fssaiNumber!==""?fssaiNumber:null,
					fssaiUrl :params4,
					licenseUrl :params3,
					aadharUrl:params1,
					GSTurl:params5,
					subscription:{type:1,amount:1000},
					sameDayDelivery:params6==="false"?false:true,
        			deliveryAfter:params7,
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('imageUrl')
					localStorage.setItem("newUser", true);
					dispatch({
						type: "SIGNUP_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SIGNUP_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SIGNUP_NETWORK_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const submitSignUpG = (param,params,params1,params2,params3,params4,params5,params6,params7,params8) => {
	let {
		emailAddress,
		password,
		firstName,
		lastName,
		AlternateContactNumber,
		MobileNumber,
		storeName,
		PANnumber,
		storeDescription,
		storeManagerFirstName,
		storeManagerLastName,
		storeManagerMobile,
		ManagerAlternateContactNumber,
		bankAccountNumber,
		accountHolderName,
		IFSCcode,
		fssaiNumber
	} = param;
	let {
		storeCoordinates,
		storeLocation,
		deliveryType,
		paymentSupported,
		deliveryDays,
		minAmount,
		holidays,
		openingTime,
		closingTime
	}=params[0]
	let storeLogoUrl=localStorage.getItem('imageUrl');
	
	let deliverDay = deliveryDays.map((day) => ({
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	let { deliveryMaxDistance } = params[2];
	
	let charge = params2?(params2.map((char) => {
		return {
			kmRange: `${char.kmRange}`,
			charge:char.value?`${char.value}`:"0",
		};
	}))
	:(charges.map((c)=>{
		
		return{
			
		kmRange: `${c.label}`,
		charge:c.distanceValue<=deliveryMaxDistance?"0":null,
	}}))
	
	return (dispatch) => {
		dispatch({
			type: "SIGNUP_WAITING",
		});
		client
			.mutate({
				mutation: SIGNUP_G,
				variables: {
					firstName: firstName,
					lastName: lastName,
					mobileNumber: `${MobileNumber}`,
					secondaryNumber: `${AlternateContactNumber}`==="undefined"?null:`${AlternateContactNumber}`,
					emailAddress: `${emailAddress}`.toLowerCase(),
					password: `${password}`,
					PANnumber: `${PANnumber}`===''?"":`${PANnumber}`,
					bankDetails:{
						bankAccountNumber:`${bankAccountNumber}`===''?null:`${bankAccountNumber}`,
						accountHolderName:`${accountHolderName}`===''?null:`${accountHolderName}`,
						IFSCcode:`${IFSCcode}`===''?null:`${IFSCcode}`
					},
					storeCoordinates: storeCoordinates,
					storeLocation: `${storeLocation}`,
					storeName: `${storeName}`,
					storeDescription: `${storeDescription}`,
					storeLogoUrl: `${storeLogoUrl}`,
					storeManagerFirstName: `${storeManagerFirstName}`,
					storeManagerLastName: `${storeManagerLastName}`,
					storeManagerMobile: `${storeManagerMobile}`,
					storeManagerPhone:`${ManagerAlternateContactNumber}`==="undefined"?null:`${ManagerAlternateContactNumber}`,
					deliveryType: deliveryType,
					deliveryMaxDistance: Number(deliveryMaxDistance),
					deliveryCharge: charge,
					paymentSupported: paymentSupported,
					deliveryDays: deliverDay,
					businessTypeId:params8,
					minAmount:minAmount?Number(minAmount):1,
					holidays:holidays,
					openingTime:openingTime,
					closingTime:closingTime,
					fssaiNo :fssaiNumber!==""?fssaiNumber:null,
					fssaiUrl :params4,
					licenseUrl :params3,
					aadharUrl:params1,
					GSTurl:params5,
					subscription:{type:1,amount:1000},
					sameDayDelivery:params6==="false"?false:true,
        			deliveryAfter:params7,
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('imageUrl')
					localStorage.setItem("newUser", true);
					dispatch({
						type: "SIGNUP_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SIGNUP_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SIGNUP_NETWORK_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const submitSignUpPG = (param,params,params1,params2,params3,params4,params5,params6,params7,params8) => {
	let {
		emailAddress,
		password,
		firstName,
		lastName,
		AlternateContactNumber,
		MobileNumber,
		storeName,
		storeDescription,
		storeManagerFirstName,
		storeManagerLastName,
		storeManagerMobile,
		ManagerAlternateContactNumber,
		bankAccountNumber,
		accountHolderName,
		IFSCcode,
		fssaiNumber
	} = param;
	let {
		storeCoordinates,
		storeLocation,
		deliveryType,
		paymentSupported,
		deliveryDays,
		minAmount,
		holidays,
		openingTime,
		closingTime
	}=params[0]
	let storeLogoUrl=localStorage.getItem('imageUrl');
	
	let deliverDay = deliveryDays.map((day) => ({
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	let { deliveryMaxDistance } = params[2];
	
	let charge = params2?(params2.map((char) => {
		return {
			kmRange: `${char.kmRange}`,
			charge:char.value?`${char.value}`:"0",
		};
	}))
	:(charges.map((c)=>{
		
		return{
			
		kmRange: `${c.label}`,
		charge:c.distanceValue<=deliveryMaxDistance?"0":null,
	}}))
	
	return (dispatch) => {
		dispatch({
			type: "SIGNUP_WAITING",
		});
		client
			.mutate({
				mutation: SIGNUP_PG,
				variables: {
					firstName: firstName,
					lastName: lastName,
					mobileNumber: `${MobileNumber}`,
					secondaryNumber: `${AlternateContactNumber}`==="undefined"?null:`${AlternateContactNumber}`,
					emailAddress: `${emailAddress}`.toLowerCase(),
					password: `${password}`,
					bankDetails:{
						bankAccountNumber:`${bankAccountNumber}`===''?null:`${bankAccountNumber}`,
						accountHolderName:`${accountHolderName}`===''?null:`${accountHolderName}`,
						IFSCcode:`${IFSCcode}`===''?null:`${IFSCcode}`
					},
					storeCoordinates: storeCoordinates,
					storeLocation: `${storeLocation}`,
					storeName: `${storeName}`,
					storeDescription: `${storeDescription}`,
					storeLogoUrl: `${storeLogoUrl}`,
					storeManagerFirstName: `${storeManagerFirstName}`,
					storeManagerLastName: `${storeManagerLastName}`,
					storeManagerMobile: `${storeManagerMobile}`,
					storeManagerPhone:`${ManagerAlternateContactNumber}`==="undefined"?null:`${ManagerAlternateContactNumber}`,
					deliveryType: deliveryType,
					deliveryMaxDistance: Number(deliveryMaxDistance),
					deliveryCharge: charge,
					paymentSupported: paymentSupported,
					deliveryDays: deliverDay,
					businessTypeId:params8,
					minAmount:minAmount?Number(minAmount):1,
					holidays:holidays,
					openingTime:openingTime,
					closingTime:closingTime,
					fssaiNo :fssaiNumber!==""?fssaiNumber:null,
					fssaiUrl :params4,
					licenseUrl :params3,
					aadharUrl:params1,
					GSTurl:params5,
					subscription:{type:1,amount:1000},
					sameDayDelivery:params6==="false"?false:true,
        			deliveryAfter:params7,
				},
			})
			.then((res) => {
				if (res.data) {
					localStorage.removeItem('imageUrl')
					localStorage.setItem("newUser", true);
					dispatch({
						type: "SIGNUP_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "SIGNUP_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "SIGNUP_NETWORK_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const uploadLogoImage = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_LOGOIMAGE_UPLOAD",
		});
		client
			.mutate({
				// mutation: UPLOAD_FILE,
				// variables: {
				// 	file: Imgdata,
				// 	type:1
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:1,
					size:180,
					filename:`${'logo'}-${Math.floor(Math.random()*(9999-1000+1)+1000)}${new Date()}.jpg`
				},
			})
			.then((res) => { 
				
				if (res.data) {
					
					localStorage.setItem('imageUrl',res.data.imageEditAndUpload.Location)
					
					dispatch({
						type: "LOGOIMAGE_UPLOAD_SUCCESS",
						payload: res.data,
						
					});
				} else {
					
					dispatch({
						type: "LOGOIMAGE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				
				dispatch({
					type: "LOGOIMAGE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};
export const closeSnack = () => {
	return {
		type: "CLOSE_SNACK",
	};
};

export const setOpenTime =(time)=>{
	return (dispatch) => {
	
		dispatch({
			type: "OPENING_TIME",
			payload:{
				time
			}
		});
	}
};
export const setCloseTime =(time)=>{
	return (dispatch) => {
	
		dispatch({
			type: "CLOSING_TIME",
			payload:{
				time
			}
		});
	}
};
export const handleTableChange = (charges) => {
	
return (dispatch) => {
	
	dispatch({
		type: "DELIVERY_CHARGES",
		payload:{
			charges,
		}
	});
}
};

export const UploadAadhar = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_AADHAR_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-aadhar.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "AADHAR_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "AADHAR_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "AADHAR_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const UploadPan = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_PAN_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-pan.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "PAN_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "PAN_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "PAN_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const UploadLicense = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_LICENSE_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-license.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "LICENSE_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "LICENSE_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "LICENSE_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const UploadFSSAI = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_FSSAI_UPLOAD",
		});
		client
			.mutate({
				mutation: AADHAR_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-fssai.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "FSSAI_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "FSSAI_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "FSSAI_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};

export const UploadGst = (params) => {
	let Imgdata = params.files;
	return (dispatch) => {
		dispatch({
			type: "LOADING_GST_UPLOAD",
		});
		client
			.mutate({
				mutation: GST_UPLOAD,
				variables: {
					file: Imgdata,
					type:4,
					size:512,
					filename:`${params.fileName}-gst.jpg`
				},
			})
			.then((res) => { 
				if (res.data) {
					dispatch({
						type: "GST_UPLOAD_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "GST_UPLOAD_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "GST_UPLOAD_FAILURE",
					payload: err.message,
				});
			});
	};
};


export const SubscriptionClick = () => {
	
	return {
		type: "SUBSCRIPTION_CLICK",
		payload: true
	};
};
export const LogoDelete = () => {
	return {
		type: "LogoDelete",
	};
};
export const LogoChange = () => {
	
	return {
		type: "LogoChange",
	};
};



export const Delete_Logo = (params) => {
	return (dispatch) => {
		dispatch({
			type: "LOADING_LOGO_DELETE",
		});
		client
			.mutate({
				mutation: DELETE_S3,
				variables: {
					fileName:params
				},
				
			})
			.then((res) => {
				if (res.data) {
					localStorage.setItem('imageUrl',null)
					dispatch({
						type: "LOGO_DELETE_SUCCESS",
						payload: res.data,
					});
				} else {
					dispatch({
						type: "LOGO_DELETE_FAILURE",
						payload: res.message,
					});
				}
			})
			.catch((err) => {
				dispatch({
					type: "LOGO_DELETE_NFAILURE",
					payload: err.message,
				});
			});
	};

}
export const setDays = (params)=>{
	return {
		type: "Number of days",
		payload: params
	};
}
export const aadharChange= (params) => {
	return {
		type: "aadharChange",
		payload: params
	};
};

export const getSameDay = (params) => {
	return {
		type: "SameDayDelivery",
		payload: params
	};
};


export const aadharDelete= () => {
	return {
		type: "aadharDelete",
	};
};

export const LicenseChange= (params) => {
	return {
		type: "LicenseChange",
		payload: params
	};
};

export const LicenseDelete= () => {
	return {
		type: "LicenseDelete",
	};
};
export const PanChange= (params) => {
	return {
		type: "PanChange",
		payload: params
	};
};

export const PanDelete= () => {
	return {
		type: "PanDelete",
	};
};
export const gstChange= (params) => {
	return {
		type: "GSTChange",
		payload: params
	};
};

export const setImage=() => {
	return {
		type: "Image Change",
		payload: false
	};
};

export const gstDelete= () => {
	return {
		type: "GSTDelete",
	};
};
export const fssaiChange= (params) => {
	return {
		type: "fssaiChange",
		payload: params
	};
};

export const fssaiDelete= () => {
	return {
		type: "fssaiDelete",
	};
};


export const checkBoxSlots = (params) => {
	return {
		type: "SLOT_CHECKBOX",
		payload:params
	};
}

export const setbusinessTypeId = (params) => {
	return {
		type: "SET_BUSINESS_TYPE",
		payload:params
	};
}
export const setbusinessTypeError = (params) => {
	return {
		type: "SET_BUSINESS_TYPE_ERROR",
		payload:params
	};
}

export const sendOTP =(phoneNumber)=>{
	return(dispatch)=>{
		client
		.mutate({
			mutation:SEND_OTP,
			variables: {
				phoneNumber: phoneNumber,
			  },
		})
		.then((response) => {
			
			dispatch({ type: 'SEND_OTP_SUCCESS', payload: response.message});

		  })
		  .catch((error) => {
			dispatch({ type: 'SEND_OTP_FAILURE', payload: error.message });

		  });
	}

}

 export const sellerReg =(params)=>{
	let{emailId,mobileNumber,otp,password,storeName,fcmToken,storeid}=params
	return (dispatch) => {
		dispatch({
			type:"SELLER_REG_REQ"
		});
		client
		 .mutate({
			mutation:SELLER_REG,
			variables: {
				emailId: emailId,
				mobileNumber: mobileNumber,
				otp: parseInt(otp),
				password: password,
				storeName: storeName,
				fcmToken: fcmToken,
			  },
		 })
		 .then((response) => {
			dispatch({ type: 'SELLER_REG_SUCCESS', payload: response.data });
             localStorage.setItem("token",response.data.retailerSignup.token);
			 localStorage.setItem("storeid",response.data.retailerSignup.storeid);
			 localStorage.setItem("storestatus",response.data.retailerSignup.storeStatus);
			 localStorage.setItem("loginformNumber",response.data.retailerSignup.formNumber)
		  })
		  .catch((error) => {
			dispatch({ type: 'SELLER_REG_FAILURE', payload: error.message });
		  });
	}
 }
  
 export const signupForm1 = (params) =>{
	let {firstName,lastName,secondaryNumber,PANnumber,
		GSTnumber,
		storeManagerFirstName,
		storeManagerLastName,
		storeManagerMobile,
		storeManagerPhone,
		bankAccountNumber,accountHolderName,IFSCcode}=params
		const storeid = localStorage.getItem('storeid');

	return(dispatch) =>{
		dispatch({
			type:"SIGNUP_FORM1_REQ"
		})
		client
		.mutate({
			mutation: SIGNUP_FORM1,
			variables:{
				firstName:firstName,lastName:lastName,secondaryNumber:secondaryNumber,PANnumber:PANnumber,
		GSTnumber:GSTnumber,
		storeManagerFirstName:storeManagerFirstName,
		storeManagerLastName:storeManagerLastName,
		storeManagerMobile:storeManagerMobile,
		storeManagerPhone:storeManagerPhone,
		storeid:storeid,
		bankDetails:{
			bankAccountNumber: bankAccountNumber,
  accountHolderName: accountHolderName,
  IFSCcode:IFSCcode,


		}
			},
		})
		.then((response)=>{
			dispatch({type :"SIGNUP_FORM1_SUCCESS", payload: response.data});
		})
		.catch((error)=>{
			dispatch({type: "SIGNUP_FORM1_FAILURE", payload: error.message})
		});
	}

 }

 export const signupForm2 = (params,params1) =>{
	let {storeName,storeDescription,businessTypeId } = params
	let {storeLocation,storeCoordinates}=params1[0]
	let storeLogoUrl=localStorage.getItem('imageUrl');

	return(dispatch) =>{
		dispatch({
			type:"SIGNUP_FORM2_REQ"
		})
		client
		.mutate({
			mutation:SIGNUP_FORM2,
			variables:{
				storeName:storeName,
				storeDescription:storeDescription,
				businessTypeId:businessTypeId,
				storeLocation:`${storeLocation}`,
				storeLogoUrl:`${storeLogoUrl}`,
				storeCoordinates:storeCoordinates

			}
		})
		.then((response)=>{
			dispatch({type:"SIGNUP_FORM2_SUCCESS",payload:response.data});
		})
		.catch((error)=>{
			dispatch({type:"SIGNUP_FORM2_FAILURE", payload: error.message})
		})
	}

 }
 export const setStoreName = (name) => ({
	type: SET_STORE_NAME,
	payload: name,
  });
  
  export const setStoreDescription = (description) => ({
	type: SET_STORE_DESCRIPTION,
	payload: description,
  });
  export const setStoreLocation =(location)=>({
	type:SET_STORE_LOCATION,
	payload:location,
  });
  export const setStoreCoordinates =(coordinates)=>({
	type:SET_STORE_COORDINATES,
	payload:coordinates
  })

  export const signupForm3= (params,params2,params3,params4
  ) => {
	let {
		deliveryType,
		deliveryDays,
		minAmount,
		holidays,
		openingTime,
		closingTime
	}=params2[0]
	let deliverDay = deliveryDays.map((day) => ({
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	let { deliveryMaxDistance } = params2[2];
	let charge = params3?(params3.map((char) => {
		return {
			kmRange: `${char.kmRange}`,
			charge:char.value?`${char.value}`:"0",
		};
	}))
	:(charges.map((c)=>{
		
		return{
			
		kmRange: `${c.label}`,
		charge:c.distanceValue<=deliveryMaxDistance?"0":null,
	}}))
	
	return (dispatch) => {
	  dispatch({
		type: "SIGNUP_FORM3_REQ",
	  });
  
	  client
		.mutate({
		  mutation: SIGNUP_FORM3,
		  variables: {
			deliveryType: deliveryType,
			minAmount: minAmount ? Number(minAmount) : 1,
			openingTime: openingTime,
			closingTime: closingTime,
			holidays: holidays,
			deliveryDays: deliverDay, 
			deliveryMaxDistance: Number(deliveryMaxDistance),
			deliveryCharge: charge,
			sameDayDelivery: params3 === "false" ? false : true,
			deliveryAfter: params4,
		  },
		})
		.then((response) => {
		  dispatch({ type: "SIGNUP_FORM3_SUCCESS", payload: response.data });
		})
		.catch((error) => {
		  dispatch({ type: "SIGNUP_FORM3_FAILURE", payload: error.message });
		});
	};
  };
  
  export const signupForm4= (param={},params,params1,params2,params3)=>{
	let {fssaiNumber}=param
	return(dispatch) =>{
		dispatch({
			type:"SIGNUP_FORM4_REQ"
		})
		client
		.mutate({
			mutation:SIGNUP_FORM4,
			variables:{
				aadharUrl:params,
				GSTurl:params3,
				licenseUrl:params1,
				fssaiNo :fssaiNumber!==""?fssaiNumber:null,
				fssaiUrl:params2
			}
		})
		.then((response)=>{
			dispatch({type:"SIGNUP_FORM4_SUCCESS" , payload:response.data});    
			// localStorage.removeItem('retailerid')
			// localStorage.removeItem('token')
			// localStorage.removeItem('isLoggedin')
			// localStorage.removeItem('storeid')
			// localStorage.removeItem('imgUpload')
			// localStorage.removeItem('loginformNumber')
			// window.location.reload();

		})
		.catch((error)=>{
			dispatch({type:"SIGNUP_FORM4_SUCCESS",payload:error.message})
		})
	}
  }