import React, { createRef, useState } from "react";
import { fields } from "./helper";
import { Snackbar } from "@mui/material";
import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LOGO } from "../../utils/image";
// import { Modal } from 'antd';
// import { ExclamationCircleOutlined } from '@ant-design/icons';
import Sellerreg from "../SellerRegistration/Sellerreg";
import Card from "../Commoncomponents/card/index.js";
import { Checkbox } from "../MUIComponents/index.js";
import { Grid } from "../MUIComponents/index.js";
import ShopAssistantLogin from "./ShopAssistantLogin.js";
import "./index.css";
import EyeClose from "../../utils/images/EyeClose.svg";
import EyeOpen from "../../utils/images/EyeOpen.svg";
import { contentFontSize_18 } from "../Typography/index.js";
import { Typography } from "../MUIComponents/index";

const validationSchema = Yup.object().shape({
  userName: Yup.string()
    .email("Email must be a valid email")
    .matches(/^\S*$/, "Space is not allowed")
    .required("Email address is required"),
});
const LoginvalidationSchema = Yup.object().shape({
  userName: Yup.string()
    .matches(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      "Email Address must be valid"
    )

    .matches(/^\S*$/, "Space is not allowed")
    .required("Email address is required"),
  password: Yup.string().required("Password is required"),
  //eslint-disable-next-line
  // .min(8,"Password must be atleast 8 characters long")
  // .matches( /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,"Password must contain one number uppercase and lowercase letters and special character")
  // .matches(/^\S*$/, "Space is not allowed")
  // .trim("Space not allowed"),
});

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

// const { confirm } = Modal;

const LoginForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [shopAssistantLogin, setshopAssistantLogin] = useState(false);
  const [loginForm, setLoginForm] = useState(true);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };

  const OpenShopAssistantSignIn = () => {
    setLoginForm(false);
    setshopAssistantLogin((prev) => !prev);
  };
  const wrapper = createRef();

  const [open, setOpen] = React.useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("sm");
  const [stayLogin, setStayLogin] = useState(false);

  const handleStayLoggedInChange = (event) => {
    setStayLogin(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const toggleForm = () => {
    setShowRegisterForm((prev) => !prev);
  };

  const loginformik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    onSubmit: (values) => {
      props.signInClick(values,stayLogin);
    },
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.resetpassword(values.userName);
      setOpen(false);
    },
  });

  return (
    <Grid id="ethlogin">
      <div>
        {showRegisterForm ? (
          <Sellerreg toggleForm={toggleForm} {...props} />
        ) : (
         

          <>
            <Card
              boxShadow={"none"}
              padding={"20px 50px 20px 50px"}
              background="rgba(255, 255, 255, 0.9)"
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={LOGO}
                    width="210px"
                    height="70px"
                    style={{
                      objectFit: "contain",
                    }}
                    alt="shop and deliver.png"
                  />
                </div>

                {shopAssistantLogin && (
                  <ShopAssistantLogin
                    {...props}
                    loginForm={loginForm}
                    setLoginForm={setLoginForm}
                    setshopAssistantLogin={setshopAssistantLogin}
                  />
                )}
                {loginForm && (
                  <div>
                    <div className="signInText-heading">
                      <div className="pbtm">
                        <h6 className="signin-heading">{"Sign In"}</h6>
                      </div>
                    </div>
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#313131",
                        fontWeight: "400",
                        fontFamily: "Roboto",
                      }}
                      fontSize={contentFontSize_18}
                    >
                      Welcome back, Seller!Please enter your details
                    </Typography>
                    <form onSubmit={loginformik.handleSubmit}>
                      {fields.map((field) => {
                        return (
                          <div className="pbtm">
                            <label className="textfield-label">
                              {field.label}
                            </label>
                            <TextField
                              id={field.name}
                              value={
                                field.name === "userName"
                                  ? loginformik.values.userName
                                  : loginformik.values.password
                              }
                              onChange={loginformik.handleChange}
                              error={
                                loginformik.touched[`${field.name}`] &&
                                Boolean(loginformik.errors[`${field.name}`])
                              }
                              helperText={
                                loginformik.touched[`${field.name}`] &&
                                loginformik.errors[`${field.name}`]
                              }
                              InputProps={{
                                endAdornment:
                                  field.type === "password" ? (
                                    <InputAdornment position="end">
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={handleClick}
                                      >
                                        {showPassword ? (
                                          <img
                                            src={EyeOpen}
                                            alt="Eye Open image"
                                            width={"20px"}
                                          />
                                        ) : (
                                          <img
                                            src={EyeClose}
                                            alt="Eye Close image"
                                            width={"20px"}
                                          />
                                        )}
                                      </div>
                                    </InputAdornment>
                                  ) : null,
                              }}
                              sx={{
                                width: "100%",

                                "& .MuiInputBase-input": {
                                  backgroundColor: "none",
                                },
                              }}
                              // className="form-fields"
                              type={
                                (field.name === "password" && showPassword) ||
                                field.name === "userName"
                                  ? "text"
                                  : "password"
                              }
                              name={field.name}
                              placeholder={field.placeholder}
                              // label={field.label}
                              // variant="standard"
                              fullWidth
                            />
                          </div>
                        );
                      })}

                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="pbtm"
                      >
                        <div className="flex">
                          <Checkbox
                            onChange={handleStayLoggedInChange}
                            checked={stayLogin}
                          />{" "}
                          <p>Stay Logged In</p>
                        </div>
                        <div>
                          <button
                            type="button"
                            style={{
                              cursor: "pointer",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "rgba(43, 110, 246, 1)",
                              background: "none",
                              borderWidth: "initial",
                              borderStyle: "none",
                              borderColor: "initial",
                              borderImage: "initial",
                              marginTop: "1px",
                              fontFamily: "Varela Round, sans-serif",
                            }}
                            onClick={handleClickOpen}
                          >
                            Forgot Password ?
                          </button>
                        </div>
                      </div>

                      <div style={{ width: "100%" }}>
                        <button
                          id="login-btn"
                          className="signin-btn"
                          type="submit"
                          width="100%"
                        >
                          Log In
                        </button>
                      </div>
                    </form>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      onClick={OpenShopAssistantSignIn}
                    >
                      <p className="shop-assistant-login-text">
                        Login as shop assistant
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "rgba(49, 49, 49, 1)",
                          fontSize: "14px",
                          fontWeight: 400,
                        }}
                      >
                        Don't you have an account?&nbsp;
                      </span>
                      <button
                        type="button"
                        style={{
                          cursor: "pointer",
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "rgba(43, 110, 246, 1)",
                          background: "none",
                          borderWidth: "initial",
                          borderStyle: "none",
                          borderColor: "initial",
                          borderImage: "initial",
                          marginTop: "1px",
                          fontFamily: "Varela Round, sans-serif",
                        }}
                        onClick={() => toggleForm()}
                      >
                        Register Now
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Card>

            {props.isLoggedIn === false &&
            props.isNetError === "Invalid Username or Password" ? (
              <Snackbar
                style={{ width: "fit-content" }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={props.isNetError}
                autoHideDuration={3000}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                onClose={() => props.closeSnackBars()}
              >
                <Alert
                  severity={"error"}
                  onClose={() => props.closeSnackBars()}
                >
                  <AlertTitle>
                    {props.isNetError
                      ? `${props.incorrectMessage[0].message}`
                      : null}
                  </AlertTitle>
                </Alert>
              </Snackbar>
            ) : (
              props.isLoggedIn === false &&
              props.isNetError !== "Invalid Username or Password" && (
                <Snackbar
                  style={{
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    left: "50%",
                  }}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  open={props.isNetError}
                  autoHideDuration={8000}
                  ContentProps={{
                    "aria-describedby": "message-id",
                  }}
                  onClose={() => props.closeSnackBars()}
                >
                  <Alert
                    severity={"error"}
                    onClose={() => props.closeSnackBars()}
                  >
                    <AlertTitle>
                      {props.isNetError
                        ? `${props.incorrectMessage[0].message}`
                        : null}
                    </AlertTitle>
                  </Alert>
                </Snackbar>
              )
            )}

            {(props.isLoggedIn === false && props.isError) ||
            props.isPasswordset ||
            props.isPasswordsetFailed ||
            props.isPasswordsetnetwork ? (
              <Snackbar
                style={{ width: "fit-content", left: "50%" }}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                open={
                  props.isError ||
                  props.isPasswordset ||
                  props.isPasswordsetFailed ||
                  props.isPasswordsetnetwork
                }
                autoHideDuration={3000}
                ContentProps={{
                  "aria-describedby": "message-id",
                }}
                onClose={() => props.closeSnackBars()}
              >
                <Alert>
                  <AlertTitle>
                    {props.isError
                      ? `${"Network error"}`
                      : props.isPasswordset
                      ? `${"New Password has been sent to your email id.Please check your spam folder if not found on inbox."}`
                      : props.isPasswordsetFailed
                      ? `${props.incorrectPassMessage}`
                      : props.isPasswordsetnetwork
                      ? `${"Network Error"}`
                      : null}
                  </AlertTitle>
                </Alert>
              </Snackbar>
            ) : null}

            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Reset password</DialogTitle>
              <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id={"userName"}
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.userName && Boolean(formik.errors.userName)
                    }
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                    className="login_field_input"
                    type={"email"}
                    name={"userName"}
                    label={"Registered email address"}
                    variant="outlined"
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <button onClick={handleClose} id="Cancel-btn" type="button">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    id="Submit-btn"
                    variant="success"
                    width="100%"
                  >
                    Submit
                  </button>
                </DialogActions>
              </form>
            </Dialog>
          </>
        )}
      </div>
    </Grid>
  );
};

export default LoginForm;
