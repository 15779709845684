// import React,{useState} from "react";
// import { Row, Col } from "react-bootstrap";
// import TextField from "@material-ui/core/TextField";
// // import Grid from "@material-ui/core/Grid";
// import Visibility from '@material-ui/icons/Visibility';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { Container } from "@mui/material";

// import { ThemeProvider ,createMuiTheme} from '@material-ui/core/styles';
// import { green } from "@material-ui/core/colors";
// import './index.css';

// const validationSchema = Yup.object().shape({
// 	password:Yup.string()
// 	// .trim('space not allowed')
//    .test('no-leading-space', 'Password should not start with space', value => {
//      if (value) {
//        return !value.startsWith(' ');
//      }
//      return true;
//    }).matches(/^\S*$/,'Space is not allowed')
//     .min(8, 'Password is too short - should be 8 characters minimum')
//     //eslint-disable-next-line
//     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,'Password must contain one number uppercase and lowercase letters and special character')
// 	  .required('Password is required'),
// 	  newPassword:Yup.string()
// 	  // .trim('space not allowed')
//     .test('no-leading-space', 'Password should not start with space', value => {
//       if (value) {
//         return !value.startsWith(' ');
//       }
//       return true;
//     })
//     .matches(/^\S*$/,'Space is not allowed')
//     .min(8, 'Password is too short - should be 8 characters minimum')
//     //eslint-disable-next-line
//     .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,'Password must contain one number uppercase and lowercase letters and special character')
//   .required('Password is required')
// ,
//   confirmNewPassword:Yup.string()
//   // .trim('space not allowed')
//   .test('no-leading-space', 'Password should not start with space', value => {
//     if (value) {
//       return !value.startsWith(' ');
//     }
//     return true;
//   })
//   .matches(/^\S*$/,'Space is not allowed')
//   .min(8, 'Password is too short - should be 8 characters minimum')
//   //eslint-disable-next-line
//   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,'Password must contain one number uppercase and lowercase letters and special character')
//   .required('Password confirmation is required!')
//   .when("newPassword", {
// 	is: (val) => (val && val.length > 0 ? true : false),
// 	then: Yup.string().oneOf(
// 	  [Yup.ref("newPassword")],
// 	  "Both password need to be the same",
// 	  ),
// 	})
//   });
  

  
// const ResetPassword = (props) => {
// 	document.title ="Seller Portal | Change Password"; 
// 	const  theme = createMuiTheme({
// 		palette: {
// 		  primary: green,
// 		},
// 		overrides: {
// 		  MuiInputBase: {
// 			input: {
// 			  '&:-webkit-autofill': {
// 				transitionDelay: '9999s',
// 				transitionProperty: 'background-color, color',
// 			  },
// 			},
// 		  },
// 		},
// 	})
// 	const [showPassword, setShowPassword] = useState(false);
// 	const [showPassword1, setShowPassword1] = useState(false);
// 	const [showPassword2, setShowPassword2] = useState(false);

// 	const handleClick = () => {
// 	  setShowPassword(prev => !prev);
// 	}
// 	const handleClick1 = () => {
// 		setShowPassword1(prev => !prev);
// 	  }
// 	  const handleClick2 = () => {
// 		setShowPassword2(prev => !prev);
// 	  }
// 	let { id } = props.retailerDetails&&props.retailerDetails.data.retailerdetails;
// 	const formik = useFormik({
// 		initialValues: {
// 		  password: "",
// 		  newPassword: "",
// 		  confirmNewPassword: "",
// 		},
// 		validationSchema: validationSchema,
    
//     onReset: (values) => {
//       formik.setValues({
//       password: "",
// 		  newPassword: "",
// 		  confirmNewPassword: "",
//       });
//     },
// 		onSubmit: (values, { resetForm }) => {
//       if(values.password===values.newPassword)
//       {
//         formik.setFieldError('newPassword','new password must be different from old password')
//         formik.setFieldError('confirmNewPassword','new password must be different from old password')
//       }
//       else{
// 		  props.resetPassword({
// 			retailerid: id,
// 			password: values.password,
// 			newPassword: values.newPassword,
// 		});
//     setTimeout(() => {
//       resetForm();
//     }, 1000);
//   }
// 		},
 
// 	  });

// 	return (
//     <Container
//       maxWidth="sm"
//       style={{
//         backgroundColor: "white",
//         border: "1px solid rgba(0, 0, 0, 0.16)",
//         borderRadius: "10px",
//         Top: "40px",
//         paddingTop: "40px",
//         paddingBottom: "40px",
       
//       }}
//     >
//       <div style={{ 
//         display:'flex', 
//         justifyContent:'center',
//         alignItems:'center'}}>
//         <h5 style={{ fontWeight: 800 }}>Change password</h5>
//         </div>
//         <form onSubmit={formik.handleSubmit} style={{ width: "100%",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
//           <ThemeProvider theme={theme}>
//               <Row style={{ paddingTop: 20 ,width:'100%',display:'flex',justifyContent:'center', alignItems:'center'}}>
//                 <Col md={12} xs={12} style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
//                 <TextField         
//                   InputProps={{
//                     style:{width:'100%'},
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <div
//                           style={{ cursor: "pointer" }}
//                           onClick={handleClick}
//                         >
//                           {showPassword ? (
//                             <Visibility
//                               style={{ cursor: "pointer", fontSize: "medium" }}
//                             />
//                           ) : (
//                             <VisibilityOff
//                               style={{ cursor: "pointer", fontSize: "medium" }}
//                             />
//                           )}
//                         </div>
//                       </InputAdornment>
//                     ),
//                   }}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   id="outlined-read-only-password"
//                   label="Enter current password"
//                   type={showPassword ? "text" : "password"}
//                   name="password"
//                   value={formik.values.password}
//                   onChange={formik.handleChange}
//                   error={
//                     formik.touched.password && Boolean(formik.errors.password)
//                   }
//                   helperText={formik.touched.password && formik.errors.password}
//                   variant="outlined"
//                   fullWidth
//                 />
//                </Col>
//               </Row>
//               <Row style={{ width:'100%',display:'flex',justifyContent:'center', alignItems:'center'}}>
//                 <Col md={6} xs={12} style={{ paddingTop: 30 ,paddingBottom:30}}>
//                   <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                     id="outlined-read-only-newPassword"
//                     label="Enter new password"
//                     type={showPassword1 ? "text" : "password"}
//                     name="newPassword"
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <div
//                             style={{ cursor: "pointer" }}
//                             onClick={handleClick1}
//                           >
//                             {showPassword1 ? (
//                               <Visibility
//                                 style={{
//                                   cursor: "pointer",
//                                   fontSize: "medium",
//                                 }}
//                               />
//                             ) : (
//                               <VisibilityOff
//                                 style={{
//                                   cursor: "pointer",
//                                   fontSize: "medium",
//                                 }}
//                               />
//                             )}
//                           </div>
//                         </InputAdornment>
//                       ),
//                     }}
//                     value={formik.values.newPassword}
//                     error={
//                       formik.touched.newPassword &&
//                       Boolean(formik.errors.newPassword)||
//                       ((formik.touched.confirmNewPassword &&
//                         formik.errors.confirmNewPassword==="Both password need to be the same")?true:false)
//                     }
//                     helperText={
//                       (formik.touched.newPassword && formik.errors.newPassword)||((formik.touched.confirmNewPassword &&
//                         formik.errors.confirmNewPassword==="Both password need to be the same")?formik.errors.confirmNewPassword:null)
//                     }
//                     onChange={formik.handleChange}
//                     variant="outlined"
//                     fullWidth
//                   />
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingTop: 30 ,paddingBottom:30}}>
//                   <TextField
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                     id="outlined-read-only-confirmPassword"
//                     type={showPassword2 ? "text" : "password"}
//                     name="confirmNewPassword"
//                     label="Retype new password"
//                     InputProps={{
//                       endAdornment: (
//                         <InputAdornment position="end">
//                           <div
//                             style={{ cursor: "pointer" }}
//                             onClick={handleClick2}
//                           >
//                             {showPassword2 ? (
//                               <Visibility
//                                 style={{
//                                   cursor: "pointer",
//                                   fontSize: "medium",
//                                 }}
//                               />
//                             ) : (
//                               <VisibilityOff
//                                 style={{
//                                   cursor: "pointer",
//                                   fontSize: "medium",
//                                 }}
//                               />
//                             )}
//                           </div>
//                         </InputAdornment>
//                       ),
//                     }}
//                     value={formik.values.confirmNewPassword}
//                     error={
//                       formik.touched.confirmNewPassword &&
//                       Boolean(formik.errors.confirmNewPassword)
//                     }
//                     helperText={
//                       formik.touched.confirmNewPassword &&
//                       formik.errors.confirmNewPassword
//                     }
//                     onChange={formik.handleChange}
//                     variant="outlined"
//                     fullWidth
//                   />
//                 </Col>
//               </Row>
//               <Row style={{width:'100%',justifyContent:'center',alignItems:'center',textAlign:'center',display:'flex'}}>
//                 <button
//                   type="submit"
//                   style={{
//                     justifyContent: "center",
//                     color: "rgb(255, 255, 255)",
//                     backgroundColor:'rgb(137, 199, 74, 1)',
//                     boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//                     width:'auto',
//                     float: "right",
//                     height: "37px",
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     borderRadius: "5px",
//                     borderWidth: "1px",
//                     borderStyle: " solid",
//                     borderColor: "transparent",
//                     borderImage: "initial",
//                     overflow: "hidden",
//                     outline: "none",
//                     minHeight: " 14px",
//                     fontFamily: "Varela Round, sans-serif",
//                   }}
//                   id="cancel-button"
//                   variant="success"
//                 >
//                   Change password
//                 </button>
//             </Row>
//           </ThemeProvider>
//         </form>
     
//     </Container>
//   );
// };
// export default ResetPassword;






//this is the code by berlin for change password page

import React from 'react'
import Card from '../Commoncomponents/card/index';
import {Divider} from '../MUIComponents/index';
import CustomTextField from '../Commoncomponents/input/index';
import Button from '../Commoncomponents/button/index';

export default function index() {
  return (
    <div div style={{background:"#faf5f5"}}>
    <div style={{display:'flex',alignItems:'center',justifyContent:"center", height: "70vh",}}>
          <Card height="400px" width="455px" padding="20px" boxShadow="none">

            <div style={{textAlign:"center",fontWeight:"600",fontSize:"20px",padding:"10px",boxSizing:"border-box"}}>
              Change your password
            </div>
            <div style={{paddingBottom:"10px"}}>
            <Divider/>
            </div>
            <div style={{display:"flex",flexDirection:"column",gap:"20px"}}>

              <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                
                <div>Password</div>
                <div><CustomTextField type="password" placeholder="Enter current password"  label='' variant="outlined" border='1px solid black' borderRadius='10px' size='Normal'/></div>
              </div>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                
                <div>New Password</div>
                <div><CustomTextField type="password" placeholder="Enter new password" label='' variant="outlined" border='1px solid black' borderRadius='10px' size='Normal'/></div>
              </div>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                
                <div>Reset Password</div>
                <div><CustomTextField type="password" placeholder="Confirm new password" label='' variant="outlined" border='1px solid black' borderRadius='10px' size='Normal'/></div>
              </div>

              <div style={{textAlign:"center"}}>
                <Button width="100%" padding="10px">Set Password</Button>
              </div>
            </div>
            

          </Card>

    </div>
    </div>
  )
}
