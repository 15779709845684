// import React from "react";
// import Grid from '@mui/material/Grid';
// import LaptopScreen from "./LaptopScreen";
// import MobileScreen from "./MobileScreen";

// const HomePage = (props) => {

//   return (

//     // <div style={{backgroundColor:'white'}}>
//     //   <Responsive displayIn={IdResponsiveRenderOnlyIn.Laptop}>
//     //     <LaptopScreen {...props}/>
//     //   </Responsive>
//     //   <Responsive displayIn={[IdResponsiveRenderOnlyIn.Tablet]}>
//     //     <MobileScreen {...props}/>
//     //   </Responsive>
//     //   <Responsive displayIn={[IdResponsiveRenderOnlyIn.Mobile]}>
//     //     <MobileScreen {...props}/>
//     //   </Responsive>
//     // </div>

//     <Grid container style={{backgroundColor:"white"}}>
//         <Grid
//         item
//         // xs={12} sm={8} md={6} lg={4}
//         >
//           <LaptopScreen {...props}/>
//         </Grid>
//         <Grid
//         item
//         // xs={12} sm={8} md={6} lg={4}
//         >
//           <MobileScreen/>
//         </Grid> */}

//     </Grid>
//   );
// };

// export default HomePage;

import React from "react";
import Grid from "@mui/material/Grid";
import LaptopScreen from "./LaptopScreen";
import MobileScreen from "./MobileScreen";
import { useMediaQuery, useTheme } from "@mui/material";

const HomePage = (props) => {
  const desktop = useMediaQuery(useTheme().breakpoints.up("sm"));
  return (
    // <div style={{backgroundColor:'white'}}>
    //   <Responsive displayIn={IdResponsiveRenderOnlyIn.Laptop}>
    //     <LaptopScreen {...props}/>
    //   </Responsive>
    //   <Responsive displayIn={[IdResponsiveRenderOnlyIn.Tablet]}>
    //     <MobileScreen {...props}/>
    //   </Responsive>
    //   <Responsive displayIn={[IdResponsiveRenderOnlyIn.Mobile]}>
    //     <MobileScreen {...props}/>
    //   </Responsive>
    // </div>

    <Grid container style={{ backgroundColor: "white" }}>
      {desktop ? (
        <Grid
          item
          // xs={12} sm={8} md={6} lg={4}
        >
          <LaptopScreen {...props} />
        </Grid>
      ) : (
        <Grid
          item
          // xs={12} sm={8} md={6} lg={4}
        >
          <MobileScreen {...props}/>
        </Grid>
      )}
    </Grid>
  );
};

export default HomePage;
