import {React,useEffect,useState }from "react";
import "./LandingHoverimg.css"; // Import the CSS file

import Landingimage1 from "../../utils/images/Landingimage1.jpeg";
import Landingimage2 from "../../utils/images/Landingimage2.jpeg";
import Landingimage3 from "../../utils/images/Landingimage3.jpeg";
import Landingimage4 from "../../utils/images/Landingimage4.jpeg";
import Landingimage5 from "../../utils/images/Landingimage5.jpeg";
import Landingimage6 from "../../utils/images/Landingimage6.jpeg";
import Landingimage7 from "../../utils/images/Landingimage7.jpeg";
import { Typography, useMediaQuery, useTheme } from "../MUIComponents/index";
import {
  HeaderNavigationFontSize_22,
  contentFontSize_18,
  ButtonFontSize_18,
  HeaderfontSize_30,
} from "../Typography";
import Back from "../../utils/Icon/Backtotop.svg";

const LandingHoveringimg = () => {

  const scrollToTop = () => {
    const scrollDuration = 1000; 
    const scrollStep = -window.scrollY / (scrollDuration / 15);
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };
  const [color, setColor] = useState('#13801C');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setColor(prevColor => (prevColor === '#13801C' ? 'black' : '#13801C'));
    }, 2000);

    return () => clearInterval(intervalId);
  },[]);

  return (
    <>
    <div style={{padding:"20px"}}>
        <div>
            <Typography fontSize={HeaderfontSize_30} className="header" style={{color:color}}>Who can Register? </Typography>
            <Typography fontSize={HeaderfontSize_30} className="header"style={{color:color}}>Grow Your Business with NearShopz - Open to All Businesses!</Typography>
        </div>
        
      <div className="scroll-container " style={{marginBottom:"50px",paddingTop:"70px"}}>
        
     <div className="scrollinside">
            <img src={Landingimage1} alt="firstimg" className="imagegray" width="302px" height="550px" style={{borderRadius:"10px"}}/>
            <img src={Landingimage2} alt="firstimg" className="imagegray" width="302px" height="550px" style={{borderRadius:"10px"}}/>
            <img src={Landingimage3} alt="firstimg" className="imagegray" width="302px" height="550px" style={{borderRadius:"10px"}}/>
            <img src={Landingimage4} alt="firstimg" className="imagegray" width="302px" height="550px" style={{borderRadius:"10px"}}/>
            <img src={Landingimage5} alt="firstimg" className="imagegray" width="302px" height="550px" style={{borderRadius:"10px"}}/>
            <img src={Landingimage6} alt="firstimg" className="imagegray" width="302px" height="550px" style={{borderRadius:"10px"}}/>
            <img src={Landingimage7} alt="firstimg" className="imagegray" width="302px" height="550px" style={{borderRadius:"10px"}}/>
            </div>
          </div>
      </div>
      <div style={{display:"flex",justifyContent:"flex-end",paddingRight:"28px",paddingBottom:"10px",gap:"10px",cursor:"pointer",width:"96%"}} onClick={scrollToTop}>
        <div>
        <Typography fontSize={HeaderNavigationFontSize_22} className="backtotop">Back to top</Typography></div>
        <div>
        <img src={Back}/></div>
      </div>
    </>
  );
};

export default LandingHoveringimg;
