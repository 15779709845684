import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';
import { no_Orders } from "../../utils/image";
import { Container } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      isMobile: window.innerWidth < 768
    };
  }

  updateIsMobile = () => {
    this.setState({
      isMobile: window.innerWidth < 768
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateIsMobile);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateIsMobile);
    this.props.criticalOrders();
  }

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  getDetails = (
    id,
    orderNumber,
    paymentStatus,
    deliveryDate,
    deliveryTime
  ) => {
    this.props.history.push({
      pathname: "/home/Criticalorders/details",
      state: {
        orderId: id.toString(),
        orderNumber: orderNumber.toString(),
        paymentStatus: paymentStatus,
        deliveryDate: deliveryDate,
        deliveryTime: deliveryTime,
      },
    });
  };

  render() {
    document.title = "Seller Portal | Critical Orders";
    return (
      this.state.isMobile ?
        <div
          style={{
            width: '92%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}>
          {this.props.ActiveOrder &&
            this.props.ActiveOrder.data &&
            this.props.ActiveOrder.data.criticalOrders.length === 0 ? (
              <img
                style={{
                  width: '100%',
                  height: 'auto'
                }}
                src={no_Orders}
                alt="noresult"></img>
            ) : (
              this.props.ActiveOrder &&
              this.props.ActiveOrder.data &&
              this.props.ActiveOrder.data.criticalOrders &&
              this.props.ActiveOrder.data.criticalOrders[0].orderid && (
                <Grid item xs={8} md={12}>
                  <TableContainer
                    style={{
                      marginTop: 20,
                      width: '100%'
                    }}
                    component={Paper}
                  >
                    <Table>
                      <TableHead
                        style={{
                          backgroundColor: "red",
                          color: "rgb(83,170,73)"
                        }}
                      >
                        <TableRow>
                          <TableCell>Order #</TableCell>
                          <TableCell>Last updated on</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Delivery time</TableCell>
                          <TableCell>Customer name</TableCell>
                          <TableCell>Mobile number</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.ActiveOrder.data.criticalOrders.map((row, index) =>
                          <TableRow
                            className="tableRow"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.getDetails(
                                row.orderid.id,
                                row.orderid.orderNumber,
                                row.orderid.paymentStatus,
                                row.orderid.deliveryDate,
                                row.orderid.deliveryTime
                              );
                            }}
                            key={index}
                          >
                            <TableCell component="th" scope="row">
                              {row.orderid.orderNumber}
                            </TableCell>
                            <TableCell>
                              {row.orderid.stats && row.orderid.stats[0].created}
                            </TableCell>
                            <TableCell>
                              {row.lastStatus && row.lastStatus}
                            </TableCell>
                            <TableCell>
                              {row.orderid.deliveryDate && row.orderid.deliveryDate + " "}
                              {row.orderid.deliveryTime && row.orderid.deliveryTime}
                            </TableCell>
                            <TableCell>
                              {row.orderid.userid.firstName && row.orderid.userid.firstName}
                            </TableCell>
                            <TableCell>
                              {row.orderid.userid.phoneNumber && row.orderid.userid.phoneNumber}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )
            )}
        </div>
        :
        <Container
          maxWidth='md'
          style={{
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            borderRadius: "10px",
            Top: "40px",
            padding: "30px"
          }}>
          <div>
            {this.props.ActiveOrder &&
              this.props.ActiveOrder.data &&
              this.props.ActiveOrder.data.criticalOrders.length === 0 ? (
                <img style={{
                  width: '700px',
                }} src={no_Orders} alt="noresult"></img>
              ) : (
                this.props.ActiveOrder &&
                this.props.ActiveOrder.data &&
                this.props.ActiveOrder.data.criticalOrders &&
                this.props.ActiveOrder.data.criticalOrders[0].orderid && (
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      style={{
                        marginTop: 20,
                      }}
                      component={Paper}
                    >
                      <Table >
                        <TableHead
                          style={{ backgroundColor: "red", color: "rgb(83,170,73)" }}
                        >
                          <TableRow>
                            <TableCell>Order #</TableCell>
                            <TableCell>Last updated on</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Delivery time</TableCell>
                            <TableCell>Customer name</TableCell>
                            <TableCell>Mobile number</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.ActiveOrder.data.criticalOrders.map((row, index) =>
                            <TableRow
                              className="tableRow"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.getDetails(
                                  row.orderid.id,
                                  row.orderid.orderNumber,
                                  row.orderid.paymentStatus,
                                  row.orderid.deliveryDate,
                                  row.orderid.deliveryTime
                                );
                              }}
                              key={index}
                            >
                              <TableCell component="th" scope="row">
                                {row.orderid.orderNumber}
                              </TableCell>
                              <TableCell>
                                {row.orderid.stats && row.orderid.stats[0].created}
                              </TableCell>
                              <TableCell>
                                {row.lastStatus && row.lastStatus}
                              </TableCell>
                              <TableCell>
                                {row.orderid.deliveryDate && row.orderid.deliveryDate + " "}
                                {row.orderid.deliveryTime && row.orderid.deliveryTime}
                              </TableCell>
                              <TableCell>
                                {row.orderid.userid.firstName && row.orderid.userid.firstName}
                              </TableCell>
                              <TableCell>
                                {row.orderid.userid.phoneNumber && row.orderid.userid.phoneNumber}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )
              )}
          </div>
        </Container>
    );
  }
}

export default index;
