import React from "react";
import "./index.css";

const Marker = () => {
	return (
		<div>
			<div
				className="pin bounce"
				style={{ backgroundColor: "red", cursor: "pointer" }}
				title="Marker"
			/>
			<div className="pulse" />
		</div>
	);
};

export default Marker;
