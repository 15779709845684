import { React, useEffect, useState } from "react";
import "./Contact.css";
import Facebook from "../../utils/Icon/facebook.svg";
import Instagram from "../../utils/Icon/Instagram.svg";
import Youtube from "../../utils/Icon/Youtube.svg";
import {
  Typography,
  Container,
  Box,
  Grid,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  TextField,
  Button,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "../MUIComponents";
import "./Privacy.css";
import {
  contentFontSize_20,
  HeaderFontSize_24,
  HeaderfontSize_30,
  HeaderNavigationFontSize_20,
  contentFontsize_24,
  contentFontSize_16,
  HeaderFontSize_24_xs_17,
  contentFontSize_15,
} from "../Typography";
import { useNavigate } from "react-router-dom";
import CustomCard from "../Commoncomponents/card";
import Dialer from "../../utils/Icon/Dialer.svg";
import Mail from "../../utils/Icon/mail.svg";
import Location from "../../utils/Icon/location.svg";
import CustomButton from "../Commoncomponents/button";
import SmallCircle from "../../utils/images/SmallCircle.png";
import BigCircle from "../../utils/images/BigCircle.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import { useLocation } from "react-router-dom";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const initialSubject = location.state?.subject || "";

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string(),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    // subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      subject: initialSubject,
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
   
      if (values.subject===""){
        alert("Please select a subject")
        return;
      }
      try {

        const response = await fetch(
          "https://testserver.nearshopz.com/api/contactus",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          message.success({
            content: "Message sent successfully", 
            className: "custom-class",
            style: {
              marginTop: "20vh",
              // marginLeft: "40vh",
            },
          })
          window.scrollTo({
            top: 0,
            behavior: "smooth", 
          });
        }
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
        alert("Failed to send message. Please try again later.");
      }
    },
  });

  const nav = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const upLgmatch = useMediaQuery(theme.breakpoints.up("lg"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const handleHomeClick = () => {
    nav("/");
  };
  const handleFbClick = () => {
    window.location.href = "https://www.facebook.com/NearShopz";
  };
  const handleYoutbClick = () => {
    window.location.href =
      " https://www.youtube.com/@nearshopz1078?app=desktop";
  };
  const handleInstaClick = () => {
    window.location.href = "https://www.instagram.com/nearshopz_india/";
  };
  return (
    <>
      <div className="background">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "30px",
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <Typography
              style={{
                color: "#2D2A6E",
                fontFamily: "Nunito",
                fontWeight: "700",
                cursor: "pointer",
              }}
              fontSize={contentFontSize_20}
              onClick={handleHomeClick}
            >
              Home
            </Typography>
            <Typography
              style={{
                color: "#69A85C",
                fontFamily: "Nunito",
                fontWeight: "700",
              }}
              fontSize={contentFontSize_20}
            >
              Contact Us
            </Typography>
          </div>
          <img
            src="/static/media/Logo.8e4fa8338e24da3e1e2e.png"
            width="210px"
            height="70px"
            alt="shop and deliver.png"
          ></img>
        </div>
        <div
          style={{
            marginTop: "60px",
            marginLeft: "15px",
            height: "100%",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <div style={{ position: "relative" }}>
                <CustomCard
                  background={"green"}
                  height={"100%"}
                  boxShadow={"none"}
                >
                  <div
                    style={{
                      marginTop: "80px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    <Typography
                      className="Contact-info"
                      fontSize={HeaderFontSize_24_xs_17}
                    >
                      Contact Information
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <img src={Dialer} alt="Phone Icon" />
                        <Typography
                          className="contact-no"
                          fontSize={contentFontSize_16}
                        >
                          +91 77368 77750
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <img src={Mail} alt="Mail Icon" />
                        <Typography
                          className="contact-no"
                          fontSize={contentFontSize_16}
                        >
                          support@nearshopz.com
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "20px",
                        }}
                      >
                        <img
                          src={Location}
                          alt="Location Icon"
                          style={{ marginTop: "2px" }}
                        />
                        <Typography
                          className="contact-no"
                          fontSize={contentFontSize_16}
                          style={{
                            wordBreak: "break-word",
                            textAlign: "left",
                          }}
                        >
                          Module 2501(A), Yamuna Building, 5th floor, Technopark
                          Phase III Main Rd, Thiruvananthapuram, Kerala 695581
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        gap: "10px",
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingTop: "150px",
                      }}
                    >
                      <div>
                        <img src={Facebook} onClick={handleFbClick} />
                      </div>
                      <div>
                        <img src={Instagram} onClick={handleInstaClick} />
                      </div>
                      <div>
                        <img src={Youtube} onClick={handleYoutbClick} />
                      </div>
                    </div>
                  </div>
                </CustomCard>
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "-100px",
                  }}
                >
                  <img src={BigCircle} alt="no image" />
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: "80px",
                    right: "50px",
                  }}
                >
                  <img
                    src={SmallCircle}
                    alt="no image"
                    width="138px"
                    height="138px"
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={8} sx={{ paddingRight: "30px" }}>
              <Typography className="Contactus" fontSize={HeaderfontSize_30}>
                Contact Us
              </Typography>
              <Typography
                className="Contactustext"
                fontSize={contentFontSize_20}
              >
                Any question or remarks? Just write us a message!
              </Typography>
              <div style={{ marginTop: "100px" }}>
                <form
                  className="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={formik.handleSubmit}
                >
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingLeft: mdmatch ? "32px" : "85px" }}
                    >
                      <FormControl fullWidth required variant="standard">
                        <label>
                          <Typography className="Contactformtext">
                            First Name<span className="redstart">*</span>
                          </Typography>
                        </label>
                        <TextField
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          variant="standard"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingLeft: mdmatch ? "32px" : "85px" }}
                    >
                      <FormControl fullWidth required variant="standard">
                        <label>
                          <Typography className="Contactformtext">
                            Last Name
                          </Typography>
                        </label>
                        <TextField
                        error={
                          formik.touched.lastName && Boolean(formik.errors.lastName)
                        }
                        helperText={formik.touched.lastName && formik.errors.lastName}
                          variant="standard"
                          name="lastName"
                          // label="Last Name"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingLeft: mdmatch ? "32px" : "85px" }}
                    >
                      <FormControl fullWidth required variant="standard">
                        <label>
                          <Typography className="Contactformtext">
                            Email<span className="redstart">*</span>
                          </Typography>
                        </label>
                        <TextField
                         error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                          variant="standard"
                          type="email"
                          name="email"
                          // label="Email"
                          // type="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ paddingLeft: mdmatch ? "32px" : "85px" }}
                    >
                      <FormControl fullWidth required variant="standard">
                        <label>
                          <Typography className="Contactformtext">
                            Phone Number<span className="redstart">*</span>
                          </Typography>
                        </label>
                        <TextField
                           error={
                            formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
                          }
                          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                          variant="standard"
                          name="phoneNumber"
                          // label="Phone Number"
                          value={formik.values.phoneNumber}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingLeft: mdmatch ? "32px" : "85px" }}
                    >
                      <Typography className="select-sub">
                        Select Subject?<span className="redstart">*</span>
                      </Typography>
                      <RadioGroup
                        row
                        name="subject"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value="Partner with Us"
                          control={<Radio />}
                          label="Partner with Us"
                        />
                        <FormControlLabel
                          value="Shop with Us"
                          control={<Radio />}
                          label="Shop with Us"
                        />
                        <FormControlLabel
                          value="Ride with Us"
                          control={<Radio />}
                          label="Ride with Us"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ paddingLeft: mdmatch ? "32px" : "85px" }}
                    >
                      <FormControl fullWidth required variant="standard">
                        <label>
                          Message <span className="redstart">*</span>
                        </label>
                        <TextField
                          variant="standard"
                          placeholder="Write the message"
                          value={formik.values.message}
                          name="message"
                          error={
                            formik.touched.message && Boolean(formik.errors.message)
                          }
                          helperText={formik.touched.message && formik.errors.message}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <CustomButton
                          background={"green"}
                          padding={"10px"}
                          matchPadding={true}
                          width={"180px"}
                          type="submit"
                        >
                          <Typography
                            className="button"
                            fontSize={contentFontSize_15}
                          >
                            Send Message
                          </Typography>
                        </CustomButton>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Contact;
