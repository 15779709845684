import React, { useState } from 'react';
import { Typography, Checkbox, useTheme, useMediaQuery, FormControl, Select, MenuItem, InputLabel } from '../MUIComponents/index';
import '../Profile/ManageSlots.css';

export default function SameDayDelivery() {
  const [option, setOption] = useState("false"); 
  const [deliveryOption, setDeliveryOption] = useState('');

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCheckboxChange = (value) => {
    setOption(value);
  };

  const handleDropdownChange = (event) => {
    setDeliveryOption(event.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <div>
        <Typography
          className="chooseHolidaysStyle"
          fontSize={18} 
        >
          Do you support same day delivery?<span style={{ color: "#EA4335" }}>*</span>
        </Typography>
      </div>
      <div style={{ display: "flex", gap: smmatch ? "80px" : "60px" }}>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Checkbox
            checked={option === "true"}
            style={{
              padding: 0,
              borderRadius: 0,
              color: "#85BE49",
            }}
            size={matches ? "small" : "medium"}
            onChange={() => handleCheckboxChange("true")} 
          />
          <Typography
            className="kmStyle"
            fontSize={20} 
          >
            Yes
          </Typography>
        </div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Checkbox
            checked={option === "false"}
            style={{
              padding: 0,
              borderRadius: 0,
              color: "#85BE49",
            }}
            size={matches ? "small" : "medium"}
            onChange={() => handleCheckboxChange("false")}
          />
          <Typography
            className="kmStyle"
            fontSize={20}
          >
            No
          </Typography>
        </div>
      </div>

      {option === "false" && (
        <div style={{ marginTop: "15px" }}>
          <FormControl fullWidth>
            <InputLabel id="delivery-time-label">Delivery Time</InputLabel>
            <Select
              labelId="delivery-time-label"
              id="delivery-time-select"
              value={deliveryOption}
              onChange={handleDropdownChange}
            >
              <MenuItem value="within24hours">Within 24 hours</MenuItem>
              <MenuItem value="within2days">Within 2 days</MenuItem>
              <MenuItem value="within3days">Within 3 days</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
}
