import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import SearchProductCard from "../SearchDetailsCards";
import OrderStatusStepper from "../StatusStepper";
import "./index.css";
import { useMediaQuery } from 'react-responsive';
import { Container } from "@mui/material";

const customerFields = [
	{ label: "Customer Name", value: "customerName" },
	{ label: "Mobile Number", value: "mobileNumber" },
];
const deliveryFields = [
	{ label: "Delivery type", value: "deliveryType" },
	{ label: "Delivery address", value: "deliveryAddress" },
	{ label: "Delivery date", value: "deliveryDate" },
	{ label: "Delivery slot", value: "deliveryTime" },
	{ label: "Instructions from customer ", value: "specialInstructions" },
];

const CriticalDetails = (props) => {
	// const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
	const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
	const { getSearchDetails } = props;
	useEffect(() => {
		props.getSearchDetails(props.location.state.orderId);
	},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getSearchDetails]);

	return (
		isSmallScreen ?
			<div
				style={{
					width: '90vw',
					backgroundColor: "white",
					border: "1px solid rgba(0, 0, 0, 0.16)",
					borderRadius: "10px",
					Top: "40px",
					padding: "30px"
				}}>
				<div>
					<div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
						<b style={{ fontWeight: 600, justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '23px' }}>
							{"Order details - " + props.location.state.orderNumber}
						</b>
					</div>
					<form id="search-details" style={{ widt: '100%' }}>
						<div className="delivery-section">
							<h5 style={{ fontWeight: 600 }}>{"Customer details"}</h5>
							<ul className="details-list">
								{props.searchDetails &&
									customerFields.map((detail, index) => {
										return (
											<>
												<Row key={index} style={{ paddingBottom: '8px' }}>
													<Col key={detail.label}>{detail.label}</Col>
													<Col key={detail.value}>{':\t\t' + props.searchDetails[detail.value]}</Col>
												</Row>
											</>
										);
									})}
							</ul>
						</div>
						<div className="delivery-section">
							<h5 style={{ fontWeight: 600 }}>{"Order details"}</h5>
							<ul className="details-list">
								{props.searchDetails &&
									deliveryFields.map((detail, index) => {
										return (
											<>
												<Row key={index} style={{ paddingBottom: '8px' }}>
													<Col key={detail.label}>{detail.label}</Col>
													<Col key={detail.value}>
														{props.searchDetails[detail.value] ? ':\t ' + props.searchDetails[detail.value] : ':\t ----------'}
													</Col>
												</Row>
											</>
										);
									})}
							</ul>
						</div>
						<div className="product-section">
							<h5 style={{ fontWeight: 600 }}>{"Product Details"}</h5>
							<ul style={{ width: '100%' }}>
								{props.searchDetails &&
									props.searchDetails.products.length &&
									<SearchProductCard {...props} />
								}
							</ul>
							<ul style={{ float: 'right' }}>
								<li>
									<Typography variant="button">Total Amount :
										<i
											style={{ paddingLeft: 5, paddingRight: 5 }}
											className="fa fa-inr"
											aria-hidden="true" />
										{parseFloat(props.searchDetails && props.searchDetails.totalPrice).toFixed(2)}
									</Typography>
								</li>
							</ul>
						</div>
						<br />
						<div className="product-section">
							<h5 style={{ fontWeight: 600 }}>{"Order Status"}</h5>
							<OrderStatusStepper
								scheduleAlert={props.scheduleAlert}
								orderNumber={props.searchDetails && props.searchDetails.orderNumber}
								deliveryDate={props.searchDetails && props.searchDetails.deliveryDate}
								deliveryTime={props.searchDetails && props.searchDetails.deliveryTime}
								commentsSeller={props.searchDetails && props.searchDetails.commentsSeller}
								status={props.searchDetails && props.searchDetails.stats}
								statusChangeHandler={props.postStatusChange}
								orderid={props.searchDetails && props.searchDetails.id}
								rejectedStatus={props.rejectedStatusHandler}
								paymentStatus={props.searchDetails && props.searchDetails.paymentStatus}
								shopAssistant={props.searchDetails && props.searchDetails.shopAssistantId}
								{...props}
							/>
						</div>
					</form>
					<Row>
						<Col>
							<button
								style={{
									justifyContent: ' center',
									color: 'rgb(255, 255, 255)',
									backgroundColor: 'rgb(137, 199, 74, 1)',
									boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
									width: '100px',
									float: 'right',
									height: '37px',
									fontSize: '16px',
									fontWeight: '600',
									borderRadius: '5px',
									borderWidth: '1px',
									borderStyle: ' solid',
									borderColor: 'transparent',
									borderImage: 'initial',
									overflow: 'hidden',
									outline: 'none',
									minHeight: ' 14px',
									fontFamily: 'Varela Round, sans-serif'
								}}
								variant="success"
								onClick={() => {
									props.history.push("/home/Criticalorders");
								}}>
								Back
							</button>
						</Col>
					</Row>
				</div>
			</div>
			:
			<Container maxWidth='md' 
			style={{
				width: '100%',
				backgroundColor: "white",
				border: "1px solid rgba(0, 0, 0, 0.16)",
				borderRadius: "10px",
				Top: "40px",
				padding: "30px"
			}}>
				<div >
					<div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
						<b style={{ fontWeight: 600, justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '23px' }}>
							{"Order details - " + props.location.state.orderNumber}
						</b>
					</div>
					<form id="search-details">
						<div className="delivery-section">
							<h5 style={{ fontWeight: 600 }}>{"Customer details"}</h5>
							<ul className="details-list">
								{props.searchDetails &&
									customerFields.map((detail, index) => {
										return (
											<>
												<Row key={index} style={{ paddingBottom: '8px' }}>
													<Col key={detail.label}>{detail.label}</Col>
													<Col key={detail.value}>{':\t\t' + props.searchDetails[detail.value]}</Col>

												</Row>
											</>
										);
									})}
							</ul>
						</div>
						<div className="delivery-section">
							<h5 style={{ fontWeight: 600 }}>{"Order details"}</h5>
							<ul className="details-list">
								{props.searchDetails &&
									deliveryFields.map((detail, index) => {
										return (
											<>
												<Row key={index} style={{ paddingBottom: '8px' }}>
													<Col key={detail.label}>{detail.label}</Col>
													<Col key={detail.value}>
														{props.searchDetails[detail.value] ? ':\t ' + props.searchDetails[detail.value] : ':\t ----------'}
													</Col>
												</Row>
											</>
										);
									})}
							</ul>
						</div>
						<div className="product-section">
							<h5 style={{ fontWeight: 600 }}>{"Product Details"}</h5>
							<ul>
								{props.searchDetails &&
									props.searchDetails.products.length &&
									<SearchProductCard {...props} />
								}
							</ul>
							<ul style={{ float: 'right' }}>
								<li>
									<Typography variant="button">Total Amount :
										<i
											style={{ paddingLeft: 5, paddingRight: 5 }}
											className="fa fa-inr"
											aria-hidden="true" />
										{parseFloat(props.searchDetails && props.searchDetails.totalPrice).toFixed(2)}
									</Typography>
								</li>
							</ul>
						</div>
						<br />
						<div className="product-section">
							<h5 style={{ fontWeight: 600 }}>{"Order Status"}</h5>
							<OrderStatusStepper
								scheduleAlert={props.scheduleAlert}
								orderNumber={props.searchDetails && props.searchDetails.orderNumber}
								deliveryDate={props.searchDetails && props.searchDetails.deliveryDate}
								deliveryTime={props.searchDetails && props.searchDetails.deliveryTime}
								commentsSeller={props.searchDetails && props.searchDetails.commentsSeller}
								status={props.searchDetails && props.searchDetails.stats}
								statusChangeHandler={props.postStatusChange}
								orderid={props.searchDetails && props.searchDetails.id}
								rejectedStatus={props.rejectedStatusHandler}
								paymentStatus={props.searchDetails && props.searchDetails.paymentStatus}
								shopAssistant={props.searchDetails && props.searchDetails.shopAssistantId}
								{...props}
							/>
						</div>
					</form>
					<Row>
						<Col>
							<button
								style={{
									justifyContent: ' center',
									color: 'rgb(255, 255, 255)',
									backgroundColor: 'rgb(137, 199, 74, 1)',
									boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
									width: '100px',
									float: 'right',
									height: '37px',
									fontSize: '16px',
									fontWeight: '600',
									borderRadius: '5px',
									borderWidth: '1px',
									borderStyle: ' solid',
									borderColor: 'transparent',
									borderImage: 'initial',
									overflow: 'hidden',
									outline: 'none',
									minHeight: ' 14px',
									fontFamily: 'Varela Round, sans-serif'
								}}
								variant="success"
								onClick={() => {
									props.history.push("/home/Criticalorders");
								}}>
								Back
							</button>
						</Col>
					</Row>
				</div>
			</Container>
	);
};
export default CriticalDetails;
