// import React from "react";
// import Container from '@mui/material/Container';
// import { Row, Col } from 'react-bootstrap';
// import TextField from "@mui/material/TextField";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { green } from "@mui/material/colors";
// import { useFormik } from "formik";
// import { freedeliveryvalidationSchema } from "./Validation";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// import './index.css';
// import dayjs from 'dayjs';
// import AdvancedFormat from 'dayjs/plugin/advancedFormat';
// dayjs.extend(AdvancedFormat);

// export default function FreeDEliveryCoupon(props) {

//   const [isUser, setIsUser] = React.useState(false);
//   const [tovalue, setToValue] = React.useState();
//   const [formSubmitted,setFormSubmitted]=React.useState(false)

//   const [fromvalue, setFromValue] = React.useState();
//   const onKeyDown = (e) => {
//     e.preventDefault();
//   }
//   const handleChange = (event) => {
//     // console.log("handleChange() - value is", event.target.checked);
//     setIsUser(event.target.checked);
//     // Can't set form object values here as setState calls don't happen immediately.
//   };

//   const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//     overrides: {
//       MuiInputBase: {
//         input: {
//           "&:-webkit-autofill": {
//             transitionDelay: "9999s",
//             transitionProperty: "background-color, color",
//           },
//         },
//       },
//     },
//   });

//   const formik = useFormik({
//     initialValues: {
//       couponCode: '',
//       usesperCustomer: '',
//       minOrder: '',
//       fromdate: '',
//       todate: '',
//       fromtime: new Date(),
//       totime: new Date(),
//     },
//     validationSchema: freedeliveryvalidationSchema,

//     onSubmit: (values) => {
//       let { couponCode, usesperCustomer, minOrder, fromdate, todate, fromtime, totime } = values;
//       let StartDate = new Date(fromtime);
//       let ToDate = new Date(totime);
//       // let {fromtime, totime}= values;
//       // let StartDate =  new Date(fromtime);
//       // let ToDate =  new Date( totime);

//       if (StartDate >= ToDate) {
//         formik.setFieldError("totime", "End Time must be greater than Start Time")
//         // console.log(`${StartDate} is greater than ${ToDate}`);
//         // false
//       }
//       else if (StartDate <= new Date(Date.now() - 1000 * (60 * 5))) {
//         formik.setFieldError("fromtime", "Start Time must be greater than Today") // false
//       }
//       else if (ToDate < new Date()) {
//         formik.setFieldError("totime", "End Time must be greater than Today") // false
//       }

//       // console.log(getTime(fromtime))
//       else {
//         props.CreateDeliveryFree({ couponCode, minOrder, usesperCustomer, StartDate, ToDate, isUser })
//       }
//       setFormSubmitted(true);
//     }
//   })

//   return (
//     <>
//       <form
//         //  enableReinitialize
//         // enableReinitialize={true}
//         style={{ width: "100%", paddingTop: "15px" }}
//         onSubmit={formik.handleSubmit}
//         onReset={formik.handleReset}
//       >
//         <Container
//         maxWidth='md'
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             marginTop: '100px',
//             padding: "30px",
//             width: "100%",
//           }}
//         >
//           <span>
//             <h3 className="CouponType">
//               {'Delivery free coupon'}
//             </h3>
//           </span>
//         </Container>
//         <Container
//         maxWidth='md'
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             marginTop: "20px",
//             padding: "30px",
//             width: "100%",
//           }}
//         >
//           <Row
//             className="CouponHeading">
//             <p>
//               Create coupon
//             </p>
//           </Row>
//           <div className="formDiv">
//             <Row>
//               <Col md={6} sm={12} xs={12} style={{paddingBottom:'30px'}}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     InputProps={{ style: { height: 35 } }}
//                     fullWidth
//                     variant="outlined"
//                     value={formik.values.couponCode}
//                     onChange={formik.handleChange}
//                     error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
//                     helperText={formik.touched.couponCode && formik.errors.couponCode}
//                     disabled={formSubmitted}
//                     className="form-control"
//                     name="couponCode"
//                     label="Coupon code"
//                     InputLabelProps={{
//                       shrink: true,

//                     }}
//                   />
//                 </ThemeProvider>
//               </Col>
//               <Col md={6} sm={12} xs={12} style={{paddingBottom:'30px'}}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     InputProps={{ style: { height: 35 }, inputProps: { min: 1 } }}
//                     type="number"
//                     fullWidth
//                     variant="outlined"
//                     value={formik.values.usesperCustomer}
//                     onChange={formik.handleChange}
//                     error={formik.touched.usesperCustomer && Boolean(formik.errors.usesperCustomer)}
//                     helperText={formik.touched.usesperCustomer && formik.errors.usesperCustomer}
//                     disabled={formSubmitted}

//                     className="form-control"
//                     name="usesperCustomer"
//                     onKeyPress={(event) => {
//                       if (event?.key === "-" || event?.key === "+") {
//                         event.preventDefault();
//                       }
//                     }}
//                     label="Uses per customer"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </ThemeProvider>
//               </Col>

//             </Row>
//             <Row style={{ paddingTop: '10px' }}>
//               <Col md={6} sm={12} xs={12} >
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     InputProps={{ style: { height: 35 }, inputProps: { min: 1 } }}
//                     type="number"
//                     fullWidth
//                     variant="outlined"
//                     value={formik.values.minOrder}
//                     onChange={formik.handleChange}
//                     error={formik.touched.minOrder && Boolean(formik.errors.minOrder)}
//                     helperText={formik.touched.minOrder && formik.errors.minOrder}
//                     disabled={formSubmitted}
//                     className="form-control"
//                     name="minOrder"
//                     onKeyPress={(event) => {
//                       if (event?.key === "-" || event?.key === "+") {
//                         event.preventDefault();
//                       }
//                     }}
//                     label="Minimum order value"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </ThemeProvider>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//         <Container
//         maxWidth='md'
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             marginTop: "20px",
//             padding: "30px",
//             width: "100%",
//           }}
//         >
//           <Row
//             className="CouponHeading">
//             <p >
//               Coupon Validity
//             </p>
//           </Row>
//           <div className="formDiv">
//             <Row>
//               <Col md={6} sm={12} xs={12} style={{paddingBottom:'50px'}}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DateTimePicker
//                     value={fromvalue}
//                     onChange={(newValue) => {
//                       setFromValue(newValue);
//                       formik.setFieldValue('fromtime', newValue)
//                     }}
//                     label="Start Time"
//                     renderInput={(props) =>
//                     <TextField
//                       onKeyDown={onKeyDown}
//                       {...props} value={formik.values.fromtime}
//                       onChange={formik.handleChange}
//                       error={formik.touched.fromtime && Boolean(formik.errors.fromtime)}
//                       helperText={formik.touched.fromtime && formik.errors.fromtime}
//                       disabled={formSubmitted}
//                       className="form-control"
//                       name="fromtime" />}
//                       minDate={dayjs()}
//                       minTime={
//                         dayjs(formik.values.fromtime).isSame(dayjs(), 'day') ? dayjs() : undefined
//                       }
//                   />
//                 </LocalizationProvider>
//               </Col>

//               <Col md={6} sm={12} xs={12} style={{paddingBottom:'20px'}}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DateTimePicker
//                     value={tovalue}
//                     onChange={(newValue) => {
//                       setToValue(newValue);
//                       formik.setFieldValue('totime', newValue)
//                     }}
//                     label="End Time"
//                     renderInput={(props) =>
//                     <TextField
//                       onKeyDown={onKeyDown}
//                       {...props} value={formik.values.totime}
//                       onChange={formik.handleChange}
//                       error={formik.touched.totime && Boolean(formik.errors.totime)}
//                       helperText={formik.touched.totime && formik.errors.totime}
//                       disabled={formSubmitted}
//                       className="form-control"
//                       name="totime" />}
//                       minDate={dayjs(fromvalue)}
//                       minTime={
//                         dayjs(formik.values.totime).isSame(dayjs(), 'day') ? dayjs() : undefined
//                       }
//                   />
//                 </LocalizationProvider>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//         <Row className="centerDiv">
//         <button type='submit' className="couponButton" disabled={formSubmitted}>
//             {formSubmitted  ? "Creating" : "Create Coupon"}
//           </button>
//         </Row>
//       </form>
//     </>
//   )
// }








//new code

import React, { useEffect, useState } from "react";
import { Grid, Box } from "../MUIComponents/index";
import { Typography } from "../MUIComponents/index";
import "./FlatDiscount.css";
import {
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  ButtonFontSize_18,
} from "../Typography/index";
import CustomTextField from "../Commoncomponents/input";
import CustomButton from "../Commoncomponents/button";
import {
  useTheme,
  useMediaQuery,
  DemoContainer,
  AdapterDayjs,
  LocalizationProvider,
  DateTimePicker,
} from "../MUIComponents/index";
import Calendar from "../../utils/Icon/Calendar.svg";
import { useFormik } from "formik";
import { freedeliveryvalidationSchema } from "./Validation";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { TextField } from "../MUIComponents/index";
import { message } from "antd";


function FreeDeliveryCoupon(props) {

    const [isUser, setIsUser] = useState(false);
  const [tovalue, setToValue] = useState();
  const [formSubmitted,setFormSubmitted]=useState(false)

  const [fromvalue, setFromValue] = useState();


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));


  const nav = useNavigate();


  useEffect(()=>{
    if(props.Deliverycouponsuccess){
      message.success({
        content: "Free Delivery Coupon created Successfully",
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
  
        setTimeout(() => {
        nav('/home/upcomingCoupons');
      }, 4000);
    }
  },[props.Deliverycouponsuccess])


  function handleCouponPage(){
    nav("/home/Coupons")
  }
   
  function handleCouponTypePage(){
    nav("/home/Coupontype")
  }
    

  function MuiIcon() {
    return <img src={Calendar} alt="Calendar icon" width="22px" height="21.99px" />;
  }

    const onKeyDown = (e) => {
    e.preventDefault();
  }


  const formik = useFormik({
    initialValues: {
      couponCode: "",
      usesperCustomer: "",
      minOrder: "",
      fromdate: "",
      todate: "",
      fromtime: '',
      totime: '',
    },
    validationSchema: freedeliveryvalidationSchema,

    onSubmit: (values) => {
      let {
        couponCode,
        usesperCustomer,
        minOrder,
        fromdate,
        todate,
        fromtime,
        totime,
      } = values;
      let StartDate = new Date(fromtime);
      let ToDate = new Date(totime);
     

      if (StartDate >= ToDate) {
        formik.setFieldError(
          "totime",
          "End Time must be greater than Start Time"
        );
      
      } else if (StartDate <= new Date(Date.now() - 1000 * (60 * 5))) {
        formik.setFieldError(
          "fromtime",
          "Start Time must be greater than current time"
        ); 
      } else if (ToDate < new Date()) {
        formik.setFieldError("totime", "End Time must be greater than start time"); // false
      }

      else {
        props.CreateDeliveryFree({
          couponCode,
          minOrder,
          usesperCustomer,
          StartDate,
          ToDate,
          isUser,
        });
        setFormSubmitted(true);
        const currentDate = new Date().getTime();
        if(values.fromtime>currentDate){
         
            
           
          
        } else {
          nav('/home/Coupons');
        }
      }
     
    

    },
  });

 


  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorFieldName = Object.keys(formik.errors)[0];
      const errorField = document.getElementsByName(errorFieldName)[0];
      if (errorField) {
        errorField.focus();
      } else {
        console.error(`Element with name ${errorFieldName} not found.`);
      }
    }
  }, [formik.isSubmitting, formik.errors]);

  return (
    <>
      <div style={{ display: "flex", gap: "8px" }}>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
            onClick={handleCouponPage}
          >
            Coupon
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
          >
            /
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
            onClick={handleCouponTypePage}
          >
            Coupon Type
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
          >
            /
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
            style={{ color: "#85BE49" }} 
          >
            Coupons Type
          </Typography>
        </div>
      </div>

      <Typography
        style={{
          margin: "20px 0px 20px 0px",
          fontFamily: "Roboto",
          fontWeight: "600",
        }}
        fontSize={HeaderNavigationFontSize_20_xs_13}
      >
       Free Delivery
      </Typography>

      <div style={{ marigin: "20px" }}>
      <form
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  fontSize={contentFontSize_16}
                  className="flatLabelFont"
                >
                  Coupon Code <span className="red-start">*</span>
                </Typography>
                <TextField
 		              value={formik.values.couponCode}
                  onChange={formik.handleChange}
                   error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                   helperText={formik.touched.couponCode && formik.errors.couponCode}
                   name="couponCode"
                   variant="outlined"
                   sx={{
                     width: '100%',
                 
                     '& .MuiOutlinedInput-root': {
                       '& fieldset': {
                         borderColor: '#85BE49', 
                       },
                       '&.Mui-focused fieldset': {
                         borderColor: '#85BE49', 
                       },
                     },
                     '& .MuiInputBase-input': {
                       backgroundColor: 'white',
                       borderRadius:"10px" 
                     },
                   }}
                   InputProps={{
                    sx: {
                      fontSize: matches && '13px', 
                    },
                }}
                    size={matches ? "small" : "normal"}


                ></TextField>

              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  fontSize={contentFontSize_16}
                  className="flatLabelFont"
                >
                  Uses Per Customer <span className="red-start">*</span>
                </Typography>
                <TextField
 		            value={formik.values.usesperCustomer}
                onChange={formik.handleChange}
                error={formik.touched.usesperCustomer && Boolean(formik.errors.usesperCustomer)}
                 helperText={formik.touched.usesperCustomer && formik.errors.usesperCustomer}
                 name="usesperCustomer"
                 variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                      },
                  }}
                      size={matches ? "small" : "normal"}


                ></TextField>

              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  fontSize={contentFontSize_16}
                  className="flatLabelFont"
                >
                  Minimum Order Value <span className="red-start">*</span>
                </Typography>
                <TextField
 		                value={formik.values.minOrder}
                     onChange={formik.handleChange}
                     error={formik.touched.minOrder && Boolean(formik.errors.minOrder)}
                     helperText={formik.touched.minOrder && formik.errors.minOrder}
                     name="minOrder"
                     variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                      },
                  }}
                      size={matches ? "small" : "normal"}


                ></TextField>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography
                  fontSize={contentFontSize_16}
                  className="flatLabelFont"
                >
                  Coupon Validity <span className="red-start">*</span>
                </Typography>
                {/* {Start} */}
                <Grid container spacing={2} sx={{paddingTop:smmatch?"16px":"0px"}}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                          slots={{
                            openPickerIcon: MuiIcon,
                          }}
                          slotProps={{
                            textField: {
                              placeholder: "From",
                              error:formik.touched.fromtime && Boolean(formik.errors.fromtime),
                              helperText:formik.touched.fromtime && formik.errors.fromtime,
                              size: matches && "small",
                            },
                          }}
                          sx={{
                            width: '100%',
                        
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#85BE49', 
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#85BE49', 
                              },
                            },
                            '& .MuiInputBase-input': {
                              backgroundColor: 'white',
                              borderRadius:"10px" ,
                              fontSize: matches && '13px', 
                            },
                          }}
                          value={fromvalue}
                          onChange={(newValue) => {
                          setFromValue(newValue);
                          formik.setFieldValue('fromtime',newValue)
                          }}

                          renderInput={(props) => <TextField
                          onKeyDown={onKeyDown}
                          {...props} value={formik.values.fromtime}
                          onChange={formik.handleChange}
                          error={formik.touched.fromtime && Boolean(formik.errors.fromtime)}
                          helperText={formik.touched.fromtime && formik.errors.fromtime}
                          name="fromtime" />}
                          minDate={dayjs()}
                          minTime={dayjs(formik.values.fromtime).isSame(dayjs(), 'day') ? dayjs() : undefined}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                          slots={{
                            openPickerIcon: MuiIcon,
                          }}
                          slotProps={{
                            textField: {
                              placeholder: "To",
                              error:formik.touched.totime && Boolean(formik.errors.totime),
                          helperText:formik.touched.totime && formik.errors.totime,
                              size: matches && "small",
                            },
                          }}
                          sx={{
                            width: '100%',
                        
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#85BE49', 
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#85BE49', 
                              },
                            },
                            '& .MuiInputBase-input': {
                              backgroundColor: 'white',
                              borderRadius:"10px" ,
                              fontSize: matches && '13px', 
                            },
                          }}
                          value={tovalue}
                          onChange={(newValue) => {
                          setToValue(newValue);
                          formik.setFieldValue('totime',newValue)
                          }}

                          renderInput={(props) => <TextField
                          onKeyDown={onKeyDown}
                          {...props} value={formik.values.totime}
                          onChange={formik.handleChange}
                          error={formik.touched.totime && Boolean(formik.errors.totime)}
                          helperText={formik.touched.totime && formik.errors.totime}

                          className="form-control"
                          name="totime" />}
                          minDate={dayjs(fromvalue)}
                          minTime={
                          dayjs(formik.values.totime).isSame(dayjs(), 'day') ? dayjs() : undefined
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                {/* {end} */}
              </div>
            </Grid>
          </Grid>
        </Box>
        <div style={{ marginTop: "150px", textAlign: "center" }}>
          <CustomButton
            type="submit"
            width={
              upXlmatch
                ? "358px"
                : xlmatch
                ? lgmatch
                  ? mdmatch
                    ? smmatch
                      ? xsmatch
                        ? "250px"
                        : "250px"
                      : "310px"
                    : "330px"
                  : "340px"
                : "358px"
            }
            borderRadius="20px"
            background={formSubmitted?"gray":'#659B1B'}
            disabled={formSubmitted&&"disabled"}
            matchPadding={true}
            padding={"10px 0px"}
          >
            <Typography fontSize={ButtonFontSize_18} className="create-coupon">
              
              {/* {formSubmitted  ? "Creating" : "Create Coupon"} */}
              Create Coupon
            </Typography>
          </CustomButton>
        </div>

        </form>
      </div>
    </>
  );
}

export default FreeDeliveryCoupon;
