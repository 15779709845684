import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Box,
  OutlinedInput,
  ListItemText,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import CustomButton from "../../Commoncomponents/button/index"; // Adjust import path as per your file structure
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, useMediaQuery } from "@mui/material";
import { message } from "antd";
import { validationSchema } from "../Validation/SlotValidation";

const Holidays = ({ selectedHolidays, setSelectedHolidays, formik }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tempHolidays, setTempHolidays] = useState([]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));

  const HolidaysList = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
  ];

  const handleChange = (event) => {
    const { value } = event.target;
    setTempHolidays(value);
  };

  const handleConfirm = () => {
    setSelectedHolidays(tempHolidays);
    message.success({
      content: "Holiday Added Successfully",
      className: "custom-class",
      style: {
        marginTop: "10vh",
        // marginLeft: "15vw",
      },
      duration: 3.5,
    });
    setIsDropdownOpen(false);
  };

  const handleRemove = (id) => {
    const updatedHolidays = selectedHolidays.filter((item) => item !== id);
    setSelectedHolidays(updatedHolidays);
  };

  return (
    <>
      <FormControl
        fullWidth
        sx={{ borderRadius: "10px", background: "white" }}
        size="medium"
      >
        <Select
          multiple
          value={tempHolidays}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={() => ""}
          MenuProps={{
            disableScrollLock: true,
            marginThreshold: null,
            open: isDropdownOpen,
          }}
          name="holiday"
          onBlur={formik.handleBlur}
          error={formik.touched.holiday && Boolean(formik.errors.holiday)}
          // helperText={formik.touched.holiday && formik.errors.holiday}
          sx={{
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#85BE49",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#85BE49",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#85BE49",
            },
          }}
          size={matches ? "small" : "normal"}
          onOpen={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
        >
          {HolidaysList.map((day) => (
            <MenuItem key={day.id} value={day.id}>
              <Checkbox checked={tempHolidays.includes(day.id)} />
              <ListItemText primary={day.name} />
            </MenuItem>
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px 0px",
            }}
          >
            <CustomButton
              matchPadding={true}
              padding="10px"
              borderRadius="13px"
              onClick={handleConfirm}
            >
              <Typography fontSize="13px" style={{ fontWeight: "700" }}>
                Confirm Holiday
              </Typography>
            </CustomButton>
          </div>
        </Select>
      </FormControl>

      <div style={{ marginTop: "15px" }}>
        <Box mt={2}>
          <Grid container spacing={3}>
            {selectedHolidays.map((selectedDay) => (
              <Grid item xs={12} sm={3} md={3} lg={3} xl={3} key={selectedDay}>
                <div>
                  <CustomButton
                    variant="contained"
                    color="white"
                    background="#85BE49"
                    width="100%"
                    borderRadius="12.09px"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: smmatch
                          ? "space-between"
                          : "space-between",
                        alignItems: "center",
                        width: "100%",
                        height: smmatch && "20px",
                      }}
                    >
                      <Typography>
                        {HolidaysList.find((e) => e.id === selectedDay)?.name}
                      </Typography>
                      <IconButton onClick={() => handleRemove(selectedDay)}>
                        <CloseIcon
                          style={{
                            color: "white",
                            width: matches ? "13px" : "auto",
                          }}
                        />
                      </IconButton>
                    </div>
                  </CustomButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default Holidays;
