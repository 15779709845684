import { combineReducers } from "redux";
import { SignUpReducer } from "../Reducers/SignUpReducer";
import { LoginReducer } from "../Reducers/LoginReducers";
import { SignInReducer } from "../Reducers/SignInReducer";
const rootReducer = combineReducers({
	SignUpReducer,
	LoginReducer,
	SignInReducer,
});
export default rootReducer;
