import { React, useState } from "react";
import "./Subcategory.css";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card";
import Arrow from "../../utils/Icon/Arrow.svg";
import Bevarages from "../../utils/Icon/Bevarages.svg";
import Businessproduct from "./Businessproduct";
import {
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  contentFontSize_18,
} from "../Typography";
import { dummyData } from "../Data/Dummydata";
import { useNavigate } from "react-router-dom";

function Subcategory({ setArrowClick, }) {
  const [nextArrow, setNextArrow] = useState(false);
  function handleClickOne() {
    // setSubCategory(false);
  }
  const nav = useNavigate();
  function handleClickTwo() {
    setArrowClick(false);
    nav("/home/CatalogProducts")
  }
  function handleArrowClick() {
    setNextArrow(true);
  }
const [selectedSubCategory,setSelectedSubCategory]= useState([])
const selectedCategory= localStorage.getItem('selectedCat')
const lastSelectedCategory = selectedCategory[selectedCategory.length - 1];
const  categoryData= dummyData.business_types
.flatMap(businessType => businessType.categories)
.find(category => category.name ===  selectedCategory)  


localStorage.setItem("categorydata", JSON.stringify(categoryData))
  

  function handleSubCategoryClick(subcategoryName){
    const storedSubCategories = JSON.parse(localStorage.getItem('selectedSubCategory')) || [];
    const newCategory = { name: subcategoryName };
    const updatedSubCategories = [...storedSubCategories, newCategory];
    setSelectedSubCategory(updatedSubCategories);
    localStorage.setItem("selectedSubCategory", JSON.stringify(updatedSubCategories));
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {nextArrow ? (
        <Businessproduct
          setArrowClick={setArrowClick}
          setNextArrow={setNextArrow}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          selectedCategory={selectedCategory}
          dummyData={dummyData}
        />
      ) : (
        <div>
          <div className="main">
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
                style={{ cursor: "pointer" }} onClick={handleClickTwo}
              >
                Business Type
              </Typography>
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
                style={{ cursor: "pointer" }} onClick={handleClickOne}
              >
                Category
              </Typography>
              <Typography
                className="main"
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                /
              </Typography>
              <Typography
                className="main"
                style={{ color: "#85BE49" }}
                fontSize={HeaderNavigationFontSize_20_xs_13}
              >
                Sub Category
              </Typography>
          </div>
          <Typography className="SubCategory" fontSize={HeaderNavigationFontSize_20}>Choose Your Sub Category</Typography>
          
          <>
          {categoryData.subcategories.map((subcategory, index) => (
          <div style={{ paddingTop: "30px", paddingRight: "30px" }}>
            <CustomCard padding={"20px"} onClick={() => handleSubCategoryClick(subcategory.name)}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3} sm={2} md={2} lg={1} xl={1}>
                    <CustomCard
                      padding="2px"
                      // width={"70px"}
                      height={"70px"}
                      background="#F2F2F2"
                      boxShadow="none"
                      borderRadius="10px"
                      width={matches ? "60px" : "70px"}
                      sx={{ alignItems: "center" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          //   padding:"6px"
                        }}
                      >
                        <img
                          src={Bevarages}
                          alt="Bevarages"
                          style={{ width: matches ? "45px" : "80%" }}
                        />
                      </div>
                    </CustomCard>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        paddingLeft: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                        }}
                      >
                        <Typography className="Drinks" fontSize={contentFontSize_18}>{subcategory.name}</Typography>
                        <Typography className="product-choose" fontSize={contentFontSize_16}>Choose From {subcategory.products.length} Products</Typography>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div style={{ display: "flex", gap: "15px" }}>
                          <div onClick={handleArrowClick}>
                            <img src={Arrow} alt="arrowicon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </CustomCard>
          </div>
          ))}
          </>
        </div>
        
      )}
    </>
  );
}

export default Subcategory;
