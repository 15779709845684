import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
// import { makeStyles } from "@mui/styles";
// import {
//   DollarSign,
// 	User as UsersIcon,
// 	HelpCircle as Help,
//    PlusSquare as PlusIcon,
//    Layers as dept,
//    Edit as EditIcon,
//    AlertTriangle as Critical, 
//    LogOut ,Grid , Bookmark,Users,
//    UserCheck,
//    ShoppingBag,
//    Key,
//    Truck,
//    UserPlus,
//    Eye,
//    Percent,
// } from "react-feather"; 
import { ReactComponent as DashBoard } from "../../utils/navbaricons/Dashboard.svg";
import { ReactComponent as Myorders } from "../../utils/navbaricons/Myorders.svg";
import { ReactComponent as Addproducts } from "../../utils/navbaricons/Addproducts.svg";
import { ReactComponent as Coupons } from "../../utils/navbaricons/Coupons.svg";
import { ReactComponent as Payment } from "../../utils/navbaricons/Payment.svg";
import { ReactComponent as ShopAssistant } from "../../utils/navbaricons/Shopassistant.svg";
import { ReactComponent as Subscription } from "../../utils/navbaricons/Subscription.svg";
import { ReactComponent as Help } from "../../utils/navbaricons/Help.svg";


export const drawerWidth = 300;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  paddingRight:'5px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

// export const useStyles = makeStyles((theme) => ({
//     mobileDrawer: {
//       width: 256,
//     },
   
//     listItem: {
//       color: 'rgb(13, 17, 54)',
//       fontSize:'14px',
// 		fontWeight: theme.typography.fontWeightMedium,
// 		justifyContent: "flex-start",
// 		letterSpacing: 0,
// 		padding: "10px 8px",
// 		textTransform: "none",
// 		width: "100%",
//     outline: "none",
//     // "&:active":{
//     //   outline: "none",
// 		// 	color: "white",
//     //   backgroundColor:'rgb(137, 199, 74, 1)',
// 		// },
// 		// "&:hover": {
//     //   outline: "none",
//     //   color: "white",
//     //   backgroundColor:'rgb(137, 199, 74, 1)',

// 		// },
// 		// "&:focus": {
// 		// 	outline: "none",
// 		// 	color: "white",
//     //   backgroundColor:'rgb(137, 199, 74, 1)',
// 		// },
//       // transition: theme.transitions.create("padding", {
//       //   easing: theme.transitions.easing.sharp,
//       //   duration: theme.transitions.duration.enteringScreen
//       // })
//     },
//     listItemClose: {
//       paddingLeft: theme.spacing(1)
//     },
//     toolbar: theme.mixins.toolbar,
//     nested: {
     
//       paddingLeft: theme.spacing(2)
//     },
//     desktopDrawer: {
//       width: 256,
//       top: 64,
      
//     },
//     avatar: {
//       cursor: "pointer",
//       width: 64,
//       height: 64,
//     },
//     listContainer: {
//       paddingRight:'10px',
//       boxShadow:"0px 10px 20px 0px rgb(0 0 0 / 16%)",
//      '@global': {
//         '*::-webkit-scrollbar': {
//           'scrollbar-width': 'thin',
//           width:'0px',
//           visibility: 'hidden'
//         }
//       } ,
//       '&:hover':{
//         overflowY: 'scroll',
//         '@global': {
//           '*': {
//             'scrollbar-width': 'thin',
           
//           },
//           '*::-webkit-scrollbar': {
//             width: '4px',
//             cursor:'pointer',
//             height:'5px'
//         }
//          ,
//         /* Track */
//         '*::-webkit-scrollbar-track': { 
//           '*-webkit-border-radius': '10px',
//             borderRadius: '13px'
//         },
         
//         /* Handle */
//         '*::-webkit-scrollbar-thumb': {
//           '*-webkit-border-radius': '10px',
//             borderRadius: '13px',
//             background:'rgb(137, 199, 74, 1)'
//         },
//         '*::-webkit-scrollbar-thumb:window-inactive': {
//           background:'rgb(137, 199, 74, 1)' 
//         }
//         } 
//      }
     
//     },
//     drawer: {
//       [theme.breakpoints.up("sm")]: {
//         width: drawerWidth,
//         flexShrink: 0,
//       },
//       width: drawerWidth,
//       flexShrink: 0,
//       // whiteSpace: 'nowrap',
//     },
//     appBar: {
//       zIndex: theme.zIndex.drawer + 1,
//       width: "100%",
//       backgroundColor: "white",
//       [theme.breakpoints.up("sm")]: {
//         width: `100%`,
//         backgroundColor: "white",
//       },
//     },
//     menuButton: {
//       marginRight: theme.spacing(2),
//       [theme.breakpoints.up("sm")]: {
//         display: "none",
//       },
//       buttonDanger: {
//         backgroundColor: "red",
//       },
//     },
//     // necessary for content to be below app bar
//     // toolbar: theme.mixins.toolbar,
//     drawerPaper: {
//       width: drawerWidth,
//     },
//     content: {
//       flexGrow: 1,
//       padding: theme.spacing(3),
//     },
//     drawerContainer: {
//       // overflow: 'auto',
//       paddingTop: "60px",
//     },
//     grow: {
//       flexGrow: 1,
//     },
//     contents: {
//       marginLeft: 300,
//       marginTop: "70px",
//       [theme.breakpoints.down("md")]: {
//         marginLeft: 300,
//         width: "100%",
//       },
//     },
//     select:{
// 			color: "white",
//       backgroundColor:'rgb(137, 199, 74, 1)',
// 	},
// 	item: {
// 		margin: "0px 5px 21px",
// 		display: "flex",
// 		paddingTop: 0,
// 		borderBottom: "none",
// 		paddingBottom: "3px",
// 	},
//   activeButton:{
//     color: "white",
//     backgroundColor:'rgb(137, 199, 74, 1)',
//     fontWeight: theme.typography.fontWeightMedium,
// 		justifyContent: "flex-start",
// 		letterSpacing: 0,
// 		padding: "10px 8px",
// 		textTransform: "none",
// 		width: "100%",
//     outline: "none",
//     "&:hover": {
//       outline: "none",
//       color: "white",
//       backgroundColor:'rgb(137, 199, 74, 1)',

// 		},
//   },
// 	button: {
// 		color: 'rgb(13, 17, 54)',
// 		fontWeight: theme.typography.fontWeightMedium,
// 		justifyContent: "flex-start",
// 		letterSpacing: 0,
// 		padding: "10px 8px",
// 		textTransform: "none",
// 		width: "100%",
//     outline: "none",
// 		"&:active":{
//       outline: "none",
// 			color: "white",
//       backgroundColor:'rgb(137, 199, 74, 1)',
// 		},
// 		"&:hover": {
//       outline: "none",
// 			color: "rgb(83,170,73)",
// 		},
// 		"&:focus": {
// 			outline: "none",
// 			color: "white",
//       backgroundColor:'rgb(137, 199, 74, 1)',
// 		},
// 	},
//   active:{
//     margin: "0px 5px 21px",
// 		display: "flex",
// 		paddingTop: 0,
// 		borderBottom: "none",
// 		paddingBottom: "3px",
//     color: "white",
//     backgroundColor:'rgb(137, 199, 74, 1)',
//   },
// 	icon: {
// 		marginRight: theme.spacing(1),
//     "&:active":{
// 			color: "white",
//       backgroundColor:'rgb(137, 199, 74, 1)',
// 		},
// 		"&:hover": {
// 			color: "rgb(83,170,73)",
// 		},
// 		"&:focus": {
// 			outline: "none",
// 			color: "white",
//       backgroundColor:'rgb(137, 199, 74, 1)',
// 		},
// 	},
// 	title: {
//         textAlign:'left',
// 		     marginRight: "-25px",
// 	},
// 	list: {
// 		width: "210px",
// 		height: "150%",
    
// 	},
// 	expand: {
// 		marginLeft: "37px",
// 	},
// 	collapse: {
//     color: 'rgb(13, 17, 54)',
//     fontSize:'14px',
// 		fontWeight: 800,
// 		justifyContent: "flex-start",
// 		letterSpacing: 0,
// 		paddingTop:'10px',
//     paddingBottom:'15px',
// 		textTransform: "none",
// 		// minHeight: "1px",
// 		// height: "auto",
// 		paddingLeft: "20px",
// 		transitionDuration: "186ms",
//     outline: "none",
    
		
// 	},
//   }));

//   export const items = [{
// 	id: 0,
// 	icon: Grid,
// 	title: "Dashboard",
// },
//      {
//         id: 1,
//         icon: UserPlus,
//         title: "Register",
//       },
// 	{
// 		id: 2,
// 		open: false,
// 		icon: UsersIcon,
// 		title: "My Account",
// 		subMenus: [
//       {icon: UserCheck,
//       title: 	"My Profile"},
// 		{	icon: ShoppingBag,
//       title: "My Store"},
// 		{	icon: Truck ,
//       title: "Additional Details"},
// 		{	icon: Key,
//       title: "Change Password"},
// 		],
// 	},
// 	{
// 		id:3,
// 		icon: DollarSign,
// 		title: "Subscription",
// 	},
// 	{
// 		id:4,
// 		icon: dept,
// 		title: "Manage Hierarchy",
// 	},
// 	{
// 		id: 5,
// 		icon: PlusIcon,
// 		title: "Add Products",
// 	},
// 	{
// 		id: 6,
// 		icon: EditIcon,
// 		title: "Edit Products",
// 	},
// 	{
// 		id: 7,
// 		icon: Bookmark,
// 		title: "My Orders",
// 	},
// 	{
// 		id: 8,
// 		icon: Users,
// 		title: "Shop Assistant",
// 		open:false,
// 		subMenus: [
//       {icon: UserPlus,
//         title: 	"Register"},
//       {	icon: Eye,
//         title: "View shop assistants"}
// 		],
// 	},
// 	{
// 		id: 9,
// 		icon: Percent,
// 		title: "Coupons",
// 	},
// 	{
// 		id:10,
// 		icon: Critical,
// 		title: "Critical orders",
// 	},
// 	{
// 		id: 11,
// 		icon: Help,
// 		title: "Help",
// 	},

 
// ];



const role = localStorage.getItem("role");

let items;
if (role !== "Store Manager") {
  items = [
    {
      id: 0,
      icon: Myorders,
      title: "My Orders",
    }
  ];
} else {
  items = [
    {
      id: 0,
      icon: DashBoard,
      title: "Dashboard",
    },
    {
      id: 1,
      icon: Myorders,
      title: "My Orders",
    },
    {
      id: 2,
      icon: Addproducts,
      title: "Add Products",
    },
    {
      id: 3,
      icon: Payment,
      title: "Payments",
    },
    {
      id: 4,
      icon: Coupons,
      title: "Coupons",
    },
    {
      id: 5,
      icon: ShopAssistant,
      title: "Shop Assistant",
    },
    {
      id: 6,
      icon: Subscription,
      title: "Subscription",
    },
    {
      id: 7,
      icon: Help,
      title: "Help",
    }
  ];
}

export { items };
