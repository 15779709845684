import { gql } from "@apollo/client";

export const EDIT_RETAILER_MUTATION = gql`
  mutation (
    $retailerid: ID
    $firstName: String
    $lastName: String
    $mobileNumber: String
    $secondaryNumber: String
    $emailAddress: String
    $PANnumber: String
    $GSTnumber: String
    $GSTurl:String
    $profileImageUrl:String
  ) {
    updateretailer(
      retailerid: $retailerid
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      emailAddress: $emailAddress
      PANnumber: $PANnumber
      GSTnumber: $GSTnumber
      GSTurl:$GSTurl
      profileImageUrl:$profileImageUrl
    ) {
      id
      firstName
      lastName
      mobileNumber
      secondaryNumber
      emailAddress
      PANnumber
      GSTnumber
      GSTurl
      profileImageUrl
      stores {
        id
        storeWorking
        storeLocation
        storeName
        storeDescription
        storeManagerFirstName
        storeManagerLastName
        bankDetails{
          bankAccountNumber
          accountHolderName
          IFSCcode
        }
        storeCoordinates {
          coordinates
        }
        storeLogoUrl
        deliveryType
        deliveryMaxDistance
        deliveryCharge {
          kmRange
          charge
        }
        paymentSupported
        holidays
        deliveryDays {
          deliveryDay
          T9amTO12pm
          T12pmTO3pm
          T3pmTO6pm
          T6pmTO9pm
        }
        storeManagerMobile
        storeManagerPhone
      }
    }
  }
`;

export const EDIT_STORE_MUTATION = gql`
  mutation (
    $storeid: ID!
    $storeName: String
    $storeLocation: String
    $storeDescription: String
    $storeLogoUrl: String
    $storeCoordinates: locationInput
    $storeManagerFirstName: String
    $storeManagerLastName: String
    $storeManagerMobile: String
    $storeManagerPhone: String
    $deliveryType: [String]
    $deliveryMaxDistance: Float
    $deliveryCharge: [deliveryChargeInput]
    $paymentSupported: [String]
    $deliveryDays: [deliveryDaysInput]
    $openingTime:String
    $closingTime:String
    $holidays:[Int]
    $minAmount:Float
    $packingCharge: Float

   
    $licenseUrl: String
    $fssaiNo: String
    $bankDetails: bankDetailsInput
    $sameDayDelivery:Boolean
    $deliveryAfter:Int
  ) {
    updatestore(
      storeid: $storeid
      storeName: $storeName
      storeLocation: $storeLocation
      storeDescription: $storeDescription
      storeLogoUrl: $storeLogoUrl
      licenseUrl:  $licenseUrl
      fssaiNo: $fssaiNo
      storeCoordinates: $storeCoordinates
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeManagerPhone: $storeManagerPhone
      deliveryType: $deliveryType
      deliveryMaxDistance: $deliveryMaxDistance
      deliveryCharge: $deliveryCharge
      paymentSupported: $paymentSupported
      deliveryDays: $deliveryDays
      openingTime:$openingTime
      closingTime:$closingTime
      holidays:$holidays
      minAmount:$minAmount
      packingCharge:$packingCharge
      bankDetails:$bankDetails
      sameDayDelivery: $sameDayDelivery
      deliveryAfter:$deliveryAfter
    ) {
      id
      storeLocation
      storeWorking
      storeName
      sameDayDelivery
      deliveryAfter
      storeDescription
      storeManagerFirstName
      storeManagerLastName
      licenseUrl
      fssaiNo
      fssaiUrl
      openingTime
      closingTime
      bankDetails{
        bankAccountNumber
        accountHolderName
        IFSCcode
      }
      storeCoordinates {
        coordinates
      }
      storeLogoUrl
      deliveryType
      minAmount
      packingCharge
      deliveryMaxDistance
      deliveryCharge {
        kmRange
        charge
      }
      paymentSupported
      holidays
      deliveryDays {
        deliveryDay
        T9amTO12pm
        T12pmTO3pm
        T3pmTO6pm
        T6pmTO9pm
      }
      storeManagerMobile
      storeManagerPhone
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation ($retailerid: ID!, $password: String, $newPassword: String) {
    updateretailer(
      password: $password
      retailerid: $retailerid
      newPassword: $newPassword
    ) {
      id
      firstName
      lastName
      mobileNumber
      secondaryNumber
      emailAddress
      PANnumber
      GSTnumber
      stores {
        id
        storeLocation
        storeName
        storeDescription
        storeManagerFirstName
        storeManagerLastName
        storeCoordinates {
          coordinates
        }
        storeLogoUrl
        deliveryType
        deliveryMaxDistance
        deliveryCharge {
          kmRange
          charge
        }
        paymentSupported
        deliveryDays {
          deliveryDay
          T9amTO12pm
          T12pmTO3pm
          T3pmTO6pm
          T6pmTO9pm
        }
        storeManagerMobile
        storeManagerPhone
      }
    }
  }
`;
export const LOGOUT = gql`
  mutation ($token: String!, $retailerid: ID!) {
    logout(token: $token, retailerid: $retailerid)
  }
`;

export const ADD_PRODUCT = gql`
  mutation (
    $productname: String!
    $desc: String!
    $mfgname: String
    $brand: String
    $price: String!
    $barcode: String
    $promoprice: String
    $quantity: String
    $uom: String
    $image: ImagesInput
    $status: String!
    $foodType: foodTypeInput
    $displayrank: Int
    $storeid: ID!
    $salesdepartment: ID!
    $salescategory: ID
    $salescategory1: ID
    $salescategory2: ID
  ) {
    createSalesProducts(
      productname: $productname
      desc: $desc
      mfgname: $mfgname
      brand: $brand
      price: $price
      barcode: $barcode
      promoprice: $promoprice
      quantity: $quantity
      uom: $uom
      image: $image
      status: $status
      displayrank: $displayrank
      foodType: $foodType
      storeid: $storeid
      salesdepartment: $salesdepartment
      salescategory: $salescategory
      salescategory1: $salescategory1
      salescategory2: $salescategory2
    ) {
      id
      productname
      desc
      mfgname
      brand
      price
      promoprice
      uom
      quantity
      images
      image {
        primary
      }
      status
      barcode
    }
  }
`;
export const ADD_PRDT = gql`
  mutation (
    $productname: String!
    $desc: String!
    $mfgname: String
    $brand: String
    $price: String!
    $barcode: String
    $promoprice: String
    $quantity: String
    $uom: String
    $status: String!
    $displayrank: Int
    $foodType: foodTypeInput
    $storeid: ID!
    $salesdepartment: ID!
    $salescategory: ID
    $salescategory1: ID
    $salescategory2: ID
  ) {
    createSalesProducts(
      productname: $productname
      desc: $desc
      mfgname: $mfgname
      brand: $brand
      price: $price
      barcode: $barcode
      promoprice: $promoprice
      quantity: $quantity
      uom: $uom
      status: $status
      displayrank: $displayrank
      foodType: $foodType
      storeid: $storeid
      salesdepartment: $salesdepartment
      salescategory: $salescategory
      salescategory1: $salescategory1
      salescategory2: $salescategory2
    ) {
      id
      productname
      desc
      mfgname
      brand
      price
      promoprice
      uom
      foodType{
        id
      }
      quantity
      images
      image {
        primary
        secondary
        thumbnail
      }
      status
      barcode
    }
  }
`;
export const CREATE_HEIRARCHY = gql`
  mutation (
    $storeid: ID!
    $name: String!
    $displayrank: Int!
    $parentid: ID
    $status: String!
    $childCategoryPresent: Boolean!
  ) {
    createSalesHierarchy(
      storeid: $storeid
      name: $name
      displayrank: $displayrank
      parentid: $parentid
      status: $status
      childCategoryPresent: $childCategoryPresent
    ) {
      id
      name
      parentid {
        id
        name
      }
      storeid
      displayrank
      childCategoryPresent
    }
  }
`;

export const AADHAR_UPLOAD = gql`
mutation($file:Upload,$size:Int,$filename:String,$type:Int){
  imageEditAndUpload(file:$file,size:$size,filename:$filename,type:$type)
 {
   ETag
   Location
}
}
`

export const UPDATE_HEIRARCHY = gql`
  mutation (
    $hierarchyid: ID!
    $name: String
    $parentid: ID
    $status: String
    $displayrank: Int
    $childCategoryPresent: Boolean
  ) {
    updateSalesHierarchy(
      hierarchyid: $hierarchyid
      name: $name
      parentid: $parentid
      status: $status
      displayrank: $displayrank
      childCategoryPresent: $childCategoryPresent
    ) {
      id
      name
      parentid {
        id
        name
      }
      status
      storeid
      displayrank
      childCategoryPresent
    }
  }
`;

export const DELETE_S3 = gql`
  mutation ($fileName: String!) {
    deleteFromS3(fileName: $fileName)
  }
`;

export const SHOPASSISTANT_REGISTRATION = gql`
mutation($firstName:String!,$lastName:String!,$phoneNumber:String!,$storeid:ID!,$profilePicUrl:String!)
{
	createShopAssistant(firstName:$firstName,lastName:$lastName,phoneNumber:$phoneNumber,storeid:$storeid,profilePicUrl:$profilePicUrl)
	{
		id
    profilePicUrl
	}
}`;

export const EDIT_SHOPASSISTANT_REGISTRATION=gql`
mutation($shopAssistantId: ID $firstName: String, $lastName : String,$profilePicUrl:String){
  updateShopAssistant(shopAssistantId: $shopAssistantId,
  firstName : $firstName, lastName: $lastName,profilePicUrl:$profilePicUrl
  )
  {
    id
    firstName
    lastName
    phoneNumber
    profilePicUrl
  }
   }`;

export const DELETE_SHOPASSISTANT=gql`
mutation($shopAssistantId: ID!)
    {
    deleteShopAssistant(
      shopAssistantId: $shopAssistantId  )
      {
        firstName
        lastName
        phoneNumber    

      }
    }`;


export const SHOPASSISTANT_LIST = gql`
query($storeid:ID!){
  shopAssistantsList(storeid:$storeid){
    id
    firstName
    lastName
    phoneNumber
    profilePicUrl
  }
}`;

export const SHOPASSISTANT_ORDERS = gql`
query($shopAssistantId:ID!,$status:String)
{
  shopAssistantActiveOrdersList(shopAssistantId:$shopAssistantId,status:$status)
{
  id
  orderNumber
  storeid {
    id
  }
  shopAssistantId{
    id
    firstName
    }
    deliveryPartnerId{
    firstName
    }
  lastStatus
  stats{
    status
  }
  totalPrice
  deliveryAddress
  deliveryDate
  customerName
}
}`;


export const SHOPASSISTANT_COMPLETEDORDERS = gql`
query($shopAssistantId:ID!,$status:String!)
{
  shopAssistantCompletedOrdersList(shopAssistantId:$shopAssistantId,status:$status)
{
  count
items{
id
orderNumber
lastStatus
  stats{
    status
  }
  totalPrice
  deliveryAddress
  deliveryDate
  customerName
shopAssistantId{
id
firstName
}
deliveryPartnerId{
id
firstName
}
type
}
hasNext
}
}`;


export const ORDER_DETAILS = gql`
	query($orderid: ID!) {
		orderdetails(orderid: $orderid) {
			id
			orderNumber
			storeid {
				id
			}
			products {
        shopAssistantQuantity
        productPrice
				productid {
					productname
					images
          image{
            primary
          }
					desc
					price
          promoprice
					uom
          quantity
				}
				quantity
				price
			}
      lastStatus
			totalPrice
			deliveryAddress
			deliveryDate
			deliveryType
			deliveryTime
			mobileNumber
			specialInstructions
			commentsSeller
      estimatedDeliveryTime
			customerName
			stats {
				status
				created
			}
		}
	}
`;

export const REASSIGN_ORDERS = gql`
mutation($shopAssistantId:ID!,$orderId:String!)
{
  assignOrderToShopAssistant(shopAssistantId:$shopAssistantId,orderId:$orderId){
   id
 }
}`;

export const ASSIGN_DELIVERY_PARTNER =gql`
mutation($deliveryPartnerId:ID!,$orderId:ID!,$estimatedDeliveryTime:String!){
  assignOrderToDeliveryPartner(deliveryPartnerId:$deliveryPartnerId,orderId:$orderId,estimatedDeliveryTime:$estimatedDeliveryTime)
  {
    id
    shopAssistantId
    {
      id
    }
    deliveryPartnerId
    {
      id
    }
  }
}
`
export const STORE_WORKING = gql`
mutation($storeWorking: Boolean,$storeid:ID!) {
  updatestore(storeWorking:$storeWorking,storeid:$storeid){
  id
  storeWorking
}
}
`
export const STORE_WORKING_SUBSCRIPTION = gql`
mutation($storeWorking: Boolean,$storeid:ID!,$subscription:subscriptionInput!) {
  updatestore(storeWorking:$storeWorking,storeid:$storeid,subscription:$subscription){
  id
  storeWorking
}
}
`
export const Multiple_Upload = gql`
mutation($files:[Upload],$type:Int!,$storeid:ID!,$fileName:[String]){
  uploadMultipleFiles(files:$files,type:$type,storeid:$storeid,fileName:$fileName){
  ETag
  Location
  Key
  Bucket
  }
 }
 `
export const AlertOrders = gql`
query($storeid:ID!){
  alertOrders(storeid:$storeid){
  id
  seenBy
  notification
  orderid{
    orderNumber
    userid{
      firstName
      lastName
      
    }
    deliveryAddress
    notifications{
    message
    }
    }
  storeid{
  storeName
  }
  counter
  scheduledDateAndTime
  scheduledMinutes
  lastStatus
  updatedAt
  }
  }
`
 
export const CRITICAL_ORDERS =gql`
query($storeid:ID){
  criticalOrders(storeid:$storeid){
  id
  orderid{
    id
    lastStatus
  orderNumber
  totalPrice
  productCount
  paymentStatus
  shopAssistantId{
    id
  }
  deliveryPartnerId{
    id
  }
  products{
    productid{
      productname
    }
  }
  stats{
    status
    created
  }
  userid{
    firstName
    lastName
    phoneNumber
  }
  deliveryAddress
  deliveryDate
  deliveryTime
  notifications{
  message
  }
  }
  storeid{
    storeName
    storeLocation
    storeManagerMobile
  }
  counter
  scheduledDateAndTime
  scheduledMinutes
  lastStatus
  updatedAt
  }
  }
`
export const NEW_UPLOAD =  gql`
mutation($file:Upload,$size:Int,$filename:String,$type:Int,$storeid:String){
  imageEditAndUpload(file:$file,size:$size,filename:$filename,type:$type,storeid:$storeid)
 {
   ETag
   Location
}
}
`
export const ORDER_NEW_UPLOAD =  gql`
mutation($file:Upload,$size:Int,$filename:String,$type:Int,$orderid:String){
  imageEditAndUpload(file:$file,size:$size,filename:$filename,type:$type,orderid:$orderid)
 {
   ETag
   Location
}
}
`





// export const AADHAR_UPLOAD = gql`
// mutation($file:Upload,$size:Int,$filename:String,$type:Int){
//   imageEditAndUpload(file:$file,size:$size,filename:$filename,type:$type)
//  {
//    ETag
//    Location
// }
// }
// `





export const OrderNotification=gql`
query($storeid:ID!){
  alertOrders(storeid:$storeid){
    id
    orderid{
      id
      lastStatus
    }
   orderNumber
   notification
   seenBy
 }  
 }`


// export const OrderNotificationSeen=gql`

// query($userid:ID!){
//   getOrderNotifications(userid:$userid){
//   id
//   message
//   seenBy
//   created
//   }
//   }
//`



 
 export const Dashboard =gql`
 query($storeid:ID!){
   dashboard(storeid:$storeid){
    totalCustomers
    totalAmountRecieved
    totalOrdersRecieved
    totalOrdersPlaced
    totalOrdersAccepted
    totalOrdersShopping
    totalOrdersReady
    totalOrdersCompleted
    totalOrdersCancelled
    totalOrdersRejected
    popularProduct1
    popularProductImg1
    popularProduct2
    popularProductImg2
    popularProduct3
    popularProductImg3
    popularProduct4
    popularProductImg4
    popularProduct5
    popularProductImg5
   }
 }
 `

// export const newDashboard =gql`
// query($storeid : ID!){
//   dashboardNew(storeid : $storeid){
//       type
//       totalIncome
//       totalOrders
//       totalProducts
// totalInactiveProducts
// totalPayout
// newOrders
// activeOrders
// readyOrders
// completedOrders
// cancelledOrders
// rejectedOrders
// totalCoupons
// topSellingProducts{
// productId{
// id
// productname
// image{
// primary
// secondary
// }
// }
// }


// storeid
// }
// }
//  `
export const newDashboard =gql`
 mutation($storeId:ID) {
  getDashboardNew(storeId:$storeId){
  type
 totalIncome
 totalOrders
 totalProducts
totalInactiveProducts
totalPayout
newOrders
activeOrders
readyOrders
completedOrders
cancelledOrders
rejectedOrders
totalCoupons
topSellingProducts{
productId{
id
productname
image{
primary

}
}
}
storeid
storeName
storeLogoUrl
pastWeekOrderDetails{
  Monday
  Tuesday
  Wednesday
  Thursday
  Friday
  Saturday
  Sunday
  }
 }
 }`

 export const GST_FILEUPLOAD = gql`
mutation($file:Upload,$size:Int,$filename:String,$type:Int){
	imageEditAndUpload(file:$file,size:$size,filename:$filename,type:$type)
   {
	 ETag
	 Location
  }
  }
`

export const GET_BUSINESSTYPES = gql`
query{
  businessTypeListing{
    id
    name
    iconImage
  }
  }`

export const CATALOG_DEPARTMENTS = gql`
query($businessTypeId:Int){
  departmentWiseListing(businessTypeId:$businessTypeId)
    {
      id
      name
      displayrank
      
    }
  }`

export const GET_MASTER_DEPARTMENTS = gql`
query($businessTypeId:Int,$storeId:ID!){
  departmentWiseListing(businessTypeId:$businessTypeId){
    id
    name
    parentid
    status
    childCategoryPresent
    displayrank
    departmentProductsCount
    salesDepartmentProductsCount(storeId:$storeId)
  }
  }`
  

export const GET_MASTER_CATEGORY = gql`
query($departmentid:ID!,$storeId:ID!){
  masterCategories(departmentid:$departmentid){
    id
    name
    parentid
    status
    childCategoryPresent
    displayrank
    categoryProductsCount
    salesCategoryProductsCount(storeId:$storeId)
  }
  }`

  export const GET_MASTER_PRODUCTS = gql`
  query($storeid:ID!,$categoryid:ID!,$pagination:PaginationArg){
    masterCategoryProducts(storeid:$storeid,categoryid:$categoryid,pagination:$pagination){
      id
    productname
      desc
      mfgname
      brand
      price
      promoprice
      quantity
      uom
      isAdded
      image{
        primary
        secondary
      }
    }
    }`

  export const ADD_CATALOG_PRODUCTS = gql`
  mutation($departmentName:String!,
  $categoryName:String!,
  $storeid:ID!,
  $productid:[ID!]){
    addCatalogProducts(departmentName:$departmentName,
    categoryName:$categoryName,
    storeid:$storeid,
    productid:$productid)
  }`


  export const LAST_SEEN_CHANGE = gql`
  mutation($alertIds:[ID]!){
  seenAlertStatus(alertIds: $alertIds)
  }
  `
  
  
  
  export const createPercentageCoupon = gql`
  mutation(
  $type:Int!,
  $couponCode:String!,
  $custUseCount:Int!,
  $minPurAmt:Float!,
  $startDate:String!,
  $endDate:String!,
  
  $disAmt:Float!,
  $discountPercent:Float! 
  $storeid:ID!){
    createCoupon
    (
    type:$type,
    couponCode:$couponCode,
    custUseCount:$custUseCount,
    minPurAmt:$minPurAmt,
    startDate:$startDate,
    endDate:$endDate,
    
    disAmt:$disAmt,
    discountPercent:$discountPercent
    storeid:$storeid)
  }`
  
  export const createFlatCoupon = gql`
  mutation(
  $type:Int!,
  $couponCode:String!,
  $custUseCount:Int!,
  $minPurAmt:Float!,
  $startDate:String!,
  $endDate:String!,
  $disAmt:Float!,
  
  $storeid:ID!){
    createCoupon
    (
    type:$type,
    couponCode:$couponCode,
    custUseCount:$custUseCount,
    minPurAmt:$minPurAmt,
    startDate:$startDate,
    endDate:$endDate,
    disAmt:$disAmt,
    
    storeid:$storeid)
  }`
  export const createDeliveryFreeCoupon = gql`
  mutation(
  $type:Int!,
  $couponCode:String!,
  $custUseCount:Int!,,
  $startDate:String!,
  $endDate:String!,
  $minPurAmt:Float!,
  $storeid:ID!){
    createCoupon
    (
    type:$type,
    couponCode:$couponCode,
    custUseCount:$custUseCount,
    minPurAmt:$minPurAmt,
    startDate:$startDate,
    endDate:$endDate,
    
    storeid:$storeid)
  }`
  export const createBXGYCoupon = gql`
  mutation(
  $type:Int!,
  $couponCode:String!,
  $custUseCount:Int!,,
  $startDate:String!,
  $endDate:String!,
  $storeid:ID!
  $getItemNo:Int,
  $buyItemNo:Int,
  $productIds:[ID],
  $categoryId:[ID],
  $allProducts:Boolean){
    createCoupon
    (
    type:$type,
    couponCode:$couponCode,
    custUseCount:$custUseCount,
    startDate:$startDate,
    endDate:$endDate,
    storeid:$storeid,
    getItemNo:$getItemNo,
    buyItemNo:$buyItemNo,
    productIds:$productIds,
    categoryId:$categoryId,
    allProducts:$allProducts)
  }`

  export const LISTCOUPONS = gql`
  query{
    listAllCoupons{
      id
      type
      name
      couponCode
      custUseCount
      totalCustUseCount
      discountPercent
      minPurAmt
      disAmt
      buyItemNo
      getItemNo
      appliedCount
      description
      status
    }
  }`

  export const LISTUSERCOUPONS = gql`
  query{
    listUserSpecificCoupons{
      id
      type
      name
      couponCode
      custUseCount
      totalCustUseCount
      discountPercent
      minPurAmt
      disAmt
      endDate
      description
      status
    }
  }`

export const LISTALLCOUPONS =gql`
query($storeid:ID!){
  listAllCoupons(storeid:$storeid){
    id
    type
    name
    couponCode
    custUseCount
    totalCustUseCount
    discountPercent
    minPurAmt
    buyItemNo
    getItemNo
    appliedCount
    disAmt
    endDate
    startDate
    description
    status
  }
}`
export const LISTALLUPCOMINGCOUPONS=gql`
query($storeid:ID!){

  listAllUpcomingCoupons(storeid:$storeid){    
  
  id    
  
  type
  
  name
  
  couponCode
  
  custUseCount
  
  totalCustUseCount
  
  discountPercent
  
  minPurAmt
  
  buyItemNo
  
  getItemNo
  
  appliedCount
  
  disAmt
  
  endDate
  
  startDate
  description
  businessType
  status
  
  }
}`
  
export const LISTALLONGOINGCOUPONS=gql`
query($storeid:ID!){

  listAllOngoingCoupons(storeid:$storeid){    
  
  id    
  
  type
  
  name
  
  couponCode
  
  custUseCount
  
  totalCustUseCount
  
  discountPercent
  
  minPurAmt
  
  buyItemNo
  
  getItemNo
  
  appliedCount
  
  disAmt
  
  endDate
  startDate
  
  description
  
  status
  businessType
  }
}
`




export const ASSIGN_COUPON = gql`
mutation($couponId:ID!,$userId:ID!,$userCouponCount:Int!){
  assignCouponToUser(couponId:$couponId,userId:$userId,userCouponCount:$userCouponCount)
}
`
export const EDIT_COUPON = gql`
mutation($couponId:ID!,$status:String!){
  editCoupon(couponId:$couponId,status:$status){
    id
    type
    name
    couponCode
    status
    isUserDependent
  }
}`

export const BXGY_PRODUCTS = gql`
query($storeid:ID!,$pagination:PaginationArg){
  bxgyProductsListing(storeid:$storeid,pagination:$pagination){
    count
    items{
      id
      productname
      price
      image{
        primary
      }
      desc
    }
    hasNext
  }
}
`
export const GETLINK = gql`
mutation($storeid:ID){
  shareMyLink(storeid:$storeid) 
}`
export const ALL_ORDERS_COUNT = gql`
query($storeid:ID){
getOrderStatusCount(storeid:$storeid){
activeOrders
newOrders
readyOrders

}
}
`
export const NEWORDERS_LIST = gql`
query ($storeid: ID!) {
    newOrdersList(storeid: $storeid) {
      id
      orderNumber
      storeid {
        id
        storeName
      }
      userid {
        firstName
        lastName
        address {
          id
          type
          houseNumber
          landmark
          locationDescription
        }
      }
      deliveryAddress
      deliveryDate
      deliveryTime
      products {
        id
        productid {
          id
          productname
        }
        productPrice
        quantity
        shopAssistantQuantity
        price
        status
      }
      stats {
        status

        created
        createdTime
      }
      total
      totalPayable
    }
  }

`;
export const ACTIVEORDERS_LIST = gql`
query($storeid : ID!){
  activeOrdersList(storeid : $storeid){
    id
    orderNumber
  lastStatus
    totalPrice
    estimatedDeliveryTime
    userid{
      firstName
      lastName
      address{
        houseNumber
        locationDescription
        
      }
    }
    deliveryDate
    deliveryTime
    products{
      id
      productid{
        id
        productname
        price
        quantity
         image{
          primary
          secondary
        }
        images
      
      }
      shopAssistantQuantity
      productPrice
      quantity
      price
      status
    }
    total
    totalPrice
    deliveryCharge
    deliveryAddress
    totalPriceDelivery
    discountPrice
    couponId
    couponID{
      couponCode
      disAmt
    
    }
    packingCharge
    totalPriceUser
    paymentStatus
    preparationTime
    stats{
    status
      created
      createdTime
      
    }
    
  }
}

`
export const READYORDERS_LIST = gql`
query($storeid : ID!){
  readyOrdersList(storeid : $storeid){
    id
    orderNumber
  lastStatus
    totalPrice
    estimatedDeliveryTime
    userid{
      firstName
      lastName
      address{
        houseNumber
        locationDescription
        
      }
    }
    deliveryDate
    deliveryTime
    products{
      id
      productid{
        productname
        price
        quantity
         image{
          primary
          secondary
        }
        images
      
      }
      shopAssistantQuantity
      productPrice
      quantity
      price
      status
    }
    total
    totalPrice
    deliveryCharge
    deliveryAddress
    totalPriceDelivery
    discountPrice
    couponId
    couponID{
      couponCode
      disAmt
    
    }
    packingCharge
    totalPriceUser
    paymentStatus
    preparationTime
    stats{
    status
      created
      createdTime
      
    }
    travelTime{
      text
      value
    }
    travelDistance{
      text
      value
    }
  }
}

`

export const OUTFORDELIVERY_LIST = gql`
query($storeid : ID!){
  outOfDeliveryList(storeid : $storeid){
    id
    orderNumber
  lastStatus
    totalPrice
    estimatedDeliveryTime
    userid{
      firstName
      lastName
      address{
        houseNumber
        locationDescription
        
      }
    }
    deliveryDate
    deliveryTime
    products{
      id
      productid{
        productname
        price
        quantity
         image{
          primary
          secondary
        }
        images
      
      }
      shopAssistantQuantity
      productPrice
      quantity
      price
      status
    }
    total
    totalPrice
    deliveryCharge
    deliveryAddress
    totalPriceDelivery
    discountPrice
    couponId
    couponID{
      couponCode
      disAmt
    
    }
    packingCharge
    totalPriceUser
    paymentStatus
    preparationTime
    stats{
    status
      created
      createdTime
      
    }
    travelTime{
      text
      value
    }
    travelDistance{
      text
      value
    }
  }
}

`
export const COMPLETEDORDERS_LIST = gql`
query($storeid:ID ,$pagination: PaginationArg){
  completedOrdersList(storeid:$storeid,pagination:$pagination){
   hasNext
   count
   items{
    id
    orderNumber
    userid
   {
    firstName
    lastName
    address{
      id
      type
      houseNumber
      landmark
      locationDescription
      
    }
   }

couponID{
couponCode
disAmt

}

   deliveryAddress
   deliveryDate
   deliveryTime
    products{
      id
      productid
      {
        id
        productname
      }
      productPrice
      quantity
      shopAssistantQuantity
      price
      status
      
    }
shopAssistantId {
firstName
lastName
}
    stats{
      status
      created
      createdTime
    
      
      
    }
    total
    totalPrice
    totalPayable
    deliveryCharge
    totalPriceUser
    packingCharge
    handlingCharge

    
  }
  }
}

`;
export const ORDER_COUNT = gql`
query($storeid:ID! ){
outReadyOrdersCount(storeid:$storeid)
}
`






// export const COMPLETEDORDERS_LIST = gql`
// query(
//   $pagination: PaginationArg
//   $storeid: ID!
// )  {
//    count
//    items{
//    commentsSeller
//    orderNumber
//     storeid {
//       id
//     }
//   stats{
//      status
//      created
//   }
//   deliveryDate
//   deliveryTime
//   mobileNumber
//   customerName
//   specialInstructions
//   lastStatus
//   id
//   }
//   hasNext
// }
// `;