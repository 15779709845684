import React,{useState} from "react";
import { useFormik } from 'formik';
import {  storevalidationSchema } from './validation';
import { Row, Col } from "react-bootstrap";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditImageField from "../EditImageLogo";
import TextField from "@mui/material/TextField";
import MapEditField from "../EditMapLocation";
import EditLocation from "../EditLocation";
import ImageEditWindow from "../ImageEditModal";
import MapEditModal from "../MapEditModal";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import Card from "@mui/material/Card";
// import { makeStyles } from "@mui/styles";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import client from "../../Configurations/apollo";
import {EDIT_STORE_MUTATION} from './Query';
import { Alert } from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import AlertTitle from '@mui/material/AlertTitle';
import { Container } from "@mui/material";
import { useMediaQuery } from 'react-responsive';
import {NoImage}  from '../../utils/image';
import MenuItem from '@mui/material/MenuItem';
import BusinessType from './BusinessType';
import BusinessTypeDisable from "./BusinessTypeDisable";
import "../MyAccountForm/index.css";

// const useStyles = makeStyles({
// 	root: {
// 		maxWidth:200,
// 	},
// 	media: {
// 		height: 200,
// 	},
// });


const Business = [

    {
      id: 1,
      name: "Groceries & Essentials",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Groceries+%26+Essentials.png"
    },
    {
      id: 2,
      name: "Restaurants & Bakery",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Restaurant+%26+bakery.jpg"
    },
    {
      id: 3,
      name: "Fish & Meat",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Meat+%26+Fish.jpg"
    },
    {
      id: 4,
      name: "Fruits & Vegetables",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Fruits+%26+Vegetables.png"
    },
    {
      id: 5,
      name: "Medicines",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Medicines.webp"
    },
    {
      id: 6,
      name: "Electronics & Home Appliances",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Electronics+%26+Home+Appliances.jpg"
    },
    {
      id: 7,
      name: "Lifestyle & Fashion",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Lifestyle+%26+Fashion.jpg"
    },
    {
      id: 8,
      name: "Sports & Fitness",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/sports+%26+fitness.jpg"
    },
    {
      id: 9,
      name: "Pet Care",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Pet+Care.jpg"
    },
    {
      id: 10,
      name: "Home decor & Furnishing",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+decor+%26+Furnishing.png"
    },
    {
      id: 11,
      name: "Home Business",
      coverImage: "imageUrl",
      iconImage: "https://localcommerce.s3.ap-south-1.amazonaws.com/businessType/Home+Business.jpg"
    }
  ];
  
  

const StoreEdit = (props) => {
	const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' });
	document.title ="Seller Portal | My Store"; 
	function Alert(props) {
		return <Alert elevation={6} variant="filled" {...props} />;
	}
	// const classes = useStyles();
	React.useEffect(()=>{
		// document.body.scrollTop = 0;
		props.setBusinessType(props.retailerDetails&&props.retailerDetails.data&&props.retailerDetails.data.retailerdetails
			&&props.retailerDetails.data.retailerdetails.stores[0]&&props.retailerDetails.data.retailerdetails.stores[0].businessTypeId)
	}
	,[])

	const [edit, setEdit] = useState(false);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [BusinessError, setBError] = useState(false);
	// const [errorMessage,setErrorMessage] = useState("");
	const [net, setNet] = useState(false);
	// const [netMessage,setNetmsg] = useState("");
	const [buisnessType, setBuisnessType] = React.useState(props.retailerDetails&&props.retailerDetails.data
		&&props.retailerDetails.data.retailerdetails&&
		props.retailerDetails.data.retailerdetails.stores[0]["buisnessType"]);
	


	const handleBuisnessType = (event) => {
		buisnessType(event.target.value);
	};
	const handleEdit = () =>
	{
		setEdit(!edit)
	}

	const  theme = createTheme({
		palette: {
		  primary: green,
		},
		overrides: {
		  MuiInputBase: {
			input: {
			  '&:-webkit-autofill': {
				transitionDelay: '9999s',
				transitionProperty: 'background-color, color',
			  },
			},
		  },
		},
	})

	const [state, setState] = React.useState({
		mapEditModal: false,
		imageEditModal: false,
		
	});
	const token = localStorage.getItem('token');
	let { deliveryDays, deliveryCharge,storeCoordinates} =props.retailerDetails&&
										props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										props.retailerDetails.data.retailerdetails.stores[0]&&props.retailerDetails.data.retailerdetails.stores[0];
	delete storeCoordinates.__typename;
	let deliveryRate = deliveryCharge.map((rate) => ({
		kmRange: rate.kmRange,
		charge: rate.charge,
	}));
	let deliverDay = deliveryDays.map((day) => ({
		
		T9amTO12pm:`${day.T9amTO12pm}`,
		T12pmTO3pm:`${day.T12pmTO3pm}`,
		T3pmTO6pm:`${day.T3pmTO6pm}`,
		T6pmTO9pm:`${day.T6pmTO9pm}`,
		deliveryDay:`${day.deliveryDay}`,
	}));
	const storeformik = useFormik({
		initialValues: {
		  storeName:props.retailerDetails&&props.retailerDetails.data
		  			&&props.retailerDetails.data.retailerdetails&&
					  props.retailerDetails.data.retailerdetails.stores[0]["storeName"],
		  storeDescription:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0]["storeDescription"],
		  storeManagerFirstName:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0]["storeManagerFirstName"],
		  storeManagerLastName:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0]["storeManagerLastName"],
		  storeManagerPhone:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0]["storeManagerPhone"]==null?"":
		          props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0]["storeManagerPhone"],
		  storeManagerMobile:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0]["storeManagerMobile"],
		  bankAccountNumber:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["bankAccountNumber"]==null?"":
		  props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["bankAccountNumber"],
		  IFSCcode:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["IFSCcode"]==null?""
		  :props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["IFSCcode"],
		  accountHolderName:props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["accountHolderName"]==null?"":
		  props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["accountHolderName"],
		  buisnessType:props.retailerDetails&&props.retailerDetails.data
		  &&props.retailerDetails.data.retailerdetails&&
		  props.retailerDetails.data.retailerdetails.stores[0].businessType
		},
		validationSchema: storevalidationSchema,
		onSubmit: (values) => {
			// props.editStore(props.retailerDetails.data.retailerdetails.stores[0],values)
			
			if(props.fileListLogo===true&&!props.newSignedLogoUrl){
				Modal.confirm({
					title: 'Warning',
					icon: <ExclamationCircleOutlined />,
					content: 'Please upload the image or delete the image before saving',
					okText: 'Ok',
				  });
			}
			else if(props.businessId.length===0)
			{
				setBError(true)
			}
			else if(values.storeManagerMobile===values.storeManagerPhone)
			{
			  storeformik.setFieldError('storeManagerPhone','Please provide different contact number');
			}
			else if(props.newSignedLogoUrl!==null){
				props.Delete_Logo(props.retailerDetails&&props.retailerDetails.data&&
					props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl);
					
						client
						.mutate({
							mutation: EDIT_STORE_MUTATION,
							variables: {
								storeid: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].id,
								storeName: values.storeName,
								storeLocation: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].storeLocation,
								storeDescription: values.storeDescription,
								storeLogoUrl: props.newSignedLogoUrl!==""?props.newSignedLogoUrl:props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl,
								storeCoordinates:{
									coordinates:[Number(storeCoordinates.coordinates[0]),
									Number(storeCoordinates.coordinates[1])]
									},
								storeManagerFirstName: values.storeManagerFirstName,
								storeManagerLastName: values.storeManagerLastName,
								storeManagerMobile: values.storeManagerMobile,
								storeManagerPhone: values.storeManagerPhone===""?" ":values.storeManagerPhone,
								deliveryType: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryType,
								
								paymentSupported: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].paymentSupported,
								deliveryDays: deliverDay,
								bankDetails:{bankAccountNumber:values.bankAccountNumber,accountHolderName:values.accountHolderName,IFSCcode:values.IFSCcode},
								businessTypeId:props.businessId,
								sameDayDelivery:values.buisnessType==="Home Buisness"?props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].sameDayDelivery:true,
     							deliveryAfter:values.buisnessType==="Home Buisness"?props.retailerDetails&&props.retailerDetails.data&&
								 props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryAfter:null
							},
							context: {
								headers: {
								Authorization:'Bearer ' + token
								}
							},
						})
						.then((res)=>{
							if(res.data){
								setEdit(!edit);
								setOpen(true)
								props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
							}
							else{
								setError(true);
							}
						})
							.catch((err) => {
								setNet(true);
								// setNetmsg(err)
							})
			
			// 
			}
			else{
				client
						.mutate({
							mutation: EDIT_STORE_MUTATION,
							variables: {
								storeid: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].id,
								storeName: values.storeName,
								storeLocation: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].storeLocation,
								storeDescription: values.storeDescription,
								storeLogoUrl: props.newSignedUrl!==""?props.newSignedUrl:props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl,
								storeCoordinates:{
									coordinates:[Number(storeCoordinates.coordinates[0]),
									Number(storeCoordinates.coordinates[1])]
									},
								storeManagerFirstName: values.storeManagerFirstName,
								storeManagerLastName: values.storeManagerLastName,
								storeManagerMobile: values.storeManagerMobile,
								storeManagerPhone: values.storeManagerPhone===""?" ":values.storeManagerPhone,
								deliveryType: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryType,
								
								paymentSupported: props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].paymentSupported,
								deliveryDays: deliverDay,
								bankDetails:{bankAccountNumber:values.bankAccountNumber,accountHolderName:values.accountHolderName,IFSCcode:values.IFSCcode},
								businessTypeId:props.businessId,
								sameDayDelivery:values.buisnessType==="Home Buisness"?props.retailerDetails&&props.retailerDetails.data&&
								props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].sameDayDelivery:true,
     							deliveryAfter:values.buisnessType==="Home Buisness"?props.retailerDetails&&props.retailerDetails.data&&
								 props.retailerDetails.data.retailerdetails&&props.retailerDetails.data.retailerdetails.stores[0].deliveryAfter:null

							},
							context: {
								headers: {
								Authorization:'Bearer ' + token
								}
							},
						})
						.then((res)=>{
							if(res.data){
								setEdit(!edit);
								setOpen(true)
								props.getSellerDetail(`${localStorage.getItem('retailerid')}`);
							}
							else{
								setError(true);
							}
						})
							.catch((err) => {
								setNet(true);
							})
			}
		},
	  });

	const handleImageEdit = () => {
		setState({ imageEditModal: !state.imageEditModal });
		props.newSignedUrl &&
			props.postNewUrl({
				url: props.newSignedUrl,
				details: props.retailerDetails.data.retailerdetails,
			});
	};
	const mapEditModal = () => {
		setState({ ...state, mapEditModal: !state.mapEditModal });
	};
	
	return(
		isSmallScreen?
		<Container maxWidth="md" style={{  
			backgroundColor: "white",
        border: "1px solid rgba(0, 0, 0, 0.16)",
        borderRadius: "10px",
        Top: "40px",
        padding: "30px",
		width:'76vw'
			}}>
		<div style={{   paddingBottom: '10px',fontWeight:600 }}>
            <h5  style={{  fontWeight:800,textAlign:'center',fontSize:"23px" }}>
				{'Store Details'}
			</h5>
			
			{open ? (
								<Snackbar
									style={{ width: "100%", bottom: "50%", left:"50%" }}
									open={open}
									autoHideDuration={2000}
									message={!edit
										? "Changes have been saved successfully"
										:  "Edit Unsuccessful"
										}
									onClose={() => setOpen(false)}>
									
								</Snackbar>
							) : null}
								{error||net ? (
								<Snackbar
									style={{ width: "fit-content", left: "50%", bottom: "50%" }}
									open={error||net}
									autoHideDuration={2000}
									message={error?`${'Edit Unsuccessful'}`
									:
									net?`${'Network Error'}`
									:null}
									onClose={() => {setError(false);
									setNet(false)}}>
									
								</Snackbar>
							) : null}
			{edit === false ?
					(
						<>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Store Name</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeName}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
									<label className="labl">Store Description</label>
										<h6 style={{wordBreak:"break-all",textAlign:"justify",margin:0,padding:0}}><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeDescription}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Store Manager First Name</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerFirstName}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Store Manager Last Name</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerLastName}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Store Manager Phone Number </label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerMobile}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Store Manager Alternate Contact Number</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerPhone}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Bank Account Number </label>
									
										<h6><b>{props.retailerDetails&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["bankAccountNumber"]
										&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails.bankAccountNumber.replace(/\d(?=\d{4})/g, "*")}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl"> Account Holder Name </label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].bankDetails.accountHolderName}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl"> IFSC Code</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].bankDetails.IFSCcode}</b></h6>
									</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Store Location</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeLocation}</b></h6>
									</Col>
								</Row>
								<Row >
								
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
										<label className="labl">Store Logo</label>
										<Card 
										// className={classes.root}
										>
										<CardActionArea>
											<CardMedia
												// className={classes.media}
												image={props.retailerDetails&&
													props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
													props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl===null?
													NoImage
												:
												props.retailerDetails&&
													props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
													props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl
											}
												title="Store Logo"
											/>
											<CardContent></CardContent>
										</CardActionArea>
										</Card>
									</Col>
									<Col xl={6} lg={6} sm={12} xs={12} style={{ paddingTop: 20, paddingLeft: '3%' }}>

									<label className="labl"> Buisness Type</label>
										<BusinessTypeDisable  {...props}/>
									</Col>		
								</Row>
								<Row style={{ marginTop: "70px",justifyContent:'center',alignItems:'center',textAlign:'center' }}>
								<Col>
									<button
										style={{
											justifyContent:' center',
											color: 'rgb(255, 255, 255)',
											backgroundColor:'rgb(137, 199, 74, 1)',
											boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
											// float:'right',
											height: '37px',
											fontSize: '16px',
											fontWeight: '600',
											borderRadius: '5px',
											borderWidth: '1px',
											borderStyle:' solid',
											borderColor: 'transparent',
											borderImage: 'initial',
											overflow: 'hidden',
											outline: 'none',
											minHeight:' 14px',
											fontFamily: 'Varela Round, sans-serif',
											width:'103px'
											}}
										type="button"
									onClick={handleEdit}
									>
										Edit
						</button>
								</Col>
							</Row>
								</>
					):(
				<form onSubmit={storeformik.handleSubmit} style={{width:'100%'}}>
					{state.imageEditModal ? (
					<ImageEditWindow {...props} handleImageEdit={handleImageEdit} />
				) : (null)}
				{state.mapEditModal ? (
					<MapEditModal {...props} mapEditModal={mapEditModal} />
				) : (null)}
				
						
						<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>						
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Name *"
										name="storeName"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeName && Boolean(storeformik.errors.storeName)}
										helperText={storeformik.touched.storeName && storeformik.errors.storeName}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Description *"
										name="storeDescription"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeDescription}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeDescription && Boolean(storeformik.errors.storeDescription)}
										helperText={storeformik.touched.storeDescription && storeformik.errors.storeDescription}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manger First Name *"
										name="storeManagerFirstName"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerFirstName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeManagerFirstName && Boolean(storeformik.errors.storeManagerFirstName)}
										helperText={storeformik.touched.storeManagerFirstName && storeformik.errors.storeManagerFirstName}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manager Last Name *"
										name="storeManagerLastName"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerLastName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeManagerLastName && Boolean(storeformik.errors.storeManagerLastName)}
										helperText={storeformik.touched.storeManagerLastName && storeformik.errors.storeManagerLastName}
									/>
									</ThemeProvider>
								</Col>
								
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manager Mobile Number *"
										name="storeManagerMobile"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerMobile}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeManagerMobile && Boolean(storeformik.errors.storeManagerMobile)}
										helperText={storeformik.touched.storeManagerMobile && storeformik.errors.storeManagerMobile}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manager Alternate Contact Number"
										name="storeManagerPhone"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerPhone&&storeformik.values.storeManagerPhone}
										fullWidth
										InputLabelProps={{
											shrink: true,
											style:{width:'100%',whiteSpace:'nowrap'}
										}}
										error={storeformik.touched.storeManagerPhone && Boolean(storeformik.errors.storeManagerPhone)}
										helperText={storeformik.touched.storeManagerPhone && storeformik.errors.storeManagerPhone}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
								<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Bank Account Number"
										name="bankAccountNumber"
										onChange={storeformik.handleChange}
										value={storeformik.values.bankAccountNumber&&storeformik.values.bankAccountNumber}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.bankAccountNumber && Boolean(storeformik.errors.bankAccountNumber)}
										helperText={storeformik.touched.bankAccountNumber && storeformik.errors.bankAccountNumber}
									/>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Account Holder Name"
										name="accountHolderName"
										onChange={storeformik.handleChange}
										value={storeformik.values.accountHolderName&&storeformik.values.accountHolderName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.accountHolderName&& Boolean(storeformik.errors.accountHolderName)}
										helperText={storeformik.touched.accountHolderName && storeformik.errors.accountHolderName}
									/>
										
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20}}>
								<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="IFSC Code"
										name="IFSCcode"
										onChange={storeformik.handleChange}
										value={storeformik.values.IFSCcode&&storeformik.values.IFSCcode}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.IFSCcode&& Boolean(storeformik.errors.IFSCcode)}
										helperText={storeformik.touched.IFSCcode && storeformik.errors.IFSCcode}
									/>
									</Col>
									<Col md={6} sm={12} xs={12} style={{ paddingTop: 20}}>
										<BusinessType {...props}/>
										{BusinessError===true?<p style={{color:'red'}}>Business type is required</p>:props.businessId.length>0&&null}
										</Col>
									
						</Row>
						<EditLocation {...props} />
						<MapEditField {...props} mapEditModal={mapEditModal} />
						<Grid item xs={12} style={{ paddingTop: 50 }}>
							<EditImageField {...props} handleImageEdit={handleImageEdit} />
						</Grid>
						
				
					<Row style={{ marginTop: "70px",justifyContent:'center',alignItems:'center',textAlign:'center' }}>
					<Col>
						<button
						 style={{
							justifyContent:' center',
							color: 'rgb(255, 255, 255)',
							backgroundColor:'rgb(137, 199, 74, 1)',
							boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
							height: '37px',
							fontSize: '16px',
							fontWeight: '600',
							borderRadius: '5px',
							borderWidth: '1px',
							borderStyle:' solid',
							borderColor: 'transparent',
							borderImage: 'initial',
							overflow: 'hidden',
							outline: 'none',
							minHeight:' 14px',
							fontFamily: 'Varela Round, sans-serif',
							width:'103px'
							}}
							type="submit"
							variant="success">
							Save
						</button>
					</Col>
				</Row>
				</form>
				)}
			</div>
		</Container>



:


<Container maxWidth="md" style={{  
			backgroundColor: "white",
        border: "1px solid rgba(0, 0, 0, 0.16)",
        borderRadius: "10px",
        Top: "40px",
        padding: "30px",
			}}>
		<div style={{   paddingBottom: '10px',fontWeight:600 }}>
            <h5  style={{  fontWeight:800,textAlign:'center',fontSize:"23px" }}>
				{'Store Details'}
			</h5>
			{/* <GetUrl location={ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeLocation}
				    name={ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
						props.retailerDetails.data.retailerdetails.stores&&
						props.retailerDetails.data.retailerdetails.stores[0].storeName}
						{...props}
			/> */}
			{open ? (
								<Snackbar
									style={{ width: "100%", bottom: "50%" ,left:'50%'  }}
									open={open}
									autoHideDuration={2000}
									message={!edit
										? "Changes have been saved successfully"
										:  "Edit Unsuccessful"
										}
									onClose={() => setOpen(false)}>
									{/* <Alert
										severity={!edit ? "success" : "error"}>
										<AlertTitle>
											{!edit
												? "Changes have been saved successfully"
												:  "Edit Unsuccessful"
												}
										</AlertTitle>
									</Alert> */}
								</Snackbar>
							) : null}
								{error||net ? (
								<Snackbar
									style={{ width: "fit-content", left: "50%", bottom: "50%" }}
									open={error||net}
									autoHideDuration={2000}
									message={error?`${'Edit Unsuccessful'}`
									:
									net?`${'Network Error'}`
									:null}
									onClose={() => {setError(false);
									setNet(false)}}>
									{/* <Alert
										severity={ "error"}>
										<AlertTitle>
											{error?`${'Edit Unsuccessful'}`
											:
											net?`${'Network Error'}`
											:null}
												
										</AlertTitle>
									</Alert> */}
								</Snackbar>
							) : null}
			{edit === false ?
					(
						<>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20,paddingLeft:'10%' }}>
										<label className="labl">Store Name</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeName}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20,paddingLeft:'10%' }}>
									<label className="labl">Store Description</label>
										<h6 style={{wordBreak:"break-all",textAlign:"justify",margin:0,padding:0}}><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeDescription}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20,paddingLeft:'10%' }}>
										<label className="labl">Store Manager First Name</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerFirstName}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20,paddingLeft:'10%' }}>
										<label className="labl">Store Manager Last Name</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerLastName}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 ,paddingLeft:'10%'}}>
										<label className="labl">Store Manager Phone Number </label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerMobile}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 ,paddingLeft:'10%'}}>
										<label className="labl">Store Manager Alternate Contact Number</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeManagerPhone}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20,paddingLeft:'10%' }}>
										<label className="labl">Bank Account Number </label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&props.retailerDetails.data.retailerdetails.stores[0].bankDetails["bankAccountNumber"]&&
										 props.retailerDetails.data.retailerdetails.stores[0].bankDetails["bankAccountNumber"].replace(/\d(?=\d{4})/g, "*")}</b></h6>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 ,paddingLeft:'10%'}}>
										<label className="labl"> Account Holder Name </label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].bankDetails['accountHolderName']}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20,paddingLeft:'10%' }}>
										<label className="labl"> IFSC Code</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].bankDetails['IFSCcode']}</b></h6>
									</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20,paddingLeft:'10%' }}>
										<label className="labl">Store Location</label>
										<h6><b>{ props.retailerDetails && props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
										 props.retailerDetails.data.retailerdetails.stores&&
										 props.retailerDetails.data.retailerdetails.stores[0].storeLocation}</b></h6>
									</Col>
								</Row>
								<Row >
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 ,paddingLeft:'10%'}}>
										<label className="labl">Store Logo</label>
										<Card
										//  className={classes.root}
										 >
										<CardActionArea>
											<CardMedia
												// className={classes.media}
												image={props.retailerDetails&&
													props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
													props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl===null?
												NoImage
											:
											props.retailerDetails&&
											props.retailerDetails.data&&props.retailerDetails.data.retailerdetails&&
											props.retailerDetails.data.retailerdetails.stores[0].storeLogoUrl}
												title="Store Logo"
											/>
											<CardContent></CardContent>
										</CardActionArea>
										</Card>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 ,paddingLeft:'10%'}}>
									<label className="labl"> Buisness Type</label>
										<BusinessTypeDisable  {...props}/>
									</Col>
								</Row>
								<Row style={{ marginTop: "70px" ,display: 'flex',	justifyContent:' center',alignItems:'center',textAlign:'center'}}>
								{/* <Col> */}
									<button
										style={{
											justifyContent:' center',
											color: 'rgb(255, 255, 255)',
											backgroundColor:'rgb(137, 199, 74, 1)',
											boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
											float:'left',
											height: '37px',
											fontSize: '16px',
											fontWeight: '600',
											borderRadius: '5px',
											borderWidth: '1px',
											borderStyle:' solid',
											borderColor: 'transparent',
											borderImage: 'initial',
											overflow: 'hidden',
											outline: 'none',
											minHeight:' 14px',
											fontFamily: 'Varela Round, sans-serif'
											}}
										id="submit-button"
										type="button"
									onClick={handleEdit}
									>
										Edit
						</button>
								{/* </Col> */}
							</Row>
								</>
					):(
				<form onSubmit={storeformik.handleSubmit}  style={{width:'100%'}}>
					{state.imageEditModal ? (
					<ImageEditWindow {...props} handleImageEdit={handleImageEdit} />
				) : (null)}
				{state.mapEditModal ? (
					<MapEditModal {...props} mapEditModal={mapEditModal} />
				) : (null)}
				
						
						<Row >
								<Col md={6} sm={12} xs={12} style={{ paddingTop: 30 }}>						
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Name *"
										name="storeName"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeName && Boolean(storeformik.errors.storeName)}
										helperText={storeformik.touched.storeName && storeformik.errors.storeName}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Description *"
										name="storeDescription"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeDescription}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeDescription && Boolean(storeformik.errors.storeDescription)}
										helperText={storeformik.touched.storeDescription && storeformik.errors.storeDescription}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manger First Name *"
										name="storeManagerFirstName"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerFirstName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeManagerFirstName && Boolean(storeformik.errors.storeManagerFirstName)}
										helperText={storeformik.touched.storeManagerFirstName && storeformik.errors.storeManagerFirstName}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manager Last Name *"
										name="storeManagerLastName"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerLastName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeManagerLastName && Boolean(storeformik.errors.storeManagerLastName)}
										helperText={storeformik.touched.storeManagerLastName && storeformik.errors.storeManagerLastName}
									/>
									</ThemeProvider>
								</Col>
								
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manager Mobile Number *"
										name="storeManagerMobile"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerMobile}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeManagerMobile && Boolean(storeformik.errors.storeManagerMobile)}
										helperText={storeformik.touched.storeManagerMobile && storeformik.errors.storeManagerMobile}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 30 }}>
								<ThemeProvider theme={theme}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Store Manager Alternate Contact Number"
										name="storeManagerPhone"
										onChange={storeformik.handleChange}
										value={storeformik.values.storeManagerPhone&&storeformik.values.storeManagerPhone}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.storeManagerPhone && Boolean(storeformik.errors.storeManagerPhone)}
										helperText={storeformik.touched.storeManagerPhone && storeformik.errors.storeManagerPhone}
									/>
									</ThemeProvider>
								</Col>
								<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
								<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Bank Account Number"
										name="bankAccountNumber"
										onChange={storeformik.handleChange}
										value={storeformik.values.bankAccountNumber&&storeformik.values.bankAccountNumber}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.bankAccountNumber && Boolean(storeformik.errors.bankAccountNumber)}
										helperText={storeformik.touched.bankAccountNumber && storeformik.errors.bankAccountNumber}
									/>
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20 }}>
									<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="Account Holder Name"
										name="accountHolderName"
										onChange={storeformik.handleChange}
										value={storeformik.values.accountHolderName&&storeformik.values.accountHolderName}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.accountHolderName&& Boolean(storeformik.errors.accountHolderName)}
										helperText={storeformik.touched.accountHolderName && storeformik.errors.accountHolderName}
									/>
										
									</Col>
									<Col md={6} sm={12} xs={12}style={{ paddingTop: 20}}>
								<TextField
										InputProps={{ style: { height: 35 } }}
										variant="outlined"
										label="IFSC Code"
										name="IFSCcode"
										onChange={storeformik.handleChange}
										value={storeformik.values.IFSCcode&&storeformik.values.IFSCcode}
										fullWidth
										InputLabelProps={{
											shrink: true,
										}}
										error={storeformik.touched.IFSCcode&& Boolean(storeformik.errors.IFSCcode)}
										helperText={storeformik.touched.IFSCcode && storeformik.errors.IFSCcode}
									/>
									</Col>
									<Col md={6} sm={12} xs={12} style={{ paddingTop: 20}}>
										<BusinessType {...props}/>
										{props.businessId.length===0?<p style={{color:'red'}}>Business type is required</p>:props.businessId.length>0&&null}
									{/* <TextField
									  style={{
										width: "100%",
										marginLeft: 10
									  }}
									  InputProps={{
										style: { height: 35, width: "100%" },
										autoComplete: "off",
									  }}
									  InputLabelProps={{
										shrink: true,
									}}
									name="buisnessType"
          id="outlined-select-currency"
		  variant="outlined"
          select
          label="Buisness Type"
          value={storeformik.values.buisnessType&&storeformik.values.buisnessType}
		  onChange={storeformik.handleChange}
          error={storeformik.touched.buisnessType&& Boolean(storeformik.errors.buisnessType)}
		helperText={storeformik.touched.buisnessType && storeformik.errors.buisnessType}
        >
          {Business.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
     
	  </Col>
						</Row>
						<EditLocation {...props} />
				       <MapEditField {...props} mapEditModal={mapEditModal} />
						<Grid item xs={12} style={{ paddingTop: 50 }}>
							<EditImageField {...props} handleImageEdit={handleImageEdit} />
							
						</Grid>

				
					<Row style={{ marginTop: "70px",display:'flex',justifyContent:'center',alignItems:'center'}}>
					{/* <Col> */}
						<button
						 style={{
							justifyContent:' center',
							color: 'rgb(255, 255, 255)',
							backgroundColor:'rgb(137, 199, 74, 1)',
							boxShadow: '0px 14px 24px 0px rgba(93, 183, 96, 0.38)',
							float:'right',
							height: '37px',
							fontSize: '16px',
							fontWeight: '600',
							borderRadius: '5px',
							borderWidth: '1px',
							borderStyle:' solid',
							borderColor: 'transparent',
							borderImage: 'initial',
							overflow: 'hidden',
							outline: 'none',
							minHeight:' 14px',
							fontFamily: 'Varela Round, sans-serif'
							}}
							id="submit-button"
							type="submit"
							// onClick={storeformik.handleSubmit}
							variant="success">
							Save
						</button>
					{/* </Col> */}
				</Row>
				</form>
				)}
			</div>
		</Container>
    )
};
export default StoreEdit;

