import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import StoreLocation4 from "../../utils/images/StoreLocation4.svg";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { InputAdornment, Typography } from "../MUIComponents/index";
import {contentFontSize_16} from '../Typography/index'

const StoreLocation = ({props,setMapaddress,setMapCoordinates,setMapDetails,mapaddress}) => {
  const [address, setAddress] = useState();



  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (address) => {
    setAddress(address);
  };


  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        if (results.length === 0) {
          throw new Error("No results found for the provided address");
        }
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setMapaddress(address);
        setMapCoordinates(latLng);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };
  


  
  

  const searchOptions = {
    componentRestrictions: { country: ["IN"] },
  };

  return (
    <PlacesAutocomplete
      searchOptions={searchOptions}
      value={address}
      onChange={(e) => handleChange(e)}
      onSelect={(e) => handleSelect(e)}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
			<div style={{ display: "flex",flexDirection: "column",gap: "10px"}}>
			 <Typography
             style={{fontFamily:"Roboto",fontWeight:"400"}}
              fontSize={contentFontSize_16}
            >
              Store Location <span style={{ color: " #EA4335" }}>*</span>
            </Typography>
          <TextField
			variant="outlined"
			sx={{
			  width: '100%',
		  
			  '& .MuiOutlinedInput-root': {
				'& fieldset': {
				  borderColor: '#85BE49', 
				},
				'&.Mui-focused fieldset': {
				  borderColor: '#85BE49', 
				},
			  },
			  '& .MuiInputBase-input': {
				// backgroundColor: 'white',
				borderRadius:"10px" 
			  },
			}}
			InputProps={{
			 sx: {
			   fontSize: matches && '13px', 
			   background: 'rgba(133, 190, 73, 0.35)'
			 },
			 endAdornment: (
			  <InputAdornment position="start">
				<img
				src={StoreLocation4}
				alt="Store Location"
				style={{ marginRight: "8px",}}
			  />
			  
			  </InputAdornment>
			),
		 }}
			 size={matches ? "small" : "normal"}
          
            required
           
            {...getInputProps({
              className: "location-search-input",
            })}
          />
		  </div>
          <div style={{ marginLeft: 10, width: "550px" }} className="autocomplete-dropdown-container">
            {suggestions.map((suggestion) => {
              const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";

              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default StoreLocation;