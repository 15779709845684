import React, { useState } from "react";
import { Grid } from "../MUIComponents/index";
import CustomCard from "../Commoncomponents/card/index";
import UpdateProfile from "../../utils/images/UpdateProfile.svg";
import UpdateProfileStore from "../../utils/images/UpdateProfileStore.svg";
import UpdateTimings from "../../utils/images/UpdateTimings.svg";
import UpdateChangePassword from "../../utils/images/UpdateChangePassword.svg";
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import "./Profile.css";
import ChangePassword from "./ChangePassword";
import ProfileUpdate from "./ProfileUpdate";
import StoreUpdate from "./StoreUpdate";
import ManageSlots from "./ManageSlots";
import { Typography } from "../MUIComponents/index";
import { HeaderNavigationFontSize_20 } from "../Typography/index";
import { useMediaQuery } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { HeaderFontSize_24 } from "../Typography/index";
import { useNavigate } from "react-router-dom";
import dummy from '../../utils/images/dummy.png';
export default function Index(props) {
  // const [showChangePassword, setShowChangePassword] = useState(false);
  // const [updateStoreState, setUpdateStorestate] = useState(false);
  // const [updateProfileState, setUpdateProfileState] = useState(false);
  // const [manageTimingState, setManageTimingState] = useState(false);

  const nav = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  function handleUpdatePassword() {
    nav("/home/ChangePassword")
    // console.log("Update Password CustomCard clicked");
    // setShowChangePassword(true);
    // console.log("showChangePassword:", showChangePassword);
  }

  function handleTiming() {
    nav("/home/ManageSlots")
    // setManageTimingState(true);
  }

  function handleStore() {
    nav("/home/UpdateStore")
    // setUpdateStorestate(true);
  }

  function handleProfile() {
    nav("/home/UpdateProfile")
    // setUpdateProfileState(true);
  }

  return (
    <>
       {/* {!showChangePassword &&
        !updateStoreState &&
        !updateProfileState && 
        // !manageTimingState && ( */}
        
            <div className="updateProfileProfileImage">
              <div
                style={{
                  width: matches ? "80px" : "100px",
                  height: matches ? "80px" : "100px",
                  borderRadius: "50%",
                  border: "3px solid #72B01D",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              { props?.retailerDetails?.data?.retailerdetails?.profileImageUrl?(<img
                 src={
                  props?.retailerDetails?.data?.retailerdetails?.profileImageUrl     
                }
                  alt="user profile image"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />):(
                  <img
                 src={dummy}
                  alt="user profile image"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
                )}
              </div>
              <Typography className="ProfileName" fontSize={HeaderFontSize_24}>
                <div style={{display:"flex",gap:"5px"}}>
                  <div>
                  {props?.retailerDetails?.data?.retailerdetails?.firstName}
                  </div>
                  <div>
                  {props?.retailerDetails?.data?.retailerdetails?.lastName}
                  </div>
               
                </div>
                
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                padding: "40px 5px",
              }}
            >
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
              >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <CustomCard
                    onClick={handleProfile}
                    cursor="pointer"
                    boxShadow="rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                    padding={matches ? "20px" : "40px"}
                  >
                    <div className="updateProfileflex">
                      <img
                        src={UpdateProfile}
                        alt="update Profile image"
                        style={{ width: matches ? "30px" : "50px" }}
                      />
                      <Typography
                        fontSize={HeaderNavigationFontSize_20}
                        className="updateProfileText"
                      >
                        Update My Profile
                      </Typography>
                    </div>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <CustomCard
                    onClick={handleStore}
                    cursor="pointer"
                    boxShadow="rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                    padding={matches ? "20px" : "40px"}
                  >
                    <div className="updateProfileflex">
                      <img
                        src={UpdateProfileStore}
                        alt="update Store image"
                        style={{ width: matches ? "30px" : "50px" }}
                      />
                      <Typography
                        className="updateProfileText"
                        fontSize={HeaderNavigationFontSize_20}
                      >
                        Update Your Store
                      </Typography>
                    </div>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <CustomCard
                    onClick={handleTiming}
                    cursor="pointer"
                    boxShadow="rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                    padding={matches ? "20px" : "40px"}
                  >
                    <div className="updateProfileflex">
                      <img
                        src={UpdateTimings}
                        alt="update Timimg image"
                        style={{ width: matches ? "30px" : "50px" }}
                      />
                      <Typography
                        fontSize={HeaderNavigationFontSize_20}
                        className="updateProfileText"
                      >
                        Manage Slots & Timings
                      </Typography>
                    </div>
                  </CustomCard>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <CustomCard
                    onClick={handleUpdatePassword}
                    cursor="pointer"
                    boxShadow="rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                    padding={matches ? "20px" : "40px"}
                  >
                    <div className="updateProfileflex">
                      <img
                        src={UpdateChangePassword}
                        alt="Update Password image"
                        style={{ width: matches ? "30px" : "50px" }}
                      />
                      <Typography
                        fontSize={HeaderNavigationFontSize_20}
                        className="updateProfileText"
                      >
                        Change Password
                      </Typography>
                    </div>
                  </CustomCard>
                </Grid>
              </Grid>
            </div>
          </>
        // )}
      // {showChangePassword && <ChangePassword {...props} />}
      // {updateStoreState && (
      //   <StoreUpdate
      //     setShowChangePassword={setShowChangePassword}
      //     setUpdateStorestate={setUpdateStorestate}
      //     setUpdateProfileState={setUpdateProfileState}
      //     setManageTimingState={setManageTimingState}
      //     {...props}
      //   />
      // )}

      // {updateProfileState && (
      //   <ProfileUpdate
      //     setShowChangePassword={setShowChangePassword}
      //     setUpdateStorestate={setUpdateStorestate}
      //     setUpdateProfileState={setUpdateProfileState}
      //     setManageTimingState={setManageTimingState}
      //     {...props}
      //   />
      // )}

      // {manageTimingState && <ManageSlots {...props} />}
    // </>
  );
}
