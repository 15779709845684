// import React, { useState } from "react";
// import { useFormik } from "formik";
// import { validationSchema } from "./Validation";
// import { Row, Col } from "react-bootstrap";
// import TextField from "@mui/material/TextField";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { green } from "@mui/material/colors";
// import FileInputDemo from "../file-input/FileInputDemo";
// import NewImg from "./ImageCropper/NewImg";
// import MultipleImg from "./ImageCropper/MultipleImg";
// import MenuItem from "@mui/material/MenuItem";
// import { Modal } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
// import NoDepartment from './NoDepartment';
// import { useMediaQuery } from 'react-responsive';
// import { Container } from "@mui/material";
// import client from "../../Configurations/apollo";
// import { gql } from "@apollo/client";
// import { message } from 'antd';
// import "./index.css";

// const SEARCH = gql`
// query($productname:String,
//   $pagination:PaginationArg){
//   viewMasterProducts(productname:$productname,pagination:$pagination){
//   count
//   items{
//   id
//   productname
//   image{
//   primary
//   }
//   desc
//   }
//   hasNext
//   }
//   }
// `;

// const Add = (props) => {
//   const [filteredData, setFilteredData] = useState([]);
//   const [wordEntered, setWordEntered] = useState("");
//   const [open, setOpen] = React.useState(false);
//   const [value, setValue] = React.useState(false);

//   React.useEffect(() => {
//     document.body.scrollTop = 0;
//     setWordEntered("");

//     localStorage.setItem("imageUrl", null)
//   },// eslint-disable-next-line react-hooks/exhaustive-deps
//     [props.productAdded])

//   React.useEffect(() => {
//     // setFormikValue();
//     props.setMaster();
//     resetFormik();
//     props.getMasterDepartment();
//     localStorage.setItem("imageUrl", null)
//   },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [])

//   React.useEffect(() => {
//     if (props.MasterProductDetailsSuccess === true) {
//       setFormikValue();
//       localStorage.setItem("imageUrl", props.MasterProductDetailsData &&
//         props.MasterProductDetailsData.data && props.MasterProductDetailsData.data.viewParticularMasterProduct &&
//         props.MasterProductDetailsData.data && props.MasterProductDetailsData.data.viewParticularMasterProduct.image &&
//         props.MasterProductDetailsData.data && props.MasterProductDetailsData.data.viewParticularMasterProduct.image.primary)
//     }
//     else
//       resetFormik();
//   },// eslint-disable-next-line react-hooks/exhaustive-deps
//     [props.MasterProductDetailsSuccess])

//   const handleFilter = (event) => {
//     setOpen(true)
//     const searchWord = event.target.value;
//     formik.setFieldValue(
//       "Productname",
//       searchWord
//     );
//     setWordEntered(searchWord);
//     // setMenuOpen(true);
//     setValue(true)
//     if (searchWord.length >= 3) {
//       client
//         .query({
//           query: SEARCH,
//           variables: {
//             productname: `${searchWord}`,
//             pagination: { offset: 0, first: 10 },
//           },
//         })
//         .then((res) => {
//           if (
//             res.data &&
//             res.data.viewMasterProducts &&
//             res.data.viewMasterProducts !== null
//           ) {
//             setFilteredData(res.data);
//             //  newFilter = res.data.viewMasterProducts.items.map((i) => {
//             //   return i.productname.toLowerCase().includes(searchWord.toLowerCase());
//             // })
//           } else if (res.errors) {
//             console.log(res.errors);
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }

//     if (searchWord === "") {
//       setFilteredData([]);
//     } else {
//       setFilteredData(filteredData);
//     }
//   };

//   // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
//   // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
//   const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
//   document.title = "Seller Portal | Add Products";
//   const formik = useFormik({
//     initialValues: {
//       Productname: "",
//       Description: "",
//       barcode: "",
//       Price: "",
//       PromoPrice: "",
//       brand: "",
//       MfgName: "",
//       Quantity: "",
//       unit: "",
//       displayRank: "",
//       status: "",
//     },
//     validationSchema: validationSchema,
//     onReset: (values) => {
//       formik.setValues({
//         Productname: "",
//         Description: "",
//         barcode: "",
//         Price: "",
//         PromoPrice: "",
//         brand: "",
//         MfgName: "",
//         Quantity: "",
//         unit: "",
//         displayRank: "",
//         status: "",
//       });
//     },
//     onSubmit: (values, { resetForm }) => {
//       let department = state.department;
//       let category = state.salescategory;
//       let subcategory = state.salescategory1;
//       let category2 = state.salescategory2;
//       // localStorage.getItem('imageUrl')
//       document.body.scrollTop = 0;
//       window.scrollTo(0, 0);
//       if (props.fileListLogo === true && props.proImage === null) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content:
//             "Please upload the image or delete the image before Submiting.",
//           okText: "Ok",
//         });
//       } else if (props.multiFile === true && props.multiImage === null) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content:
//             "Please upload the image or delete the image before Submiting.",
//           okText: "Ok",
//         });
//       }
//       else if (localStorage.getItem("imageUrl") === null) {
//         if (values.PromoPrice === "") {
//           props.AddPrdt(values, department, category, subcategory, category2);
//           props.CREATEMASTERPRODUCTS(values,
//             null,
//             props.multipleSignedUrl ? props.multipleSignedUrl
//               && props.multipleSignedUrl.uploadMultipleFiles
//               && props.multipleSignedUrl.uploadMultipleFiles
//               : null,
//             props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//               props.thumbnailUrl.imageEditAndUpload.Location :
//               null
//           )
//           setTimeout(() => {
//             resetForm();
//             setState({
//               department: "",
//               salescategory: "",
//               salescategory1: "",
//               salescategory2: "",
//             });
//           }, 2000);
//         }
//         else {
//           if (Number(values.PromoPrice) >= Number(values.Price)) {
//             message.error({
//               content: 'Promo price must be lesser than price',
//               className: 'custom-class',
//               style: {
//                 marginTop: '20vh',
//               },
//             });
//           }
//           else {

//             if (values.PromoPrice === "") {
//               props.AddPrdt(values, department, category, subcategory, category2);
//               props.CREATEMASTERPRODUCTS(values,
//                 null,
//                 props.multipleSignedUrl ? props.multipleSignedUrl
//                   && props.multipleSignedUrl.uploadMultipleFiles
//                   && props.multipleSignedUrl.uploadMultipleFiles
//                   : null,
//                 props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//                   props.thumbnailUrl.imageEditAndUpload.Location :
//                   null
//               )
//               setTimeout(() => {
//                 resetForm();
//                 setState({
//                   department: "",
//                   salescategory: "",
//                   salescategory1: "",
//                   salescategory2: "",
//                 });
//               }, 2000);
//             }
//             else {
//               if (Number(values.PromoPrice) >= Number(values.Price)) {
//                 message.error({
//                   content: 'Promo price must be lesser than price',
//                   className: 'custom-class',
//                   style: {
//                     marginTop: '20vh',
//                   },
//                 });
//               }
//               else {
//                 props.AddPrdt(values, department, category, subcategory, category2);
//                 props.CREATEMASTERPRODUCTS(values,
//                   null,
//                   props.multipleSignedUrl ? props.multipleSignedUrl
//                     && props.multipleSignedUrl.uploadMultipleFiles
//                     && props.multipleSignedUrl.uploadMultipleFiles
//                     : null,
//                   props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//                     props.thumbnailUrl.imageEditAndUpload.Location :
//                     null
//                 )
//                 setTimeout(() => {
//                   resetForm();
//                   setState({
//                     department: "",
//                     salescategory: "",
//                     salescategory1: "",
//                     salescategory2: "",
//                   });
//                 }, 2000);
//               }
//             }
//           }
//         }
//       }
//       else {
//         if (values.PromoPrice === "") {
//           props.CREATEMASTERPRODUCTS(values,
//             localStorage.getItem("imageUrl") ? localStorage.getItem("imageUrl") :
//               props.MasterProductDetailsData &&
//               props.MasterProductDetailsData.data &&
//               props.MasterProductDetailsData.data
//                 .viewParticularMasterProduct &&
//               props.MasterProductDetailsData.data
//                 .viewParticularMasterProduct.image &&
//               props.MasterProductDetailsData.data
//                 .viewParticularMasterProduct.image.primary,
//             props.multipleSignedUrl ? props.multipleSignedUrl
//               && props.multipleSignedUrl.uploadMultipleFiles
//               && props.multipleSignedUrl.uploadMultipleFiles
//               : null,
//             props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//               props.thumbnailUrl.imageEditAndUpload.Location :
//               null
//           )
//           props.AddProduct(
//             values,
//             department,
//             category,
//             subcategory,
//             category2,
//             props.multipleSignedUrl ? props.multipleSignedUrl
//               && props.multipleSignedUrl.uploadMultipleFiles
//               && props.multipleSignedUrl.uploadMultipleFiles
//               : null,
//             props.thumbnailUrl ? props.thumbnailUrl : null

//           );
//           setTimeout(() => {
//             resetForm();
//             setState({
//               department: "",
//               salescategory: "",
//               salescategory1: "",
//               salescategory2: "",
//             });
//           }, 2000);
//         }
//         else {
//           if (Number(values.PromoPrice) >= Number(values.Price)) {
//             message.error({
//               content: 'Promo price must be lesser than price',
//               className: 'custom-class',
//               style: {
//                 marginTop: '20vh',
//               },
//             });
//           }
//           else {
//             props.CREATEMASTERPRODUCTS(values,
//               localStorage.getItem("imageUrl") ? localStorage.getItem("imageUrl") :
//                 props.MasterProductDetailsData &&
//                 props.MasterProductDetailsData.data &&
//                 props.MasterProductDetailsData.data
//                   .viewParticularMasterProduct &&
//                 props.MasterProductDetailsData.data
//                   .viewParticularMasterProduct.image &&
//                 props.MasterProductDetailsData.data
//                   .viewParticularMasterProduct.image.primary,
//               props.multipleSignedUrl ? props.multipleSignedUrl
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 : null,
//               props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//                 props.thumbnailUrl.imageEditAndUpload.Location :
//                 null
//             )
//             props.AddProduct(
//               values,
//               department,
//               category,
//               subcategory,
//               category2,
//               props.multipleSignedUrl ? props.multipleSignedUrl
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 : null,
//               props.thumbnailUrl ? props.thumbnailUrl : null

//             );
//             setTimeout(() => {
//               resetForm();
//               setState({
//                 department: "",
//                 salescategory: "",
//                 salescategory1: "",
//                 salescategory2: "",
//               });
//             }, 2000);
//           }
//         }

//       }

//     },
//   });

//   const clearInput = (id) => {
//     setFilteredData([]);
//     setValue(false)
//     props.getMasterProductDetails(id);
//   };

//   const resetFormik = () => {
//     formik.setFieldValue(
//       "Description", ''
//     );
//     formik.setFieldValue(
//       "brand",
//       ''
//     );
//     formik.setFieldValue(
//       "MfgName",
//       ''
//     );
//     formik.setFieldValue(
//       "Price", ''
//     );
//     formik.setFieldValue(
//       "status",
//       ''
//     );
//   }

//   const setFormikValue = () => {
//     // if(props.MasterProductDetailsData &&
//     // props.MasterProductDetailsData.data &&
//     // props.MasterProductDetailsData.data
//     //   .viewParticularMasterProduct===null)
//     //   {
//     //     resetFormik()
//     //   }
//     //   else{

//     formik.setFieldValue(
//       "Description",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.desc
//     );
//     formik.setFieldValue(
//       "brand",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.brand
//     );
//     formik.setFieldValue(
//       "MfgName",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.mfgname
//     );
//     formik.setFieldValue(
//       "Price",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.price
//     );
//     formik.setFieldValue(
//       "status",
//       props.MasterProductDetailsData &&
//         props.MasterProductDetailsData.data &&
//         props.MasterProductDetailsData.data
//           .viewParticularMasterProduct &&
//         props.MasterProductDetailsData.data
//           .viewParticularMasterProduct.status === "available" ? "active" : ""
//     );
//     // }
//   }
//   const [state, setState] = React.useState({
//     department: "",
//     salescategory: "",
//     salescategory1: "",
//     salescategory2: "",
//   });
//   const handleFormChange = (e, name) => {
//     // set
//     if (e.target.name === "department") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory: "",
//         salescategory1: "",
//         salescategory2: "",
//       });
//     }
//     else if (e.target.name === "salescategory") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory1: "",
//         salescategory2: "",
//       });
//     }
//     else if (e.target.name === "salescategory1") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory2: "",
//       });
//     }
//     else {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value
//       });
//     }

//   };
//   const getCategoryList = (id) => {
//     props.getCategoryList(id);
//   };
//   const getCategory1List = (id) => {

//     props.getCategory1List(id);
//   };
//   const getCategory2List = (id) => {
//     props.getCategory2List(id);
//   };

//   const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//     overrides: {
//       MuiInputBase: {
//         input: {
//           "&:-webkit-autofill": {
//             transitionDelay: "9999s",
//             transitionProperty: "background-color, color",
//           },
//         },
//       },
//     },
//   });

//   return (
//     isSmallScreen ? (
//       <div
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           Top: "40px",
//           padding: "30px",
//           width: "100%",
//         }}
//       >

//         {props.DepartmentList && props.DepartmentList.data && props.DepartmentList.data.salesdepartments &&
//           props.DepartmentList.data.salesdepartments.length !== 0 ?
//           (<>
//             <div
//               type="button"
//               style={{
//                 border: '1px solid #d9d9d9',
//                 borderRadius: '8px',
//                 cursor: 'pointer',
//                 width: '96%',
//                 backgroundColor: '#fafafa',
//                 paddingLeft: '10px'
//               }}
//               onClick={() => props.history.push('/home/CatalogBuilder')}>

//               <h4
//                 style={{
//                   fontSize: '16px',
//                   lineHeight: '24px',
//                   fontWeight: 800
//                 }}>
//                 Catalogue Builder
//               </h4>
//               <div style={{ display: 'flex' }}>
//                 <span>
//                   <h5
//                     style={{
//                       fontSize: '14px',
//                       lineHeight: '20px', fontWeight: 500
//                     }}>
//                     Choose and create your catalogue from our already existing products.
//                   </h5>
//                 </span>
//                 <span>
//                   <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="rgb(137, 199, 74, 1)"></path>
//                     <path d="M18.2922 22.9106L24.2512 16.9622L24.2002 16.995C24.5455 16.793 24.7617 16.4147 24.7617 15.9999L24.7564 15.8878C24.7248 15.5549 24.5538 15.2548 24.2911 15.0643L24.2512 15.0384L18.2921 9.08924L18.2064 9.01263C17.7513 8.65814 17.1228 8.68729 16.7199 9.07188L16.6416 9.15466C16.2755 9.58339 16.294 10.2248 16.7021 10.6324L20.9324 14.8571L8.34386 14.8571L8.23626 14.8629C7.65553 14.9331 7.23791 15.4226 7.23791 15.9999L7.24293 16.1094C7.29608 16.6859 7.7649 17.1428 8.34386 17.1428H20.9347L16.7022 21.3675L16.6255 21.453C16.2674 21.912 16.3124 22.539 16.7199 22.928C17.1599 23.348 17.8618 23.3403 18.2922 22.9106Z" fill="white">
//                     </path>
//                   </svg>
//                 </span>
//               </div>
//             </div>
//             <div
//               style={{
//                 paddingTop: "15px",
//                 paddingBottom: "10px",
//                 textAlign: "center",
//                 fontWeight: 600,
//               }}
//             >
//               <h5 style={{ fontWeight: 800, fontSize: "23px" }}>
//                 {"Add new product"}
//               </h5>
//             </div>
//             <form

//               // enableReinitialize={true}
//               style={{ width: "100%" }}
//               onSubmit={formik.handleSubmit}
//               onReset={formik.handleReset}
//             >
//               <Row style={{ width: "100%" }}>
//                 <Col md={12} sm={12} xs={12}>
//                   <ThemeProvider theme={theme}>
// <TextField
//   type="text"
//   label="Product Name"
//   variant="outlined"
//   InputLabelProps={{
//     shrink: true,
//     // style:{ position:'absolute'}
//   }}
//   style={{
//     width: "100%", marginBottom: "30px", marginLeft: 10
//   }}
//   InputProps={{ style: { height: 35 } }}
//   value={wordEntered}
//   onChange={handleFilter}
// />

// {filteredData && filteredData.length !== 0 && (
//   <div className="dataResult" style={{ width: '100px', fontSize: '9px' }}>
//     {filteredData.viewMasterProducts.items.map((i) => {
//       return (

//         <MenuItem onClick={() => {
//           clearInput();
//           setWordEntered(i.productname)
//         }}>
//           <img
//             src={i.image.primary !== null ? i.image.primary : null}
//             alt="product"
//             style={{ marginRight: "7px" }}
//             height="20px"
//             width="20px"
//           />
//           {i.productname}
//         </MenuItem>

//       );
//     })}

//                       </div>
//                     )}
//                     {/* <TextField
//               style={{ marginBottom: "30px", marginLeft: 10 }}
//               InputProps={{
//                 style: { height: 35, backgroundColor: "white" },
//               }}
//               fullWidth
//               variant="outlined"
//               className="form-control"
//               value={formik.values.Productname || " "}
//               onChange={formik.handleChange}
//               error={
//                 formik.touched.Productname && Boolean(formik.errors.Productname)
//               }
//               helperText={
//                 formik.touched.Productname && formik.errors.Productname
//               }
//               name="Productname"
//               label="Enter product name*"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             /> */}
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: "100%" }}>
//                 <Col md={12} sm={12} xs={12}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "50px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 75 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Description || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.Description && Boolean(formik.errors.Description)
//                       }
//                       helperText={
//                         formik.touched.Description && formik.errors.Description
//                       }
//                       className="form-control"
//                       // as="textarea"
//                       label="Short description about the product"
//                       rows={3}
//                       name="Description"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: "100%" }}>
//                 <Col md={12} sm={12} xs={12}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "30px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.barcode || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.barcode && Boolean(formik.errors.barcode)}
//                       helperText={formik.touched.barcode && formik.errors.barcode}
//                       className="form-control"
//                       name="barcode"
//                       label="Barcode number"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{ marginBottom: "15px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Price || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.Price && Boolean(formik.errors.Price)}
//                       helperText={formik.touched.Price && formik.errors.Price}
//                       name="Price"
//                       label="Price*"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{
//                         marginBottom: "15px", marginLeft: 10
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.PromoPrice || " "}
//                       error={
//                         formik.touched.PromoPrice &&
//                         Boolean(formik.errors.PromoPrice)
//                       }
//                       helperText={
//                         formik.touched.PromoPrice && formik.errors.PromoPrice
//                       }
//                       onChange={formik.handleChange}
//                       name="PromoPrice"
//                       label="Promo Price"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="brand"
//                       value={formik.values.brand || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.brand && Boolean(formik.errors.brand)}
//                       helperText={formik.touched.brand && formik.errors.brand}
//                       label="Brand name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px", marginLeft: 10
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="MfgName"
//                       value={formik.values.MfgName || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.MfgName && Boolean(formik.errors.MfgName)
//                       }
//                       helperText={formik.touched.MfgName && formik.errors.MfgName}
//                       label="Manufacturer name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{ marginBottom: "15px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="Quantity"
//                       value={formik.values.Quantity || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.Quantity && Boolean(formik.errors.Quantity)
//                       }
//                       helperText={formik.touched.Quantity && formik.errors.Quantity}
//                       label="Quantity "
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",
//                         marginLeft: 10,
//                         height: 35,
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       SelectProps={{ autoWidth: true }}
//                       fullWidth
//                       variant="outlined"
//                       select
//                       label="Unit of measurement"
//                       name="unit"
//                       value={formik.values.unit || ''}
//                       onChange={formik.handleChange}
//                       error={formik.touched.unit && Boolean(formik.errors.unit)}
//                       helperText={formik.touched.unit && formik.errors.unit}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     >
//                       <MenuItem value="No.s" style={{ cursor: "pointer" }}>
//                         No.s
//                       </MenuItem>
//                       <MenuItem value="kg" style={{ cursor: "pointer" }}>
//                         Kilogram (kg)
//                       </MenuItem>
//                       <MenuItem value="g" style={{ cursor: "pointer" }}>
//                         gram (g)
//                       </MenuItem>
//                       <MenuItem value="gal" style={{ cursor: "pointer" }}>
//                         gallon (gal)
//                       </MenuItem>
//                       <MenuItem value="l" style={{ cursor: "pointer" }}>
//                         liter (l)
//                       </MenuItem>
//                       <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
//                         milliliter (ml)
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ paddingTop: 0, width: '100%' }}>
//                 <Col md={12} xs={12} style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                   {/* <ImageCropper {...props} /> */}
//                   <h6>{"Upload your primary image of the product "}</h6>
//                   <NewImg {...props} fileName={formik.values.Productname} />
//                 </Col>
//               </Row>
//               {props.newSignedUrl ?
//                 <Row style={{ marginLeft: "15px", paddingTop: 0, width: '100%' }}>
//                   <Col md={12} xs={12} style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                     {/* <ImageCropper {...props} /> */}
//                     <h6>
//                       {"Upload your secondary images of the product ( if any ) "}
//                     </h6>
//                     <MultipleImg {...props} />
//                   </Col>
//                 </Row>
//                 :
//                 null
//               }
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{
//                         marginBottom: "15px", marginLeft: 10
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="displayRank"
//                       value={formik.values.displayRank || ""}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.displayRank &&
//                         Boolean(formik.errors.displayRank)
//                       }
//                       helperText={
//                         formik.touched.displayRank && formik.errors.displayRank
//                       }
//                       label="Display rank in the category"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",
//                         marginLeft: 10,
//                         width: "100%"
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       select
//                       variant="outlined"
//                       SelectProps={{ autoWidth: true, width: "100%" }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       label="Status"
//                       name="status"
//                       value={formik.values.status}
//                       onChange={formik.handleChange}
//                       error={formik.touched.status && Boolean(formik.errors.status)}
//                       helperText={formik.touched.status && formik.errors.status}
//                       defaultValue=""
//                     >
//                       <MenuItem value={"active"} style={{ cursor: "pointer" }}>
//                         {"active"}
//                       </MenuItem>
//                       <MenuItem value={"inactive"} style={{ cursor: "pointer" }}>
//                         {"inactive"}
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>

//               {/* </Form> */}
//               <Row style={{ width: '100%', marginLeft: "15px" }}>
//                 <h6
//                   style={{
//                     paddingBottom: "20px",
//                     paddingTop: "20px",
//                   }}
//                 >
//                   <b>
//                     {"Select the hierarchy in which the product should display"}
//                   </b>
//                 </h6>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 {props.DepartmentList !== "" && (
//                   <>
//                     <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           style={{
//                             marginBottom: "15px",
//                             marginLeft: 10,
//                             width: "100%",
//                           }}
//                           InputProps={{ style: { height: 35, width: "100%" } }}
//                           sx={{ minWidth: 70 }}
//                           //  fullWidth
//                           select
//                           variant="outlined"
//                           SelectProps={{ autoWidth: true }}
//                           label="Department"
//                           name="department"
//                           required
//                           defaultValue=""
//                           value={state.department}
//                           onChange={(e) => {
//                             handleFormChange(e);
//                             getCategoryList(e.target.value);
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                         >
//                           {props.DepartmentList &&
//                             props.DepartmentList.data &&
//                             props.DepartmentList.data.salesdepartments !== null &&
//                             props.DepartmentList.data.salesdepartments.map((s) => (
//                               <MenuItem value={`${s.id}`} key={s.id}>{s.name}</MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>

//                     {props.CategoryList &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               required
//                               style={{
//                                 marginBottom: "15px",
//                                 width: "100%",
//                                 marginLeft: 10
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               defaultValue=""
//                               select
//                               variant="outlined"
//                               // sx={{ minWidth: "auto"}}
//                               // SelectProps={{ autoWidth: true }}
//                               label=" Category"
//                               name="salescategory"
//                               value={state.salescategory}
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);

//                               }}
//                               // error={formik.touched.salescategory && Boolean(formik.errors.salescategory)}
//                               // helperText={formik.touched.salescategory && formik.errors.salescategory}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map((c) => (
//                                   <MenuItem value={`${c.id}`} key={c.id}>{c.name}</MenuItem>
//                                 ))}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )}
//                   </>
//                 )}
//               </Row>
//               <Row style={{ paddingTop: 15, width: '100%' }}>
//                 {state.salescategory !== "" &&
//                   props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <>
//                       <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                         <ThemeProvider theme={theme}>
//                           <TextField
//                             defaultValue=""
//                             required
//                             style={{
//                               marginBottom: "15px",
//                               width: "100%",
//                               marginLeft: 10
//                             }}
//                             InputProps={{
//                               style: { height: 35, width: "100%" },
//                               autoComplete: "off",
//                             }}
//                             select
//                             variant="outlined"
//                             // SelectProps={{ autoWidth: true }}
//                             label="Sub-Category 1"
//                             name="salescategory1"
//                             value={state.salescategory1}
//                             onChange={(e) => {
//                               handleFormChange(e);
//                               getCategory2List(e.target.value);
//                             }}
//                             // error={formik.touched.salescategory1 && Boolean(formik.errors.salescategory1)}
//                             // helperText={formik.touched.salescategory1 && formik.errors.salescategory1}
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                           >
//                             {props.Category1List &&
//                               props.Category1List.data.salescategory.map((c) => (
//                                 <MenuItem
//                                   style={{ cursor: "pointer" }}
//                                   value={`${c.id}`}
//                                   key={c.id}
//                                 >
//                                   {c.name}
//                                 </MenuItem>
//                               ))}
//                           </TextField>
//                         </ThemeProvider>
//                       </Col>
//                       {state.salescategory1 !== "" &&
//                         props.CategoryList &&
//                         props.CategoryList.data.salescategory.length !== 0 &&
//                         props.Category2List &&
//                         props.Category2List.data.salescategory.length !== 0 && (
//                           <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                             <ThemeProvider theme={theme}>
//                               <TextField
//                                 defaultValue=""
//                                 required
//                                 style={{
//                                   marginBottom: "15px",
//                                   width: "100%",
//                                   marginLeft: 10
//                                 }}
//                                 InputProps={{
//                                   style: { height: 35, width: "100%" },
//                                   autoComplete: "off",
//                                 }}
//                                 select
//                                 variant="outlined"
//                                 // SelectProps={{ autoWidth: true }}
//                                 label="Sub-Category 2"
//                                 name="salescategory2"
//                                 value={state.salescategory2}
//                                 onChange={(e) => handleFormChange(e)}
//                                 // error={formik.touched.salescategory2 && Boolean(formik.errors.salescategory2)}
//                                 // helperText={formik.touched.salescategory2 && formik.errors.salescategory2}
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               >
//                                 {props.Category2List &&
//                                   props.Category2List.data.salescategory.map(
//                                     (c) => (
//                                       <MenuItem
//                                         style={{
//                                           cursor: "pointer",
//                                           width: "fit-content",
//                                         }}
//                                         value={`${c.id}`}
//                                         key={c.id}
//                                       >
//                                         {c.name}
//                                       </MenuItem>
//                                     )
//                                   )}
//                               </TextField>
//                             </ThemeProvider>
//                           </Col>
//                         )}
//                     </>
//                   )}
//               </Row>

//               <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'cneter', textAlign: 'center' }}>
//                 <Col sm={12} md={12} xs={12} style={{ paddingTop: "15px" }}>
//                   <button
//                     style={{
//                       color: "rgb(255, 255, 255)",
//                       backgroundImage:
//                         "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
//                       width: "75px",
//                       height: "37px",
//                       fontSize: "16px",
//                       fontWeight: "600",
//                       borderRadius: "5px",
//                       borderWidth: "1px",
//                       borderStyle: " solid",
//                       justifyContent: 'center',
//                       alignContent: 'center',
//                       alignItems: 'cneter',
//                       textAlign: 'center',
//                       borderColor: "transparent",
//                       borderImage: "initial",
//                       overflow: "hidden",
//                       outline: "none",
//                       minHeight: " 14px",
//                       fontFamily: "Varela Round, sans-serif",
//                     }}
//                     type="submit"
//                   >
//                     {props.addingProduct ? "loading" : "submit"}
//                   </button>
//                 </Col>
//               </Row>
//               <br />
//             </form>
//           </>)
//           :
//           (<NoDepartment />)}

//       </div>
//     ) : (

//       <Container maxWidth='md'
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           Top: "40px",
//           padding: "30px",
//           display: 'flex',
//           flexDirection: 'column',
//           flex: ' 1 1 0',
//           width: "100%",
//         }}
//       >

//         <div
//           type="button"
//           style={{ border: '1px solid #d9d9d9', borderRadius: '8px', cursor: 'pointer', width: '96%', backgroundColor: '#fafafa', paddingLeft: '10px' }}
//           onClick={() => props.history.push('/home/CatalogBuilder')}>

//           <h4 style={{
//             fontSize: '16px',
//             lineHeight: '24px',
//             fontWeight: 800
//           }}>
//             Catalogue Builder
//           </h4>
//           <div style={{ display: 'flex' }}>
//             <span>
//               <h5 style={{
//                 fontSize: '14px',
//                 lineHeight: '20px', fontWeight: 500
//               }}>
//                 Choose and create your catalogue from our already existing products.
//               </h5>
//             </span>
//             <span >
//               <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="rgb(137, 199, 74, 1)"></path>
//                 <path d="M18.2922 22.9106L24.2512 16.9622L24.2002 16.995C24.5455 16.793 24.7617 16.4147 24.7617 15.9999L24.7564 15.8878C24.7248 15.5549 24.5538 15.2548 24.2911 15.0643L24.2512 15.0384L18.2921 9.08924L18.2064 9.01263C17.7513 8.65814 17.1228 8.68729 16.7199 9.07188L16.6416 9.15466C16.2755 9.58339 16.294 10.2248 16.7021 10.6324L20.9324 14.8571L8.34386 14.8571L8.23626 14.8629C7.65553 14.9331 7.23791 15.4226 7.23791 15.9999L7.24293 16.1094C7.29608 16.6859 7.7649 17.1428 8.34386 17.1428H20.9347L16.7022 21.3675L16.6255 21.453C16.2674 21.912 16.3124 22.539 16.7199 22.928C17.1599 23.348 17.8618 23.3403 18.2922 22.9106Z" fill="white">
//                 </path>
//               </svg>
//             </span>
//           </div>

//         </div>
//         {props.DepartmentList && props.DepartmentList.data && props.DepartmentList.data.salesdepartments &&
//           props.DepartmentList.data.salesdepartments.length !== 0 ?
//           (<>
//             <div
//               style={{
//                 paddingTop: "15px",
//                 paddingBottom: "10px",
//                 textAlign: "center",
//                 fontWeight: 600,
//               }}
//             >
//               <h5 style={{ fontWeight: 800, fontSize: "23px" }}>
//                 {"Add new product"}
//               </h5>
//             </div>

//             <form
//               //  enableReinitialize
//               // enableReinitialize={true}
//               style={{ width: "100%", paddingTop: "15px" }}
//               onSubmit={formik.handleSubmit}
//               onReset={formik.handleReset}
//             >
//               <Row style={{ width: "100%", paddingBottom: '30px' }}>
//                 <Col md={12} sm={12} xs={12}>

//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       type="text"
//                       label="Product Name"
//                       variant="outlined"
//                       name="Productname"
//                       // onChange={formik.handleChange}
//                       error={
//                         formik.touched.Productname && Boolean(formik.errors.Productname)
//                       }
//                       helperText={
//                         formik.touched.Productname && formik.errors.Productname
//                       }
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                       style={{
//                         width: "100%"
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       value={wordEntered}
//                       onChange={handleFilter}
//                       onBlur={() => setTimeout(() => {
//                         setOpen(false)
//                       }, 1000)}
//                     />

//                     {value && open && filteredData && filteredData.length !== 0 && (
//                       <div className="dataResult">
//                         {filteredData.viewMasterProducts.items.map((i, index) => {

//                           return (

//                             <MenuItem
//                               key={index}
//                               onClick={() => {

//                                 setWordEntered(i.productname);
//                                 formik.setFieldValue(
//                                   "Productname",
//                                   i.productname
//                                 );
//                                 clearInput(i.id);
//                               }}
//                             >
//                               <img
//                                 src={i.image.primary !== null ? i.image.primary : null}
//                                 alt="product"
//                                 style={{ marginRight: "7px" }}
//                                 height="40px"
//                                 width="40px"
//                               />
//                               {i.productname}
//                             </MenuItem>
//                           );
//                         })}

//                       </div>
//                     )}
//                     {/* <TextField
//                 style={{ marginBottom: "30px" }}
//                 InputProps={{
//                   style: { height: 35, backgroundColor: "white" },
//                 }}
//                 fullWidth
//                 variant="outlined"
//                 className="form-control"
//                 value={formik.values.Productname || " "}
//                 onChange={formik.handleChange}
//                 error={
//                   formik.touched.Productname && Boolean(formik.errors.Productname)
//                 }
//                 helperText={
//                   formik.touched.Productname && formik.errors.Productname
//                 }
//                 name="Productname"
//                 label="Enter product name*"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//               /> */}

//                   </ThemeProvider>
//                 </Col>
//               </Row>

//               <Row style={{ width: "100%" }}>
//                 <ThemeProvider theme={theme}>
//                   <Col md={12} sm={12} xs={12}>
//                     <TextField
//                       style={{ marginBottom: "50px" }}
//                       InputProps={{ style: { height: 75 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Description || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.Description && Boolean(formik.errors.Description)
//                       }
//                       helperText={
//                         formik.touched.Description && formik.errors.Description
//                       }
//                       className="form-control"
//                       // as="textarea"
//                       label="Short description about the product"
//                       rows={3}
//                       name="Description"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}

//                     />
//                   </Col>
//                 </ThemeProvider>
//               </Row>
//               <Row style={{ width: "100%" }}>
//                 <ThemeProvider theme={theme}>
//                   <Col md={12} sm={12} xs={12}>
//                     <TextField
//                       style={{ marginBottom: "30px", }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.barcode || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.barcode && Boolean(formik.errors.barcode)}
//                       helperText={formik.touched.barcode && formik.errors.barcode}
//                       className="form-control"
//                       name="barcode"
//                       label="Barcode number"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                     />
//                   </Col>
//                 </ThemeProvider>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px" }}
//                       InputProps={{ style: { height: 35 } }}
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Price || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.Price && Boolean(formik.errors.Price)}
//                       helperText={formik.touched.Price && formik.errors.Price}
//                       name="Price"
//                       label="Price*"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",

//                       }}
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.PromoPrice || " "}
//                       error={
//                         formik.touched.PromoPrice &&
//                         Boolean(formik.errors.PromoPrice)
//                       }
//                       helperText={
//                         formik.touched.PromoPrice && formik.errors.PromoPrice
//                       }
//                       onChange={formik.handleChange}
//                       name="PromoPrice"
//                       label="Promo Price"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px" }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="brand"
//                       value={formik.values.brand || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.brand && Boolean(formik.errors.brand)}
//                       helperText={formik.touched.brand && formik.errors.brand}
//                       label="Brand name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",

//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="MfgName"
//                       value={formik.values.MfgName || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.MfgName && Boolean(formik.errors.MfgName)
//                       }
//                       helperText={formik.touched.MfgName && formik.errors.MfgName}
//                       label="Manufacturer name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px" }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       variant="outlined"
//                       name="Quantity"
//                       value={formik.values.Quantity || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.Quantity && Boolean(formik.errors.Quantity)
//                       }
//                       helperText={formik.touched.Quantity && formik.errors.Quantity}
//                       label="Quantity "
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                       style={{
//                         marginBottom: "15px",

//                         height: 35,
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       SelectProps={{ autoWidth: true }}
//                       fullWidth
//                       variant="outlined"
//                       select
//                       label="Unit of measurement"
//                       name="unit"
//                       value={formik.values.unit || ''}
//                       onChange={formik.handleChange}
//                       error={formik.touched.unit && Boolean(formik.errors.unit)}
//                       helperText={formik.touched.unit && formik.errors.unit}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     >
//                       <MenuItem value="No.s" style={{ cursor: "pointer" }}>
//                         No.s
//                       </MenuItem>
//                       <MenuItem value="kg" style={{ cursor: "pointer" }}>
//                         Kilogram (kg)
//                       </MenuItem>
//                       <MenuItem value="g" style={{ cursor: "pointer" }}>
//                         gram (g)
//                       </MenuItem>
//                       <MenuItem value="gal" style={{ cursor: "pointer" }}>
//                         gallon (gal)
//                       </MenuItem>
//                       <MenuItem value="l" style={{ cursor: "pointer" }}>
//                         liter (l)
//                       </MenuItem>
//                       <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
//                         milliliter (ml)
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ paddingTop: 0, width: '100%' }}>
//                 <Col md={12} xs={12} style={{ marginBottom: "20px" }}>
//                   {/* <ImageCropper {...props} /> */}
//                   <h6>{"Upload your primary image of the product "}</h6>
//                   <FileInputDemo {...props} fileName={formik.values.Productname} />
//                   {/* <NewImg {...props} fileName={formik.values.Productname} /> */}
//                 </Col>
//               </Row>
//               {props.newSignedUrl || props.masterUrl ?

//                 <Row style={{ paddingTop: 0, width: '100%' }}>
//                   <Col md={12} xs={12} style={{ marginBottom: "20px" }}>
//                     {/* <ImageCropper {...props} /> */}
//                     <h6>
//                       {"Upload your secondary images of the product ( if any ) "}
//                     </h6>
//                     <MultipleImg {...props} />
//                   </Col>
//                 </Row>
//                 :
//                 null
//               }
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px"
//                       }}
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="displayRank"
//                       value={formik.values.displayRank || ""}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.displayRank &&
//                         Boolean(formik.errors.displayRank)
//                       }
//                       helperText={
//                         formik.touched.displayRank && formik.errors.displayRank
//                       }
//                       label="Display rank in the category"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",

//                         width: "100%"
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       select
//                       variant="outlined"
//                       SelectProps={{ autoWidth: true, width: "100%" }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       label="Status"
//                       name="status"
//                       key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                       value={formik.values.status}
//                       onChange={formik.handleChange}
//                       error={formik.touched.status && Boolean(formik.errors.status)}
//                       helperText={formik.touched.status && formik.errors.status}
//                       defaultValue=""
//                     >
//                       <MenuItem value={"active"} style={{ cursor: "pointer" }}>
//                         {"active"}
//                       </MenuItem>
//                       <MenuItem value={"inactive"} style={{ cursor: "pointer" }}>
//                         {"inactive"}
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>

//               {/* </Form> */}
//               <Row style={{ width: '100%' }}>
//                 <h6
//                   style={{
//                     paddingBottom: "20px",
//                     paddingTop: "20px",
//                   }}
//                 >
//                   <b>
//                     {"Select the hierarchy in which the product should display"}
//                   </b>
//                 </h6>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 {props.DepartmentList !== "" && (
//                   <>
//                     <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           style={{
//                             marginBottom: "15px",
//                             width: "100%",
//                           }}
//                           key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                           InputProps={{ style: { height: 35, width: "100%" } }}
//                           sx={{ minWidth: 70 }}
//                           //  fullWidth
//                           select
//                           variant="outlined"
//                           SelectProps={{ autoWidth: true }}
//                           label="Department"
//                           name="department"
//                           required
//                           defaultValue=""
//                           value={state.department}
//                           onChange={(e) => {
//                             handleFormChange(e);
//                             getCategoryList(e.target.value);
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}\
//                         >
//                           {props.DepartmentList &&
//                             props.DepartmentList.data &&
//                             props.DepartmentList.data.salesdepartments !== null &&
//                             props.DepartmentList.data.salesdepartments.map((s) => (
//                               <MenuItem value={`${s.id}`} key={s.id}>{s.name}</MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>

//                     {props.CategoryList &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                               required
//                               style={{
//                                 marginBottom: "15px",

//                                 width: "100%",
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               defaultValue=""
//                               select
//                               variant="outlined"
//                               // sx={{ minWidth: "auto"}}
//                               // SelectProps={{ autoWidth: true }}
//                               label="Category"
//                               name="salescategory"
//                               value={state.salescategory}
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);

//                               }}
//                               // error={formik.touched.salescategory && Boolean(formik.errors.salescategory)}
//                               // helperText={formik.touched.salescategory && formik.errors.salescategory}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map((c) => (
//                                   <MenuItem value={`${c.id}`} key={c.id}>{c.name}</MenuItem>
//                                 ))}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )}
//                   </>
//                 )}
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 {state.salescategory !== "" &&
//                   props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <>
//                       <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                         <ThemeProvider theme={theme}>
//                           <TextField
//                             defaultValue=""
//                             key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                             required
//                             style={{
//                               marginBottom: "15px",
//                               width: "100%",
//                             }}
//                             InputProps={{
//                               style: { height: 35, width: "100%" },
//                               autoComplete: "off",
//                             }}
//                             select
//                             variant="outlined"
//                             // SelectProps={{ autoWidth: true }}
//                             label="Sub-Category 1"
//                             name="salescategory1"
//                             value={state.salescategory1}
//                             onChange={(e) => {
//                               handleFormChange(e);
//                               getCategory2List(e.target.value);
//                             }}
//                             // error={formik.touched.salescategory1 && Boolean(formik.errors.salescategory1)}
//                             // helperText={formik.touched.salescategory1 && formik.errors.salescategory1}
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                           >
//                             {props.Category1List &&
//                               props.Category1List.data.salescategory.map((c) => (
//                                 <MenuItem
//                                   style={{ cursor: "pointer" }}
//                                   value={`${c.id}`}
//                                   key={c.id}
//                                 >
//                                   {c.name}
//                                 </MenuItem>
//                               ))}
//                           </TextField>
//                         </ThemeProvider>
//                       </Col>
//                       {state.salescategory1 !== "" &&
//                         props.CategoryList &&
//                         props.CategoryList.data.salescategory.length !== 0 &&
//                         props.Category2List &&
//                         props.Category2List.data.salescategory.length !== 0 && (
//                           <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                             <ThemeProvider theme={theme}>
//                               <TextField
//                                 defaultValue=""
//                                 key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                                 required
//                                 style={{
//                                   marginBottom: "15px",

//                                   width: "100%",
//                                 }}
//                                 InputProps={{
//                                   style: { height: 35, width: "100%" },
//                                   autoComplete: "off",
//                                 }}
//                                 select
//                                 variant="outlined"
//                                 label="Sub-Category 2"
//                                 name="salescategory2"
//                                 value={state.salescategory2}
//                                 onChange={(e) => handleFormChange(e)}
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               >
//                                 {props.Category2List &&
//                                   props.Category2List.data.salescategory.map(
//                                     (c) => (
//                                       <MenuItem
//                                         style={{
//                                           cursor: "pointer",
//                                           width: "fit-content",
//                                         }}
//                                         value={`${c.id}`}
//                                         key={c.id}
//                                       >
//                                         {c.name}
//                                       </MenuItem>
//                                     )
//                                   )}
//                               </TextField>
//                             </ThemeProvider>
//                           </Col>
//                         )}
//                     </>
//                   )}
//               </Row>

//               <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "15px" }}>
//                 {/* <Col sm={6} md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                 {" "}
//               </Col>
//               <Col sm={6} md={6} xs={12} style={{ paddingLeft: "50%", paddingTop: "15px" }}> */}
//                 <button
//                   style={{
//                     justifyContent: " center",
//                     color: "rgb(255, 255, 255)",
//                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                     boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//                     width: "150px",
//                     float: "right",
//                     height: "37px",
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     borderRadius: "5px",
//                     borderWidth: "1px",
//                     borderStyle: " solid",
//                     borderColor: "transparent",
//                     borderImage: "initial",
//                     overflow: "hidden",
//                     outline: "none",
//                     minHeight: " 14px",
//                     fontFamily: "Varela Round, sans-serif",
//                   }}
//                   id="submit-button"
//                   type="submit"
//                 >
//                   {props.addingProduct ? "loading" : "Submit"}
//                 </button>
//                 {/* </Col> */}
//               </Row>
//               <br />
//             </form>
//           </>)
//           :
//           (<NoDepartment />)}

//       </Container>
//     )

//   );
// };

// export default Add;
import { React, useEffect, useState } from "react";
import CustomButton from "../Commoncomponents/button/index";
import CustomTextField from "../Commoncomponents/input";
import { ReactComponent as FilterIcon } from "../../utils/Icon/filtericon.svg";
import { ReactComponent as Addproduct } from "../../utils/Icon/add.svg";
import { DialogTitle, InputAdornment, Select } from "../MUIComponents/index";
import SearchIcon from "../../utils/Icon/search.svg";
import { useNavigate } from "react-router-dom";
import "./add.css";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  DialogContent,
  Box,
  MenuItem,
  Autocomplete,
  TextField,
} from "../MUIComponents/index";
import CustomDialog from "../Commoncomponents/dialog";
import Product from "./Product";
import {
  contentFontSize_16,
  ButtonFontSize_18,
  ButtonFontSize_18_xs_10,
  HeaderFontSize_36_xs_25,
  contentFontSize_18_xs_13,
  ButtonFontSize_16,
  ButtonFontSize_16_xs_11,
  HeaderFontSize_24,
} from "../Typography/index";
import ProductCard from "./ProductCard";
import Categorypdt from "../Heirarchy/index";
import Filter from "./Filter";
import { gql, useLazyQuery } from "@apollo/client";
import client from "../../Configurations/apollo";
import Search from "../../utils/Icon/search.svg";

const Add = (props) => {

  const [activeButton, setActiveButton] = useState("product");
  const [addNew, setAddNew] = useState(false);
  const [productButton, setProductButton] = useState(true);
  const [categoryButton, setCategoryButton] = useState(false);
  const [addNewProduct, setAddNewProduct] = useState(false);
  const [addCatalog, setAddCatalog] = useState(false);
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState(null);
  const [filter, setFilter] = useState(false);
  const [show, setShow] = useState(false);
  const [wordEntered, setWordEntered] = useState("");
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [storeid, setStoreId] = useState(localStorage.getItem("storeid"));
  const [key, setKey] = useState("");
  const [from, setFrom] = useState(0);
  const [first, setFirst] = useState(50);
  const [department, setDepartment] = useState(null);
  const [productAdd, setProductAdd] = useState(false)
  const [searchWord, setSearchWord] = useState("");
  const [filteredData, setFilteredData] = useState([])
  const [show1, setShow1] = useState(false)

  const [state, setState] = useState({
    department: null,
    category: null,
    category1: null,
    category2: null,
    storeid: localStorage.getItem("storeid"),
    key: "",
    first: 20,
    page: null,
    products: [],
    status: null,
  });

  const handleFilter = (event) => {
    let { value } = event.target;
    setState({
      ...state,
      [event.target.name]: value,
    });
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    if (searchWord.length >= 3) {
      props.setCriteria(state);
    props.ProductSearch(state);
    }

    if (searchWord === "") {
      setFilteredData([]);
      props.ProductSearch({
        from,
        first,
        department,
        storeid,
        category,
        key,
      }); 
    } else {
      setFilteredData(filteredData);
    }
  };

  const clearInput = () => {
    setSearchWord("");
    setFilteredData([]);
  };

  const handleFilterClick = () => {
    setFilter(true);
  };

  const nav = useNavigate();

  const theme = useTheme();
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  function handleClose() {
    setAddNew(false);
  }
  function handleClickOne() {
    setProductButton(true);
    setCategoryButton(false);
  }
  function handleClickTwo() {
    nav("/home/ManageHierarchy");
  }
  function handleClickThree() {
    setAddNew(true);
  }
  function handleClickProduct() {
    setProductAdd(true)
    nav("/home/Product", { state: { newProduct:newProduct} });
   
  }
  function handleClickCatalog() {
    setAddCatalog(true);
    nav("/home/CatalogBuilder");
  }
  const optionsFoodType = [
    { value: "", label: "" },
    { value: "Non-Veg", label: "Non-Veg" },
    { value: "Veg", label: "Veg" },
  ];

  return (
    <>
      {/* Product Form Navigation */}
      {productAdd ? (
        <Product
        productAdd={productAdd}
          setProductAdd={setProductAdd}
          setAddNew={setAddNew}
          setAddCatalog={setAddCatalog}
          newProduct={newProduct}
          props={props}
        />
      ) : (
        // Catalog page Navigation
        <>
          {/* Add Product First Page */}
          <Grid>
            {/* First Section */}
            <>
            <div>
              <div className="add-btn" style={{ width: "100%" }}>
                <CustomButton
                  width="50%"
                  height="100%"
                  matchPadding={true}
                  padding="8px"
                  borderRadius="15px"
                  onClick={handleClickOne}
                  color={activeButton === "product" ? "white" : "#646464"}
                  variant={
                    activeButton === "product" ? "contained" : "outlined"
                  }
                  background={
                    activeButton === "product" ? "#659B1B" : "transparent"
                  }
                  borderColor="#659B1B"
                >
                  <Typography
                    className="add-Button"
                    fontSize={ButtonFontSize_18}
                  >
                    Products
                  </Typography>
                </CustomButton>
                <CustomButton
                  width="50%"
                  height="100%"
                  matchPadding={true}
                  padding="8px"
                  borderRadius="15px"
                  onClick={handleClickTwo}
                  color="#646464"
                  variant="outlined"
                  background="white"
                  borderColor="#659B1B"
                >
                  <Typography
                    className="add-Button"
                    fontSize={ButtonFontSize_18}
                  >
                    Category
                  </Typography>
                </CustomButton>
              </div>
              <div className="search-product">
                <CustomTextField
                  name="key"
                  placeholder="Search Product"
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={state.key}
                  onChange={(event) => {
                    handleFilter(event);
                  }}
                  width={"90%"}
                  borderRadius="50%"
                  size="small"
                 startAdornment={
                <InputAdornment position="start">
                  <img
                    src={Search}
                    alt="Search"
                    style={{ marginRight: "8px" }}
                  />
                </InputAdornment>
              }
                />
                <div className="filter" >
                  <FilterIcon onClick={handleFilterClick} style={{cursor:"pointer"}}/>
                  <Typography
                    className="filter-text"
                    fontSize={contentFontSize_16}
                  >
                    Filters
                  </Typography>
                </div>
              </div>
              <div style={{ marginTop: "10px", textAlign: "right" }}>
                <CustomButton
                  matchPadding={true}
                  padding="10px"
                  width={smmatch ? "40%" : "25%"}
                  borderRadius="15px"
                  onClick={handleClickThree}
                >
                  <Addproduct className="add-icn" />
                  <Typography
                    fontSize={ButtonFontSize_16}
                    className="Add-product-btn"
                  >
                    Add New
                  </Typography>
                </CustomButton>
              </div>
              <div>
                <ProductCard {...props} setNewProduct={setNewProduct} show1={show1} />
              </div>
            </div>
              {/* First Section Ends */}

              {/* Add New Botton Click Dialog */}

              {addNew && (
                <CustomDialog
                  width="90%"
                  maxHeight="70%"
                  borderRadius="20px"
                  open={addNew}
                  onClose={handleClose}
                  titleAlign="center"
                  padding="0px"
                  top={"30px"}
                  right={"20px"}
                >
                  {/* <DialogTitle>
                  <Typography
                      style={{
                        color: "#170F49",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                      className="head-font"
                      fontSize={HeaderFontSize_24}
                    >
                      Create new product
                    </Typography>
                  </DialogTitle> */}
                  <DialogContent sx={{padding:"30px"}}>
                  <Typography
                      style={{
                        color: "#170F49",
                        textAlign: "center",
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                      className="head-font"
                      fontSize={HeaderFontSize_24}
                    >
                      Create new product
                    </Typography>
                    <Typography
                      style={{
                        paddingTop: "30px",
                        paddingBottom: "20px",
                        boxSizing: "border-box",
                      }}
                      className="para-font"
                      fontSize={contentFontSize_18_xs_13}
                    >
                      You can add your products either by using our "Catalog
                      Builder" with existing product lists or by choosing "Add
                      New Product"
                    </Typography>
                    <div className="product-button">
                      <Grid
                        container
                        spacing={4}
                        sx={{ paddingLeft: "0px", paddingTop: "30px" }}
                      >
                        <Grid item xs={12} sm={6} md={6}>
                          <CustomButton
                            width="100%"
                            height="40px"
                            borderRadius="5px"
                            color="grey"
                            variant="outlined"
                            background={"white"}
                            onClick={handleClickCatalog}
                            borderColor={"#85BE49"}
                            matchPadding={true}
                            padding="23px 0px"
                          >
                            <Typography
                              className="Catalog-Button"
                              fontSize={ButtonFontSize_16_xs_11}
                            >
                              Create From Catalog Builder
                            </Typography>
                          </CustomButton>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          sx={{ paddingTop: "20px" }}
                        >
                          <CustomButton
                            width="100%"
                            height="40px"
                            borderRadius="5px"
                            color="grey"
                            variant="outlined"
                            background={"white"}
                            onClick={handleClickProduct}
                            borderColor={"#85BE49"}
                            matchPadding={true}
                            padding="23px"
                          >
                            <Typography
                              className="Catalog-Button"
                              fontSize={ButtonFontSize_16_xs_11}
                            >
                              Add New Product
                            </Typography>
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </div>
                  </DialogContent>
                </CustomDialog>
              )}

              {/* Add New Dialog end */}
            </>

            {filter && (
              <Filter
              setShow1={setShow1}
                products={products}
                setProducts={setProducts}
                filter={filter}
                setFilter={setFilter}
                setShow={setShow}
                {...props}
              />
            )}

            {/* Category page Navigation */}

            {categoryButton && (
              <>
                <Categorypdt
                  setCategoryButton={setCategoryButton}
                  setProductButton={setProductButton}
                />
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Add;
