// import React from "react";
// import Container from '@mui/material/Container';
// import { Row, Col } from 'react-bootstrap';
// import TextField from "@mui/material/TextField";
// import { ThemeProvider, createTheme } from "@mui/system";
// import { green } from "@mui/material/colors";
// import { useFormik } from "formik";
// import { buyxgetyvalidationSchema } from "./Validation";
// import { InputAdornment } from "@mui/material";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import BxgyProducts from "./BxgyProducts";
// import { message } from 'antd';
// import './index.css';
// import { set } from "date-fns";
// import dayjs from 'dayjs';
// import AdvancedFormat from 'dayjs/plugin/advancedFormat';
// dayjs.extend(AdvancedFormat);

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2),
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1),
//   },
// }));

// function BootstrapDialogTitle(props) {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 2, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 5,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }

// export default function BuyXgetYCoupon(props) {

//   const [messageApi, contextHolder] = message.useMessage();

//   const [tovalue, setToValue] = React.useState();

//   const [fromvalue, setFromValue] = React.useState();
//   const [formSubmitted, setFormSubmitted] = React.useState(false);

//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const onKeyDown = (e) => {
//     e.preventDefault();
//   }

//   const handleStartDate = (date)=>{
//     setFromValue(date);
//     formik.setFieldValue('fromtime',date);
//     if(!tovalue || date > tovalue){
//       setToValue(date);
//       formik.setFieldValue('totime',date);
//     }
//   };

//   const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//     overrides: {
//       MuiInputBase: {
//         input: {
//           "&:-webkit-autofill": {
//             transitionDelay: "9999s",
//             transitionProperty: "background-color, color",
//           },
//         },
//       },
//     },
//   });

//   React.useEffect(() => {
//     props.getBxgyProducts({ first: 0 })
//   }, [])

//   const formik = useFormik({
//     initialValues: {
//       couponCode: '',
//       usesperCustomer: '',
//       buyitem: '',
//       getitem: '',
//       selectedproducts: null,
//       fromdate: '',
//       todate: '',
//       fromtime: new Date(),
//       totime: new Date(),
//       minOrder: ''
//     },
//     validationSchema: buyxgetyvalidationSchema,
//     onSubmit: (values) => {
//       let { todate, fromtime, totime, minOrder } = values;
//       let StartDate = new Date(fromtime);
//       let ToDate = new Date(totime);

//       if (props.BXGYCheck.length === 0) {
//         alert("Please select atleast one product")
//       }

//       // let {fromtime, totime}= values;
//       // let StartDate =  new Date(fromtime);
//       // let ToDate =  new Date( totime);
//       else if (StartDate >= ToDate) {
//         formik.setFieldError("totime", "End Time must be greater than Start Time")
//         // console.log(`${StartDate} is greater than ${ToDate}`);
//         // false
//       }
//       else if (StartDate <= new Date(Date.now() - 1000 * (60 * 5))) {
//         formik.setFieldError("fromtime", "Start Time must be greater than Today") // false
//       }
//       else if (ToDate < new Date()) {
//         formik.setFieldError("totime", "End Time must be greater than Today") // false
//       }

//       else {

//         let { couponCode, usesperCustomer, buyitem, getitem, todate, fromtime, totime, minOrder } = values;
//         let selectedproducts = props.BXGYCheck;
//         // console.log(getTime(fromtime))
//         let StartDate = new Date(fromtime);
//         let ToDate = new Date(totime);
//         props.CreateBXGY({ couponCode, usesperCustomer, buyitem, getitem, selectedproducts, StartDate, ToDate, minOrder })

//       }
//       // props.CreateBXGY()
//       setFormSubmitted(true);

//     }
//   })

//   return (
//     <>
//       <form
//         //  enableReinitialize
//         // enableReinitialize={true}
//         style={{ width: "100%", paddingTop: "15px" }}
//         onSubmit={formik.handleSubmit}
//         onReset={formik.handleReset}
//       >
//         <Container maxWidth='md'
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             marginTop: '100px',
//             padding: "30px",
//             width: "100%",
//           }}
//         >
//           <h3 className="CouponType">
//             {'Buy x Get y free coupon'}
//           </h3>
//         </Container>
//         <Container maxWidth='md'
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             marginTop: "20px",
//             padding: "30px",
//             width: "100%",
//           }}
//         >
//           <Row
//             className="CouponHeading">
//             <p >
//               Create coupon
//             </p>
//           </Row>
//           <div className="formDiv">
//             <Row>
//               <Col md={6} sm={12} xs={12} style={{paddingTop:'15px'}}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     InputProps={{ style: { height: 35 } }}
//                     fullWidth
//                     variant="outlined"
//                     value={formik.values.couponCode}
//                     onChange={formik.handleChange}
//                     error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
//                     helperText={formik.touched.couponCode && formik.errors.couponCode}
//                     // disabled={formSubmitted}
//                     className="form-control"
//                     name="couponCode"
//                     label="Coupon code"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </ThemeProvider>
//               </Col>
//               <Col md={6} sm={12} xs={12} style={{paddingTop:'15px'}}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     InputProps={{ style: { height: 35 }, inputProps: { min: 1 } }}
//                     type="number"
//                     fullWidth
//                     variant="outlined"
//                     value={formik.values.usesperCustomer}
//                     onChange={formik.handleChange}
//                     error={formik.touched.usesperCustomer && Boolean(formik.errors.usesperCustomer)}
//                     helperText={formik.touched.usesperCustomer && formik.errors.usesperCustomer}
//                     // disabled={formSubmitted}
//                     className="form-control"
//                     name="usesperCustomer"
//                     label="Uses per customer"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </ThemeProvider>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//         <Container
//           maxWidth='md'
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             marginTop: "20px",
//             padding: "30px",
//             width: "100%",
//           }}
//         >
//           <Row
//             className="CouponHeading">
//             <p>
//               Coupon details
//             </p>
//           </Row>
//           <div className="formDiv">
//             <Row>
//               <Col md={6} sm={12} xs={12} style={{paddingTop:'15px'}}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     InputProps={{
//                       style: { height: 35 }, inputProps: { min: 1 }, endAdornment: <InputAdornment position="end">Items</InputAdornment>, }}
//                     type="number"
//                     fullWidth
//                     variant="outlined"
//                     value={formik.values.buyitem}
//                     onChange={formik.handleChange}
//                     onKeyPress={(event) => {
//                       if (event?.key === "-" || event?.key === "+") {
//                         event.preventDefault();
//                       }
//                     }}
//                     error={formik.touched.buyitem && Boolean(formik.errors.buyitem)}
//                     helperText={formik.touched.buyitem && formik.errors.buyitem}
//                     // disabled={formSubmitted}
//                     className="form-control"
//                     name="buyitem"
//                     label="Buy"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </ThemeProvider>
//               </Col>
//               <Col md={6} sm={12} xs={12} style={{paddingTop:'15px'}}>
//                 <ThemeProvider theme={theme}>
//                   <TextField
//                     InputProps={{ style: { height: 35 }, inputProps: { min: 1 }, endAdornment: <InputAdornment position="end">Items FREE</InputAdornment>, }}
//                     type="number"
//                     fullWidth
//                     variant="outlined"
//                     value={formik.values.getitem}
//                     onKeyPress={(event) => {
//                       if (event?.key === "-" || event?.key === "+") {
//                         event.preventDefault();
//                       }
//                     }}
//                     onChange={formik.handleChange}
//                     error={formik.touched.getitem && Boolean(formik.errors.getitem)}
//                     helperText={formik.touched.getitem && formik.errors.getitem}
//                     // disabled={formSubmitted}
//                     className="form-control"
//                     name="getitem"
//                     label="Get"
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                   />
//                 </ThemeProvider>
//               </Col>
//             </Row>
//             <Row style={{ paddingTop: '35px' }}>
//               <Col md={6} sm={12} xs={12}>
//                 <ThemeProvider theme={theme}>
//                   <button type="button"
//                     className="productButton"
//                     variant="outlined" onClick={handleClickOpen}>
//                     {props.BXGYCheck && props.BXGYCheck.length === 0 ? 'Select Products' : `${props.BXGYCheck.length} ${'Products Selected (Edit)'}`}
//                   </button>
//                   <BootstrapDialog
//                     onClose={handleClose}
//                     aria-labelledby="customized-dialog-title"
//                     open={open}
//                     style={{justifyContent:'center'}}
//                   >
//                     <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
//                       {`Available Products(${props.BxgyProducts && props.BxgyProducts.data &&
//                         props.BxgyProducts.data.bxgyProductsListing.count} )`}
//                     </BootstrapDialogTitle>
//                     <DialogContent dividers>

//                       <BxgyProducts {...props} />

//                     </DialogContent>
//                     <DialogActions style={{justifyContent:"center"}}>
//                       <button type='button' className="productButtonsave" autoFocus onClick={handleClose}>
//                         Save
//                       </button>
//                     </DialogActions>
//                   </BootstrapDialog>
//                 </ThemeProvider>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//         <Container
//           maxWidth='md'
//           style={{
//             backgroundColor: "white",
//             border: "1px solid rgba(0, 0, 0, 0.16)",
//             borderRadius: "10px",
//             marginTop: "20px",
//             padding: "30px",
//             width: "100%",
//           }}
//         >
//           <Row
//             className="CouponHeading">
//             <p >
//               Coupon Validity
//             </p>
//           </Row>
//           <div className="formDiv">
//             <Row>
//               <Col md={6} sm={12} xs={12}  style={{paddingTop:'15px'}}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DateTimePicker
//                     value={fromvalue}
//                     onChange={(newValue) => {
//                       setFromValue(newValue);
//                       formik.setFieldValue('fromtime', newValue)
//                     }}
//                     label="Start Time"
//                     renderInput={(props) => <TextField
//                       onKeyDown={onKeyDown} {...props} value={formik.values.fromtime}
//                       onChange={formik.handleChange}
//                       error={formik.touched.fromtime && Boolean(formik.errors.fromtime)}
//                       helperText={formik.touched.fromtime && formik.errors.fromtime}
//                       // disabled={formSubmitted}
//                       className="form-control"
//                       name="fromtime"
//                      />}
//                     minDate={dayjs()}
//                     minTime={dayjs()}
//                   />
//                 </LocalizationProvider>
//               </Col>

//               <Col md={6} sm={12} xs={12} style={{paddingTop:'15px'}}>
//                 <LocalizationProvider dateAdapter={AdapterDayjs}>
//                   <DateTimePicker
//                     value={tovalue}
//                     onChange={(newValue) => {
//                       setToValue(newValue);
//                       formik.setFieldValue('totime', newValue)
//                     }}
//                     label="End Time"
//                     startDate={fromvalue}
//                     renderInput={(props) => <TextField
//                       onKeyDown={onKeyDown}
//                       {...props} value={formik.values.totime}
//                       onChange={formik.handleChange}
//                       error={formik.touched.totime && Boolean(formik.errors.totime)}
//                       helperText={formik.touched.totime && formik.errors.totime}
//                       // disabled={formSubmitted}
//                       className="form-control"
//                       name="totime" />}
//                       minDate={dayjs()}
//                       minTime={dayjs()}
//                   />
//                 </LocalizationProvider>
//               </Col>
//             </Row>
//           </div>
//         </Container>
//         <Row className="centerDiv">
//         <button type='submit' className="couponButton" disabled={formSubmitted}>
//             {formSubmitted  ? "Creating" : "Create Coupon"}
//           </button>
//         </Row>
//       </form>
//     </>
//   )
// }

//new code

import React, { useEffect, useState } from "react";
import { Grid, Box } from "../MUIComponents/index";
import { Typography } from "../MUIComponents/index";
import { buyxgetyvalidationSchema } from "./Validation";

import {
  HeaderNavigationFontSize_20_xs_13,
  contentFontSize_16,
  ButtonFontSize_18,
} from "../Typography/index";
import "./BuyXgetYCoupon.css";
import CustomTextField from "../Commoncomponents/input";
import CustomButton from "../Commoncomponents/button";
import {
  useTheme,
  useMediaQuery,
  DemoContainer,
  AdapterDayjs,
  LocalizationProvider,
  DateTimePicker,
} from "../MUIComponents/index";
import Calendar from "../../utils/Icon/Calendar.svg";
import { useFormik } from "formik";
import { flatdiscountvalidationSchema } from "./Validation";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Line from "../../utils/images/Line.svg";
import { InputAdornment } from "../MUIComponents/index";
import CustomDialog from "../Commoncomponents/dialog/index";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "../MUIComponents/index";
import BxgyProducts from "./BxgyProducts";

import { CloseIcon, Dialog } from "../MUIComponents/index";
import { HeaderfontSize_30 } from "../Typography/index";
import { TextField } from "../MUIComponents/index";
import { message } from "antd";

export default function BuyXgetYCoupon(props) {
  const [open, setOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
const [tovalue, setToValue] = useState();
const [fromvalue, setFromValue] = useState();
const [checkedData,setCheckedData]=useState([]);


  useEffect(() => {
    props.getBxgyProducts({ first: 0 });
  }, []);

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleClose1(){
    props.BxgycheckBox(checkedData)
    message.success({
      content: "Product Added Successfully",
      className: "custom-class",
      style: {
        marginTop: "10vh",
        marginLeft: "15vw",
      },
      duration: 3.5,
    });

    setTimeout(() => {
      setOpen(false)
    }, 4000);
    
  }

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const nav = useNavigate();

  useEffect(()=>{
      if(props.BXGYCouponssuccess){
          
  message.success({
    content: "BXGY Coupon created Successfully",
    className: "custom-class",
    style: {
      marginTop: "10vh",
      marginLeft: "15vw",
    },
    duration: 3.5,
  });
  setTimeout(() => {
    nav('/home/upcomingCoupons');
  }, 4000);
      }
  },[props.BXGYCouponssuccess])


  function handleCouponPage() {
    nav("/home/Coupons");
  }

  function handleCouponTypePage() {
    nav("/home/Coupontype");
  }

  function MuiIcon() {
    return <img src={Calendar} alt="Calendar icon" width="22px" height="21.99px" />;
  }

  const formik = useFormik({
    initialValues: {
      couponCode: '',
      usesperCustomer: '',
      buyitem: '',
      getitem: '',
      selectedproducts: null,
      fromdate: '',
      todate: '',
      fromtime: '',
      totime: '',
      minOrder: ''
    },
    validationSchema: buyxgetyvalidationSchema,
    onSubmit: (values) => {
      let { todate, fromtime, totime, minOrder } = values;
      let StartDate = new Date(fromtime);
      let ToDate = new Date(totime);
  
      if (props.BXGYCheck.length === 0) {
        message.warning({
          content: "Select at least one product",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "20vw",
          },
        });
      } else if (StartDate >= ToDate) {
        formik.setFieldError("totime", "End Time must be greater than Start Time");
      } else if (StartDate <= new Date(Date.now() - 1000 * (60 * 5))) {
        formik.setFieldError("fromtime", "Start Time must be greater than current time");
      } else if (ToDate < new Date()) {
        formik.setFieldError("totime", "End Time must be greater than start time");
      } else {
        let { couponCode, usesperCustomer, buyitem, getitem, todate, fromtime, totime, minOrder } = values;
        let selectedproducts = props.BXGYCheck;
        let StartDate = new Date(fromtime);
        let ToDate = new Date(totime);
        props.CreateBXGY({ couponCode, usesperCustomer, buyitem, getitem, selectedproducts, StartDate, ToDate, minOrder });
        setFormSubmitted(true);
        const currentDate = new Date().getTime();
        if (values.fromtime > currentDate) {
        
        } else {
          nav('/home/Coupons');
        }
      }
    }
  });
  
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const errorFieldName = Object.keys(formik.errors)[0];
      const errorField = document.getElementsByName(errorFieldName)[0];
      if (errorField) {
        errorField.focus();
      } else {
        console.error(`Element with name ${errorFieldName} not found.`);
      }
    }
  }, [formik.isSubmitting, formik.errors]);


  return (
    <>
     <form
onSubmit={formik.handleSubmit}
onReset={formik.handleReset}
>
      <div style={{ display: "flex", gap: "8px" }}>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
            onClick={handleCouponPage}
          >
            Coupon
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
          >
            /
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
            onClick={handleCouponTypePage}
          >
            Coupon Type
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
          >
            /
          </Typography>
        </div>
        <div>
          <Typography
            className="main"
            fontSize={HeaderNavigationFontSize_20_xs_13}
            style={{ color: "#85BE49" }}
          >
            Coupons Type
          </Typography>
        </div>
      </div>
      <Typography
        style={{
          margin: "20px 0px 20px 0px",
          fontFamily: "Roboto",
          fontWeight: "600",
        }}
        fontSize={HeaderNavigationFontSize_20_xs_13}
      >
        Buy X Get Y Free
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography className="Name" fontSize={contentFontSize_16}>
              Coupon Code <span className="red-start">*</span>
            </Typography>

            <TextField
              value={formik.values.couponCode}
                onChange={formik.handleChange}
                error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                helperText={formik.touched.couponCode && formik.errors.couponCode}
                name="couponCode"
                variant="outlined"
                sx={{
                  width: '100%',
              
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#85BE49', 
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#85BE49', 
                    },
                  },
                  '& .MuiInputBase-input': {
                    backgroundColor: 'white',
                    borderRadius:"10px" 
                  },
                }}
                InputProps={{
                 sx: {
                   fontSize: matches && '13px', 
                 },
             }}
                 size={matches ? "small" : "normal"}


            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography className="Name" fontSize={contentFontSize_16}>
              Uses Per Customer <span className="red-start">*</span>
            </Typography>

            <TextField
              value={formik.values.usesperCustomer}
              onChange={formik.handleChange}
              error={formik.touched.usesperCustomer && Boolean(formik.errors.usesperCustomer)}
              helperText={formik.touched.usesperCustomer && formik.errors.usesperCustomer}
              name="usesperCustomer"
              variant="outlined"
              sx={{
                width: '100%',
            
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#85BE49', 
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#85BE49', 
                  },
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'white',
                  borderRadius:"10px" 
                },
              }}
              InputProps={{
               sx: {
                 fontSize: matches && '13px', 
               },
           }}
               size={matches ? "small" : "normal"}


            />
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        <div className="Block"></div>
      </div>

      {/* <div style={{width:"100%",borderTop:"0.5px dashed black", height:"5px",margin:"20px auto"}}> */}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography className="Name" fontSize={contentFontSize_16}>
              Buy <span className="red-start">*</span>
            </Typography>

            <TextField
             
              value={formik.values.buyitem}
              onChange={formik.handleChange}
              error={formik.touched.buyitem && Boolean(formik.errors.buyitem)}
              helperText={formik.touched.buyitem && formik.errors.buyitem}
              name="buyitem"
              variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                        background:"white"
                      },
                      endAdornment:(
                        <InputAdornment position="start">
                          <Typography
                            className="GetAdorment"
                            fontSize={contentFontSize_16}
                          >
                            Items
                          </Typography>
                        </InputAdornment>
                      )
                  }}
                      size={matches ? "small" : "normal"}


            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography className="Name" fontSize={contentFontSize_16}>
              Get <span className="red-start">*</span>
            </Typography>

            <TextField
              value={formik.values.getitem}
              onChange={formik.handleChange}
              error={formik.touched.getitem && Boolean(formik.errors.getitem)}
              helperText={formik.touched.getitem && formik.errors.getitem}
              name="getitem"
              variant="outlined"
                     sx={{
                       width: '100%',
                   
                       '& .MuiOutlinedInput-root': {
                         '& fieldset': {
                           borderColor: '#85BE49', 
                         },
                         '&.Mui-focused fieldset': {
                           borderColor: '#85BE49', 
                         },
                       },
                       '& .MuiInputBase-input': {
                         backgroundColor: 'white',
                         borderRadius:"10px" 
                       },
                     }}
                     InputProps={{
                      sx: {
                        fontSize: matches && '13px', 
                        background:"white"
                      },
                      endAdornment:(
                        <InputAdornment position="start">
                          <Typography
                            className="GetAdorment"
                            fontSize={contentFontSize_16}
                          >
                            Items Free
                          </Typography>
                        </InputAdornment>
                      )
                  }}
                      size={matches ? "small" : "normal"}

             
             
              
            />
          </div>
        </Grid>
      </Grid>
      <div style={{ marginTop: "24px" }}>
        <CustomButton
          width={smmatch ? "100%" : "30%"}
          borderRadius="15px"
          // onClick={handleClose}
          onClick={handleOpen}
        >
          <Typography
            fontSize={ButtonFontSize_18}
            className="create-coupon"
            // onClick={createPercentageCoupoun}
          >
            {props.BXGYCheck && props.BXGYCheck.length === 0 ? 'Select Products' : `${props.BXGYCheck.length} ${'Products Selected (Edit)'}`}
          </Typography>
        </CustomButton>

        {/* <CustomDialog
        width="100%"
        height="80%"
        borderRadius="20px"
        open={open}
        onClose={handleClose}
        // titleAlign="left"
        // title={`Available Products(${props.BxgyProducts && props.BxgyProducts.data &&props.BxgyProducts.data.bxgyProductsListing.count} )`}
        showCloseButton={true}
      > */}
        <Dialog width="100%" height="80%" open={open} onClose={handleClose}>
          <DialogTitle>
            <div className="ProductListPopUpStyle">
              <Typography
                fontSize={HeaderfontSize_30}
                className="ProductListFontStyle"
              >
                {`Available Products(${
                  props.BxgyProducts &&
                  props.BxgyProducts.data &&
                  props.BxgyProducts.data.bxgyProductsListing.count
                } )`}{" "}
              </Typography>
              <div
                onClick={handleClose}
                // style={{
                //   position: "absolute",
                //   right: "30px",
                //   top: "30px",
                //   cursor: "pointer",
                // }}
                style={{
                  display:'flex',
                  justifyContent:'center',
                  alignItems:"center"
                }}
              >
                <div
                  style={{
                    height: "27px",
                    width: "27px",
                    border: "2px solid #8B8989",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CloseIcon style={{ color: "#8B8989" }} />
                </div>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <div style={{ margin: "15px 0px" }}>
              <BxgyProducts props={props} setCheckedData={setCheckedData} checkedData={checkedData}/>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <div className="ProductListPopUpStyle">
              <CustomButton
              type="button"
                width={
                  upXlmatch
                    ? "358px"
                    : xlmatch
                    ? lgmatch
                      ? mdmatch
                        ? smmatch
                          ? xsmatch
                            ? "250px"
                            : "250px"
                          : "310px"
                        : "330px"
                      : "340px"
                    : "358px"
                }
                borderRadius="20px"
                onClick={handleClose1}
              >
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="create-coupon"
                  // onClick={createPercentageCoupoun}
                >
                  Add
                </Typography>
              </CustomButton>
            </div>
          </DialogActions>
        </Dialog>
      </div>
      <div style={{ marginTop: "40px", marginBottom: "40px" }}>
        <div className="Block"></div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography fontSize={contentFontSize_16} className="flatLabelFont">
          Coupon Validity <span className="red-start">*</span>
        </Typography>
        {/* {Start} */}
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    slots={{
                      openPickerIcon: MuiIcon,
                    }}
                    slotProps={{
                      textField: {
                        placeholder: "From",
                        error:formik.touched.fromtime && Boolean(formik.errors.fromtime),
                        helperText:formik.touched.fromtime && formik.errors.fromtime,
                        size: matches && "small",
                      },
                    }}
                    sx={{
                      width: '100%',
                  
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49', 
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49', 
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius:"10px" ,
                        fontSize: matches && '13px', 
                      },
                    }}
                    value={fromvalue}
                    onChange={(newValue) => {
                    setFromValue(newValue);
                    formik.setFieldValue('fromtime',newValue)
                    }}

                    renderInput={(props) => <TextField
                    onKeyDown={onKeyDown}
                    {...props}
                      value={formik.values.fromtime}
                    onChange={formik.handleChange}
                    error={formik.touched.fromtime && Boolean(formik.errors.fromtime)}
                    helperText={formik.touched.fromtime && formik.errors.fromtime}
                    name="fromtime" />}
                    minDate={dayjs()}
                    minTime={dayjs(formik.values.fromtime).isSame(dayjs(), 'day') ? dayjs() : undefined}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    slots={{
                      openPickerIcon: MuiIcon,
                    }}
                    slotProps={{
                      textField: {
                        placeholder: "To",
                        error:formik.touched.totime && Boolean(formik.errors.totime),
                    helperText:formik.touched.totime && formik.errors.totime,
                        size: matches && "small",
                      },
                    }}
                    sx={{
                      width: '100%',
                  
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#85BE49', 
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#85BE49', 
                        },
                      },
                      '& .MuiInputBase-input': {
                        backgroundColor: 'white',
                        borderRadius:"10px" ,
                        fontSize: matches && '13px', 
                      },
                    }}
                    value={tovalue}
                    onChange={(newValue) => {
                    setToValue(newValue);
                    formik.setFieldValue('totime',newValue)
                    }}

                    renderInput={(props) => <TextField
                    onKeyDown={onKeyDown}
                    {...props} value={formik.values.totime}
                    onChange={formik.handleChange}
                    error={formik.touched.totime && Boolean(formik.errors.totime)}
                    helperText={formik.touched.totime && formik.errors.totime}

                    className="form-control"
                    name="totime" />}
                    minDate={dayjs(fromvalue)}
                    minTime={
                    dayjs(formik.values.totime).isSame(dayjs(), 'day') ? dayjs() : undefined
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </div>
        {/* {end} */}
      </div>
      <div style={{ marginTop: "150px", textAlign: "center" }}>
        <CustomButton
          type="submit"
          width={
            upXlmatch
              ? "358px"
              : xlmatch
              ? lgmatch
                ? mdmatch
                  ? smmatch
                    ? xsmatch
                      ? "250px"
                      : "250px"
                    : "310px"
                  : "330px"
                : "340px"
              : "358px"
          }
          borderRadius="20px"
          background={props.BXGYCouponssuccess?"gray":'#659B1B'}
          disabled={props.BXGYCouponssuccess&&"disabled"}
          matchPadding={true}
          padding={"10px 0px"}
          // onClick={handleClose}
        >
          <Typography
            fontSize={ButtonFontSize_18}
            className="create-coupon"
            // onClick={createPercentageCoupoun}
          >
            {/* {formSubmitted  ? "Creating" : "Create Coupon"} */}
            create Coupon
          </Typography>
        </CustomButton>
      </div>
      </form>
    </>
  );
}
