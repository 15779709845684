// import React, { useState } from "react";
// import { useFormik } from "formik";
// import { validationSchema } from "./Validation";
// import { Row, Col } from "react-bootstrap";
// import TextField from "@mui/material/TextField";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { green } from "@mui/material/colors";
// import FileInputDemo from "../file-input/FileInputDemo";
// import NewImg from "./ImageCropper/NewImg";
// import MultipleImg from "./ImageCropper/MultipleImg";
// import MenuItem from "@mui/material/MenuItem";
// import { Modal } from "antd";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
// import NoDepartment from './NoDepartment';
// import { useMediaQuery } from 'react-responsive';
// import { Container } from "@mui/material";
// import client from "../../Configurations/apollo";
// import { gql } from "@apollo/client";
// import { message } from 'antd';
// import "./index.css";

// const SEARCH = gql`
// query($productname:String,
//   $pagination:PaginationArg){
//   viewMasterProducts(productname:$productname,pagination:$pagination){
//   count
//   items{
//   id
//   productname
//   image{
//   primary
//   }
//   desc
//   }
//   hasNext
//   }
//   }
// `;

// const Add = (props) => {
//   const [filteredData, setFilteredData] = useState([]);
//   const [wordEntered, setWordEntered] = useState("");
//   const [open, setOpen] = React.useState(false);
//   const [value, setValue] = React.useState(false);

//   React.useEffect(() => {
//     document.body.scrollTop = 0;
//     setWordEntered("");

//     localStorage.setItem("imageUrl", null)
//   },// eslint-disable-next-line react-hooks/exhaustive-deps
//     [props.productAdded])

//   React.useEffect(() => {
//     // setFormikValue();
//     props.setMaster();
//     resetFormik();
//     props.getMasterDepartment();
//     localStorage.setItem("imageUrl", null)
//   },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [])

//   React.useEffect(() => {
//     if (props.MasterProductDetailsSuccess === true) {
//       setFormikValue();
//       localStorage.setItem("imageUrl", props.MasterProductDetailsData &&
//         props.MasterProductDetailsData.data && props.MasterProductDetailsData.data.viewParticularMasterProduct &&
//         props.MasterProductDetailsData.data && props.MasterProductDetailsData.data.viewParticularMasterProduct.image &&
//         props.MasterProductDetailsData.data && props.MasterProductDetailsData.data.viewParticularMasterProduct.image.primary)
//     }
//     else
//       resetFormik();
//   },// eslint-disable-next-line react-hooks/exhaustive-deps
//     [props.MasterProductDetailsSuccess])

// const handleFilter = (event) => {
//   setOpen(true)
//   const searchWord = event.target.value;
//   formik.setFieldValue(
//     "Productname",
//     searchWord
//   );
//   setWordEntered(searchWord);
//   // setMenuOpen(true);
//   setValue(true)
//   if (searchWord.length >= 3) {
//     client
//       .query({
//         query: SEARCH,
//         variables: {
//           productname: `${searchWord}`,
//           pagination: { offset: 0, first: 10 },
//         },
//       })
//       .then((res) => {
//         if (
//           res.data &&
//           res.data.viewMasterProducts &&
//           res.data.viewMasterProducts !== null
//         ) {
//           setFilteredData(res.data);
//           //  newFilter = res.data.viewMasterProducts.items.map((i) => {
//           //   return i.productname.toLowerCase().includes(searchWord.toLowerCase());
//           // })
//         } else if (res.errors) {
//           console.log(res.errors);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   }

//   if (searchWord === "") {
//     setFilteredData([]);
//   } else {
//     setFilteredData(filteredData);
//   }
// };

//   // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
//   // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1224px)' })
//   const isSmallScreen = useMediaQuery({ query: '(max-width: 1224px)' })
//   document.title = "Seller Portal | Add Products";
//   const formik = useFormik({
//     initialValues: {
//       Productname: "",
//       Description: "",
//       barcode: "",
//       Price: "",
//       PromoPrice: "",
//       brand: "",
//       MfgName: "",
//       Quantity: "",
//       unit: "",
//       displayRank: "",
//       status: "",
//     },
//     validationSchema: validationSchema,
//     onReset: (values) => {
//       formik.setValues({
//         Productname: "",
//         Description: "",
//         barcode: "",
//         Price: "",
//         PromoPrice: "",
//         brand: "",
//         MfgName: "",
//         Quantity: "",
//         unit: "",
//         displayRank: "",
//         status: "",
//       });
//     },
//     onSubmit: (values, { resetForm }) => {
//       let department = state.department;
//       let category = state.salescategory;
//       let subcategory = state.salescategory1;
//       let category2 = state.salescategory2;
//       // localStorage.getItem('imageUrl')
//       document.body.scrollTop = 0;
//       window.scrollTo(0, 0);
//       if (props.fileListLogo === true && props.proImage === null) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content:
//             "Please upload the image or delete the image before Submiting.",
//           okText: "Ok",
//         });
//       } else if (props.multiFile === true && props.multiImage === null) {
//         Modal.confirm({
//           title: "Confirm",
//           icon: <ExclamationCircleOutlined />,
//           content:
//             "Please upload the image or delete the image before Submiting.",
//           okText: "Ok",
//         });
//       }
//       else if (localStorage.getItem("imageUrl") === null) {
//         if (values.PromoPrice === "") {
//           props.AddPrdt(values, department, category, subcategory, category2);
//           props.CREATEMASTERPRODUCTS(values,
//             null,
//             props.multipleSignedUrl ? props.multipleSignedUrl
//               && props.multipleSignedUrl.uploadMultipleFiles
//               && props.multipleSignedUrl.uploadMultipleFiles
//               : null,
//             props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//               props.thumbnailUrl.imageEditAndUpload.Location :
//               null
//           )
//           setTimeout(() => {
//             resetForm();
//             setState({
//               department: "",
//               salescategory: "",
//               salescategory1: "",
//               salescategory2: "",
//             });
//           }, 2000);
//         }
//         else {
//           if (Number(values.PromoPrice) >= Number(values.Price)) {
//             message.error({
//               content: 'Promo price must be lesser than price',
//               className: 'custom-class',
//               style: {
//                 marginTop: '20vh',
//               },
//             });
//           }
//           else {

//             if (values.PromoPrice === "") {
//               props.AddPrdt(values, department, category, subcategory, category2);
//               props.CREATEMASTERPRODUCTS(values,
//                 null,
//                 props.multipleSignedUrl ? props.multipleSignedUrl
//                   && props.multipleSignedUrl.uploadMultipleFiles
//                   && props.multipleSignedUrl.uploadMultipleFiles
//                   : null,
//                 props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//                   props.thumbnailUrl.imageEditAndUpload.Location :
//                   null
//               )
//               setTimeout(() => {
//                 resetForm();
//                 setState({
//                   department: "",
//                   salescategory: "",
//                   salescategory1: "",
//                   salescategory2: "",
//                 });
//               }, 2000);
//             }
//             else {
//               if (Number(values.PromoPrice) >= Number(values.Price)) {
//                 message.error({
//                   content: 'Promo price must be lesser than price',
//                   className: 'custom-class',
//                   style: {
//                     marginTop: '20vh',
//                   },
//                 });
//               }
//               else {
//                 props.AddPrdt(values, department, category, subcategory, category2);
//                 props.CREATEMASTERPRODUCTS(values,
//                   null,
//                   props.multipleSignedUrl ? props.multipleSignedUrl
//                     && props.multipleSignedUrl.uploadMultipleFiles
//                     && props.multipleSignedUrl.uploadMultipleFiles
//                     : null,
//                   props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//                     props.thumbnailUrl.imageEditAndUpload.Location :
//                     null
//                 )
//                 setTimeout(() => {
//                   resetForm();
//                   setState({
//                     department: "",
//                     salescategory: "",
//                     salescategory1: "",
//                     salescategory2: "",
//                   });
//                 }, 2000);
//               }
//             }
//           }
//         }
//       }
//       else {
//         if (values.PromoPrice === "") {
//           props.CREATEMASTERPRODUCTS(values,
//             localStorage.getItem("imageUrl") ? localStorage.getItem("imageUrl") :
//               props.MasterProductDetailsData &&
//               props.MasterProductDetailsData.data &&
//               props.MasterProductDetailsData.data
//                 .viewParticularMasterProduct &&
//               props.MasterProductDetailsData.data
//                 .viewParticularMasterProduct.image &&
//               props.MasterProductDetailsData.data
//                 .viewParticularMasterProduct.image.primary,
//             props.multipleSignedUrl ? props.multipleSignedUrl
//               && props.multipleSignedUrl.uploadMultipleFiles
//               && props.multipleSignedUrl.uploadMultipleFiles
//               : null,
//             props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//               props.thumbnailUrl.imageEditAndUpload.Location :
//               null
//           )
//           props.AddProduct(
//             values,
//             department,
//             category,
//             subcategory,
//             category2,
//             props.multipleSignedUrl ? props.multipleSignedUrl
//               && props.multipleSignedUrl.uploadMultipleFiles
//               && props.multipleSignedUrl.uploadMultipleFiles
//               : null,
//             props.thumbnailUrl ? props.thumbnailUrl : null

//           );
//           setTimeout(() => {
//             resetForm();
//             setState({
//               department: "",
//               salescategory: "",
//               salescategory1: "",
//               salescategory2: "",
//             });
//           }, 2000);
//         }
//         else {
//           if (Number(values.PromoPrice) >= Number(values.Price)) {
//             message.error({
//               content: 'Promo price must be lesser than price',
//               className: 'custom-class',
//               style: {
//                 marginTop: '20vh',
//               },
//             });
//           }
//           else {
//             props.CREATEMASTERPRODUCTS(values,
//               localStorage.getItem("imageUrl") ? localStorage.getItem("imageUrl") :
//                 props.MasterProductDetailsData &&
//                 props.MasterProductDetailsData.data &&
//                 props.MasterProductDetailsData.data
//                   .viewParticularMasterProduct &&
//                 props.MasterProductDetailsData.data
//                   .viewParticularMasterProduct.image &&
//                 props.MasterProductDetailsData.data
//                   .viewParticularMasterProduct.image.primary,
//               props.multipleSignedUrl ? props.multipleSignedUrl
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 : null,
//               props.thumbnailUrl ? props.thumbnailUrl && props.thumbnailUrl.imageEditAndUpload &&
//                 props.thumbnailUrl.imageEditAndUpload.Location :
//                 null
//             )
//             props.AddProduct(
//               values,
//               department,
//               category,
//               subcategory,
//               category2,
//               props.multipleSignedUrl ? props.multipleSignedUrl
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 && props.multipleSignedUrl.uploadMultipleFiles
//                 : null,
//               props.thumbnailUrl ? props.thumbnailUrl : null

//             );
//             setTimeout(() => {
//               resetForm();
//               setState({
//                 department: "",
//                 salescategory: "",
//                 salescategory1: "",
//                 salescategory2: "",
//               });
//             }, 2000);
//           }
//         }

//       }

//     },
//   });

//   const clearInput = (id) => {
//     setFilteredData([]);
//     setValue(false)
//     props.getMasterProductDetails(id);
//   };

//   const resetFormik = () => {
//     formik.setFieldValue(
//       "Description", ''
//     );
//     formik.setFieldValue(
//       "brand",
//       ''
//     );
//     formik.setFieldValue(
//       "MfgName",
//       ''
//     );
//     formik.setFieldValue(
//       "Price", ''
//     );
//     formik.setFieldValue(
//       "status",
//       ''
//     );
//   }

//   const setFormikValue = () => {
//     // if(props.MasterProductDetailsData &&
//     // props.MasterProductDetailsData.data &&
//     // props.MasterProductDetailsData.data
//     //   .viewParticularMasterProduct===null)
//     //   {
//     //     resetFormik()
//     //   }
//     //   else{

//     formik.setFieldValue(
//       "Description",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.desc
//     );
//     formik.setFieldValue(
//       "brand",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.brand
//     );
//     formik.setFieldValue(
//       "MfgName",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.mfgname
//     );
//     formik.setFieldValue(
//       "Price",
//       props.MasterProductDetailsData &&
//       props.MasterProductDetailsData.data &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct &&
//       props.MasterProductDetailsData.data
//         .viewParticularMasterProduct.price
//     );
//     formik.setFieldValue(
//       "status",
//       props.MasterProductDetailsData &&
//         props.MasterProductDetailsData.data &&
//         props.MasterProductDetailsData.data
//           .viewParticularMasterProduct &&
//         props.MasterProductDetailsData.data
//           .viewParticularMasterProduct.status === "available" ? "active" : ""
//     );
//     // }
//   }
//   const [state, setState] = React.useState({
//     department: "",
//     salescategory: "",
//     salescategory1: "",
//     salescategory2: "",
//   });
//   const handleFormChange = (e, name) => {
//     // set
//     if (e.target.name === "department") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory: "",
//         salescategory1: "",
//         salescategory2: "",
//       });
//     }
//     else if (e.target.name === "salescategory") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory1: "",
//         salescategory2: "",
//       });
//     }
//     else if (e.target.name === "salescategory1") {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value,
//         salescategory2: "",
//       });
//     }
//     else {
//       setState({
//         ...state,
//         [e.target.name]: e.target.value
//       });
//     }

//   };
//   const getCategoryList = (id) => {
//     props.getCategoryList(id);
//   };
//   const getCategory1List = (id) => {

//     props.getCategory1List(id);
//   };
//   const getCategory2List = (id) => {
//     props.getCategory2List(id);
//   };

//   const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//     overrides: {
//       MuiInputBase: {
//         input: {
//           "&:-webkit-autofill": {
//             transitionDelay: "9999s",
//             transitionProperty: "background-color, color",
//           },
//         },
//       },
//     },
//   });

//   return (
//     isSmallScreen ? (
//       <div
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           Top: "40px",
//           padding: "30px",
//           width: "100%",
//         }}
//       >

//         {props.DepartmentList && props.DepartmentList.data && props.DepartmentList.data.salesdepartments &&
//           props.DepartmentList.data.salesdepartments.length !== 0 ?
//           (<>
//             <div
//               type="button"
//               style={{
//                 border: '1px solid #d9d9d9',
//                 borderRadius: '8px',
//                 cursor: 'pointer',
//                 width: '96%',
//                 backgroundColor: '#fafafa',
//                 paddingLeft: '10px'
//               }}
//               onClick={() => props.history.push('/home/CatalogBuilder')}>

//               <h4
//                 style={{
//                   fontSize: '16px',
//                   lineHeight: '24px',
//                   fontWeight: 800
//                 }}>
//                 Catalogue Builder
//               </h4>
//               <div style={{ display: 'flex' }}>
//                 <span>
//                   <h5
//                     style={{
//                       fontSize: '14px',
//                       lineHeight: '20px', fontWeight: 500
//                     }}>
//                     Choose and create your catalogue from our already existing products.
//                   </h5>
//                 </span>
//                 <span>
//                   <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="rgb(137, 199, 74, 1)"></path>
//                     <path d="M18.2922 22.9106L24.2512 16.9622L24.2002 16.995C24.5455 16.793 24.7617 16.4147 24.7617 15.9999L24.7564 15.8878C24.7248 15.5549 24.5538 15.2548 24.2911 15.0643L24.2512 15.0384L18.2921 9.08924L18.2064 9.01263C17.7513 8.65814 17.1228 8.68729 16.7199 9.07188L16.6416 9.15466C16.2755 9.58339 16.294 10.2248 16.7021 10.6324L20.9324 14.8571L8.34386 14.8571L8.23626 14.8629C7.65553 14.9331 7.23791 15.4226 7.23791 15.9999L7.24293 16.1094C7.29608 16.6859 7.7649 17.1428 8.34386 17.1428H20.9347L16.7022 21.3675L16.6255 21.453C16.2674 21.912 16.3124 22.539 16.7199 22.928C17.1599 23.348 17.8618 23.3403 18.2922 22.9106Z" fill="white">
//                     </path>
//                   </svg>
//                 </span>
//               </div>
//             </div>
//             <div
//               style={{
//                 paddingTop: "15px",
//                 paddingBottom: "10px",
//                 textAlign: "center",
//                 fontWeight: 600,
//               }}
//             >
//               <h5 style={{ fontWeight: 800, fontSize: "23px" }}>
//                 {"Add new product"}
//               </h5>
//             </div>
//             <form

//               // enableReinitialize={true}
//               style={{ width: "100%" }}
//               onSubmit={formik.handleSubmit}
//               onReset={formik.handleReset}
//             >
//               <Row style={{ width: "100%" }}>
//                 <Col md={12} sm={12} xs={12}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       type="text"
//                       label="Product Name"
//                       variant="outlined"
//                       InputLabelProps={{
//                         shrink: true,
//                         // style:{ position:'absolute'}
//                       }}
//                       style={{
//                         width: "100%", marginBottom: "30px", marginLeft: 10
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       value={wordEntered}
//                       onChange={handleFilter}
//                     />

//                     {filteredData && filteredData.length !== 0 && (
//                       <div className="dataResult" style={{ width: '100px', fontSize: '9px' }}>
//                         {filteredData.viewMasterProducts.items.map((i) => {
//                           return (

//                             <MenuItem onClick={() => {
//                               clearInput();
//                               setWordEntered(i.productname)
//                             }}>
//                               <img
//                                 src={i.image.primary !== null ? i.image.primary : null}
//                                 alt="product"
//                                 style={{ marginRight: "7px" }}
//                                 height="20px"
//                                 width="20px"
//                               />
//                               {i.productname}
//                             </MenuItem>

//                           );
//                         })}

//                       </div>
//                     )}
//                     {/* <TextField
//               style={{ marginBottom: "30px", marginLeft: 10 }}
//               InputProps={{
//                 style: { height: 35, backgroundColor: "white" },
//               }}
//               fullWidth
//               variant="outlined"
//               className="form-control"
//               value={formik.values.Productname || " "}
//               onChange={formik.handleChange}
//               error={
//                 formik.touched.Productname && Boolean(formik.errors.Productname)
//               }
//               helperText={
//                 formik.touched.Productname && formik.errors.Productname
//               }
//               name="Productname"
//               label="Enter product name*"
//               InputLabelProps={{
//                 shrink: true,
//               }}
//             /> */}
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: "100%" }}>
//                 <Col md={12} sm={12} xs={12}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "50px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 75 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Description || " "}
//                       onChange={formik.handleChange}
// error={
//   formik.touched.Description && Boolean(formik.errors.Description)
// }
// helperText={
//   formik.touched.Description && formik.errors.Description
// }
//                       className="form-control"
//                       // as="textarea"
//                       label="Short description about the product"
//                       rows={3}
//                       name="Description"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: "100%" }}>
//                 <Col md={12} sm={12} xs={12}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "30px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.barcode || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.barcode && Boolean(formik.errors.barcode)}
//                       helperText={formik.touched.barcode && formik.errors.barcode}
//                       className="form-control"
//                       name="barcode"
//                       label="Barcode number"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{ marginBottom: "15px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Price || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.Price && Boolean(formik.errors.Price)}
//                       helperText={formik.touched.Price && formik.errors.Price}
//                       name="Price"
//                       label="Price*"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{
//                         marginBottom: "15px", marginLeft: 10
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.PromoPrice || " "}
//                       error={
//                         formik.touched.PromoPrice &&
//                         Boolean(formik.errors.PromoPrice)
//                       }
//                       helperText={
//                         formik.touched.PromoPrice && formik.errors.PromoPrice
//                       }
//                       onChange={formik.handleChange}
//                       name="PromoPrice"
//                       label="Promo Price"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="brand"
//                       value={formik.values.brand || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.brand && Boolean(formik.errors.brand)}
//                       helperText={formik.touched.brand && formik.errors.brand}
//                       label="Brand name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px", marginLeft: 10
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="MfgName"
//                       value={formik.values.MfgName || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.MfgName && Boolean(formik.errors.MfgName)
//                       }
//                       helperText={formik.touched.MfgName && formik.errors.MfgName}
//                       label="Manufacturer name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{ marginBottom: "15px", marginLeft: 10 }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="Quantity"
//                       value={formik.values.Quantity || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.Quantity && Boolean(formik.errors.Quantity)
//                       }
//                       helperText={formik.touched.Quantity && formik.errors.Quantity}
//                       label="Quantity "
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",
//                         marginLeft: 10,
//                         height: 35,
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       SelectProps={{ autoWidth: true }}
//                       fullWidth
//                       variant="outlined"
//                       select
//                       label="Unit of measurement"
//                       name="unit"
//                       value={formik.values.unit || ''}
//                       onChange={formik.handleChange}
//                       error={formik.touched.unit && Boolean(formik.errors.unit)}
//                       helperText={formik.touched.unit && formik.errors.unit}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     >
//                       <MenuItem value="No.s" style={{ cursor: "pointer" }}>
//                         No.s
//                       </MenuItem>
//                       <MenuItem value="kg" style={{ cursor: "pointer" }}>
//                         Kilogram (kg)
//                       </MenuItem>
//                       <MenuItem value="g" style={{ cursor: "pointer" }}>
//                         gram (g)
//                       </MenuItem>
//                       <MenuItem value="gal" style={{ cursor: "pointer" }}>
//                         gallon (gal)
//                       </MenuItem>
//                       <MenuItem value="l" style={{ cursor: "pointer" }}>
//                         liter (l)
//                       </MenuItem>
//                       <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
//                         milliliter (ml)
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ paddingTop: 0, width: '100%' }}>
//                 <Col md={12} xs={12} style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                   {/* <ImageCropper {...props} /> */}
//                   <h6>{"Upload your primary image of the product "}</h6>
// <NewImg {...props} fileName={formik.values.Productname} />
//                 </Col>
//               </Row>
//               {props.newSignedUrl ?
//                 <Row style={{ marginLeft: "15px", paddingTop: 0, width: '100%' }}>
//                   <Col md={12} xs={12} style={{ marginBottom: "20px", marginLeft: "10px" }}>
//                     {/* <ImageCropper {...props} /> */}
//                     <h6>
//                       {"Upload your secondary images of the product ( if any ) "}
//                     </h6>
//                     <MultipleImg {...props} />
//                   </Col>
//                 </Row>
//                 :
//                 null
//               }
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       style={{
//                         marginBottom: "15px", marginLeft: 10
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="displayRank"
//                       value={formik.values.displayRank || ""}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.displayRank &&
//                         Boolean(formik.errors.displayRank)
//                       }
//                       helperText={
//                         formik.touched.displayRank && formik.errors.displayRank
//                       }
//                       label="Display rank in the category"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",
//                         marginLeft: 10,
//                         width: "100%"
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       select
//                       variant="outlined"
//                       SelectProps={{ autoWidth: true, width: "100%" }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       label="Status"
//                       name="status"
//                       value={formik.values.status}
//                       onChange={formik.handleChange}
//                       error={formik.touched.status && Boolean(formik.errors.status)}
//                       helperText={formik.touched.status && formik.errors.status}
//                       defaultValue=""
//                     >
//                       <MenuItem value={"active"} style={{ cursor: "pointer" }}>
//                         {"active"}
//                       </MenuItem>
//                       <MenuItem value={"inactive"} style={{ cursor: "pointer" }}>
//                         {"inactive"}
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>

//               {/* </Form> */}
//               <Row style={{ width: '100%', marginLeft: "15px" }}>
//                 <h6
//                   style={{
//                     paddingBottom: "20px",
//                     paddingTop: "20px",
//                   }}
//                 >
//                   <b>
//                     {"Select the hierarchy in which the product should display"}
//                   </b>
//                 </h6>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 {props.DepartmentList !== "" && (
//                   <>
//                     <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           style={{
//                             marginBottom: "15px",
//                             marginLeft: 10,
//                             width: "100%",
//                           }}
//                           InputProps={{ style: { height: 35, width: "100%" } }}
//                           sx={{ minWidth: 70 }}
//                           //  fullWidth
//                           select
//                           variant="outlined"
//                           SelectProps={{ autoWidth: true }}
//                           label="Department"
//                           name="department"
//                           required
//                           defaultValue=""
//                           value={state.department}
//                           onChange={(e) => {
//                             handleFormChange(e);
//                             getCategoryList(e.target.value);
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                         >
//                           {props.DepartmentList &&
//                             props.DepartmentList.data &&
//                             props.DepartmentList.data.salesdepartments !== null &&
//                             props.DepartmentList.data.salesdepartments.map((s) => (
//                               <MenuItem value={`${s.id}`} key={s.id}>{s.name}</MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>

//                     {props.CategoryList &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               required
//                               style={{
//                                 marginBottom: "15px",
//                                 width: "100%",
//                                 marginLeft: 10
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               defaultValue=""
//                               select
//                               variant="outlined"
//                               // sx={{ minWidth: "auto"}}
//                               // SelectProps={{ autoWidth: true }}
//                               label=" Category"
//                               name="salescategory"
//                               value={state.salescategory}
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);

//                               }}
//                               // error={formik.touched.salescategory && Boolean(formik.errors.salescategory)}
//                               // helperText={formik.touched.salescategory && formik.errors.salescategory}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map((c) => (
//                                   <MenuItem value={`${c.id}`} key={c.id}>{c.name}</MenuItem>
//                                 ))}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )}
//                   </>
//                 )}
//               </Row>
//               <Row style={{ paddingTop: 15, width: '100%' }}>
//                 {state.salescategory !== "" &&
//                   props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <>
//                       <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                         <ThemeProvider theme={theme}>
//                           <TextField
//                             defaultValue=""
//                             required
//                             style={{
//                               marginBottom: "15px",
//                               width: "100%",
//                               marginLeft: 10
//                             }}
//                             InputProps={{
//                               style: { height: 35, width: "100%" },
//                               autoComplete: "off",
//                             }}
//                             select
//                             variant="outlined"
//                             // SelectProps={{ autoWidth: true }}
//                             label="Sub-Category 1"
//                             name="salescategory1"
//                             value={state.salescategory1}
//                             onChange={(e) => {
//                               handleFormChange(e);
//                               getCategory2List(e.target.value);
//                             }}
//                             // error={formik.touched.salescategory1 && Boolean(formik.errors.salescategory1)}
//                             // helperText={formik.touched.salescategory1 && formik.errors.salescategory1}
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                           >
//                             {props.Category1List &&
//                               props.Category1List.data.salescategory.map((c) => (
//                                 <MenuItem
//                                   style={{ cursor: "pointer" }}
//                                   value={`${c.id}`}
//                                   key={c.id}
//                                 >
//                                   {c.name}
//                                 </MenuItem>
//                               ))}
//                           </TextField>
//                         </ThemeProvider>
//                       </Col>
//                       {state.salescategory1 !== "" &&
//                         props.CategoryList &&
//                         props.CategoryList.data.salescategory.length !== 0 &&
//                         props.Category2List &&
//                         props.Category2List.data.salescategory.length !== 0 && (
//                           <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                             <ThemeProvider theme={theme}>
//                               <TextField
//                                 defaultValue=""
//                                 required
//                                 style={{
//                                   marginBottom: "15px",
//                                   width: "100%",
//                                   marginLeft: 10
//                                 }}
//                                 InputProps={{
//                                   style: { height: 35, width: "100%" },
//                                   autoComplete: "off",
//                                 }}
//                                 select
//                                 variant="outlined"
//                                 // SelectProps={{ autoWidth: true }}
//                                 label="Sub-Category 2"
//                                 name="salescategory2"
//                                 value={state.salescategory2}
//                                 onChange={(e) => handleFormChange(e)}
//                                 // error={formik.touched.salescategory2 && Boolean(formik.errors.salescategory2)}
//                                 // helperText={formik.touched.salescategory2 && formik.errors.salescategory2}
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               >
//                                 {props.Category2List &&
//                                   props.Category2List.data.salescategory.map(
//                                     (c) => (
//                                       <MenuItem
//                                         style={{
//                                           cursor: "pointer",
//                                           width: "fit-content",
//                                         }}
//                                         value={`${c.id}`}
//                                         key={c.id}
//                                       >
//                                         {c.name}
//                                       </MenuItem>
//                                     )
//                                   )}
//                               </TextField>
//                             </ThemeProvider>
//                           </Col>
//                         )}
//                     </>
//                   )}
//               </Row>

//               <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'cneter', textAlign: 'center' }}>
//                 <Col sm={12} md={12} xs={12} style={{ paddingTop: "15px" }}>
//                   <button
//                     style={{
//                       color: "rgb(255, 255, 255)",
//                       backgroundImage:
//                         "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
//                       width: "75px",
//                       height: "37px",
//                       fontSize: "16px",
//                       fontWeight: "600",
//                       borderRadius: "5px",
//                       borderWidth: "1px",
//                       borderStyle: " solid",
//                       justifyContent: 'center',
//                       alignContent: 'center',
//                       alignItems: 'cneter',
//                       textAlign: 'center',
//                       borderColor: "transparent",
//                       borderImage: "initial",
//                       overflow: "hidden",
//                       outline: "none",
//                       minHeight: " 14px",
//                       fontFamily: "Varela Round, sans-serif",
//                     }}
//                     type="submit"
//                   >
//                     {props.addingProduct ? "loading" : "submit"}
//                   </button>
//                 </Col>
//               </Row>
//               <br />
//             </form>
//           </>)
//           :
//           (<NoDepartment />)}

//       </div>
//     ) : (

//       <Container maxWidth='md'
//         style={{
//           backgroundColor: "white",
//           border: "1px solid rgba(0, 0, 0, 0.16)",
//           borderRadius: "10px",
//           Top: "40px",
//           padding: "30px",
//           display: 'flex',
//           flexDirection: 'column',
//           flex: ' 1 1 0',
//           width: "100%",
//         }}
//       >

//         <div
//           type="button"
//           style={{ border: '1px solid #d9d9d9', borderRadius: '8px', cursor: 'pointer', width: '96%', backgroundColor: '#fafafa', paddingLeft: '10px' }}
//           onClick={() => props.history.push('/home/CatalogBuilder')}>

//           <h4 style={{
//             fontSize: '16px',
//             lineHeight: '24px',
//             fontWeight: 800
//           }}>
//             Catalogue Builder
//           </h4>
//           <div style={{ display: 'flex' }}>
//             <span>
//               <h5 style={{
//                 fontSize: '14px',
//                 lineHeight: '20px', fontWeight: 500
//               }}>
//                 Choose and create your catalogue from our already existing products.
//               </h5>
//             </span>
//             <span >
//               <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="rgb(137, 199, 74, 1)"></path>
//                 <path d="M18.2922 22.9106L24.2512 16.9622L24.2002 16.995C24.5455 16.793 24.7617 16.4147 24.7617 15.9999L24.7564 15.8878C24.7248 15.5549 24.5538 15.2548 24.2911 15.0643L24.2512 15.0384L18.2921 9.08924L18.2064 9.01263C17.7513 8.65814 17.1228 8.68729 16.7199 9.07188L16.6416 9.15466C16.2755 9.58339 16.294 10.2248 16.7021 10.6324L20.9324 14.8571L8.34386 14.8571L8.23626 14.8629C7.65553 14.9331 7.23791 15.4226 7.23791 15.9999L7.24293 16.1094C7.29608 16.6859 7.7649 17.1428 8.34386 17.1428H20.9347L16.7022 21.3675L16.6255 21.453C16.2674 21.912 16.3124 22.539 16.7199 22.928C17.1599 23.348 17.8618 23.3403 18.2922 22.9106Z" fill="white">
//                 </path>
//               </svg>
//             </span>
//           </div>

//         </div>
//         {props.DepartmentList && props.DepartmentList.data && props.DepartmentList.data.salesdepartments &&
//           props.DepartmentList.data.salesdepartments.length !== 0 ?
//           (<>
//             <div
//               style={{
//                 paddingTop: "15px",
//                 paddingBottom: "10px",
//                 textAlign: "center",
//                 fontWeight: 600,
//               }}
//             >
//               <h5 style={{ fontWeight: 800, fontSize: "23px" }}>
//                 {"Add new product"}
//               </h5>
//             </div>

//             <form
//               //  enableReinitialize
//               // enableReinitialize={true}
//               style={{ width: "100%", paddingTop: "15px" }}
//               onSubmit={formik.handleSubmit}
//               onReset={formik.handleReset}
//             >
//               <Row style={{ width: "100%", paddingBottom: '30px' }}>
//                 <Col md={12} sm={12} xs={12}>

//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       type="text"
//                       label="Product Name"
//                       variant="outlined"
//                       name="Productname"
//                       // onChange={formik.handleChange}
//                       error={
//                         formik.touched.Productname && Boolean(formik.errors.Productname)
//                       }
//                       helperText={
//                         formik.touched.Productname && formik.errors.Productname
//                       }
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                       style={{
//                         width: "100%"
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       value={wordEntered}
//                       onChange={handleFilter}
//                       onBlur={() => setTimeout(() => {
//                         setOpen(false)
//                       }, 1000)}
//                     />

//                     {value && open && filteredData && filteredData.length !== 0 && (
//                       <div className="dataResult">
//                         {filteredData.viewMasterProducts.items.map((i, index) => {

//                           return (

//                             <MenuItem
//                               key={index}
//                               onClick={() => {

//                                 setWordEntered(i.productname);
//                                 formik.setFieldValue(
//                                   "Productname",
//                                   i.productname
//                                 );
//                                 clearInput(i.id);
//                               }}
//                             >
//                               <img
//                                 src={i.image.primary !== null ? i.image.primary : null}
//                                 alt="product"
//                                 style={{ marginRight: "7px" }}
//                                 height="40px"
//                                 width="40px"
//                               />
//                               {i.productname}
//                             </MenuItem>
//                           );
//                         })}

//                       </div>
//                     )}
//                     {/* <TextField
//                 style={{ marginBottom: "30px" }}
//                 InputProps={{
//                   style: { height: 35, backgroundColor: "white" },
//                 }}
//                 fullWidth
//                 variant="outlined"
//                 className="form-control"
//                 value={formik.values.Productname || " "}
//                 onChange={formik.handleChange}
//                 error={
//                   formik.touched.Productname && Boolean(formik.errors.Productname)
//                 }
//                 helperText={
//                   formik.touched.Productname && formik.errors.Productname
//                 }
//                 name="Productname"
//                 label="Enter product name*"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//               /> */}

//                   </ThemeProvider>
//                 </Col>
//               </Row>

//               <Row style={{ width: "100%" }}>
//                 <ThemeProvider theme={theme}>
//                   <Col md={12} sm={12} xs={12}>
//                     <TextField
//                       style={{ marginBottom: "50px" }}
//                       InputProps={{ style: { height: 75 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Description || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.Description && Boolean(formik.errors.Description)
//                       }
//                       helperText={
//                         formik.touched.Description && formik.errors.Description
//                       }
//                       className="form-control"
//                       // as="textarea"
//                       label="Short description about the product"
//                       rows={3}
//                       name="Description"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}

//                     />
//                   </Col>
//                 </ThemeProvider>
//               </Row>
//               <Row style={{ width: "100%" }}>
//                 <ThemeProvider theme={theme}>
//                   <Col md={12} sm={12} xs={12}>
//                     <TextField
//                       style={{ marginBottom: "30px", }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.barcode || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.barcode && Boolean(formik.errors.barcode)}
//                       helperText={formik.touched.barcode && formik.errors.barcode}
//                       className="form-control"
//                       name="barcode"
//                       label="Barcode number"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                     />
//                   </Col>
//                 </ThemeProvider>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px" }}
//                       InputProps={{ style: { height: 35 } }}
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.Price || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.Price && Boolean(formik.errors.Price)}
//                       helperText={formik.touched.Price && formik.errors.Price}
//                       name="Price"
//                       label="Price*"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",

//                       }}
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       value={formik.values.PromoPrice || " "}
//                       error={
//                         formik.touched.PromoPrice &&
//                         Boolean(formik.errors.PromoPrice)
//                       }
//                       helperText={
//                         formik.touched.PromoPrice && formik.errors.PromoPrice
//                       }
//                       onChange={formik.handleChange}
//                       name="PromoPrice"
//                       label="Promo Price"
//                       InputLabelProps={{
//                         shrink: true,

//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px" }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="brand"
//                       value={formik.values.brand || " "}
//                       onChange={formik.handleChange}
//                       error={formik.touched.brand && Boolean(formik.errors.brand)}
//                       helperText={formik.touched.brand && formik.errors.brand}
//                       label="Brand name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",

//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="MfgName"
//                       value={formik.values.MfgName || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.MfgName && Boolean(formik.errors.MfgName)
//                       }
//                       helperText={formik.touched.MfgName && formik.errors.MfgName}
//                       label="Manufacturer name"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{ marginBottom: "15px" }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       variant="outlined"
//                       name="Quantity"
//                       value={formik.values.Quantity || " "}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.Quantity && Boolean(formik.errors.Quantity)
//                       }
//                       helperText={formik.touched.Quantity && formik.errors.Quantity}
//                       label="Quantity "
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                       style={{
//                         marginBottom: "15px",

//                         height: 35,
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       SelectProps={{ autoWidth: true }}
//                       fullWidth
//                       variant="outlined"
//                       select
//                       label="Unit of measurement"
//                       name="unit"
//                       value={formik.values.unit || ''}
//                       onChange={formik.handleChange}
//                       error={formik.touched.unit && Boolean(formik.errors.unit)}
//                       helperText={formik.touched.unit && formik.errors.unit}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     >
//                       <MenuItem value="No.s" style={{ cursor: "pointer" }}>
//                         No.s
//                       </MenuItem>
//                       <MenuItem value="kg" style={{ cursor: "pointer" }}>
//                         Kilogram (kg)
//                       </MenuItem>
//                       <MenuItem value="g" style={{ cursor: "pointer" }}>
//                         gram (g)
//                       </MenuItem>
//                       <MenuItem value="gal" style={{ cursor: "pointer" }}>
//                         gallon (gal)
//                       </MenuItem>
//                       <MenuItem value="l" style={{ cursor: "pointer" }}>
//                         liter (l)
//                       </MenuItem>
//                       <MenuItem value={"ml"} style={{ cursor: "pointer" }}>
//                         milliliter (ml)
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>
//               <Row style={{ paddingTop: 0, width: '100%' }}>
//                 <Col md={12} xs={12} style={{ marginBottom: "20px" }}>
//                   {/* <ImageCropper {...props} /> */}
//                   <h6>{"Upload your primary image of the product "}</h6>
//                   <FileInputDemo {...props} fileName={formik.values.Productname} />
//                   {/* <NewImg {...props} fileName={formik.values.Productname} /> */}
//                 </Col>
//               </Row>
//               {props.newSignedUrl || props.masterUrl ?

//                 <Row style={{ paddingTop: 0, width: '100%' }}>
//                   <Col md={12} xs={12} style={{ marginBottom: "20px" }}>
//                     {/* <ImageCropper {...props} /> */}
//                     <h6>
//                       {"Upload your secondary images of the product ( if any ) "}
//                     </h6>
//                     <MultipleImg {...props} />
//                   </Col>
//                 </Row>
//                 :
//                 null
//               }
//               <Row style={{ width: '100%' }}>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px"
//                       }}
//                       onKeyPress={(event) => {
//                         if (event?.key === "-" || event?.key === "+") {
//                           event.preventDefault();
//                         }
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       fullWidth
//                       variant="outlined"
//                       name="displayRank"
//                       value={formik.values.displayRank || ""}
//                       onChange={formik.handleChange}
//                       error={
//                         formik.touched.displayRank &&
//                         Boolean(formik.errors.displayRank)
//                       }
//                       helperText={
//                         formik.touched.displayRank && formik.errors.displayRank
//                       }
//                       label="Display rank in the category"
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                     />
//                   </ThemeProvider>
//                 </Col>
//                 <Col md={6} xs={12} style={{ paddingTop: "15px" }}>
//                   <ThemeProvider theme={theme}>
//                     <TextField
//                       style={{
//                         marginBottom: "15px",

//                         width: "100%"
//                       }}
//                       InputProps={{ style: { height: 35 } }}
//                       select
//                       variant="outlined"
//                       SelectProps={{ autoWidth: true, width: "100%" }}
//                       InputLabelProps={{
//                         shrink: true,
//                       }}
//                       label="Status"
//                       name="status"
//                       key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                       value={formik.values.status}
//                       onChange={formik.handleChange}
//                       error={formik.touched.status && Boolean(formik.errors.status)}
//                       helperText={formik.touched.status && formik.errors.status}
//                       defaultValue=""
//                     >
//                       <MenuItem value={"active"} style={{ cursor: "pointer" }}>
//                         {"active"}
//                       </MenuItem>
//                       <MenuItem value={"inactive"} style={{ cursor: "pointer" }}>
//                         {"inactive"}
//                       </MenuItem>
//                     </TextField>
//                   </ThemeProvider>
//                 </Col>
//               </Row>

//               {/* </Form> */}
//               <Row style={{ width: '100%' }}>
//                 <h6
//                   style={{
//                     paddingBottom: "20px",
//                     paddingTop: "20px",
//                   }}
//                 >
//                   <b>
//                     {"Select the hierarchy in which the product should display"}
//                   </b>
//                 </h6>
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 {props.DepartmentList !== "" && (
//                   <>
//                     <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                       <ThemeProvider theme={theme}>
//                         <TextField
//                           style={{
//                             marginBottom: "15px",
//                             width: "100%",
//                           }}
//                           key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                           InputProps={{ style: { height: 35, width: "100%" } }}
//                           sx={{ minWidth: 70 }}
//                           //  fullWidth
//                           select
//                           variant="outlined"
//                           SelectProps={{ autoWidth: true }}
//                           label="Department"
//                           name="department"
//                           required
//                           defaultValue=""
//                           value={state.department}
//                           onChange={(e) => {
//                             handleFormChange(e);
//                             getCategoryList(e.target.value);
//                           }}
//                           InputLabelProps={{
//                             shrink: true,
//                           }}
//                         >
//                           {props.DepartmentList &&
//                             props.DepartmentList.data &&
//                             props.DepartmentList.data.salesdepartments !== null &&
//                             props.DepartmentList.data.salesdepartments.map((s) => (
//                               <MenuItem value={`${s.id}`} key={s.id}>{s.name}</MenuItem>
//                             ))}
//                         </TextField>
//                       </ThemeProvider>
//                     </Col>

//                     {props.CategoryList &&
//                       props.CategoryList.data.salescategory.length !== 0 && (
//                         <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                           <ThemeProvider theme={theme}>
//                             <TextField
//                               key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                               required
//                               style={{
//                                 marginBottom: "15px",

//                                 width: "100%",
//                               }}
//                               InputProps={{
//                                 style: { height: 35, width: "100%" },
//                                 autoComplete: "off",
//                               }}
//                               defaultValue=""
//                               select
//                               variant="outlined"
//                               // sx={{ minWidth: "auto"}}
//                               // SelectProps={{ autoWidth: true }}
//                               label="Category"
//                               name="salescategory"
//                               value={state.salescategory}
//                               onChange={(e) => {
//                                 handleFormChange(e);
//                                 getCategory1List(e.target.value);

//                               }}
//                               // error={formik.touched.salescategory && Boolean(formik.errors.salescategory)}
//                               // helperText={formik.touched.salescategory && formik.errors.salescategory}
//                               InputLabelProps={{
//                                 shrink: true,
//                               }}
//                             >
//                               {props.CategoryList &&
//                                 props.CategoryList.data.salescategory.map((c) => (
//                                   <MenuItem value={`${c.id}`} key={c.id}>{c.name}</MenuItem>
//                                 ))}
//                             </TextField>
//                           </ThemeProvider>
//                         </Col>
//                       )}
//                   </>
//                 )}
//               </Row>
//               <Row style={{ width: '100%' }}>
//                 {state.salescategory !== "" &&
//                   props.CategoryList &&
//                   props.CategoryList.data.salescategory.length !== 0 &&
//                   props.Category1List &&
//                   props.Category1List.data.salescategory.length !== 0 && (
//                     <>
//                       <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                         <ThemeProvider theme={theme}>
//                           <TextField
//                             defaultValue=""
//                             key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                             required
//                             style={{
//                               marginBottom: "15px",
//                               width: "100%",
//                             }}
//                             InputProps={{
//                               style: { height: 35, width: "100%" },
//                               autoComplete: "off",
//                             }}
//                             select
//                             variant="outlined"
//                             // SelectProps={{ autoWidth: true }}
//                             label="Sub-Category 1"
//                             name="salescategory1"
//                             value={state.salescategory1}
//                             onChange={(e) => {
//                               handleFormChange(e);
//                               getCategory2List(e.target.value);
//                             }}
//                             // error={formik.touched.salescategory1 && Boolean(formik.errors.salescategory1)}
//                             // helperText={formik.touched.salescategory1 && formik.errors.salescategory1}
//                             InputLabelProps={{
//                               shrink: true,
//                             }}
//                           >
//                             {props.Category1List &&
//                               props.Category1List.data.salescategory.map((c) => (
//                                 <MenuItem
//                                   style={{ cursor: "pointer" }}
//                                   value={`${c.id}`}
//                                   key={c.id}
//                                 >
//                                   {c.name}
//                                 </MenuItem>
//                               ))}
//                           </TextField>
//                         </ThemeProvider>
//                       </Col>
//                       {state.salescategory1 !== "" &&
//                         props.CategoryList &&
//                         props.CategoryList.data.salescategory.length !== 0 &&
//                         props.Category2List &&
//                         props.Category2List.data.salescategory.length !== 0 && (
//                           <Col md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                             <ThemeProvider theme={theme}>
//                               <TextField
//                                 defaultValue=""
//                                 key={`${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`}
//                                 required
//                                 style={{
//                                   marginBottom: "15px",

//                                   width: "100%",
//                                 }}
//                                 InputProps={{
//                                   style: { height: 35, width: "100%" },
//                                   autoComplete: "off",
//                                 }}
//                                 select
//                                 variant="outlined"
//                                 label="Sub-Category 2"
//                                 name="salescategory2"
//                                 value={state.salescategory2}
//                                 onChange={(e) => handleFormChange(e)}
//                                 InputLabelProps={{
//                                   shrink: true,
//                                 }}
//                               >
//                                 {props.Category2List &&
//                                   props.Category2List.data.salescategory.map(
//                                     (c) => (
//                                       <MenuItem
//                                         style={{
//                                           cursor: "pointer",
//                                           width: "fit-content",
//                                         }}
//                                         value={`${c.id}`}
//                                         key={c.id}
//                                       >
//                                         {c.name}
//                                       </MenuItem>
//                                     )
//                                   )}
//                               </TextField>
//                             </ThemeProvider>
//                           </Col>
//                         )}
//                     </>
//                   )}
//               </Row>

//               <Row style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "15px" }}>
//                 {/* <Col sm={6} md={6} xs={12} style={{ paddingBottom: "15px" }}>
//                 {" "}
//               </Col>
//               <Col sm={6} md={6} xs={12} style={{ paddingLeft: "50%", paddingTop: "15px" }}> */}
//                 <button
//                   style={{
//                     justifyContent: " center",
//                     color: "rgb(255, 255, 255)",
//                     backgroundColor: 'rgb(137, 199, 74, 1)',
//                     boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
//                     width: "150px",
//                     float: "right",
//                     height: "37px",
//                     fontSize: "16px",
//                     fontWeight: "600",
//                     borderRadius: "5px",
//                     borderWidth: "1px",
//                     borderStyle: " solid",
//                     borderColor: "transparent",
//                     borderImage: "initial",
//                     overflow: "hidden",
//                     outline: "none",
//                     minHeight: " 14px",
//                     fontFamily: "Varela Round, sans-serif",
//                   }}
//                   id="submit-button"
//                   type="submit"
//                 >
//                   {props.addingProduct ? "loading" : "Submit"}
//                 </button>
//                 {/* </Col> */}
//               </Row>
//               <br />
//             </form>
//           </>)
//           :
//           (<NoDepartment />)}

//       </Container>
//     )

//   );
// };

// export default Add;

import { React, useEffect, useState } from "react";
import "./product.css";
import CustomTextField from "../Commoncomponents/input";
import CustomSelect from "../Commoncomponents/selectButton";
import CustomButton from "../Commoncomponents/button";
import { validationSchema } from "./Validation";
import { useFormik } from "formik";
import client from "../../Configurations/apollo";
import MultipleImg from "./ImageCropper/MultipleImg";
import { gql } from "@apollo/client";
import { message } from "antd";
import { Row, Col } from "react-bootstrap";
import FileInputDemo from "../file-input/FileInputDemo";
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
} from "../MUIComponents/index";
import { useDispatch } from "react-redux";
import {
  contentFontSize_16,
  ButtonFontSize_18,
  HeaderNavigationFontSize_20,
} from "../Typography/index";
import ProductUpload from "../../utils/images/ProductUpload.jpg";
import NewImg from "./ImageCropper/NewImg";
import { useNavigate, useLocation } from "react-router-dom";

function Product(props) {
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productOfferPrice, setProductOfferPrice] = useState("");

  const nav = useNavigate();
  const location = useLocation();
  const dispatch=useDispatch();

  const [state, setState] = useState({
    department: "",
    salescategory: "",
  });

  useEffect(()=>{

    if(props.productAddFail){
      message.error({
        content: `${props.ProductUpadteError}`,
        className: "custom-class",
        style: {
          marginTop: "10vh",
          marginLeft: "15vw",
        },
        duration: 3.5,
      });
      setTimeout(() => {
        formik.resetForm()
      }, 3500);
      
  
    }else if(props.productAdded){
    message.success({
      content: "Product Added successfully",
      className: "custom-class",
      style: {
        marginTop: "10vh",
        marginLeft: "15vw",
      },
      duration: 3.5,
    });
    setTimeout(() => {
      nav('/home/AddProducts');
    }, 4000);}
  
  },[props.ProductErrorData,props.productAdded])


  useEffect(()=>{
    dispatch({type:"CLEAR_ADD_PRODUCT"})
  },[])

  const SEARCH = gql`
    query ($productname: String, $pagination: PaginationArg) {
      viewMasterProducts(productname: $productname, pagination: $pagination) {
        count
        items {
          id
          productname
          image {
            primary
          }
          desc
        }
        hasNext
      }
    }
  `;
  const handleFormChange = (e, name) => {
    if (e.target.name === "department") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        // salescategory: "",
      });
    } else if (e.target.name === "salescategory") {
      setState({
        ...state,
        [e.target.name]: e.target.value,
      });
    }
  };

  const resetFormik = () => {
    formik.setFieldValue("Productname", "");
    formik.setFieldValue("Description", "");
    formik.setFieldValue("brand", "");
    formik.setFieldValue("MfgName", "");
    formik.setFieldValue("Price", "");
  };

  useEffect(() => {
    props.setMaster();
    resetFormik();
    props.getMasterDepartment();
    localStorage.setItem("imageUrl", null);
  }, []);

  useEffect(() => {
    if (props.MasterProductDetailsSuccess === true) {
      // setFormikValue();
      localStorage.setItem(
        "imageUrl",
        props.MasterProductDetailsData &&
          props.MasterProductDetailsData.data &&
          props.MasterProductDetailsData.data.viewParticularMasterProduct &&
          props.MasterProductDetailsData.data &&
          props.MasterProductDetailsData.data.viewParticularMasterProduct
            .image &&
          props.MasterProductDetailsData.data &&
          props.MasterProductDetailsData.data.viewParticularMasterProduct.image
            .primary
      );
    } else resetFormik();
  }, [props.MasterProductDetailsSuccess]);

  const formik = useFormik({
    initialValues: {
      Productname: "",
      Description: "",
      barcode: "",
      Price: "",
      PromoPrice: "",
      brand: "",
      MfgName: "",
      Quantity: "",
      unit: "",
      displayRank: "",
      status: "active",
      department: "",
      salescategory: "",
      foodType:[]
    },
    validationSchema: validationSchema,
    validateOnChange:true,
      validateOnBlur:true,
    onReset: (values) => {
      formik.setValues({
        Productname: "",
        Description: "",
        barcode: "",
        Price: "",
        PromoPrice: "",
        brand: "",
        MfgName: "",
        Quantity: "",
        unit: "",
        displayRank: "",
        status: "active",
        department: "",
        salescategory: "",
        foodType:[]
      });
    },
    onSubmit: (values, { resetForm }) => {
   
      let department = values.department;
      let category = values.salescategory;
      let subcategory = state.salescategory1;
      let category2 = state.salescategory2;
      // localStorage.getItem('imageUrl')
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
      if (props.fileListLogo === true && props.proImage === null) {
      } else if (props.multiFile === true && props.multiImage === null) {
      } else if (localStorage.getItem("imageUrl") === null) {
        if (values.PromoPrice === "") {
          props.AddPrdt(values, department, category, subcategory, category2);
        }
        else {
          if (Number(values.PromoPrice) >= Number(values.Price)) {
            message.error({
              content: "Promo price must be lesser than price",
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          } else {
            if (values.PromoPrice === "") {
              props.AddPrdt(
                values,
                department,
                category,
                subcategory,
                category2
              );
            } else {
              if (Number(values.PromoPrice) >= Number(values.Price)) {
                message.error({
                  content: "Promo price must be lesser than price",
                  className: "custom-class",
                  style: {
                    marginTop: "20vh",
                  },
                });
              } else {
                props.AddPrdt(
                  values,
                  department,
                  category,
                  subcategory,
                  category2
                );
              }
            }
          }
        }
      } else {
        if (values.PromoPrice === "") {
          props.AddProduct1(
            values,
            department,
            category,
            subcategory,
            category2,
            props.multipleSignedUrl
              ? props.multipleSignedUrl &&
                  props.multipleSignedUrl.uploadMultipleFiles &&
                  props.multipleSignedUrl.uploadMultipleFiles
              : null,
            props.thumbnailUrl ? props.thumbnailUrl : null
          );
        } else {
          if (Number(values.PromoPrice) >= Number(values.Price)) {
            message.error({
              content: "Promo price must be lesser than price",
              className: "custom-class",
              style: {
                marginTop: "20vh",
              },
            });
          } else {
            props.AddProduct1(
              values,
              department,
              category,
              subcategory,
              category2,
              props.multipleSignedUrl
                ? props.multipleSignedUrl &&
                    props.multipleSignedUrl.uploadMultipleFiles &&
                    props.multipleSignedUrl.uploadMultipleFiles
                : null,
              props.thumbnailUrl ? props.thumbnailUrl : null
            );
          }
        }
      }
      setTimeout(() => {
        dispatch({ type: "CLEAR_ADD_PRODUCT" });
      }, 3000);
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  useEffect(() => {
    props.getDepartmentList(localStorage.getItem("storeid"));
  }, [props.getDepartmentList]);

  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsSubcategory, setOptionsSubcategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (
      props.DepartmentList &&
      props.DepartmentList.data &&
      props.DepartmentList.data.salesdepartments
    ) {
      setOptionsCategory(
        props.DepartmentList.data.salesdepartments.map((s) => ({
          value: s.id,
          label: s.name,
        }))
      );
    }
  }, [props.DepartmentList]);

  const getCategoryList = (id) => {
    props.getCategoryList(id);
  };
  const getCategory1List = (categoryId) => {
    const selectedCategory = props.DepartmentList.data.salesdepartments.find(
      (category) => category.id === categoryId
    );

    if (selectedCategory) {
      const subcategories = selectedCategory.childCategories || [];
      setOptionsSubcategory(
        subcategories.map((subcategory) => ({
          value: subcategory.id,
          label: subcategory.name,
        }))
      );
    }
  };
  const [isUnitOpen, setIsUnitOpen] = useState(false);
  const [isFoodtypeOpen, setIsFoodtypeOpen] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const handleScroll = () => {
    setIsUnitOpen(false);
    setIsFoodtypeOpen(false);
    setIsCategoryOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (formik.values.department && !formik.values.salescategory) {
      formik.setFieldError("salescategory", "Sales category is required");
    } else {
      formik.setFieldError("salescategory", "");
    }
  }, [formik.values.department]);

  const foodTypes = [
    { id: 0, value: "Veg" },
    { id: 1, value: "Non Veg" },
    { id: 2, value: "Egg" },
    { id: 3, value: "Gluten-free" },
    { id: 4, value: "Dairy-free" },
    { id: 5, value: "Halal" },
    { id: 6, value: "Kosher" },
    { id: 7, value: "Organic" },
    { id: 8, value: "Low-carb" },
    { id: 9, value: "Low-fat" },
    { id: 10, value: "Sugar-free" },
    { id: 11, value: "Paleo" },
  ];

  const optionsFoodType = [
    { value: "", label: "" },
    { value: "Non-Veg", label: "Non-Veg" },
    { value: "Veg", label: "Veg" },
  ];
  const optionsmeasurent = [
    { value: "No.s", label: "No.s" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "g", label: "gram (g)" },
    { value: "gal", label: "gallon (gal)" },
    { value: "l", label: "liter (l)" },
    { value: "ml", label: "milliliter (ml)" },
  ];
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  function handleClose() {
    nav("/home/AddProducts");
  }
  return (
    <>
      <div className="product">
        <div style={{ display: "flex" }}>
          <Typography
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            className="header-font"
            fontSize={HeaderNavigationFontSize_20}
          >
            Product
          </Typography>
          <Typography
            className="header-font"
            fontSize={HeaderNavigationFontSize_20}
          >
            /
          </Typography>
          <Typography
            className="header-font"
            fontSize={HeaderNavigationFontSize_20}
            style={{ color: "#85BE49" }}
          >
            Add New Product
          </Typography>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div style={{ paddingTop: "30px", boxSizing: "border-box" }}>
          <div className="product-details">
            <div style={{ display: "flex", gap: "3px" }}>
              <Typography className="text-style" fontSize={contentFontSize_16}>
                Product Name
              </Typography>
              <span className="red-start">*</span>
            </div>
            <TextField
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#85BE49",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#85BE49",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
              }}
              size={matches ? "small" : "normal"}
              name="Productname"
              match={matches ? true : false}
              error={
                formik.touched.Productname && Boolean(formik.errors.Productname)
              }
              helperText={
                formik.touched.Productname && formik.errors.Productname
              }
              value={formik.values.Productname || " "}
              onChange={formik.handleChange}
            />
            <div style={{ display: "flex", gap: "3px" }}>
              <Typography className="text-style" fontSize={contentFontSize_16}>
                Product Description
                <span className="red-start">*</span>
              </Typography>
            </div>
            <TextField
              variant="outlined"
              InputProps={{
                sx: {
                  fontSize: matches && "13px",
                  background: "white",
                },
              }}
              // className="custom-select_1"
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#85BE49",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#85BE49",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
              }}
              name="Description"
              error={
                formik.touched.Description && Boolean(formik.errors.Description)
              }
              helperText={
                formik.touched.Description && formik.errors.Description
              }
              value={formik.values.Description || " "}
              onChange={formik.handleChange}
              multiline={true}
              rows="2"
              // background="white"
              size={matches ? "small" : "normal"}
              match={matches ? true : false}
            />
            <Typography className="text-style" fontSize={contentFontSize_16}>
              Barcode Number
            </Typography>
            <TextField
              // className="custom-select_1"
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#85BE49",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#85BE49",
                  },
                },
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: "10px",
                },
              }}
              name="barcode"
              helperText={formik.touched.barcode && formik.errors.barcode}
              error={formik.touched.barcode && Boolean(formik.errors.barcode)}
              value={formik.values.barcode || " "}
              onChange={formik.handleChange}
              background="white"
              size={matches ? "small" : "normal"}
              match={matches ? true : false}
            />
          </div>
          <Grid>
            <Box className="text-style">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={6} sx={{ paddingTop: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      paddingTop: "0px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Product Price
                        <span className="red-start">*</span>
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        // className="custom-select_1"
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        error={
                          formik.touched.Price && Boolean(formik.errors.Price)
                        }
                        name="Price"
                        onChange={formik.handleChange}
                        helperText={formik.touched.Price && formik.errors.Price}
                        value={formik.values.Price || " "}
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Food Type
                        <span className="red-start">*</span>
                      </Typography>
                    </div>
                    <div>
                      <Select
                        defaultValue="Veg"
                        name="foodType"
                        open={isFoodtypeOpen}
                        onClose={() => setIsFoodtypeOpen(false)}
                        onOpen={() => setIsFoodtypeOpen(true)}
                        value={formik.values.foodType}
                        onChange={formik.handleChange}
                        match={matches ? true : false}
                        size={matches ? "small" : "normal"}
                        className="custom-select_1"
                        sx={{
                          " .MuiMenu-paper": {
                            position: "absolute",
                          },
                          width: "100%",
                          buttonColor: "success",
                          variant: "outlined",
                          background: "white",
                          borderRadius: "10px",
                        }}
                      >
                        {foodTypes.map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Brand Name
                        {/* <span className="red-start">*</span> */}
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        // className="custom-select_1"
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        name="brand"
                        value={formik.values.brand || " "}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.brand && Boolean(formik.errors.brand)
                        }
                        helperText={formik.touched.brand && formik.errors.brand}
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Add Category
                        <span className="red-start">*</span>
                      </Typography>
                    </div>
                    {props.DepartmentList !== "" && (
                      <FormControl sx={{ width: "100%" }}>
                        <div>
                          <Select
                            open={isCategoryOpen}
                            onClose={() => setIsCategoryOpen(false)}
                            onOpen={() => setIsCategoryOpen(true)}
                            className="custom-select_1"
                            error={
                              formik.touched.department &&
                              Boolean(formik.errors.department)
                            }
                            helperText={
                              formik.touched.department &&
                              formik.errors.department
                            }
                            match={matches ? true : false}
                            size={matches ? "small" : "normal"}
                            name="department"
                            defaultValue=""
                            value={formik.values.department}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldValue("salescategory", "");
                              formik.setFieldTouched("department", false);
                              formik.setFieldTouched("salescategory", true);
                              getCategoryList(e.target.value);
                            }}
                            sx={{
                              width: "100%",
                              buttonColor: "success",
                              variant: "outlined",
                              background: "white",
                              borderRadius: "10px",
                            }}
                          >
                            {props.DepartmentList &&
                              props.DepartmentList.data &&
                              props.DepartmentList.data.salesdepartments !==
                                null &&
                              props.DepartmentList.data.salesdepartments.map(
                                (s) => (
                                  <MenuItem value={`${s.id}`} key={s.id}>
                                    {s.name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        </div>
                        {formik.touched.department &&
                        formik.errors.department ? (
                          <FormHelperText
                            sx={{ color: "red", marginLeft: "10px !important" }}
                          >
                            {formik.touched.department &&
                              formik.errors.department}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Promo Price
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        // className="custom-select_1"
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        name="PromoPrice"
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                        value={formik.values.PromoPrice || " "}
                        error={
                          formik.touched.PromoPrice &&
                          Boolean(formik.errors.PromoPrice)
                        }
                        helperText={
                          formik.touched.PromoPrice && formik.errors.PromoPrice
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Unit of Measurement
                        <span className="red-start">*</span>
                      </Typography>
                    </div>

                    <div
                      style={{ display: "flex", gap: "10px", width: "100%" }}
                    >
                      <div style={{ width: "50%" }}>
                        <TextField
                          id="outlined-password-input"
                          sx={{
                            width: "100%",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#85BE49",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#85BE49",
                              },
                            },
                            "& .MuiInputBase-input": {
                              backgroundColor: "white",
                              borderRadius: "10px",
                            },
                          }}
                          label="Quantity"
                          background="white"
                          // // placeholder="10"
                          size={matches ? "small" : "normal"}
                          match={matches ? true : false}
                          name="Quantity"
                          value={formik.values.Quantity}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.Quantity &&
                            Boolean(formik.errors.Quantity)
                          }
                          helperText={
                            formik.touched.Quantity && formik.errors.Quantity
                          }
                        />
                      </div>
                      <div style={{ width: "50%" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{
                              color:
                                formik.touched.unit && formik.errors.unit
                                  ? "red"
                                  : "inherit",
                            }}
                          >
                            Unit
                          </InputLabel>
                          <Select
                            id="unit-select"
                            open={isUnitOpen}
                            onClose={() => setIsUnitOpen(false)}
                            onOpen={() => setIsUnitOpen(true)}
                            label="unit"
                            className="custom-select_1"
                            defaultValue="KG"
                            size={matches ? "small" : "normal"}
                            name="unit"
                            value={formik.values.unit || ""}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.unit && Boolean(formik.errors.unit)
                            }
                            helperText={
                              formik.touched.unit && formik.errors.unit
                            }
                            sx={{
                              width: "100%",
                              buttonColor: "success",
                              variant: "outlined",
                              background: "white",
                              borderRadius: "10px",
                            }}
                          >
                            {/* <MenuItem value="" disabled>
                              Select Unit
                            </MenuItem> */}
                            {optionsmeasurent.map((unit) => (
                              <MenuItem key={unit.value} value={unit.value}>
                                {unit.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.unit && formik.errors.unit ? (
                            <FormHelperText sx={{ color: "red" }}>
                              {formik.touched.unit && formik.errors.unit}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                    }}
                  >
                    <div
                      style={{ marginTop: "30px", display: "flex", gap: "3px" }}
                      className="text-style"
                    >
                      <Typography
                        className="text-style"
                        fontSize={contentFontSize_16}
                      >
                        Manufacture Name
                        {/* <span className="red-start">*</span> */}
                      </Typography>
                    </div>
                    <div>
                      <TextField
                        // className="custom-select_1"
                        sx={{
                          width: "100%",

                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#85BE49",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#85BE49",
                            },
                          },
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: "10px",
                          },
                        }}
                        name="MfgName"
                        value={formik.values.MfgName || " "}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.MfgName &&
                          Boolean(formik.errors.MfgName)
                        }
                        helperText={
                          formik.touched.MfgName && formik.errors.MfgName
                        }
                        background="white"
                        size={matches ? "small" : "normal"}
                        match={matches ? true : false}
                      />
                    </div>
                  </div>
                  {formik.values.department !== "" &&
                    props.CategoryList &&
                    props.CategoryList.data &&
                    props.CategoryList.data.salescategory &&
                    props.CategoryList.data.salescategory.length !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            gap: "3px",
                          }}
                          className="text-style"
                        >
                          <Typography
                            className="text-style"
                            fontSize={contentFontSize_16}
                          >
                            Choose Sub Category
                            <span className="red-start">*</span>
                          </Typography>
                        </div>
                        {/* {props.CategoryList &&
                      props.CategoryList.data.salescategory.length !== 0 && ( */}
                        <FormControl>
                          <div>
                            <Select
                              className="custom-select_1"
                              name="salescategory"
                              value={formik.values.salescategory}
                              onChange={(e) => {
                                formik.handleChange(e);
                                getCategory1List(e.target.value);
                              }}
                              width="100%"
                              options={optionsSubcategory}
                              match={matches ? true : false}
                              size={matches ? "small" : "normal"}
                              buttonColor="success"
                              sx={{
                                width: "100%",
                                variant: "outlined",
                                background: "white",
                                borderRadius: "10px",
                              }}
                            >
                              {props.CategoryList &&
                                props.CategoryList.data.salescategory.map(
                                  (c) => (
                                    <MenuItem value={`${c.id}`} key={c.id}>
                                      {c.name}
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </div>
                          <FormHelperText
                            sx={{ color: "red", marginLeft: "10px !important" }}
                          >
                            {formik.touched.salescategory &&
                              formik.errors.salescategory}
                          </FormHelperText>
                        </FormControl>
                        {/* )} */}
                      </div>
                    )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <div className="product-details" style={{ marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <Typography className="text-style" fontSize={contentFontSize_16}>
                Upload Product Images
              </Typography>
            </div>
            <div>
            <Row style={{ paddingTop: 0, width: '100%' }}>
                <Col md={12} xs={12} style={{ marginBottom: "20px" }}>
                  <h6>{"Upload your primary image of the product "}</h6>
                  <FileInputDemo {...props} fileName={formik.values.Productname} />
                </Col>
              </Row>
            </div>
            {props.newSignedUrl ? (
              <Row style={{ paddingTop: 0, width: "100%" }}>
                <Col md={12} xs={12} style={{ marginBottom: "20px" }}>
                  <h6>
                    {"Upload your secondary images of the product ( if any ) "}
                  </h6>
                  <MultipleImg {...props} />
                </Col>
              </Row>
            ) : null}
          </div>
          <div style={{ marginTop: "40px", textAlign: "center" }}>
            <CustomButton
              width={
                upXlmatch
                  ? "358px"
                  : xlmatch
                  ? lgmatch
                    ? mdmatch
                      ? smmatch
                        ? xsmatch
                          ? "250px"
                          : "250px"
                        : "310px"
                      : "330px"
                    : "340px"
                  : "358px"
              }
              type="submit"
              borderRadius="20px"
            >
              <Typography
                fontSize={ButtonFontSize_18}
                className="Add-product-btn"
              >
                {" "}
                Add Product
              </Typography>
            </CustomButton>
          </div>
        </div>
      </form>
    </>
  );
}

export default Product;
