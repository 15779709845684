import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import { Upload, message, Modal } from "antd";
import { Row, Col } from "react-bootstrap";
import Cards from "../../Cards";
import "./index.css";
import client from "../../../Configurations/apollo";
import { AADHAR_UPLOAD } from "../../../Redux/Action/NewSignUp/GqlQuery";
import { useDispatch } from "react-redux";

const Profileimg = ({logo,setLogo}) => {
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState();

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };
const dispatch = useDispatch()
  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const SubmitNow = () => {
    const Imgdata = fileList[0].originFileObj;
    handleImageUpload(Imgdata);
  };

  const handleImageUpload = (Imgdata) => {
      dispatch({
        type: "LOADING_PROFILE_IMAGE_UPLOAD",
      });
      client
        .mutate({
          // mutation: UPLOAD_FILE,
          // variables: {
          // 	file: Imgdata,
          // 	type:1
          mutation: AADHAR_UPLOAD,
          variables: {
            file: Imgdata,
            type:1,
            size:180,
            filename:`${'logo'}-${Math.floor(Math.random()*(9999-1000+1)+1000)}${new Date()}.jpg`
          },
        })
        .then((res) => { 
          
          if (res.data) {
            
            localStorage.setItem('ProfileUrl',res.data.imageEditAndUpload.Location)
            // setLogo(res.data.imageEditAndUpload.Location)
            message.success({
              content: "Profile Image Uploaded Successfully", 
              className: "custom-class",
              style: {
                marginTop: "20vh",
                // marginLeft: "15vh",
              },
            })
            
            dispatch({
              type: "PROFILE_IMAGE_UPLOAD_SUCCESS",
              payload: res.data,
              
            });
          } else {
            message.error({
              content: `${res.message}`, 
              className: "custom-class",
              style: {
                marginTop: "20vh",
                // marginLeft: "15vh",
              },
            })
            dispatch({
              type: "PROFILE_IMAGE_UPLOAD_FAILURE",
              payload: res.message,
            });
          }
        })
        .catch((err) => {
          message.error({
            content: `${err.message}`, 
            className: "custom-class",
            style: {
              marginTop: "20vh",
              // marginLeft: "15vh",
            },
          })
          dispatch({
            type: "PROFILE_IMAGE_UPLOAD_FAILURE",
            payload: err.message,
          });
        });
  
    // client
    //   .mutate({
    //     mutation: AADHAR_UPLOAD,
    //     variables: {
    //       file: Imgdata,
    //       type: 1,
    //       size: 180,
    //       filename: `logo-${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}${new Date()}.jpg`,
    //     },
    //   })
    //   .then((res) => {
    //     localStorage.setItem("imageUrl", res.data.imageEditAndUpload.Location);
    //     if (res.data) {
    //       message.success({
    //         content: "Image Uploaded Successfully", 
    //         className: "custom-class",
    //         style: {
    //           marginTop: "20vh",
    //           marginLeft: "40vh",
    //         },
    //       })
    //       setLogo(localStorage.getItem("StoreLogo"))
    //     } else {
    //     }
    //   })
    //   .catch((err) => {
    //   });
  };

  const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    // if (!isJpgOrPng) {
    //   message.error("You can only upload JPG/PNG file!");
    // }
    // const isLt2M = file.size / 1024 / 1024 < 1;
    // if (!isLt2M) {
    //   message.error("Image must be smaller than 1MB!");
    // }
    // return isJpgOrPng && isLt2M;
  };

  const handleRemove = () => {
    setFileList([])
     localStorage.removeItem("StoreLogo")
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <Row style={{ paddingLeft: 10, paddingTop: 10 }}>
        <Col>
          <ImgCrop
            modalTitle="Logo"
            modalWidth={700}
            modalHeight={700}
            width={512}
            height={512}
            aspect={1 / 1}
            minZoom={0.1}
            rotate={true}
            resize={false}
            resizeAndDrag={true}
            grid
          >
            <Upload
              maxCount={1}
              customRequest={dummyRequest}
              beforeUpload={beforeUpload}
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onRemove={handleRemove}
              onPreview={handlePreview}
              accept="image/*"
            >
              {fileList.length < 1 && "Click here to choose the Profile image"}
            </Upload>
          </ImgCrop>
        </Col>
        <Col>
          {/* <Cards /> */}
        </Col>
      </Row>
      <Row style={{ paddingLeft: 10, paddingTop: 10 }}>
        <Col>
          {fileList.length !== 0 && (
            <button
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                color: "rgb(255, 255, 255)",
                backgroundImage: "-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%)",
                boxShadow: "0px 14px 24px 0px rgba(93, 183, 96, 0.38)",
                width: "80px",
                height: "30px",
                fontSize: "16px",
                fontWeight: "600",
                borderRadius: "5px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "transparent",
                borderImage: "initial",
                overflow: "hidden",
                outline: "none",
                minHeight: "14px",
                fontFamily: "Varela Round, sans-serif",
              }}
              type="button"
              onClick={SubmitNow}
            >
              Upload
            </button>
          )}
        </Col>
      </Row>

      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default Profileimg;

