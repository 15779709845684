import React from "react";
import Typography from "@mui/material/Typography";
import "./index.css";

const FormHeaders = (props) => {
	return (
		<Typography variant="h6" gutterBottom>
			{props.header}
		</Typography>
	);
};
export default FormHeaders;
