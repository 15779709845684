import React,{useEffect} from "react";
import ResetPassword from "../ResetPassword";
import AdditionalDetails from "../AdditionalDetailsEdit";
import RetailerEdit from './RetailerEdit';
import StoreEdit from './StoreEdit';
// import "./index.css";

const EditableForm = (props) => {
	const { getSellerDetail } = props;
	useEffect(() => {
		getSellerDetail(`${localStorage.getItem('retailerid')}`);
		props.setBusinessType(props.retailerDetails&&props.retailerDetails.data&&props.retailerDetails.data.retailerdetails
			&&props.retailerDetails.data.retailerdetails.stores[0]&&props.retailerDetails.data.retailerdetails.stores[0].businessTypeId)
	}, [getSellerDetail])
	
	return props.SubList === 0 ? (
		<RetailerEdit {...props}/>
	) : props.SubList === 1 ? (
		<StoreEdit {...props}/>
	) 
	: props.SubList === 2 ? (
		<AdditionalDetails {...props} />
	)
	 : (
		<ResetPassword {...props} />
	);
};
export default EditableForm;
