// import React from "react";
// // import HomeBanner from "../../Components/Banner";
// import "./index.css";
// import AboutUs from "../../Components/AboutUs";
// import LoginForm from "../../Components/LoginForm";
// import HowTo from "../../Components/HowTo";
// import WhyChoose from "../../Components/WhyChoose";
// import WhoCan from "../../Components/WhoCan";
// import Footer from "../../Components/Footer";
// import SignupModal from "./Signupmodal";
// // import { Row, Col, Image, Container } from "react-bootstrap";

// class MobileScreen extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   render() {
//     if (localStorage.getItem("token") && this.props.successData) {
//       this.props.history.push({
//         pathname: `/home/Dashboard`,
//       });
//     }
//     return (
//       <>
//       <SignupModal {...props}/>
//         <div style={{ width: "100%" }}>
//           <LoginForm {...props} />
//         </div>
//         {/* <div>
//           <AboutUs {...this.props} />
//         </div>
//         <div>
//           <HowTo />
//         </div>
//         <div>
//           <WhyChoose />
//         </div>
//         <div>
//           <WhoCan />
//         </div>
//         <div>
//           <Footer {...this.props}/>
//         </div> */}
//       </>
//     );
//   }
// }
// export default MobileScreen;
import React, { useEffect } from "react";
import "./index.css";
import AboutUs from "../../Components/AboutUs";
import LoginForm from "../../Components/LoginForm";
import HowTo from "../../Components/HowTo";
import WhyChoose from "../../Components/WhyChoose";
import WhoCan from "../../Components/WhoCan";
import Footer from "../../Components/Footer";
import SignupModal from "./Signupmodal";

const MobileScreen = (props) => {


  return (
    <>
      {/* <SignupModal {...props} /> */}
      <div style={{ width: "100%" }}>
        <LoginForm {...props} />
      </div>
       <div>
        <AboutUs {...props} />
      </div> 
      <div className="new_one">
       <div>
        <HowTo />
      </div>
       <div>
        <WhyChoose />
      </div>
     {/* <div>
        <WhoCan />
      </div> */}
     <div>
        <Footer {...props} />
      </div>  
      </div>
    </>
  );
};

export default MobileScreen;
