import React, { useState, useEffect } from "react";
import "./ProfileUpdate.css";
import examplemanimage from "../../utils/images/examplemanimage.jpg";
import CustomTextField from "../Commoncomponents/input/index";
import { InputAdornment } from "../MUIComponents/index";
import indiaImage from "../../utils/images/indiaImage.svg";
import CustomButton from "../Commoncomponents/button/index";
import EditIcon1 from "../../utils/Icon/EditIcon1.svg";
import { Grid } from "../MUIComponents/index";
import { Box } from "../MUIComponents/index";
import { Typography } from "../MUIComponents/index";
import { contentFontSize_16 } from "../Typography/index";
import { useMediaQuery, Modal } from "../MUIComponents/index";
import { useTheme } from "../MUIComponents/index";
import { HeaderFontSize_24 } from "../Typography/index";
import { HeaderNavigationFontSize_20 } from "../Typography/index";
import { ButtonFontSize_18 } from "../Typography/index";
import { useFormik } from "formik";
import { validationSchema } from "../MyAccountForm/validation";
import client from "../../Configurations/apollo";
import { EDIT_RETAILER_MUTATION } from "../MyAccountForm/Query";
import UploadAdhar from "./UploadAdhar.js";
import PanUpload from "./PanUpload.js"
import { formControlClasses } from "@mui/material";
import { fixObservableSubclass } from "@apollo/client/utilities";
import ProfileImageEditWindow from '../ImageEditModal/ProfileImageEditWindow.js'
import { useNavigate } from "react-router-dom";
import { TextField } from "../MUIComponents/index";
import { useDispatch } from "react-redux";
import { message } from "antd";
import dummy from '../../utils/images/dummy.png';

export default function ProfileUpdate(props) {
  const { getSellerDetail } = props;
  const [edit, setEdit] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [net, setNet] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);

  const [openImageEdit, setOpenImageEdit] = useState(false)
  const [open3, setOpen3] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  // const [ProfileImageTemp, setProfileImageTemp] = useState(null);
  // const [ProfileImageTemp1,setProfileImageTemp1]=useState(null)



  const [ProfileImageTemp1, setProfileImageTemp1] = useState(null);
  const [profileEdit, setProfileEdit] = useState(null)


  const [state, setState] = React.useState({
    mapEditModal: false,
  });
  const dispatch = useDispatch();
  const nav = useNavigate();

  const handleOpen6 = () => {
    setOpen6(true)
  }

  const handleClose6 = () => {
    setOpen6(false)
  }

  const handleOpen7 = () => {
    setOpen7(true)
  }

  const handleClose7 = () => {
    setOpen7(false)
  }
  const handleOpen8 = () => {
    setOpen8(true)
  }

  const handleClose8 = () => {
    setOpen8(false)
  }

  const handleImageEdit = () => {
    //setState({ imageEditModal: !state.imageEditModal });
    if (props?.newSignedLogoUrl) {
      setState({ imageEditModal: true })
      localStorage.setItem("ProfileImagelocal", true)
      props?.newSignedUrl &&
        props.postNewUrl({
          url: props?.newSignedUrl,
          details: props?.retailerDetails?.data?.retailerdetails,

        });
    }
  };




  const editStoreLogo = () => {
    setOpenImageEdit(true)
    setOpen3(true)
    dispatch({
      type: "CLEAR_LOGO_URL"
    });
  }


  // function handleOpen4(){
  //   setOpen4(true);
  //   console.log("Opened inside");
  // }

  // const handleClose4 = () => setOpen4(false);

  useEffect(() => {
    // Clear ProfileImageTemp1 when leaving the component
    return () => {
      // localStorage.removeItem("ProfileImageTemp1");
      localStorage.removeItem("ProfileImagelocal")
      // props.uploadLogoImage({files:undefined})
      setProfileImageTemp1(null);
      dispatch({
        type: "CLEAR_LOGO_URL"
      });
      dispatch({
        type: "CLEAR_PROFILE_URL"
      });
    };
  }, []);




  const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };



  const token = localStorage.getItem("token");

  function handleProfileroute() {
    nav("/home/Profile")
  }

  const handleEdit = () => {
    setEdit(!edit);
  };

  const ProfileImageLocal = localStorage.getItem("ProfileImagelocal")

  useEffect(() => {

    if (props?.newSignedLogoUrl && ProfileImageLocal) {
      // localStorage.setItem("ProfileImageTemp1", props.newSignedLogoUrl);
     
      setProfileImageTemp1(props?.newSignedLogoUrl);
    }
  }, [ProfileImageLocal,props?.newSignedLogoUrl]);



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName:
        props?.retailerDetails &&
        props?.retailerDetails?.data &&
        props?.retailerDetails?.data?.retailerdetails &&
        `${props?.retailerDetails?.data?.retailerdetails?.firstName}`,
      lastName:
        props?.retailerDetails &&
        props?.retailerDetails?.data &&
        props?.retailerDetails?.data?.retailerdetails &&
        props?.retailerDetails?.data?.retailerdetails["lastName"],
      mobileNumber:
        props?.retailerDetails &&
        props?.retailerDetails?.data &&
        props?.retailerDetails?.data?.retailerdetails &&
        props?.retailerDetails?.data?.retailerdetails["mobileNumber"],
      secondaryNumber:
        props?.retailerDetails &&
          props?.retailerDetails?.data &&
          props?.retailerDetails?.data?.retailerdetails &&
          props?.retailerDetails?.data?.retailerdetails["secondaryNumber"] === null
          ? ""
          : props?.retailerDetails &&
          props?.retailerDetails?.data &&
          props?.retailerDetails?.data?.retailerdetails &&
          props?.retailerDetails?.data?.retailerdetails["secondaryNumber"],
      emailAddress:
        props?.retailerDetails &&
        props?.retailerDetails?.data &&
        props?.retailerDetails?.data?.retailerdetails &&
        props?.retailerDetails?.data?.retailerdetails["emailAddress"],
      PANnumber:
        props?.retailerDetails &&
          props?.retailerDetails?.data &&
          props?.retailerDetails?.data?.retailerdetails &&
          props?.retailerDetails?.data?.retailerdetails["PANnumber"] === null
          ? ""
          : props?.retailerDetails &&
          props?.retailerDetails?.data &&
          props?.retailerDetails?.data?.retailerdetails &&
          props?.retailerDetails?.data?.retailerdetails["PANnumber"],
      GSTnumber:
        props?.retailerDetails &&
          props?.retailerDetails?.data &&
          props?.retailerDetails?.data?.retailerdetails &&
          props?.retailerDetails?.data?.retailerdetails["GSTnumber"] === null
          ? ""
          : props?.retailerDetails &&
          props?.retailerDetails?.data &&
          props?.retailerDetails?.data?.retailerdetails &&
          props?.retailerDetails?.data?.retailerdetails["GSTnumber"],
      aadharNumber:props?.retailerDetails&&
          props?.retailerDetails?.data&&
          props?.retailerDetails?.data?.retailerdetails&&
          props?.retailerDetails?.data?.retailerdetails?.aadharNumber,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      if (values.mobileNumber === values.secondaryNumber) {
        document.body.scrollTop = 0;
        formik.setFieldError(
          "secondaryNumber",
          "Please provide different contact number"
        );
      } else {
        client
          .mutate({
            mutation: EDIT_RETAILER_MUTATION,
            variables: {
              retailerid:
                props.retailerDetails &&
                props.retailerDetails.data &&
                props.retailerDetails.data.retailerdetails["id"],
              firstName: values.firstName,
              lastName: values.lastName,
              mobileNumber: values.mobileNumber,
              secondaryNumber:
                values.secondaryNumber === "" ? " " : values.secondaryNumber,
              emailAddress: values.emailAddress,
              PANnumber: values.PANnumber === "" ? null : values.PANnumber,
              GSTnumber: values.GSTnumber === "" ? null : values.GSTnumber,
              GSTurl: props.GSTEditUrl ? props.GSTEditUrl : null,
              profileImageUrl:
                props.newSignedLogoUrl !== ""
                  ? props.newSignedLogoUrl
                  : props.retailerDetails &&
                  props.retailerDetails.data &&
                  props.retailerDetails.data.retailerdetails &&
                  props.retailerDetails.data.retailerdetails.profileImageUrl,


            },
            context: {
              headers: {
                Authorization: "Bearer " + token,
              },
            },
          })
          .then((res) => {
            if (res.data) {
              setEdit(!edit);
              setOpen(true);
              props.getSellerDetail(`${localStorage.getItem("retailerid")}`);
            } else {
              setError(true);
              // setErrorMessage(res.message)
            }
          })
          .catch((err) => {
            setNet(true);
            // setNetmsg(err)
          });
        //

        setFormSubmitted(true)
        message.success({
          content: "Saved changes successfully",
          className: "custom-class",
          style: {
            marginTop: "10vh",
            marginLeft: "15vw",
          },
          duration: 3.5,
        });
        setTimeout(() => {
          nav('/home/Profile')
        }, 4000);




      }
      setEdit(!edit);
    },
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"))


  const handleOpen = () => setOpen4(true);
  const handleClose = () => setOpen4(false);
  const handleOpen1 = () => setOpen5(true);
  const handleClose1 = () => setOpen5(false);



  useEffect(() => {
    document.body.scrollTop = 0;
    props?.props?.getSellerDetail(`${localStorage.getItem("retailerid")}`);

  }, [getSellerDetail]);


  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  return (
    <div style={{ background: "rgb(244, 245, 249)", height: "100%" }}>
      <div className="profileLinker">
        <Typography
          onClick={handleProfileroute}
          style={{ cursor: "pointer" }}
          fontSize={HeaderNavigationFontSize_20}
          className="ProfileUpdateFontFamily"
        >
          Profile
        </Typography>
        <Typography
          fontSize={HeaderNavigationFontSize_20}
          className="ProfileUpdateFontFamily"
        >
          /
        </Typography>
        <Typography
          fontSize={HeaderNavigationFontSize_20}
          style={{ color: "#85BE49" }}
          className="ProfileUpdateFontFamily"
        >
          Update My Profile
        </Typography>
      </div>
      <Typography
        fontSize={HeaderNavigationFontSize_20}
        className="ProfileSecond"
      >
        Update My Profile
      </Typography>
      <div className="updateProfileProfileImage"
      >
        <div className="updateProfileProfileImage1">
          <div className="profile">
            <div
              style={{
                width: matches ? "80px" : "100px",
                height: matches ? "80px" : "100px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {ProfileImageTemp1 ? (
                <img
                  src={
                    profileEdit
                  }
                  alt="user profile image"
                  style={{
                    width: matches ? "80px" : "100px",
                    height: matches ? "80px" : "100px",
                    borderRadius: "50%",
                    border: "3px solid #72B01D",
                    cursor: "pointer"
                  }}
                  onClick={handleOpen6}
                />
              ) :

                (
                  props?.retailerDetails?.data?.retailerdetails?.profileImageUrl ? (<img
                    src={
                      props?.retailerDetails?.data?.retailerdetails?.profileImageUrl
                    }
                    alt="user profile image"
                    style={{
                      width: matches ? "80px" : "100px",
                      height: matches ? "80px" : "100px",
                      borderRadius: "50%",
                      border: "3px solid #72B01D",
                      cursor: "pointer",
                    }}
                    onClick={handleOpen7}
                  />) : (
                    <img
                      src={
                        dummy
                      }
                      alt="user profile image"
                      style={{
                        width: matches ? "80px" : "100px",
                        height: matches ? "80px" : "100px",
                        borderRadius: "50%",
                        border: "3px solid #72B01D",
                        cursor: "pointer",
                      }}
                      onClick={handleOpen8}
                    />
                  )
                )}
              <div
                className="svg-icon"
                style={{
                  width: matches ? "20px" : "29px",
                  height: matches ? "21px" : "30px",
                }}
              >
                <img src={EditIcon1} alt="edit pen" onClick={editStoreLogo} style={{ cursor: "pointer" }} />
                {openImageEdit ? <ProfileImageEditWindow {...props} handleImageEdit={handleImageEdit} open3={open3} setProfileEdit={setProfileEdit} profileEdit={profileEdit} setOpen3={setOpen3} style={{ width: "100%" }} /> : null}

              </div>
            </div>
          </div>
          <Typography
            className="ProfileNameupdate"
            fontSize={HeaderFontSize_24}
          >
            {/* Berlin Jino D */}
            <div style={{ display: "flex", gap: "5px" }}>
              <div>
                {props?.retailerDetails &&
                  props?.retailerDetails?.data &&
                  props?.retailerDetails?.data?.retailerdetails &&
                  `${props?.retailerDetails?.data?.retailerdetails?.firstName}`}
              </div>
              <div>

                {props?.retailerDetails &&
                  props?.retailerDetails?.data &&
                  props?.retailerDetails?.data?.retailerdetails &&
                  props?.retailerDetails?.data?.retailerdetails["lastName"]}
              </div>
            </div>



          </Typography>

          <Modal
            open={open6}
            style={modalStyle}
            onClose={handleClose6}

          >
            <img
              src={
                profileEdit
                  ? profileEdit
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen6}
            ></img>

          </Modal>
          <Modal
            open={open7}
            style={modalStyle}
            onClose={handleClose7}

          >
            <img
              src={
                props?.retailerDetails?.data?.retailerdetails?.profileImageUrl
                  ? props?.retailerDetails?.data?.retailerdetails?.profileImageUrl
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen7}
            ></img>

          </Modal>
          <Modal
            open={open8}
            style={modalStyle}
            onClose={handleClose8}

          >
            <img
              src={
                dummy
                  ? dummy
                  : null
              }
              style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
              // onClick={() => setOpen5(false)}
              onClick={handleOpen8}
            ></img>

          </Modal>
        </div>
        {/* {start} */}
        <div style={{ width: "100%" }}>
          <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>


              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                  <div className="Namestyles">
                    <Typography
                      className="ProfileLabelStyles"
                      fontSize={contentFontSize_16}
                    >
                      First Name <span className="redstart">*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: '100%',

                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#85BE49', // This will change the border color
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#85BE49', // This will change the border color when focused
                          },
                        },
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          borderRadius: "10px" // This will change the input background color
                        },
                      }}
                      InputProps={{
                        sx: {
                          fontSize: matches && '13px',
                        },
                      }}
                      size={matches ? 'small' : 'normal'}
                      name="firstName"
                      value={formik.values.firstName || ''}
                      onChange={formik.handleChange}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                    />

                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: smmatch && "0px" }} >
                  <div className="Namestyles">
                    <Typography
                      className="ProfileLabelStyles"
                      fontSize={contentFontSize_16}
                    >
                      Last Name <span className="redstart">*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      sx={{
                        width: '100%',

                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#85BE49',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#85BE49',
                          },
                        },
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          borderRadius: "10px"
                        },
                      }}
                      InputProps={{
                        sx: {
                          fontSize: matches && '13px',
                        },
                      }}
                      size={matches ? "small" : "normal"}
                      name="lastName"
                      value={formik.values.lastName && formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    ></TextField>
                  </div>
                </Grid>
              </Grid>
              {/* </div> */}
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                    <div className="Namestyles">
                      <Typography
                        className="ProfileLabelStyles"
                        fontSize={contentFontSize_16}
                      >
                        Email <span className="redstart">*</span>
                      </Typography>
                      <TextField
                        variant="outlined"

                        sx={{
                          width: '100%',

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#85BE49',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#85BE49',
                            },
                          },
                          '& .MuiInputBase-input': {
                            backgroundColor: 'white',
                            borderRadius: "10px"
                          },
                        }}
                        InputProps={{
                          sx: {
                            fontSize: matches && '13px',
                          },
                        }}
                        size={matches ? "small" : "normal"}
                        disabled
                        name="emailAddress"
                        value={
                          formik.values.emailAddress && formik.values.emailAddress
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.emailAddress &&
                          Boolean(formik.errors.emailAddress)
                        }
                        helperText={
                          formik.touched.emailAddress &&
                          formik.errors.emailAddress
                        }

                      ></TextField>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: smmatch && "0px" }} >
                    <div className="Namestyles">
                      <Typography
                        className="ProfileLabelStyles"
                        fontSize={contentFontSize_16}
                      >
                        Mobile Number <span className="redstart">*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        sx={{
                          width: '100%',

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#85BE49',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#85BE49',
                            },
                          },
                          '& .MuiInputBase-input': {
                            backgroundColor: 'white',
                            borderRadius: "10px"
                          },
                        }}
                        size={matches ? "small" : "normal"}
                        InputProps={{
                          sx: {
                            fontSize: matches && '13px',
                            background: "white"
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={indiaImage}
                                alt="India flag"
                                style={{ marginRight: "8px" }}
                              />
                              <div style={{ color: "black" }}>+91 |</div>
                            </InputAdornment>
                          ),
                        }}


                        // startAdornment={
                        //   <InputAdornment position="start">
                        //     <img
                        //       src={indiaImage}
                        //       alt="India flag"
                        //       style={{ marginRight: "8px" }}
                        //     />
                        //     <div style={{ color: "black" }}>+91 |</div>
                        //   </InputAdornment>
                        // }
                        name="mobileNumber"
                        value={formik.values.mobileNumber}
                        onChange={formik.handleChange}

                        error={
                          formik.touched.mobileNumber &&
                          Boolean(formik.errors.mobileNumber)
                        }
                        helperText={
                          formik.touched.mobileNumber &&
                          formik.errors.mobileNumber
                        }
                      ></TextField>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                    <div className="Namestyles">
                      <Typography
                        className="ProfileLabelStyles"
                        fontSize={contentFontSize_16}
                      >
                        Alternate Mobile Number
                      </Typography>

                      <TextField
                        variant="outlined"
                        sx={{
                          width: '100%',

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#85BE49',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#85BE49',
                            },
                          },
                          '& .MuiInputBase-input': {
                            backgroundColor: 'white',
                            borderRadius: "10px"
                          },
                        }}
                        size={matches ? "small" : "normal"}
                        InputProps={{
                          sx: {
                            fontSize: matches && '13px',
                            background: "white"
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={indiaImage}
                                alt="India flag"
                                style={{ marginRight: "8px" }}
                              />
                              <div style={{ color: "black" }}>+91 |</div>
                            </InputAdornment>
                          ),
                        }}

                        name="secondaryNumber"
                        value={
                          formik.values.secondaryNumber === null
                            ? ""
                            : formik.values.secondaryNumber
                        }
                        onChange={formik.handleChange}

                        error={
                          formik.touched.secondaryNumber &&
                          Boolean(formik.errors.secondaryNumber)
                        }
                        helperText={
                          formik.touched.secondaryNumber &&
                          formik.errors.secondaryNumber
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            <img
                              src={indiaImage}
                              alt="India flag"
                              style={{ marginRight: "8px" }}
                            />
                            <div style={{ color: "black" }}>+91 |</div>
                          </InputAdornment>
                        }
                      ></TextField>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: smmatch && "0px" }} >
                    <div className="Namestyles">
                      <Typography
                        className="ProfileLabelStyles"
                        fontSize={contentFontSize_16}
                      >
                        Aadhaar Number
                      </Typography>
                      <TextField
                        variant="outlined"
                        sx={{
                          width: '100%',

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#85BE49',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#85BE49',
                            },
                          },
                          '& .MuiInputBase-input': {
                            backgroundColor: 'white',
                            borderRadius: "10px"
                          },
                        }}
                        InputProps={{
                          sx: {
                            fontSize: matches && '13px',
                          },
                        }}
                        size={matches ? "small" : "normal"}
                        name="aadharNumber"
                        value={formik.values.aadharNumber && formik.values.aadharNumber}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.aadharNumber &&
                          Boolean(formik.errors.aadharNumber)
                        }
                        helperText={
                          formik.touched.aadharNumber && formik.errors.aadharNumber
                        }
                        disabled
                      ></TextField>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* <div> */}
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingLeft: "0px" }}>
                  <div className="Namestyles">
                    <Typography
                      className="ProfileLabelStyles"
                      fontSize={contentFontSize_16}
                    >
                      PAN Number
                    </Typography>

                    <TextField
                      variant="outlined"
                      sx={{
                        width: '100%',

                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#85BE49',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#85BE49',
                          },
                        },
                        '& .MuiInputBase-input': {
                          backgroundColor: 'white',
                          borderRadius: "10px"
                        },
                      }}
                      InputProps={{
                        sx: {
                          fontSize: matches && '13px',
                        },
                      }}
                      size={matches ? "small" : "normal"}
                      disabled
                      name="PANnumber"
                      value={
                        formik.values.PANnumber &&
                        formik.values.PANnumber.replace(/\w(?=\w{4})/g, "*")
                      }
                      onChange={formik.handleChange}
                      error={
                        formik.touched.PANnumber &&
                        Boolean(formik.errors.PANnumber)
                      }
                      helperText={
                        formik.touched.PANnumber && formik.errors.PANnumber
                      }
                    ></TextField>
                  </div>
                </Grid>
              </Grid> */}


            </Box>
            <Typography
              style={{ marginTop: "20px", marginBottom: "15px" }}
              className="ProfileLabelStyles"
              fontSize={contentFontSize_16}
            >
            Uploaded Documents
            </Typography>

            <div className="footerUpdateProfile">

            {   props?.retailerDetails &&
                      props?.retailerDetails?.data &&
                      props?.retailerDetails?.data?.retailerdetails &&
                      props?.retailerDetails?.data?.retailerdetails?.aadharUrl&&(<div style={{display:"flex",flexDirection:"column",gap:"10px"}}> <Typography
                className="ProfileLabelStyles"
                fontSize={contentFontSize_16}
              >
                Aadhaar
              </Typography>
              <div className="aadhaarStyle" >
                <img
                  src={
                    props?.retailerDetails &&
                      props?.retailerDetails?.data &&
                      props?.retailerDetails?.data?.retailerdetails &&
                      props?.retailerDetails?.data?.retailerdetails?.aadharUrl
                      ? props?.retailerDetails?.data?.retailerdetails?.aadharUrl
                      : null
                  }
                  style={{ width: matches ? "80px" : "100px", height: matches ? "80px" : "100px", cursor: "pointer" }}

                  onClick={() => setOpen4(true)}
                ></img>
              </div>
              </div>)}

              <Modal
                open={open4}
                style={modalStyle}
                onClose={handleClose}

              >
                <img
                  src={
                    props?.retailerDetails &&
                      props?.retailerDetails?.data &&
                      props?.retailerDetails?.data?.retailerdetails &&
                      props?.retailerDetails?.data?.retailerdetails?.GSTurl
                      ? props?.retailerDetails?.data?.retailerdetails?.aadharUrl
                      : null
                  }
                  style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
                  onClick={handleOpen}

                ></img>

              </Modal>

              {/* <AadharUpload/> */}

              {props?.retailerDetails &&
                props?.retailerDetails?.data &&
                props?.retailerDetails?.data?.retailerdetails &&
                props?.retailerDetails?.data?.retailerdetails?.PANurl && (<div style={{display:"flex",flexDirection:"column",gap:"10px"}}> <Typography
                  className="ProfileLabelStyles"
                  fontSize={contentFontSize_16}
                >
                  PAN
                </Typography>

                  <div className="aadhaarStyle" >
                    <img
                      src={
                        props?.retailerDetails &&
                          props?.retailerDetails?.data &&
                          props?.retailerDetails?.data?.retailerdetails &&
                          props?.retailerDetails?.data?.retailerdetails?.PANurl
                          ? props?.retailerDetails?.data?.retailerdetails?.PANurl
                          : null
                      }
                      style={{ width: matches ? "80px" : "100px", height: matches ? "80px" : "100px", cursor: "pointer" }}
                      onClick={() => setOpen5(true)}


                    ></img>
                  </div>
                </div>
                )}

              <Modal
                open={open5}
                style={modalStyle}
                onClose={handleClose1}

              >
                <img
                  src={
                    props?.retailerDetails &&
                      props?.retailerDetails?.data &&
                      props?.retailerDetails?.data?.retailerdetails &&
                      props?.retailerDetails?.data?.retailerdetails?.PANurl
                      ? props?.retailerDetails?.data?.retailerdetails?.PANurl
                      : null
                  }
                  style={{ width: matches ? "50%" : "50%", height: matches ? "50%" : "50%", cursor: "pointer" }}
                  // onClick={() => setOpen5(false)}
                  onClick={handleOpen1}
                ></img>

              </Modal>




            </div>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <CustomButton
                width={matches ? "40%" : "30%"}
                borderRadius="20px"
                type="submit"
                matchPadding={true}
                padding="10px 0px"
                background={formSubmitted ? "gray" : '#659B1B'}
                disabled={formSubmitted && "disabled"}
                onClick={handleEdit}
              >
                <Typography
                  fontSize={ButtonFontSize_18}
                  className="ProfileUpdateButtonStyle"
                >
                  {/* {edit ? "Save Changes" : "Edit"} */}
                  Save Changes
                </Typography>
              </CustomButton>
            </div>
          </form>
        </div>

        {/* {end} */}
      </div>
    </div>
  );
}
