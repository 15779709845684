import React, { useEffect } from "react";
import withClearCache from "../ClearCache";
import ReduxProvider from "./redux";
import { ApolloProvider } from '@apollo/client';
import client from "./apollo";
import Main from './Main/index';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  useEffect(() => {
    const storeid = localStorage.getItem('storeid');
    const token = localStorage.getItem('token');
    const pathname =  window.location.pathname; 
    const pathURL = window?.location?.href
    // const loginformNumber = localStorage.getItem('loginformNumber');

    // if (pathname === "/Register" && loginformNumber !== 0) {
    //   console.log("Redirecting to / because loginformNumber is not 0");
    //   window.location.href = '/';
    // }

    if (storeid === null && token === null) {
      if (pathname !== '/' && pathname !== "/seller-registration" && pathname !== "/Register") {
        window.location.href = '/';
      }
    } else if (localStorage.getItem('role') === 'Shop Assistant') {
      if (!(pathURL.includes("/home/MyOrders") || pathURL.includes("/home/Help"))) {
        window.location.href = '/home/MyOrders';
      }
    }
  }, [ window?.location?.href]);

  return (
    <div className="App">
      <ReduxProvider>
        <ApolloProvider client={client}>
          <Main {...props} />
        </ApolloProvider>
      </ReduxProvider>
    </div>
  );
}

export default App;
