import { gql } from "@apollo/client";


export const EDIT_RETAILER_MUTATION = gql`
  mutation (
    $retailerid: ID
    $firstName: String
    $lastName: String
    $mobileNumber: String
    $secondaryNumber: String
    $emailAddress: String
    $PANnumber: String
    $GSTnumber: String
    $GSTurl:String
    $profileImageUrl:String
  ) {
    updateretailer(
      retailerid: $retailerid
      firstName: $firstName
      lastName: $lastName
      mobileNumber: $mobileNumber
      secondaryNumber: $secondaryNumber
      emailAddress: $emailAddress
      PANnumber: $PANnumber
      GSTnumber: $GSTnumber
      GSTurl:$GSTurl
      profileImageUrl:$profileImageUrl
    ) {
      id
      firstName
      lastName
      mobileNumber
      secondaryNumber
      emailAddress
      PANnumber
      GSTnumber
      GSTurl
      profileImageUrl
      stores {
        id
        storeLocation
        storeName
        storeDescription
        storeManagerFirstName
        storeManagerLastName
        storeCoordinates {
          coordinates
        }
        storeLogoUrl
        deliveryType
        deliveryMaxDistance
        deliveryCharge {
          kmRange
          charge
        }
        paymentSupported
        deliveryDays {
          deliveryDay
          T9amTO12pm
          T12pmTO3pm
          T3pmTO6pm
          T6pmTO9pm
        }
        storeManagerMobile
        storeManagerPhone
      }
    }
  }
`;



export const EDIT_STORE_MUTATION = gql`
  mutation (
    $storeid: ID!
    $storeName: String
    $storeLocation: String
    $storeDescription: String
    $storeLogoUrl: String
    $storeCoordinates: locationInput
    $storeManagerFirstName: String
    $storeManagerLastName: String
    $storeManagerMobile: String
    $storeManagerPhone: String
    $deliveryType: [String]
    $paymentSupported: [String]
    $fssaiNo: String
    $deliveryDays: [deliveryDaysInput]
    $bankDetails: bankDetailsInput
    $businessTypeId:[Int]
    $sameDayDelivery:Boolean
    $deliveryAfter:Int
  ) {
    updatestore(
      storeid: $storeid
      storeName: $storeName
      storeLocation: $storeLocation
      storeDescription: $storeDescription
      storeLogoUrl: $storeLogoUrl
      fssaiNo: $fssaiNo
      storeCoordinates: $storeCoordinates
      storeManagerFirstName: $storeManagerFirstName
      storeManagerLastName: $storeManagerLastName
      storeManagerMobile: $storeManagerMobile
      storeManagerPhone: $storeManagerPhone
      deliveryType: $deliveryType
      paymentSupported: $paymentSupported
      deliveryDays: $deliveryDays
      bankDetails: $bankDetails
      businessTypeId: $businessTypeId
      sameDayDelivery:$sameDayDelivery
      deliveryAfter:$deliveryAfter
    ) {
      id
      storeLocation
      storeName
      fssaiNo
      storeDescription
      storeManagerFirstName
      storeManagerLastName
      storeCoordinates {
        coordinates
      }
      storeLogoUrl
      deliveryType
      sameDayDelivery
      deliveryAfter
      businessTypeId
      deliveryMaxDistance
      deliveryCharge {
        kmRange
        charge
      }
      paymentSupported
      deliveryDays {
        deliveryDay
        T9amTO12pm
        T12pmTO3pm
        T3pmTO6pm
        T6pmTO9pm
      }
      storeManagerMobile
      storeManagerPhone
    }
  }
`;
// 